import React, { useMemo } from 'react';

import { FetchError } from 'components';
import { useFetcher, useModalMeasurements } from 'hooks';
import {
  getVitalsHistory,
  HistoryHeader,
  HistoryLabel,
  HistoryLoadingPlaceholder,
  PainLevel
} from 'Residents';

import { PainLevelHistoryChart } from './Charts';
import { PainLevelHistoryLogs } from './PainLevelHistoryLogs';

import style from 'Residents/History/History.module.css';

export const PainLevelHistory: React.FunctionComponent<{
  onClose: () => void;
  facilityId: string;
  residentId: string;
  historyFilters: {
    start: string;
    end: string;
    label: HistoryLabel;
  };
  onAdd: () => void;
  changeHistoryFilter: (filter: HistoryLabel) => void;
}> = ({
  onClose,
  facilityId,
  residentId,
  historyFilters,
  onAdd,
  changeHistoryFilter
}) => {
  const { modalOffsetTop, modalOffsetLeft, modalWidth } = useModalMeasurements(
    'Vitals',
    'MainContent',
    !!residentId
  );
  const computedOffsetTop = modalOffsetTop >= 0 ? modalOffsetTop : 0;
  const computedOffsetLeft = modalOffsetLeft >= 0 ? modalOffsetLeft - 15 : 0;

  const vitalsHistoryLogAction = useMemo(() => {
    if (facilityId && residentId) {
      const { start, end } = historyFilters;

      return getVitalsHistory(facilityId, residentId, 'PAIN_LEVEL', start, end);
    }
  }, [facilityId, residentId, historyFilters]);

  const { data: vitalsHistory, loading, error } = useFetcher<PainLevel[]>(
    vitalsHistoryLogAction,
    []
  );

  return (
    <>
      <div
        className={style.history}
        style={{
          marginTop: computedOffsetTop,
          marginLeft: computedOffsetLeft,
          width: modalWidth
        }}
        id="History"
      >
        {!error && (
          <HistoryHeader
            title="Pain Level History"
            toggle={onClose}
            onAdd={onAdd}
          />
        )}
        {!loading && error && (
          <FetchError error={error} closable={true} onClose={onClose} />
        )}
        {!error && (
          <>
            <PainLevelHistoryChart
              changeHistoryFilter={changeHistoryFilter}
              activeHistoryTab={historyFilters.label}
              loading={loading}
              readings={vitalsHistory as PainLevel[]}
            />
            {loading && <HistoryLoadingPlaceholder />}
            {!loading && (
              <PainLevelHistoryLogs
                historyLogs={vitalsHistory as PainLevel[]}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
