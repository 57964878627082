import classnames from 'classnames';
import React, { MouseEventHandler } from 'react';

import { SwitchHomeView, TOGGLE_BUTTON_ID } from 'Home';
import { useHomeViewContext } from 'Home/contexts';

import { ReactComponent as ViewIcon } from 'icons/view.svg';

import styles from './DashboardViewSelector.module.css';

export const DashboardViewSelector = () => {
  const [{ topAlign }, homeViewDispatch] = useHomeViewContext();

  const togglePanel: MouseEventHandler<HTMLButtonElement> = e => {
    if (!topAlign) {
      homeViewDispatch({
        type: SwitchHomeView.SET_TOP,
        payload: e.pageY
      });
    } else {
      homeViewDispatch({
        type: SwitchHomeView.SET_TOP,
        payload: undefined
      });
    }
  };

  return (
    <div className={styles.buttonContainer}>
      <button
        onClick={togglePanel}
        className={classnames(styles.viewButton, {
          [styles.dashboardViewBtnActive]: topAlign,
          [styles.dashboardViewBtn]: !topAlign
        })}
        id={TOGGLE_BUTTON_ID}
      >
        <ViewIcon
          style={{
            userSelect: 'none',
            pointerEvents: 'none'
          }}
        />
      </button>
    </div>
  );
};
