import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelList,
  Table
} from 'components';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';
import { getHeight } from 'utils';

import { Caregiver, Facility } from 'Settings/types';

import { getCaregiversByQuery, getPaginatedCaregivers } from '../actions';
import {
  CAREGIVERS_LIST_PLACEHOLDER,
  CAREGIVERS_SEARCH_INPUT_NAME,
  CAREGIVERS_TITLE,
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH
} from '../constants/index';

import { AddCaregiverForm } from './AddCaregiverForm';
import { CaregiverTableRow } from './CaregiverTableRow';

import stylesModal from 'components/Modal/Modal.module.css';

const CaregiverListTableColumns: Column[] = [
  { name: 'Caregiver Name', align: 'left', cols: 3 },
  { name: 'Role', align: 'left', cols: 3 },
  { name: 'Employee Number', align: 'left', cols: 2 },
  { name: 'Status', align: 'center', cols: 1 }
];

export const CaregiverList: React.FunctionComponent<{
  facility: Facility;
  selectCaregiver: (caregiverId: string) => void;
  selectedCaregiverId: string;
  modifiedCaregiver: Caregiver;
  resetActiveCaregiver: () => void;
}> = ({
  facility,
  selectCaregiver,
  selectedCaregiverId,
  modifiedCaregiver,
  resetActiveCaregiver
}) => {
  const [page, setPage] = useState(0);
  const [searchedCaregiversPage, setSearchedCaregiversPage] = useState(0);
  const [caregivers, setCaregivers] = useState<Caregiver[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getCaregiversMemo = useMemo(
    () => getPaginatedCaregivers(facility.id, page),
    [facility.id, page]
  );

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getCaregiversByQuery(
        facility.id,
        searchTerm,
        searchedCaregiversPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedCaregiversPage]);

  const {
    data: { items: caregiverList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Caregiver[];
    nextPage?: string;
  }>(getCaregiversMemo, { items: [] });

  const {
    data: {
      items: searchedCaregivers,
      nextPage: nextPageForSearchedCaregivers
    },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{
    items: Caregiver[];
    nextPage?: string;
  }>(memoSearchAction, { items: [] });

  useEffect(() => {
    if (caregiverList.length !== 0) {
      setCaregivers(prevState => [...prevState, ...caregiverList]);
    }
  }, [caregiverList]);

  useEffect(() => {
    const selectedCaregiverIndex = caregivers.some(caregiver => {
      return caregiver.id === selectedCaregiverId;
    });

    if (
      nextPage &&
      selectedCaregiverId &&
      !selectedCaregiverIndex &&
      caregivers.length
    ) {
      setPage(parseInt(nextPage!, 10));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregivers]);

  // reset active caregiver id in Overview if selectedCaregiver no longer has access to the facility
  useEffect(() => {
    if (!caregivers.length) {
      return;
    }
    const selectedCaregiver = caregivers.find(
      ({ id }) => id === selectedCaregiverId
    );
    if (!selectedCaregiver && !nextPage) {
      resetActiveCaregiver();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregivers, selectedCaregiverId, resetActiveCaregiver]);

  useEffect(() => {
    setCaregivers(currentCaregivers => {
      return currentCaregivers.map(caregiver =>
        caregiver.id === modifiedCaregiver.id ? modifiedCaregiver : caregiver
      );
    });
  }, [modifiedCaregiver]);

  useEffect(() => {
    if (searchError) {
      return setCaregivers([]);
    }
    if (searchedCaregiversPage > 0) {
      setCaregivers(prevState => [...prevState, ...searchedCaregivers]);
    } else {
      setCaregivers(searchedCaregivers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedCaregivers, searchError]);

  const scrollAction = () => {
    if (isFetching && caregiverList.length && nextPage && !isSearching) {
      setPage(parseInt(nextPage, 10));
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedCaregivers.length &&
      nextPageForSearchedCaregivers &&
      isSearching
    ) {
      setSearchedCaregiversPage(parseInt(nextPageForSearchedCaregivers, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const { isShowing, toggle } = useModal();

  const modalClosed = () => {
    setCaregivers([]);
    if (page === 0) {
      setRefetch();
    } else {
      setPage(0);
    }
    toggle();
  };

  const onAdd = () => {
    toggle();
  };

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedCaregiversPage(0);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setCaregivers([]);
      setPage(0);
      setRefetch();
    }
  };

  const render = (rowValues: any) => (
    <CaregiverTableRow
      key={rowValues.id}
      values={rowValues}
      selectCaregiver={selectCaregiver}
      isSelected={selectedCaregiverId === rowValues.id}
    />
  );

  const isDataAvailable = caregivers.length > 0;

  return (
    <PanelList
      title={CAREGIVERS_TITLE}
      onAdd={onAdd}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={CAREGIVERS_SEARCH_INPUT_NAME}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<Caregiver>
          columns={CaregiverListTableColumns}
          rows={caregivers}
          placeholder={CAREGIVERS_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}

      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentLeftAlign}>
            <div
              className={stylesModal.modalWithVerticalBar}
              style={{ marginTop: getHeight('Caregivers') }}
            >
              <AddCaregiverForm
                toggle={modalClosed}
                selectCaregiver={selectCaregiver}
                facility={facility}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PanelList>
  );
};
