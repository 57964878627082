import React, { useEffect, useRef } from 'react';

import { useAuthState } from 'Auth';
import { Modal, Spinner } from 'components';

import styles from './CallWrapper.module.css';

const interfaceConfigOverwrite = {
  DEFAULT_BACKGROUND: '#f6f7fb',
  SHOW_JITSI_WATERMARK: false,
  SHOW_WATERMARK_FOR_GUESTS: false,
  DEFAULT_REMOTE_DISPLAY_NAME: 'TH User',
  DEFAULT_LOCAL_DISPLAY_NAME: 'me',
  TOOLBAR_BUTTONS: ['microphone', 'fullscreen', 'hangup']
};

const audioConfigOverwrite = {
  startAudioOnly: true
};

const defaultConfigOverwrite = { prejoinPageEnabled: false };

export const CallWrapper: React.FunctionComponent<{
  roomName: string;
  id: string;
  isOpen: boolean;
  isAudioCall: boolean;
  domain: string;
  token: string;
  onClose: () => void;
}> = ({ roomName, isOpen, onClose, id, isAudioCall, domain, token }) => {
  const callApi = useRef<any | null>(null);

  const { loggedUser } = useAuthState();

  const userInfo = {
    displayName: loggedUser ? loggedUser.name : 'TH User'
  };

  const configOverwrite = isAudioCall
    ? { ...defaultConfigOverwrite, ...audioConfigOverwrite }
    : defaultConfigOverwrite;

  useEffect(() => {
    if (isOpen && token) {
      const options = {
        roomName,
        parentNode: document.querySelector(`#${id}`),
        interfaceConfigOverwrite,
        userInfo,
        configOverwrite,
        jwt: token
      };
      if (!callApi.current) {
        callApi.current = new (window as any).JitsiMeetExternalAPI(
          domain,
          options
        );
      }
      const endMeeting = () => {
        onClose();
        callApi.current.dispose();
      };

      if (callApi.current) {
        callApi.current.addListener('readyToClose', endMeeting);
      }

      return () => {
        if (callApi.current) {
          callApi.current.removeListener('readyToClose', endMeeting);
        }
      };
    }
  }, [
    id,
    isOpen,
    onClose,
    roomName,
    isAudioCall,
    configOverwrite,
    userInfo,
    domain,
    token
  ]);

  return (
    <Modal isShowing={isOpen} toggle={onClose}>
      <div className={styles.iframeContainer} id={id} />
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    </Modal>
  );
};
