import { MAX_PAGE_SIZE } from 'consts';
import { ResidentListResponse } from 'Settings';
import { axiosWrapper, sortByKey } from 'utils';

export const getResidentsByWard = (
  facilityId: string,
  wardId: string,
  isActive: boolean
) => async () => {
  const response = await axiosWrapper<ResidentListResponse>(
    `/facilities/${facilityId}/residents?ward_id=${wardId}&size=${MAX_PAGE_SIZE}&is_active=${isActive}`
  );
  const sortedResidents = sortByKey(response.data.items, 'name');

  return { items: sortedResidents };
};
