import React, { useRef, useState } from 'react';

import { Modal } from 'components';
import { useModal } from 'hooks';
import {
  DailySteps,
  getLastWeekHistoryFilters,
  getThisMonthHistoryFilters,
  getThisWeekHistoryFilters,
  HistoryLabel
} from 'Residents';

import { DailyStepsCard } from './Cards';
import { DailyStepsHistory } from './History';
import { AddActivityReading } from './History/AddReadings/AddActivityReading';
import { DailyStepsReadingForm } from './History/AddReadings/DailyStepsReadingForm';

import style from 'Residents/History/History.module.css';

export const DailyStepsDetails: React.FunctionComponent<{
  dailySteps: DailySteps;
  facilityId: string;
  residentId: string;
  onReadingAdded: () => void;
}> = ({ dailySteps, facilityId, residentId, onReadingAdded }) => {
  const [historyFilters, setHistoryFilters] = useState(
    getThisWeekHistoryFilters()
  );
  const hasNewReading = useRef(false);
  const { isShowing: isHistoryOpen, toggle: toggleHistoryView } = useModal();
  const { isShowing: isAddFormOpen, toggle: toggleAddReading } = useModal();

  const onAddReading = () => {
    toggleAddReading();
    hasNewReading.current = true;
    // update history filters to This Week
    setHistoryFilters(getThisWeekHistoryFilters());
  };

  const onCloseHistoryView = () => {
    if (hasNewReading.current) {
      // let parent know to refetch on add
      onReadingAdded();
    }
    setHistoryFilters(getThisWeekHistoryFilters());
    toggleHistoryView();
  };

  const changeHistoryFilter = (historyLabel: HistoryLabel) => {
    switch (historyLabel) {
      case 'This Week':
        setHistoryFilters(getThisWeekHistoryFilters());
        break;
      case 'Last Week':
        setHistoryFilters(getLastWeekHistoryFilters());
        break;
      case 'This Month':
        setHistoryFilters(getThisMonthHistoryFilters());
        break;
    }
  };

  const onAdd = () => {
    if (!isAddFormOpen) {
      toggleAddReading();
    }
  };

  return (
    <>
      <DailyStepsCard onClick={toggleHistoryView} dailySteps={dailySteps} />
      <Modal isShowing={isHistoryOpen} toggle={toggleHistoryView}>
        <div className={style.modalBackdrop} id="HistoryModal">
          <div className={style.modalContent}>
            <DailyStepsHistory
              onClose={onCloseHistoryView}
              onAdd={onAdd}
              historyFilters={historyFilters}
              facilityId={facilityId}
              residentId={residentId}
              changeHistoryFilter={changeHistoryFilter}
            />
          </div>
        </div>
      </Modal>
      <AddActivityReading
        isOpen={isAddFormOpen}
        onCancel={toggleAddReading}
        onSave={onAddReading}
        facilityId={facilityId}
        residentId={residentId}
        FormComponent={DailyStepsReadingForm}
      />
    </>
  );
};
