import React from 'react';

import {
  BloodSugar,
  formatLogDate,
  HistoryLogsList,
  Measurement
} from 'Residents';

import { HistoryLogEntry } from './HistoryLogEntry';

import styles from 'Residents/History/History.module.css';

export const BloodSugarHistoryLogs: React.FunctionComponent<{
  historyLogs: BloodSugar[];
}> = ({ historyLogs }) => {
  const logEntries = historyLogs.map(log => {
    const { measuredAt, code, value, unit } = log;
    const { timestamp, date } = formatLogDate(measuredAt);

    return (
      <HistoryLogEntry
        key={log.measuredAt}
        timestamp={timestamp}
        date={date}
        code={code}
      >
        <Measurement value={value.bloodSugar} unit={unit} />
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList>{logEntries}</HistoryLogsList>
  ) : (
    <p className={styles.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
