import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import { useAuthContext } from 'Auth';
import { LoadingPlaceholder } from 'components';
import { usePoster, useQueryParams, useSubmitError } from 'hooks';

import { resetPassword } from './actions';
import { LoginInput } from './LoginInput';
import { LoginTemplate } from './LoginTemplate';

import styles from './PasswordReset.module.css';

interface PasswordResetFormData {
  password: string;
  confirmPassword: string;
}

export const PasswordReset: React.FunctionComponent<RouteComponentProps> = ({
  history,
  location
}) => {
  const [
    { authenticationError, loading: authLoading, isAuthenticated },
    { dispatch }
  ] = useAuthContext();
  const { register, errors, triggerValidation, watch, getValues } = useForm<
    PasswordResetFormData
  >({
    mode: 'onBlur',
    submitFocusError: false
  });
  const {
    setAction,
    loading: onSaveLoading,
    error,
    data: status
  } = usePoster();
  const { setSubmitted } = useSubmitError(error, onSaveLoading);
  const query = useQueryParams();
  const passwordResetId = query.get('passwordResetId') || '';

  useEffect(() => {
    if (!passwordResetId || status === 204) {
      history.replace('/login');
    }
  }, [passwordResetId, status, history]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const { password, confirmPassword } = getValues();
      setAction(resetPassword(passwordResetId, password, confirmPassword));
      setTimeout(() => {
        setSubmitted(true);
      });
    }
  };

  const onChange = () => {
    triggerValidation();
    if (authenticationError) {
      dispatch({ type: 'SET_AUTH_ERROR', payload: null });
    }
  };

  const hasValidationErrors = Object.keys(errors).length > 0;
  const passwordsMismatchError =
    errors.confirmPassword && errors.confirmPassword.type === 'validate'
      ? 'please make sure the passwords match.'
      : undefined;
  const errorMessage = error ? error.message : undefined;
  const feedbackMessage = passwordsMismatchError || errorMessage;
  const loading = onSaveLoading && authLoading;

  return (
    <LoginTemplate isAuthenticated={isAuthenticated} location={location}>
      <h1 className={styles.title}>hello,</h1>
      <h1 className={styles.details}>please enter a new password</h1>
      <form onSubmit={onSubmit} autoComplete="off">
        <LoginInput
          name="password"
          placeholder="password"
          requiredValueMissing={!!errors.password || !!passwordsMismatchError}
          type="password"
          forwardedRef={register({ required: true })}
          onChange={onChange}
        />
        <LoginInput
          name="confirmPassword"
          placeholder="confirm password"
          requiredValueMissing={!!errors.confirmPassword}
          type="password"
          feedbackMessage={feedbackMessage}
          forwardedRef={register({
            required: true,
            validate: (value: string) =>
              value && value !== watch('password')
                ? "New password and confirmation password fields don't match."
                : undefined
          })}
          onChange={onChange}
        />
        {loading ? (
          <div className={styles.loading}>
            <LoadingPlaceholder />
          </div>
        ) : (
          <button
            className={styles.confirm}
            disabled={hasValidationErrors}
            onClick={onSubmit}
          >
            create your password
          </button>
        )}
      </form>
    </LoginTemplate>
  );
};
