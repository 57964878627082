import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';

import { useLocationState } from 'Location';
import { Caregiver, getCaregiversByFacility } from 'Settings';
import { FacilityId } from 'types';

enum FacilityCaregiversActionTypes {
  SET_FACILITY_CAREGIVERS = 'SET_FACILITY_CAREGIVERS',
  ADD_FACILITY_CAREGIVER = 'ADD_FACILITY_CAREGIVER',
  ADD_FACILITY_CAREGIVERS_BATCH = 'ADD_FACILITY_CAREGIVERS_BATCH'
}

type FacilityCaregiversAction =
  | {
      type: FacilityCaregiversActionTypes.SET_FACILITY_CAREGIVERS;
      payload: Caregiver[];
    }
  | {
      type: FacilityCaregiversActionTypes.ADD_FACILITY_CAREGIVERS_BATCH;
      payload: Caregiver[];
    }
  | {
      type: FacilityCaregiversActionTypes.ADD_FACILITY_CAREGIVER;
      payload: Caregiver;
    };

const initialDispatch: React.Dispatch<FacilityCaregiversAction> = () =>
  undefined;

const FacilityCaregiversContext = createContext<Caregiver[]>([]);
const FacilityCaregiversDispatch = createContext<typeof initialDispatch>(
  initialDispatch
);

const asyncSetter = async (
  facilityId: FacilityId,
  setter: React.Dispatch<React.SetStateAction<Caregiver[]>>
) => {
  const getFunction = getCaregiversByFacility(facilityId);
  const { items } = await getFunction();

  setter(items);
};

const caregiversReducer: React.Reducer<
  Caregiver[],
  FacilityCaregiversAction
> = (state, action) => {
  switch (action.type) {
    case FacilityCaregiversActionTypes.ADD_FACILITY_CAREGIVER:
      return [action.payload, ...state];
    case FacilityCaregiversActionTypes.ADD_FACILITY_CAREGIVERS_BATCH:
      return [...action.payload, ...state];
    case FacilityCaregiversActionTypes.SET_FACILITY_CAREGIVERS:
      return action.payload;
    default:
      return state;
  }
};

export const FacilityCaregiversProvider: React.FunctionComponent = ({
  children
}) => {
  const [state, dispatch] = useReducer(caregiversReducer, []);
  const [localCaregivers, setLocalCaregivers] = useState<Caregiver[]>([]);
  const [loadingCaregivers, setLoadingCaregivers] = useState<boolean>(false);
  const [caregiversError, setCaregiversError] = useState<Error | null>(null);

  const {
    facility: { id: facilityId }
  } = useLocationState();

  useEffect(() => {
    if (!facilityId) {
      return undefined;
    }
    dispatch({
      type: FacilityCaregiversActionTypes.SET_FACILITY_CAREGIVERS,
      payload: []
    });
    setLoadingCaregivers(true);

    try {
      asyncSetter(facilityId, setLocalCaregivers);
    } catch (error) {
      setCaregiversError(error as Error);
    } finally {
      setLoadingCaregivers(false);
    }
  }, [facilityId]);

  useEffect(() => {
    if (!caregiversError && !loadingCaregivers) {
      dispatch({
        type: FacilityCaregiversActionTypes.ADD_FACILITY_CAREGIVERS_BATCH,
        payload: localCaregivers
      });
    }
  }, [localCaregivers, caregiversError, loadingCaregivers]);

  return (
    <FacilityCaregiversContext.Provider value={state}>
      <FacilityCaregiversDispatch.Provider value={dispatch}>
        {children}
      </FacilityCaregiversDispatch.Provider>
    </FacilityCaregiversContext.Provider>
  );
};

export const useFacilityCaregiversState = () => {
  const state = useContext(FacilityCaregiversContext);
  if (state === undefined) {
    throw new Error(
      'useFacilityCaregiversState must be used within a FacilityCaregiversProvider'
    );
  }

  return state;
};

export const useFacilityCaregiversDispatch = () => {
  const dispatch = useContext(FacilityCaregiversDispatch);
  if (dispatch === undefined) {
    throw new Error(
      'useFacilityCaregiversDispatch must be used within a FacilityCaregiversProvider'
    );
  }

  return dispatch;
};

export const useFacilityCaregiversContext = (): {
  facilityCaregivers: Caregiver[];
  facilityCaregiversDispatch: React.Dispatch<FacilityCaregiversAction>;
} => {
  return {
    facilityCaregivers: useFacilityCaregiversState(),
    facilityCaregiversDispatch: useFacilityCaregiversDispatch()
  };
};
