import { axiosWrapper } from 'utils';

export const unassignCaregiverFromWard = (body: {
  wardId: string;
  caregiverId: string;
  facilityId: string;
  workingDay: string;
}) => async () => {
  const response = await axiosWrapper(
    `/ward-assignments`,
    'DELETE',
    null,
    body
  );

  return response.data;
};
