export enum HomeCssClasses {
  REGULAR = 'regular',
  ALARMS_ONLY = 'alarmsOnly'
}

export enum PriorityCssClasses {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low'
}
