import moment from 'moment';
import React from 'react';
import useForm from 'react-hook-form';

import { AddReadingForm, ReadingInput } from 'Residents';

interface BloodSugarReadingFormData {
  bloodSugar: string;
}

export const BloodSugarReadingForm: React.FunctionComponent<{
  isOpen: boolean;
  onSave: (formData: any) => void;
  onCancel: () => void;
  loading: boolean;
}> = ({ isOpen, onSave, onCancel, loading, children }) => {
  const { register, errors, getValues, triggerValidation } = useForm<
    BloodSugarReadingFormData
  >({
    mode: 'onBlur',
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const { bloodSugar } = getValues();
      const measuredAt = moment().toISOString(true);

      onSave({
        bloodSugar: parseInt(bloodSugar, 10),
        measuredAt,
        type: 'BLOOD_SUGAR'
      });
    }
  };

  return (
    <AddReadingForm
      isOpen={isOpen}
      onSave={handleSubmit}
      onCancel={onCancel}
      title="Add Blood Sugar Reading"
      savingDisabled={loading}
    >
      <ReadingInput
        name="bloodSugar"
        label="blood sugar"
        register={register}
        hasError={!!errors.bloodSugar}
        unit="(mg/dL)"
        validationRules={{ required: true, pattern: /^([0]|[1-9]+[0-9]*)$/ }}
      />
      {children}
    </AddReadingForm>
  );
};
