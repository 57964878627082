import { axiosWrapper } from 'utils';

import { Facility } from 'Settings/types';

export const editFacility = (facility: Facility) => async () => {
  const response = await axiosWrapper(
    `/facilities/${facility.id}`,
    'PUT',
    null,
    facility
  );
  return response.data;
};
