import { BasicVital, VitalType } from 'Residents';
import { axiosWrapper } from 'utils';

export const getVitalsHistory = (
  facilityId: string,
  residentId: string,
  type: VitalType,
  startDate: string,
  endDate: string
) => async () => {
  const intervalParams = new URLSearchParams({
    start: startDate,
    end: endDate
  });
  const response = await axiosWrapper<BasicVital[]>(
    `/facilities/${facilityId}/residents/${residentId}/vitals/${type}?${intervalParams}`
  );

  return response.data;
};
