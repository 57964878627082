import React from 'react';

import { ReactComponent as SadFace } from 'icons/sad-face.svg';
import { convertTimestamp, PainLevel } from 'Residents';

import { VitalCard } from './VitalCard';

import style from './PainLevelVitalCard.module.css';

export const PainLevelVitalCard: React.FunctionComponent<{
  painLevel: PainLevel | undefined;
  onClick: () => void;
}> = ({ painLevel, onClick }) => {
  const { measuredAt, value, code } = painLevel || {};
  const hasWarning = !!code && (code === 'ORANGE' || code === 'RED');

  return (
    <div className={style.painLevel} onClick={onClick}>
      <VitalCard
        title={'Pain Level'}
        reading={value && value.painLevel}
        timestamp={measuredAt && convertTimestamp(measuredAt)}
        hasWarning={hasWarning}
      >
        <SadFace />
      </VitalCard>
    </div>
  );
};
