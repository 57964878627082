import React, { useCallback, useEffect, useMemo } from 'react';

import { useAuthState } from 'Auth';
import {
  ChatRoom,
  ConversationTypes,
  getChatRooms,
  useMissedCallsState
} from 'Communication';
import { DetailsPlaceholder, FetchError, LoadingPlaceholder } from 'components';
import { useFetcher } from 'hooks';
import { DeviceLocation, useLocationState } from 'Location';
import { Caregiver, getCaregiverDetails } from 'Settings';
import { getCallCount } from 'utils';

import { getCaregiverStatus } from './actions';
import { StaffActionTypes, useCaregiverContext } from './caregiver.context';
import { CaregiverDetailsPanel } from './CaregiverDetailsPanel';
import { CaregiverStatus, StatusType, StatusTypes } from './types';

import styles from './CaregiverDetailsPanel.module.css';

export const WithCaregiverDetails: React.FunctionComponent<{
  facilityId: string;
  caregiverId: string;
  wardId: string;
  onEditClick: () => void;
}> = ({ facilityId, caregiverId, wardId, onEditClick }) => {
  const [
    { caregiverDetails, shouldRefetchDetails },
    dispatch
  ] = useCaregiverContext();
  const { loggedUser, loginId } = useAuthState();
  const { devicesLocation, rtlsLoading, rtlsError } = useLocationState();
  const { state: missedCalls } = useMissedCallsState();

  const caregiverDetailsAction = useMemo(() => {
    if (caregiverId && facilityId) {
      return getCaregiverDetails(caregiverId, facilityId);
    }
  }, [caregiverId, facilityId]);

  const getStatusAction = useMemo(() => {
    if (caregiverId && facilityId && wardId) {
      return getCaregiverStatus(caregiverId, facilityId, wardId);
    }
  }, [caregiverId, facilityId, wardId]);

  const getChatRoomsAction = useMemo(() => {
    if (loggedUser && loggedUser.type === 'CAREGIVER') {
      return getChatRooms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, missedCalls]);

  const {
    data: caregiver,
    error: caregiverError,
    loading: caregiverLoading
  } = useFetcher<Caregiver>(caregiverDetailsAction, {} as Caregiver);

  const {
    data: statusData,
    error: statusError,
    loading: statusLoading,
    setRefetch: refetchCaregiverStatus
  } = useFetcher<CaregiverStatus[]>(getStatusAction, []);

  const {
    data: chatRooms,
    loading: chatRoomLoading,
    error: chatRoomError
  } = useFetcher<ChatRoom[]>(getChatRoomsAction, []);

  const location = useMemo(
    () =>
      devicesLocation.find(
        (entry: DeviceLocation) =>
          entry.caregiver && entry.caregiver.id === caregiver.id && entry.room
      ),
    [caregiver, devicesLocation]
  );

  const status = useMemo(() => {
    const wardStatus = statusData.find(({ wardId: id }) => wardId === id);
    return wardStatus ? wardStatus.status : StatusTypes.OFF_DUTY;
  }, [statusData, wardId]);

  const onCaregiverFetch = useCallback(
    (
      caregiverFetched: Caregiver,
      locationFetched: DeviceLocation | undefined,
      caregiverStatus: StatusType
    ) => {
      dispatch({
        type: StaffActionTypes.UPDATE_CAREGIVER,
        payload: {
          ...caregiverFetched,
          location: locationFetched
            ? {
                ward: locationFetched.room.ward.name,
                room: locationFetched.room.number
              }
            : undefined,
          status: caregiverStatus,
          relationship: undefined
        }
      });
    },
    [dispatch]
  );

  const fetchError =
    caregiverError || rtlsError || statusError || chatRoomError;
  const loading =
    caregiverLoading || rtlsLoading || statusLoading || chatRoomLoading;

  useEffect(() => {
    if (!caregiverId) {
      dispatch({ type: StaffActionTypes.RESET_CAREGIVER });
    }
  }, [caregiverId, dispatch]);

  useEffect(() => {
    if (loading) {
      return;
    }
    onCaregiverFetch(caregiver, location, status);
  }, [caregiver, onCaregiverFetch, location, status, loading]);

  useEffect(() => {
    if (shouldRefetchDetails) {
      refetchCaregiverStatus();
    }
  }, [refetchCaregiverStatus, shouldRefetchDetails]);

  const activeChat = chatRooms.find(
    entry =>
      entry.participants.some(e => e === loginId) &&
      entry.participants.some(e => e === caregiver.loginId) &&
      entry.chatRoomType.toLowerCase() === ConversationTypes.CAREGIVER
  );

  const callCount = getCallCount(activeChat?.id, missedCalls);

  return (
    <>
      {caregiverId ? (
        <section
          className={styles.caregiverDetailsWrapper}
          id="Caregiver Details"
        >
          {!loading && !fetchError && (
            <CaregiverDetailsPanel
              onEditClick={onEditClick}
              activeChat={activeChat || ({} as ChatRoom)}
              caregiver={caregiverDetails}
              callCount={callCount}
            />
          )}
          {loading && !fetchError && <LoadingPlaceholder fullHeight={false} />}
          {fetchError && !loading && <FetchError error={fetchError} />}
        </section>
      ) : (
        <div className={styles.detailsPlaceholderWrapper}>
          <DetailsPlaceholder
            message="No caregiver information available"
            title="Caregiver Details"
          />
        </div>
      )}
    </>
  );
};
