import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { DeviceListResponse } from '../types/device.type';

export const getDevicesByFacility = (facilityId: string) => async () => {
  const response = await axiosWrapper<DeviceListResponse>(
    `/facilities/${facilityId}/devices?size=${MAX_PAGE_SIZE}`
  );
  return response.data.items;
};

export const getDevicesByQuery = async (
  facilityId: string,
  searchQuery: string,
  page: number
) => {
  const response = await axiosWrapper<DeviceListResponse>(
    `/facilities/${facilityId}/devices?deviceSearchQuery=${searchQuery}&page=${page}`
  );
  return response.data;
};
