import classnames from 'classnames';
import React, { useMemo } from 'react';

import { caregiverStatusMap, StatusType } from 'Caregivers';
import { ImageUpload, ProfilePlaceholder, RoundCrossButton } from 'components';
import { formatName } from 'utils';

import styles from './ProfileHeader.module.css';

export const ProfileHeader: React.FunctionComponent<{
  name: string;
  title: string;
  status: StatusType | undefined;
  isEditOn?: boolean;
  toggleEditMode?: () => void;
  disabled?: boolean;
  photoUrl?: string;
  onAvatarChange?: (image: File | Blob, filename: string) => void;
}> = ({
  name,
  title,
  status,
  onAvatarChange = () => null,
  isEditOn = false,
  toggleEditMode = () => null,
  disabled = false,
  photoUrl
}) => {
  const type = status ? caregiverStatusMap[status] : status;
  const formattedName = useMemo(() => formatName(name), [name]);

  return (
    <div className={styles.basicInfo}>
      {isEditOn ? (
        <ImageUpload
          name={name}
          onImageChange={onAvatarChange}
          photoUrl={photoUrl}
          size={64}
          profileType={type}
          parentId="ManageUserAccount"
        />
      ) : (
        <ProfilePlaceholder
          name={name}
          type={type}
          size={64}
          photoUrl={photoUrl}
        />
      )}
      <div className={styles.userDetails}>
        <span className={styles.name}>{formattedName}</span>
        <span className={styles.label}>{title}</span>
        <button
          className={classnames(styles.accountBtn, {
            [styles.hidden]: isEditOn
          })}
          disabled={disabled}
          onClick={toggleEditMode}
        >
          Manage your account
        </button>
      </div>

      {isEditOn && (
        <div className={styles.buttonWrapper}>
          <RoundCrossButton onClick={toggleEditMode} />
        </div>
      )}
    </div>
  );
};
