import React, { SyntheticEvent, useMemo, useState } from 'react';
import Select from 'react-select';

import { DropdownOption } from 'components/types';
import { isEmpty, sortByKey } from 'utils';

import { getDefaultStyles } from './AutocompleteSelect.styles';

const processValue = (selectValue: any) => {
  if (isEmpty(selectValue)) {
    return selectValue;
  }
  if (Array.isArray(selectValue)) {
    return selectValue.map(({ value: val }) => val);
  }
  return selectValue.value;
};

const getSelectedValue = (val: any, options: DropdownOption[]) => {
  if (val === null) {
    return val;
  }
  if (Array.isArray(val)) {
    return options.reduce((selectedOptions: any[], current) => {
      if (val.includes(current.value)) {
        return [...selectedOptions, current];
      }
      return selectedOptions;
    }, []);
  }
  const selected = options.find(option => option.value === val);
  return isEmpty(selected) ? null : selected;
};

const mergeStyles = (defaults: any, custom: any) => {
  const combinedKeys = Object.keys({ ...defaults, ...custom });
  return combinedKeys.reduce((combinedStyles, currentKey) => {
    const defaultStyleObject = defaults[currentKey]
      ? defaults[currentKey]
      : () => ({});
    const customStyleObject = custom[currentKey] ? custom[currentKey] : {};

    return {
      ...combinedStyles,
      [currentKey]: (
        provided: any,
        state: {
          data: { color: string };
          isDisabled: boolean;
          isFocused: boolean;
          isSelected: boolean;
        }
      ) => {
        const customCSSProperties =
          typeof customStyleObject === 'function'
            ? customStyleObject(provided, state)
            : customStyleObject;

        return {
          ...provided,
          ...defaultStyleObject(provided, state),
          ...customCSSProperties
        };
      }
    };
  }, {});
};

const simulateClick = (randomId: string) => () => {
  let event;
  if (document.createEvent) {
    // IE11 compatibility
    event = document.createEvent('MouseEvent');
    event.initMouseEvent(
      'mousedown',
      true,
      true,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
  } else {
    event = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true
    });
  }
  const select = document.getElementById(randomId);
  if (select) {
    select.dispatchEvent(event);
    event.preventDefault();
  }
};

export const AutocompleteSelect: React.FunctionComponent<{
  options: DropdownOption[];
  onChange: (selected: any) => void;
  onBlur?: (e: React.SyntheticEvent) => void;
  onFocus?: () => void;
  isMulti?: boolean;
  hasError?: boolean;
  autocomplete?: boolean;
  value?: any;
  noOptionsMessage?: any;
  placeholder?: string;
  isClearable?: boolean;
  dropdownStyles?: {
    [key: string]: { [key: string]: string };
  };
  isDisabled?: boolean;
  sortField?: string;
}> = ({
  options,
  onChange,
  onBlur,
  onFocus,
  hasError,
  value,
  isMulti = false,
  autocomplete = true,
  noOptionsMessage = () => 'No Options',
  placeholder,
  isClearable = !isMulti,
  dropdownStyles = {},
  isDisabled = false,
  sortField = 'label'
}) => {
  const [randomId] = useState(`Select-${Math.random()}`);

  const handleChange = (selected: any) => {
    onChange(processValue(selected));
  };

  const onInputChange = (inputValue: any, actionObject: any) => {
    if (actionObject.action === 'input-change' && inputValue === '') {
      onChange(processValue(inputValue));
    }
  };

  const defaultStyles = useMemo(() => getDefaultStyles({ hasError, isMulti }), [
    hasError,
    isMulti
  ]);
  const mergedStyles = useMemo(
    () => mergeStyles(defaultStyles, dropdownStyles),
    [defaultStyles, dropdownStyles]
  );

  const handleBlur = (e: SyntheticEvent) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Select
      id={randomId}
      onMenuOpen={simulateClick(randomId)}
      styles={mergedStyles}
      options={sortByKey(options, sortField)}
      isMulti={isMulti}
      placeholder={placeholder || 'Select'}
      onChange={handleChange}
      onInputChange={onInputChange}
      onBlur={handleBlur}
      onFocus={onFocus}
      isClearable={isClearable}
      isSearchable={autocomplete}
      value={getSelectedValue(value, options)}
      backspaceRemovesValue={true}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled}
    />
  );
};
