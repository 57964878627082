import moment from 'moment';

import { emptyWeek, LastBathCount } from 'Residents';

const convertReadings = (day: string, readings: LastBathCount[]) => {
  const bathReadings = readings.find(
    entry => moment(entry.timestamp).format('ddd') === day
  );

  const nrOfBaths = Number(bathReadings?.value?.numberOfBathingActivities);

  return {
    baths: nrOfBaths,
    unit: nrOfBaths === 1 ? 'bath' : 'baths'
  };
};

export const toWeekData = (data: LastBathCount[]) =>
  emptyWeek.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
