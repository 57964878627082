import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { useAuthState } from 'Auth';
import { Switch, WithPermissions } from 'components';

import style from './UserFacilitiesAccessListEntry.module.css';

export const UserFacilitiesAccessListEntry: React.FunctionComponent<{
  facilityAccess: { name: string; id: string; hasAccess: boolean };
  handleToggle: (value: boolean, facilityId: string) => void;
  loading: boolean;
  selectedUser: { id: string };
}> = ({
  facilityAccess: { name, id, hasAccess: access },
  handleToggle,
  loading,
  selectedUser
}) => {
  const { loggedUser } = useAuthState();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    setHasAccess(access);
  }, [access]);

  const onToggle = (value: boolean) => {
    handleToggle(value, id);
    setHasAccess(value);
  };

  return (
    <li className={classnames(style.entry, { [style.loading]: loading })}>
      <span>{`${name}`}</span>
      <WithPermissions
        action="settings-users:manageFacilityAccess"
        data={{ loggedUser, selectedUser }}
        yes={<Switch isOn={hasAccess} onToggle={onToggle} id={id} />}
      />
    </li>
  );
};
