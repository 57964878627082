import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import {
  FetchError,
  LoadingPlaceholder,
  SearchHeader,
  Snackbar
} from 'components';
import { useFetcher, useModal } from 'hooks';
import { useLocationState } from 'Location';
import { Caregiver } from 'Settings';

import { useCaregiversStatus } from 'Caregivers';

import { getCaregiversByWard } from './actions';
import { StaffActionTypes, useCaregiverContext } from './caregiver.context';
import { StaffListEntry } from './StaffListEntry';
import { StaffListHeader } from './StaffListHeader';
import { StaffMember, StaffMemberDetail, StatusType } from './types';
import { getStaffMembers } from './utils';

import style from './StaffList.module.css';

export const StaffList: React.FunctionComponent<{
  selectedStaffId: string;
  selectStaffId: (value: string | undefined) => void;
  removeSelection: () => void;
}> = ({ selectedStaffId, selectStaffId, removeSelection }) => {
  const [staffList, setStaffList] = useState([] as StaffMemberDetail[]);
  const [searchValue, setSearchValue] = useState('');
  const [isStatusSnackbarShowing, setIsStatusSnackbarShowing] = useState(false);

  const {
    facility,
    ward: wardId,
    devicesLocation,
    rtlsError,
    rtlsLoading
  } = useLocationState();
  const [{ caregiverDetails }, dispatch] = useCaregiverContext();
  const { isShowing: isSearching, toggle: toggleSearch } = useModal();
  const {
    data: caregiversStatusMap,
    loading: statusLoading,
    error: statusError,
    changeCaregiverStatus,
    statusChangeError,
    refetchStatus
  } = useCaregiversStatus();

  const caregiversByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getCaregiversByWard(facility.id, wardId);
    }
  }, [facility.id, wardId]);

  const {
    data: caregiversByWard,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<Caregiver[]>(caregiversByWardAction, []);

  const fetchError = caregiversError || rtlsError || statusError;
  const loading = caregiversLoading || rtlsLoading || statusLoading;

  useEffect(() => {
    const list = getStaffMembers(
      caregiversByWard,
      devicesLocation,
      caregiversStatusMap
    );
    setStaffList(list);
  }, [caregiversByWard, caregiversStatusMap, devicesLocation]);

  useEffect(() => {
    setStaffList(currentStaffList => {
      return currentStaffList.map(member => {
        const caregiver =
          member.id === caregiverDetails.id ? caregiverDetails : member;
        return { ...caregiver };
      });
    });
  }, [caregiverDetails]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (selectedStaffId === '' && staffList.length > 0) {
      selectStaffId(staffList[0].id);
    }
    if (selectedStaffId) {
      const correspondingStaff = staffList.find(
        ({ id }) => id === selectedStaffId
      );
      if (!correspondingStaff) {
        removeSelection();
      }
    }
  }, [selectedStaffId, staffList, selectStaffId, removeSelection, loading]);

  useEffect(() => {
    if (statusChangeError) {
      setIsStatusSnackbarShowing(true);
    }
  }, [statusChangeError]);

  const onSelect = (value: string | undefined) => {
    selectStaffId(value);
  };

  const onCaregiverStatusChange = (
    caregiverId: string,
    newStatus: StatusType
  ) => {
    if (caregiverId === selectedStaffId) {
      dispatch({
        type: StaffActionTypes.UPDATE_CAREGIVER,
        payload: { ...caregiverDetails, status: newStatus }
      });
    }
    changeCaregiverStatus(caregiverId, newStatus);
  };

  const dismissStatusError = () => {
    dispatch({ type: StaffActionTypes.REFETCH_CAREGIVER });

    refetchStatus();
    setIsStatusSnackbarShowing(false);
  };

  const filteredStaff = useMemo(() => {
    if (searchValue.length < 3 || !isSearching) {
      return staffList;
    }
    return staffList.filter(
      ({ name, role }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()) ||
        (role as string).toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [staffList, searchValue, isSearching]);

  const content = filteredStaff.map((staffMember: StaffMember) => (
    <StaffListEntry
      key={staffMember.id}
      staffMember={staffMember}
      isSelected={staffMember.id === selectedStaffId}
      onSelect={onSelect}
      onStatusChange={onCaregiverStatusChange}
    />
  ));

  const staffListContent = filteredStaff.length ? (
    <ul>{content}</ul>
  ) : (
    <p className={style.listPlaceholder}>
      {isSearching
        ? 'The search value returned no results.'
        : 'No caregivers for this ward.'}
    </p>
  );

  return (
    <div className={classnames(style.list, { [style.hasError]: !!fetchError })}>
      {!fetchError && (
        <>
          {isSearching ? (
            <SearchHeader
              searchInputName="caregivers"
              onClose={toggleSearch}
              searchValue={searchValue}
              onSearch={setSearchValue}
            />
          ) : (
            <StaffListHeader toggleSearch={toggleSearch} />
          )}
        </>
      )}
      {loading && !fetchError && <LoadingPlaceholder />}
      {!loading && !fetchError && staffListContent}
      {fetchError && !loading && <FetchError error={fetchError} />}
      {statusChangeError && (
        <Snackbar
          isOpen={isStatusSnackbarShowing}
          onClose={dismissStatusError}
          message="An error occurred while changing status. Please reload to see latest changes."
        />
      )}
    </div>
  );
};
