import React from 'react';

import styles from './StatusChangeError.module.css';

export const StatusChangeError: React.FunctionComponent<{
  onDismiss: () => void;
}> = ({ onDismiss }) => (
  <div className={styles.errorContainer}>
    <h5 className={styles.message}>
      An error occurred while changing status. Please reload to see latest
      changes.
    </h5>
    <button className={styles.button} onClick={onDismiss}>
      Reload
    </button>
  </div>
);
