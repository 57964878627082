import { CaregiverStatus, StatusType } from 'Caregivers';

export const createCaregiverStatusDTO = (data: {
  status: StatusType;
  wardId: string;
  allWards: boolean;
  breakDuration?: number;
  facilityId: string;
  caregiverId: string;
}) => {
  const { breakDuration, allWards, wardId, ...other } = data;
  const caregiverStatus: Partial<CaregiverStatus> = { ...other };
  if (!allWards) {
    caregiverStatus.wardId = wardId;
  }
  if (breakDuration) {
    caregiverStatus.breakDuration = breakDuration;
  }

  return caregiverStatus;
};
