import { ConversationType } from 'Communication';
import { LoginId } from 'types';

export type RtlsUpdate = {
  assetTracker: {
    description: string | null;
    facilityId: string | null;
    id: string | null;
    mac: string | null;
    name: string | null;
    uniqueIdentifier: string | null;
  } | null;
  caregiver: {
    facilityId: string | null;
    id: string | null;
    name: string | null;
    role: string | null;
    userId: string | null;
  } | null;
  id: string | null;
  isActive: true | null;
  name: string | null;
  resident: {
    birthday: string | null;
    facilityId: string | null;
    gender: string | null;
    id: string | null;
    isActive: boolean | null;
    name: string | null;
    number: string | null;
    roomId: string | null;
  } | null;
  room: {
    id: string | null;
    number: string | null;
    ward: {
      facilityId: string | null;
      id: string | null;
      name: string | null;
    } | null;
  } | null;
  timestamp: string | null;
  type: string | null;
} | null;

export interface IMessageView {
  chatRoomId: string;
  chatRoomType: ConversationType;
  id: string;
  loginSenderId: string;
  messageType: MessageViewType;
  receivers: string[];
  timestamp: string;
  values: {
    callId?: string;
    callType?: string;
    callerName?: string;
    callerRole?: string;
    callerStatus?: string;
    callerType?: string;
    callPassword?: string;
    callServerURL?: string;
    callDuration?: number;
    callDescription?: string;
    intendedResidentId?: string;
    messageThatWasSeen?: string;
    chatRoomName?: string;
    participantLoginId?: LoginId;
  };
}

export enum MessageViewTypes {
  CALL_INITIATION_MESSAGE = 'CALL_INITIATION_MESSAGE',
  CALL_MESSAGE = 'CALL_MESSAGE',
  MESSAGE_SEEN_MESSAGE = 'MESSAGE_SEEN_MESSAGE',
  CALL_ACCEPTED_MESSAGE = 'CALL_ACCEPTED_MESSAGE',
  CALL_DISMISS_MESSAGE = 'CALL_DISMISS_MESSAGE',
  CALL_STARTED_MESSAGE = 'CALL_STARTED_MESSAGE',
  JOIN_CALL_MESSAGE = 'JOIN_CALL_MESSAGE',
  LEAVE_CALL_MESSAGE = 'LEAVE_CALL_MESSAGE',
  CALL_ENDED_MESSAGE = 'CALL_ENDED_MESSAGE'
}

export type MessageViewType =
  | MessageViewTypes.CALL_ACCEPTED_MESSAGE
  | MessageViewTypes.CALL_DISMISS_MESSAGE
  | MessageViewTypes.CALL_ENDED_MESSAGE
  | MessageViewTypes.CALL_INITIATION_MESSAGE
  | MessageViewTypes.CALL_MESSAGE
  | MessageViewTypes.CALL_STARTED_MESSAGE
  | MessageViewTypes.JOIN_CALL_MESSAGE
  | MessageViewTypes.LEAVE_CALL_MESSAGE
  | MessageViewTypes.MESSAGE_SEEN_MESSAGE;
