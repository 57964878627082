import React from 'react';

import { CaregiverFacilityAccess } from '../types';

import styles from 'components/List/List.module.css';

export const FacilityAccessListItem: React.FunctionComponent<{
  values: CaregiverFacilityAccess;
  onSelect: (facilityId: string) => void;
  isSelected: boolean;
}> = ({ values, onSelect, isSelected }) => {
  const handleClick = () => {
    onSelect(values.facilityId);
  };
  const rowClass = isSelected
    ? `${styles.listItem} ${styles.selected}`
    : styles.listItem;

  return (
    <li onClick={handleClick} className={rowClass}>
      <span className={styles.listItemSpan}>{values.facilityName}</span>
      <span className={styles.listItemSpanSmall}>{values.wards}</span>
    </li>
  );
};
