import Croppie from 'croppie';
import React, { useEffect, useRef } from 'react';

import { RoundTrashButton } from 'components';

import 'croppie/croppie.css';
import styles from './ImagePreview.module.css';

export const ImagePreview: React.FunctionComponent<{
  imageUrl: string;
  onSaveImage: (image: File | Blob) => void;
  onRemoveImage: () => void;
}> = ({ imageUrl, onSaveImage, onRemoveImage }) => {
  const previewRef = useRef<any | null>(null);
  const imageCropperRef = useRef<Croppie | null>(null);

  useEffect(() => {
    if (previewRef.current && imageUrl) {
      imageCropperRef.current = new Croppie(previewRef.current, {
        viewport: { width: 200, height: 200, type: 'circle' },
        mouseWheelZoom: false
      });
      imageCropperRef.current.bind({ url: imageUrl });
    }
  }, [imageUrl]);

  const getCroppedImage = async () => {
    const cropper = imageCropperRef.current;
    if (cropper) {
      const resultingImage = await cropper.result({
        type: 'blob',
        circle: false
      });

      cropper.destroy();
      onSaveImage(resultingImage);
    }
  };

  const removeImage = () => {
    if (imageCropperRef.current) {
      imageCropperRef.current.destroy();
      onRemoveImage();
    }
  };

  return (
    <div className={styles.previewContainer}>
      <div className={styles.imagePreview} ref={previewRef}>
        <RoundTrashButton onClick={removeImage} />
      </div>
      <button className={styles.uploadButton} onClick={getCroppedImage}>
        Upload Photo
      </button>
    </div>
  );
};
