import React, { useEffect, useMemo, useState } from 'react';

import stylesForm from 'styles/form.module.css';
import roomStyles from './RoomDeviceInput.module.css';

import {
  DeviceFetchDropdown,
  RoundPlusButton,
  RoundTrashButton
} from 'components';
import { useFetcher } from 'hooks';
import { Device, Facility, getDeviceDetails, RoomDevice } from 'Settings';
import { getRoomDevices } from 'utils';

export const RoomDeviceInput: React.FunctionComponent<{
  facility: Facility;
  deviceIndex: number;
  name: string;
  register: (param: any) => void;
  label: string;
  error: { [key: string]: any } | undefined;
  setValue: (
    name: any,
    value: any,
    revalidate?: boolean
  ) => void | Promise<boolean>;
  watch: (name?: string) => void;
  isLast: boolean;
  onAdd: (index: number) => void;
  onDelete?: (inputIndex: number, inputName: any) => void;
  hasDeviceAccess: boolean;
  defaultValue?: RoomDevice;
  statusChange: (status: { error: boolean; loading: boolean }) => void;
  filterSelectedDevices?: any;
}> = ({
  facility,
  deviceIndex,
  name,
  label,
  register,
  isLast,
  error,
  setValue,
  watch,
  onAdd,
  onDelete,
  hasDeviceAccess = false,
  defaultValue,
  statusChange,
  filterSelectedDevices
}) => {
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue, true);
      setDeviceId(defaultValue.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const [deviceId, setDeviceId] = useState('');

  const onAddInput = () => {
    onAdd(deviceIndex + 1);
  };
  const onDeleteInput = () => {
    if (onDelete) {
      onDelete(deviceIndex, name);
    }
  };

  const buttonsContainerClass = !!onDelete ? roomStyles.withDelete : '';

  const changeHandler = (
    fieldName: string,
    formattedValue: any,
    revalidate: boolean | undefined
  ) => {
    setValue(name, formattedValue, revalidate);
    setDeviceId(formattedValue.id);
  };

  const getDeviceDetailsMemo = useMemo(
    () => getDeviceDetails(deviceId, facility.id),
    [deviceId, facility.id]
  );

  const { data } = useFetcher<Device>(getDeviceDetailsMemo, {} as Device);
  return (
    <>
      <DeviceFetchDropdown
        name={name}
        value={watch(name)}
        onChange={changeHandler}
        register={register}
        hasError={!!error}
        facility={facility}
        changeStatus={statusChange}
        label={`${label} ID`}
        cssClass={stylesForm.selectGroupQuarter}
        filterFunction={getRoomDevices}
        filterSelectedDevices={filterSelectedDevices}
        sortBy="mac"
        defaultValue={defaultValue}
      />
      {deviceId ? (
        <div className={stylesForm.inputGroupQuarter}>
          <label className={roomStyles.deviceTypeLabel}>
            <span className={roomStyles.collapse}>{`${label} Name`}</span>
            <span>:</span>
          </label>
          <span className={roomStyles.disabledText}>{data.name}</span>
        </div>
      ) : (
        <div className={stylesForm.inputGroupQuarter} />
      )}
      {deviceId && hasDeviceAccess ? (
        <div className={roomStyles.inputGroupUUID}>
          <label className={roomStyles.deviceTypeLabel}>
            <span
              className={roomStyles.collapse}
            >{`${label} Unique Identifier`}</span>
            <span>:</span>
          </label>
          <span className={roomStyles.disabledText}>
            {data.uniqueIdentifier}
          </span>
        </div>
      ) : (
        <div className={roomStyles.inputGroupUUID} />
      )}
      {!error && (
        <div
          className={`${roomStyles.deviceInputButtons} ${buttonsContainerClass}`}
        >
          {onDelete && (
            <RoundTrashButton onClick={onDeleteInput} small={true} />
          )}
          {isLast && <RoundPlusButton onClick={onAddInput} small={true} />}
        </div>
      )}
    </>
  );
};
