import React, { createContext, useContext, useReducer } from 'react';

import { StatusType } from 'Caregivers';
import { Caregiver } from 'Settings';

import { getCaregiversByStatus } from './schedule.utils';

type Action =
  | {
      type: 'SET_CAREGIVERS';
      payload: Caregiver[];
    }
  | {
      type: 'CLEAR_CAREGIVERS';
    }
  | {
      type: 'ADD_CAREGIVER';
      payload: Caregiver;
    }
  | {
      type: 'REMOVE_CAREGIVER';
      payload: string;
    }
  | {
      type: 'SET_CAREGIVERS_STATUS';
      payload: { [caregiverId: string]: StatusType };
    }
  | {
      type: 'SET_STATUS_LOADING';
      payload: boolean;
    }
  | {
      type: 'SET_STATUS_FETCH_ERROR';
      payload: Error | null;
    };
interface State {
  assignedCaregivers: Caregiver[];
  caregiversStatus: { [caregiverId: string]: StatusType };
  statusFetchError: Error | null;
  statusLoading: boolean;
}
type Dispatch = (action: Action) => void;

const ScheduleStateContext = createContext<State | undefined>(undefined);
const ScheduleDispatchContext = createContext<Dispatch | undefined>(undefined);

export const scheduleReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_CAREGIVERS': {
      const { available, unavailable, inactive } = getCaregiversByStatus(
        action.payload,
        state.caregiversStatus
      );
      return {
        ...state,
        assignedCaregivers: [...available, ...unavailable, ...inactive]
      };
    }
    case 'CLEAR_CAREGIVERS': {
      return {
        ...state,
        assignedCaregivers: []
      };
    }
    case 'ADD_CAREGIVER': {
      const { available, unavailable, inactive } = getCaregiversByStatus(
        [...state.assignedCaregivers, action.payload],
        state.caregiversStatus
      );

      return {
        ...state,
        assignedCaregivers: [...available, ...unavailable, ...inactive]
      };
    }
    case 'REMOVE_CAREGIVER': {
      return {
        ...state,
        assignedCaregivers: state.assignedCaregivers.filter(
          ({ id }) => id !== action.payload
        )
      };
    }
    case 'SET_CAREGIVERS_STATUS': {
      const { available, unavailable, inactive } = getCaregiversByStatus(
        state.assignedCaregivers,
        action.payload
      );

      return {
        ...state,
        caregiversStatus: action.payload,
        assignedCaregivers: [...available, ...unavailable, ...inactive]
      };
    }
    case 'SET_STATUS_LOADING': {
      return {
        ...state,
        statusLoading: action.payload
      };
    }
    case 'SET_STATUS_FETCH_ERROR': {
      return {
        ...state,
        statusError: action.payload
      };
    }
  }
};

export const ScheduleProvider: (props: {
  children: React.ReactNode;
}) => any = ({ children }) => {
  const [state, dispatch] = useReducer(scheduleReducer, {
    assignedCaregivers: [],
    caregiversStatus: {},
    statusLoading: false,
    statusFetchError: null
  });
  return (
    <ScheduleStateContext.Provider value={state}>
      <ScheduleDispatchContext.Provider value={dispatch}>
        {children}
      </ScheduleDispatchContext.Provider>
    </ScheduleStateContext.Provider>
  );
};

export const useScheduleState = () => {
  const context = useContext(ScheduleStateContext);
  if (context === undefined) {
    throw new Error('useScheduleState must be used within a ScheduleProvider');
  }
  return context;
};

export const useScheduleDispatch = () => {
  const context = useContext(ScheduleDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useScheduleDispatch must be used within a ScheduleProvider'
    );
  }
  return context;
};

export const useScheduleContext = (): [State, Dispatch] => {
  return [useScheduleState(), useScheduleDispatch()];
};
