import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  PanelList,
  Table,
  WithPermissions
} from 'components';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';

import { getFacilitiesByQuery, getPaginatedFacilities } from '../actions';
import {
  DEFAULT_PAGE,
  DEFAULT_SEARCH_TERM,
  FACILITY_LIST_PLACEHOLDER,
  FACILITY_SEARCH_INPUT_NAME,
  FACILITY_TITLE,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH
} from '../constants/index';
import { Facility } from '../types/facility.type';

import { AddFacility } from './AddFacility';
import { FacilityTableRow } from './FacilityTableRow';

const FacilityTableColumns: Column[] = [
  { name: 'Facility Name', align: 'left', cols: 3 },
  { name: 'Full Address', align: 'left', cols: 4 },
  { name: 'Wards', align: 'center', cols: 1 },
  { name: 'Status', align: 'center', cols: 1 }
];

export const FacilityList: React.FunctionComponent<{
  selectedFacility: Facility;
  selectFacility: (id: string) => void;
}> = ({ selectedFacility, selectFacility }) => {
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [searchedFacilitiesPage, setSearchedFacilitiesPage] = useState(
    DEFAULT_PAGE
  );
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getFacilitiesMemo = useMemo(() => getPaginatedFacilities(page), [page]);

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getFacilitiesByQuery(searchTerm, searchedFacilitiesPage);
    }
  }, [searchTerm, searchedFacilitiesPage]);

  const {
    data: { items: facilityList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Facility[];
    nextPage?: string;
  }>(getFacilitiesMemo, { items: [] });

  const {
    data: {
      items: searchedFacilities,
      nextPage: nextPageForSearchedFacilities
    },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{
    items: Facility[];
    nextPage?: string;
  }>(memoSearchAction, { items: [] });

  const { isShowing, toggle: toggleAdd } = useModal();

  useEffect(() => {
    if (facilityList.length !== 0) {
      setFacilities(prevState => [...prevState, ...facilityList]);
    }
  }, [facilityList]);

  useEffect(() => {
    if (searchError) {
      return setFacilities([]);
    }
    if (searchedFacilitiesPage > DEFAULT_PAGE) {
      setFacilities(prevState => [...prevState, ...searchedFacilities]);
    } else {
      setFacilities(searchedFacilities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedFacilities, searchError]);

  const onFacilityAdd = () => {
    setFacilities([]);
    if (page === DEFAULT_PAGE) {
      setRefetch();
    } else {
      setPage(DEFAULT_PAGE);
    }
  };

  const scrollAction = () => {
    if (
      !loading &&
      isFetching &&
      facilityList.length &&
      nextPage &&
      !isSearching
    ) {
      setPage(nextPage);
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedFacilities.length &&
      nextPageForSearchedFacilities &&
      isSearching
    ) {
      setSearchedFacilitiesPage(nextPageForSearchedFacilities);
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedFacilitiesPage(DEFAULT_PAGE);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setFacilities([]);
      setPage(DEFAULT_PAGE);
      setRefetch();
    }
  };

  const render = (rowValues: any) => (
    <FacilityTableRow
      key={rowValues.id}
      values={rowValues}
      selectFacility={selectFacility}
      isSelected={rowValues.id === selectedFacility.id}
    />
  );

  const isDataAvailable = facilities.length > 0;
  const content = (
    <>
      {!error && (!loading || isDataAvailable) && (
        <Table<Facility>
          columns={FacilityTableColumns}
          rows={facilities}
          placeholder={FACILITY_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}
      <WithPermissions
        action="settings-facilities:add"
        yes={
          <AddFacility
            onClose={toggleAdd}
            onFacilityAdd={onFacilityAdd}
            isOpen={isShowing}
            selectFacility={selectFacility}
          />
        }
      />
    </>
  );

  return (
    <WithPermissions
      action="settings-facilities:add"
      yes={
        <PanelList
          title={FACILITY_TITLE}
          onAdd={toggleAdd}
          hasError={!!error}
          onSearch={handleSearch}
          searchInputName={FACILITY_SEARCH_INPUT_NAME}
        >
          {content}
        </PanelList>
      }
      no={
        <PanelList
          title={FACILITY_TITLE}
          hasError={!!error}
          onSearch={handleSearch}
          searchInputName={FACILITY_SEARCH_INPUT_NAME}
        >
          {content}
        </PanelList>
      }
    />
  );
};
