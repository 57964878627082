import React from 'react';

import { BackToggleButton, RoundPlusButton } from 'components';

import style from './HistoryHeader.module.css';

export const HistoryHeader: React.FunctionComponent<{
  toggle: () => void;
  title: string;
  onAdd?: () => void;
}> = ({ toggle, title, onAdd }) => {
  const onClick = (e: any) => {
    e.preventDefault();
    toggle();
  };

  return (
    <header className={style.header}>
      <BackToggleButton title={title} onClick={onClick} />
      {onAdd && <RoundPlusButton onClick={onAdd} testId="add-test-button" />}
    </header>
  );
};
