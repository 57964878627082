import { Caregiver, CaregiverListResponse } from 'Settings';
import { axiosWrapper } from 'utils';

export const searchCaregiversByName = (
  searchValue: string,
  page: number
) => async () => {
  const initialUrl = `/caregivers?caregiverName=${searchValue}&size=30`;
  const url = page ? `${initialUrl}&page=${page}` : initialUrl;
  const response = await axiosWrapper<
    CaregiverListResponse | { items: Caregiver[]; nextPage: string }
  >(url);

  return response.data;
};
