import React, { useState } from 'react';

import { ReactComponent as CancelIcon } from 'icons/crossmark.svg';

import styles from './SearchContactHeader.module.css';

export const SearchContactHeader: React.FunctionComponent<{
  onCancel: () => void;
  searchValue: string;
  onSearch: (value: string) => void;
  disabled?: boolean;
}> = ({ onCancel, searchValue, onSearch, disabled = false }) => {
  const [localValue, setLocalValue] = useState<string>(searchValue);

  const onInputChange = (evt: any) => {
    const { value } = evt.target;
    setLocalValue(value);
    onSearch(value);
  };

  const onClick = (e: any) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <header className={styles.header}>
      <label htmlFor="contact" className={styles.label}>
        To:
      </label>
      <input
        className={styles.searchInput}
        name="contact"
        type="text"
        placeholder="Search for contact..."
        onChange={onInputChange}
        value={localValue}
        autoFocus={true}
        disabled={disabled}
      />
      <button className={styles.cancelButton} onClick={onClick}>
        <CancelIcon />
      </button>
    </header>
  );
};
