import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { AuthProvider } from 'Auth';
import { CommunicationProvider } from 'Communication';
import { LocationProvider } from 'Location';
import { NotificationProvider } from 'Notifications';
import { SocketProvider } from 'Socket';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';

declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}

ReactDOM.render(
  <AuthProvider>
    <LocationProvider>
      <SocketProvider>
        <NotificationProvider>
          <CommunicationProvider>
            <App />
          </CommunicationProvider>
        </NotificationProvider>
      </SocketProvider>
    </LocationProvider>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
