import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { IdnListResponse } from '../types/idn.type';

export const getIdns = async () => {
  const response = await axiosWrapper<IdnListResponse>(
    `/idns?size=${MAX_PAGE_SIZE}`
  );
  return response.data;
};

export const getIdnsByQuery = async (searchQuery: string, page: number) => {
  const response = await axiosWrapper<IdnListResponse>(
    `/idns/search?idnSearchQuery=${searchQuery}&page=${page}`
  );
  return response.data;
};
