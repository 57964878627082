import React from 'react';

import {
  FoodIntake,
  formatLogDate,
  HistoryLogsList,
  Measurement
} from 'Residents';

import { HistoryLogEntry } from './HistoryLogEntry';

import style from './HistoryLogs.module.css';

export const FoodIntakeHistoryLogs: React.FunctionComponent<{
  historyLogs: FoodIntake[];
  scrollRef?: any;
}> = ({ historyLogs, scrollRef }) => {
  const logEntries = historyLogs.map(log => {
    const { timestamp, value, unit } = log;
    const { timestamp: time, date } = formatLogDate(timestamp);
    const { count, foodType, method } = value;

    return (
      <HistoryLogEntry key={log.timestamp} timestamp={time} date={date}>
        <div className={style.additionalInfo}>
          <span className={style.topSpan}>{foodType}</span>
          {method && <span className={style.bottomSpan}>{method}</span>}
        </div>
        <Measurement value={count} unit={unit} />
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList scrollRef={scrollRef}>{logEntries}</HistoryLogsList>
  ) : (
    <p className={style.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
