import React, { useMemo } from 'react';

import { FetchError, LoadingPlaceholder, PanelHeader } from 'components';
import { useFetcher } from 'hooks';
import { DeviceLocation, useLocationState } from 'Location';

import { getLatestActivities } from './actions';
import {
  DailyStepsDetails,
  FoodIntakeDetails,
  LastBathDetails,
  LiquidIntakeDetails,
  LiquidOutputDetails,
  ResidentLocationDetails,
  SittingHoursDetails,
  SleepingHoursDetails,
  StaffVisitsDetails
} from './Activities';
import { Activities } from './Activities/constants';
import { useResidentState } from './resident.context';
import {
  ActivityInfoFeatures,
  ActivityType,
  BasicActivity,
  DailySteps,
  FoodIntake,
  LastBath,
  LiquidIntake,
  LiquidOutput,
  SittingHours,
  SleepingHours,
  StaffVisit
} from './types';

import style from './ActivityInformation.module.css';

export const ActivityInformation: React.FunctionComponent<{
  facilityId: string;
  residentId: string | null;
  activityInfo: ActivityInfoFeatures;
}> = ({ facilityId, residentId, activityInfo }) => {
  const { residentDetails } = useResidentState();
  const {
    devicesLocation,
    rtlsError,
    rtlsLoading,
    selectedWardDetails
  } = useLocationState();

  const latestActivitiesAction = useMemo(() => {
    if (facilityId && residentId) {
      return getLatestActivities(facilityId, residentId);
    }
  }, [facilityId, residentId]);

  const residentLocation = useMemo(
    () =>
      devicesLocation.find(
        (entry: DeviceLocation) =>
          entry.resident &&
          entry.resident.id === residentDetails.id &&
          entry.room
      ),
    [devicesLocation, residentDetails.id]
  );

  const {
    data: latestActivities,
    error: activitiesError,
    loading: activitiesLoading,
    setRefetch: refetchActivities
  } = useFetcher<BasicActivity[]>(latestActivitiesAction, []);

  const getActivity = (activityType: ActivityType) =>
    latestActivities.find(({ type }) => type === activityType);

  const onReadingAdded = () => {
    refetchActivities();
  };

  const error = activitiesError || rtlsError;
  const loading = activitiesLoading || rtlsLoading;

  return (
    <div className={style.residentActivities} id="Activities">
      {!error && <PanelHeader title="Activity Information" />}
      {loading && !error && <LoadingPlaceholder />}
      {error && !loading && <FetchError error={error} />}
      {!loading && !error && (
        <div className={style.cards}>
          {residentId ? (
            <>
              {selectedWardDetails?.services.rtls.features.rtlsPatient
                .isEnabled &&
                activityInfo.praiPatientLocation && (
                  <ResidentLocationDetails
                    residentId={residentId}
                    facilityId={facilityId}
                    residentLocation={residentLocation}
                  />
                )}
              {activityInfo.praiSteps && (
                <DailyStepsDetails
                  dailySteps={getActivity(Activities.WALKING) as DailySteps}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={onReadingAdded}
                />
              )}
              {activityInfo.praiSittingHours && (
                <SittingHoursDetails
                  sittingHours={
                    getActivity(Activities.LOUNGING) as SittingHours
                  }
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={onReadingAdded}
                />
              )}
              {activityInfo.praiSleepingHours && (
                <SleepingHoursDetails
                  sleepingHours={
                    getActivity(Activities.SLEEPING) as SleepingHours
                  }
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={onReadingAdded}
                />
              )}
              {activityInfo.praiBath && (
                <LastBathDetails
                  lastBath={getActivity(Activities.BATHING) as LastBath}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={onReadingAdded}
                />
              )}
              {selectedWardDetails?.services.rtls.features.rtlsStaff
                .isEnabled &&
                activityInfo.praiStaffVisits && (
                  <StaffVisitsDetails
                    staffVisits={getActivity(Activities.ROUNDING) as StaffVisit}
                    facilityId={facilityId}
                    residentId={residentId}
                  />
                )}
              {activityInfo.praiFluidIntake && (
                <LiquidIntakeDetails
                  liquidIntake={
                    getActivity(Activities.LIQUID_INTAKE) as LiquidIntake
                  }
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={onReadingAdded}
                />
              )}
              {activityInfo.praiFluidOutput && (
                <LiquidOutputDetails
                  liquidOutput={
                    getActivity(Activities.LIQUID_OUTTAKE) as LiquidOutput
                  }
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={onReadingAdded}
                />
              )}
              {activityInfo.praiFoodIntake && (
                <FoodIntakeDetails
                  foodIntake={getActivity(Activities.FOOD_INTAKE) as FoodIntake}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={onReadingAdded}
                />
              )}
            </>
          ) : (
            <p className={style.activitiesPlaceholder}>
              No activity information available
            </p>
          )}
        </div>
      )}
    </div>
  );
};
