import moment from 'moment';

import { BodyTemperature, emptyWeek } from 'Residents';

import { warningCodes } from '../consts/warningCodes.constant';

const convertReadings = (day: string, readings: BodyTemperature[]) => {
  const bodyTemperatureReadings = readings
    .filter(entry => moment(entry.measuredAt).format('ddd') === day)
    .map(entry => parseFloat(entry.value.bodyTemperature));

  const bodyTemperature =
    bodyTemperatureReadings.length > 0
      ? Math.round(
          (bodyTemperatureReadings.reduce((a, b) => a + b) /
            bodyTemperatureReadings.length) *
            10
        ) / 10
      : undefined;

  const hasWarning = warningCodes.some(code =>
    readings
      .filter(entry => moment(entry.measuredAt).format('ddd') === day)
      .map(entry => entry.code)
      .includes(code as 'GREEN' | 'YELLOW' | 'ORANGE' | 'RED')
  );

  return {
    bodyTemperature,
    hasWarning,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toWeekData = (data: BodyTemperature[]) =>
  emptyWeek.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
