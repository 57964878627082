import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { bodyWeightInterval } from '../Intervals';
import { BodyWeightLine } from '../Lines';
import { BodyWeightToolTip } from '../ToolTips';

export const BodyWeightChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    bodyTemperature?: number;
    hasWarning?: boolean | undefined;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={bodyWeightInterval}
      data={data}
      tooltip={BodyWeightToolTip}
      lineOne={BodyWeightLine('#7cac31')}
      color="#7cac31"
      allowHighlight={allowHighlight}
    />
  );
};
