import React from 'react';
import useForm from 'react-hook-form';

import {
  DismissibleError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  Select,
  StatusDropdown
} from 'components';
import {
  accountOwnerOptions,
  distributorNameOptions,
  facilities,
  regionalManagerOptions,
  regions,
  states
} from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { Idn } from 'Settings/types';

import { createFacility } from '../actions';
import { timezoneOptions } from '../Facilities/facility.utils';

import groupStyles from 'Settings/Settings.module.css';
import formGenericStyles from 'styles/form.module.css';

import { createFacilityDTO } from './createFacilityDTO';

interface AddFacilityFormData {
  accountOwner: string;
  additionalData4: string;
  additionalData1: string;
  additionalData3: string;
  additionalData2: string;
  address: string;
  city: string;
  distributorName: string;
  type: string;
  name: string;
  region: string;
  regionalManager: string;
  state: string;
  isActive: boolean;
  website: string;
  zip: string;
  timezone: string;
}

export const AddFacilityForm: React.FunctionComponent<{
  toggle: () => void;
  selectedIdn: Idn;
  onFacilityCreate: (id: string) => void;
}> = ({ toggle, selectedIdn, onFacilityCreate }) => {
  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<AddFacilityFormData>({
    mode: 'onBlur',
    defaultValues: {
      isActive: true
    },
    submitFocusError: false
  });
  const { data: addedFacilityId, setAction, loading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, loading);

  const saveFacility = (facility: any) => {
    setAction(createFacility(facility));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const formData = getValues();
      saveFacility(createFacilityDTO(formData, selectedIdn));
    }
  };

  if (submitted && !loading && !error) {
    toggle();
    if (addedFacilityId) {
      onFacilityCreate(addedFacilityId);
    }
  }

  const stateOptions = states.map(state => ({
    value: state.value,
    label: state.value
  }));
  const facilityOptions = facilities.map(facility => ({
    value: facility,
    label: facility
  }));
  const regionOptions = regions.map(region => ({
    value: region.label,
    label: region.label
  }));

  return (
    <>
      <FormHeader
        title="Add New Facility"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={loading}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={formGenericStyles.firstFormRow}>
            <div className={formGenericStyles.inputGroupHalf}>
              <Input
                name="name"
                label="Facility Name"
                register={register}
                validationRules={{ required: true }}
                hasError={!!errors.name}
                testId="fi-name"
              />
            </div>
            <div className={formGenericStyles.selectGroupStatusSmall}>
              <StatusDropdown
                register={register}
                onChange={setValue}
                value={watch('isActive')}
                hasError={!!errors.isActive}
              />
            </div>
          </div>
          <div className={formGenericStyles.formRow}>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="address"
                label="Address"
                register={register}
                hasError={!!errors.address}
              />
            </div>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="city"
                label="City"
                register={register}
                hasError={!!errors.city}
              />
            </div>
            <div className={formGenericStyles.selectGroupEighth}>
              <Select
                name="state"
                label="State"
                options={stateOptions}
                hasError={!!errors.state}
                register={register}
                onChange={setValue}
                value={watch('state')}
              />
            </div>
            <div className={formGenericStyles.inputGroupEighth}>
              <Input
                name="zip"
                label="Zip"
                register={register}
                hasError={!!errors.zip}
              />
            </div>
            <div className={formGenericStyles.selectGroupQuarter}>
              <Select
                name="timezone"
                label="Time Zone"
                options={timezoneOptions}
                hasError={!!errors.timezone}
                register={register}
                onChange={setValue}
                value={watch('timezone')}
                required={true}
              />
            </div>
          </div>
          <div className={formGenericStyles.formRow}>
            <div className={formGenericStyles.selectGroupQuarter}>
              <Select
                name="type"
                label="Facility Type"
                options={facilityOptions}
                hasError={!!errors.type}
                register={register}
                onChange={setValue}
                value={watch('type')}
                required={true}
              />
            </div>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="website"
                label="Website"
                register={register}
                hasError={!!errors.website}
              />
            </div>
            <div className={groupStyles.panelGroupQuarterForm}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  IDN Parent Number
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {selectedIdn.number}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarterForm}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>IDN Parent</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {selectedIdn.name}
              </div>
            </div>
          </div>
          <div className={formGenericStyles.formRow}>
            <div className={formGenericStyles.selectGroupQuarter}>
              <Select
                name="distributorName"
                label="Distributor Name"
                options={distributorNameOptions}
                hasError={!!errors.distributorName}
                register={register}
                onChange={setValue}
                value={watch('distributorName')}
                required={true}
              />
            </div>
            <div className={formGenericStyles.selectGroupQuarter}>
              <Select
                name="accountOwner"
                label="Account Owner"
                options={accountOwnerOptions}
                hasError={!!errors.accountOwner}
                register={register}
                onChange={setValue}
                value={watch('accountOwner')}
                required={true}
              />
            </div>
            <div className={formGenericStyles.selectGroupQuarter}>
              <Select
                name="regionalManager"
                label="Regional Manager"
                options={regionalManagerOptions}
                hasError={!!errors.regionalManager}
                register={register}
                onChange={setValue}
                value={watch('regionalManager')}
                required={true}
              />
            </div>
            <div className={formGenericStyles.selectGroupQuarter}>
              <Select
                name="region"
                label="Region"
                options={regionOptions}
                hasError={!!errors.region}
                register={register}
                onChange={setValue}
                value={watch('region')}
                required={true}
              />
            </div>
          </div>
          <div className={formGenericStyles.formRow}>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="additionalData1"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData1}
              />
            </div>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="additionalData2"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData2}
              />
            </div>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="additionalData3"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData3}
              />
            </div>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="additionalData4"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData4}
              />
            </div>
          </div>

          <DismissibleError
            name="Facility"
            visible={isErrorShowing}
            error={error}
            dismiss={dismissError}
          />
        </form>
      )}
    </>
  );
};
