import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import { useFetcher } from 'hooks';
import { getCaregiversByFacility } from 'Settings/actions';
import { Caregiver } from 'Settings/types';
import {
  formatSelectedValues,
  sortByKey,
  stripIdForDropdownValue
} from 'utils';

import formStyles from 'styles/form.module.css';

export const CaregiverFetchDropdown: React.FunctionComponent<{
  name: string;
  value: any;
  onChange: (name: any, value: any, revalidate?: boolean) => void;
  register: any;
  hasError: boolean;
  facilityId: string;
  changeStatus: (status: any) => void;
  required?: boolean;
  isVisible?: boolean;
}> = ({
  name,
  value,
  facilityId,
  hasError,
  changeStatus,
  onChange,
  register,
  required,
  isVisible = true
}) => {
  const getCaregiversMemo = useMemo(() => getCaregiversByFacility(facilityId), [
    facilityId
  ]);
  const {
    data: caregivers,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<{
    items: Caregiver[];
  }>(getCaregiversMemo, { items: [] });

  const caregiverOptions = sortByKey(
    caregivers.items.map(({ id, name: caregiverName }) => ({
      value: id,
      label: caregiverName
    })),
    'label'
  );

  useEffect(() => {
    changeStatus({ loading: caregiversLoading, error: caregiversError });
  }, [caregiversLoading, caregiversError, changeStatus]);

  const handleChange = (
    fieldName: string,
    selected: any,
    revalidate: boolean
  ) => {
    const formattedValue = formatSelectedValues(selected, caregiverOptions);
    onChange(fieldName, formattedValue, revalidate);
  };

  const style = isVisible ? { display: 'flex' } : { display: 'none' };

  return (
    <div className={formStyles.selectGroupQuarter} style={style}>
      <Select
        name={name}
        label="Caregiver"
        options={caregiverOptions}
        value={stripIdForDropdownValue(value)}
        register={register}
        hasError={hasError}
        onChange={handleChange}
        required={required}
      />
    </div>
  );
};
