import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ProfilePlaceholder } from 'components';
import { VACANT_ROOM_NAME } from 'consts';
import { ReactComponent as ExclamationMark } from 'icons/exclamationMark.svg';
import { useNotificationState } from 'Notifications';
import { getMarkAndHighlight, splitName } from 'utils';

import style from './ResidentCard.module.css';

export const ResidentCard: React.FunctionComponent<{
  id?: string;
  name?: string;
  type?: string;
  link: string;
  facility?: string;
  photoUrl?: string;
  location: string;
}> = ({
  id,
  name = VACANT_ROOM_NAME,
  type = 'unknown',
  link,
  facility,
  photoUrl,
  location
}) => {
  const { notifications } = useNotificationState();
  const { firstName, lastName, initial } = splitName(name);

  const mainDetails = facility || location;
  const extraDetails = facility ? location : '';

  const residentNotificationStyle = getMarkAndHighlight(notifications, id);

  return (
    <Link
      to={link}
      className={classnames(style.card, {
        [style.highlighted]: residentNotificationStyle.isHighlighted
      })}
    >
      <ProfilePlaceholder
        size={90}
        name={name}
        type={type}
        photoUrl={photoUrl}
      />
      <span className={style[type]}>
        <span>{`${firstName} ${initial}`}</span>
        <span>{lastName}</span>
      </span>
      <span className={style.detailsMain}>{mainDetails}</span>
      {extraDetails && (
        <span className={style.detailsExtra}>{extraDetails}</span>
      )}
      {residentNotificationStyle.hasExclamationMark && (
        <div className={style.warning} data-testid="warning-test">
          <ExclamationMark />
        </div>
      )}
    </Link>
  );
};
