import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { FieldName } from 'react-hook-form/dist/types';

import {
  CaregiverFetchDropdown,
  DismissibleError,
  FetchError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  ResidentFetchDropdown,
  RoomFetchDropdown,
  Select,
  StatusDropdown,
  WardFetchDropdown
} from 'components';
import { residentLabels } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { createDevice, DeviceError, Facility } from 'Settings';
import {
  availableDeviceTypes as deviceTypes,
  getFormValidationErrors,
  isAssetType,
  isCaregiverType,
  isGateway,
  isResidentType,
  isRoomType
} from 'utils';

import { createDeviceDTO } from './createDeviceDTO';

import formStyles from 'styles/form.module.css';
import styles from './Devices.module.css';

interface AddDeviceFormData {
  name: string;
  type: string;
  mac: string;
  isActive: boolean;
  room: { id: string; number: string } | null;
  ward: { id: string; name: string } | null;
  caregiver: { id: string; name: string } | null;
  resident: { id: string; name: string } | null;
  rssiTriggerLevel: string;
  description: string;
  uniqueIdentifier: string;
}

export const AddDeviceForm: React.FunctionComponent<{
  facility: Facility;
  toggle: () => void;
  onCreateDevice: (id: string) => void;
}> = ({ toggle, facility, onCreateDevice }) => {
  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<AddDeviceFormData>({
    mode: 'onBlur',
    defaultValues: {
      isActive: true
    },
    submitFocusError: false
  });

  const {
    data: newDeviceId,
    setAction,
    loading: deviceLoading,
    error: deviceError
  } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(deviceError, deviceLoading);

  const [requirement, setRequirement] = useState({
    isRoomRequired: false,
    isCaregiverRequired: false,
    isResidentRequired: false,
    isDescriptionRequired: false,
    isGateway: false
  });

  const [caregiverStatus, setCaregiverStatus] = useState({
    loading: true,
    error: false
  });

  const [wardStatus, setWardStatus] = useState({
    loading: true,
    error: false
  });

  const [roomStatus, setRoomStatus] = useState({
    loading: true,
    error: false
  });

  const [residentStatus, setResidentStatus] = useState({
    loading: true,
    error: false
  });

  const onWardChange = (
    name: FieldName<AddDeviceFormData>,
    selected: string
  ) => {
    setValue(name, selected);
    setValue('room', null);
  };

  const onTypeChange = (
    name: FieldName<AddDeviceFormData>,
    selectedType: string
  ) => {
    setValue(name, selectedType, true);
    if (selectedType) {
      setRequirement({
        isCaregiverRequired: isCaregiverType(selectedType),
        isResidentRequired: isResidentType(selectedType, facility.type),
        isRoomRequired: isRoomType(selectedType),
        isDescriptionRequired: isAssetType(selectedType),
        isGateway: isGateway(selectedType)
      });
    }
  };

  const saveDevice = (deviceFormData: any) => {
    setAction(createDevice(deviceFormData));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const deviceDTO = createDeviceDTO(getValues(), facility.id, requirement);

      saveDevice(deviceDTO);
    }
  };

  useEffect(() => {
    if (!submitted || deviceLoading || deviceError) {
      return undefined;
    }
    toggle();
    if (newDeviceId) {
      onCreateDevice(newDeviceId);
    }
  }, [
    submitted,
    deviceLoading,
    deviceError,
    toggle,
    newDeviceId,
    onCreateDevice
  ]);

  const typeOptions = Object.entries(deviceTypes(facility.type)).map(
    ([key, value]) => ({
      value: key,
      label: value
    })
  );

  const overallFetchError =
    roomStatus.error ||
    caregiverStatus.error ||
    residentStatus.error ||
    wardStatus.error;

  const fetchLoading =
    roomStatus.loading ||
    caregiverStatus.loading ||
    residentStatus.loading ||
    wardStatus.loading;

  const rowDisplay =
    !getValues().type || getValues().type === 'Asset Tracker' ? 'none' : 'flex';
  const formDisplay = fetchLoading ? 'none' : 'flex';

  const hasRoomWarning = () => {
    const { type, ward, room } = getValues();
    if (type && !isRoomType(type)) {
      return false;
    }
    return ward && !room;
  };

  const validationErrors = getFormValidationErrors(errors);

  const handleInputChange = () => {
    if (deviceError) {
      dismissError();
    }
  };

  return (
    <>
      <FormHeader
        title="Add New Device"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={deviceLoading}
        visible={!overallFetchError}
      />
      {overallFetchError ? (
        <FetchError
          error={overallFetchError}
          closable={true}
          onClose={toggle}
        />
      ) : (
        <>
          {fetchLoading && <LoadingPlaceholder />}
          <form onSubmit={handleSubmit}>
            <div
              className={formStyles.formRow}
              style={{ display: formDisplay }}
            >
              <div className={formStyles.inputGroupQuarter}>
                <Input
                  name="mac"
                  label="Device ID"
                  register={register}
                  validationRules={{
                    required: {
                      value: true,
                      message: 'Device ID is required.'
                    }
                  }}
                  hasError={!!errors.mac}
                />
              </div>
              <div className={formStyles.inputGroupQuarter}>
                <Input
                  name="name"
                  label="Device Name"
                  register={register}
                  validationRules={{
                    required: {
                      value: true,
                      message: 'Device Name is required.'
                    }
                  }}
                  hasError={
                    Boolean(errors.name) ||
                    Boolean(
                      isErrorShowing &&
                        deviceError &&
                        (deviceError as DeviceError).code ===
                          'device.name.unique'
                    )
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div className={formStyles.selectGroupQuarter}>
                <Select
                  name="type"
                  label="Device Type"
                  options={typeOptions}
                  hasError={!!errors.type}
                  register={register}
                  onChange={onTypeChange}
                  value={watch('type')}
                  required={true}
                />
              </div>
              <div className={formStyles.selectGroupStatusSmall}>
                <StatusDropdown
                  register={register}
                  onChange={setValue}
                  value={watch('isActive')}
                  hasError={!!errors.isActive}
                />
              </div>
            </div>
            <div
              className={formStyles.formRow}
              style={{ display: formDisplay }}
            >
              <div className={formStyles.inputGroupHalf}>
                <Input
                  name="uniqueIdentifier"
                  label="Unique Identifier"
                  register={register}
                  validationRules={{
                    required: {
                      value: true,
                      message: 'Unique Identifier is required.'
                    },
                    pattern: {
                      value: /^([A-F0-9]{32})$/,
                      message:
                        'Unique Identifier can only contain uppercase letters A through F, digits and be 32 characters long.'
                    }
                  }}
                  hasError={
                    Boolean(errors.uniqueIdentifier) ||
                    Boolean(
                      isErrorShowing &&
                        deviceError &&
                        (deviceError as DeviceError).code ===
                          'device.mac.uniqueIdentifier.unique'
                    )
                  }
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={formStyles.formRow} style={{ display: rowDisplay }}>
              {requirement.isDescriptionRequired && (
                <div className={formStyles.inputGroup}>
                  <Input
                    name="description"
                    label="Description"
                    register={register}
                    hasError={!!errors.description}
                  />
                </div>
              )}
              {requirement.isGateway && (
                <div className={formStyles.inputGroupQuarter}>
                  <Input
                    name="rssiTriggerLevel"
                    label="RSSI Threshold"
                    register={register}
                    hasError={!!errors.rssiTriggerLevel}
                    validationRules={{
                      required: {
                        value: true,
                        message: 'RSSI Threshold is required.'
                      },
                      validate: (enter: number) =>
                        (enter >= -120 && enter <= 0) ||
                        'RSSI Threshold must be between -120 and 0.'
                    }}
                  />
                </div>
              )}
              <CaregiverFetchDropdown
                name="caregiver"
                value={watch('caregiver')}
                onChange={setValue}
                register={register}
                hasError={!!errors.caregiver}
                facilityId={facility.id}
                changeStatus={setCaregiverStatus}
                isVisible={requirement.isCaregiverRequired}
              />
              <WardFetchDropdown
                name="ward"
                value={watch('ward')}
                onChange={onWardChange}
                register={register}
                hasError={!!errors.ward}
                facilityId={facility.id}
                changeStatus={setWardStatus}
                isVisible={requirement.isRoomRequired}
                cssClass={formStyles.selectGroupQuarter}
              />
              <RoomFetchDropdown
                name="room"
                value={watch('room')}
                onChange={setValue}
                register={register}
                hasError={!!errors.room}
                facilityId={facility.id}
                changeStatus={setRoomStatus}
                wardId={getValues().ward ? getValues().ward!.id : ''}
                isVisible={requirement.isRoomRequired}
                noOptionsMessage={
                  watch('ward') ? 'No rooms available' : 'Select a ward first'
                }
              />
              <ResidentFetchDropdown
                name={'resident'}
                value={watch('resident')}
                onChange={setValue}
                register={register}
                hasError={!!errors.resident}
                facility={facility}
                changeStatus={setResidentStatus}
                isVisible={requirement.isResidentRequired}
                label={residentLabels[facility.type]}
                activeOnly={true}
              />
            </div>
            {validationErrors && (
              <div className={styles.errorsWrapper}>{validationErrors}</div>
            )}
            {hasRoomWarning() && !deviceError && (
              <h4 className={styles.wardMessage}>
                Note: If you leave the room field empty, the device will be
                unassigned.
              </h4>
            )}

            <DismissibleError
              name="Device"
              visible={isErrorShowing}
              error={deviceError}
              dismiss={dismissError}
            />
          </form>
        </>
      )}
    </>
  );
};
