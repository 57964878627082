import React from 'react';

import { GraphSpinner } from './GraphSpinner';

import styles from 'components/message.module.css';

export const GraphLoadingPlaceholder: React.FunctionComponent<{
  color?: string;
}> = ({ color }) => (
  <div className={styles.loadingContainer} data-testid="loading-placeholder">
    <GraphSpinner color={color} />
  </div>
);
