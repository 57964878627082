import React from 'react';

import { errorMessages } from 'consts';
import { ReactComponent as CrossIcon } from '../../icons/crossmark.svg';
import { isErrorMessage } from '../../Settings/types';

import styles from './error.module.css';

export const FetchError: React.FunctionComponent<{
  error?: any;
  closable?: boolean;
  onClose?: () => void;
}> = ({ error, closable, onClose }) => {
  const onClick = () => {
    if (closable && onClose) {
      onClose();
    }
  };

  const errorInList = errorMessages('').find(
    item => item?.code === error?.code || item?.statusCode === error?.status
  );

  const message = errorInList
    ? error && isErrorMessage(error)
      ? error.message
      : errorInList.message
    : 'Something went wrong, could not fetch data.';

  return (
    <div className={styles.fetchErrorContainer}>
      <div className={styles.errorTitleWrapper}>
        <h1 className={styles.errorTitle}>Oops!</h1>
        <button
          className={styles.errorCircle}
          onClick={onClick}
          disabled={!closable}
        >
          <CrossIcon />
        </button>
      </div>
      <h3 className={styles.errorMessage}>{message}</h3>
      <h5 className={styles.errorSuggestion}>Try refreshing the page.</h5>
    </div>
  );
};
