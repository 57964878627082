import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import { getCaregiversByWard, useCaregiversStatus } from 'Caregivers';
import { useCaregiverContext } from 'Caregivers/caregiver.context';
import {
  ChatMessage,
  ChatMessageType,
  ChatRoom,
  ConversationTypes,
  createChatRoom,
  formatMessages,
  getChatRoomsByLoginId,
  getMessageHistory,
  isLastMessageSeen,
  markMessageSeen,
  Message,
  Participant,
  Reader,
  sendChatMessage,
  uploadFile
} from 'Communication';
import { ChatMessages } from 'Communication/ChatMessages';
import { MessageInputBox } from 'Communication/MessageInputBox';
import { FetchError, PanelHeader } from 'components';
import { useFetcher, usePoster, useSubmitError } from 'hooks';
import { useLocationState } from 'Location';
import { Caregiver } from 'Settings';
import { ComUpdateTypes, useSocketState } from 'Socket';
import { ChatId } from 'types';

import styles from './CaregiverChatWindow.module.css';

export const CaregiversChatWindow: React.FunctionComponent = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [messageToSend, setMessageToSend] = useState<ChatMessage | null>(null);
  const [page, setPage] = useState<string>('0');
  const [scrollToNewestMessage, setScrollToNewestMessage] = useState(false);
  const [localError, setLocalError] = useState<Error | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [receivedNewMessage, setReceivedNewMessage] = useState<boolean>(false);
  const [lastMessageWasSeen, setLastMessageWasSeen] = useState<boolean>(false);

  const [currentChatId, setCurrentChatId] = useState<ChatId | undefined>(
    undefined
  );

  const { comUpdate } = useSocketState();
  const { loggedUser, loginId } = useAuthState();
  const {
    facility: { id: facilityId },
    ward: wardId
  } = useLocationState();

  const { setAction, loading: onSaveLoading, error: sendError } = usePoster();

  const {
    setAction: createChatAction,
    error: createChatError,
    data: chatId
  } = usePoster();

  const { submitted, setSubmitted, dismissError } = useSubmitError(
    sendError || createChatError,
    onSaveLoading
  );
  const [
    {
      caregiverDetails: { loginId: caregiverLoginId, name: caregiverName }
    }
  ] = useCaregiverContext();

  const chatByLoginIdPromise = useMemo(() => {
    if (caregiverLoginId) {
      return getChatRoomsByLoginId(caregiverLoginId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverLoginId]);

  const {
    data: chatRooms,
    loading: loadingChatRooms,
    error: chatRoomsError
  } = useFetcher(chatByLoginIdPromise, [] as ChatRoom[]);

  useEffect(() => {
    if (chatRoomsError || loadingChatRooms || chatRooms.length === 0) {
      return undefined;
    }

    const caregiverChat = chatRooms.find(
      chat => chat.chatRoomType === ConversationTypes.CAREGIVER
    );

    setCurrentChatId(caregiverChat?.id);
  }, [chatRoomsError, chatRooms, loadingChatRooms]);

  const caregiversByWardAction = useMemo(() => {
    if (facilityId) {
      return getCaregiversByWard(facilityId, wardId);
    }
  }, [facilityId, wardId]);

  const {
    data: caregiversByWard,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<Caregiver[]>(caregiversByWardAction, []);

  const {
    data: caregiversWardStatusMap,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const getMessagesMemo = useMemo(() => {
    if (page !== undefined && currentChatId) {
      return getMessageHistory(currentChatId, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentChatId]);

  const {
    data: { items: fetchedMessages, nextPage: messagesNextPage },
    loading: messagesLoading,
    error: messagesError
  } = useFetcher<{ items: Message[]; nextPage?: string }>(getMessagesMemo, {
    items: [],
    nextPage: undefined
  });

  useEffect(() => {
    if (
      messagesLoading ||
      caregiversLoading ||
      statusLoading ||
      loadingChatRooms
    ) {
      setFetching(true);
    } else {
      setFetching(false);
    }
  }, [messagesLoading, caregiversLoading, statusLoading, loadingChatRooms]);

  useEffect(() => {
    if (
      createChatError ||
      sendError ||
      statusError ||
      caregiversError ||
      messagesError ||
      chatRoomsError
    ) {
      setLocalError(
        createChatError ||
          sendError ||
          statusError ||
          caregiversError ||
          messagesError ||
          chatRoomsError
      );
    } else {
      setLocalError(null);
    }
  }, [
    createChatError,
    sendError,
    statusError,
    caregiversError,
    messagesError,
    chatRoomsError
  ]);

  useEffect(() => {
    if (!caregiverLoginId || !wardId || !facilityId) {
      return undefined;
    }
    setMessages([]);
    setCurrentChatId(undefined);
    setPage('0');
  }, [caregiverLoginId, wardId, facilityId]);

  useEffect(() => {
    if (fetchedMessages.length > 0 && !messagesLoading && !messagesError) {
      const filteredMessages = fetchedMessages.filter(
        msg => !messages.find(prevMsg => msg.id === prevMsg.id)
      );

      const newMessages = formatMessages({
        loginId,
        caregivers: caregiversByWard,
        caregiversWardStatusMap,
        messages: filteredMessages,
        relatedContacts: []
      });

      setMessages(prevMessages => {
        return prevMessages.concat(newMessages);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedMessages,
    caregiversByWard,
    caregiversWardStatusMap,
    messagesLoading,
    messagesError
  ]);

  const sendNewMessage = async (message: any, type: ChatMessageType) => {
    if (loggedUser === null) {
      return undefined;
    }

    const author: Participant = {
      id: loggedUser.id,
      loginId,
      name: loggedUser.name,
      photoUrl: loggedUser.photoUrl,
      relationship: undefined,
      residentId: undefined,
      role: loggedUser.role,
      status: caregiversWardStatusMap[loggedUser.id]
    };

    const newChatMessage: ChatMessage = {
      id: Date.now().toString(),
      conversationId: '',
      content: message,
      type,
      readBy: [],
      author,
      sentAt: moment().toISOString(true)
    };

    if (currentChatId) {
      await setAction(sendChatMessage(currentChatId, message));
      setSubmitted(true);
      return setMessageToSend({
        ...newChatMessage,
        conversationId: currentChatId
      });
    }

    const desc = `${loggedUser.name} and ${caregiverName} chat room`;

    await createChatAction(
      createChatRoom(
        [caregiverLoginId, loginId],
        ConversationTypes.CAREGIVER,
        message,
        desc
      )
        .then(({ chatRoomId }) => {
          setCurrentChatId(chatRoomId);
        })
        .finally(() => {
          setSubmitted(true);
        })
    );
  };

  const onFileUpload = async (file: File) => {
    if (!loggedUser) {
      return undefined;
    }

    if (currentChatId) {
      await setAction(uploadFile(file, currentChatId, loginId));

      return setSubmitted(true);
    }

    const desc = `${loggedUser.name} and ${caregiverName} chat room`;

    await createChatAction(
      createChatRoom(
        [caregiverLoginId, loginId],
        ConversationTypes.CAREGIVER,
        null,
        desc
      )
        .then(({ chatRoomId }) => {
          setAction(uploadFile(file, chatRoomId, loginId));
          setCurrentChatId(chatRoomId);
        })
        .finally(() => {
          setSubmitted(true);
        })
    );
  };

  const onCancel = (message: ChatMessage) => {
    const filteredMessages = messages.filter(
      entry => entry.errorId !== message.errorId
    );

    setMessages(filteredMessages);
  };

  const onRetry = (message: ChatMessage) => {
    const { conversationId, content } = message;
    const { errorId, ...messageWithNoError } = message;

    onCancel(message);
    setAction(sendChatMessage(conversationId || chatId, content));
    setMessageToSend(messageWithNoError);
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  // receiving a message on current chat
  useEffect(() => {
    if (!comUpdate) {
      return undefined;
    }

    if (
      comUpdate.chatRoomId === currentChatId &&
      (comUpdate.messageType === ComUpdateTypes.TEXT_MESSAGE ||
        comUpdate.messageType === ComUpdateTypes.FILE_MESSAGE)
    ) {
      const senderCaregiver = caregiversByWard.find(
        caregiver => comUpdate.loginSenderId === caregiver.loginId
      );

      if (!senderCaregiver) {
        return undefined;
      }

      const receivedMessage = {
        id: comUpdate.id,
        author: {
          loginId: senderCaregiver.loginId,
          id: senderCaregiver.id,
          name: senderCaregiver.name,
          photoUrl: senderCaregiver.photoUrl,
          status:
            senderCaregiver && caregiversWardStatusMap[senderCaregiver.id],
          role: senderCaregiver?.role
        },
        readBy: [],
        content: comUpdate.values.textMessage,
        fileUri: comUpdate.values.fileUri,
        fileName: comUpdate.values.fileName,
        type: comUpdate.messageType.toLowerCase(),
        conversationId: comUpdate.chatRoomId,
        sentAt: comUpdate.timestamp
      };

      if (receivedMessage.author.loginId !== loginId) {
        setReceivedNewMessage(true);
      }

      setMessages(prevMessages => [
        (receivedMessage as unknown) as ChatMessage,
        ...prevMessages
      ]);
      setScrollToNewestMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comUpdate]);

  useEffect(() => {
    if (!comUpdate) {
      return undefined;
    }

    if (comUpdate.messageType === ComUpdateTypes.MESSAGE_SEEN_MESSAGE) {
      const reader = Object.assign(
        { status: undefined },
        caregiversByWard.find(
          caregiver => caregiver.loginId === comUpdate.loginSenderId
        )
      ) as Reader;

      const newMessages = messages.reduce((acc, msg) => {
        if (
          msg.author.loginId === loginId &&
          comUpdate.values.messageThatWasSeen === msg.id
        ) {
          acc.push({
            ...msg,
            readBy: [reader]
          });
        } else {
          acc.push(msg);
        }
        return acc;
      }, [] as ChatMessage[]);

      setMessages(newMessages);
      setScrollToNewestMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, comUpdate]);

  useEffect(() => {
    if (submitted && !onSaveLoading && !sendError && messageToSend) {
      setMessageToSend(null);
      setSubmitted(false);
      setScrollToNewestMessage(true);
    } else if (sendError && !onSaveLoading && submitted && messageToSend) {
      const messageWithError = { ...messageToSend, errorId: Math.random() };
      setMessages(
        prevMessages => [...prevMessages, messageWithError] as ChatMessage[]
      );
      setMessageToSend(null);
      setSubmitted(false);

      setScrollToNewestMessage(true);
    }
  }, [submitted, onSaveLoading, sendError, setSubmitted, messageToSend]);

  const handlePagination = () => {
    if (fetching || localError || !messagesNextPage) {
      return undefined;
    }
    setPage(messagesNextPage);
  };

  const handleMessageInputFocus = () => {
    const caregiverChat = chatRooms.find(
      chat => chat.chatRoomType === ConversationTypes.CAREGIVER
    ) as ChatRoom;

    // check if last message from caregiver chat was already seen
    if (
      !isLastMessageSeen(caregiverChat, loginId) &&
      caregiverChat.lastMessage &&
      !lastMessageWasSeen
    ) {
      // if not, send message_seen_message
      markMessageSeen(caregiverChat.id, caregiverChat.lastMessage.id);
      setLastMessageWasSeen(true);
    }

    // if we receive a new message on ComUpdate
    if (receivedNewMessage) {
      // if so, send message_seen_message for new message that we received
      markMessageSeen(caregiverChat.id, messages[0].id);
      setReceivedNewMessage(false);
    }
  };

  return (
    <div className={styles.chatWindowStickyWrapper}>
      <div className={styles.chatWindowContainer} id="chatWindowContainer">
        {!localError && <PanelHeader title={'Messages'} />}
        <ChatMessages
          messages={messages}
          onCancel={onCancel}
          onRetry={onRetry}
          onTrigger={handlePagination}
          error={localError}
          scrollToNewestMessage={scrollToNewestMessage}
          resetScroll={setScrollToNewestMessage}
          loading={fetching || onSaveLoading}
          chatId={currentChatId}
        />
        <MessageInputBox
          onSend={sendNewMessage}
          onFileUpload={onFileUpload}
          onInputFocus={handleMessageInputFocus}
          clearOnSend={true}
          disabled={submitted}
        />
        {!fetching && localError && (
          <div className={styles.errorContainer}>
            <FetchError onClose={dismissError} />
          </div>
        )}
      </div>
    </div>
  );
};
