import { axiosWrapper } from 'utils';

export const downloadGatewayCertificate = (
  deviceId: string,
  facilityId: string
) => async () => {
  const response = await axiosWrapper<any>(
    `/facilities/${facilityId}/devices/${deviceId}/certificate`,
    'GET',
    null,
    null,
    'blob'
  );

  return response.data;
};
