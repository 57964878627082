export const WARD_SEARCH_INPUT_NAME = 'wards by name';
export const WARD_TITLE = 'Wards';
export const WARD_LIST_PLACEHOLDER = 'No data available';
export const WARD_ALARM_YES = 'Yes';
export const WARD_ALARM_NO = 'No';
export const UNATTENDED_ALARM_TITLE = 'Rounding';

export enum AlarmPriorityTypes {
  YELLOW = 'Low',
  ORANGE = 'Medium',
  RED = 'High'
}

export type AlarmPriorityKey = keyof typeof AlarmPriorityTypes;

export interface RoundingAlarmMap {
  label: AlarmPriorityTypes;
  value: AlarmPriorityKey;
}

export const priorityOptions: RoundingAlarmMap[] = Object.keys(
  AlarmPriorityTypes
).map((key, i) => {
  return {
    label: AlarmPriorityTypes[key as AlarmPriorityKey],
    value: key as AlarmPriorityKey,
    sortHelper: String(i)
  };
});
