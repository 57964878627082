import { HOME_VIEW_STORAGE_KEY } from 'Home/constants';
import {
  HomeCssClasses,
  HomeView,
  HomeViews,
  PriorityCssClasses
} from 'Home/types';
import { AlarmsPriority } from 'Notifications/types';

export const setHomeViewStorage = (value: HomeView) => {
  return window.localStorage.setItem(HOME_VIEW_STORAGE_KEY, value);
};

export const getHomeViewStorage = () => {
  return window.localStorage.getItem(HOME_VIEW_STORAGE_KEY);
};

export const getHomePageStyle = (view: HomeView): string => {
  switch (view) {
    case HomeViews.ALARMS_WITH_RESIDENTS: {
      return HomeCssClasses.REGULAR;
    }
    case HomeViews.ALARMS_ONLY: {
      return HomeCssClasses.ALARMS_ONLY;
    }
    default: {
      return HomeCssClasses.REGULAR;
    }
  }
};

export const getPriorityStyle = (
  priority: AlarmsPriority
): PriorityCssClasses => {
  switch (priority) {
    case AlarmsPriority.PRIORITY_HIGH: {
      return PriorityCssClasses.HIGH;
    }
    case AlarmsPriority.PRIORITY_MEDIUM: {
      return PriorityCssClasses.MEDIUM;
    }
    case AlarmsPriority.PRIORITY_LOW: {
      return PriorityCssClasses.LOW;
    }
    default: {
      return PriorityCssClasses.LOW;
    }
  }
};
