import React from 'react';

import { Select } from 'components';
import { activeOption, inactiveOption } from 'consts';

export const StatusDropdown: React.FunctionComponent<{
  value: any;
  register: any;
  onChange: (name: any, value: any, revalidate?: boolean) => void;
  hasError: boolean;
}> = ({ value, register, onChange, hasError }) => (
  <Select
    name="isActive"
    label="Status"
    options={[activeOption, inactiveOption]}
    hasError={hasError}
    isClearable={false}
    register={register}
    onChange={onChange}
    value={value}
  />
);
