import React from 'react';

import { useLocationState } from 'Location';

import styles from './AccessMessage.module.css';

export const AccessMessage: React.FunctionComponent<{}> = () => {
  const {
    filtersLoading,
    filtersError,
    ward: wardId,
    facility
  } = useLocationState();

  return !filtersError && !filtersLoading && (!facility.id || !wardId) ? (
    <h4 className={styles.accessMessage}>
      {!wardId &&
        facility.id &&
        'No information available. Please make sure you have access to any wards.'}
      {!wardId &&
        !facility.id &&
        'You do not have access to any facilities. Please contact your administrator.'}
    </h4>
  ) : null;
};
