import { isValidSearchTerm } from 'Communication/utils';
import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper, handleError } from 'utils';

import { CAREGIVERS_PAGE_SIZE } from '../constants/caregivers.constants';
import { Caregiver, CaregiverListResponse } from '../types/caregiver.type';

export const getCaregiversByFacility = (facilityId: string) => {
  return async () => {
    const response = await axiosWrapper<CaregiverListResponse>(
      `/facilities/${facilityId}/caregivers?size=${MAX_PAGE_SIZE}`
    );
    return response.data;
  };
};

export const getCaregiversByQuery = async (
  facilityId: string,
  searchQuery: string,
  page: number
) => {
  let response;
  if (isValidSearchTerm(searchQuery)) {
    response = await axiosWrapper<CaregiverListResponse>(
      `/facilities/${facilityId}/caregivers?caregiverSearchQuery=${searchQuery}&page=${page}`
    );
  } else {
    response = await axiosWrapper<CaregiverListResponse>(
      `/facilities/${facilityId}/caregivers`
    );
  }
  return response.data;
};

export const getPaginatedCaregiversByQuery = async (
  facilityId: string,
  searchQuery: string,
  page: string
) => {
  const url = `/facilities/${facilityId}/caregivers/adhoc`;
  const searchParams = new URLSearchParams();

  searchParams.append('page', page);
  searchParams.append('size', CAREGIVERS_PAGE_SIZE);
  if (isValidSearchTerm(searchQuery)) {
    searchParams.append('caregiverSearchQuery', searchQuery);
  }

  const { data } = await axiosWrapper<{
    items: Caregiver[];
    nextPage?: string;
  }>(url, 'GET', undefined, undefined, undefined, searchParams);

  return {
    caregiversPage: data.items,
    nextPage: data.nextPage
  };
};

export async function fetchCaregivers(
  facilityId: string,
  searchTerm: string,
  listSetter: (arr: Caregiver[]) => void,
  pageSetter: (page?: string) => void,
  errorSetter: (error: Error | null) => void,
  loadingSetter: (value: boolean) => void,
  page: string
) {
  loadingSetter(true);
  try {
    const { caregiversPage, nextPage } = await getPaginatedCaregiversByQuery(
      facilityId,
      searchTerm,
      page
    );
    listSetter(caregiversPage);
    pageSetter(nextPage);
  } catch (error) {
    errorSetter(handleError(error));
  } finally {
    loadingSetter(false);
  }
}
