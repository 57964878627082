import { axiosWrapper } from 'utils';

import { Caregiver } from 'Settings/types';

export const createCaregiver = (caregiver: Caregiver) => async () => {
  const url = `/facilities/${caregiver.facilityId}/caregivers`;
  const response = await axiosWrapper(url, 'POST', null, caregiver);

  return response.headers.location.replace(`${url}/`, '');
};
