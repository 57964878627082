import { Caregiver } from 'Settings/types';

export const createCaregiverDTO = (
  formValues: any,
  additionalData = {} as Caregiver
) => {
  const { password, badge: formBadge, ...otherFormValues } = formValues;
  const formValuesToSend = password ? formValues : otherFormValues;

  const caregiverData = {
    ...additionalData,
    ...formValuesToSend,
    isActive: formValues.isActive,
    state: formValues.state,
    role: formValues.role,
    name: formValues.name.trim()
  };

  const badge = formBadge
    ? { id: formBadge.id, name: formBadge.name }
    : undefined;

  return { ...caregiverData, badge };
};
