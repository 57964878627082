import React, { Component } from 'react';

import styles from './Tabs.module.css';

interface TabProps {
  activeTab: string;
  label: string;
  onClick: (label: string) => void;
}
export class Tab extends Component<TabProps> {
  public onClick = () => {
    const { label, onClick }: TabProps = this.props;
    onClick(label);
  };

  public render() {
    const {
      onClick,
      props: { activeTab, label }
    } = this;

    if (activeTab === label) {
      return (
        <li className={styles.tabListItemActive} onClick={onClick}>
          {label}
        </li>
      );
    }

    return (
      <li className={styles.tabListItem} onClick={onClick}>
        {label}
      </li>
    );
  }
}
