import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateUtils } from 'react-day-picker';

import { CaregiverSchedule } from 'Caregivers';
import { getWardAssignments } from 'Caregivers/actions';
import { getMonthInterval } from 'Caregivers/Schedule/WorkSchedule';
import { Calendar, FetchError, LoadingPlaceholder } from 'components';
import { WardId } from 'types';

import customStyles from './ScheduleCalendar.module.css';

export const ScheduleCalendar: React.FunctionComponent<{
  onSelectDay: (day: Date) => void;
  wardId: WardId;
}> = ({ onSelectDay, wardId }) => {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [range, setRange] = useState<string[]>([]);
  const [assingments, setAssignments] = useState<CaregiverSchedule[]>([]);
  const [scheduledDays, setScheduledDays] = useState<Date[]>([]);
  const [workedDays, setWorkedDays] = useState<Date[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const [start, end] = getMonthInterval(selectedMonth);
    const r = [];

    for (let i = moment(start); i.isBefore(moment(end)); i.add(1, 'day')) {
      r.push(i.format('YYYY-MM-DD'));
    }

    setRange(r);
  }, [selectedMonth]);

  useEffect(() => {
    if (range.length === 0) {
      return undefined;
    }
    setLoading(true);
    const promises = range.map(day =>
      getWardAssignments({
        workingDay: day,
        wardId
      })()
    );

    Promise.all(promises)
      .then(response => setAssignments(response.flat()))
      .catch(err => {
        setError(err as Error);
      })
      .finally(() => setLoading(false));
  }, [range, wardId]);

  useEffect(() => {
    const dates = assingments.reduce((acc, item) => {
      const day = new Date(item.workingDay);
      if (!acc.includes(day)) {
        acc.push(day);
      }

      return acc;
    }, [] as Date[]);

    setScheduledDays(dates.filter(day => DateUtils.isFutureDay(day)));
    setWorkedDays(dates.filter(day => DateUtils.isPastDay(day)));
  }, [assingments]);

  return (
    <div className={customStyles.calendarWrapper}>
      {loading && !error && (
        <LoadingPlaceholder
          fullHeight={true}
          customStyles={customStyles.loading}
        />
      )}
      {!loading && error && <FetchError error={error} />}
      {!error && (
        <Calendar
          title="Schedule"
          styles={customStyles}
          onMonthChange={setSelectedMonth}
          onSelectDay={onSelectDay}
          dayModifiers={{
            [customStyles.scheduled]: scheduledDays,
            [customStyles.worked]: workedDays
          }}
        />
      )}
    </div>
  );
};
