import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { UserFacilityAccessListResponse } from '../types/access.type';

export const getUserAccessToFacilities = (userId: string) => async () => {
  const response = await axiosWrapper<UserFacilityAccessListResponse>(
    `/users/${userId}/accesses/facilities?size=${MAX_PAGE_SIZE}`
  );
  return response.data.items;
};
