import React, { useMemo } from 'react';

import {
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelDetail,
  TextStatus,
  WithPermissions
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useModal } from 'hooks';
import { Facility, getFacilityDetails } from 'Settings';
import { getHeight } from 'utils';

import { EditFacilityForm } from './EditFacilityForm';
import { formatTimezone } from './facility.utils';

import modalStyles from 'components/Modal/Modal.module.css';
import groupStyles from 'Settings/Settings.module.css';

export const FacilityDetails: React.FunctionComponent<{
  facilityId: string;
  refetchOnChange: () => void;
  inOverview?: boolean;
}> = ({ facilityId, refetchOnChange, inOverview }) => {
  const getFacilityDetailsMemo = useMemo(() => getFacilityDetails(facilityId), [
    facilityId
  ]);

  const { data: facility, error, loading, setRefetch } = useFetcher<Facility>(
    getFacilityDetailsMemo,
    {} as Facility
  );

  const { isShowing, toggle } = useModal();

  const onEdit = () => {
    toggle();
  };

  const modalClosed = () => {
    toggle();
    setRefetch();
  };

  const formStyles = inOverview
    ? { marginTop: getHeight('Facility Details'), marginLeft: 0 }
    : { marginTop: getHeight('Facility Details') };

  const content = (
    <>
      {facility && !error && !loading && (
        <>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupHalf}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Facility Name
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div
                className={
                  inOverview
                    ? groupStyles.panelDetailSpan
                    : groupStyles.panelDetailSpanBlue
                }
              >
                {facility.name || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupDetailSmall}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>{`${
                  residentLabels[facility.type]
                }s`}</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.numberOfResidents || 0}
              </div>
            </div>
            <div className={groupStyles.statusFacilityDetails}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Status</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                <TextStatus status={facility.isActive} />
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Address</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.address || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>City</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.city || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupEighth}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>State</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.state || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupEighth}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Zip</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.zip || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Time Zone</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.timezone ? formatTimezone(facility.timezone) : 'N/A'}
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Facility Type
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.type || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Website</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {facility.website || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  IDN Parent Number
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.idn ? facility.idn.number : 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>IDN Parent</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.idn ? facility.idn.name : 'N/A'}
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Distributor Name
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {facility.distributorName || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Account Owner
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {facility.accountOwner || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Regional Manager
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {facility.regionalManager || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Region</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.region || 'N/A'}
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Additional Data
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.additionalData1 || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Additional Data
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.additionalData2 || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Additional Data
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.additionalData3 || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Additional Data
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {facility.additionalData4 || 'N/A'}
              </div>
            </div>
          </div>
          <Modal isShowing={isShowing} toggle={toggle}>
            <div className={modalStyles.modalBackdrop}>
              <div className={modalStyles.modalContentRightAlign}>
                <div
                  className={
                    inOverview
                      ? modalStyles.facilityModalInOverview
                      : modalStyles.facilityModal
                  }
                  style={formStyles}
                >
                  <EditFacilityForm
                    toggle={modalClosed}
                    selectedFacility={facility}
                    refetchOnChange={refetchOnChange}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      {loading && <LoadingPlaceholder />}
      {error && !loading && <FetchError error={error} />}
    </>
  );

  return (
    <WithPermissions
      action="settings-facilities:edit"
      yes={
        <PanelDetail
          title="Facility Details"
          onAction={onEdit}
          hasError={!!error}
        >
          {content}
        </PanelDetail>
      }
      no={
        <PanelDetail title="Facility Details" hasError={!!error}>
          {content}
        </PanelDetail>
      }
    />
  );
};
