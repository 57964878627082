import moment from 'moment';

import { HistoryLabel } from './types';

export const convertTimestamp = (
  isoString: string | undefined
): string | undefined => {
  const isValidISO = isoString && moment(isoString, moment.ISO_8601).isValid;
  if (!isValidISO) {
    return undefined;
  }

  const [date, time] = moment(isoString)
    .parseZone()
    .format('MM/DD/YYYY HH:mm')
    .split(' ');

  return `${date} @ ${time}`;
};

export const formatLogDate = (readingTime: string) => {
  const today = moment().parseZone();
  const logDate = moment(readingTime).parseZone();
  const isToday = logDate.isSame(today, 'day');
  const formattedDate = logDate.format('MMMM D, YYYY');
  const [day, timestamp] = logDate.format('dddd HH:mm').split(' ');
  const weekDay = isToday ? 'Today' : day;

  return {
    timestamp: `${weekDay} @ ${timestamp}`,
    date: formattedDate
  };
};

export const getThisWeekHistoryFilters = () => {
  const startOfWeek = moment()
    .startOf('week')
    .toISOString(true); // starts on Sun
  const endOfWeek = moment()
    .endOf('week')
    .toISOString(true); // ends on Sat

  return {
    start: startOfWeek,
    end: endOfWeek,
    label: 'This Week' as HistoryLabel
  };
};

export const getLastWeekHistoryFilters = () => {
  const start = moment()
    .subtract(1, 'weeks')
    .startOf('week')
    .toISOString(true);
  const end = moment()
    .subtract(1, 'weeks')
    .endOf('week')
    .toISOString(true);

  return {
    start,
    end,
    label: 'Last Week' as HistoryLabel
  };
};

export const getThisMonthHistoryFilters = () => {
  const start = moment()
    .startOf('month')
    .toISOString(true);
  const end = moment()
    .endOf('month')
    .toISOString(true);

  return {
    start,
    end,
    label: 'This Month' as HistoryLabel
  };
};

export const numberWithCommas = (num: number) =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

const pad = (num: any) => {
  return ('0' + num).slice(-2);
};

export const formatSeconds = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  minutes = minutes % 60;

  const paddedHours = pad(hours);

  return `${paddedHours === '00' ? '' : paddedHours + ':'}${pad(minutes)}:${pad(
    seconds
  )}`;
};

export const secondsToDecimalHours = (seconds: number) => seconds * (1 / 3600);

export const decimalHoursToTime = (hours: number) => {
  const [hour, mins] = hours.toString().includes('.')
    ? hours.toString().split('.')
    : [hours.toString(), '0'];
  const [minutes, secs] = (
    Math.round(Number(`0.${mins}`) * 60 * 10000000000000) / 10000000000000
  ) // force a programatic round after a certain digit past the decimal point since js doesn't seem to
    .toString()
    .split('.');
  const seconds = Math.trunc(
    Math.round(parseFloat(`0.${secs}`) * 60 * 1000000000) / 1000000000
  );
  // force a programatic round after a certain digit past the decimal point since js doesn't seem to
  const paddedHours = pad(hour);

  return `${paddedHours === '00' ? '' : paddedHours + ':'}${pad(minutes)}:${pad(
    seconds
  )}`;
};

export const timeToSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(':');
  return (
    parseInt(hours, 10) * 60 * 60 +
    parseInt(minutes, 10) * 60 +
    parseInt(seconds, 10)
  );
};
