import React from 'react';

import {
  BloodPressure,
  formatLogDate,
  HistoryLogsList,
  Measurement
} from 'Residents';

import { HistoryLogEntry } from './HistoryLogEntry';

import { useLocationState } from 'Location';
import styles from 'Residents/History/History.module.css';

export const BloodPressureHistoryLogs: React.FunctionComponent<{
  historyLogs: BloodPressure[];
}> = ({ historyLogs }) => {
  const { selectedWardDetails } = useLocationState();

  const logEntries = historyLogs.map(log => {
    const { measuredAt, code, value, unit } = log;
    const { timestamp, date } = formatLogDate(measuredAt);

    return (
      <HistoryLogEntry
        key={log.measuredAt}
        timestamp={timestamp}
        date={date}
        code={code}
      >
        <Measurement
          value={`${value.systolicPressure}/${value.diastolicPressure}`}
          unit={unit}
        />
        {selectedWardDetails?.services.patientResidentVitals.features
          .prvHeartRate.isEnabled && (
          <Measurement value={value.heartRate} unit="bpm" />
        )}
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList>{logEntries}</HistoryLogsList>
  ) : (
    <p className={styles.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
