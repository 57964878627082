import React, { useEffect, useMemo, useState } from 'react';
import useForm from 'react-hook-form';

import { StatusType } from 'Caregivers';
import { DetailBox, Input, Select } from 'components';
import { caregiverRoles } from 'consts';

import { ProfileHeader } from './ProfileHeader';

import { editUserAvatar } from './actions';
import {
  CaregiverProfileDetails,
  CaregiverProfileFormData,
  isCaregiverProfile,
  UserProfileDetails,
  UserProfileFormData
} from './types';

import { EMAIL_PATTERN, VALIDATION_MESSAGE } from 'Settings/constants';
import styles from './ManageAccount.module.css';

interface UserAccountFormData {
  name: string;
  userEmail: string;
  role: string;
  homePhone: string;
  mobilePhone: string;
  userPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const FORM_HEIGHT_DIFF = 318; // rest of elements from wrapper
const ERROR_WRAPPER_MARGINS = 23;

const roleOptions = caregiverRoles.map((role: string) => ({
  value: role,
  label: role
}));

export const UserAccountForm: React.FunctionComponent<{
  onSave: (user: UserProfileFormData | CaregiverProfileDetails) => void;
  onCancel: () => void;
  onSaveLoading: boolean;
  userData: UserProfileDetails | CaregiverProfileDetails;
  dismissMessages: () => void;
  status: StatusType | undefined;
}> = ({
  onSave,
  onCancel,
  onSaveLoading,
  userData,
  dismissMessages,
  status,
  children
}) => {
  const [errorsWrapperHeight, setErrorsWrapperHeight] = useState(0);
  const [photoUrl, setPhotoUrl] = useState(userData.photoUrl);

  const getDefaultValues = () => {
    const { name, email, homePhone, mobilePhone, role } = userData;
    const defaults = {
      name,
      userEmail: email,
      homePhone,
      mobilePhone
    };
    return isCaregiverProfile(userData) ? { ...defaults, role } : defaults;
  };

  const {
    register,
    getValues,
    errors,
    setValue,
    watch,
    triggerValidation,
    clearError
  } = useForm<UserAccountFormData>({
    mode: 'onBlur',
    defaultValues: {
      ...getDefaultValues()
    },
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const {
        userPassword,
        newPassword: newPass,
        confirmNewPassword,
        userEmail,
        ...remainingFields
      } = getValues();
      let updates: CaregiverProfileFormData | UserProfileFormData = {
        ...userData,
        ...remainingFields,
        email: userEmail,
        name: remainingFields.name.trim(),
        photoUrl
      };
      if (userPassword && confirmNewPassword && newPassword) {
        updates = {
          ...updates,
          oldPassword: userPassword,
          newPassword: newPass,
          confirmNewPassword
        };
      }
      onSave(updates);
    }
  };

  const validationErrors = useMemo(() => {
    const errorEntries = Object.entries(errors);
    if (!errorEntries.length) {
      return null;
    }

    return errorEntries.reduce((messages: any[], errorEntry) => {
      const [type, error] = errorEntry;
      if (error && error.message) {
        const errorContent = (
          <h3 className={styles.validationError} key={type}>
            {error.message}
          </h3>
        );
        return [...messages, errorContent];
      }
      return messages;
    }, [] as string[]);
  }, [errors]);

  useEffect(() => {
    if (validationErrors) {
      dismissMessages();
    }
    const errorsWrapper = document.getElementById('AccountFormErrors');
    const height = errorsWrapper
      ? errorsWrapper.clientHeight + ERROR_WRAPPER_MARGINS
      : 0;
    setErrorsWrapperHeight(height);
  }, [validationErrors, dismissMessages]);

  const newPassword = watch('newPassword');
  const confirmationPassword = watch('confirmNewPassword');
  const oldPassword = watch('userPassword');

  const validatePasswords = () => {
    if (!newPassword && !confirmationPassword) {
      clearError(['newPassword', 'confirmNewPassword', 'userPassword']);
      return;
    }
    if (newPassword === confirmationPassword) {
      clearError(['newPassword', 'confirmNewPassword']);
      return;
    }
    if (!oldPassword && (newPassword || confirmationPassword)) {
      setValue('userPassword', '', true);
    }
    if (!newPassword && confirmationPassword) {
      setValue('newPassword', '', true);
      return;
    }
    if (!confirmationPassword && newPassword) {
      setValue('confirmNewPassword', '', true);
      return;
    }
    if (newPassword !== confirmationPassword) {
      setValue('newPassword', newPassword, true);
      setValue('confirmNewPassword', confirmationPassword, true);
    }
  };

  const handleAvatarChange = async (image: File | Blob, filename: string) => {
    const imageUrl = await editUserAvatar(image, filename);
    setPhotoUrl(imageUrl);
  };

  const roleContent = isCaregiverProfile(userData) ? (
    <div className={styles.selectGroup}>
      <Select
        name="role"
        label="Caregiver Role"
        options={roleOptions}
        hasError={!!errors.role}
        register={register}
        onChange={setValue}
        value={watch('role')}
        required={true}
      />
    </div>
  ) : (
    <div className={styles.detailRow}>
      <DetailBox label="Title" value={userData.role} />
    </div>
  );

  const formHeight = errorsWrapperHeight + FORM_HEIGHT_DIFF;

  return (
    <>
      <ProfileHeader
        name={userData.name}
        title={userData.role}
        toggleEditMode={onCancel}
        isEditOn={true}
        status={status}
        photoUrl={photoUrl}
        onAvatarChange={handleAvatarChange}
      />
      <form
        onSubmit={handleSubmit}
        className={styles.accountForm}
        style={{ maxHeight: `calc(100vh - ${formHeight}px)` }}
        autoComplete="off"
      >
        <div className={styles.inputGroup}>
          <Input
            name="name"
            label="Full Name"
            register={register}
            validationRules={{
              required: { value: true, message: 'Name field is required.' }
            }}
            hasError={!!errors.name}
          />
        </div>

        {roleContent}

        <div className={styles.inputGroup}>
          <Input
            name="userEmail"
            label="E-mail Address"
            register={register}
            hasError={!!errors.userEmail}
            validationRules={{
              required: {
                value: true,
                message: 'E-mail address field is required.'
              },
              pattern: {
                value: EMAIL_PATTERN,
                message: VALIDATION_MESSAGE
              }
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            name="homePhone"
            label="Home Phone"
            register={register}
            hasError={!!errors.homePhone}
            validationRules={{
              pattern: {
                value: /^(\([0-9]{3}\)) ([0-9]{3})-([0-9]{4})$/,
                message:
                  'Invalid phone number. Please use (XXX) XXX-XXXX format.'
              }
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            name="mobilePhone"
            label="Cell Phone"
            register={register}
            hasError={!!errors.mobilePhone}
            validationRules={{
              pattern: {
                value: /^(\([0-9]{3}\)) ([0-9]{3})-([0-9]{4})$/,
                message:
                  'Invalid phone number. Please use (XXX) XXX-XXXX format.'
              }
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            name="userPassword"
            label="Old Password"
            register={register}
            placeholder="Enter your old password"
            validationRules={{
              validate: (value: string) =>
                (watch('newPassword') || watch('confirmNewPassword')) && !value
                  ? 'You must enter your old password before changing it.'
                  : undefined
            }}
            hasError={!!errors.userPassword}
            type="password"
            onBlur={validatePasswords}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            name="newPassword"
            label="New Password"
            register={register}
            placeholder="Enter your new password"
            validationRules={{
              validate: (value: string) =>
                value !== watch('confirmNewPassword') ? '' : undefined
            }}
            hasError={!!errors.newPassword}
            type="password"
            onBlur={validatePasswords}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            name="confirmNewPassword"
            label="Confirm New Password"
            register={register}
            placeholder="Confirm you new password"
            validationRules={{
              validate: (value: string) =>
                value !== watch('newPassword')
                  ? "New password and confirmation password fields don't match."
                  : undefined
            }}
            hasError={!!errors.confirmNewPassword}
            type="password"
            onBlur={validatePasswords}
          />
        </div>
      </form>
      {children}
      {validationErrors && (
        <div className={styles.errorsWrapper} id="AccountFormErrors">
          {validationErrors}
        </div>
      )}
      <button
        className={styles.submitButton}
        onClick={handleSubmit}
        disabled={onSaveLoading}
      >
        update my account
      </button>
    </>
  );
};
