import React, { useMemo } from 'react';

import { caregiverStatusMap, StatusType } from 'Caregivers';
import { ProfilePlaceholder } from 'components';
import { AlarmTypes, useNotificationState } from 'Notifications';
import { Caregiver } from 'Settings';
import { formatName } from 'utils';

import style from './StaffMemberPlaceholder.module.css';

export const StaffMemberPlaceholder: React.FunctionComponent<{
  staffMember: Caregiver;
  status: StatusType;
}> = ({
  staffMember: { id, name, role, isActive, photoUrl },
  status,
  children
}) => {
  const type = isActive ? caregiverStatusMap[status] : 'unknown';

  const { notifications } = useNotificationState();

  const isHighlighted = notifications.some(
    ({ type: notificationType, personOfInterest }) =>
      notificationType === AlarmTypes.CAREGIVER_ALARM &&
      personOfInterest?.id === id
  );

  const formattedName = useMemo(() => formatName(name), [name]);

  return (
    <>
      {isHighlighted && <div className={style.highlighted} />}
      <ProfilePlaceholder name={name} type={type} photoUrl={photoUrl} />
      <div className={style.content}>
        <div className={style.actions}>
          <span className={style.name}>{formattedName}</span>
          {children}
        </div>
        <span className={style.role}>{role}</span>
      </div>
    </>
  );
};
