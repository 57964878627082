import React, { useEffect, useState } from 'react';

import { DropdownMenu, Snackbar } from 'components';
import {
  NotificationActionTypes,
  NotificationBadgeWithProviders,
  useNotificationContext
} from 'Notifications';

import { useLocationState } from 'Location';
import { NotificationList } from './NotificationList';

export const NotificationsOverview = () => {
  const [isErrorShowing, setIsErrorShowing] = useState(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(true);
  const [{ dismissError }, dispatch] = useNotificationContext();
  const { selectedWardDetails } = useLocationState();

  useEffect(() => {
    if (!selectedWardDetails) {
      return undefined;
    }
    const alarmFeatures = Object.values(
      selectedWardDetails.services.alarmsNotifications.features
    ).some(feat => feat.isEnabled === true);

    setShowNotifications(alarmFeatures);
  }, [selectedWardDetails]);

  useEffect(() => {
    setIsErrorShowing(!!dismissError);
  }, [dismissError]);

  const dismissMessage = () => {
    setIsErrorShowing(false);
    dispatch({ type: NotificationActionTypes.SET_REFETCH });
  };

  return (
    <>
      {showNotifications && (
        <DropdownMenu
          Icon={NotificationBadgeWithProviders}
          testId="notification-icon"
          dropdownArrow={true}
        >
          <NotificationList />
        </DropdownMenu>
      )}
      <Snackbar
        isOpen={isErrorShowing}
        onClose={dismissMessage}
        message="An error occurred while dismissing notifications. Please reload to see latest changes."
        reloadAction={dismissMessage}
      />
    </>
  );
};
