import React from 'react';

import { ReactComponent as Steps } from 'icons/steps.svg';
import { DailySteps, numberWithCommas } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './DailyStepsCard.module.css';

export const DailyStepsCard: React.FunctionComponent<{
  dailySteps: DailySteps | undefined;
  onClick: () => void;
}> = ({ dailySteps, onClick }) => {
  const { value, unit } = dailySteps || {};
  const reading = value && numberWithCommas(parseInt(value.count, 10));

  return (
    <div className={style.dailySteps} onClick={onClick}>
      <ActivityInfoCard reading={reading} unit={unit} type="dailySteps">
        <Steps />
      </ActivityInfoCard>
    </div>
  );
};
