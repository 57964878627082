import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { FILE_SIZE } from 'Communication';
import { Modal } from 'components';
import { useModal } from 'hooks';
import { ReactComponent as CameraIcon } from 'icons/camera.svg';
import { ReactComponent as CloseIcon } from 'icons/crossmark.svg';
import { resizeFile } from 'utils';

import { DroppableFileUpload } from './DroppableFileUpload';
import { FilePreview } from './FilePreview';

import styles from './FileUpload.module.css';

export const FileUpload: React.FunctionComponent<{
  size?: number;
  onFileChange: (file: File) => void;
  disabled: boolean;
  parentId?: string;
}> = ({ size, onFileChange, parentId, disabled = false }) => {
  const [file, setFile] = useState<File | null>(null);
  const offsetTop = useRef(0);

  const {
    isShowing: isUploadModalOpen,
    toggle: toggleFileUploadModal
  } = useModal();

  useEffect(() => {
    if (parentId) {
      const parentElement = document.getElementById(parentId);
      offsetTop.current = parentElement
        ? parentElement.getBoundingClientRect().top
        : 0;
    }
  }, [parentId]);

  useEffect(() => {
    if (!isUploadModalOpen) {
      setFile(null);
    }
  }, [isUploadModalOpen]);

  const onClick = () => {
    toggleFileUploadModal();
  };

  const getFile = async (selectedFile: File) => {
    const fileToUpload =
      selectedFile.size > FILE_SIZE
        ? await resizeFile(selectedFile)
        : selectedFile;

    toggleFileUploadModal();
    onFileChange(fileToUpload);
  };

  const removeFile = () => {
    setFile(null);
  };

  const extraProps = size
    ? {
        style: {
          width: size,
          height: size,
          minWidth: size
        }
      }
    : {};

  return (
    <>
      <button
        className={classnames(styles.fileUploadContainer, {
          [styles.disabled]: disabled
        })}
        onClick={onClick}
        type="button"
        {...extraProps}
      >
        <span className={styles.fileUploadIcon}>
          <CameraIcon />
        </span>
      </button>

      <Modal
        isShowing={isUploadModalOpen}
        toggle={toggleFileUploadModal}
        parentId={parentId}
      >
        <div
          className={styles.modalBackdrop}
          style={{ top: -offsetTop.current }}
        >
          <div className={styles.modalContent}>
            <button className={styles.cancelButton} onClick={onClick}>
              <CloseIcon />
            </button>
            <h2 className={styles.uploadTitle}>Upload a file</h2>
            {file ? (
              <FilePreview
                file={file}
                onSaveFile={getFile}
                onRemoveFile={removeFile}
              />
            ) : (
              <DroppableFileUpload onFileLoaded={setFile} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
