import React from 'react';

import {
  formatLogDate,
  formatSeconds,
  HistoryLogsList,
  SleepingHours
} from 'Residents';

import { HistoryLogEntry } from './HistoryLogEntry';
import { HistoryValue } from './HistoryValue';

import style from './HistoryLogs.module.css';

export const SleepingHoursHistoryLogs: React.FunctionComponent<{
  historyLogs: SleepingHours[];
  scrollRef?: any;
}> = ({ historyLogs, scrollRef }) => {
  const logEntries = historyLogs.map(log => {
    const { timestamp, value } = log;
    const { timestamp: time, date } = formatLogDate(timestamp);
    const actualValue = formatSeconds(parseFloat(value.count));

    return (
      <HistoryLogEntry key={log.timestamp} timestamp={time} date={date}>
        <HistoryValue value={actualValue} />
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList scrollRef={scrollRef}>{logEntries}</HistoryLogsList>
  ) : (
    <p className={style.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
