import React from 'react';

import {
  BodyWeight,
  formatLogDate,
  HistoryLogsList,
  Measurement
} from 'Residents';

import { HistoryLogEntry } from './HistoryLogEntry';

import styles from 'Residents/History/History.module.css';

export const BodyWeightHistoryLogs: React.FunctionComponent<{
  historyLogs: BodyWeight[];
}> = ({ historyLogs }) => {
  const logEntries = historyLogs.map(log => {
    const { measuredAt, value, unit } = log;
    const { timestamp, date } = formatLogDate(measuredAt);

    return (
      <HistoryLogEntry key={log.measuredAt} timestamp={timestamp} date={date}>
        <Measurement value={value.bodyWeight} unit={unit} />
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList>{logEntries}</HistoryLogsList>
  ) : (
    <p className={styles.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
