import React, { useRef, useState } from 'react';

import { Modal } from 'components';
import { useModal } from 'hooks';
import {
  BloodSugar,
  getLastWeekHistoryFilters,
  getThisMonthHistoryFilters,
  getThisWeekHistoryFilters,
  HistoryLabel
} from 'Residents';

import { BloodSugarVitalCard } from './Cards';
import { BloodSugarHistory } from './History';
import { AddBloodSugarReading } from './History/AddReadings/AddBloodSugarReading';

import style from 'Residents/History/History.module.css';

export const ManageBloodSugar: React.FunctionComponent<{
  bloodSugar: BloodSugar;
  facilityId: string;
  residentId: string;
  onReadingAdded: () => void;
}> = ({ bloodSugar, facilityId, residentId, onReadingAdded }) => {
  const [historyFilters, setHistoryFilters] = useState(
    getThisWeekHistoryFilters()
  );
  const hasNewReading = useRef(false);
  const { isShowing, toggle: toggleHistoryView } = useModal();
  const { isShowing: isAddFormOpen, toggle: toggleAddReading } = useModal();

  const onAddReading = () => {
    toggleAddReading();
    hasNewReading.current = true;
    // update history filters to This Week
    setHistoryFilters(getThisWeekHistoryFilters());
  };

  const onCloseHistoryView = () => {
    if (hasNewReading.current) {
      // let parent know to refetch on add
      onReadingAdded();
    }
    toggleHistoryView();
    setHistoryFilters(getThisWeekHistoryFilters());
  };

  const changeHistoryFilter = (historyLabel: HistoryLabel) => {
    switch (historyLabel) {
      case 'This Week':
        setHistoryFilters(getThisWeekHistoryFilters());
        break;
      case 'Last Week':
        setHistoryFilters(getLastWeekHistoryFilters());
        break;
      case 'This Month':
        setHistoryFilters(getThisMonthHistoryFilters());
        break;
    }
  };

  const onAdd = () => {
    if (!isAddFormOpen) {
      toggleAddReading();
    }
  };

  return (
    <>
      <BloodSugarVitalCard
        onClick={toggleHistoryView}
        bloodSugar={bloodSugar}
      />
      <Modal isShowing={isShowing} toggle={toggleHistoryView}>
        <div className={style.modalBackdrop} id="HistoryModal">
          <div className={style.modalContent}>
            <BloodSugarHistory
              onClose={onCloseHistoryView}
              facilityId={facilityId}
              residentId={residentId}
              onAdd={onAdd}
              historyFilters={historyFilters}
              changeHistoryFilter={changeHistoryFilter}
            />
          </div>
        </div>
      </Modal>
      <AddBloodSugarReading
        isOpen={isAddFormOpen}
        onCancel={toggleAddReading}
        onSave={onAddReading}
        facilityId={facilityId}
        residentId={residentId}
      />
    </>
  );
};
