export enum StatusTypes {
  ON_DUTY = 'ON_DUTY',
  OFF_DUTY = 'OFF_DUTY',
  ON_BREAK = 'ON_BREAK'
}

export type StatusType =
  | StatusTypes.ON_DUTY
  | StatusTypes.OFF_DUTY
  | StatusTypes.ON_BREAK;

export interface CaregiverStatus {
  breakDuration: number; // seconds
  caregiverId: string;
  facilityId: string;
  status: StatusType;
  wardId: string;
}

export interface CaregiverStatusListResponse {
  items: CaregiverStatus[];
}
