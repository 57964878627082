import { axiosWrapper } from 'utils';

import { Device } from 'Settings/types';

export const editDevice = (device: Device) => async () => {
  const response = await axiosWrapper(
    `/facilities/${device.facilityId}/devices/${device.id}`,
    'PUT',
    null,
    device
  );
  return response.data;
};
