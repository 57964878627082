import { axiosWrapper } from 'utils';

export const giveCaregiverWardAccess = (
  caregiverId: string,
  wardId: string
) => async () => {
  const url = `/caregivers/${caregiverId}/accesses/wards`;
  const response = await axiosWrapper(url, 'POST', null, { wardId });
  return response.data;
};
