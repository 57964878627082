import React, { createContext, useContext, useReducer } from 'react';

export type Action =
  | { type: 'INITIATE_SEARCH' }
  | { type: 'EXIT_SEARCH' }
  | { type: 'SET_SEARCH_TERM'; payload: string };

export interface State {
  searching: boolean;
  searchTerm: string;
}
export type Dispatch = (action: Action) => void;

const SearchStateContext = createContext<State | undefined>(undefined);
const SearchDispatchContext = createContext<Dispatch | undefined>(undefined);

const searchReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'INITIATE_SEARCH': {
      return {
        ...state,
        searching: true
      };
    }
    case 'EXIT_SEARCH': {
      return {
        ...state,
        searching: false,
        searchTerm: ''
      };
    }
    case 'SET_SEARCH_TERM': {
      return {
        ...state,
        searchTerm: action.payload
      };
    }
  }
};

export const SearchProvider: (props: {
  children: React.ReactNode;
  initialState?: State;
}) => any = ({
  children,
  initialState = {
    searching: false,
    searchTerm: ''
  }
}) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
};

export const useSearchState = () => {
  const context = useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error('useSearchState must be used within a SearchProvider');
  }
  return context;
};

export const useSearchDispatch = () => {
  const context = useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error('useSearchDispatch must be used within a SearchProvider');
  }
  return context;
};

export const useSearchContext = (): [State, Dispatch] => {
  return [useSearchState(), useSearchDispatch()];
};
