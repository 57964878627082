import { MAX_PAGE_SIZE } from 'consts';
import { Room, RoomListResponse } from 'Settings';
import { FacilityId, WardId } from 'types';
import { axiosWrapper, sortByKey } from 'utils';

export const getRoomsByWard = (
  facilityId: string,
  wardId: string
) => async () => {
  const response = await axiosWrapper<RoomListResponse>(
    `/facilities/${facilityId}/rooms?wardId=${wardId}&size=${MAX_PAGE_SIZE}`
  );

  return { items: sortByKey(response.data.items, 'number') };
};

export interface IPaginatedRooms {
  items: Room[];
  nextPage: string;
}

export const getPaginatedRooms = (
  facilityId: FacilityId | undefined,
  wardId: WardId | undefined,
  page: string | undefined
) => {
  if (!facilityId || !wardId || !page) {
    return () => ({
      items: [],
      nextPage: undefined
    });
  }

  return async (): Promise<IPaginatedRooms> => {
    const {
      data: { items, nextPage }
    } = await axiosWrapper<IPaginatedRooms>(
      `/facilities/${facilityId}/rooms?wardId=${wardId}&size=20&page=${page}`
    );

    return {
      items,
      nextPage
    };
  };
};
