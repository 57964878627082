import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable
} from 'react-beautiful-dnd';
import { DateUtils } from 'react-day-picker';

import { CaregiverSchedule, getWardAssignments } from 'Caregivers';
import { FetchError, LoadingPlaceholder } from 'components';
import { useFetcher } from 'hooks';
import { Caregiver, getCaregiverDetails } from 'Settings';

import { AssignmentTableEntry } from './AssignmentTableEntry';
import { getDraggableStyle, getDroppableStyle } from './dragNdrop.utils';
import { ResidentList } from './ResidentList';
import { useScheduleContext } from './schedule.context';

import style from './StaffAssignments.module.css';

export const StaffAssignments: React.FunctionComponent<{
  selectedDay: Date;
  wardId: string;
  setDragDisabled: (status: boolean) => void;
  dragDisabled: boolean;
}> = ({ selectedDay, wardId, children, setDragDisabled, dragDisabled }) => {
  const [currentDraggedId, setCurrentDraggedId] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [fetchActionStatus, setFetchActionStatus] = useState<{
    loading: boolean;
    error: Error | null;
  }>({ loading: false, error: null });
  const [
    assignmentToRoomError,
    setAssignmentToRoomError
  ] = useState<Error | null>(null);

  const [
    { assignedCaregivers, caregiversStatus, statusLoading, statusFetchError },
    dispatch
  ] = useScheduleContext();

  const wardAssignmentsAction = useMemo(() => {
    if (wardId) {
      return getWardAssignments({
        workingDay: moment(selectedDay).format('YYYY-MM-DD'),
        wardId
      });
    }
  }, [selectedDay, wardId]);

  const {
    data: wardAssignments,
    error: wardAssignmentsError,
    loading: wardAssignmentsLoading,
    setRefetch: refetchWardAssignments
  } = useFetcher<CaregiverSchedule[]>(wardAssignmentsAction, []);

  useEffect(() => {
    async function loadCaregivers() {
      try {
        setFetchActionStatus({ loading: true, error: null });

        const caregiversDetails: Caregiver[] = await Promise.all(
          wardAssignments.map(
            async ({ caregiverId, facilityId }) =>
              await getCaregiverDetails(caregiverId, facilityId)()
          )
        );

        dispatch({
          type: 'SET_CAREGIVERS',
          payload: caregiversDetails
        });
        setFetchActionStatus(prevState => ({
          ...prevState,
          error: null
        }));
      } catch (error) {
        setFetchActionStatus(prevState => ({
          ...prevState,
          error: error as Error
        }));
      } finally {
        setFetchActionStatus(prevState => ({
          ...prevState,
          loading: false
        }));
      }
    }

    if (!wardAssignments.length) {
      dispatch({ type: 'CLEAR_CAREGIVERS' });
      return;
    }
    loadCaregivers();
  }, [wardAssignments, dispatch]);

  const loading =
    wardAssignmentsLoading || fetchActionStatus.loading || statusLoading;
  const error =
    wardAssignmentsError ||
    fetchActionStatus.error ||
    assignmentToRoomError ||
    statusFetchError;

  const getRenderItem = (
    items: Caregiver[],
    index: number,
    isDraggingOver: boolean
  ) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: any
  ) => {
    const item = items[rubric.source.index];
    if (snapshot.isDragging) {
      setCurrentDraggedId(item.id);
    }

    if (!snapshot.isDragging && item.id === currentDraggedId) {
      setCurrentDraggedId('');
    }

    return (
      <AssignmentTableEntry
        key={item.id}
        isDraggingOver={isDraggingOver}
        staffMember={item}
        status={caregiversStatus[item.id]}
        isEven={index % 2 === 1}
        draggableRef={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getDraggableStyle(snapshot, provided.draggableProps.style)}
      />
    );
  };

  const tableContent = (isDraggingOver: boolean) => {
    return assignedCaregivers.map((caregiver, index) => {
      return (
        <Draggable
          key={caregiver.id}
          draggableId={`staffAssignments-${JSON.stringify(caregiver)}`}
          index={index}
          isDragDisabled={DateUtils.isPastDay(selectedDay) || dragDisabled}
        >
          {getRenderItem(assignedCaregivers, index, isDraggingOver)}
        </Draggable>
      );
    });
  };

  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, {
      reloadAction: () => {
        if (child.props.reloadAction) {
          child.props.reloadAction();
        }
        refetchWardAssignments();
      }
    })
  );

  const show = !loading && !error;

  const onScroll = () => {
    const container = document.getElementById('assignment-container');
    if (container) {
      setScrollPosition(container.scrollLeft);
    }
  };

  return (
    <Droppable
      droppableId="staffAssignments"
      isDropDisabled={DateUtils.isPastDay(selectedDay) || dragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          id="assignment-container"
          className={classnames(style.assignmentsContainer, {
            [style.hasError]: !!error
          })}
          onScroll={onScroll}
        >
          <div
            className={classnames(style.content, {
              [style.displayFlex]: show,
              [style.displayNone]: !show
            })}
          >
            <ResidentList
              setAssignmentToRoomError={setAssignmentToRoomError}
              setDragDisabled={setDragDisabled}
              currentDragged={currentDraggedId}
              selectedDay={selectedDay}
              styles={getDroppableStyle(snapshot)}
              scrollPosition={scrollPosition}
              setFetchActionStatus={setFetchActionStatus}
            />
            <div className={style.assignmentsTable}>
              {tableContent(
                snapshot.isDraggingOver && !snapshot.draggingFromThisWith
              )}
            </div>
          </div>
          {loading && !error && <LoadingPlaceholder />}
          {!loading && error && <FetchError error={error} />}
          {childrenWithProps}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
