import { Resident } from 'Settings/types';

export const createResidentDTO = (
  formValues: any,
  additionalData = {} as Resident
) => {
  const residentData = {
    ...additionalData,
    ...formValues
  };

  const { badge, room, ward, ...residentDTO } = residentData;

  if (room) {
    residentDTO.room = room;
    if (ward) {
      residentDTO.room.ward = formValues.ward;
    }
  }

  if (badge) {
    residentDTO.badge = { id: badge.id, name: badge.name };
  }
  return residentDTO;
};
