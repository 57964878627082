import React from 'react';

import { PanelDetail } from 'components';

import styles from './DetailsPlaceholder.module.css';

export const DetailsPlaceholder: React.FunctionComponent<{
  message: string;
  title: string;
  actionType?: string;
  showActionButton?: boolean;
}> = ({ message, title, actionType, showActionButton }) => {
  const handleAction = showActionButton === false ? undefined : () => null;
  return (
    <PanelDetail title={title} onAction={handleAction} actionType={actionType}>
      <p className={styles.placeholder}>{message}</p>
    </PanelDetail>
  );
};
