import React, { useEffect, useMemo, useState } from 'react';

import { FetchError, LoadingPlaceholder } from 'components';
import { useFetcher, useInfiniteScroll, useModalMeasurements } from 'hooks';
import {
  BasicActivity,
  getActivitiesHistoryPaginated,
  getDailyActivityCounter,
  HistoryHeader,
  HistoryLabel,
  LiquidIntake
} from 'Residents';

import { Activities } from '../constants/index';
import { LiquidIntakeHistoryChart } from './Charts/LiquidIntake/LiquidIntakeHistoryChart';
import { LIQUID_INTAKE_TITLE } from './constants/index';
import { LiquidIntakeHistoryLogs } from './LiquidIntakeHistoryLogs';

import style from 'Residents/History/History.module.css';

export const LiquidIntakeHistory: React.FunctionComponent<{
  onClose: () => void;
  historyFilters: {
    start: string;
    end: string;
    label: HistoryLabel;
  };
  facilityId: string;
  residentId: string;
  changeHistoryFilter: (filter: HistoryLabel) => void;
  onAdd: () => void;
}> = ({
  onClose,
  historyFilters,
  facilityId,
  residentId,
  changeHistoryFilter,
  onAdd
}) => {
  const { modalOffsetTop, modalOffsetLeft, modalWidth } = useModalMeasurements(
    'Activities',
    'MainContent',
    !!residentId
  );
  const computedOffsetTop = modalOffsetTop >= 0 ? modalOffsetTop : 0;
  const computedOffsetLeft = modalOffsetLeft >= 0 ? modalOffsetLeft - 15 : 0;

  const [page, setPage] = useState(0);
  const [activities, setActivities] = useState<BasicActivity[]>([]);

  useEffect(() => {
    setActivities([]);
    setPage(0);
  }, [historyFilters]);

  const activitiesHistoryLogAction = useMemo(() => {
    if (facilityId && residentId) {
      const { start, end } = historyFilters;

      return getActivitiesHistoryPaginated(
        facilityId,
        residentId,
        Activities.LIQUID_INTAKE,
        start,
        end,
        page
      );
    }
  }, [facilityId, residentId, historyFilters, page]);

  const {
    data: { items: activitiesHistory, nextPage },
    loading: activitiesHistoryLoading,
    error: activitiesHistoryError
  } = useFetcher<{ items: BasicActivity[]; nextPage?: string }>(
    activitiesHistoryLogAction,
    { items: [] }
  );

  const dailyActivityCounterLogAction = useMemo(() => {
    if (facilityId && residentId) {
      const { start, end } = historyFilters;

      return getDailyActivityCounter(
        facilityId,
        residentId,
        Activities.LIQUID_INTAKE,
        start,
        end
      );
    }
  }, [facilityId, residentId, historyFilters]);

  const {
    data: dailyActivityCounter,
    loading: dailyActivityCounterLoading,
    error: dailyActivityCounterError
  } = useFetcher(dailyActivityCounterLogAction, []);

  useEffect(() => {
    if (activitiesHistory.length !== 0) {
      setActivities(prev => [...prev, ...activitiesHistory]);
    }
  }, [activitiesHistory]);

  const scrollAction = () => {
    if (
      !activitiesHistoryLoading &&
      isFetching &&
      activitiesHistory.length &&
      nextPage
    ) {
      setPage(parseInt(nextPage, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    activitiesHistoryLoading
  );

  const isDataAvailable = activities.length > 0;

  return (
    <>
      <div
        className={style.history}
        style={{
          marginTop: computedOffsetTop,
          marginLeft: computedOffsetLeft,
          width: modalWidth
        }}
        id="History"
      >
        {!activitiesHistoryError && (
          <HistoryHeader
            title={LIQUID_INTAKE_TITLE}
            toggle={onClose}
            onAdd={onAdd}
          />
        )}

        {!activitiesHistoryLoading && activitiesHistoryError && (
          <FetchError
            error={activitiesHistoryError}
            closable={true}
            onClose={onClose}
          />
        )}
        {!activitiesHistoryError && !dailyActivityCounterError && (
          <>
            {
              <LiquidIntakeHistoryChart
                changeHistoryFilter={changeHistoryFilter}
                activeHistoryTab={historyFilters.label}
                readings={dailyActivityCounter}
                loading={dailyActivityCounterLoading}
              />
            }
            {(!activitiesHistoryLoading || isDataAvailable) && (
              <LiquidIntakeHistoryLogs
                scrollRef={scrollRef}
                historyLogs={activities as LiquidIntake[]}
              />
            )}
            {activitiesHistoryLoading && isDataAvailable && (
              <LoadingPlaceholder
                fullHeight={false}
                withTransparency={true}
                customStyles={style.loadingPlaceholderHistory}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
