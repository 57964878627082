import { useEffect, useMemo, useState } from 'react';

import { useFetcher, usePoster } from 'hooks';
import { useLocationState } from 'Location';

import {
  changeCaregiverStatus as changeCaregiverStatusAction,
  getCaregiversWardStatus
} from './actions';
import { CaregiverStatus, StatusType } from './types';

export const useCaregiversStatus = (): {
  data: { [caregiverId: string]: StatusType };
  loading: boolean;
  error: Error | null;
  changeCaregiverStatus: (caregiverId: string, newStatus: StatusType) => void;
  statusChangeError: Error | null;
  refetchStatus: () => void;
  resetChangeError: () => void;
} => {
  const [wardStatus, setWardStatus] = useState<{
    [caregiverId: string]: StatusType;
  }>({});

  const {
    facility: { id: facilityId },
    ward: wardId
  } = useLocationState();

  const { setAction, error: statusChangeError, resetError } = usePoster();

  const getWardStatusAction = useMemo(() => {
    if (facilityId && wardId) {
      return getCaregiversWardStatus(facilityId, wardId);
    }
  }, [facilityId, wardId]);

  const {
    data: statusData,
    error,
    loading,
    setRefetch: refetchStatus
  } = useFetcher<CaregiverStatus[]>(getWardStatusAction, []);

  useEffect(() => {
    const wardStatusMap = statusData.reduce(
      (statusMap, { caregiverId, status }) => ({
        ...statusMap,
        [caregiverId]: status
      }),
      {}
    );

    setWardStatus(wardStatusMap);
  }, [statusData]);

  const changeCaregiverStatus = (
    caregiverId: string,
    newStatus: StatusType
  ) => {
    setWardStatus(prevStatus => ({
      ...prevStatus,
      [caregiverId]: newStatus
    }));

    const data = { caregiverId, status: newStatus, wardId, facilityId };

    setAction(changeCaregiverStatusAction(data));
  };

  return {
    data: wardStatus,
    loading,
    error,
    changeCaregiverStatus,
    statusChangeError,
    refetchStatus,
    resetChangeError: resetError
  };
};
