import { axiosWrapper } from 'utils';

export const giveUserFacilityAccess = (
  userId: string,
  facilityId: string
) => async () => {
  const url = `/users/${userId}/accesses/facilities`;
  const response = await axiosWrapper(url, 'POST', null, { facilityId });
  return response.data;
};
