import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AccessMessage, FetchError, LoadingPlaceholder } from 'components';
import { useLocationState } from 'Location';

import { HomepageAlarms } from './Alarms/HomepageAlarms';
import { CareTeamList } from './CareTeamList';
import { useHomeViewContext } from './contexts/home-view.context';
import { ResidentsList } from './ResidentsList';
import { HomeViews } from './types/homeview.types';
import { getHomePageStyle } from './utils/home-view.util';

import styles from './Home.module.css';

export const Home = ({ history }: RouteComponentProps) => {
  const [showAlarms, setShowAlarms] = useState<boolean>(true);
  const {
    ward: wardId,
    filtersLoading,
    filtersError,
    selectedWardDetails
  } = useLocationState();
  const [{ view }] = useHomeViewContext();
  const customStyle = getHomePageStyle(view);

  useEffect(() => {
    if (!selectedWardDetails) {
      return undefined;
    }
    const alarmFeatures = Object.values(
      selectedWardDetails.services.alarmsNotifications.features
    ).some(feat => feat.isEnabled === true);

    setShowAlarms(alarmFeatures);
  }, [selectedWardDetails]);

  return (
    <>
      <div className={styles.homepageContainer}>
        {!filtersLoading && !filtersError && (
          <>
            {wardId && (
              <>
                <div className={styles.mainHomeContent}>
                  {showAlarms && (
                    <div
                      className={classnames(styles.alarms, styles[customStyle])}
                    >
                      <HomepageAlarms view={view} />
                    </div>
                  )}
                  {view === HomeViews.ALARMS_WITH_RESIDENTS && (
                    <ResidentsList />
                  )}
                </div>
                <CareTeamList history={history} />
              </>
            )}
            <AccessMessage />
          </>
        )}

        {filtersLoading && !filtersError && <LoadingPlaceholder />}
        {!filtersLoading && filtersError && <FetchError />}
      </div>
    </>
  );
};
