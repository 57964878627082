import React, { useMemo } from 'react';

import {
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelDetail,
  ProfileComponent,
  TextStatus
} from 'components';

import { getHeight } from 'utils';

import { Caregiver, Facility } from 'Settings/types';
import { getCaregiverDetails } from '../actions';
import { EditCaregiverForm } from './EditCaregiverForm';

import { useFetcher, useModal } from 'hooks';

import stylesModal from 'components/Modal/Modal.module.css';
import groupStyles from 'Settings/Settings.module.css';

const passwordDisplay = (value: number) => '*'.repeat(value);

export const CaregiverDetails: React.FunctionComponent<{
  facility: Facility;
  caregiver: { id: string };
  onCaregiverChange: (caregiver: Caregiver) => void;
}> = ({ caregiver, onCaregiverChange, facility }) => {
  const getCaregiverDetailsMemo = useMemo(
    () => getCaregiverDetails(caregiver.id, facility.id),
    [caregiver, facility.id]
  );

  const {
    data: caregiverData,
    error: caregiverError,
    loading: caregiverLoading,
    setRefetch
  } = useFetcher<Caregiver>(getCaregiverDetailsMemo, {} as Caregiver);

  const { isShowing, toggle } = useModal();

  const onEdit = () => {
    toggle();
  };

  const modalClosed = () => {
    toggle();
    setRefetch();
  };

  return (
    <PanelDetail
      onAction={onEdit}
      title={'Caregiver Details'}
      hasError={!!caregiverError}
    >
      {caregiver.id && !caregiverError && !caregiverLoading && (
        <>
          <div className={groupStyles.detailRow}>
            <ProfileComponent
              name={caregiverData.name}
              label="Caregiver"
              style={groupStyles.panelGroupHalfRow}
              photoUrl={caregiverData.photoUrl}
            />

            <div
              className={groupStyles.panelGroupHalfRow}
              style={{ justifyContent: 'space-between' }}
            >
              <div className={groupStyles.panelGroup}>
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>Admin</span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <div className={groupStyles.panelDetailSpan}>
                  {caregiverData.isManager ? 'yes' : 'no'}
                </div>
              </div>
              <div
                className={`${groupStyles.panelGroupStatus} ${groupStyles.detailsPanelStatus}`}
              >
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>Status</span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <div className={groupStyles.panelDetailSpan}>
                  <TextStatus status={caregiverData.isActive} />
                </div>
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Address</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {caregiverData.address || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>City</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {caregiverData.city || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>State</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {caregiverData.state || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Zip</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {caregiverData.zip || 'N/A'}
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  E-mail Address
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {caregiverData.email || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Home Phone</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {caregiverData.homePhone || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Cell Phone</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {caregiverData.mobilePhone || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Employee Number
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {caregiverData.number}
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Caregiver Role
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {caregiverData.role}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Location Badge
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {caregiverData.badge && caregiverData.badge.name
                  ? caregiverData.badge.name
                  : 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Phone Extension
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpanBlue}>
                {caregiverData.phoneExtension || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Password</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {passwordDisplay(
                  caregiverData.password ? caregiverData.password.length : 10
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {caregiverLoading && <LoadingPlaceholder />}
      {caregiverError && !caregiverLoading && (
        <FetchError error={caregiverError} />
      )}

      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentRightAlign}>
            <div
              className={stylesModal.modalRightWithVerticalBar}
              style={{ marginTop: getHeight('Caregiver Details') }}
            >
              <EditCaregiverForm
                toggle={modalClosed}
                caregiver={caregiverData}
                onCaregiverChange={onCaregiverChange}
                facility={facility}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PanelDetail>
  );
};
