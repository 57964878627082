import { CaregiverStatusListResponse } from 'Caregivers';
import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

export const getCaregiversWardStatus = (
  facilityId: string,
  wardId: string
) => async () => {
  const response = await axiosWrapper<CaregiverStatusListResponse>(
    `/facilities/${facilityId}/wards/${wardId}/caregiver-statuses?size=${MAX_PAGE_SIZE}`
  );

  return response.data.items;
};
