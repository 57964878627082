import React, { useState } from 'react';
import useForm from 'react-hook-form';

import { createResidentDTO } from './createResidentDTO';

import {
  CustomMessage,
  DetailBox,
  DeviceFetchDropdown,
  FetchError,
  Input,
  LoadingPlaceholder,
  PanelHeader,
  ProfileComponent,
  RoomFetchDropdown,
  RoundCheckButton,
  RoundCrossButton,
  WardFetchDropdown
} from 'components';
import { useLocationContext, useLocationState } from 'Location';
import { Resident } from 'Settings';
import { getAvailableResidentDevices } from 'utils';

import { FieldName } from 'react-hook-form/dist/types';
import detailStyles from './ResidentDetailsPanel.module.css';

const formatBirthday = (birthday: string) => {
  const [year, month, day] = birthday.split('-');
  return `${month}/${day}/${year}`;
};

interface EditResidentFormData {
  cellPhoneNumber: string;
  badge: { id: string; name: string } | null;
  room: { id: string; number: string } | null;
  ward: { id: string; name: string } | null;
}

export const EditResidentForm: React.FunctionComponent<{
  resident: Resident;
  onSave: (formData: any) => void;
  onCancel: () => void;
  onSaveLoading: boolean;
}> = ({ resident, onSave, onCancel, onSaveLoading, children }) => {
  const {
    location: { facility },
    label
  } = useLocationContext();

  const { selectedWardDetails } = useLocationState();

  const [roomStatus, setRoomStatus] = useState({
    loading: true,
    error: false
  });

  const [wardStatus, setWardStatus] = useState({
    loading: true,
    error: false
  });

  const [braceletStatus, setBraceletStatus] = useState({
    loading: true,
    error: false
  });

  const getDefaultValues = () => {
    const { room, badge, cellPhoneNumber } = resident;
    const roomDefaults = room ? { id: room.id, number: room.number } : null;
    const wardDefaults =
      room && room.ward ? { id: room.ward.id, name: room.ward.name } : null;
    const badgeDefaults = badge ? { id: badge.id, name: badge.name } : null;

    return {
      cellPhoneNumber,
      room: roomDefaults,
      ward: wardDefaults,
      badge: badgeDefaults
    };
  };

  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<EditResidentFormData>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const updatedResident = createResidentDTO(getValues(), resident);
      onSave(updatedResident);
    }
  };

  const onWardChange = (
    name: FieldName<EditResidentFormData>,
    selected: string
  ) => {
    setValue(name, selected);
    setValue('room', null);
  };

  const fetchLoading = selectedWardDetails?.services.rtls.features.rtlsPatient
    .isEnabled
    ? braceletStatus.loading || wardStatus.loading || roomStatus.loading
    : wardStatus.loading || roomStatus.loading;

  const fetchError =
    braceletStatus.error || roomStatus.error || wardStatus.error;

  const formDisplay = fetchLoading ? 'none' : 'block';

  const hasRoomWarning = () => {
    const { ward, room } = getValues();
    return ward && !room && !children;
  };

  const title = `${label} Details`;
  const numberLabel = `${label} Number`;
  const deviceLabel = `${label} Device Name`;
  const message = `Note: If you leave the room field empty, the ${label.toLowerCase()} won't be assigned to a room nor ward.`;

  return (
    <>
      {fetchError ? (
        <FetchError error={fetchError} closable={true} onClose={onCancel} />
      ) : (
        <>
          {!fetchLoading && (
            <PanelHeader title={title}>
              <RoundCrossButton onClick={onCancel} />
              <RoundCheckButton
                onClick={handleSubmit}
                disabled={onSaveLoading}
              />
            </PanelHeader>
          )}
          {fetchLoading && <LoadingPlaceholder />}
          <form
            onSubmit={handleSubmit}
            style={{ display: formDisplay }}
            className={detailStyles.residentDetailsForm}
          >
            <div className={detailStyles.fixedHeightRow}>
              <ProfileComponent
                name={resident.name}
                label={label}
                photoUrl={resident.photoUrl}
                style={detailStyles.third}
                type={resident.gender.toLowerCase()}
              />
              <div className={detailStyles.third}>
                <DetailBox
                  label="Date of Birth"
                  value={formatBirthday(resident.birthday)}
                />
              </div>
              <div className={detailStyles.third}>
                <DetailBox label={numberLabel} value={resident.number} />
              </div>
            </div>
            <div className={detailStyles.detailRow}>
              <div className={detailStyles.third}>
                <WardFetchDropdown
                  name="ward"
                  value={watch('ward')}
                  onChange={onWardChange}
                  register={register}
                  hasError={!!errors.ward}
                  facilityId={facility.id}
                  changeStatus={setWardStatus}
                  cssClass={detailStyles.selectGroupHalf}
                />
                <RoomFetchDropdown
                  name="room"
                  value={watch('room')}
                  onChange={setValue}
                  register={register}
                  hasError={!!errors.room}
                  facilityId={facility.id}
                  changeStatus={setRoomStatus}
                  wardId={getValues().ward ? getValues().ward!.id : ''}
                  noOptionsMessage={
                    watch('ward') ? 'No rooms available' : 'Select a ward first'
                  }
                  cssClass={detailStyles.selectGroupHalf}
                />
              </div>
              {selectedWardDetails?.services.rtls.features.rtlsPatient
                .isEnabled && (
                <DeviceFetchDropdown
                  name="badge"
                  value={watch('badge')}
                  onChange={setValue}
                  register={register}
                  hasError={!!errors.badge}
                  facility={facility}
                  changeStatus={setBraceletStatus}
                  label={deviceLabel}
                  filterFunction={getAvailableResidentDevices}
                  cssClass={detailStyles.selectGroupThird}
                  defaultValue={getDefaultValues().badge}
                />
              )}
              <div className={detailStyles.inputGroupThird}>
                <Input
                  name="cellPhoneNumber"
                  label="Cell Phone"
                  register={register}
                  hasError={!!errors.cellPhoneNumber}
                  isLink={true}
                />
              </div>
            </div>
          </form>
          {hasRoomWarning() && <CustomMessage message={message} />}
          {children}
        </>
      )}
    </>
  );
};
