import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getCaregiversByWard } from 'Caregivers';
import { useFetcher } from 'hooks';
import { useLocationState } from 'Location';
import { getResidentsByWard } from 'Residents';
import { Caregiver, Resident } from 'Settings';

import { getWardNotifications } from './actions';
import { Dispatch } from './notification.context';
import {
  getResidentNotifications,
  loadDetailedCaregiverAlarm,
  loadDetailedResidentAlarm,
  loadDetailedRoomAlarm
} from './notification.utils';
import {
  AlarmTypes,
  Notification,
  NotificationActionTypes,
  NotificationDetail,
  NotificationSound
} from './types';

export const useNotificationsFetch = (dispatch: Dispatch) => {
  const [alarmDetailsFetchState, setAlarmDetailsFetchState] = useState<{
    loading: boolean;
    error: Error | null;
  }>({ loading: true, error: null });

  const caregiversByWard = useRef<Caregiver[]>([]);
  const residentsByWard = useRef<Resident[]>([]);

  const {
    ward: wardId,
    facility,
    filtersLoading,
    devicesLocation
  } = useLocationState();

  const wardNotificationsAction = useMemo(() => {
    if (wardId && facility.id && !filtersLoading) {
      dispatch({ type: NotificationActionTypes.FETCH });
      return getWardNotifications(facility.id, wardId);
    }
  }, [wardId, facility.id, filtersLoading, dispatch]);

  const residentsByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getResidentsByWard(facility.id, wardId, true);
    }
  }, [facility.id, wardId]);

  const caregiversByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getCaregiversByWard(facility.id, wardId);
    }
  }, [facility.id, wardId]);

  const {
    data: wardNotifications,
    loading: notificationsLoading,
    error: notificationsError,
    setRefetch: refetchNotifications
  } = useFetcher<Notification[]>(wardNotificationsAction, []);

  const {
    data: residentsData,
    error: residentsError,
    loading: residentsLoading
  } = useFetcher<{
    items: Resident[];
  }>(residentsByWardAction, { items: [] });

  const {
    data: caregiversData,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<Caregiver[]>(caregiversByWardAction, []);

  const fetchError =
    notificationsError ||
    residentsError ||
    caregiversError ||
    alarmDetailsFetchState.error;

  const fetchLoading =
    notificationsLoading || residentsLoading || caregiversLoading;

  useEffect(() => {
    residentsByWard.current = residentsData.items;
    caregiversByWard.current = caregiversData;
  }, [residentsData, caregiversData]);

  useEffect(() => {
    if (fetchError) {
      dispatch({
        type: NotificationActionTypes.FAIL_FETCH,
        payload: fetchError
      });
    }
  }, [fetchError, dispatch]);

  useEffect(() => {
    if (fetchLoading || fetchError) {
      return;
    }

    let didCancel = false;

    async function loadDetailedAlarms() {
      const generalResidentNotifications = await getResidentNotifications(
        wardNotifications,
        residentsByWard.current,
        facility.id
      );

      try {
        const caregiverAlarms = wardNotifications.filter(
          ({ type }) => type === AlarmTypes.CAREGIVER_ALARM
        );
        const residentAlarms = wardNotifications.filter(
          ({ type }) => type === AlarmTypes.RESIDENT_BADGE_SOS_ALARM
        );

        const roomAlarms = wardNotifications.filter(
          ({ type }) => type === AlarmTypes.ROOM_ALARM
        );

        if (
          !caregiverAlarms.length &&
          !residentAlarms.length &&
          !roomAlarms.length
        ) {
          dispatch({
            type: NotificationActionTypes.FINISH_FETCH,
            payload: generalResidentNotifications
          });

          setAlarmDetailsFetchState({ loading: false, error: null });
          return;
        }

        dispatch({ type: NotificationActionTypes.FETCH });
        setAlarmDetailsFetchState({ loading: true, error: null });

        const detailedCaregiverAlarms: NotificationDetail[] = caregiverAlarms.map(
          alarm => loadDetailedCaregiverAlarm(alarm, devicesLocation)
        );
        const detailedResidentAlarms: NotificationDetail[] = residentAlarms.map(
          alarm => loadDetailedResidentAlarm(alarm, devicesLocation)
        );

        const detailedRoomAlarms: NotificationDetail[] = roomAlarms.map(alarm =>
          loadDetailedRoomAlarm(alarm)
        );

        if (!didCancel) {
          const notifications = [
            ...detailedCaregiverAlarms,
            ...detailedResidentAlarms,
            ...detailedRoomAlarms,
            ...generalResidentNotifications
          ];

          dispatch({
            type: NotificationActionTypes.FINISH_FETCH,
            payload: notifications
          });

          setAlarmDetailsFetchState({ loading: false, error: null });
        }
      } catch (error) {
        if (!didCancel) {
          setAlarmDetailsFetchState({ loading: false, error: error as Error });
        }
      }
    }

    loadDetailedAlarms();

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, facility.id, fetchError, fetchLoading, wardNotifications]);

  const refetch = useCallback(() => {
    return refetchNotifications();
  }, [refetchNotifications]);

  const loadDetailedAlarm = useCallback(
    (alarm: Notification, sound: NotificationSound) => {
      let detailedAlarm: NotificationDetail;
      const { type } = alarm;

      if (type === AlarmTypes.CAREGIVER_ALARM) {
        detailedAlarm = loadDetailedCaregiverAlarm(alarm, devicesLocation);
      } else {
        detailedAlarm = loadDetailedResidentAlarm(alarm, devicesLocation);
      }

      dispatch({ type: NotificationActionTypes.ADD, payload: detailedAlarm });
      dispatch({
        type: NotificationActionTypes.SOUND,
        payload: sound
      });
    },
    [dispatch, devicesLocation]
  );

  return { refetch, loadDetailedAlarm };
};
