import classnames from 'classnames';
import React from 'react';
import useForm from 'react-hook-form';
import { NavLink, RouteProps } from 'react-router-dom';

import { useAuthContext } from 'Auth';
import { LoadingPlaceholder } from 'components';

import { LoginInput } from './LoginInput';
import { LoginTemplate } from './LoginTemplate';

import loginStyles from './Login.module.css';

interface LoginFormData {
  email: string;
  password: string;
}

export const Login: React.FunctionComponent<RouteProps> = ({ location }) => {
  const [
    { authenticationError, loading, isAuthenticated },
    { dispatch, login }
  ] = useAuthContext();
  const { register, errors, getValues, triggerValidation } = useForm<
    LoginFormData
  >({
    mode: 'onBlur',
    submitFocusError: false
  });

  const onSignIn = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const { email, password } = getValues();
      login({ username: email, password });
    }
  };

  const onFocus = () => {
    if (authenticationError) {
      dispatch({ type: 'SET_AUTH_ERROR', payload: null });
    }
  };

  const hasValidationErrors = Object.keys(errors).length > 0;

  const handleKeyPress = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      return await triggerValidation();
    }
  };

  return (
    <LoginTemplate isAuthenticated={isAuthenticated} location={location}>
      <h1 className={loginStyles.welcomeText}>welcome,</h1>
      <h1 className={loginStyles.spacedHeading}>sign in to continue</h1>
      <form onSubmit={onSignIn}>
        <LoginInput
          name="email"
          placeholder="email address"
          requiredValueMissing={!!errors.email || !!authenticationError}
          forwardedRef={register({ required: true })}
          onFocus={onFocus}
          onKeyPress={handleKeyPress}
        />
        <LoginInput
          onKeyPress={handleKeyPress}
          name="password"
          placeholder="password"
          requiredValueMissing={!!errors.password || !!authenticationError}
          type="password"
          feedbackMessage={
            !!authenticationError
              ? 'sign in failed. try again or click forgot password to reset your account.'
              : undefined
          }
          forwardedRef={register({ required: true })}
          onFocus={onFocus}
        />
        {loading ? (
          <div className={loginStyles.loginLoading}>
            <LoadingPlaceholder />
          </div>
        ) : (
          <button
            className={loginStyles.loginButton}
            disabled={hasValidationErrors}
            type="submit"
          >
            sign in
          </button>
        )}
      </form>
      <NavLink
        className={classnames(loginStyles.forgotPassword, {
          [loginStyles.disabled]: loading
        })}
        to={loading ? '#' : '/forgot-password'}
      >
        I forgot my password
      </NavLink>
    </LoginTemplate>
  );
};
