import { residentLabels } from 'consts';
import { Device } from 'Settings';

export const caregiverDeviceTypes: any = {
  caregiver_badge: 'Caregiver Badge',
  caregiver_phone: 'Caregiver Phone'
};

export const roomDeviceTypes: any = {
  iot_gateway: 'IoT Gateway'
};

export const residentDeviceTypes = (facilityType: string) => ({
  resident_bracelet: 'Resident Bracelet',
  resident_badge: `${residentLabels[facilityType]} Device`
});

export const deviceTypes = (facilityType: string) => ({
  ...roomDeviceTypes,
  ...caregiverDeviceTypes,
  ...residentDeviceTypes(facilityType),
  asset_tracker: 'Asset Tracker'
});

// currently defined by API
export const availableDeviceTypes = (facilityType: string) => ({
  iot_gateway: 'IoT Gateway',
  caregiver_badge: 'Caregiver Badge',
  resident_badge: `${residentLabels[facilityType]} Device`,
  asset_tracker: 'Asset Tracker'
});

export const getTypeForDevice = (
  deviceMacAddress: string,
  devices: Device[]
) => {
  const device = devices.find(({ mac }) => mac === deviceMacAddress);
  return device ? device.type : undefined;
};

export const isCaregiverType = (deviceType: string) =>
  Object.keys(caregiverDeviceTypes).includes(deviceType);

export const isRoomType = (deviceType: string) =>
  Object.keys(roomDeviceTypes).includes(deviceType);

export const isAssetType = (deviceType: string) =>
  deviceType === 'asset_tracker';

export const isResidentType = (deviceType: string, facilityType: string) =>
  Object.keys(residentDeviceTypes(facilityType)).includes(deviceType);

export const isGateway = (deviceType: string) => deviceType === 'iot_gateway';

export const getRoomDevices = (devices: Device[]) =>
  devices.filter(({ isActive, type }) => isActive && type === 'iot_gateway');

export const deviceIsAssignedToRoom = (
  devices: Device[],
  deviceMac: string,
  roomId?: string
) =>
  !!devices.find(device => {
    const hasRoom =
      device.room && roomId ? device.room.id !== roomId : device.room;
    return device.type === 'iot_gateway' && hasRoom && device.mac === deviceMac;
  });

export const getAvailableResidentDevices = (
  devices: Device[],
  facilityType: string
) =>
  devices.filter(
    ({ isActive, type, resident }) =>
      isActive && isResidentType(type, facilityType) && !resident
  );

export const getAvailableCaregiverDevices = (devices: Device[]) =>
  devices.filter(
    ({ isActive, type }) => isActive && type === 'caregiver_badge'
  );

export const findDuplicatedDevices = (devices: any[]) =>
  devices.reduce(
    (duplicates: string[], current: any, index: number, sourceArray: any[]) => {
      const [inputName, value] = current;
      const firstOccurrence = sourceArray.findIndex(
        ([, mac]) => mac && mac === value
      );
      if (firstOccurrence !== -1 && firstOccurrence !== index) {
        return [...duplicates, inputName];
      }
      return duplicates;
    },
    []
  );

export const getRoomDeviceValidationRules = (
  inputName: string,
  formValues: any,
  availableDevices: Device[],
  roomId?: string
) => ({
  validate: {
    duplicate: (value: string) => {
      const {
        number: roomNumber,
        ward,
        residents,
        ...deviceValues
      } = formValues();

      const duplicateKeys = Object.keys(deviceValues).filter(
        (deviceName: string) =>
          value && (deviceValues as any)[deviceName] === value
      );
      const isFirst = duplicateKeys.length && duplicateKeys[0] === inputName;

      return (
        duplicateKeys.length <= 1 ||
        isFirst ||
        'This device has already been entered.'
      );
    },
    typeMatch: (value: string) =>
      !value
        ? true
        : !!getTypeForDevice(value, availableDevices) ||
          `Device not found. Please make sure that the ID you entered is configured up in the system.`,
    available: (value: string) =>
      !deviceIsAssignedToRoom(availableDevices, value, roomId) ||
      'This device is already assigned to a room.'
  }
});

export const getKeyForDeviceType = (
  deviceType: string,
  facilityType: string
) => {
  if (isResidentType(deviceType, facilityType)) {
    return 'resident';
  } else if (isCaregiverType(deviceType)) {
    return 'caregiver';
  } else if (isRoomType(deviceType)) {
    return 'room';
  }
};
