import React from 'react';

import styles from './HistoryLogEntry.module.css';

export const HistoryLogEntry: React.FunctionComponent<{
  timestamp: string;
  date: string;
}> = ({ timestamp, date, children }) => {
  return (
    <li className={styles.logEntry}>
      <div className={styles.detailWrapper}>
        <span className={styles.topSpan}>{timestamp}</span>
        <span className={styles.bottomSpan}>{date}</span>
      </div>
      {children}
    </li>
  );
};
