import classnames from 'classnames';
import React from 'react';

import { RoundButton } from './RoundButton';

import styles from './RoundCheckButton.module.css';

import { ReactComponent as CheckIcon } from 'icons/checkmark.svg';

export const RoundCheckButton: React.FunctionComponent<{
  onClick: (e: any) => void;
  disabled?: boolean;
  testId?: string;
}> = ({ onClick, disabled, testId }) => (
  <RoundButton
    className={classnames(styles.roundCheckButton, {
      [styles.grey]: disabled
    })}
    onClick={onClick}
    disabled={disabled}
    testId={testId}
  >
    <CheckIcon />
  </RoundButton>
);
