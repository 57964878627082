import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom';

import { useAuthState, UserRole } from 'Auth';

interface CustomRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  roles?: UserRole[];
}
type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

export const PrivateAccessRoute: React.FunctionComponent<CustomRouteProps> = ({
  component: Component,
  roles,
  location,
  ...rest
}) => {
  const { isAuthenticated, role } = useAuthState();

  const renderComponent: RenderComponent = props => {
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      );
    }
    // check if route is restricted for user role
    if (roles && role && !roles.includes(role as UserRole)) {
      return (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={renderComponent} />;
};
