import { errorMessages } from 'consts';
import React from 'react';
import { FieldError } from 'react-hook-form/dist/types';

import styles from 'styles/errors.module.css';

export const handleError = (error: any, label = 'resident', type = 'any') => {
  if (typeof error === 'object' && error.data) {
    const { data, status } = error;
    const code = data.code || data.error;
    const errorMatch = errorMessages(label).find(err =>
      type !== 'any'
        ? code === err.code && status === err.statusCode && type === err.type
        : code === err.code && status === err.statusCode
    );
    if (errorMatch) {
      return errorMatch;
    }
    return error;
  }
  return error;
};

export const handleAuthError = (error: any) => {
  if (typeof error === 'object' && error.status === 401) {
    return error;
  }
  return null;
};

export const getFormValidationErrors = (
  errors: Partial<Record<string, FieldError>>
) => {
  const errorEntries = Object.entries(errors);
  if (!errorEntries.length) {
    return null;
  }

  return errorEntries.reduce((messages: any[], errorEntry) => {
    const [type, error] = errorEntry;
    if (error && error.message) {
      const errorContent = (
        <h3 className={styles.validationError} key={type}>
          {error.message}
        </h3>
      );
      return [...messages, errorContent];
    }
    return messages;
  }, [] as string[]);
};
