import { ChatId } from 'types';
import { axiosWrapper } from 'utils';

export const changeChatRoomName = async (
  chatRoomId: ChatId,
  chatName: string,
  residentId?: string
) => {
  const searchParams = new URLSearchParams();
  searchParams.append(chatRoomId, chatRoomId);
  if (residentId) {
    searchParams.append(residentId, residentId);
  }

  const body = {
    chatRoomName: chatName
  };

  await axiosWrapper(
    `/chat-rooms?chatRoomId=${chatRoomId}`,
    'PATCH',
    undefined,
    body,
    undefined,
    searchParams
  );
};
