import { Room } from './types';

export const formatCurrentLocation = (room: Room) => {
  if (!room) {
    return 'Unknown';
  }

  const { ward } = room;

  const hasWard = ward && ward.name;
  const hasRoom = !!room.number;

  if (hasWard && hasRoom) {
    return `${ward.name} - ${room.number}`;
  }

  return hasWard ? ward.name : room.number;
};
