import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  DetailBox,
  DismissibleError,
  FormHeader,
  Input,
  Select
} from 'components';
import {
  DARK_GREY_COLOR,
  ERROR_COLOR,
  GREEN_COLOR,
  relationships,
  WHITE_COLOR
} from 'consts';
import { RelatedContactStatuses } from 'consts/relatedContacts.constant';
import { usePoster } from 'hooks';
import {
  RelatedContactStatus,
  resendVerificationEmailRelatedContact,
  ResidentContact
} from 'Settings';
import {
  EMAIL_PATTERN,
  RESEND_ACTIVATION_EMAIL,
  VALIDATION_MESSAGE
} from 'Settings/constants';
import { getFormValidationErrors, sortByKey } from 'utils';

import stylesForm from 'styles/form.module.css';
import styles from './EditRelatedContactForm.module.css';

const getStatusColor = (value: string) =>
  value === RelatedContactStatuses.PENDING ||
  value === RelatedContactStatuses.REVOKED
    ? DARK_GREY_COLOR
    : value === RelatedContactStatuses.ACTIVE
    ? GREEN_COLOR
    : ERROR_COLOR;

const getStatusOptions = (status: RelatedContactStatus) => {
  const editableStatuses = [
    RelatedContactStatuses.INACTIVE,
    RelatedContactStatuses.REVOKED
  ];
  const defaultOptions = [
    { label: 'Inactive', value: RelatedContactStatuses.INACTIVE },
    { label: 'Revoked', value: RelatedContactStatuses.REVOKED }
  ];

  if (!editableStatuses.includes(status)) {
    return sortByKey(
      [
        ...defaultOptions,
        {
          value: status,
          label: status.slice(0, 1).toUpperCase() + status.slice(1)
        }
      ],
      'label'
    );
  }
  return defaultOptions;
};

interface EditRelatedContactFormData {
  name: string;
  relationship: string;
  email: string;
  phoneNumber: string;
  status: string;
}

export const EditRelatedContactForm: React.FunctionComponent<{
  relatedContact: ResidentContact;
  onClose: () => void;
  onSave: (updates: ResidentContact) => void;
  onSaveLoading: boolean;
  errorName: string;
  visible: boolean;
  error: Error | null;
  dismiss: () => void;
}> = ({
  onClose,
  onSave,
  relatedContact,
  onSaveLoading,
  errorName,
  visible,
  error,
  dismiss
}) => {
  const getDefaultValues = () => {
    const { name, relationship, status, email, phoneNumber } = relatedContact;
    return { name, relationship, status, email, phoneNumber };
  };
  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<EditRelatedContactFormData>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
    submitFocusError: false
  });

  const [emailWasResend, setEmailWasResend] = useState(false);
  const [emailFieldIsPristine, setEmailFieldIsPristine] = useState(true);
  const [statusOptions, setStatusOptions] = useState<
    Array<{
      label: 'Active' | 'Inactive' | 'Pending' | 'Revoked';
      value: RelatedContactStatus;
    }>
  >([]);

  useEffect(() => {
    if (relatedContact.status) {
      const options = getStatusOptions(relatedContact.status);
      setStatusOptions(options);
    }
  }, [relatedContact]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const formData = getValues();
      onSave({
        ...relatedContact,
        ...formData
      } as ResidentContact);
    }
  };

  const relationshipOptions = relationships.map(relationship => ({
    value: relationship,
    label: relationship
  }));

  const customListStyle = {
    singleValue: {
      color: getStatusColor(watch('status'))
    },
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color: state.isSelected ? WHITE_COLOR : getStatusColor(state.data.value)
      };
    }
  };

  const validationErrors = getFormValidationErrors(errors);

  const { setAction } = usePoster();

  const resendActivationEmailHandler = () => {
    if (relatedContact.status === RelatedContactStatuses.PENDING) {
      setAction(
        resendVerificationEmailRelatedContact(
          relatedContact.id,
          relatedContact.residentId
        )
      );
      setEmailWasResend(true);
    }
  };

  const emailInputChangeHandler = (evt: any) => {
    const { value } = evt.target;
    if (
      relatedContact.status === RelatedContactStatuses.PENDING &&
      value !== relatedContact.email
    ) {
      setEmailFieldIsPristine(false);
    } else {
      setEmailFieldIsPristine(true);
    }
    if (error) {
      dismiss();
    }
  };

  const handlePreventDefault = (e: any) => e.preventDefault();

  return (
    <>
      <FormHeader
        title="Edit Related Contact"
        onCancel={onClose}
        onSubmit={handleSubmit}
        submitDisabled={onSaveLoading}
      />
      <form onSubmit={handlePreventDefault} className={stylesForm.visible}>
        <div className={stylesForm.formRow}>
          <div className={stylesForm.inputGroupHalf}>
            <Input
              name="name"
              label="Contact Name"
              register={register}
              validationRules={{ required: true }}
              hasError={!!errors.name}
            />
          </div>
          <div className={stylesForm.selectGroupQuarter}>
            <Select
              name="relationship"
              label="Relationship"
              options={relationshipOptions}
              hasError={!!errors.relationship}
              register={register}
              onChange={setValue}
              value={watch('relationship')}
              required={true}
            />
          </div>
          <div className={stylesForm.selectGroupStatusSmall}>
            {relatedContact.status === RelatedContactStatuses.PENDING ? (
              <DetailBox label="Status" value="Pending" />
            ) : (
              <Select
                name="status"
                label="Status"
                options={statusOptions}
                hasError={!!errors.status}
                register={register}
                onChange={setValue}
                value={watch('status')}
                required={true}
                optionsStyle={customListStyle}
              />
            )}
          </div>
        </div>

        <div className={stylesForm.formRow}>
          <div className={stylesForm.inputGroupQuarter}>
            <Input
              name="email"
              label="E-mail Address"
              register={register}
              hasError={!!errors.email || visible}
              onChange={emailInputChangeHandler}
              validationRules={{
                required: true,
                pattern: {
                  value: EMAIL_PATTERN,
                  message: VALIDATION_MESSAGE
                }
              }}
            />
          </div>
          <div className={stylesForm.inputGroupQuarter}>
            <Input
              name="phoneNumber"
              label="Phone Number"
              register={register}
              hasError={!!errors.phoneNumber}
            />
          </div>
          {emailFieldIsPristine &&
            relatedContact.status === RelatedContactStatuses.PENDING && (
              <div className={styles.buttonContainer}>
                {!emailWasResend && (
                  <button
                    className={styles.activationEmailButton}
                    onClick={resendActivationEmailHandler}
                  >
                    {RESEND_ACTIVATION_EMAIL}
                  </button>
                )}
              </div>
            )}
        </div>
        <DismissibleError
          name={errorName}
          visible={visible}
          error={error}
          dismiss={dismiss}
        />
        {validationErrors && (
          <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
        )}
      </form>
    </>
  );
};
