import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react';

import { StatusTypes } from 'Caregivers';
import {
  IAdHocCaregiver,
  IAdHocManageMembersProps,
  isValidSearchTerm
} from 'Communication';
import { CaregiverProfileStatus, Modal, SearchHeader } from 'components';
import { useModalMeasurements } from 'hooks';
import { Caregiver, fetchCaregivers } from 'Settings';
import { LoginId } from 'types';
import { debounce, formatName } from 'utils';

import { AdHocList } from './AdHocList';
import { AdHocMember } from './AdHocMember';
import { DEAFULT_SEARCH_TERM } from './constants';

import styles from './AdHocManageMembers.module.css';

export const AdHocManageMembers: FunctionComponent<IAdHocManageMembersProps> = ({
  showModal,
  onClose = () => undefined,
  members,
  onDeselect,
  onSelect,
  facilityId,
  statuses,
  statusLoading,
  statusError,
  userLoginId
}) => {
  const [selectedMembers, setSelectedMembers] = useState<JSX.Element[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(DEAFULT_SEARCH_TERM);
  const [fetchMore, setFetchMore] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const [tempList, setTempList] = useState<Caregiver[]>([]);
  const [tempError, setTempError] = useState<Error | null>(null);
  const [tempLoading, setTempLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchList, setSearchList] = useState<IAdHocCaregiver[]>([]);
  const [selectedIds, setSelectedIds] = useState<LoginId[]>([]);

  const handleClose = () => {
    onClose(false);
  };

  const {
    modalOffsetTop,
    modalHeight,
    scrollableAncestorHeight,
    modalOffsetLeft
  } = useModalMeasurements('AdHocDetails', 'MainContent', showModal);

  const computedModalHeight =
    modalHeight > scrollableAncestorHeight - 60
      ? scrollableAncestorHeight - 60
      : modalHeight;

  const handleAddMember = (id: LoginId) => {
    setSelectedIds(prevLoginIds => {
      return [...prevLoginIds, id];
    });
    onSelect(id);
  };
  const handleRemoveMember = (id: LoginId) => {
    setSelectedIds(prevLoginIds => {
      return prevLoginIds.filter(loginId => loginId !== id);
    });
    onDeselect(id);
  };

  useEffect(() => {
    const list = members.map(member => {
      const { loginId, name, role, id, photoUrl } = member;
      return (
        <AdHocMember
          loginId={loginId}
          name={formatName(name)}
          role={role}
          photoUrl={photoUrl}
          key={id}
          isSelected={true}
          onAdd={handleAddMember}
          onRemove={handleRemoveMember}
        />
      );
    });

    const selectedLoginIds = members.map(member => member.loginId);
    setSelectedIds(selectedLoginIds);
    setSelectedMembers(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  useEffect(() => {
    setNextPage('0');
    setFetchMore(true);
  }, [facilityId]);

  useEffect(() => {
    if (fetchMore && nextPage) {
      setFetchMore(false);
      fetchCaregivers(
        facilityId,
        searchTerm,
        setTempList,
        setNextPage,
        setTempError,
        setTempLoading,
        nextPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, nextPage]);

  const resetLocalState = useCallback(() => {
    setSearchList([]);
    setNextPage('0');
    setFetchMore(true);
  }, []);

  useEffect(
    () => {
      if (isValidSearchTerm(searchTerm) && !searching && !tempLoading) {
        setSearching(true);
      }
      if (isValidSearchTerm(searchTerm) && searching && !tempLoading) {
        resetLocalState();
      }
      if (!isValidSearchTerm(searchTerm) && searching && !tempLoading) {
        setSearching(false);
        resetLocalState();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, searching]
  );

  useEffect(() => {
    if (
      tempList.length === 0 ||
      tempLoading ||
      tempError ||
      statusLoading ||
      statusError
    ) {
      return undefined;
    }

    const activeList = tempList.filter(item => {
      return item.isActive === true && item.loginId !== userLoginId;
    });

    return setSearchList(prevLocal => {
      return [...prevLocal, ...activeList].map(item => {
        const status: CaregiverProfileStatus =
          statuses[item.id] === StatusTypes.OFF_DUTY
            ? 'unavailable'
            : 'available';

        return {
          ...item,
          status
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempList, statuses, tempLoading, tempError, statusLoading, statusError]);

  const handleFetchMore = useCallback(
    value => {
      if (nextPage) {
        setFetchMore(value);
      }
    },
    [nextPage]
  );

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 300);

  return (
    <Modal isShowing={showModal} toggle={handleClose}>
      <div className={styles.modalBackdrop}>
        <div
          className={styles.modalContent}
          style={{
            marginTop: modalOffsetTop + 110,
            height: computedModalHeight,
            marginLeft: modalOffsetLeft
          }}
        >
          <div className={styles.listsContainer}>
            <SearchHeader
              searchInputName="caregivers"
              onClose={handleClose}
              onSearch={handleSearch}
              searchValue={searchTerm}
            />
            <div className={styles.searchContainer}>
              <AdHocList
                items={searchList}
                selectedItemsIds={selectedIds}
                onSelect={handleAddMember}
                onDeselect={handleRemoveMember}
                loadingItems={tempLoading}
                itemsError={tempError}
                refetch={handleFetchMore}
                customListStyle={styles.searchList}
                customItemStyle={styles.searchListItem}
                customInfoStyle={styles.itemInfo}
                customButtonsStyle={styles.buttons}
              />
            </div>
            {!!selectedMembers.length && (
              <ul className={styles.selectedItems}>{selectedMembers}</ul>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
