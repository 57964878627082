import React from 'react';

import { BackButton, RoundSearchButton } from 'components';

import style from './StaffListHeader.module.css';

export const StaffListHeader: React.FunctionComponent<{
  toggleSearch: () => void;
}> = ({ toggleSearch }) => {
  return (
    <header className={style.header}>
      <BackButton title="Care Team" link="/Caregivers" />
      <RoundSearchButton onClick={toggleSearch} testId="search-test-button" />
    </header>
  );
};
