import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  DismissibleError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  StatusDropdown
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { getFormValidationErrors, removeExtraSpaces } from 'utils';

import { createWard } from '../actions';
import { Ward, WardError } from '../types';

import stylesForm from 'styles/form.module.css';

interface AddWardFormData {
  name: string;
  isActive: boolean;
}

export const AddWardForm: React.FunctionComponent<{
  facilityId: string;
  toggle: () => void;
  onWardAdded: (wardId: string) => void;
}> = ({ toggle, onWardAdded, facilityId }) => {
  const [localErrors, setLocalErrors] = useState<{
    name: boolean;
  }>({ name: false });

  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<AddWardFormData>({
    mode: 'onBlur',
    defaultValues: {
      isActive: true
    },
    submitFocusError: false
  });
  const { data: newWardId, setAction, loading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, loading);

  const saveWard = (ward: Omit<Ward, 'toggleFeature'>) => {
    setAction(createWard(ward));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isValid = await triggerValidation();
    if (isValid) {
      const formData = getValues();

      const { name, isActive } = formData;

      saveWard({
        facilityId,
        isActive,
        name: removeExtraSpaces(name)
      });
    }
  };

  useEffect(() => {
    let nameHasError = false;

    if (
      errors.name ||
      (isErrorShowing &&
        error &&
        (error as WardError).code === 'ward.name.unique')
    ) {
      nameHasError = true;
    }

    setLocalErrors({
      name: nameHasError
    });
  }, [errors, error, isErrorShowing]);

  useEffect(() => {
    if (!submitted || loading || error) {
      return undefined;
    }
    toggle();
    if (newWardId) {
      onWardAdded(newWardId);
    }
  }, [submitted, loading, error, toggle, newWardId, onWardAdded]);

  const validationErrors = getFormValidationErrors(errors);

  const handleInputChange = async () => {
    if (error) {
      dismissError();
    }
  };

  return (
    <>
      <FormHeader
        title="Add New Ward"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={loading}
      />
      {loading && <LoadingPlaceholder />}
      <form
        onSubmit={handleSubmit}
        className={classnames(stylesForm.visible, {
          [stylesForm.hidden]: loading
        })}
      >
        <div className={stylesForm.formRow}>
          <div className={stylesForm.inputGroupQuarter}>
            <Input
              name="name"
              label="Ward Name"
              register={register}
              validationRules={{ required: true }}
              hasError={Boolean(localErrors.name)}
              onChange={handleInputChange}
            />
          </div>
          <div className={stylesForm.selectGroupStatusSmall}>
            <StatusDropdown
              register={register}
              onChange={setValue}
              value={watch('isActive')}
              hasError={!!errors.isActive}
            />
          </div>
        </div>
        <DismissibleError
          name="Ward"
          visible={isErrorShowing}
          error={error}
          dismiss={dismissError}
        />
        {validationErrors && (
          <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
        )}
      </form>
    </>
  );
};
