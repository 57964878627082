import React, { SyntheticEvent, useEffect } from 'react';

import { AutocompleteSelect } from 'components';
import { DropdownOption } from 'components/types';

import styles from './Select.module.css';

export const Select: React.FunctionComponent<{
  name: string;
  label: string;
  options: DropdownOption[];
  optionsStyle?: any;
  value: any;
  register: any;
  hasError: boolean;
  onChange: (name: any, value: any, revalidate: boolean) => void;
  onBlur?: (e: SyntheticEvent) => void;
  required?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  noOptionsMessage?: any;
  isDisabled?: boolean;
  sortField?: string;
}> = ({
  name,
  label,
  options,
  optionsStyle,
  value,
  register,
  required = false,
  hasError,
  onChange,
  onBlur,
  isMulti,
  isClearable,
  noOptionsMessage,
  children,
  ...props
}) => {
  const handleChange = (selected: any) => {
    onChange(name, selected, true);
  };

  const handleBlur = (e: SyntheticEvent) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    const validation = required
      ? {
          required: `${label} field is required.`
        }
      : { required };

    register({ name, type: 'custom' }, validation);
  }, [register, name, required, label]);

  return (
    <>
      <label className={styles.inputLabel}>
        <span className={styles.spanCollapse}>
          {children}
          {label}
        </span>
        <span className={styles.spanFixed}>:{required && ' *'}</span>
      </label>
      <AutocompleteSelect
        options={options}
        dropdownStyles={optionsStyle}
        hasError={hasError}
        onChange={handleChange}
        value={value}
        isMulti={isMulti}
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        onBlur={handleBlur}
        {...props}
      />
    </>
  );
};
