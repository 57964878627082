import { CallType } from './chat.type';

export interface IMissedCallsResponse {
  items: IMissedCall[];
  nextPage?: number;
}

export interface IMissedCall {
  id: string;
  timestamp: string;
  loginSenderId: string;
  callId: string;
  callType: CallType;
  chatRoomId: string;
  seen: boolean;
}

export interface ICommunicationCounts {
  missedAudioCalls: number;
  missedVideoCalls: number;
  missedTextMessages: number;
}

export interface IMissedCallCount {
  chatRoomId: string;
  audioMissedCallCount: number;
  videoMissedCallCount: number;
}

export enum MissedCallActionTypes {
  SET_MISSED_CALLS = 'SET_MISSED_CALLS'
}
