import { axiosWrapper } from 'utils';

import { ResidentContact } from 'Settings';

export const editResidentContact = (
  residentId: string,
  residentContact: ResidentContact
) => async () => {
  const url = `/residents/${residentId}/related-contacts/${residentContact.id}`;
  const response = await axiosWrapper(url, 'PUT', null, residentContact);

  return response.data;
};
