import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { CaregiverSchedule, getWardAssignments } from 'Caregivers';
import { FetchError } from 'components';
import { useFetcher } from 'hooks';

import { DailyScheduleEntry } from './DailyScheduleEntry';
import { DailyScheduleLoadingPlaceholder } from './DailyScheduleLoadingPlaceholder';

import { sortByKey } from 'utils';
import styles from './DailyScheduleLogs.module.css';

const placeholder = (
  <p className={styles.emptyPlaceholder}>No work scheduled for this day</p>
);

export const DailyScheduleLogs: React.FunctionComponent<{
  caregiverId: string;
  date: Date;
}> = ({ caregiverId, date }) => {
  const [title, setTitle] = useState<string>('');
  const [ScheduleLogContent, setScheduleLogContent] = useState<JSX.Element>(
    placeholder
  );

  const wardAssignmentsAction = useMemo(() => {
    if (caregiverId) {
      return getWardAssignments({
        workingDay: moment(date).format('YYYY-MM-DD'),
        caregiverId
      });
    }
  }, [caregiverId, date]);

  const { data: wardAssignments, error, loading } = useFetcher<
    CaregiverSchedule[]
  >(wardAssignmentsAction, []);

  useEffect(() => {
    if (loading || error) {
      return undefined;
    }

    const groups = wardAssignments.reduce((acc: any[], assignment) => {
      const existingFacility = acc.find(
        (fac: any) => fac.facilityId === assignment.facilityId
      );
      if (existingFacility) {
        existingFacility.wards.push(assignment.wardName);
        existingFacility.wards.sort();
      } else {
        acc.push({
          facilityId: assignment.facilityId,
          facilityName: assignment.facilityName,
          wards: [assignment.wardName]
        });
      }

      return sortByKey(acc, 'facilityName');
    }, []);

    if (groups.length === 0) {
      setScheduleLogContent(placeholder);
    } else {
      const list = groups.map(({ facilityName, wards, facilityId }) => (
        <DailyScheduleEntry
          facilityName={facilityName}
          key={facilityId}
          wards={wards}
        />
      ));
      setScheduleLogContent(<ul className={styles.logs}>{list}</ul>);
    }
  }, [wardAssignments, error, loading]);

  useEffect(() => {
    setTitle(moment(date).format('MMMM D, YYYY'));
  }, [date]);

  return (
    <div className={styles.logsWrapper}>
      <h1 className={styles.title}>{title}</h1>
      {!loading && !error && ScheduleLogContent}
      {loading && !error && <DailyScheduleLoadingPlaceholder />}
      {!loading && error && (
        <div className={styles.hasError}>
          <FetchError error={error} />
        </div>
      )}
    </div>
  );
};
