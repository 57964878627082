import React from 'react';

import styles from './GraphSpinner.module.css';

export const GraphSpinner: React.FunctionComponent<{ color?: string }> = ({
  color = '#FFF'
}) => (
  <div className={styles.loadingContainer}>
    <div className={styles.first} style={{ background: color }} />
    <div className={styles.second} style={{ background: color }} />
    <div className={styles.third} style={{ background: color }} />
  </div>
);
