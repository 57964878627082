import React from 'react';

import {
  GraphLoadingPlaceholder,
  HistoryFilterTabs,
  HistoryLabel,
  SleepingHours
} from 'Residents';

import { SLEEPING_HOURS_COLOR } from '../chartColors.const';
import { SleepingHoursChart } from './SleepingHoursChart';
import { toMonthData } from './toMonthData';
import { toWeekData } from './toWeekData';

import styles from './../Charts.module.css';

export const SleepingHoursHistoryChart: React.FunctionComponent<{
  changeHistoryFilter: (filter: HistoryLabel) => void;
  activeHistoryTab: HistoryLabel;
  readings: SleepingHours[];
  loading: boolean;
}> = ({ changeHistoryFilter, activeHistoryTab, readings, loading }) => {
  return (
    <div className={styles.chartSleepingHours}>
      <HistoryFilterTabs
        onSelect={changeHistoryFilter}
        activeTab={activeHistoryTab}
        color={SLEEPING_HOURS_COLOR}
      />
      {!loading && (
        <SleepingHoursChart
          data={
            activeHistoryTab !== 'This Month'
              ? toWeekData(readings)
              : toMonthData(readings)
          }
          allowHighlight={activeHistoryTab !== 'Last Week'}
        />
      )}
      {loading && <GraphLoadingPlaceholder color={SLEEPING_HOURS_COLOR} />}
    </div>
  );
};
