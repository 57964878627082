import classnames from 'classnames';
import React from 'react';

import styles from './DetailBox.module.css';

export const DetailBox: React.FunctionComponent<{
  label: string;
  value: any;
  isLink?: boolean;
}> = ({ label, value, isLink = false }) => (
  <div className={styles.detailWrapper}>
    <span className={styles.labelSpan}>{label}</span>
    <span
      className={classnames(styles.valueSpan, {
        [styles.link]: isLink && value
      })}
    >
      {value || 'N/A'}
    </span>
  </div>
);
