import { axiosWrapper } from 'utils';

import { MAX_PAGE_SIZE } from 'consts';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { Ward } from 'Settings/types';
import { mapWard } from './featureMapping.actions';

export const getPaginatedWards = (
  facilityId: string,
  page: number
) => async () => {
  const initialUrl = `/facilities/${facilityId}/wards`;

  const url =
    page !== undefined
      ? `${initialUrl}?page=${page}&size=${MAX_PAGE_SIZE}`
      : initialUrl;
  const response = await axiosWrapper<{ items: Ward[]; nextPage: string }>(url);

  const mappedWards: MappedWard[] = response.data.items.map(mapWard);

  return {
    items: mappedWards,
    nextPage: response.data.nextPage
  };
};
