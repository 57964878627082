export const sleepingHoursInterval = (
  data: Array<{
    label: string;
    hours: number;
  }>
) => {
  let max = 0;
  let min = 2147483647;
  data.forEach(entry => {
    if (entry.hours < min) {
      min = entry.hours;
    }
    if (entry.hours > max) {
      max = entry.hours;
    }
  });

  if (min === 2147483647) {
    min = 0;
  }
  let interval = 1;

  const intervalMin = Math.trunc(min / 10) * 10;

  let intervalMax = 24;

  if (max < intervalMax) {
    return [0, 8, 16, 24];
  }

  // intervalMax must be bigger than the max of the values plus
  //  3 quarters of the interval so that the warning svg is always inside the graph
  while (intervalMax < max + (interval / 4) * 3) {
    intervalMax = intervalMin + 3 * interval;
    interval += 1;
  }
  // we return an arrray representing the ticks of the y axis, the first and last representing
  // the min and max values of the y axis === the domain

  return [
    intervalMin,
    (intervalMax - intervalMin) / 3 + intervalMin,
    (2 * (intervalMax - intervalMin)) / 3 + intervalMin,
    intervalMax
  ];
};
