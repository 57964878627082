import React from 'react';
import { Tooltip } from 'recharts';
import style from './ToolTip.module.css';

const Content: React.FunctionComponent<{
  payload?: any;
}> = props => {
  if (props.payload[0]) {
    return (
      <div className={style.oxygenSaturationTooltip}>
        <span>{`${props.payload[0].payload.label}`}</span>
        <span>
          Oxygen Saturation:{' '}
          <b>{`${props.payload[0].payload.oxygenSaturation}`}</b>
          {` ${props.payload[0].payload.unit}`}
        </span>
      </div>
    );
  }
  return <></>;
};

export const OxygenSaturationToolTip = <Tooltip content={Content} />;
