import React, { useState } from 'react';

import { RolesEnum, useAuthState } from 'Auth';
import { DetailsPlaceholder, TwoColumnLayout } from 'components';
import { Device, Facility } from 'Settings/types';
import { DeviceDetails } from './DeviceDetails';
import { DeviceList } from './DeviceList';
import deviceStyles from './DeviceSettings.module.css';

export const DeviceSettings: React.FunctionComponent<{
  facility: Facility;
  activeDevice: string;
}> = ({ facility, activeDevice }) => {
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>(
    activeDevice
  );

  const { role } = useAuthState();

  const [modifiedDevice, setModifiedDevice] = useState<Device>({} as Device);

  return (
    <>
      <TwoColumnLayout
        left={
          <DeviceList
            facility={facility}
            onDeviceSelect={setSelectedDeviceId}
            selectedDeviceId={selectedDeviceId}
            modifiedDevice={modifiedDevice}
            hasDeviceAccess={role === RolesEnum.ROLE_ADMIN}
          />
        }
        right={
          <div className={deviceStyles.panelHeight}>
            {selectedDeviceId !== '' && (
              <DeviceDetails
                deviceId={selectedDeviceId}
                facility={facility}
                onModifiedDevice={setModifiedDevice}
                hasDeviceAccess={role === RolesEnum.ROLE_ADMIN}
              />
            )}
            {selectedDeviceId === '' && (
              <DetailsPlaceholder
                message="Please select device to display details"
                title="Device Details"
              />
            )}
          </div>
        }
      />
    </>
  );
};
