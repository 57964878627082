import React, { useMemo } from 'react';

import {
  PanelHeader,
  RoundAudioCallButton,
  RoundEditButton,
  RoundVideoCallButton,
  WithPermissions
} from 'components';
import { concatTitle, sortByKey } from 'utils';
import { Participant } from './types';

import styles from './ChatWindowHeader.module.css';

export const ChatWindowHeader: React.FunctionComponent<{
  title: string;
  participants: Participant[] | undefined;
  isAvailable: boolean;
  onAudioCall?: () => void;
  onVideoCall?: () => void;
  onEdit?: () => void;
  isVisible?: boolean;
}> = ({
  title,
  participants,
  isAvailable,
  onAudioCall,
  onVideoCall,
  onEdit,
  isVisible
}) => {
  const sortedParticipants = useMemo(
    () => participants && sortByKey(participants, 'name'),
    [participants]
  );
  const participantsNames = useMemo(
    () => sortedParticipants && concatTitle(sortedParticipants, 'name', ', '),
    [sortedParticipants]
  );

  return (
    <div className={styles.chatHeaderContainer}>
      <PanelHeader title={title} participants={Boolean(participants?.length)}>
        {isVisible && (
          <WithPermissions
            action="communication-buttons:view"
            data={{ isAvailable }}
            yes={
              <>
                {onEdit && <RoundEditButton onClick={onEdit} />}
                {onAudioCall && (
                  <RoundAudioCallButton
                    onClick={onAudioCall}
                    testId="audio-call-test-button"
                  />
                )}
                {onVideoCall && (
                  <RoundVideoCallButton
                    onClick={onVideoCall}
                    testId="video-call-test-button"
                  />
                )}
              </>
            }
          />
        )}
      </PanelHeader>
      {participants && (
        <div title={participantsNames} className={styles.participants}>
          {participantsNames}
        </div>
      )}
    </div>
  );
};
