import moment from 'moment';

import { BodyTemperature, emptyMonth, weekOfMonth } from 'Residents';

import { warningCodes } from '../consts/warningCodes.constant';

const convertReadings = (week: string, readings: BodyTemperature[]) => {
  const BodyTemperatureReadings = readings
    .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
    .map(entry => parseFloat(entry.value.bodyTemperature));

  const bodyTemperature =
    BodyTemperatureReadings.length > 0
      ? Math.round(
          (BodyTemperatureReadings.reduce((a, b) => a + b) /
            BodyTemperatureReadings.length) *
            10
        ) / 10
      : undefined;

  const hasWarning = warningCodes.some(code =>
    readings
      .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
      .map(entry => entry.code)
      .includes(code as 'GREEN' | 'YELLOW' | 'ORANGE' | 'RED')
  );

  return {
    bodyTemperature,
    hasWarning,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toMonthData = (data: BodyTemperature[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
