import moment from 'moment';

import { ChartLabelType } from './XAxisTick';

export const weekOfMonth = (date: moment.Moment) => {
  const now = date.locale('us'); // making sure we have the right locale, by default=us
  let weekInYearIndex = now.week();
  if (now.year() !== now.weekYear()) {
    weekInYearIndex =
      now
        .clone()
        .subtract(1, 'week')
        .week() + 1;
  }

  return (
    weekInYearIndex -
    moment(now)
      .startOf('month')
      .week() +
    1
  );
};

export const emptyMonth = Array.from(
  Array(weekOfMonth(moment().endOf('month'))),
  (_, i) => {
    return ('Week ' + (i + 1)) as ChartLabelType;
  }
);

export const emptyWeek = [
  'Sun' as ChartLabelType,
  'Mon' as ChartLabelType,
  'Tue' as ChartLabelType,
  'Wed' as ChartLabelType,
  'Thu' as ChartLabelType,
  'Fri' as ChartLabelType,
  'Sat' as ChartLabelType
];
