import React from 'react';

import styles from './DailyScheduleEntry.module.css';

export const DailyScheduleEntry: React.FunctionComponent<{
  facilityName: string;
  wards: string[];
}> = ({ facilityName, wards }) => {
  return (
    <li className={styles.logEntry}>
      <p className={styles.detailLine}>
        <span className={styles.label}>Where:</span>
        <span className={styles.facilityDetail}>{facilityName}</span>
      </p>
      <p className={styles.wardsLine}>
        <span className={styles.label}>Ward(s):</span>
        <span className={styles.wardDetail}>{wards.join(', ')}</span>
      </p>
    </li>
  );
};
