import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DropdownMenu } from 'components';
import { ReactComponent as MyProfile } from 'icons/my-profile.svg';
import { ReactComponent as Logo } from 'icons/th-logo-small.svg';
import { NotificationsOverview } from 'Notifications';
import { SearchBar } from 'Search';
import { MyProfileDetails } from 'UserProfile';

import { LocationFilters } from './LocationFilters';

import iconStyles from '../../styles/icons.module.css';
import styles from './Header.module.css';

const includedTabs = [
  'Location',
  'Residents',
  'ResidentDashboard',
  'Caregivers',
  'CaregiverDashboard',
  'Schedule',
  'Home',
  'Communication'
];

const myProfileIcon = () => (
  <span className={styles.profileIconWrapper}>
    <MyProfile />
  </span>
);

export const Header: React.FunctionComponent = () => {
  const [showArrow, setShowArrow] = useState(true);

  return (
    <header className={styles.header}>
      <Logo className={iconStyles.headerLogo} data-testid="logo-icon" />
      <div className={styles.rightContentWrapper}>
        <Switch>
          <Route
            path={`/:activeTab(${includedTabs.join('|')})`}
            component={LocationFilters}
          />
        </Switch>
        <div className={styles.iconsContainer}>
          <SearchBar />
          <Switch>
            <Route
              path={`/:activeTab(${[...includedTabs, 'Search'].join('|')})`}
              component={NotificationsOverview}
            />
          </Switch>
          <DropdownMenu
            Icon={myProfileIcon}
            testId="profile-icon"
            dropdownArrow={showArrow}
          >
            <MyProfileDetails toggleStyle={setShowArrow} />
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};
