import { CaregiverStatus } from 'Caregivers';
import { axiosWrapper } from 'utils';

export const changeCaregiverStatus = (
  body: Partial<CaregiverStatus>
) => async () => {
  const baseUrl = `/facilities/${body.facilityId}/caregivers/${body.caregiverId}/caregiver-statuses`;
  const url = body.wardId ? `${baseUrl}?wardId=${body.wardId}` : baseUrl;
  const response = await axiosWrapper(url, 'POST', null, body);

  return response.data;
};
