export const profileDropdownStyles: {
  [key: string]: { [key: string]: any };
} = {
  control: {
    borderBottom: 'none',
    fontSize: '15px',
    height: '100%',
    padding: '0',
    alignItems: 'center',
    '&:hover': {
      borderBottom: 'none'
    },
    '& > div:first-of-type': {
      justifyContent: 'flex-end'
    }
  },
  dropdownIndicator: {
    backgroundPosition: 'center bottom 2px',
    height: '25px'
  },
  container: {
    flex: '0 1 auto',
    minHeight: '33px',
    padding: '3px 0 0 0'
  },
  placeholder: {
    height: '19px',
    margin: 0,
    paddingRight: '5px',
    right: 0,
    textAlign: 'left',
    opacity: 1,
    width: '100%'
  },
  singleValue: {
    height: '19px',
    margin: 0,
    paddingRight: '5px',
    right: 0,
    width: '100%',
    maxWidth: '100%',
    fontWeight: '500',
    fontSize: '14px'
  }
};
