import { RoundButton } from 'components';
import React from 'react';

import { ReactComponent as Refresh } from 'icons/auto-renew.svg';
import { ReactComponent as Cross } from 'icons/crossmark.svg';

import styles from './MessageError.module.css';

export const MessageError: React.FunctionComponent<{
  cancel: () => void;
  retry: () => void;
}> = ({ cancel, retry }) => {
  return (
    <div className={styles.errorMessage}>
      <span className={styles.redText}>The message failed to send!</span>
      <div className={styles.errorButtons}>
        <RoundButton className={styles.refreshButton} size={18} onClick={retry}>
          <Refresh />
        </RoundButton>
        <span className={styles.blueText}> Retry</span>
        <RoundButton className={styles.crossButton} size={18} onClick={cancel}>
          <Cross />
        </RoundButton>
        <span className={styles.redText}>Cancel</span>
      </div>
    </div>
  );
};
