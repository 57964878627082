import { axiosWrapper } from 'utils';

import { Device } from 'Settings/types';

export const createDevice = (device: Device) => async () => {
  const url = `/facilities/${device.facilityId}/devices`;
  const response = await axiosWrapper(url, 'POST', null, device);

  return response.headers.location.replace(`${url}/`, '');
};
