import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import { RoomAssignment } from 'Caregivers/types';
import {
  acceptCall,
  CallerDetails,
  CommunicationActionTypes,
  ConversationTypes,
  dismissCall,
  getRoomToken,
  useCommunicationContext
} from 'Communication';
import { joinCall, leaveCall, markMessageSeen } from 'Communication/actions';
import { CallTypes } from 'Communication/types';
import {
  CallInitiationModal,
  CallWrapper,
  Modal,
  RoundCrossButton,
  Snackbar
} from 'components';
import { useFetcher, usePoster, useSubmitError } from 'hooks';
import { useLocationState } from 'Location';
import { getRoomAssignments } from 'Residents/actions/getRoomAssignments.action';
import { getResidentDetails } from 'Settings/actions/getResidentDetails.action';
import { Resident } from 'Settings/types';
import { ComMessageUpdate, ComUpdateTypes, useSocketState } from 'Socket';
import { CallId, ChatId, MessageId } from 'types';
import { formatName, isIEorEdge } from 'utils';

import styles from './CallModal.module.css';

const initialResident: Resident = {
  id: '',
  isActive: false,
  address: '',
  city: '',
  name: '',
  number: '',
  state: '',
  zip: '',
  email: '',
  homePhoneNumber: '',
  cellPhoneNumber: '',
  gender: '',
  birthday: '',
  maritalStatus: '',
  room: { id: '', number: '', ward: { id: '', name: '' } },
  facilityId: ''
};

const initialCallerDetails: CallerDetails = {
  name: 'Someone',
  type: 'available',
  title: 'Unknown',
  photoUrl: undefined
};

export const CallModal: React.FunctionComponent<{}> = () => {
  const [startingCall, setStartingCall] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [called, setCalled] = useState(false);
  const [chatId, setChatId] = useState<ChatId | undefined>(undefined);
  const [callId, setCallId] = useState<CallId | undefined>(undefined);
  const [messageId, setMessageId] = useState<MessageId | undefined>(undefined);
  const [caller, setCaller] = useState<CallerDetails>(initialCallerDetails);
  const [domain, setDomain] = useState<string | undefined>(undefined);
  const [startTime, setStartTime] = useState();
  const [callAnswered, setCallAnswered] = useState(false);
  const [callHasAlreadyStarted, setCallHasAlreadyStarted] = useState(false);
  const [callStartedMessage, setCallStartedMessage] = useState<any>(null);

  const [wsUpdate, setWsUpdate] = useState<ComMessageUpdate | null>(null);

  const [
    { isVideoCallOn, isAudioCallOn, callee, showNotSupportedModal = false },
    dispatch
  ] = useCommunicationContext();

  const [isAudioCall, setIsAudioCall] = useState(isAudioCallOn);
  const { facility, ward: wardId } = useLocationState();
  const { comUpdate } = useSocketState();

  useEffect(() => {
    if (comUpdate) {
      setWsUpdate(comUpdate);
    }
  }, [comUpdate]);

  const { loginId, loggedUser } = useAuthState();

  const residentId = wsUpdate?.values?.intendedResidentId;
  const chatType = wsUpdate?.chatRoomType.toLowerCase();
  const callerName = wsUpdate?.values.callerName;

  const formattedCallerName = useMemo(() => formatName(callerName), [
    callerName
  ]);

  const residentAction = useMemo(() => {
    if (facility?.id && residentId) {
      return getResidentDetails(facility?.id, residentId);
    }
  }, [facility, residentId]);

  const { data: resident } = useFetcher<Resident>(
    residentAction,
    initialResident
  );

  const roomAssAction = useMemo(() => {
    if (resident.room && loggedUser && wardId && callId) {
      return getRoomAssignments({
        workingDay: moment().format('YYYY-MM-DD'),
        roomId: resident.room.id,
        caregiverId: loggedUser.id,
        wardId
      });
    }
  }, [loggedUser, wardId, resident.room, callId]);

  const { data: roomAssignmentsResponse } = useFetcher<RoomAssignment[]>(
    roomAssAction,
    []
  );

  useEffect(() => {
    if (
      (callId &&
        chatType === ConversationTypes.CARE_TEAM &&
        roomAssignmentsResponse.length > 0) ||
      (callId && chatType !== ConversationTypes.CARE_TEAM)
    ) {
      return setShowModal(true);
    }
    setShowModal(false);
  }, [callId, chatType, roomAssignmentsResponse]);

  const {
    setAction: setStopAction,
    error: stopError,
    loading: stopLoading
  } = usePoster();

  const {
    data: token,
    setAction: setTokenAction,
    loading: tokenLoading,
    error: tokenError
  } = usePoster();

  const {
    submitted: stopSubmitted,
    setSubmitted: setStopSubmitted,
    isErrorShowing: isStopErrorShowing,
    dismissError: dismissStopError
  } = useSubmitError(stopError, stopLoading);

  const {
    data: startData,
    setData: setStartData,
    setAction: setStartAction,
    error: startError,
    loading: startLoading
  } = usePoster();

  const {
    data: joinData,
    setAction: setJoinAction,
    error: joinError,
    loading: joinLoading
  } = usePoster();

  const { setAction: setMessageSeenAction } = usePoster();
  const {
    isErrorShowing: isStartErrorShowing,
    dismissError: dismissStartError
  } = useSubmitError(startError || tokenError, startLoading || tokenLoading);

  const answerCall = () => {
    if (chatId && messageId && callId) {
      setMessageSeenAction(markMessageSeen(chatId, messageId));

      if (callHasAlreadyStarted) {
        setJoinAction(joinCall(chatId, callId));
      } else {
        setStartAction(acceptCall(chatId, callId));
      }
      setCallAnswered(true);
      setTokenAction(getRoomToken);
      setWsUpdate(null);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rejectCall = () => {
    if (callId && chatId && !stopSubmitted) {
      setStopAction(dismissCall(chatId, callId));
      setTimeout(() => {
        setStopSubmitted(true);
      });
    }
    setWsUpdate(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stopCall = () => {
    if (chatId && callId) {
      setStopAction(leaveCall(chatId, callId, moment().diff(startTime)));
      setTimeout(() => {
        setStopSubmitted(true);
      });
    }
  };

  useEffect(() => {
    setStartingCall(isVideoCallOn || isAudioCallOn);
    setTokenAction(getRoomToken);
  }, [isVideoCallOn, isAudioCallOn, setTokenAction]);

  useEffect(() => {
    if (stopSubmitted && !stopLoading && !stopError) {
      dispatch({ type: CommunicationActionTypes.STOP_CALL });
      setCalled(false);
      setDomain(undefined);
      setCallId(undefined);
      setCallAnswered(false);
      if (startingCall) {
        setStartingCall(false);
      }
      setStopSubmitted(false);
      setCallHasAlreadyStarted(false);
      setCallStartedMessage(null);
      setStartData(null);
    }
  }, [
    stopSubmitted,
    stopLoading,
    stopError,
    startingCall,
    setStopSubmitted,
    dispatch,
    setStartData,
    setCallStartedMessage
  ]);

  // if I'm being called
  useEffect(() => {
    if (
      wsUpdate &&
      wsUpdate.messageType === ComUpdateTypes.CALL_MESSAGE &&
      loginId !== wsUpdate.loginSenderId &&
      wsUpdate.receivers.includes(loginId) &&
      !callHasAlreadyStarted
    ) {
      if (isIEorEdge()) {
        return dispatch({ type: CommunicationActionTypes.STOP_CALL });
      }
      setMessageId(wsUpdate.id);
      setIsAudioCall(wsUpdate.values.callType === CallTypes.AUDIO);
      setCalled(true);
      setCallId(wsUpdate.values.callId);
      setCaller({
        name: formattedCallerName,
        type: 'available',
        title: wsUpdate.values.callerRole,
        photoUrl: wsUpdate.values.senderPhotoUrl
      });
      setChatId(wsUpdate.chatRoomId);
    }
  }, [wsUpdate, loginId, callHasAlreadyStarted, dispatch, formattedCallerName]);

  // if I'm being called and I'm already in a conversation
  // this the call
  useEffect(() => {
    if (
      wsUpdate &&
      wsUpdate.messageType === ComUpdateTypes.CALL_MESSAGE &&
      loginId !== wsUpdate.loginSenderId &&
      wsUpdate.receivers.includes(loginId) &&
      callId &&
      wsUpdate.values.callId !== callId &&
      callHasAlreadyStarted
    ) {
      setStopAction(dismissCall(wsUpdate.chatRoomId, wsUpdate.values.callId));
    }
  }, [wsUpdate, loginId, callHasAlreadyStarted, callId, setStopAction]);

  useEffect(() => {
    if (
      !startError &&
      !startLoading &&
      startData &&
      chatId &&
      callId &&
      callHasAlreadyStarted
    ) {
      setJoinAction(joinCall(chatId, callId));
    }
  }, [
    startError,
    startLoading,
    startData,
    setJoinAction,
    chatId,
    callId,
    callHasAlreadyStarted
  ]);

  /**
   * @description I'M CALLING SOMEBODY
   */
  useEffect(() => {
    if (
      wsUpdate &&
      wsUpdate.messageType === ComUpdateTypes.CALL_MESSAGE &&
      loginId === wsUpdate.loginSenderId &&
      wsUpdate.receivers.includes(loginId) &&
      startingCall
    ) {
      setIsAudioCall(wsUpdate.values.callType === CallTypes.AUDIO);

      if (callHasAlreadyStarted) {
        setJoinAction(joinCall(wsUpdate.chatRoomId, wsUpdate.values.callId));
      } else {
        setStartAction(acceptCall(wsUpdate.chatRoomId, wsUpdate.values.callId));
        setMessageSeenAction(markMessageSeen(wsUpdate.chatRoomId, wsUpdate.id));
      }

      setCallId(wsUpdate.values.callId);
      setChatId(wsUpdate.chatRoomId);
    }
  }, [
    callHasAlreadyStarted,
    wsUpdate,
    loginId,
    setJoinAction,
    setMessageSeenAction,
    setStartAction,
    startingCall
  ]);

  useEffect(() => {
    if (
      callStartedMessage &&
      callAnswered &&
      !joinError &&
      !joinLoading &&
      joinData
    ) {
      setDomain(
        callStartedMessage.values.callServerURL
          .replace('https://', '')
          .replace('/', '')
      );
      setCallId(callStartedMessage.values.callId);
      setStartingCall(false);
      setCalled(false);
      setStartTime(callStartedMessage.timestamp);
    }
  }, [callStartedMessage, callAnswered, joinError, joinLoading, joinData]);

  useEffect(() => {
    if (
      wsUpdate &&
      wsUpdate.messageType === ComUpdateTypes.CALL_STARTED_MESSAGE
    ) {
      setCallHasAlreadyStarted(true);
      setCallStartedMessage({ ...wsUpdate });

      if (callAnswered || startingCall) {
        setDomain(
          wsUpdate.values.callServerURL.replace('https://', '').replace('/', '')
        );
        setCallId(wsUpdate.values.callId);
        setStartingCall(false);
        setCalled(false);
        setStartTime(wsUpdate.timestamp);
      }
    }
  }, [wsUpdate, loginId, callAnswered, startingCall]);

  useEffect(() => {
    if (
      wsUpdate &&
      wsUpdate.messageType === ComUpdateTypes.CALL_ENDED_MESSAGE &&
      callId &&
      wsUpdate.values.callId === callId
    ) {
      dispatch({ type: CommunicationActionTypes.STOP_CALL });
      setCalled(false);
      setDomain(undefined);
      setCallId(undefined);
      setCallAnswered(false);
      if (startingCall) {
        setStartingCall(false);
      }
      setCallStartedMessage(null);
      setCallHasAlreadyStarted(false);
      setStartData(null);
    }
  }, [
    wsUpdate,
    loginId,
    stopCall,
    dispatch,
    startingCall,
    callId,
    setStartData,
    setCallStartedMessage
  ]);

  useEffect(() => {
    if (
      wsUpdate &&
      wsUpdate.messageType === ComUpdateTypes.CALL_DISMISS_MESSAGE &&
      wsUpdate.chatRoomId === chatId &&
      wsUpdate.values.callId === callId &&
      wsUpdate.loginSenderId === loginId
    ) {
      dispatch({ type: CommunicationActionTypes.STOP_CALL });
      setCalled(false);
      setDomain(undefined);
      setCallId(undefined);
      setCallAnswered(false);
      if (startingCall) {
        setStartingCall(false);
      }
      setCallStartedMessage(null);
      setCallHasAlreadyStarted(false);
      setStartData(null);
    }
  }, [
    wsUpdate,
    loginId,
    rejectCall,
    chatId,
    callId,
    setStartData,
    setCallStartedMessage,
    dispatch,
    startingCall
  ]);

  const closeNotSupportedModal = () => {
    dispatch({ type: CommunicationActionTypes.CLEAR_NOT_SUPPORTED });
  };

  return (
    <>
      {
        <Modal
          isShowing={showNotSupportedModal}
          toggle={closeNotSupportedModal}
        >
          <div className={styles.notSupported}>
            <div className={styles.message}>
              Your browser does not support audio/video calls.
            </div>
            <div>
              Please use one of the following: Google Chrome, Firefox, Safari or
              Microsoft Edge based on Chromium.
            </div>
            <div className={styles.closeButton}>
              <RoundCrossButton onClick={closeNotSupportedModal} />
            </div>
          </div>
        </Modal>
      }
      {called && showModal && (
        <CallInitiationModal
          isOpen={true}
          onClose={rejectCall}
          type={'incoming'}
          callee={caller}
          answerCall={answerCall}
        />
      )}
      {callee && startingCall && (
        <CallInitiationModal
          isOpen={true}
          onClose={rejectCall}
          type={'outgoing'}
          callee={callee}
        />
      )}
      {domain && token && callId && (
        <CallWrapper
          token={token}
          roomName={callId}
          isOpen={true}
          isAudioCall={isAudioCall}
          onClose={stopCall}
          id="react-jitsi-meet"
          domain={domain}
        />
      )}
      <Snackbar
        message={'Cannot end call. Please try again.'}
        isOpen={isStopErrorShowing}
        onClose={dismissStopError}
      />
      <Snackbar
        message={'Cannot start call. Please try again.'}
        isOpen={isStartErrorShowing}
        onClose={dismissStartError}
      />
    </>
  );
};
