import React, { Dispatch, SetStateAction } from 'react';
import styles from './ChatPhoto.module.css';

export const ChatPhoto: React.FunctionComponent<{
  photoUrl: string;
  onFotoLoad: Dispatch<SetStateAction<number>>;
}> = ({ photoUrl, onFotoLoad }) => {
  const handleLoad = () => {
    onFotoLoad(prev => prev + 1);
  };

  return (
    <div
      className={styles.chatImage}
      style={{ backgroundImage: `url('${photoUrl}')` }}
    >
      <img src={photoUrl} alt="File" onLoad={handleLoad} />
    </div>
  );
};
