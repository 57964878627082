import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { FetchError, LoadingPlaceholder } from 'components';
import { useFetcher, useInfiniteScroll, useModalMeasurements } from 'hooks';
import { DeviceLocation, useLabelState } from 'Location';
import {
  BasicActivity,
  HistoryFilterTabs,
  HistoryHeader,
  HistoryLabel,
  ResidentLocation
} from 'Residents';
import { getActivitiesHistoryPaginated } from 'Residents/actions';
import { Activities } from '../constants/index';
import { LOCATION_TITLE } from './constants/index';
import { ResidentLocationHistoryLogs } from './ResidentLocationHistoryLogs';

import style from 'Residents/History/History.module.css';
import customStyles from './ResidentLocationHistory.module.css';

const RESIDENT_LOCATION_COLOR = '#80c255';

export const ResidentLocationHistory: React.FunctionComponent<{
  onClose: () => void;
  historyFilters: {
    start: string;
    end: string;
    label: HistoryLabel;
  };
  residentId: string;
  facilityId: string;
  changeHistoryFilter: (filter: HistoryLabel) => void;
  currentLocation: DeviceLocation | undefined;
}> = ({
  onClose,
  residentId,
  facilityId,
  historyFilters,
  changeHistoryFilter,
  currentLocation
}) => {
  const label = useLabelState();
  const { modalOffsetTop, modalOffsetLeft, modalWidth } = useModalMeasurements(
    'Activities',
    'MainContent',
    !!residentId
  );

  const [page, setPage] = useState(0);
  const [activities, setActivities] = useState<BasicActivity[]>([]);

  useEffect(() => {
    setActivities([]);
    setPage(0);
  }, [historyFilters]);

  const activitiesHistoryLogAction = useMemo(() => {
    if (facilityId && residentId) {
      const { start, end } = historyFilters;

      return getActivitiesHistoryPaginated(
        facilityId,
        residentId,
        Activities.LOCATION,
        start,
        end,
        page
      );
    }
  }, [facilityId, residentId, historyFilters, page]);

  const {
    data: activitiesHistory,
    loading: activitiesHistoryLoading,
    error: activitiesHistoryError
  } = useFetcher<{ items: BasicActivity[]; nextPage?: string }>(
    activitiesHistoryLogAction,
    {
      items: []
    }
  );

  useEffect(() => {
    if (activitiesHistory.items.length !== 0) {
      setActivities(prev => [...prev, ...activitiesHistory.items]);
    }
  }, [activitiesHistory]);

  const scrollAction = () => {
    if (
      !activitiesHistoryLoading &&
      isFetching &&
      activitiesHistory.items.length &&
      activitiesHistory.nextPage
    ) {
      setPage(parseInt(activitiesHistory.nextPage, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    activitiesHistoryLoading
  );

  const computedOffsetTop = modalOffsetTop >= 0 ? modalOffsetTop : 0;
  const computedOffsetLeft = modalOffsetLeft >= 0 ? modalOffsetLeft - 15 : 0;
  const title = `${label} ${LOCATION_TITLE}`;

  const residentLocationHistory = useMemo(
    () => activities as ResidentLocation[],
    [activities]
  );

  const lastKnownLocation =
    currentLocation && historyFilters.label !== 'Last Week'
      ? currentLocation
      : undefined;

  const isDataAvailable = activities.length > 0;

  return (
    <>
      <div
        className={classnames(style.history, {
          [customStyles.residentLocationWrapper]: true
        })}
        style={{
          marginTop: computedOffsetTop,
          marginLeft: computedOffsetLeft,
          width: modalWidth
        }}
        id="History"
      >
        {!activitiesHistoryError && (
          <HistoryHeader title={title} toggle={onClose} />
        )}
        {!activitiesHistoryLoading && activitiesHistoryError && (
          <FetchError
            error={activitiesHistoryError}
            closable={true}
            onClose={onClose}
          />
        )}
        {!activitiesHistoryError && (
          <>
            <HistoryFilterTabs
              onSelect={changeHistoryFilter}
              activeTab={historyFilters.label}
              color={RESIDENT_LOCATION_COLOR}
            />
            {(!activitiesHistoryLoading || isDataAvailable) && (
              <ResidentLocationHistoryLogs
                historyLogs={residentLocationHistory}
                lastKnownLocation={lastKnownLocation}
                scrollRef={scrollRef}
              />
            )}
            {activitiesHistoryLoading && (
              <LoadingPlaceholder fullHeight={false} />
            )}
          </>
        )}
      </div>
    </>
  );
};
