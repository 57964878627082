import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import useForm from 'react-hook-form';

import {
  DeviceFetchDropdown,
  DismissibleError,
  FetchError,
  FormHeader,
  ImageUpload,
  Input,
  LoadingPlaceholder,
  Select,
  StatusDropdown,
  Switch
} from 'components';
import { caregiverRoles, states } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { editUserAvatar } from 'UserProfile';
import { getAvailableCaregiverDevices, getFormValidationErrors } from 'utils';

import { createCaregiver } from '../actions';
import { Facility } from '../types';
import { createCaregiverDTO } from './createCaregiverDTO';

import stylesForm from 'styles/form.module.css';

interface AddCaregiverFormData {
  name: string;
  number: string;
  isActive: boolean;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  role: string;
  badge: { id: string; name: string } | null;
  phoneExtension: string;
  password: string;
  isManager: boolean;
}

export const AddCaregiverForm: React.FunctionComponent<{
  facility: Facility;
  toggle: () => void;
  selectCaregiver: (caregiverNumber: string) => void;
}> = ({ toggle, selectCaregiver, facility }) => {
  const [photoUrl, setAvatarURL] = useState<string | undefined>(undefined);
  const [deviceStatus, setDeviceStatus] = useState({
    loading: true,
    error: false
  });

  const {
    register,
    getValues,
    errors,
    setValue,
    watch,
    triggerValidation
  } = useForm<AddCaregiverFormData>({
    mode: 'onBlur',
    defaultValues: {
      isActive: true,
      isManager: false
    },
    submitFocusError: false
  });

  const { data, setAction, loading: onSaveLoading, error } = usePoster();

  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, onSaveLoading);

  const handleEmailChange = () => {
    if (error) {
      dismissError();
    }
  };

  const saveCaregiver = (caregiver: any) => {
    setAction(createCaregiver(caregiver));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const validationErrors = getFormValidationErrors(errors);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const caregiverData = createCaregiverDTO({ ...getValues() });
      caregiverData.photoUrl = photoUrl;
      saveCaregiver({
        ...caregiverData,
        facilityId: facility.id
      });
    }
  };

  const handleAvatarChange = async (image: File | Blob, filename: string) => {
    const imageURL = await editUserAvatar(image, filename);
    setAvatarURL(imageURL);
  };

  useEffect(() => {
    if (submitted && !onSaveLoading && !error) {
      toggle();
      if (data) {
        selectCaregiver(data);
      }
    }
  }, [data, error, onSaveLoading, selectCaregiver, submitted, toggle]);

  const roleOptions = useMemo(
    () =>
      caregiverRoles.map(caregiverRole => ({
        value: caregiverRole,
        label: caregiverRole
      })),
    []
  );

  const loading = onSaveLoading || deviceStatus.loading;

  const handleBlur = () => {
    triggerValidation([{ name: 'role' }]);
  };
  return (
    <>
      <FormHeader
        title="Add New Caregiver"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={onSaveLoading}
        visible={!deviceStatus.error}
      />
      {loading && <LoadingPlaceholder />}
      {deviceStatus.error && !loading && (
        <FetchError
          error={deviceStatus.error}
          closable={true}
          onClose={toggle}
        />
      )}
      {!deviceStatus.error && (
        <form
          onSubmit={handleSubmit}
          className={classnames({ [stylesForm.hidden]: loading })}
        >
          <div className={stylesForm.formRow}>
            <div
              className={classnames(stylesForm.inputGroupHalf, {
                [stylesForm.inputGroupWithAvatar]: true
              })}
            >
              <ImageUpload
                onImageChange={handleAvatarChange}
                photoUrl={photoUrl}
              />
              <Input
                name="name"
                label="Caregiver Name"
                register={register}
                validationRules={{ required: true }}
                hasError={!!errors.name}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Switch
                id={'cargiver-manager-check-id'}
                isOn={false}
                name="isManager"
                label="Admin"
                register={register}
              />
            </div>
            <div className={stylesForm.selectGroupStatusSmall}>
              <StatusDropdown
                register={register}
                onChange={setValue}
                value={watch('isActive')}
                hasError={!!errors.isActive}
              />
            </div>
          </div>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="address"
                label="Address"
                register={register}
                hasError={!!errors.address}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="city"
                label="City"
                register={register}
                hasError={!!errors.city}
              />
            </div>
            <div className={stylesForm.selectGroupQuarter}>
              <Select
                name="state"
                label="State"
                options={states}
                hasError={!!errors.state}
                register={register}
                onChange={setValue}
                value={watch('state')}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="zip"
                label="Zip"
                register={register}
                hasError={!!errors.zip}
              />
            </div>
          </div>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="email"
                label="E-mail Address"
                register={register}
                hasError={!!errors.email || !!isErrorShowing}
                validationRules={{ required: true }}
                onChange={handleEmailChange}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="homePhone"
                label="Home Phone Number"
                register={register}
                hasError={!!errors.homePhone}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="mobilePhone"
                label="Cell Phone Number"
                register={register}
                hasError={!!errors.mobilePhone}
              />
            </div>
          </div>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.selectGroupQuarter}>
              <Select
                name="role"
                label="Caregiver Role"
                options={roleOptions}
                hasError={!!errors.role}
                register={register}
                onChange={setValue}
                value={watch('role')}
                required={true}
                onBlur={handleBlur}
              />
            </div>
            <DeviceFetchDropdown
              name="badge"
              value={watch('badge')}
              onChange={setValue}
              register={register}
              hasError={!!errors.badge}
              facility={facility}
              changeStatus={setDeviceStatus}
              label="Device"
              cssClass={stylesForm.selectGroupQuarter}
              filterFunction={getAvailableCaregiverDevices}
            />

            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="phoneExtension"
                label="Phone Extension"
                register={register}
                hasError={!!errors.phoneExtension}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="password"
                label="Password"
                register={register}
                validationRules={{ required: true }}
                hasError={!!errors.password}
                type="password"
              />
            </div>
          </div>

          <DismissibleError
            name="Caregiver"
            visible={isErrorShowing}
            error={error}
            dismiss={dismissError}
          />
          {validationErrors && (
            <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
          )}
        </form>
      )}
    </>
  );
};
