import { CaregiverSchedule } from 'Caregivers';
import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

export const getCaregiverSchedule = (
  caregiverId: string,
  startDate: string,
  endDate: string
) => async () => {
  const queryParams = new URLSearchParams({
    caregiverId,
    startDate,
    endDate,
    size: MAX_PAGE_SIZE.toString()
  });
  const response = await axiosWrapper<{ items: CaregiverSchedule[] }>(
    `/caregiver-assignments?${queryParams}`
  );

  return response.data.items;
};
