import { axiosWrapper } from 'utils';

export const leaveCall = (
  chatRoomId: string,
  callId: string,
  durationInSeconds: number
) => async () => {
  await axiosWrapper(`/chat-rooms/${chatRoomId}/messages`, 'POST', null, {
    messageType: 'leave_call_message',
    callId,
    callDuration: durationInSeconds
  });
};
