import { BasicVital } from 'Residents';
import { axiosWrapper } from 'utils';

export const getLatestVitals = (
  facilityId: string,
  residentId: string
) => async () => {
  const response = await axiosWrapper<BasicVital[]>(
    `/facilities/${facilityId}/residents/${residentId}/vitals`
  );

  return response.data;
};
