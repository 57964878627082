import React from 'react';
import { Line } from 'recharts';

import { Dot } from 'Residents';

export const ActivityChartLine = (color: string, key: string) => (
  <Line
    dataKey={key}
    stroke={color}
    strokeWidth={2}
    connectNulls={true}
    dot={<Dot lineColor={color} backgroundColor="#FFF" />}
    isAnimationActive={true}
  />
);
