import React, { useEffect, useMemo, useState } from 'react';

import { useAuthDispatch } from 'Auth';
import {
  CaregiverStatus,
  changeCaregiverStatus,
  getCaregiverStatus,
  StatusType,
  StatusTypes
} from 'Caregivers';
import { FetcherDropdown, StatusToggle } from 'components';
import { useFetcher, useModal, usePoster } from 'hooks';
import { useLocationState } from 'Location';
import { Facility, getFacilityList, getWardsByFacility, Ward } from 'Settings';
import { sortByKey } from 'utils';

import { createCaregiverStatusDTO } from './createCaregiverStatusDTO';
import { profileDropdownStyles } from './profileDropdownStyles.constant';
import { StatusChangeError } from './StatusChangeError';
import { StatusChangeModal } from './StatusChangeModal';
import { StatusLoadingPlaceholder } from './StatusLoadingPlaceholder';

import styles from './CaregiverWardStatus.module.css';

interface WardStatus {
  id: string;
  name: string;
  status: StatusType;
}

export const CaregiverWardStatus: React.FunctionComponent<{
  caregiverId: string;
  isDropdownVisible: boolean;
  onCaregiverStatusChange: (status: StatusType | undefined) => void;
}> = ({ caregiverId, isDropdownVisible, onCaregiverStatusChange }) => {
  const [selectedFacility, setSelectedFacility] = useState('');
  const [wardsStatus, setWardsStatus] = useState<WardStatus[]>([]);
  const [wardToChange, setWardToChange] = useState<string>('');
  const [isStatusErrorShowing, setIsStatusErrorShowing] = useState(false);

  const {
    isShowing: isStatusChangeViewOn,
    toggle: toggleStatusChangeView
  } = useModal();
  const { setAction, error: statusChangeError } = usePoster();
  const { ward: selectedWardId } = useLocationState();
  const { dispatch } = useAuthDispatch();

  const getWardsAction = useMemo(() => {
    if (selectedFacility) {
      return getWardsByFacility(selectedFacility);
    }
  }, [selectedFacility]);

  const getCaregiverStatusAction = useMemo(() => {
    if (caregiverId && selectedFacility) {
      return getCaregiverStatus(caregiverId, selectedFacility);
    }
  }, [caregiverId, selectedFacility]);

  const {
    data: facilitiesData,
    error: facilitiesError,
    loading: facilitiesLoading
  } = useFetcher<Facility[]>(getFacilityList, []);

  const {
    data: wardsData,
    error: wardError,
    loading: wardLoading
  } = useFetcher<Ward[]>(getWardsAction, []);

  const {
    data: caregiverStatusData,
    error: caregiverStatusError,
    loading: caregiverStatusLoading,
    setRefetch: refetchCaregiverStatus
  } = useFetcher<CaregiverStatus[]>(getCaregiverStatusAction, []);

  const availableWards = useMemo(() => sortByKey(wardsData, 'name'), [
    wardsData
  ]);
  const availableFacilities = useMemo(() => sortByKey(facilitiesData, 'name'), [
    facilitiesData
  ]);

  const error = facilitiesError || wardError || caregiverStatusError;
  const isLoading = facilitiesLoading || wardLoading || caregiverStatusLoading;

  useEffect(() => {
    const selection =
      availableFacilities.length > 0 ? availableFacilities[0].id : '';
    setSelectedFacility(selection);
  }, [availableFacilities]);

  useEffect(() => {
    setWardsStatus(
      availableWards
        .filter(({ isActive }) => isActive)
        .map(({ id, name }) => {
          const statusData = caregiverStatusData.find(
            ({ wardId }) => wardId === id
          );
          return {
            id,
            name,
            status: statusData ? statusData.status : statusData
          } as WardStatus;
        })
    );
  }, [availableWards, caregiverStatusData]);

  useEffect(() => {
    if (!isDropdownVisible && isStatusChangeViewOn) {
      toggleStatusChangeView();
      setWardToChange('');
    }
  }, [isDropdownVisible, isStatusChangeViewOn, toggleStatusChangeView]);

  useEffect(() => {
    setIsStatusErrorShowing(!!statusChangeError);
  }, [statusChangeError]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const currentWardStatus = wardsStatus.find(
      ({ id }) => id === selectedWardId
    );
    if (currentWardStatus) {
      onCaregiverStatusChange(currentWardStatus.status);
    }
  }, [onCaregiverStatusChange, selectedWardId, wardsStatus, isLoading]);

  const onFacilityChange = (value: any) => {
    setSelectedFacility(value);
  };

  const onWardStatusChange = (wardId: string) => (
    currentStatus: StatusType
  ) => {
    if (currentStatus !== StatusTypes.ON_DUTY) {
      changeWardStatus(StatusTypes.ON_DUTY, wardId, false);
      return;
    }
    setWardToChange(wardId);
    toggleStatusChangeView();
  };

  const changeWardStatus = (
    status: StatusType,
    wardId: string,
    allWards: boolean,
    breakDuration?: number
  ) => {
    const data = createCaregiverStatusDTO({
      status,
      wardId,
      allWards,
      breakDuration,
      facilityId: selectedFacility,
      caregiverId
    });
    setAction(changeCaregiverStatus(data));

    // optimistic update
    setWardsStatus(prev =>
      prev.map(wardStatus =>
        wardStatus.id === wardId || allWards
          ? { ...wardStatus, status }
          : wardStatus
      )
    );
    setWardToChange('');
    setIsStatusErrorShowing(false);

    if (wardId === selectedWardId || allWards) {
      // trigger a page reload if changing status for current ward
      setTimeout(() => {
        dispatch({ type: 'SET_RELOAD', payload: true });
      });
    }
  };

  const dismissStatusError = () => {
    refetchCaregiverStatus();
    setIsStatusErrorShowing(false);
  };

  const wardStatusContent = () =>
    wardsStatus.map(({ id, name, status }) => (
      <li className={styles.wardEntry} key={id}>
        <span>{name}</span>
        <StatusToggle
          status={status}
          onStatusChange={onWardStatusChange(id)}
          id={id}
        />
      </li>
    ));

  const facilityOptions = availableFacilities.map(({ id, name }) => ({
    value: id,
    label: name
  }));

  return (
    <>
      {availableFacilities.length > 0 ? (
        <div className={styles.statusWrapper} id="WardStatusWrapper">
          <FetcherDropdown
            options={facilityOptions}
            onChange={onFacilityChange}
            isClearable={false}
            dropdownStyles={profileDropdownStyles}
            placeholder="Select facility"
            value={selectedFacility}
            loading={isLoading}
            error={error}
            style={{ height: '33px', width: '270px' }}
            isDisabled={isStatusChangeViewOn}
          />
          {!isLoading && !error && (
            <>
              <div className={styles.listContainer}>
                <ul className={styles.wardsList}>{wardStatusContent()}</ul>
                {isStatusChangeViewOn && (
                  <StatusChangeModal
                    wardId={wardToChange}
                    onStatusChange={changeWardStatus}
                    toggle={toggleStatusChangeView}
                  />
                )}
              </div>
            </>
          )}
          {error && !isLoading && (
            <p className={styles.errorMessage}>
              Could not load wards. Try reloading.
            </p>
          )}
          {isLoading && !error && <StatusLoadingPlaceholder />}
          {!error && !isLoading && isStatusErrorShowing && (
            <StatusChangeError onDismiss={dismissStatusError} />
          )}
        </div>
      ) : null}
    </>
  );
};
