import { ChatMessageTypes } from 'Communication/types';
import { MessageId } from 'types';
import { axiosWrapper } from 'utils';

export const sendChatMessage = async (
  chatRoomId: string,
  textMessage: string
): Promise<MessageId> => {
  const response = await axiosWrapper(
    `/chat-rooms/${chatRoomId}/messages`,
    'POST',
    null,
    {
      textMessage,
      messageType: ChatMessageTypes.TEXT_MESSAGE
    }
  );

  const lastIndex = response.headers.location.lastIndexOf('/');

  const messageId = response.headers.location.substring(lastIndex + 1);

  return messageId;
};
