import React, { useEffect, useRef } from 'react';

import { Modal } from 'components';
import { useModalMeasurements } from 'hooks';

import { AddReadingHeader } from './AddReadingHeader';

import styles from './AddReadingForm.module.css';

export const AddReadingForm: React.FunctionComponent<{
  isOpen: boolean;
  onCancel: () => void;
  onSave: (evt: any) => void;
  title: string;
  savingDisabled: boolean;
}> = ({ isOpen, onCancel, onSave, title, savingDisabled, children }) => {
  const { modalOffsetTop, modalOffsetLeft, modalWidth } = useModalMeasurements(
    'History',
    'HistoryModal',
    isOpen
  );
  const formRef = useRef<HTMLFormElement | null>(null);

  const onSubmit = (evt: any) => {
    evt.preventDefault();
    if (!savingDisabled) {
      onSave(evt);
    }
  };

  useEffect(() => {
    const handleClick = (evt: any) => {
      if (evt.key === 'Enter' && formRef.current) {
        if (navigator.userAgent.includes('Trident/7.0')) {
          // IE 11 check
          formRef.current.dispatchEvent(document.createEvent('submit'));
        } else {
          formRef.current.dispatchEvent(
            new Event('submit', { cancelable: true })
          );
        }
      }
    };

    window.addEventListener('keyup', handleClick, false);
    return () => {
      window.removeEventListener('keyup', handleClick, false);
    };
  }, []);

  return (
    <Modal isShowing={isOpen} toggle={onCancel}>
      <div className={styles.modalBackdrop}>
        <div className={styles.modalContent}>
          <div
            className={styles.addReadingContainer}
            style={{
              marginTop: modalOffsetTop + 30,
              marginLeft: modalOffsetLeft,
              width: modalWidth
            }}
          >
            <AddReadingHeader
              title={title}
              onCancel={onCancel}
              onSave={onSave}
              savingDisabled={savingDisabled}
            />
            <form className={styles.form} onSubmit={onSubmit} ref={formRef}>
              {children}
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
