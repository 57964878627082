import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAuthState } from 'Auth';
import {
  CommunicationActionTypes,
  useCommunicationDispatch
} from 'Communication';
import {
  AccessMessage,
  FetchError,
  LoadingPlaceholder,
  WithPermissions
} from 'components';
import { useModal, useQueryParams } from 'hooks';
import { useLocationState } from 'Location';

import { CaregiverProvider } from './caregiver.context';
import { CaregiversChatWindow } from './Chat/CaregiverChatWindow';
import { EditCaregiverDetails } from './EditCaregiverDetails';
import { AssignedResidentsList } from './Residents/AssignedResidentsList';
import { WorkSchedule } from './Schedule/WorkSchedule';
import { StaffList } from './StaffList';
import { WithCaregiverDetails } from './WithCaregiverDetails';

import style from './CaregiverDashboard.module.css';

export const CaregiverDashboard = ({ history }: RouteComponentProps) => {
  const { isShowing, toggle } = useModal();
  const conversationDispatch = useCommunicationDispatch();

  useEffect(() => {
    conversationDispatch({
      type: CommunicationActionTypes.CLEAR_COMMUNICATION
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const query = useQueryParams();
  const {
    facility,
    filtersLoading,
    filtersError,
    selectedWardDetails
  } = useLocationState();
  const { loggedUser } = useAuthState();

  const caregiverId = query.get('caregiver') || '';
  const wardId = query.get('ward') || '';

  const selectCaregiverId = useCallback(
    (value: string | undefined) => {
      if (!value) {
        return;
      }
      query.set('caregiver', value);
      history.push({
        search: `?${query}`
      });
    },
    [history, query]
  );

  const removeCaregiverSelection = useCallback(() => {
    if (caregiverId) {
      query.delete('caregiver');

      history.push({
        search: `?${query}`
      });
    }
  }, [history, query, caregiverId]);

  return (
    <CaregiverProvider>
      <div className={style.dashboardContainer}>
        {wardId && !filtersLoading && !filtersError && (
          <>
            <StaffList
              selectedStaffId={caregiverId}
              selectStaffId={selectCaregiverId}
              removeSelection={removeCaregiverSelection}
            />
            <main className={style.main}>
              <div className={style.mainCaregiverContent}>
                <WithCaregiverDetails
                  caregiverId={caregiverId}
                  facilityId={facility.id}
                  wardId={wardId}
                  onEditClick={toggle}
                />
                <EditCaregiverDetails
                  facility={facility}
                  toggle={toggle}
                  isOpen={isShowing}
                  wardId={wardId}
                />
                <WorkSchedule caregiverId={caregiverId} />
              </div>
              <aside className={style.aside}>
                <div className={style.residentsContainer}>
                  <AssignedResidentsList caregiverId={caregiverId} />
                </div>
                {selectedWardDetails?.services.secureCommunication.features
                  .scMessaging.isEnabled && (
                  <WithPermissions
                    action="communication-messages:view"
                    data={{ loggedUser, caregiverId }}
                    yes={<CaregiversChatWindow />}
                  />
                )}
              </aside>
            </main>
          </>
        )}
        <AccessMessage />
        {filtersLoading && !filtersError && <LoadingPlaceholder />}
        {!filtersLoading && filtersError && <FetchError />}
      </div>
    </CaregiverProvider>
  );
};
