import React, { useState } from 'react';

import { DetailsPlaceholder, TwoColumnLayout } from 'components';

import { Facility, Room } from 'Settings/types';

import { RoomDetails } from './RoomDetails';
import { RoomList } from './RoomList';

import roomStyles from './RoomSettings.module.css';

export const RoomsSettings: React.FunctionComponent<{
  facility: Facility;
  activeRoom: string;
}> = ({ facility, activeRoom }) => {
  const [selectedRoomId, setSelectedRoomId] = useState<string>(activeRoom);
  const [modifiedRoom, setModifiedRoom] = useState<Room>({} as Room);
  const selectRoom = (roomId: string) => {
    setSelectedRoomId(roomId);
  };

  const handleRoomChange = (room: Room) => {
    setModifiedRoom(room);
  };

  return (
    <TwoColumnLayout
      left={
        <RoomList
          facility={facility}
          selectRoom={selectRoom}
          selectedRoomId={selectedRoomId}
          lastModifiedRoom={modifiedRoom}
        />
      }
      right={
        <div className={roomStyles.panelHeight}>
          {selectedRoomId && (
            <RoomDetails
              facility={facility}
              selectedRoomId={selectedRoomId}
              notifyRoomChange={handleRoomChange}
            />
          )}
          {selectedRoomId === '' && (
            <DetailsPlaceholder
              message="Please select Room to display details"
              title="Room Details"
            />
          )}
        </div>
      }
    />
  );
};
