import { axiosWrapper } from 'utils';

export const editUserAvatar = async (
  avatar: File | Blob,
  filename: string
): Promise<string> => {
  const formData: any = new FormData();
  formData.append('file', avatar, filename);

  const url = '/users/photo';
  const response = await axiosWrapper(url, 'POST', null, formData);
  return response.headers.location as string;
};
