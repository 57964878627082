import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { debounce } from 'utils';

const SCROLL_OFFSET = 20;

export const useInfiniteScroll = (
  scrollAction: () => void,
  loading: boolean,
  reverseScroll?: boolean,
  activeConversationId?: string
) => {
  const [isFetching, setIsFetching] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [scrollNode, setScrollNode] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsFetching(false);
  }, [activeConversationId]);

  const scrollRef = useCallback(node => {
    if (node !== null) {
      setScrollNode(node);
    }
  }, []);

  const memoizedAction = useCallback(() => {
    scrollAction();
  }, [scrollAction]);

  useEffect(() => {
    function handleScroll() {
      if (scrollNode !== null) {
        const { scrollTop, clientHeight, scrollHeight } = scrollNode;

        if (
          scrollTop + clientHeight >= scrollHeight - SCROLL_OFFSET &&
          !isFetching &&
          !reverseScroll
        ) {
          setIsFetching(true);
        }
        if (scrollTop <= SCROLL_OFFSET && !isFetching && reverseScroll) {
          setIsFetching(true);
        }
      }
    }

    if (scrollNode !== null) {
      scrollNode.addEventListener('scroll', debounce(handleScroll), {
        passive: true
      });
      return () => {
        setIsFetching(false);
        scrollNode.removeEventListener('scroll', debounce(handleScroll));
      };
    }
  }, [isFetching, scrollNode, reverseScroll]);

  useLayoutEffect(() => {
    if (scrollNode !== null && !isFetching && !loading && !reverseScroll) {
      const { children, clientHeight } = scrollNode;
      const childrenArray = Array.from(children) as HTMLElement[];

      const heightOfChildren = childrenArray.reduce(
        (sum, { offsetHeight }) => sum + offsetHeight,
        0
      );
      if (heightOfChildren + SCROLL_OFFSET <= clientHeight) {
        setIsFetching(true);
      } else {
        setFinishedLoading(true);
      }
    }
  }, [isFetching, loading, scrollNode, reverseScroll]);

  useEffect(() => {
    if (!isFetching) {
      return;
    }

    memoizedAction();
  }, [isFetching, memoizedAction]);

  return { scrollRef, isFetching, setIsFetching, finishedLoading };
};
