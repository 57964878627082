import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { bodyTemperatureInterval } from '../Intervals';
import { BodyTemperatureLine } from '../Lines';
import { BodyTemperatureToolTip } from '../ToolTips';

export const BodyTemperatureChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    bodyTemperature?: number;
    hasWarning?: boolean | undefined;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={bodyTemperatureInterval}
      data={data}
      tooltip={BodyTemperatureToolTip}
      lineOne={BodyTemperatureLine('#2485b9')}
      color="#2485b9"
      allowHighlight={allowHighlight}
    />
  );
};
