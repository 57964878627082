import React from 'react';

import { RoundSearchButton } from 'components';

import styles from './CareTeamListHeader.module.css';

export const CareTeamListHeader: React.FunctionComponent<{
  toggleSearch: () => void;
}> = ({ toggleSearch }) => {
  return (
    <header className={styles.header}>
      <h3 className={styles.title}>Care Team</h3>
      <RoundSearchButton onClick={toggleSearch} testId="search-test-button" />
    </header>
  );
};
