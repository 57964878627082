import moment from 'moment';

import { emptyMonth, HeartRate, weekOfMonth } from 'Residents';

import { warningCodes } from '../consts/warningCodes.constant';

const convertReadings = (week: string, readings: HeartRate[]) => {
  const heartRateReadings = readings
    .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
    .map(entry => parseInt(entry.value.heartRate, 10));

  const heartRate =
    heartRateReadings.length > 0
      ? Math.round(
          heartRateReadings.reduce((a, b) => a + b) / heartRateReadings.length
        )
      : undefined;

  const hasWarning = warningCodes.some(code =>
    readings
      .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
      .map(entry => entry.code)
      .includes(code as 'GREEN' | 'YELLOW' | 'ORANGE' | 'RED')
  );

  return {
    heartRate,
    hasWarning,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toMonthData = (data: HeartRate[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
