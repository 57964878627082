import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { CaregiverProfileDetails, UserProfileDetails } from 'UserProfile';

import {
  getToken,
  getUserLoginIdByToken,
  getUserRoleByToken,
  login as authenticateUser,
  logout as logoutUser,
  userHasAccess
} from './auth.utils';
import { AuthErrorScreen } from './AuthErrorScreen';
import { AuthUpdatingScreen } from './AuthUpdatingScreen';
import { UserRole } from './roleBasedAccess';

type Action =
  | {
      type: 'SET_LOGGED_USER';
      payload: UserProfileDetails | CaregiverProfileDetails | null;
    }
  | {
      type: 'SET_AUTH_ERROR';
      payload: Error | null;
    }
  | {
      type: 'LOGIN_USER';
    }
  | {
      type: 'LOGOUT_USER';
    }
  | {
      type: 'SET_RELOAD';
      payload: boolean;
    }
  | {
      type: 'SET_AUTHORIZATION';
      payload: string | null;
    };

interface State {
  isAuthenticated: boolean;
  authenticationError: any | null;
  loading: boolean;
  loginId: string;
  loggedUser: UserProfileDetails | CaregiverProfileDetails | null;
  reloading: boolean;
  role: UserRole | null;
}
type Dispatch = (action: Action) => void;
export interface DispatchActions {
  dispatch: Dispatch;
  [key: string]: (param?: any) => void;
}

const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<DispatchActions | undefined>(
  undefined
);

export const authReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_LOGGED_USER': {
      return {
        ...state,
        loggedUser: action.payload
      };
    }
    case 'SET_AUTH_ERROR': {
      return {
        ...state,
        authenticationError: action.payload,
        loading: false
      };
    }
    case 'LOGIN_USER': {
      return {
        ...state,
        authenticationError: null,
        loading: true
      };
    }
    case 'LOGOUT_USER': {
      return {
        ...state,
        authenticationError: null,
        isAuthenticated: false,
        role: null,
        reloading: true
      };
    }
    case 'SET_RELOAD': {
      return {
        ...state,
        reloading: action.payload
      };
    }
    case 'SET_AUTHORIZATION': {
      const token = action.payload;
      const isAuthenticated = userHasAccess(token);
      return {
        ...state,
        loading: false,
        authenticationError: !isAuthenticated,
        isAuthenticated,
        role: getUserRoleByToken(token),
        loginId: getUserLoginIdByToken(token)
      };
    }
  }
};

export const AuthProvider: (props: { children: React.ReactNode }) => any = ({
  children
}) => {
  const [state, dispatch] = useReducer(authReducer, {
    isAuthenticated: userHasAccess(getToken()),
    role: getUserRoleByToken(getToken()),
    loginId: getUserLoginIdByToken(getToken()),
    authenticationError: null,
    loading: false,
    loggedUser: null,
    reloading: false
  });

  const { isAuthenticated, authenticationError, reloading } = state;

  useEffect(() => {
    if (reloading) {
      dispatch({ type: 'SET_RELOAD', payload: false });
    }
  }, [reloading]);

  const login = async (data: { username: string; password: string }) => {
    try {
      dispatch({ type: 'LOGIN_USER' });
      await authenticateUser(data);
      dispatch({ type: 'SET_AUTHORIZATION', payload: getToken() });
    } catch (error) {
      dispatch({ type: 'SET_AUTH_ERROR', payload: error as Error });
    }
  };

  const logout = () => {
    logoutUser();
    dispatch({ type: 'LOGOUT_USER' });
  };

  if (
    isAuthenticated &&
    authenticationError &&
    authenticationError.status === 401
  ) {
    return <AuthErrorScreen onReload={logout} />;
  }

  if (reloading) {
    // refresh app to reflect changes for logged user
    return <AuthUpdatingScreen />;
  }

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={{ dispatch, login, logout }}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
};

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};

export const useAuthContext = (): [State, DispatchActions] => {
  return [useAuthState(), useAuthDispatch()];
};
