import React from 'react';

import { PanelHeader, RoundPlusButton } from 'components';
import { ReactComponent as AdHocIcon } from 'icons/ad-hoc.svg';

import styles from './MessagingHistoryList.module.css';

export const MessagingHistoryHeader: React.FunctionComponent<{
  toggleNewMessage?: () => void;
  onAdHocClick?: () => void;
  isButtonHidden?: boolean;
}> = ({ toggleNewMessage, isButtonHidden = false, onAdHocClick }) => {
  return (
    <PanelHeader title="Messaging">
      {!isButtonHidden && (
        <>
          {toggleNewMessage && (
            <RoundPlusButton
              onClick={toggleNewMessage}
              testId="new-message-test-button"
            />
          )}
          {onAdHocClick && (
            <div onClick={onAdHocClick} className={styles.groupButton}>
              <AdHocIcon />
            </div>
          )}
        </>
      )}
    </PanelHeader>
  );
};
