import React from 'react';

import styles from './CaregiverWardStatus.module.css';

export const StatusLoadingPlaceholder: React.FunctionComponent = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingEntryPlaceholder} />
      <div className={styles.loadingEntryPlaceholder} />
      <div className={styles.loadingEntryPlaceholder} />
    </div>
  );
};
