import React from 'react';

import styles from './Footer.module.css';

interface FooterProps {
  APP_VERSION: string;
}

export const Footer: React.FunctionComponent<FooterProps> = ({
  APP_VERSION
}) => {
  return (
    <footer className={styles.footer}>
      <p className={styles.p} data-testid="footer-left-text">
        Copyright © {new Date().getFullYear()}{' '}
        <span className={styles.blueFont}>transparent</span>.
        <span className={styles.greenFont}>health</span>. All Rights Reserved.
      </p>
      <p className={styles.p} data-testid="footer-right-text">
        <span className={styles.blueFont}>version:</span> {APP_VERSION}
      </p>
    </footer>
  );
};
