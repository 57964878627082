import React, { useEffect, useMemo, useState } from 'react';

import {
  DetailsPlaceholder,
  PanelPlaceholder,
  TwoColumnLayout
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher } from 'hooks';
import { Facility, getWardDetails, Resident } from 'Settings';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';

import { ResidentContactList } from './ResidentContactList';
import { ResidentDetails } from './ResidentDetails';
import { ResidentList } from './ResidentList';

import residentStyles from './ResidentSettings.module.css';

export const ResidentsSettings: React.FunctionComponent<{
  facility: Facility;
  activeResident: string;
  resetActiveResident: () => void;
}> = ({ facility, activeResident, resetActiveResident }) => {
  const [selectedResidentId, setSelectedResidentId] = useState<string>(
    activeResident
  );
  const [modifiedResident, setModifiedResident] = useState<Resident>(
    {} as Resident
  );
  const [wardId, setWardId] = useState<string>();

  useEffect(() => {
    setSelectedResidentId(activeResident);
    setModifiedResident({} as Resident);
  }, [activeResident]);

  const detailPlaceholderMessage = `Please select a ${residentLabels[
    facility.type
  ].toLowerCase()} to display details`;
  const detailPlaceholderTitle = `${residentLabels[facility.type]} Details`;
  const panelPlaceholderMessage = `Please select a ${residentLabels[
    facility.type
  ].toLowerCase()} to display related contacts`;

  const handleResidentDetails = (resident: Resident) => {
    const residentWardId = resident?.room?.ward.id;
    setWardId(residentWardId);
  };

  const getWardDetailsMemo = useMemo(() => {
    if (wardId) {
      return getWardDetails(facility.id, wardId)();
    }
  }, [facility.id, wardId]);

  const { data: wardDetails } = useFetcher<MappedWard>(
    getWardDetailsMemo,
    {} as MappedWard
  );

  const relatedContactsFeature =
    wardDetails.services?.patientResidentManagement.features.prmRelatedContact
      .isEnabled;

  return (
    <TwoColumnLayout
      left={
        <ResidentList
          facility={facility}
          selectedResidentId={selectedResidentId}
          selectResident={setSelectedResidentId}
          modifiedResident={modifiedResident}
          resetActiveResident={resetActiveResident}
          residentDetails={handleResidentDetails}
        />
      }
      right={
        <>
          <div className={residentStyles.panelHeight}>
            {selectedResidentId ? (
              <ResidentDetails
                residentId={selectedResidentId}
                facility={facility}
                onResidentChange={setModifiedResident}
              />
            ) : (
              <DetailsPlaceholder
                message={detailPlaceholderMessage}
                title={detailPlaceholderTitle}
              />
            )}
          </div>
          {selectedResidentId ? (
            (relatedContactsFeature || !wardId) && (
              <ResidentContactList
                residentId={selectedResidentId}
                label={residentLabels[facility.type]}
              />
            )
          ) : (
            <PanelPlaceholder
              message={panelPlaceholderMessage}
              title="Related Contacts"
            />
          )}
        </>
      }
    />
  );
};
