import React, { useEffect } from 'react';

import { DismissibleError, Modal } from 'components';
import { useFetcher, usePoster, useSubmitError } from 'hooks';
import { getHeight } from 'utils';

import { createFacility, getIdns } from '../actions';
import { Idn } from '../types/idn.type';

import { AddFacilityForm } from './AddFacilityForm';

import stylesModal from 'components/Modal/Modal.module.css';

export const AddFacility: React.FunctionComponent<{
  onFacilityAdd: (id: string) => void;
  onClose: () => void;
  isOpen: boolean;
  selectFacility: (id: string) => void;
}> = ({ onFacilityAdd, onClose, isOpen, selectFacility }) => {
  const { data: idns } = useFetcher<{ items: Idn[] }>(getIdns, { items: [] });

  const { data, setAction, loading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, loading);

  const onSave = (facility: any) => {
    setAction(createFacility(facility));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const onCancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    onClose();
  };

  useEffect(() => {
    if (!submitted || loading || error) {
      return undefined;
    }
    onClose();
    setSubmitted(false);
    if (data) {
      onFacilityAdd(data);
      selectFacility(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, loading, error]);

  return (
    <Modal isShowing={isOpen} toggle={onClose}>
      <div className={stylesModal.modalBackdrop}>
        <div className={stylesModal.addFacilityModalContentLeft}>
          <div
            className={stylesModal.facilityModalLeft}
            style={{ marginTop: getHeight('Facilities') }}
          >
            <AddFacilityForm
              onCancel={onCancel}
              idns={idns.items}
              onSave={onSave}
              loading={loading}
            >
              {error && (
                <DismissibleError
                  name="Facility"
                  visible={isErrorShowing}
                  error={error}
                  dismiss={dismissError}
                />
              )}
            </AddFacilityForm>
          </div>
        </div>
      </div>
    </Modal>
  );
};
