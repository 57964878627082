import React from 'react';

import { PanelList } from 'components';

import styles from './PanelPlaceholder.module.css';

const onAdd = () => null;
export const PanelPlaceholder: React.FunctionComponent<{
  message: string;
  title: string;
}> = ({ message, title }) => {
  return (
    <PanelList title={title} onAdd={onAdd}>
      <p className={styles.placeholder}>{message}</p>
    </PanelList>
  );
};
