import { useCallback, useEffect, useState } from 'react';

import { useAuthDispatch } from 'Auth';
import { handleAuthError, handleError } from 'utils';

export function usePoster(
  label?: string
): {
  data: any;
  loading: boolean;
  error: Error | null;
  setAction: any;
  setData: any;
  resetError: () => void;
} {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState(null);
  const [action, setAction] = useState<any>(null);
  const { dispatch } = useAuthDispatch();

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  useEffect(() => {
    let unmounted = false;

    async function loadData() {
      try {
        setLoading(true);
        const actionData =
          typeof action === 'function' ? await action() : await action;

        if (!unmounted) {
          setData(actionData);
          setError(null);
        }
      } catch (error) {
        if (unmounted) {
          return;
        }
        setError(handleError(error, label));
        dispatch({ type: 'SET_AUTH_ERROR', payload: handleAuthError(error) });
      } finally {
        if (!unmounted) {
          setLoading(false);
        }
      }
    }
    if (action) {
      loadData();
    }
    return function cancel() {
      unmounted = true;
    };
  }, [action, dispatch, label]);

  return { data, loading, error, setAction, setData, resetError };
}
