import React from 'react';

import { RoundButton } from './RoundButton';

import styles from './RoundSearchButton.module.css';

import { ReactComponent as SearchIcon } from 'icons/search.svg';

export const RoundSearchButton: React.FunctionComponent<{
  onClick: () => void;
  testId?: string;
}> = ({ onClick, testId }) => (
  <RoundButton
    className={styles.roundSearchButton}
    onClick={onClick}
    testId={testId}
  >
    <SearchIcon />
  </RoundButton>
);
