import { axiosWrapper } from 'utils';

import { Room } from 'Settings/types';

export const getRoomDetails = (
  facilityId: string,
  roomId: string
) => async () => {
  const response = await axiosWrapper<Room>(
    `/facilities/${facilityId}/rooms/${roomId}`
  );
  return response.data;
};
