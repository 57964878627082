import React, { useMemo } from 'react';

import { FetchError } from 'components';
import { useFetcher, useModalMeasurements } from 'hooks';
import {
  BodyWeight,
  getVitalsHistory,
  HistoryHeader,
  HistoryLabel,
  HistoryLoadingPlaceholder
} from 'Residents';

import { BodyWeightHistoryLogs } from './BodyWeightHistoryLogs';
import { BodyWeightHistoryChart } from './Charts';

import style from 'Residents/History/History.module.css';

export const BodyWeightHistory: React.FunctionComponent<{
  onClose: () => void;
  historyFilters: {
    start: string;
    end: string;
    label: HistoryLabel;
  };
  facilityId: string;
  residentId: string;
  onAdd: () => void;
  changeHistoryFilter: (filter: HistoryLabel) => void;
}> = ({
  onClose,
  historyFilters,
  facilityId,
  residentId,
  onAdd,
  changeHistoryFilter
}) => {
  const { modalOffsetTop, modalOffsetLeft, modalWidth } = useModalMeasurements(
    'Vitals',
    'MainContent',
    !!residentId
  );
  const computedOffsetTop = modalOffsetTop >= 0 ? modalOffsetTop : 0;
  const computedOffsetLeft = modalOffsetLeft >= 0 ? modalOffsetLeft - 15 : 0;

  const vitalsHistoryLogAction = useMemo(() => {
    if (facilityId && residentId) {
      const { start, end } = historyFilters;

      return getVitalsHistory(
        facilityId,
        residentId,
        'BODY_WEIGHT',
        start,
        end
      );
    }
  }, [facilityId, residentId, historyFilters]);

  const { data: vitalsHistory, loading, error } = useFetcher<BodyWeight[]>(
    vitalsHistoryLogAction,
    []
  );

  return (
    <>
      <div
        className={style.history}
        style={{
          marginTop: computedOffsetTop,
          marginLeft: computedOffsetLeft,
          width: modalWidth
        }}
        id="History"
      >
        {!error && (
          <HistoryHeader
            title="Body Weight History"
            toggle={onClose}
            onAdd={onAdd}
          />
        )}
        {!loading && error && (
          <FetchError error={error} closable={true} onClose={onClose} />
        )}
        {!error && (
          <>
            <BodyWeightHistoryChart
              changeHistoryFilter={changeHistoryFilter}
              activeHistoryTab={historyFilters.label}
              readings={vitalsHistory as BodyWeight[]}
              loading={loading}
            />
            {loading && <HistoryLoadingPlaceholder />}
            {!loading && (
              <BodyWeightHistoryLogs
                historyLogs={vitalsHistory as BodyWeight[]}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
