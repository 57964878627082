import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import { useFetcher } from 'hooks';
import { getWardsByFacility } from 'Settings/actions';
import { Ward } from 'Settings/types';
import {
  formatSelectedValues,
  sortByKey,
  stripIdForDropdownValue
} from 'utils';

import styles from './Dropdown.module.css';

export const WardFetchDropdown: React.FunctionComponent<{
  name: string;
  value: any;
  onChange: (name: any, value: any, revalidate?: boolean) => void;
  register: any;
  hasError: boolean;
  facilityId: string;
  changeStatus: (status: any) => void;
  required?: boolean;
  isVisible?: boolean;
  cssClass?: string;
  defaultValue?: any;
  onBlur?: () => void;
}> = ({
  name,
  value,
  facilityId,
  hasError,
  changeStatus,
  onChange,
  register,
  required,
  defaultValue,
  isVisible = true,
  cssClass = styles.dropdown,
  onBlur
}) => {
  const getWardsMemo = useMemo(() => getWardsByFacility(facilityId, true), [
    facilityId
  ]);
  const { data: wards, error: wardsError, loading: wardsLoading } = useFetcher<
    Ward[]
  >(getWardsMemo, []);

  const getAvailableWardOptions = () => {
    let wardOptions = wards.map(({ id, name: wardName }) => ({
      value: id,
      label: wardName
    }));

    if (defaultValue && value && defaultValue.id === value.id) {
      const correspondingValue = wards.find(({ id }) => id === defaultValue.id);
      if (!correspondingValue) {
        wardOptions = [
          ...wardOptions,
          { value: defaultValue.id, label: defaultValue.name }
        ];
      }
    }

    return sortByKey(wardOptions, 'label');
  };

  const wardsOptions = getAvailableWardOptions();

  useEffect(() => {
    changeStatus({ loading: wardsLoading, error: wardsError });
  }, [wardsError, wardsLoading, changeStatus]);

  const handleChange = (
    fieldName: string,
    selected: any,
    revalidate: boolean
  ) => {
    const formattedValue = formatSelectedValues(selected, wardsOptions);
    onChange(fieldName, formattedValue, revalidate);
  };

  const style = isVisible ? { display: 'flex' } : { display: 'none' };

  return (
    <div className={cssClass} style={style}>
      <Select
        name={name}
        label="Ward"
        options={wardsOptions}
        value={stripIdForDropdownValue(value)}
        register={register}
        hasError={hasError}
        onChange={handleChange}
        required={required}
        onBlur={onBlur}
      />
    </div>
  );
};
