import React from 'react';

import fontStyles from 'styles/typography.module.css';

export const TextStatus: React.FunctionComponent<{
  status: boolean;
}> = ({ status }) =>
  status ? (
    <span className={fontStyles.greenFontColor}>Active</span>
  ) : (
    <span className={fontStyles.redFontColor}>Inactive</span>
  );
