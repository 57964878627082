import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthState } from 'Auth';
import { caregiverStatusMap, formatLocation, StaffMember } from 'Caregivers';
import {
  CallTypes,
  ChatRoom,
  CommunicationActionTypes,
  ConversationTypes,
  createChatRoomCall,
  createEmptyChatRoom,
  initiateCall,
  MessagingHistoryConversation,
  StartCallType,
  StartCallTypes,
  useCommunicationDispatch
} from 'Communication';
import {
  ProfilePlaceholder,
  RoundAudioCallButton,
  RoundMessageButton,
  RoundVideoCallButton,
  Snackbar,
  WithPermissions
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { useLocationState } from 'Location';
import { formatName, isIEorEdge } from 'utils';

import style from './CareTeamListEntry.module.css';

export const CareTeamListEntry: React.FunctionComponent<{
  staffMember: StaffMember;
  activeChat?: ChatRoom;
  callCount: { videoCount?: number; audioCount?: number };
  onClick: () => void;
  isHighlighted: boolean;
  messagesCount?: number;
}> = ({
  staffMember,
  isHighlighted,
  activeChat,
  callCount,
  messagesCount,
  onClick
}) => {
  const [callType, setCallType] = useState<StartCallType>(
    StartCallTypes.START_AUDIO_CALL
  );
  const [activeChatId, setActiveChatId] = useState(activeChat?.id);
  const { loggedUser, loginId } = useAuthState();
  const {
    facility: { id: facilityId },
    ward: wardId,
    selectedWardDetails
  } = useLocationState();
  const dispatch = useCommunicationDispatch();
  const history = useHistory();

  const { id, name, role, photoUrl, status } = staffMember;
  const type = caregiverStatusMap[status];
  const formattedName = useMemo(() => formatName(name), [name]);

  const calleeDetails = useMemo(
    () => ({
      name: formattedName,
      type,
      title: role as string,
      photoUrl
    }),
    [formattedName, type, role, photoUrl]
  );

  const isAvailable = type && (type === 'available' || type === 'break');

  const { setAction, error: sendError, loading: onSaveLoading } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(sendError, onSaveLoading);

  const handleCardClicks = (evt: any) => {
    const audioButton = evt.target.className.includes('RoundAudioCallButton');
    const videoButton = evt.target.className.includes('RoundVideoCallButton');
    const messageButton = evt.target.className.includes('RoundMessageButton');
    if (audioButton || videoButton || messageButton) {
      evt.preventDefault();
    } else {
      onClick();
    }
  };

  const startVideoCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (activeChatId) {
      setAction(initiateCall(activeChatId, CallTypes.VIDEO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${
        staffMember.name
      } chat room`;

      const createdChatRoomId = await createChatRoomCall(
        [loginId, staffMember.loginId],
        ConversationTypes.CAREGIVER,
        CallTypes.VIDEO,
        description
      );

      setActiveChatId(createdChatRoomId);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    }
  };

  const startAudioCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (activeChatId) {
      setAction(initiateCall(activeChatId, CallTypes.AUDIO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${
        staffMember.name
      } chat room`;

      const createdChatRoomId = await createChatRoomCall(
        [loginId, staffMember.loginId],
        ConversationTypes.CAREGIVER,
        CallTypes.AUDIO,
        description
      );

      setActiveChatId(createdChatRoomId);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    }
  };

  const handleClickOnMessageIcon = useCallback(async () => {
    if (!activeChatId) {
      const description = `${loggedUser!.name} and ${
        staffMember.name
      } chat room`;

      const createdChatRoomId = await createEmptyChatRoom(
        [loginId, staffMember.loginId],
        ConversationTypes.CAREGIVER,
        description
      );
      const newConversation = {
        id: createdChatRoomId,
        chatRoomType: ConversationTypes.CAREGIVER,
        participants: [
          {
            id: staffMember.id,
            loginId: staffMember.loginId,
            name: staffMember.name,
            role: staffMember.role,
            status: staffMember.status
          },
          {
            id: loggedUser!.id,
            loginId,
            name: loggedUser!.name,
            role: loggedUser!.role
          }
        ],
        hasUnreadMessages: true,
        lastMessage: activeChat?.lastMessage
      };
      dispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: newConversation as MessagingHistoryConversation
      });
    } else {
      const conversation = {
        id: activeChatId,
        chatRoomType: ConversationTypes.CAREGIVER,
        participants: [
          {
            id: staffMember.id,
            loginId: staffMember.loginId,
            name: staffMember.name,
            role: staffMember.role,
            status: staffMember.status
          },
          {
            id: loggedUser!.id,
            loginId,
            name: loggedUser!.name,
            role: loggedUser!.role
          }
        ],
        hasUnreadMessages: Boolean(messagesCount && messagesCount > 0),
        lastMessage: activeChat?.lastMessage
      };

      dispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: conversation as MessagingHistoryConversation
      });
    }

    history.push(`/Communication?facility=${facilityId}&ward=${wardId}`);
  }, [
    activeChat,
    activeChatId,
    dispatch,
    facilityId,
    history,
    loggedUser,
    loginId,
    messagesCount,
    staffMember,
    wardId
  ]);

  useEffect(() => {
    if (!onSaveLoading && !sendError && submitted && activeChatId) {
      dispatch({
        type: callType,
        payload: calleeDetails
      });
      setSubmitted(false);
    }
  }, [
    onSaveLoading,
    sendError,
    submitted,
    setSubmitted,
    callType,
    calleeDetails,
    activeChatId,
    dispatch
  ]);

  return (
    <>
      <div
        onClick={handleCardClicks}
        className={classnames(style.entry, {
          [style.highlighted]: isHighlighted
        })}
      >
        <ProfilePlaceholder
          name={staffMember.name}
          type={type}
          photoUrl={staffMember.photoUrl}
        />
        <div className={style.content}>
          <div className={style.header}>
            <span className={style.name}>{formattedName}</span>
            {selectedWardDetails?.services.secureCommunication.features
              .scMessaging.isEnabled && (
              <WithPermissions
                action="communication-icons:view"
                data={{ loggedUser, itemId: id, isAvailable }}
                yes={
                  <div className={style.buttons}>
                    {selectedWardDetails?.services.secureCommunication.features
                      .scVoice.isEnabled && (
                      <RoundAudioCallButton
                        onClick={startAudioCall}
                        size={22}
                        missedCalls={callCount.audioCount}
                      />
                    )}
                    {selectedWardDetails?.services.secureCommunication.features
                      .scVideo.isEnabled && (
                      <RoundVideoCallButton
                        onClick={startVideoCall}
                        size={22}
                        missedCalls={callCount.videoCount}
                      />
                    )}
                    <RoundMessageButton
                      onClick={handleClickOnMessageIcon}
                      size={22}
                      missedMessages={messagesCount}
                    />
                  </div>
                }
              />
            )}
          </div>
          <div className={style.details}>
            <span className={style.role}>{staffMember.role}</span>
            {selectedWardDetails?.services.rtls.features.rtlsStaff
              .isEnabled && (
              <span className={style.location}>
                {staffMember.location
                  ? formatLocation(staffMember.location)
                  : 'Unknown'}
              </span>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        message={'Cannot initiate call. Please try again.'}
        isOpen={isErrorShowing}
        onClose={dismissError}
      />
    </>
  );
};
