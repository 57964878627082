import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthState } from 'Auth';
import { caregiverStatusMap, StaffMember, StatusTypes } from 'Caregivers';
import {
  CallTypes,
  ChatRoom,
  CommunicationActionTypes,
  ConversationTypes,
  createChatRoomCall,
  createEmptyChatRoom,
  initiateCall,
  MessagingHistoryConversation,
  StartCallType,
  StartCallTypes,
  useCommunicationDispatch
} from 'Communication';
import {
  ProfilePlaceholder,
  RoundAudioCallButton,
  RoundMessageButton,
  RoundVideoCallButton,
  Snackbar,
  WithPermissions
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { useLocationState } from 'Location';
import { AlarmTypes, useNotificationState } from 'Notifications';
import { ChatId } from 'types';
import { formatName, isIEorEdge } from 'utils';

import styles from './CaregiverListEntry.module.css';

const formatLocation = (location?: { room: string; ward?: string }): string => {
  if (location === undefined) {
    return 'Unknown';
  }

  if (location.ward) {
    return `${location.ward} - ${location.room}`;
  }
  return location.room;
};

export const CaregiverListEntry: React.FunctionComponent<{
  activeChat: ChatRoom | undefined;
  data: StaffMember;
  isSelected: boolean;
  callCount: { videoCount?: number; audioCount?: number };
  messagesCount?: number;
  currentChatId: ChatId | undefined;
  onSelect: (caregiver: StaffMember) => void;
}> = ({
  activeChat,
  data,
  isSelected,
  onSelect,
  currentChatId,
  callCount,
  messagesCount
}) => {
  const [callType, setCallType] = useState<StartCallType>(
    StartCallTypes.START_AUDIO_CALL
  );
  const [activeChatId, setActiveChatId] = useState<ChatId | undefined>(
    currentChatId
  );
  const { loggedUser, loginId } = useAuthState();
  const dispatch = useCommunicationDispatch();
  const { notifications } = useNotificationState();
  const {
    facility: { id: facilityId },
    ward: wardId,
    selectedWardDetails
  } = useLocationState();

  const history = useHistory();

  const {
    name,
    role,
    status,
    location,
    id,
    photoUrl,
    loginId: caregiverLoginId
  } = data;

  const type = useMemo(() => caregiverStatusMap[status], [status]);
  const formattedName = useMemo(() => formatName(name), [name]);

  const calleeDetails = useMemo(
    () => ({
      name: formattedName,
      type,
      title: role as string,
      photoUrl
    }),
    [formattedName, type, role, photoUrl]
  );

  const isAvailable = type && (type === 'available' || type === 'break');

  const isHighlighted = useMemo(
    () =>
      notifications.some(
        ({ type: notificationType, personOfInterest }) =>
          notificationType === AlarmTypes.CAREGIVER_ALARM &&
          personOfInterest?.id === id
      ),
    [id, notifications]
  );

  const { setAction, error: sendError, loading: onSaveLoading } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(sendError, onSaveLoading);

  const startVideoCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (activeChatId) {
      setAction(initiateCall(activeChatId, CallTypes.VIDEO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${name} chat room`;

      const createdChatRoomId = await createChatRoomCall(
        [loginId, caregiverLoginId],
        ConversationTypes.CAREGIVER,
        CallTypes.VIDEO,
        description
      );

      setActiveChatId(createdChatRoomId);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    }
  };

  const startAudioCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (activeChatId) {
      setAction(initiateCall(activeChatId, CallTypes.AUDIO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${name} chat room`;

      const createdChatRoomId = await createChatRoomCall(
        [loginId, caregiverLoginId],
        ConversationTypes.CAREGIVER,
        CallTypes.AUDIO,
        description
      );

      setActiveChatId(createdChatRoomId);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    }
  };

  const handleClickOnMessageIcon = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();

      let conversation = {
        id: '',
        chatRoomType: ConversationTypes.CAREGIVER,
        participants: [
          {
            id: data.id,
            loginId: data.loginId,
            name: data.name,
            role: data.role,
            status: data.status
          },
          {
            id: loggedUser!.id,
            loginId,
            name: loggedUser!.name,
            role: loggedUser!.role
          }
        ],
        lastMessage: activeChat?.lastMessage
      } as MessagingHistoryConversation;

      if (!currentChatId) {
        const description = `${loggedUser!.name} and ${data.name} chat room`;

        const createdChatRoomId = await createEmptyChatRoom(
          [loginId, data.loginId],
          ConversationTypes.CAREGIVER,
          description
        );

        conversation = {
          ...conversation,
          hasUnreadMessages: true,
          id: createdChatRoomId
        };
      } else {
        conversation = {
          ...conversation,
          hasUnreadMessages: Boolean(messagesCount && messagesCount > 0),
          id: currentChatId
        };
      }

      dispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: conversation as MessagingHistoryConversation
      });

      history.push(`/Communication?facility=${facilityId}&ward=${wardId}`);
    },
    [
      currentChatId,
      activeChat,
      data,
      dispatch,
      facilityId,
      history,
      loggedUser,
      loginId,
      messagesCount,
      wardId
    ]
  );

  useEffect(() => {
    if (!onSaveLoading && !sendError && submitted && activeChatId) {
      dispatch({
        type: callType,
        payload: calleeDetails
      });
      setSubmitted(false);
    }
  }, [
    onSaveLoading,
    sendError,
    submitted,
    setSubmitted,
    callType,
    calleeDetails,
    activeChatId,
    dispatch
  ]);

  const handleItemClick = () => {
    onSelect(data);
  };

  return (
    <>
      <li
        className={classnames(styles.entry, {
          [styles.selected]: isSelected,
          [styles.highlighted]: isHighlighted
        })}
        onClick={handleItemClick}
      >
        <ProfilePlaceholder
          name={formattedName}
          type={type}
          photoUrl={photoUrl}
        />
        <div className={styles.info}>
          <div className={styles.header}>
            <span className={styles.name}>{formattedName}</span>
            {selectedWardDetails?.services.secureCommunication.features
              .scMessaging.isEnabled && (
              <WithPermissions
                action="communication-icons:view"
                data={{ loggedUser, itemId: id, isAvailable }}
                yes={
                  <div className={styles.buttons}>
                    {selectedWardDetails?.services.secureCommunication.features
                      .scVoice.isEnabled && (
                      <RoundAudioCallButton
                        onClick={startAudioCall}
                        size={22}
                        missedCalls={callCount.audioCount}
                      />
                    )}
                    {selectedWardDetails?.services.secureCommunication.features
                      .scVideo.isEnabled && (
                      <RoundVideoCallButton
                        onClick={startVideoCall}
                        size={22}
                        missedCalls={callCount.videoCount}
                      />
                    )}
                    <RoundMessageButton
                      onClick={handleClickOnMessageIcon}
                      size={22}
                      missedMessages={messagesCount}
                    />
                  </div>
                }
              />
            )}
          </div>
          <div className={styles.details}>
            <span className={styles.role}>{role}</span>
            {status !== StatusTypes.OFF_DUTY &&
              selectedWardDetails?.services.rtls.features.rtlsStaff
                .isEnabled && (
                <span className={styles.location}>
                  {formatLocation(location)}
                </span>
              )}
          </div>
        </div>
      </li>
      <Snackbar
        message={'Cannot initiate call. Please try again.'}
        isOpen={isErrorShowing}
        onClose={dismissError}
      />
    </>
  );
};
