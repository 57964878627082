export const removeExtraSpaces = (str: string) =>
  str
    .split(' ')
    .filter(item => item)
    .join(' ');

export const removeAllSpaces = (str: string) => {
  return str
    .split(' ')
    .filter(item => item)
    .join('');
};

export const removeSpaces = (
  obj: { [key: string]: any },
  nospaces?: string[]
): { [key: string]: any } => {
  return Object.keys(obj)
    .map(key => {
      if (typeof obj[key] !== 'string') {
        return {
          [key]: obj[key]
        };
      }

      if (nospaces && nospaces.includes(key)) {
        return {
          [key]: removeAllSpaces(obj[key])
        };
      }
      return {
        [key]: removeExtraSpaces(obj[key])
      };
    })
    .reduce((newObj, field) => {
      return { ...newObj, ...field };
    }, {});
};

export const getInitials = (name: string) => {
  if (name) {
    const nameArray = name.trim().split(/\s+/);
    const initials = nameArray.reduce(
      (nameInitials, nameValue) => (nameInitials += nameValue[0].toUpperCase()),
      ''
    );

    return initials.length > 2
      ? initials.slice(0, 2) + initials.slice(-1)
      : initials.slice(0, 2);
  }
  return '';
};

export const splitName = (name: string) => {
  if (name) {
    const nameArray = name.trim().split(/\s+/);
    const firstName = nameArray[0];
    const lastName =
      nameArray.length > 1 ? nameArray[nameArray.length - 1] : '';
    const initial =
      nameArray.length > 2 ? `${nameArray[1][0]}.`.toUpperCase() : '';

    return { firstName, lastName, initial };
  }
  return { firstName: '', lastName: '', initial: '' };
};

export const formatName = (name: string) => {
  // TO DO - fix resident/caregiver object is empty
  if (!name) {
    return '';
  }

  const { firstName, lastName, initial } = splitName(name);

  return initial
    ? `${firstName} ${initial} ${lastName}`
    : `${firstName} ${lastName}`;
};

export const concatTitle = (
  arr: any[],
  fieldToConcat: string,
  specialCharacter?: string
) => {
  const fieldValues = [];

  for (const item of arr) {
    if (item[fieldToConcat] && fieldToConcat === 'name') {
      const formattedField = formatName(item[fieldToConcat]);
      fieldValues.push(formattedField);
    } else {
      fieldValues.push(item[fieldToConcat]);
    }
  }

  if (specialCharacter) {
    return fieldValues.join(specialCharacter);
  }

  return fieldValues.join();
};
