import React, { useEffect, useRef, useState } from 'react';

import { AccessMessage, FetchError, LoadingPlaceholder } from 'components';
import { useAddEventListener } from 'hooks';
import { useLocationContext } from 'Location';
import { debounce, generateSetTriggerFunction, triggerPagination } from 'utils';

import { useCardsContext } from './contexts';
import { ResidentCard } from './ResidentCard';

import style from './ResidentsOverview.module.css';

export const ResidentsOverview: React.FunctionComponent = () => {
  const { cards, fetchMoreCards, fetching, fetchError } = useCardsContext();

  const {
    location: {
      facility: { id: facilityId },
      ward: wardId
    },
    label
  } = useLocationContext();

  const [content, setContent] = useState<JSX.Element[]>([]);
  const [placeholderMessage, setPlaceholderMessage] = useState<string>();
  const [ResidentsByRoomList, setResidentsByRoomList] = useState<
    JSX.Element | JSX.Element[] | null
  >(null);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [initialPagination, setInitialPagination] = useState<boolean>(true);
  const wrapper = useRef<HTMLDivElement>(null);
  const { current } = wrapper;

  useAddEventListener(
    current,
    'scroll',
    debounce(generateSetTriggerFunction(current!, setTrigger)),
    !initialPagination
  );

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
      fetchMoreCards();
    } else {
      setInitialPagination(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (trigger || !current) {
      return undefined;
    }
    const flag = triggerPagination(current);
    setTrigger(flag);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, current]);

  useEffect(() => {
    if (!wardId || !facilityId) {
      return setContent([]);
    }

    const list = cards.map(item => {
      const location =
        item.wardName && item.roomNumber
          ? `${item.wardName} - ${item.roomNumber}`
          : 'Unknown';

      return (
        <ResidentCard
          key={item.cardId}
          id={item.cardId}
          location={location}
          name={item.cardName}
          type={item.residentGender?.toLocaleLowerCase()}
          link={item.cardLink}
          photoUrl={item.residentPhotoUrl}
        />
      );
    });

    setContent(list);
  }, [facilityId, cards, wardId]);

  useEffect(() => {
    setInitialPagination(true);
  }, [facilityId, wardId]);

  useEffect(() => {
    setPlaceholderMessage(`No ${label.toLowerCase()}s for this ward`);
  }, [label]);

  useEffect(() => {
    if (content.length === 0) {
      return setResidentsByRoomList(
        <p className={style.listPlaceholder}>{placeholderMessage}</p>
      );
    } else {
      return setResidentsByRoomList(content);
    }
  }, [content, placeholderMessage]);

  return (
    <div className={style.content} ref={wrapper}>
      {wardId && (
        <div className={style.listContainer}>{ResidentsByRoomList}</div>
      )}
      {(initialPagination || fetching) && !fetchError && (
        <LoadingPlaceholder
          fullHeight={true}
          withTransparency={true}
          customStyles={style.customPlaceholder}
        />
      )}
      {fetchError && !fetching && <FetchError error={fetchError} />}
      <AccessMessage />
    </div>
  );
};
