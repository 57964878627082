import React, { useRef } from 'react';

import { Column } from 'components';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';

import stylesTable from 'components/Table/Table.module.css';
import styles from 'styles/typography.module.css';

export const WardTableRow: React.FunctionComponent<{
  values: MappedWard;
  isSelected: boolean;
  setActiveTab: (tab: string) => void;
  onSelect: (id: string) => void;
  columns: { [key: string]: Column };
}> = ({ values, isSelected, onSelect, setActiveTab, columns }) => {
  const linkRef = useRef(null);
  const {
    id,
    name,
    numberOfRooms,
    numberOfCaregivers,
    numberOfResidents
  } = values;

  const handleClick = (evt: any) => {
    if (evt.target === linkRef.current && isSelected) {
      return;
    }
    if (id) {
      onSelect(id);
    }
  };

  const handleLink = () => {
    if (id) {
      setActiveTab('Wards');
    }
  };

  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div onClick={handleClick} className={rowClass} role="row">
      <div
        className={`${stylesTable.tableCell} ${styles.regularLinkFont}`}
        style={{
          flexGrow: columns['name'].cols,
          textAlign: columns['name'].align
        }}
        role="cell"
        onClick={handleLink}
        ref={linkRef}
      >
        {name}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{
          flexGrow: columns['numberOfRooms'].cols,
          textAlign: columns['numberOfRooms'].align
        }}
        role="cell"
      >
        {numberOfRooms || 0}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{
          flexGrow: columns['numberOfCaregivers'].cols,
          textAlign: columns['numberOfCaregivers'].align
        }}
        role="cell"
      >
        {numberOfCaregivers || 0}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{
          flexGrow: columns['numberOfResidents'].cols,
          textAlign: columns['numberOfResidents'].align
        }}
        role="cell"
      >
        {numberOfResidents || 0}
      </div>
    </div>
  );
};
