import { ResidentContact } from 'Settings';
import { axiosWrapper } from 'utils';
import { ResidentContactListResponse } from '../types/resident.type';

export const getResidentContact = (
  residentId: string,
  contactId: string
) => async () => {
  const response = await axiosWrapper<ResidentContact>(
    `/residents/${residentId}/related-contacts/${contactId}`
  );
  return response.data;
};

export const getResidentContactByQuery = async (
  residentId: string,
  searchQuery: string,
  page: string
) => {
  const response = await axiosWrapper<ResidentContactListResponse>(
    `/residents/${residentId}/related-contacts?relatedContactSearchQuery=${searchQuery}&page=${page}`
  );
  return response.data;
};
