import React, { useState } from 'react';

import { Modal } from 'components';
import { useModal } from 'hooks';
import { DeviceLocation } from 'Location';
import {
  getLastWeekHistoryFilters,
  getThisMonthHistoryFilters,
  getThisWeekHistoryFilters,
  HistoryLabel
} from 'Residents';

import { ResidentLocationCard } from './Cards';
import { ResidentLocationHistory } from './History';

import style from 'Residents/History/History.module.css';

export const ResidentLocationDetails: React.FunctionComponent<{
  residentId: string;
  facilityId: string;
  residentLocation: DeviceLocation | undefined;
}> = ({ residentId, facilityId, residentLocation }) => {
  const [historyFilters, setHistoryFilters] = useState(
    getThisWeekHistoryFilters()
  );
  const { isShowing: isHistoryOpen, toggle: toggleHistoryView } = useModal();

  const onCloseHistoryView = () => {
    setHistoryFilters(getThisWeekHistoryFilters());
    toggleHistoryView();
  };

  const changeHistoryFilter = (historyLabel: HistoryLabel) => {
    switch (historyLabel) {
      case 'This Week':
        setHistoryFilters(getThisWeekHistoryFilters());
        break;
      case 'Last Week':
        setHistoryFilters(getLastWeekHistoryFilters());
        break;
      case 'This Month':
        setHistoryFilters(getThisMonthHistoryFilters());
        break;
    }
  };

  return (
    <>
      <ResidentLocationCard
        onClick={toggleHistoryView}
        residentLocation={residentLocation}
      />
      <Modal isShowing={isHistoryOpen} toggle={toggleHistoryView}>
        <div className={style.modalBackdrop} id="HistoryModal">
          <div className={style.modalContent}>
            <ResidentLocationHistory
              onClose={onCloseHistoryView}
              historyFilters={historyFilters}
              residentId={residentId}
              facilityId={facilityId}
              changeHistoryFilter={changeHistoryFilter}
              currentLocation={residentLocation}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
