import { Idn } from 'Settings/types';

export const createFacilityDTO = (values: any, idn: Idn) => ({
  ...values,
  name: values.name.trim(),
  idn: {
    name: idn.name,
    id: idn.id,
    number: idn.number
  }
});
