import classnames from 'classnames';
import React, { useState } from 'react';
import useForm from 'react-hook-form';

import {
  FetchError,
  FormHeader,
  ImageUpload,
  Input,
  Select,
  StatusDropdown
} from 'components';
import { states, userTitles } from 'consts';
import { User } from 'Settings';
import { editUserAvatar } from 'UserProfile';

import { EMAIL_PATTERN, VALIDATION_MESSAGE } from 'Settings/constants';
import styles from './EditUserForm.module.css';

interface EditUserFormData {
  name: string;
  title: string;
  isActive: boolean;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  password: string;
  photoUrl?: string;
}

const stateOptions = states.map(state => ({
  value: state.value,
  label: state.label
}));

const titleOptions = userTitles.map(title => ({
  value: title,
  label: title
}));

export const EditUserForm: React.FunctionComponent<{
  onSave: (user: User) => void;
  onCancel: () => void;
  user: User;
  onSaveLoading: boolean;
}> = ({ onSave, onCancel, onSaveLoading, children, user }) => {
  const [photoUrl, setPhotoUrl] = useState(user.photoUrl);
  const [imageUploadError, setImageUploadError] = useState<Error | null>(null);

  const {
    register,
    getValues,
    errors,
    setValue,
    watch,
    triggerValidation
  } = useForm<EditUserFormData>({
    mode: 'onBlur',
    defaultValues: {
      ...user
    },
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const userData = getValues();

      const { password, ...updates } = userData;

      const userDataToSave = userData.password.length > 0 ? userData : updates;

      if (photoUrl && photoUrl !== user.photoUrl) {
        userDataToSave.photoUrl = photoUrl;
      }

      onSave(({
        ...userDataToSave,
        name: userData.name.trim(),
        id: user.id
      } as unknown) as User);
    }
  };

  const getValidationErrors = () => {
    const errorEntries = Object.entries(errors);
    if (!errorEntries.length) {
      return null;
    }

    return errorEntries.reduce((messages: any[], errorEntry) => {
      const [type, error] = errorEntry;
      if (error && error.message) {
        const errorContent = (
          <h3 className={styles.validationError} key={type}>
            {error.message}
          </h3>
        );
        return [...messages, errorContent];
      }
      return messages;
    }, [] as string[]);
  };

  const handleAvatarChange = async (image: File | Blob, filename: string) => {
    try {
      const imageURL = await editUserAvatar(image, filename);
      setPhotoUrl(imageURL);
    } catch (error) {
      setImageUploadError(error as Error);
    }
  };

  return (
    <>
      <FormHeader
        title="Edit User"
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitDisabled={onSaveLoading}
      />
      {imageUploadError ? (
        <FetchError
          error={imageUploadError}
          closable={true}
          onClose={onCancel}
        />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className={styles.formRow}>
              <div
                className={classnames(styles.inputGroupHalf, {
                  [styles.inputGroupWithAvatar]: true
                })}
              >
                <ImageUpload
                  name={user.name}
                  onImageChange={handleAvatarChange}
                  photoUrl={photoUrl}
                />
                <Input
                  name="name"
                  label="Full Name"
                  register={register}
                  validationRules={{
                    required: {
                      value: true,
                      message: 'Name field is required.'
                    }
                  }}
                  hasError={!!errors.name}
                />
              </div>
              <div className={styles.selectGroupQuarter}>
                <Select
                  name="title"
                  label="Title"
                  options={titleOptions}
                  hasError={!!errors.title}
                  register={register}
                  onChange={setValue}
                  value={watch('title')}
                  required={true}
                />
              </div>
              <div className={styles.selectGroupStatusSmall}>
                <StatusDropdown
                  register={register}
                  onChange={setValue}
                  value={watch('isActive')}
                  hasError={!!errors.isActive}
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.inputGroupQuarter}>
                <Input
                  name="address"
                  label="Address"
                  register={register}
                  hasError={!!errors.address}
                />
              </div>
              <div className={styles.inputGroupQuarter}>
                <Input
                  name="city"
                  label="City"
                  register={register}
                  hasError={!!errors.city}
                />
              </div>
              <div className={styles.selectGroupQuarter}>
                <Select
                  name="state"
                  label="State"
                  options={stateOptions}
                  hasError={!!errors.state}
                  register={register}
                  onChange={setValue}
                  value={watch('state')}
                />
              </div>
              <div className={styles.inputGroupQuarter}>
                <Input
                  name="zip"
                  label="Zip"
                  register={register}
                  hasError={!!errors.zip}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.inputGroupQuarter}>
                <Input
                  name="email"
                  label="E-mail Address"
                  register={register}
                  hasError={!!errors.email}
                  validationRules={{
                    required: {
                      value: true,
                      message: 'E-mail Address field is required.'
                    },
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: VALIDATION_MESSAGE
                    }
                  }}
                />
              </div>
              <div className={styles.inputGroupQuarter}>
                <Input
                  name="homePhone"
                  label="Home Phone"
                  register={register}
                  hasError={!!errors.homePhone}
                  validationRules={{
                    pattern: {
                      value: /^(\([0-9]{3}\)) ([0-9]{3})-([0-9]{4})$/,
                      message:
                        'Invalid phone number. Please use (XXX) XXX-XXXX format.'
                    }
                  }}
                />
              </div>
              <div className={styles.inputGroupQuarter}>
                <Input
                  name="mobilePhone"
                  label="Cell Phone Number"
                  register={register}
                  hasError={!!errors.mobilePhone}
                  validationRules={{
                    pattern: {
                      value: /^(\([0-9]{3}\)) ([0-9]{3})-([0-9]{4})$/,
                      message:
                        'Invalid phone number. Please use (XXX) XXX-XXXX format.'
                    }
                  }}
                />
              </div>
              <div className={styles.inputGroupQuarter}>
                <Input
                  name="password"
                  label="Password"
                  register={register}
                  hasError={!!errors.password}
                  type="password"
                />
              </div>
            </div>
          </form>
        </>
      )}
      {children}
      {getValidationErrors() && (
        <div className={styles.errorsWrapper}>{getValidationErrors()}</div>
      )}
    </>
  );
};
