import { useState } from 'react';

interface ModalHook {
  isShowing: boolean;
  toggle: (state?: boolean) => void;
}
export function useModal(): ModalHook {
  const [isShowing, setIsShowing] = useState(false);

  function toggle(state?: boolean) {
    setIsShowing(typeof state === 'boolean' ? state : !isShowing);
  }

  return {
    isShowing,
    toggle
  };
}
