import React, { useEffect, useRef } from 'react';

import { TextStatus } from 'components';

import { Caregiver } from '../types/caregiver.type';

import stylesTable from 'components/Table/Table.module.css';

export const CaregiverTableRow: React.FunctionComponent<{
  values: Caregiver;
  selectCaregiver: (caregiverId: string) => void;
  isSelected: boolean;
}> = ({ values, selectCaregiver, isSelected }) => {
  const handleClick = () => {
    selectCaregiver(values.id);
  };
  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  const row = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && row.current) {
      row.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={row} onClick={handleClick} className={rowClass} role="row">
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        {values.name}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        {values.role}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {values.number}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        <TextStatus status={values.isActive} />
      </div>
    </div>
  );
};
