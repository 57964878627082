import React from 'react';

import { VitalCard } from './VitalCard';

import { ReactComponent as Scale } from 'icons/scale.svg';
import { BodyWeight, convertTimestamp } from 'Residents';

import style from './BodyWeightVitalCard.module.css';

export const BodyWeightVitalCard: React.FunctionComponent<{
  bodyWeight: BodyWeight;
  onClick: () => void;
}> = ({ bodyWeight, onClick }) => {
  const { measuredAt, value, unit } = bodyWeight || {};

  return (
    <div className={style.bodyWeight} onClick={onClick}>
      <VitalCard
        title="Body Weight"
        reading={value && value.bodyWeight}
        timestamp={measuredAt && convertTimestamp(measuredAt)}
        hasWarning={false}
        unit={unit}
      >
        <Scale />
      </VitalCard>
    </div>
  );
};
