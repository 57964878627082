import classnames from 'classnames';
import React from 'react';

import { HistoryLabel } from 'Residents';

import styles from './HistoryFilterTabs.module.css';

const historyTabs = ['This Week', 'Last Week', 'This Month'];

export const HistoryFilterTabs: React.FunctionComponent<{
  onSelect: (tab: HistoryLabel) => void;
  activeTab: HistoryLabel;
  color?: string;
}> = ({ onSelect, activeTab, color = '#FFF' }) => {
  const onTabClick = (e: any) => {
    const { id } = e.target;
    onSelect(id);
  };

  const getColorStyles = (tab: string) => {
    const isActiveTab = tab === activeTab;
    const defaultStyles = {
      backgroundColor: isActiveTab ? color : 'transparent'
    };

    return color !== '#FFF'
      ? {
          ...defaultStyles,
          color: isActiveTab ? '#FFF' : color
        }
      : defaultStyles;
  };

  return (
    <div className={styles.historyTabs}>
      {historyTabs.map(tabName => (
        <button
          className={classnames(styles.tab, {
            [styles.active]: activeTab === tabName
          })}
          key={tabName}
          id={tabName}
          onClick={onTabClick}
          style={getColorStyles(tabName)}
        >
          {tabName}
        </button>
      ))}
    </div>
  );
};
