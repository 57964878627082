import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  FormHeader,
  ImageUpload,
  Input,
  Select,
  StatusDropdown
} from 'components';
import { states, userTitles } from 'consts';
import { User, UserError } from 'Settings';
import { EMAIL_PATTERN, VALIDATION_MESSAGE } from 'Settings/constants';
import { editUserAvatar } from 'UserProfile';
import { getFormValidationErrors } from 'utils';

import styles from './AddNewUser.module.css';

interface AddUserFormData {
  name: string;
  title: string;
  isActive: boolean;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  password: string;
  photoUrl?: string;
}

const stateOptions = states.map(state => ({
  value: state.value,
  label: state.label
}));

const titleOptions = userTitles.map(title => ({
  value: title,
  label: title
}));

export const AddUserForm: React.FunctionComponent<{
  onSave: (user: User) => void;
  onCancel: () => void;
  onSaveLoading: boolean;
  error: Error | null;
  isErrorShowing: boolean;
  dismissError: () => void;
}> = ({
  onSave,
  onCancel,
  onSaveLoading,
  error,
  isErrorShowing,
  dismissError,
  children
}) => {
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [localErrors, setLocalErrors] = useState<{
    name: boolean;
    email: boolean;
  }>({ name: false, email: false });
  const [validationErrors, setValidationErrors] = useState<any[] | null>();

  const {
    register,
    getValues,
    errors,
    setValue,
    watch,
    triggerValidation
  } = useForm<AddUserFormData>({
    mode: 'onBlur',
    defaultValues: {
      isActive: true
    },
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const userData = getValues();
      userData.photoUrl = photoUrl;
      onSave(({
        ...userData,
        name: userData.name.trim()
      } as unknown) as User);
    }
  };

  const handleAvatarChange = async (image: File | Blob, filename: string) => {
    const imageURL = await editUserAvatar(image, filename);
    setPhotoUrl(imageURL);
  };

  useEffect(() => {
    const formValidationErrors = getFormValidationErrors(errors);
    setValidationErrors(formValidationErrors);
  }, [errors]);

  const handleBlur = () => {
    triggerValidation([{ name: 'title' }]);
  };

  const handleInputChange = () => {
    if (error) {
      dismissError();
    }
  };

  useEffect(() => {
    const nameHasError =
      Boolean(errors.name) ||
      Boolean(
        isErrorShowing &&
          error &&
          (error as UserError).code === 'thUser.name.empty'
      );
    const emailsHasError =
      Boolean(errors.email) ||
      Boolean(
        isErrorShowing &&
          error &&
          (error as UserError).code === 'user.email.unique'
      );

    setLocalErrors({
      name: nameHasError,
      email: emailsHasError
    });
  }, [errors, error, isErrorShowing]);

  return (
    <>
      <FormHeader
        title="Add New User"
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitDisabled={onSaveLoading}
      />

      <form onSubmit={handleSubmit}>
        <div className={styles.formRow}>
          <div
            className={classnames(styles.inputGroupHalf, {
              [styles.inputGroupWithAvatar]: true
            })}
          >
            <ImageUpload
              onImageChange={handleAvatarChange}
              photoUrl={photoUrl}
            />
            <Input
              name="name"
              label="Full Name"
              register={register}
              validationRules={{
                required: { value: true, message: 'Name field is required.' }
              }}
              hasError={localErrors.name}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.selectGroupQuarter}>
            <Select
              name="title"
              label="Title"
              options={titleOptions}
              hasError={!!errors.title}
              register={register}
              onChange={setValue}
              value={watch('title')}
              required={true}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.selectGroupStatusSmall}>
            <StatusDropdown
              register={register}
              onChange={setValue}
              value={watch('isActive')}
              hasError={!!errors.isActive}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.inputGroupQuarter}>
            <Input
              name="address"
              label="Address"
              register={register}
              hasError={!!errors.address}
            />
          </div>
          <div className={styles.inputGroupQuarter}>
            <Input
              name="city"
              label="City"
              register={register}
              hasError={!!errors.city}
            />
          </div>
          <div className={styles.selectGroupQuarter}>
            <Select
              name="state"
              label="State"
              options={stateOptions}
              hasError={!!errors.state}
              register={register}
              onChange={setValue}
              value={watch('state')}
            />
          </div>
          <div className={styles.inputGroupQuarter}>
            <Input
              name="zip"
              label="Zip"
              register={register}
              hasError={!!errors.zip}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.inputGroupQuarter}>
            <Input
              name="email"
              label="E-mail Address"
              register={register}
              hasError={localErrors.email}
              validationRules={{
                required: {
                  value: true,
                  message: 'E-mail Address field is required.'
                },
                pattern: {
                  value: EMAIL_PATTERN,
                  message: VALIDATION_MESSAGE
                }
              }}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.inputGroupQuarter}>
            <Input
              name="homePhone"
              label="Home Phone"
              register={register}
              hasError={!!errors.homePhone}
              validationRules={{
                pattern: {
                  value: /^(\([0-9]{3}\)) ([0-9]{3})-([0-9]{4})$/,
                  message:
                    'Invalid phone number. Please use (XXX) XXX-XXXX format.'
                }
              }}
            />
          </div>
          <div className={styles.inputGroupQuarter}>
            <Input
              name="mobilePhone"
              label="Cell Phone Number"
              register={register}
              hasError={!!errors.mobilePhone}
              validationRules={{
                pattern: {
                  value: /^(\([0-9]{3}\)) ([0-9]{3})-([0-9]{4})$/,
                  message:
                    'Invalid phone number. Please use (XXX) XXX-XXXX format.'
                }
              }}
            />
          </div>
          <div className={styles.inputGroupQuarter}>
            <Input
              name="password"
              label="Password"
              register={register}
              validationRules={{
                required: {
                  value: true,
                  message: 'Password field is required.'
                },
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long.'
                }
              }}
              hasError={!!errors.password}
              type="password"
            />
          </div>
        </div>
      </form>
      {children}
      {validationErrors && (
        <div className={styles.errorsWrapper}>{validationErrors}</div>
      )}
    </>
  );
};
