import React from 'react';

import { ReactComponent as NeutralFace } from 'icons/neutral-face.svg';
import { ReactComponent as SadFace } from 'icons/sad-face.svg';
import { ReactComponent as SmilingFace } from 'icons/smiling-face.svg';

import styles from './HistoryLogEntry.module.css';

const iconsByColor: {
  [key: string]: {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    color: string;
  };
} = {
  GREEN: { Icon: SmilingFace, color: '#8ec738' },
  YELLOW: { Icon: NeutralFace, color: '#ffd600' },
  ORANGE: { Icon: NeutralFace, color: '#F18F01' },
  DARK_ORANGE: { Icon: SadFace, color: '#e65100' },
  RED: { Icon: SadFace, color: '#E73827' }
};

export const HistoryLogEntry: React.FunctionComponent<{
  timestamp: string;
  date: string;
  code?: string;
}> = ({ timestamp, date, code = '', children }) => {
  const { Icon, color } = iconsByColor[code] || {};

  return (
    <li className={styles.logEntry}>
      <div className={styles.dateWrapper}>
        <span className={styles.day}>{timestamp}</span>
        <span className={styles.date}>{date}</span>
      </div>
      {children}
      {code && <Icon style={{ stroke: color, fill: color }} />}
    </li>
  );
};
