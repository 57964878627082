import React from 'react';

import { ReactComponent as Chair } from 'icons/armchair.svg';
import { secondsToDecimalHours, SittingHours } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './SittingHoursCard.module.css';

export const SittingHoursCard: React.FunctionComponent<{
  sittingHours: SittingHours | undefined;
  onClick: () => void;
}> = ({ sittingHours, onClick }) => {
  const { value, unit } = sittingHours || {};
  const reading =
    value && Math.trunc(secondsToDecimalHours(parseInt(value.count, 10)));

  return (
    <div className={style.sittingHours} onClick={onClick}>
      <ActivityInfoCard reading={reading} unit={unit} type="sittingHours">
        <Chair />
      </ActivityInfoCard>
    </div>
  );
};
