import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  Reducer,
  useContext,
  useEffect,
  useReducer
} from 'react';

import {
  AdHocActionType,
  AdHocActionTypes,
  IAdHocContext,
  useCommunicationState
} from 'Communication';

const InitialContext: IAdHocContext = {
  adHocName: '',
  nameEdited: false,
  selectedIds: [],
  selectedList: [],
  adHocChatId: null
};

const AdHocContext = createContext<IAdHocContext>(InitialContext);
const AdHocDispatch = createContext<Dispatch<AdHocActionType>>(() => undefined);

const adHocReducer: Reducer<IAdHocContext, AdHocActionType> = (
  state,
  action
) => {
  switch (action.type) {
    case AdHocActionTypes.SET_AD_HOC_NAME:
      return {
        ...state,
        adHocName: action.payload
      };
    case AdHocActionTypes.SET_EDITED_NAME:
      return {
        ...state,
        nameEdited: action.payload
      };
    case AdHocActionTypes.SET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: action.payload
      };
    case AdHocActionTypes.SET_SELECTED_AD_HOC:
      return {
        ...state,
        adHocChatId: action.payload
      };
    case AdHocActionTypes.SET_SELECTED_LIST:
      return {
        ...state,
        selectedList: action.payload
      };
    case AdHocActionTypes.RESET_AD_HOC:
      return InitialContext;
    default:
      return state;
  }
};

export const AdHocProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(adHocReducer, InitialContext);

  const {
    activeConversation: { id: activeConversationId }
  } = useCommunicationState();

  useEffect(() => {
    if (activeConversationId) {
      dispatch({
        type: AdHocActionTypes.RESET_AD_HOC
      });
    }
  }, [activeConversationId]);

  return (
    <AdHocContext.Provider value={state}>
      <AdHocDispatch.Provider value={dispatch}>
        {children}
      </AdHocDispatch.Provider>
    </AdHocContext.Provider>
  );
};

export const useAdHocContext = () => {
  const adHocContext = useContext<IAdHocContext>(AdHocContext);

  if (adHocContext === undefined) {
    throw new Error('useAdHocContext must be used within a AdHocProvider');
  }

  return adHocContext;
};

export const useAdHocDispatch = () => {
  const adHocDispatch = useContext<Dispatch<AdHocActionType>>(AdHocDispatch);

  if (adHocDispatch === undefined) {
    throw new Error('adHocDispatch must be used within a AdHocProvider');
  }

  return adHocDispatch;
};

export const useAdHocProvider = () => {
  return {
    adHocContext: useAdHocContext(),
    adHocDispatch: useAdHocDispatch()
  };
};
