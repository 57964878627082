import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Snackbar } from 'components';
import { ReactComponent as ImageIcon } from 'icons/image-folder.svg';
import { checkFileType } from 'utils';

import styles from './DroppableFileUpload.module.css';

const fileErrorMessages = {
  INVALID_TYPE: 'Please upload a file with the supported types: JPEG, PNG.',
  MULTIPLE_FILES: 'Please upload only one file at a time.'
};

export const DroppableFileUpload: React.FunctionComponent<{
  onFileLoaded: (file: File | null) => void;
}> = ({ onFileLoaded }) => {
  const [isOverDroppable, setIsOverDroppable] = useState(false);
  const [fileError, setFileError] = useState<
    'MULTIPLE_FILES' | 'INVALID_TYPE'
  >();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const droppableContainer = useRef<HTMLDivElement | null>(null);

  const processUploadedFile = useCallback(
    (file: File | null) => {
      if (file) {
        checkFileType(file, (isValid: boolean) => {
          if (!isValid) {
            setFileError('INVALID_TYPE');
            return;
          }
          onFileLoaded(file);
          setFileError(undefined);
        });
      }
    },
    [onFileLoaded]
  );

  useEffect(() => {
    const droppable = droppableContainer.current;

    const onDragEnter = (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      setIsOverDroppable(true);
    };
    const onDragLeave = (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      setIsOverDroppable(false);
    };
    const onDragOver = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
    };
    const onDrop = (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      setIsOverDroppable(false);

      const dataTransfer = e.dataTransfer;
      if (dataTransfer.files.length > 1) {
        setFileError('MULTIPLE_FILES');
        return;
      }
      const droppedFile = dataTransfer.files[0];
      processUploadedFile(droppedFile);
    };

    const removeEvents = (element: HTMLDivElement) => {
      element.removeEventListener('dragenter', onDragEnter, false);
      element.removeEventListener('dragleave', onDragLeave, false);
      element.removeEventListener('dragover', onDragOver, false);
      element.removeEventListener('drop', onDrop, false);
    };

    if (!droppable) {
      return;
    }

    droppable.addEventListener('dragenter', onDragEnter, false);
    droppable.addEventListener('dragleave', onDragLeave, false);
    droppable.addEventListener('dragover', onDragOver, false);
    droppable.addEventListener('drop', onDrop, false);

    return () => {
      if (droppable) {
        removeEvents(droppable);
      }
    };
  }, [processUploadedFile]);

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = evt => {
    const files = evt.target.files;
    if (files && files.length > 1) {
      setFileError('MULTIPLE_FILES');
      return;
    }
    processUploadedFile(files![0]);
  };

  const openFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearError = () => {
    setFileError(undefined);
  };

  return (
    <>
      <div
        className={classnames(styles.dropContainer, {
          [styles.highlighted]: isOverDroppable
        })}
        ref={droppableContainer}
      >
        <ImageIcon />
        <p className={styles.uploadInfo}>
          Drop your file here, or
          <button className={styles.browseButton} onClick={openFileUpload}>
            browse
          </button>
          <input
            ref={fileInputRef}
            className={styles.hidden}
            type="file"
            accept=".jpg, .jpeg, .png"
            multiple={false}
            onChange={onInputChange}
          />
        </p>
        <p className={styles.additionalInfo}>Supported types: JPG, JPEG, PNG</p>
      </div>
      <Snackbar
        message={fileError ? fileErrorMessages[fileError] : ''}
        isOpen={!!fileError}
        onClose={clearError}
      />
    </>
  );
};
