import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  DetailBox,
  DeviceFetchDropdown,
  FetchError,
  Input,
  LoadingPlaceholder,
  PanelHeader,
  ProfileComponent,
  RoundCheckButton,
  RoundCrossButton,
  Select
} from 'components';
import { caregiverRoles } from 'consts';
import { useLocationState } from 'Location';
import { Facility } from 'Settings';
import { getAvailableCaregiverDevices, getFormValidationErrors } from 'utils';

import { createCaregiverDTO } from './createCaregiverDTO';
import { caregiverStatusMap } from './status.constant';
import { StaffMemberDetail } from './types';
import { formatLocation } from './utils';

import styles from './CaregiverDetailsPanel.module.css';

interface EditCaregiverFormData {
  role: string;
  badge: { id: string; name: string } | null;
  phoneExtension: string;
}

export const EditCaregiverForm: React.FunctionComponent<{
  caregiver: StaffMemberDetail;
  facility: Facility;
  onSave: (formData: any) => void;
  onSaveLoading: boolean;
  onCancel: () => void;
}> = ({ caregiver, facility, onCancel, onSaveLoading, onSave }) => {
  const [deviceStatus, setDeviceStatus] = useState({
    loading: true,
    error: false
  });
  const [validationErrors, setValidationErrors] = useState<any[] | null>();

  const { selectedWardDetails } = useLocationState();

  const getDefaultValues = () => {
    const { role, badge, phoneExtension } = caregiver;
    return {
      role,
      badge: badge ? { id: badge.id, name: badge.name } : null,
      phoneExtension
    };
  };

  const {
    register,
    getValues,
    errors,
    setValue,
    watch,
    triggerValidation
  } = useForm<EditCaregiverFormData>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const caregiverData = createCaregiverDTO(getValues(), caregiver);
      onSave(caregiverData);
    }
  };

  const roleOptions = caregiverRoles.map(caregiverRole => ({
    value: caregiverRole,
    label: caregiverRole
  }));

  useEffect(() => {
    const formValidationErrors = getFormValidationErrors(errors);
    setValidationErrors(formValidationErrors);
  }, [errors]);

  const fetchLoading = selectedWardDetails?.services.rtls.features.rtlsStaff
    .isEnabled
    ? onSaveLoading || deviceStatus.loading
    : onSaveLoading;

  const fetchError = !!deviceStatus.error;

  return (
    <>
      {!fetchError && !fetchLoading && (
        <PanelHeader title="Caregiver Details">
          <RoundCrossButton onClick={onCancel} />
          <RoundCheckButton onClick={handleSubmit} disabled={onSaveLoading} />
        </PanelHeader>
      )}
      {fetchError && !fetchLoading && (
        <FetchError error={fetchError} closable={true} onClose={onCancel} />
      )}
      <form
        onSubmit={handleSubmit}
        className={classnames(styles.caregiverDetailsForm, {
          [styles.displayNone]: fetchLoading || fetchError
        })}
      >
        <div className={styles.detailRow}>
          <ProfileComponent
            name={caregiver.name}
            label="Caregiver"
            photoUrl={caregiver.photoUrl}
            style={styles.thirdCentered}
            type={caregiverStatusMap[caregiver.status]}
          />
          <div className={styles.selectGroupThird}>
            <Select
              name="role"
              label="Caregiver Role"
              options={roleOptions}
              hasError={!!errors.role}
              register={register}
              onChange={setValue}
              value={watch('role')}
              required={true}
            />
          </div>
          {selectedWardDetails?.services.rtls.features.rtlsStaff.isEnabled && (
            <div className={styles.thirdCentered}>
              <DetailBox
                label="Current Location"
                value={
                  caregiver.location
                    ? formatLocation(caregiver.location)
                    : 'Unknown'
                }
              />
            </div>
          )}
        </div>
        <div className={styles.detailRow}>
          <div className={styles.third}>
            <DetailBox
              label="E-mail Address"
              value={caregiver.email}
              isLink={true}
            />
          </div>
          <div className={styles.third}>
            <DetailBox
              label="Home Phone"
              value={caregiver.homePhone}
              isLink={true}
            />
          </div>
          <div className={styles.third}>
            <DetailBox
              label="Cell Phone"
              value={caregiver.mobilePhone}
              isLink={true}
            />
          </div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.third}>
            <DetailBox label="Employee Number" value={caregiver.number} />
          </div>
          {selectedWardDetails?.services.rtls.features.rtlsStaff.isEnabled && (
            <DeviceFetchDropdown
              name="badge"
              value={watch('badge')}
              onChange={setValue}
              register={register}
              hasError={!!errors.badge}
              facility={facility}
              changeStatus={setDeviceStatus}
              label="Location Badge"
              cssClass={styles.selectGroupThird}
              filterFunction={getAvailableCaregiverDevices}
              defaultValue={getDefaultValues().badge}
            />
          )}
          <div className={styles.inputGroupThird}>
            <Input
              name="phoneExtension"
              label="Phone Extension"
              register={register}
              hasError={!!errors.phoneExtension}
              isLink={true}
            />
          </div>
        </div>
      </form>
      {validationErrors && (
        <div className={styles.errorsWrapper}>{validationErrors}</div>
      )}
      {fetchLoading && <LoadingPlaceholder />}
    </>
  );
};
