import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ProfilePlaceholder } from 'components';
import { useLocationState } from 'Location';
import { AlarmTypes, useNotificationState } from 'Notifications';
import { splitName } from 'utils';

import { caregiverStatusMap } from './status.constant';
import { StaffMember, StatusTypes } from './types';
import { formatLocation } from './utils';

import style from './CaregiverCard.module.css';

export const CaregiverCard: React.FunctionComponent<{
  caregiver: StaffMember;
  link: string;
}> = ({ link, caregiver }) => {
  const { id, name, status, role, location, photoUrl } = caregiver;
  const { firstName, lastName, initial } = splitName(name);
  const type = caregiverStatusMap[status];
  const { notifications } = useNotificationState();

  const isHighlighted = notifications.some(
    ({ type: notificationType, personOfInterest }) =>
      notificationType === AlarmTypes.CAREGIVER_ALARM &&
      personOfInterest?.id === id
  );

  const { selectedWardDetails } = useLocationState();

  return (
    <Link
      to={link}
      className={classnames(style.card, {
        [style.highlighted]: isHighlighted
      })}
    >
      <ProfilePlaceholder
        size={90}
        name={name}
        type={type}
        photoUrl={photoUrl}
      />
      <span className={style.name}>
        <span>{`${firstName} ${initial}`}</span>
        <span>{lastName}</span>
      </span>
      <span className={style.role}>{role}</span>
      {status !== StatusTypes.OFF_DUTY &&
        selectedWardDetails?.services.rtls.features.rtlsStaff.isEnabled && (
          <span className={style.location}>
            {location ? formatLocation(location) : 'Unknown'}
          </span>
        )}
    </Link>
  );
};
