import moment from 'moment';
import React, { useEffect } from 'react';
import useForm from 'react-hook-form';

import { useLocationState } from 'Location';
import { AddReadingForm, ReadingInput } from 'Residents';

interface BathReadingFormData {
  timeOfBath: string;
}

export const LastBathReadingForm: React.FunctionComponent<{
  isOpen: boolean;
  onSave: (formData: any) => void;
  onCancel: () => void;
  loading: boolean;
}> = ({ isOpen, onSave, onCancel, loading, children }) => {
  const {
    facility: { timezone }
  } = useLocationState();
  const { register, setValue } = useForm<BathReadingFormData>({
    mode: 'onBlur',
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const dateForFacility = timezone ? moment.tz(timezone) : moment();
    setValue('timeOfBath', dateForFacility.format('HH:mm'));
    const timestamp = dateForFacility.toISOString(true);

    onSave({
      timestamp,
      type: 'BATHING'
    });
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const dateForFacility = timezone ? moment.tz(timezone) : moment();
        setValue('timeOfBath', dateForFacility.format('HH:mm'));
      }, 700);
    }
  }, [setValue, isOpen, timezone]);

  return (
    <AddReadingForm
      isOpen={isOpen}
      onSave={handleSubmit}
      onCancel={onCancel}
      title="Add Bath Readings"
      savingDisabled={loading}
    >
      <ReadingInput
        name="timeOfBath"
        label="time of bath"
        register={register}
        hasError={false}
        unit="(hh:mm)"
        readonly={true}
        type="text"
      />
      {children}
    </AddReadingForm>
  );
};
