import { CaregiverSchedule } from 'Caregivers';
import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

export const getWardAssignments = ({
  workingDay,
  wardId,
  caregiverId
}: {
  workingDay: string;
  wardId?: string;
  caregiverId?: string;
}) => async () => {
  const queryParams = new URLSearchParams({
    workingDay,
    size: MAX_PAGE_SIZE.toString()
  });
  if (caregiverId) {
    queryParams.set('caregiverId', caregiverId);
  }
  if (wardId) {
    queryParams.set('wardId', wardId);
  }

  const response = await axiosWrapper<{ items: CaregiverSchedule[] }>(
    `/ward-assignments?${queryParams}`
  );

  return response.data.items;
};
