import React from 'react';

import { useAuthState } from 'Auth';
import { RoundEditButton, WithPermissions } from 'components';
import { useLabelState } from 'Location';

import style from './ResidentListHeader.module.css';

export const ResidentListHeader: React.FunctionComponent<{
  onEdit: () => void;
  caregiverId: string;
}> = ({ onEdit, caregiverId }) => {
  const label = useLabelState();
  const { loggedUser } = useAuthState();

  const title = `${label}s`;

  return (
    <header className={style.header}>
      <title className={style.title}>{title}</title>
      <WithPermissions
        action="caregiver-dashboard:manageResidents"
        data={{ loggedUser, caregiverId }}
        yes={<RoundEditButton onClick={onEdit} />}
      />
    </header>
  );
};
