import classnames from 'classnames';
import React, { useMemo } from 'react';

import { useAuthState } from 'Auth';
import { ProfilePlaceholder, StatusToggle, WithPermissions } from 'components';
import { useLocationState } from 'Location';
import { AlarmTypes, useNotificationState } from 'Notifications';
import { getChangedStatus } from 'Schedule/actions/getChangedStatus.action';
import { formatName } from 'utils';

import { caregiverStatusMap } from './status.constant';
import { StaffMember, StatusType, StatusTypes } from './types';
import { formatLocation } from './utils';

import style from './StaffListEntry.module.css';

export const StaffListEntry: React.FunctionComponent<{
  staffMember: StaffMember;
  isSelected: boolean;
  onSelect: (value: string | undefined) => void;
  onStatusChange: (caregiverId: string, newStatus: StatusType) => void;
}> = ({
  staffMember: { id, name, role, status, location, photoUrl },
  isSelected,
  onSelect,
  onStatusChange
}) => {
  const { loggedUser } = useAuthState();
  const { notifications } = useNotificationState();
  const { selectedWardDetails } = useLocationState();
  const formattedName = useMemo(() => formatName(name), [name]);

  const type = caregiverStatusMap[status];

  const isHighlighted = notifications.some(
    ({ type: notificationType, personOfInterest }) =>
      notificationType === AlarmTypes.CAREGIVER_ALARM &&
      personOfInterest?.id === id
  );

  const onClick = (evt: any) => {
    if (evt.target && evt.target.className.includes('StatusToggle')) {
      return; // changing status, don't select
    }
    onSelect(id);
  };

  const handleStatusToggle = (currentStatus: StatusType) => {
    const newStatus = getChangedStatus(currentStatus);
    onStatusChange(id, newStatus);
  };

  return (
    <li
      className={classnames(style.entry, {
        [style.selected]: isSelected,
        [style.highlighted]: isHighlighted
      })}
      onClick={onClick}
    >
      <ProfilePlaceholder name={name} type={type} photoUrl={photoUrl} />
      <div className={style.content}>
        <div className={style.actions}>
          <span className={style.name}>{formattedName}</span>
          <WithPermissions
            action="caregiver-dashboard:changeStatus"
            data={{ caregiverId: id, loggedUser }}
            yes={
              <StatusToggle
                id={id}
                status={status}
                onStatusChange={handleStatusToggle}
              />
            }
          />
        </div>
        <div className={style.details}>
          <span className={style.role}>{role}</span>
          {status !== StatusTypes.OFF_DUTY &&
            selectedWardDetails?.services.rtls.features.rtlsStaff.isEnabled && (
              <span className={style.location}>
                {location ? formatLocation(location) : 'Unknown'}
              </span>
            )}
        </div>
      </div>
    </li>
  );
};
