import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  CaregiverFetchDropdown,
  DismissibleError,
  FetchError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  ResidentFetchDropdown,
  RoomFetchDropdown,
  Select,
  StatusDropdown,
  WardFetchDropdown
} from 'components';
import { residentLabels } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { Device, DeviceError, editDevice, Facility } from 'Settings';
import {
  availableDeviceTypes as deviceTypes,
  getFormValidationErrors,
  isAssetType,
  isCaregiverType,
  isGateway,
  isResidentType,
  isRoomType
} from 'utils';

import { createDeviceDTO } from './createDeviceDTO';

import { FieldName } from 'react-hook-form/dist/types';
import stylesForm from 'styles/form.module.css';
import styles from './Devices.module.css';

interface EditDeviceFormData {
  name: string;
  type: string;
  mac: string;
  isActive: boolean;
  room: { id: string; number: string } | null;
  ward: { id: string; name: string } | null;
  caregiver: { id: string; name: string } | null;
  resident: { id: string; name: string } | null;
  rssiTriggerLevel: string;
  description: string;
  uniqueIdentifier: string;
}

export const EditDeviceForm: React.FunctionComponent<{
  toggle: () => void;
  device: Device;
  facility: Facility;
  onModifiedDevice: (device: Device) => void;
  hasDeviceAccess: boolean;
}> = ({
  toggle,
  device,
  facility,
  onModifiedDevice,
  hasDeviceAccess = false
}) => {
  const [modifiedDevice, setModifiedDevice] = useState<Device>(device);
  const initialValues: any = {
    name: device.name,
    mac: device.mac,
    isActive: device.isActive,
    type: device.type,
    rssiTriggerLevel: device.rssiTriggerLevel,
    description: device.description,
    uniqueIdentifier: device.uniqueIdentifier
  };

  const processValues = (valuesToBeProcessed: any) => {
    if (device.caregiver) {
      return {
        ...valuesToBeProcessed,
        caregiver: device.caregiver
      };
    }
    if (device.resident) {
      return {
        ...valuesToBeProcessed,
        resident: device.resident
      };
    }
    if (device.room) {
      const { id, number: roomNumber } = device.room;
      valuesToBeProcessed = {
        ...valuesToBeProcessed,
        room: { id, number: roomNumber }
      };
    }
    if (device.room && device.room.ward) {
      const { ward } = device.room;
      valuesToBeProcessed = {
        ...valuesToBeProcessed,
        ward
      };
    }

    return valuesToBeProcessed;
  };

  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<EditDeviceFormData>({
    mode: 'onBlur',
    defaultValues: processValues(initialValues),
    submitFocusError: false
  });

  const { setAction, loading: deviceLoading, error: deviceError } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(deviceError, deviceLoading);

  const [requirement, setRequirement] = useState({
    isRoomRequired: isRoomType(device.type),
    isCaregiverRequired: isCaregiverType(device.type),
    isResidentRequired: isResidentType(device.type, facility.type),
    isDescriptionRequired: isAssetType(device.type),
    isGateway: isGateway(device.type)
  });

  const [caregiverStatus, setCaregiverStatus] = useState({
    loading: true,
    error: false
  });

  const [wardStatus, setWardStatus] = useState({
    loading: true,
    error: false
  });

  const [roomStatus, setRoomStatus] = useState({
    loading: true,
    error: false
  });

  const [residentStatus, setResidentStatus] = useState({
    loading: true,
    error: false
  });

  const onWardChange = (
    name: FieldName<EditDeviceFormData>,
    selected: string
  ) => {
    setValue(name, selected);
    setValue('room', null);
  };

  const onTypeChange = (
    name: FieldName<EditDeviceFormData>,
    selectedType: string
  ) => {
    setValue(name, selectedType, true);
    if (selectedType) {
      setRequirement({
        isCaregiverRequired: isCaregiverType(selectedType),
        isResidentRequired: isResidentType(selectedType, facility.type),
        isRoomRequired: isRoomType(selectedType),
        isDescriptionRequired: isAssetType(selectedType),
        isGateway: isGateway(selectedType)
      });
    }
  };

  const saveDevice = (deviceFormData: any) => {
    setAction(editDevice(deviceFormData));
    setModifiedDevice(deviceFormData);
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();

    if (isValid) {
      const deviceData = {
        ...getValues(),
        id: device.id
      };
      const deviceDTO = createDeviceDTO(deviceData, facility.id, requirement);

      saveDevice(deviceDTO);
    }
  };

  useEffect(() => {
    if (!submitted || deviceLoading || deviceError) {
      return undefined;
    }
    onModifiedDevice(modifiedDevice);
    toggle();
  }, [
    submitted,
    deviceLoading,
    deviceError,
    onModifiedDevice,
    modifiedDevice,
    toggle
  ]);

  const typeOptions = Object.entries(deviceTypes(facility.type)).map(
    ([key, value]) => ({
      value: key,
      label: value
    })
  );

  const fetchError =
    roomStatus.error ||
    caregiverStatus.error ||
    residentStatus.error ||
    wardStatus.error;

  const fetchLoading =
    roomStatus.loading ||
    caregiverStatus.loading ||
    residentStatus.loading ||
    wardStatus.loading;

  const rowDisplay = fetchLoading || !getValues().type ? 'none' : 'flex';
  const formDisplay = fetchLoading ? 'none' : 'flex';
  const formRowStyle =
    hasDeviceAccess && !fetchLoading
      ? { display: 'flex' }
      : { display: 'none' };

  const hasRoomWarning = () => {
    const { type, ward, room } = getValues();
    if (type && !isRoomType(type)) {
      return false;
    }
    return ward && !room;
  };

  const validationErrors = getFormValidationErrors(errors);

  const handleInputChange = () => {
    if (deviceError) {
      dismissError();
    }
  };

  return (
    <>
      <FormHeader
        title="Edit Device Details"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={deviceLoading}
        visible={!fetchError}
      />
      <>
        {fetchError ? (
          <FetchError error={fetchError} closable={true} onClose={toggle} />
        ) : (
          <>
            {fetchLoading && <LoadingPlaceholder />}
            <form onSubmit={handleSubmit}>
              <div
                className={stylesForm.formRow}
                style={{ display: formDisplay }}
              >
                <div className={stylesForm.inputGroupQuarter}>
                  <Input
                    name="mac"
                    label="Device ID"
                    register={register}
                    validationRules={{
                      required: {
                        value: true,
                        message: 'Device ID is required.'
                      }
                    }}
                    hasError={!!errors.mac}
                    testId="input-mac"
                  />
                </div>
                <div className={stylesForm.inputGroupQuarter}>
                  <Input
                    name="name"
                    label="Device Name"
                    register={register}
                    validationRules={{
                      required: {
                        value: true,
                        message: 'Device Name is required.'
                      }
                    }}
                    hasError={
                      Boolean(errors.name) ||
                      Boolean(
                        isErrorShowing &&
                          deviceError &&
                          (deviceError as DeviceError).code ===
                            'device.name.unique'
                      )
                    }
                    onChange={handleInputChange}
                    testId="input-name"
                  />
                </div>
                <div className={stylesForm.selectGroupQuarter}>
                  <Select
                    name="type"
                    label="Device Type"
                    options={typeOptions}
                    hasError={!!errors.type}
                    register={register}
                    onChange={onTypeChange}
                    value={watch('type')}
                    required={true}
                  />
                </div>
                <div className={stylesForm.selectGroupStatusSmall}>
                  <StatusDropdown
                    register={register}
                    onChange={setValue}
                    value={watch('isActive')}
                    hasError={!!errors.isActive}
                  />
                </div>
              </div>
              {hasDeviceAccess && (
                <div className={stylesForm.formRow} style={formRowStyle}>
                  <div className={stylesForm.inputGroupHalf}>
                    <Input
                      name="uniqueIdentifier"
                      label="Unique Identifier"
                      register={register}
                      validationRules={{
                        required: {
                          value: true,
                          message: 'Unique Identifier is required.'
                        },
                        pattern: {
                          value: /^([A-F0-9]{32})$/,
                          message:
                            'Unique Identifier can only contain uppercase letters A through F, digits and be 32 characters long.'
                        }
                      }}
                      hasError={!!errors.uniqueIdentifier}
                    />
                  </div>
                </div>
              )}
              <div
                className={stylesForm.formRow}
                style={{ display: rowDisplay }}
              >
                {requirement.isDescriptionRequired && (
                  <div className={stylesForm.inputGroup}>
                    <Input
                      name="description"
                      label="Description"
                      register={register}
                      hasError={!!errors.description}
                    />
                  </div>
                )}
                {requirement.isGateway && hasDeviceAccess && (
                  <div className={stylesForm.inputGroupQuarter}>
                    <Input
                      name="rssiTriggerLevel"
                      label="RSSI Threshold"
                      register={register}
                      validationRules={{
                        required: {
                          value: true,
                          message: 'RSSI Threshold is required.'
                        },
                        validate: (enter: number) =>
                          (enter >= -120 && enter <= 0) ||
                          'RSSI Threshold must be between -120 and 0.'
                      }}
                      hasError={!!errors.rssiTriggerLevel}
                    />
                  </div>
                )}
                <CaregiverFetchDropdown
                  name="caregiver"
                  value={watch('caregiver')}
                  onChange={setValue}
                  register={register}
                  hasError={!!errors.caregiver}
                  facilityId={facility.id}
                  changeStatus={setCaregiverStatus}
                  isVisible={requirement.isCaregiverRequired}
                />
                <WardFetchDropdown
                  name="ward"
                  value={watch('ward')}
                  onChange={onWardChange}
                  register={register}
                  hasError={!!errors.ward}
                  facilityId={facility.id}
                  changeStatus={setWardStatus}
                  isVisible={requirement.isRoomRequired}
                  cssClass={stylesForm.selectGroupQuarter}
                  defaultValue={processValues(initialValues).ward}
                />
                <RoomFetchDropdown
                  name="room"
                  value={watch('room')}
                  onChange={setValue}
                  register={register}
                  hasError={!!errors.room}
                  facilityId={facility.id}
                  changeStatus={setRoomStatus}
                  wardId={getValues().ward ? getValues().ward!.id : ''}
                  isVisible={requirement.isRoomRequired}
                  noOptionsMessage={
                    watch('ward') ? 'No rooms available' : 'Select a ward first'
                  }
                />
                <ResidentFetchDropdown
                  name="resident"
                  value={watch('resident')}
                  onChange={setValue}
                  register={register}
                  hasError={!!errors.resident}
                  facility={facility}
                  changeStatus={setResidentStatus}
                  isVisible={requirement.isResidentRequired}
                  label={residentLabels[facility.type]}
                  activeOnly={true}
                />
              </div>
              {validationErrors && (
                <div className={styles.errorsWrapper}>{validationErrors}</div>
              )}
              {hasRoomWarning() && !deviceError && (
                <h4 className={styles.wardMessage}>
                  Note: If you leave the room field empty, the device will be
                  unassigned.
                </h4>
              )}
              <DismissibleError
                name="Device"
                visible={isErrorShowing}
                error={deviceError}
                dismiss={dismissError}
              />
            </form>
          </>
        )}
      </>
    </>
  );
};
