import React, { FunctionComponent, useEffect, useState } from 'react';

import { StatusTypes } from 'Caregivers';
import { CaregiverProfileStatus, RoundEditButton } from 'components';

import { AdHocListItem } from './AdHocListItem';
import { IAdHocMemberListProps } from './types';

import styles from './AdHocMemberList.module.css';

export const AdHocMemberList: FunctionComponent<IAdHocMemberListProps> = ({
  members,
  onEditList,
  statusError,
  statusLoading,
  statuses
}) => {
  const [items, setItems] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (statusError || statusLoading) {
      return undefined;
    }

    const list = members.map(member => {
      const status: CaregiverProfileStatus =
        statuses[member.id] === StatusTypes.OFF_DUTY
          ? 'unavailable'
          : 'available';

      const item = {
        ...member,
        status
      };

      return (
        <AdHocListItem
          key={member.id}
          item={item}
          isSelected={false}
          showButtons={false}
        />
      );
    });

    setItems(list);
  }, [members, statuses, statusError, statusLoading]);

  return (
    <div className={styles.membersList}>
      <header className={styles.membersHeader}>
        <span className={styles.listTitle}>Participants</span>
        <RoundEditButton onClick={onEditList} />
      </header>
      <ul>{items}</ul>
    </div>
  );
};
