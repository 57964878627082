import classnames from 'classnames';
import React from 'react';

import { ReactComponent as Warning } from 'icons/warningNotification.svg';

import style from './VitalCard.module.css';

export const VitalCard: React.FunctionComponent<{
  title: string;
  reading?: string | number;
  timestamp?: string;
  hasWarning: boolean;
  unit?: string;
}> = ({
  title,
  reading = 'No Data',
  unit = '',
  timestamp = '',
  children,
  hasWarning
}) => {
  const noData = reading === 'No Data';

  return (
    <>
      <div className={style.header}>
        {children}
        <span className={style.title}>{title}</span>
      </div>
      <span
        className={classnames(style.reading, {
          [style.emptyReading]: noData
        })}
      >
        {reading}
        <span className={style.measurement}>{unit}</span>
      </span>
      {timestamp && <span className={style.timestamp}>{timestamp}</span>}
      {hasWarning && (
        <Warning className={style.warning} data-testid="warning-test" />
      )}
    </>
  );
};
