import { useLayoutEffect } from 'react';

export const useAddEventListener = (
  element: HTMLElement | null,
  event: keyof HTMLElementEventMap,
  listener: EventListener,
  separateCondition: boolean = true
) =>
  useLayoutEffect(() => {
    if (element && separateCondition) {
      (element! as HTMLElement).addEventListener(event, listener, {
        passive: true,
        capture: true
      });
    }

    return () => {
      if (element && separateCondition) {
        (element! as HTMLElement).removeEventListener(event, listener, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, separateCondition]);
