import React, { useEffect, useRef, useState } from 'react';

import styles from './Dropdown.module.css';

export const DropdownMenu: React.FunctionComponent<{
  Icon: any;
  testId?: string;
  dropdownArrow?: boolean;
}> = ({ Icon, testId = 'hd-btn', children, dropdownArrow }) => {
  const [visible, setVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  // on mount and unmount
  useEffect(() => {
    const handleClick = (evt: any) => {
      if (menuRef.current !== null && menuRef.current.contains(evt.target)) {
        return;
      }
      setVisible(false);
    };

    window.addEventListener('mousedown', handleClick, false);
    return () => {
      window.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  const toggleMenu = () => {
    setVisible(isVisible => !isVisible);
  };

  const menuClass = visible
    ? dropdownArrow
      ? `${styles.dropdownMenu} ${styles.menuVisible} ${styles.dropdownMenuWithArrow}`
      : `${styles.dropdownMenu} ${styles.menuVisible}`
    : styles.dropdownMenu;

  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, {
      isDropdownVisible: visible
    })
  );

  return (
    <div ref={menuRef} className={styles.dropdownContainer}>
      <div className={menuClass} id="DropdownMenu">
        {childrenWithProps}
      </div>
      <button
        className={styles.actionIconWrapper}
        onClick={toggleMenu}
        data-testid={testId}
      >
        <Icon />
      </button>
    </div>
  );
};
