import { History } from 'history';
import React, { useCallback, useEffect, useState } from 'react';

import { useAuthState } from 'Auth';
import {
  ChatContact,
  ChatRoom,
  ConversationTypes,
  generateGetChatsByUser,
  useMissedCallsState,
  useMissedMessagesState
} from 'Communication';
import {
  AccessMessage,
  FetchError,
  LoadingPlaceholder,
  WithPermissions
} from 'components';
import { useDidMount, useFetcher, useModal, useQueryParams } from 'hooks';
import { useLocationState } from 'Location';
import { ComUpdateTypes, useSocketState } from 'Socket';

import { ActivityInformation } from './ActivityInformation';
import { CaregiversList } from './Caregivers/CaregiversList';
import { ResidentDashChatWindow } from './ChatWindow/ResidentDashChatWindow';
import { EditResidentDetails } from './EditResidentDetails';
import { RelatedContactsList } from './RelatedContacts/RelatedContactsList';
import { ResidentProvider } from './resident.context';
import { ResidentsList } from './ResidentsList';
import { ResidentVitals } from './ResidentVitals';
import { ActivityInfoFeatures } from './types';
import { WithResidentDetails } from './WithResidentDetails';

import styles from './ResidentsDashboard.module.css';

export enum URLQueryValues {
  RESIDENT_KEY = 'resident',
  ROOM_KEY = 'room'
}

export type URLQueryValueType =
  | URLQueryValues.RESIDENT_KEY
  | URLQueryValues.ROOM_KEY;

export interface VitalsFeatures {
  prvBloodPressure: boolean;
  prvBodyTemperature: boolean;
  prvHeartRate: boolean;
  prvBodyWeight: boolean;
  prvBloodSugar: boolean;
  prvOxygenSaturation: boolean;
  prvPainLevel: boolean;
}

const vitalsInitial: VitalsFeatures = {
  prvBloodPressure: true,
  prvBodyTemperature: true,
  prvHeartRate: true,
  prvBodyWeight: true,
  prvBloodSugar: true,
  prvOxygenSaturation: true,
  prvPainLevel: true
};

const activityInfoInitial: ActivityInfoFeatures = {
  praiPatientLocation: true,
  praiSteps: true,
  praiSittingHours: true,
  praiSleepingHours: true,
  praiBath: true,
  praiStaffVisits: true,
  praiFluidIntake: true,
  praiFluidOutput: true,
  praiFoodIntake: true
};

export const ResidentsDashboard: React.FunctionComponent<{
  history: History;
}> = ({ history }) => {
  const [selectedContact, setSelectedContact] = useState<
    ChatContact | undefined
  >(undefined);
  const [currentChat, setCurrentChat] = useState<ChatRoom | undefined>(
    undefined
  );
  const [caregiverChatRooms, setCaregiverChatRooms] = useState<ChatRoom[]>([]);
  const [relatedChatRooms, setRelatedChatRooms] = useState<ChatRoom[]>([]);
  const [chatActivity, setChatActivity] = useState<boolean>(false);
  const [residentId, setResidentId] = useState<string | null>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [showVitals, setShowVitals] = useState<boolean>(true);
  const [vitalsToShow, setVitalsToShow] = useState<VitalsFeatures>(
    vitalsInitial
  );
  const [showActivityInfo, setShowActivityInfo] = useState<boolean>(true);
  const [activityToShow, setActivityToShow] = useState<ActivityInfoFeatures>(
    activityInfoInitial
  );

  const { isShowing, toggle } = useModal();
  const query = useQueryParams();
  const {
    filtersLoading,
    filtersError,
    facility: { id: facilityId },
    ward: wardId,
    selectedWardDetails
  } = useLocationState();
  const { loggedUser, loginId } = useAuthState();
  const { state: missedCalls } = useMissedCallsState();
  const { state: missedMessages } = useMissedMessagesState();
  const { comUpdate } = useSocketState();
  const isMounted = useDidMount();

  useEffect(() => {
    setResidentId(query.get(URLQueryValues.RESIDENT_KEY));
    setRoomId(query.get(URLQueryValues.ROOM_KEY));
  }, [query]);

  const getChatRoomsAction = useCallback(generateGetChatsByUser(loggedUser), [
    loggedUser?.id
  ]);

  const {
    data: chatRooms,
    loading: chatRoomsLoading,
    error: chatRoomsError,
    setRefetch
  } = useFetcher<ChatRoom[]>(getChatRoomsAction, []);

  useEffect(() => {
    if (
      !comUpdate ||
      !comUpdate.messageType ||
      (comUpdate.chatRoomType.toLowerCase() !== ConversationTypes.RELATED &&
        comUpdate.chatRoomType.toLowerCase() !== ConversationTypes.CAREGIVER &&
        comUpdate.messageType !== ComUpdateTypes.FILE_MESSAGE &&
        comUpdate.messageType !== ComUpdateTypes.TEXT_MESSAGE)
    ) {
      return undefined;
    }

    /**
     * @description if we receive a message on a chat that is not in the list
     * make a new chat
     */
    if (
      !caregiverChatRooms.find(chat => chat.id === comUpdate.chatRoomId) &&
      !relatedChatRooms.find(chat => chat.id === comUpdate.chatRoomId) &&
      !chatActivity
    ) {
      setChatActivity(true);

      setRefetch();
    }
  }, [
    caregiverChatRooms,
    chatActivity,
    comUpdate,
    currentChat,
    relatedChatRooms,
    setRefetch
  ]);

  useEffect(() => {
    if (
      (relatedChatRooms.length === 0 && caregiverChatRooms.length === 0) ||
      loginId === selectedContact?.loginId
    ) {
      return undefined;
    }

    let activeChat = caregiverChatRooms.find(chat =>
      chat.participants.includes(selectedContact?.loginId!)
    );

    if (!activeChat) {
      activeChat = relatedChatRooms.find(chat =>
        chat.participants.includes(selectedContact?.loginId!)
      );
    }

    setChatActivity(false);
    setCurrentChat(activeChat);
  }, [relatedChatRooms, caregiverChatRooms, selectedContact, loginId]);

  useEffect(() => {
    if (chatRoomsLoading || chatRoomsError) {
      return undefined;
    }

    const caregiverChats: ChatRoom[] = [];
    const relatedChats: ChatRoom[] = [];

    chatRooms.forEach(chat => {
      if (chat.chatRoomType.toLowerCase() === ConversationTypes.CAREGIVER) {
        caregiverChats.push(chat);
      }

      if (chat.chatRoomType.toLowerCase() === ConversationTypes.RELATED) {
        relatedChats.push(chat);
      }
    });

    setCaregiverChatRooms(caregiverChats);
    setRelatedChatRooms(relatedChats);
  }, [chatRoomsError, chatRoomsLoading, chatRooms]);

  const selectListItem = useCallback(
    (queryKey: URLQueryValueType, value: string | undefined) => {
      if (!value || value === residentId || value === roomId) {
        return;
      }
      setSelectedContact(undefined);

      const keyToRemove =
        queryKey === URLQueryValues.RESIDENT_KEY
          ? URLQueryValues.ROOM_KEY
          : URLQueryValues.RESIDENT_KEY;
      query.set(queryKey, value);
      query.delete(keyToRemove);
      history.push({
        search: `?${query}`
      });
    },
    [history, query, residentId, roomId]
  );

  const removeSelection = useCallback(() => {
    if (residentId) {
      query.delete(URLQueryValues.RESIDENT_KEY);

      history.push({
        search: `?${query}`
      });
    }
  }, [history, query, residentId]);

  useEffect(() => {
    if (isMounted) {
      query.delete(URLQueryValues.RESIDENT_KEY);
      query.delete(URLQueryValues.ROOM_KEY);
      history.push({
        search: `?${query}`
      });
    }

    setSelectedContact(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardId, facilityId]);

  useEffect(() => {
    if (!wardId && residentId) {
      removeSelection();
    }
  }, [wardId, residentId, removeSelection]);

  const handleSelectContact = (contact: ChatContact) => {
    if (
      !selectedWardDetails?.services.secureCommunication.features.scMessaging
        .isEnabled &&
      contact.chatRoomType === ConversationTypes.CAREGIVER
    ) {
      return setSelectedContact(undefined);
    }
    if (
      !selectedWardDetails?.services.secureCommunication.features
        .scRelatedContact.isEnabled &&
      contact.chatRoomType === ConversationTypes.RELATED
    ) {
      return setSelectedContact(undefined);
    }
    return setSelectedContact(contact);
  };

  const [showChat, setShowChat] = useState<boolean>(false);

  useEffect(() => {
    if (
      !selectedWardDetails?.services.secureCommunication.features.scMessaging
        .isEnabled &&
      (!selectedWardDetails?.services.secureCommunication.features
        .scRelatedContact.isEnabled ||
        !selectedWardDetails?.services.patientResidentManagement.features
          .prmRelatedContact.isEnabled)
    ) {
      setShowChat(false);
    } else {
      setShowChat(true);
    }
  }, [selectedContact, selectedWardDetails]);

  useEffect(() => {
    if (!selectedWardDetails) {
      return undefined;
    }

    const vitalsFeatures = Object.values(
      selectedWardDetails?.services.patientResidentVitals.features
    );
    let vitalsObj = vitalsInitial;

    vitalsFeatures.forEach(feat => {
      vitalsObj[feat.id] = feat.isEnabled;
    });

    setShowVitals(Object.values(vitalsObj).some(value => value === true));
    setVitalsToShow(vitalsObj);
  }, [selectedWardDetails]);

  useEffect(() => {
    if (!selectedWardDetails) {
      return undefined;
    }

    const activityInfoFeatures = Object.values(
      selectedWardDetails.services.patientResidentActivityInformation.features
    );
    let activityInfoObj = activityInfoInitial;

    activityInfoFeatures.forEach(feat => {
      activityInfoObj[feat.id] = feat.isEnabled;
    });

    const activityInfo = Object.values(activityInfoObj).some(
      activity => activity === true
    );

    setShowActivityInfo(activityInfo);
    setActivityToShow(activityInfoObj);
  }, [selectedWardDetails]);

  return (
    <ResidentProvider>
      <div className={styles.dashboardContainer}>
        {wardId && !filtersLoading && !filtersError && (
          <>
            {isMounted && (
              <ResidentsList
                selectedResidentId={residentId}
                selectedRoomId={roomId}
                onSelectListItem={selectListItem}
              />
            )}

            <div className={styles.main}>
              <div className={styles.mainResidentContent}>
                <WithResidentDetails
                  residentId={residentId}
                  onEditClick={toggle}
                />
                <EditResidentDetails
                  wardId={wardId}
                  toggle={toggle}
                  isOpen={isShowing}
                  history={history}
                />
                {showVitals && (
                  <ResidentVitals
                    facilityId={facilityId}
                    residentId={residentId}
                    vitals={vitalsToShow}
                  />
                )}
                {showActivityInfo && (
                  <ActivityInformation
                    facilityId={facilityId}
                    residentId={residentId}
                    activityInfo={activityToShow}
                  />
                )}
              </div>
              <div className={styles.residentInteractionsContent}>
                <CaregiversList
                  vacantRoomId={roomId}
                  onCaregiverSelect={handleSelectContact}
                  selectedContact={selectedContact}
                  chatRooms={caregiverChatRooms}
                  fetching={chatRoomsLoading}
                  externalError={chatRoomsError}
                  missedMessages={missedMessages}
                  missedCalls={missedCalls}
                />
                {selectedWardDetails?.services.patientResidentManagement
                  .features.prmRelatedContact.isEnabled ? (
                  <RelatedContactsList
                    onContactSelect={handleSelectContact}
                    selectedContact={selectedContact}
                    relatedChatRooms={relatedChatRooms}
                    fetching={chatRoomsLoading}
                    externalError={chatRoomsError}
                    missedMessages={missedMessages}
                    missedCalls={missedCalls}
                  />
                ) : (
                  showChat && (
                    <div>
                      <WithPermissions
                        action="communication:all"
                        yes={
                          <ResidentDashChatWindow
                            chatContact={selectedContact}
                            currentChat={currentChat}
                            chatFetching={chatRoomsLoading}
                            chatError={chatRoomsError}
                          />
                        }
                      />
                    </div>
                  )
                )}
              </div>
              {showChat &&
                selectedWardDetails?.services.patientResidentManagement.features
                  .prmRelatedContact.isEnabled && (
                  <div className={styles.messageContainer}>
                    <WithPermissions
                      action="communication:all"
                      yes={
                        <ResidentDashChatWindow
                          chatContact={selectedContact}
                          currentChat={currentChat}
                          chatFetching={chatRoomsLoading}
                          chatError={chatRoomsError}
                        />
                      }
                    />
                  </div>
                )}
            </div>
          </>
        )}
        {!filtersLoading && <AccessMessage />}
        {filtersLoading && !filtersError && <LoadingPlaceholder />}
        {!filtersLoading && filtersError && <FetchError />}
      </div>
    </ResidentProvider>
  );
};
