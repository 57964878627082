import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { FieldName } from 'react-hook-form/dist/types';

import {
  CustomMessage,
  DatePicker,
  DeviceFetchDropdown,
  DismissibleError,
  FetchError,
  FormHeader,
  ImageUpload,
  Input,
  LoadingPlaceholder,
  RoomFetchDropdown,
  Select,
  StatusDropdown,
  WardFetchDropdown
} from 'components';
import { genders, maritalStatuses, residentLabels, states } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { Facility } from 'Settings';
import { EMAIL_PATTERN, VALIDATION_MESSAGE } from 'Settings/constants';
import { editUserAvatar } from 'UserProfile';
import {
  checkDate,
  getAvailableResidentDevices,
  getFormValidationErrors
} from 'utils';

import { createResident as createResidentAction } from '../actions';
import { Resident } from '../types/resident.type';
import { createResidentDTO } from './createResidentDTO';

import { ReactComponent as ThLogo } from 'icons/TH-logo.svg';

import stylesForm from 'styles/form.module.css';

interface AddResidentFormData {
  address: string;
  city: string;
  name: string;
  number: string;
  state: string;
  isActive: boolean;
  zip: string;
  email: string;
  homePhoneNumber: string;
  cellPhoneNumber: string;
  badge: { id: string; name: string } | null;
  gender: string;
  birthday: Date | undefined;
  maritalStatus: string;
  room: { id: string; number: string } | null;
  ward: { id: string; name: string } | null;
}

const handleBlur = (
  fieldName: string,
  trigger: (payload: any[]) => void
) => () => {
  trigger([{ name: fieldName }]);
};

export const AddResidentForm: React.FunctionComponent<{
  toggle: () => void;
  facility: Facility;
  onResidentCreate: (id: string) => void;
}> = ({ toggle, facility, onResidentCreate }) => {
  const [avatarURL, setAvatarURL] = useState<string | undefined>(undefined);

  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<AddResidentFormData>({
    mode: 'onBlur',
    defaultValues: {
      isActive: true
    },
    submitFocusError: false
  });

  const { data: createdResidentId, setAction, loading, error } = usePoster(
    residentLabels[facility.type]
  );

  const [roomStatus, setRoomStatus] = useState({
    loading: true,
    error: false
  });

  const [wardStatus, setWardStatus] = useState({
    loading: true,
    error: false
  });

  const [braceletStatus, setBraceletStatus] = useState({
    loading: true,
    error: false
  });

  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, loading);

  const createResident = (resident: Resident) => {
    setAction(createResidentAction(facility.id, resident));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const residentData = createResidentDTO(getValues());
      residentData.photoUrl = avatarURL;
      createResident({ ...residentData, facilityId: facility.id });
    }
  };

  const handleAvatarChange = async (image: File | Blob, filename: string) => {
    const imageURL = await editUserAvatar(image, filename);
    setAvatarURL(imageURL);
  };

  useEffect(() => {
    if (submitted && !loading && !error) {
      toggle();
      if (createdResidentId) {
        onResidentCreate(createdResidentId);
      }
    }
  }, [submitted, loading, error, toggle, createdResidentId, onResidentCreate]);

  const onWardChange = (
    name: FieldName<AddResidentFormData>,
    selected: string
  ) => {
    setValue(name, selected);
    setValue('room', null);
  };

  const genderOptions = genders.map(gender => ({
    value: gender,
    label: gender
  }));

  const maritalOptions = maritalStatuses.map(maritalStatus => ({
    value: maritalStatus,
    label: maritalStatus
  }));

  const stateOptions = states.map(state => ({
    value: state.value,
    label: state.value
  }));

  const generalLoading =
    loading ||
    roomStatus.loading ||
    wardStatus.loading ||
    braceletStatus.loading;

  const formDisplay = generalLoading ? 'none' : 'block';

  const generalError =
    roomStatus.error || wardStatus.error || braceletStatus.error;

  const hasRoomWarning = () => {
    const { ward, room } = getValues();
    return ward && !room;
  };

  const changeDate = useCallback(
    (value: Date | undefined) => {
      setValue('birthday', value, true);
    },
    [setValue]
  );

  const title = `Add ${residentLabels[facility.type]}`;
  const nameLabel = `${residentLabels[facility.type]} Name`;
  const numberLabel = `${residentLabels[facility.type]} Number`;
  const message = `Note: If you leave the room field empty, the ${residentLabels[
    facility.type
  ].toLowerCase()} won't be assigned to a room.`;
  const validationErrors = getFormValidationErrors(errors);

  return (
    <>
      <FormHeader
        title={title}
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={generalLoading}
        visible={!generalError}
      />
      {generalError ? (
        <FetchError error={generalError} closable={true} onClose={toggle} />
      ) : (
        <>
          {generalLoading && <LoadingPlaceholder />}
          <form onSubmit={handleSubmit} style={{ display: formDisplay }}>
            <div className={stylesForm.formRow}>
              <div
                className={classnames(stylesForm.inputGroupHalf, {
                  [stylesForm.inputGroupWithAvatar]: true
                })}
              >
                <ImageUpload
                  onImageChange={handleAvatarChange}
                  photoUrl={avatarURL}
                  profileType={watch('gender') && watch('gender').toLowerCase()}
                />
                <Input
                  name="name"
                  label={nameLabel}
                  register={register}
                  validationRules={{ required: true }}
                  hasError={!!errors.name}
                />
              </div>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="number"
                  label={numberLabel}
                  register={register}
                  validationRules={{ required: true }}
                  hasError={!!errors.number}
                />
              </div>
              <div className={stylesForm.selectGroupStatusSmall}>
                <StatusDropdown
                  register={register}
                  onChange={setValue}
                  value={watch('isActive')}
                  hasError={!!errors.isActive}
                />
              </div>
            </div>
            <div className={stylesForm.formRow}>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="address"
                  label="Address"
                  register={register}
                  hasError={!!errors.address}
                />
              </div>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="city"
                  label="City"
                  register={register}
                  hasError={!!errors.city}
                />
              </div>
              <div className={stylesForm.selectGroupQuarter}>
                <Select
                  name="state"
                  label="State"
                  options={stateOptions}
                  hasError={!!errors.state}
                  register={register}
                  onChange={setValue}
                  value={watch('state')}
                />
              </div>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="zip"
                  label="Zip"
                  register={register}
                  hasError={!!errors.zip}
                />
              </div>
            </div>
            <div className={stylesForm.formRow}>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="email"
                  label="E-mail Address"
                  register={register}
                  hasError={!!errors.email}
                  validationRules={{
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: VALIDATION_MESSAGE
                    }
                  }}
                />
              </div>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="homePhoneNumber"
                  label="Home Phone Number"
                  register={register}
                  hasError={!!errors.homePhoneNumber}
                />
              </div>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="cellPhoneNumber"
                  label="Cell Phone Number"
                  register={register}
                  hasError={!!errors.cellPhoneNumber}
                />
              </div>
              <DeviceFetchDropdown
                name="badge"
                value={watch('badge')}
                onChange={setValue}
                register={register}
                hasError={!!errors.badge}
                facility={facility}
                changeStatus={setBraceletStatus}
                label="Device"
                filterFunction={getAvailableResidentDevices}
              >
                <ThLogo className={stylesForm.thLogo} />
              </DeviceFetchDropdown>
            </div>
            <div className={stylesForm.formRow}>
              <div className={stylesForm.selectGroupQuarter}>
                <Select
                  name="gender"
                  label="Gender"
                  options={genderOptions}
                  hasError={!!errors.gender}
                  register={register}
                  onChange={setValue}
                  value={watch('gender')}
                  required={true}
                  onBlur={handleBlur('gender', triggerValidation)}
                />
              </div>
              <div className={stylesForm.selectGroupQuarter}>
                <DatePicker
                  InputComponent={React.forwardRef((props, ref: any) => (
                    <Input
                      name="birthday"
                      label="Date of Birth"
                      register={register}
                      hasError={!!errors.birthday}
                      validationRules={{ required: true, validate: checkDate }}
                      {...props}
                    />
                  ))}
                  onChange={changeDate}
                />
              </div>
              <div className={stylesForm.selectGroupQuarter}>
                <Select
                  name="maritalStatus"
                  label="Marital Status"
                  options={maritalOptions}
                  hasError={!!errors.maritalStatus}
                  register={register}
                  onChange={setValue}
                  value={watch('maritalStatus')}
                  required={true}
                  onBlur={handleBlur('maritalStatus', triggerValidation)}
                />
              </div>
              <WardFetchDropdown
                name="ward"
                value={watch('ward')}
                onChange={onWardChange}
                register={register}
                hasError={!!errors.ward}
                facilityId={facility.id}
                changeStatus={setWardStatus}
                cssClass={stylesForm.selectGroupEighth}
              />
              <RoomFetchDropdown
                name="room"
                value={watch('room')}
                onChange={setValue}
                register={register}
                hasError={!!errors.room}
                facilityId={facility.id}
                changeStatus={setRoomStatus}
                wardId={getValues().ward ? getValues().ward!.id : ''}
                noOptionsMessage={
                  watch('ward') ? 'No rooms available' : 'Select a ward first'
                }
                cssClass={stylesForm.selectGroupEighth}
              />
            </div>
            {validationErrors && (
              <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
            )}
            {hasRoomWarning() && <CustomMessage message={message} />}
            <DismissibleError
              name={residentLabels[facility.type]}
              visible={isErrorShowing}
              error={error}
              dismiss={dismissError}
            />
          </form>
        </>
      )}
    </>
  );
};
