import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { ResidentListResponse } from '../types/resident.type';

export const getResidentsByFacility = (facilityId: string) => async () => {
  const response = await axiosWrapper<ResidentListResponse>(
    `/facilities/${facilityId}/residents?size=${MAX_PAGE_SIZE}`
  );
  return response.data;
};

export const getResidentsByQuery = (
  facilityId: string,
  searchQuery: string,
  page: string
) => async () => {
  const response = await axiosWrapper<ResidentListResponse>(
    `/facilities/${facilityId}/residents?residentSearchQuery=${searchQuery}&page=${page}`
  );
  return response.data;
};
