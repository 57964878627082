import React from 'react';

import { ReactComponent as Staff } from 'icons/caregivers.svg';
import { StaffVisit } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './StaffVisitsCard.module.css';

export const StaffVisitsCard: React.FunctionComponent<{
  staffVisits: StaffVisit | undefined;
  onClick: () => void;
}> = ({ staffVisits, onClick }) => {
  const { value } = staffVisits || {};
  const reading = value && value.numberOfVisits;

  return (
    <div className={style.staffVisits} onClick={onClick}>
      <ActivityInfoCard
        reading={reading}
        unit="staff visits"
        type="staffVisits"
      >
        <Staff />
      </ActivityInfoCard>
    </div>
  );
};
