import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelList,
  Table
} from 'components';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';
import { getHeight } from 'utils';

import {
  getFacilityByQueryFromIdn,
  getIdnDetails,
  getPaginatedFacilities
} from '../actions';
import {
  DEFAULT_PAGE,
  DEFAULT_SEARCH_TERM,
  FACILITY_LIST_PLACEHOLDER,
  FACILITY_SEARCH_INPUT_NAME,
  FACILITY_TITLE,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH
} from '../constants/index';
import { Facility } from '../types/facility.type';

import { AddFacilityForm } from './AddFacilityForm';
import { FacilityTableRow } from './FacilityTableRow';

import stylesModal from 'components/Modal/Modal.module.css';
import { Idn } from 'Settings/types';

const FacilityTableColumns: Column[] = [
  { name: 'Facility Name', align: 'left', cols: 3 },
  { name: 'Full Address', align: 'left', cols: 4 },
  { name: 'Residents', align: 'center', cols: 1.3 },
  { name: 'Status', align: 'center', cols: 1 }
];

export const FacilityList: React.FunctionComponent<{
  selectedIdnId: { id: string };
  onIdnChange: (idn: Idn) => void;
}> = ({ selectedIdnId, onIdnChange }) => {
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [
    searchedFacilitiesFromIdnPage,
    setSearchedFacilitiesFromIdnPage
  ] = useState(DEFAULT_PAGE);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);
  const [selectedFacility, setSelectedFacility] = useState('');

  const getFacilitiesMemo = useMemo(
    () => getPaginatedFacilities(page, selectedIdnId.id),
    [page, selectedIdnId]
  );

  const getIdnDetailsMemo = useMemo(() => getIdnDetails(selectedIdnId.id), [
    selectedIdnId
  ]);

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getFacilityByQueryFromIdn(
        searchTerm,
        selectedIdnId.id,
        searchedFacilitiesFromIdnPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedFacilitiesFromIdnPage]);

  const {
    data: { items: facilityList, nextPage },
    error: fetchError,
    loading: fetchLoading,
    setRefetch
  } = useFetcher<{ items: Facility[]; nextPage?: string }>(getFacilitiesMemo, {
    items: []
  });

  const {
    loading: idnLoading,
    error: idnError,
    data: idn,
    setRefetch: refetchIdn
  } = useFetcher<Idn>(getIdnDetailsMemo, {} as Idn);

  const {
    data: {
      items: searchedFacilitiesFromIdn,
      nextPage: nextPageForSearchedFacilitiesFromIdn
    },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{ items: Facility[]; nextPage?: string }>(memoSearchAction, {
    items: []
  });

  useEffect(() => {
    if (facilityList.length !== 0) {
      setFacilities(prevState => [...prevState, ...facilityList]);
    }
  }, [facilityList]);

  useEffect(() => {
    onIdnChange(idn);
  }, [idn, onIdnChange]);

  useEffect(() => {
    setFacilities([]);
    setPage(DEFAULT_PAGE);
  }, [selectedIdnId]);

  useEffect(() => {
    if (searchError) {
      return setFacilities([]);
    }
    if (searchedFacilitiesFromIdnPage > DEFAULT_PAGE) {
      setFacilities(prevState => [...prevState, ...searchedFacilitiesFromIdn]);
    } else {
      setFacilities(searchedFacilitiesFromIdn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedFacilitiesFromIdn, searchError]);

  const { isShowing, toggle } = useModal();

  const modalClosed = () => {
    setFacilities([]);
    refetchIdn();
    toggle();
    if (page === DEFAULT_PAGE) {
      setRefetch();
    } else {
      setPage(DEFAULT_PAGE);
    }
  };

  const loading = fetchLoading || idnLoading;
  const error = fetchError || idnError;

  const onAdd = () => {
    toggle();
  };

  const scrollAction = () => {
    if (
      !loading &&
      isFetching &&
      facilityList.length &&
      nextPage &&
      !isSearching
    ) {
      setPage(nextPage);
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedFacilitiesFromIdn.length &&
      nextPageForSearchedFacilitiesFromIdn &&
      isSearching
    ) {
      setSearchedFacilitiesFromIdnPage(nextPageForSearchedFacilitiesFromIdn);
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedFacilitiesFromIdnPage(DEFAULT_PAGE);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setFacilities([]);
      setPage(DEFAULT_PAGE);
      setRefetch();
    }
  };

  const render = (rowValues: any) => (
    <FacilityTableRow
      key={rowValues.id}
      values={rowValues}
      isSelected={rowValues.id === selectedFacility}
      selectFacility={setSelectedFacility}
    />
  );

  const isDataAvailable = facilities.length > 0;

  return (
    <PanelList
      title={FACILITY_TITLE}
      onAdd={onAdd}
      hasError={!!error}
      onSearch={handleSearch}
      resetHeaderKey={selectedIdnId.id}
      searchInputName={FACILITY_SEARCH_INPUT_NAME}
    >
      {facilityList && !error && (!loading || isDataAvailable) && (
        <Table<Facility>
          columns={FacilityTableColumns}
          rows={facilities}
          placeholder={FACILITY_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}

      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentRightAlign}>
            <div
              className={stylesModal.facilityModal}
              style={{ marginTop: getHeight('Facilities') }}
            >
              <AddFacilityForm
                toggle={modalClosed}
                selectedIdn={idn}
                onFacilityCreate={setSelectedFacility}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PanelList>
  );
};
