import { useAuthState } from 'Auth/auth.context';
import { getCommunicationCounts } from 'Communication/actions';
import { ICommunicationCounts } from 'Communication/types';
import { useFetcher } from 'hooks';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';
import { ComUpdateTypes, useSocketState } from 'Socket/socket.context';

export enum MissedCountTypes {
  SET_MISSED_COUNT = 'SET_MISSED_COUNT'
}

interface MissedCountAction {
  type: MissedCountTypes.SET_MISSED_COUNT;
  payload: ICommunicationCounts;
}

const InitialState: ICommunicationCounts = {
  missedAudioCalls: 0,
  missedVideoCalls: 0,
  missedTextMessages: 0
};

interface CountsContext {
  missedCount: ICommunicationCounts;
  fetchMissedCounts: () => void;
}

const MissedCountContext = createContext<CountsContext>({
  missedCount: InitialState,
  fetchMissedCounts: () => undefined
});

const missedCountReducer = (
  state: ICommunicationCounts = InitialState,
  action: MissedCountAction
) => {
  const { type, payload } = action;
  switch (type) {
    case MissedCountTypes.SET_MISSED_COUNT: {
      return payload;
    }
    default:
      return state;
  }
};

export const MissedCountProvider = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [state, dispatch] = useReducer(missedCountReducer, InitialState);
  const { loggedUser, loginId } = useAuthState();
  const { comUpdate } = useSocketState();

  const [fetchMissedCount, setFetchMissedCount] = useState(false);

  const refetchMissedCount = () => {
    setFetchMissedCount(true);
  };

  useEffect(() => {
    if (loggedUser && loggedUser.type === 'CAREGIVER') {
      setFetchMissedCount(true);
    }
  }, [loggedUser]);

  const getCommunicationCountsAction = useMemo(() => {
    if (fetchMissedCount) {
      return getCommunicationCounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMissedCount]);

  const {
    data: { missedAudioCalls, missedVideoCalls, missedTextMessages },
    loading: loadingCounts
  } = useFetcher<ICommunicationCounts>(
    getCommunicationCountsAction,
    InitialState
  );

  useEffect(() => {
    if (!loadingCounts) {
      setFetchMissedCount(false);

      dispatch({
        payload: {
          missedAudioCalls,
          missedVideoCalls,
          missedTextMessages
        },
        type: MissedCountTypes.SET_MISSED_COUNT
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCounts]);

  useEffect(() => {
    if (
      comUpdate &&
      (comUpdate?.messageType === ComUpdateTypes.TEXT_MESSAGE ||
        comUpdate?.messageType === ComUpdateTypes.FILE_MESSAGE ||
        comUpdate.messageType === ComUpdateTypes.CALL_DISMISS_MESSAGE ||
        (comUpdate.messageType === ComUpdateTypes.MESSAGE_SEEN_MESSAGE &&
          comUpdate.loginSenderId === loginId))
    ) {
      return setFetchMissedCount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comUpdate]);

  return (
    <MissedCountContext.Provider
      value={{
        missedCount: state,
        fetchMissedCounts: refetchMissedCount
      }}
    >
      {children}
    </MissedCountContext.Provider>
  );
};

export const useMissedCountState = () => {
  const context = useContext(MissedCountContext);

  if (context === undefined) {
    throw new Error(
      'useMissedCountState must be used within a MissedCallsProvider'
    );
  }

  return context;
};
