import { StatusType, StatusTypes } from 'Caregivers/types';
import {
  CallerDetails,
  ConversationTypes,
  isCaregiverConversation,
  isRelatedContactConversation,
  MessagingHistoryConversation
} from 'Communication/types';
import { RelatedContactStatuses } from 'consts';
import { LoginId } from 'types';
import { formatName } from 'utils';
import { getConversationName } from './messaging.utils';

export const getCaregiverStatus = (
  conversation: MessagingHistoryConversation | null,
  statusMap: { [caregiverId: string]: StatusType }
) => {
  if (!conversation) {
    return;
  }

  if (conversation.chatRoomType === ConversationTypes.CAREGIVER) {
    return statusMap[conversation.participants[0].id];
  }
  return 'unknown';
};

export const getCallerDetails = (
  conversation: MessagingHistoryConversation,
  currentUserLoginId: LoginId
): CallerDetails => {
  if (
    isCaregiverConversation(conversation) ||
    isRelatedContactConversation(conversation)
  ) {
    const whoIAmCalling = conversation.participants.find(
      participant => participant.loginId !== currentUserLoginId
    );

    const status: 'available' | 'unavailable' =
      whoIAmCalling?.status === StatusTypes.ON_BREAK ||
      whoIAmCalling?.status === StatusTypes.ON_DUTY ||
      whoIAmCalling?.status === RelatedContactStatuses.ACTIVE
        ? 'available'
        : 'unavailable';

    const formattedName = whoIAmCalling && formatName(whoIAmCalling.name);

    return {
      name: formattedName as string,
      title: whoIAmCalling?.role as string,
      photoUrl: whoIAmCalling?.photoUrl,
      type: status
    };
  }

  return {
    name: getConversationName(conversation, currentUserLoginId),
    title: '',
    type: 'unknown'
  };
};
