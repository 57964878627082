import { axiosWrapper } from 'utils';

export const assignCaregiverToWard = (body: {
  wardId: string;
  caregiverId: string;
  facilityId: string;
  workingDay: string;
}) => async () => {
  const response = await axiosWrapper(`/ward-assignments`, 'POST', null, body);

  return response.data;
};
