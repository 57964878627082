import moment from 'moment';

import { BodyWeight, emptyWeek } from 'Residents';

const convertReadings = (day: string, readings: BodyWeight[]) => {
  const bodyWeightReadings = readings
    .filter(entry => moment(entry.measuredAt).format('ddd') === day)
    .map(entry => parseFloat(entry.value.bodyWeight));

  const bodyWeight =
    bodyWeightReadings.length > 0
      ? bodyWeightReadings.reduce((a, b) => a + b) / bodyWeightReadings.length
      : undefined;

  return {
    bodyWeight: bodyWeight?.toFixed(1),
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toWeekData = (data: BodyWeight[]) =>
  emptyWeek.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
