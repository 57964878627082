import React, { useCallback, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { BackButton, VerticalTabs } from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useWithoutSocket } from 'hooks';
import { DeviceSettings } from 'Settings/Devices/DeviceSettings';
import { OverviewSettings } from 'Settings/Overview/OverviewSettings';
import { Facility } from 'Settings/types';
import { WardSettings } from 'Settings/Wards/WardSettings';

import { getFacilityDetails } from '../actions';
import { CaregiversSettings } from '../Caregivers/CaregiversSettings';
import { ResidentsSettings } from '../Residents/ResidentsSettings';
import { RoomsSettings } from '../Rooms/RoomsSettings';

const defaultFacility: Facility = {
  idn: {
    name: '',
    number: '',
    id: ''
  },
  id: '',
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  wards: 0,
  numberOfResidents: 0,
  numberOfWards: 0,
  isActive: false,
  website: '',
  type: '',
  accountOwner: '',
  regionalManager: '',
  region: '',
  distributorName: '',
  additionalData1: '',
  additionalData2: '',
  additionalData3: '',
  additionalData4: '',
  timezone: ''
};

interface ActiveItem {
  [key: string]: string;
  caregiver: string;
  ward: string;
  device: string;
  room: string;
  resident: string;
}

interface FacilitiesUrlParams {
  id: string;
  activeTab?: string;
  selected?: string;
}
export const FacilitySettings = ({
  match,
  history
}: RouteComponentProps<FacilitiesUrlParams>) => {
  useWithoutSocket();

  const onTabSelect = (tab: string) => {
    resetActiveCaregiver();
    resetActiveResident();
    resetActiveWard();
    resetActiveRoom();
    resetActiveDevice();

    history.push(tab);
  };
  const getFacilityDetailsMemo = useMemo(
    () => getFacilityDetails(match.params.id),
    [match.params.id]
  );

  const { data: facility, error, loading, setRefetch } = useFetcher<Facility>(
    getFacilityDetailsMemo,
    defaultFacility
  );

  const activeTab = match.params.activeTab || 'Overview';

  const [activeItems, setActiveItem] = useState<ActiveItem>({
    caregiver: '',
    ward: '',
    device: '',
    room: '',
    resident: ''
  });

  const onSelectActiveItem = (type: string, id: string) => {
    setActiveItem((prevState: ActiveItem) => {
      const newValue = prevState[type] === id ? '' : id;
      return {
        ...prevState,
        [type]: newValue
      };
    });
  };

  const resetActiveCaregiver = useCallback(() => {
    setActiveItem((prevState: ActiveItem) => ({ ...prevState, caregiver: '' }));
  }, []);

  const resetActiveWard = useCallback(() => {
    setActiveItem((prevState: ActiveItem) => ({ ...prevState, ward: '' }));
  }, []);

  const resetActiveRoom = useCallback(() => {
    setActiveItem((prevState: ActiveItem) => ({ ...prevState, room: '' }));
  }, []);

  const resetActiveDevice = useCallback(() => {
    setActiveItem((prevState: ActiveItem) => ({ ...prevState, device: '' }));
  }, []);

  const resetActiveResident = useCallback(() => {
    setActiveItem((prevState: ActiveItem) => ({ ...prevState, resident: '' }));
  }, []);

  return (
    <>
      <BackButton title={facility.name} link={'/Settings/Facilities'} />
      {facility.id !== '' && (
        <VerticalTabs
          onTabSelect={onTabSelect}
          activeTab={activeTab}
          tabNames={[
            'Overview',
            'Wards',
            'Rooms',
            'Caregivers',
            `${residentLabels[facility.type]}s`,
            'Devices'
          ]}
        >
          <OverviewSettings
            facility={facility}
            error={error}
            loading={loading}
            refetch={setRefetch}
            setActiveTab={onTabSelect}
            activeItems={activeItems as ActiveItem}
            onSelectActiveItem={onSelectActiveItem}
          />
          <WardSettings facility={facility} activeWard={activeItems.ward} />
          <RoomsSettings facility={facility} activeRoom={activeItems.room} />
          <CaregiversSettings
            facility={facility}
            activeCaregiver={activeItems.caregiver}
            resetActiveCaregiver={resetActiveCaregiver}
          />
          <ResidentsSettings
            facility={facility}
            activeResident={activeItems.resident}
            resetActiveResident={resetActiveResident}
          />
          <DeviceSettings
            facility={facility}
            activeDevice={activeItems.device}
          />
        </VerticalTabs>
      )}
    </>
  );
};
