import classnames from 'classnames';
import React, { useMemo } from 'react';

import { useNotificationState } from 'Notifications';

import { ProfilePlaceholder } from 'components';
import { ReactComponent as ExclamationMark } from 'icons/exclamationMark.svg';
import { URLQueryValues } from 'Residents';
import { formatName } from 'utils';
import { getMarkAndHighlight } from 'utils/checkAlarms';

import { Card } from './contexts';
import { URLQueryValueType } from './ResidentsDashboard';

import style from './ResidentListEntry.module.css';

export const ResidentListEntry: React.FunctionComponent<{
  data: Card;
  isSelected: boolean;
  onSelect: (key: URLQueryValueType, value: string | undefined) => void;
}> = ({ data, isSelected, onSelect }) => {
  const { notifications } = useNotificationState();

  const {
    cardId,
    cardName,
    cardType,
    roomNumber,
    residentGender,
    residentPhotoUrl
  } = data;

  const residentNotificationStyle = getMarkAndHighlight(notifications, cardId);

  const handleClick = () => {
    if (isSelected) {
      return undefined;
    }

    onSelect(cardType, cardId);
  };

  const formattedName = useMemo(() => formatName(cardName), [cardName]);

  return (
    <li
      className={classnames(style.entry, {
        [style.selected]: isSelected,
        [style.highlighted]: residentNotificationStyle.isHighlighted
      })}
      onClick={handleClick}
    >
      <ProfilePlaceholder
        name={cardName}
        type={residentGender?.toLocaleLowerCase()}
        photoUrl={residentPhotoUrl}
      />
      <div className={style.details}>
        <span
          className={
            !!residentGender
              ? style[residentGender.toLocaleLowerCase()]
              : style.unknown
          }
        >
          {formattedName}
        </span>
        <span className={style.room}>{`Room: ${roomNumber}`}</span>
      </div>
      {residentNotificationStyle.hasExclamationMark &&
        cardType === URLQueryValues.RESIDENT_KEY && (
          <div className={style.warning} data-testid="warning-test">
            <ExclamationMark />
          </div>
        )}
    </li>
  );
};
