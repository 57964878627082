import classnames from 'classnames';
import React from 'react';
import useForm from 'react-hook-form';

import {
  DetailBox,
  DismissibleError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  Select
} from 'components';
import { relationships } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { ResidentContact } from 'Settings';

import { createResidentContact as createRelatedContactAction } from '../actions';

import { EMAIL_PATTERN, VALIDATION_MESSAGE } from 'Settings/constants';
import stylesForm from 'styles/form.module.css';
import { getFormValidationErrors } from 'utils';

interface AddRelatedContactFormData {
  name: string;
  relationship: string;
  email: string;
  phoneNumber: string;
}

export const AddRelatedContactForm: React.FunctionComponent<{
  close: () => void;
  residentId: string;
  label: string;
}> = ({ close, residentId, label }) => {
  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<AddRelatedContactFormData>({
    mode: 'onBlur',
    submitFocusError: false
  });
  const { setAction, loading, error } = usePoster();

  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, loading);

  const handleEmailChange = () => {
    if (error) {
      dismissError();
    }
  };

  const createRelatedContact = (residentContact: ResidentContact) => {
    setAction(createRelatedContactAction(residentId, residentContact));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const validationErrors = getFormValidationErrors(errors);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const contactData = getValues();
      createRelatedContact({
        ...contactData,
        status: 'pending',
        residentId
      } as ResidentContact);
    }
  };

  if (submitted && !loading && !error) {
    close();
  }

  const relationshipOptions = relationships.map(relationship => ({
    value: relationship,
    label: relationship
  }));
  const errorName = `${label} Related Contact`;

  return (
    <>
      <FormHeader
        title="Add New Related Contact"
        onCancel={close}
        onSubmit={handleSubmit}
        submitDisabled={loading}
      />
      {loading && <LoadingPlaceholder />}
      <form
        onSubmit={handleSubmit}
        className={classnames(stylesForm.visible, {
          [stylesForm.hidden]: loading
        })}
      >
        <div className={stylesForm.formRow}>
          <div className={stylesForm.inputGroupHalf}>
            <Input
              name="name"
              label="Contact Name"
              register={register}
              validationRules={{ required: true }}
              hasError={!!errors.name}
              testId={'contact-name-form'}
            />
          </div>
          <div className={stylesForm.selectGroupQuarter}>
            <Select
              name="relationship"
              label="Relationship"
              options={relationshipOptions}
              hasError={!!errors.relationship}
              register={register}
              onChange={setValue}
              value={watch('relationship')}
              required={true}
            />
          </div>
          <div className={stylesForm.selectGroupStatusSmall}>
            <DetailBox label="Status" value="Pending" />
          </div>
        </div>

        <div className={stylesForm.formRow}>
          <div className={stylesForm.inputGroupQuarter}>
            <Input
              name="email"
              type="email"
              label="E-mail Address"
              register={register}
              hasError={!!errors.email || !!isErrorShowing}
              validationRules={{
                required: true,
                pattern: {
                  value: EMAIL_PATTERN,
                  message: VALIDATION_MESSAGE
                }
              }}
              onChange={handleEmailChange}
            />
          </div>
          <div className={stylesForm.inputGroupQuarter}>
            <Input
              name="phoneNumber"
              label="Phone Number"
              register={register}
              hasError={!!errors.phoneNumber}
            />
          </div>
        </div>
        <DismissibleError
          name={errorName}
          visible={isErrorShowing}
          error={error}
          dismiss={dismissError}
        />
        {validationErrors && (
          <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
        )}
      </form>
    </>
  );
};
