import classnames from 'classnames';
import React, { useMemo, useState } from 'react';

import { ReactComponent as ExclamationMark } from 'icons/exclamationMark.svg';

import { useFetcher, useInterval } from 'hooks';
import { formatName, getMarkAndHighlight, timeSince } from 'utils';

import { formatCurrentLocation } from './formatCurrentLocation';
import { DeviceLocation } from './types';

import { useLocationState } from 'Location';
import { AlarmTypes, useNotificationState } from 'Notifications';
import { getRoomDetails, Room } from 'Settings';

import stylesTable from 'components/Table/Table.module.css';
import listItemStyle from './LocationTableRow.module.css';

const getAssignee = (values: DeviceLocation) => {
  const { caregiver, resident, assetTracker } = values;
  const formattedCaregiverName = caregiver && formatName(caregiver.name);
  const formattedResidentName = resident && formatName(resident.name);

  if (caregiver) {
    return `${formattedCaregiverName} (${caregiver.role})`;
  } else if (resident) {
    return `${formattedResidentName}`;
  } else if (assetTracker) {
    const { description } = assetTracker;
    return description;
  }

  return 'Unknown';
};

export const LocationTableRow: React.FunctionComponent<{
  values: DeviceLocation;
}> = ({ values }) => {
  const [, setRerender] = useState(Math.random());
  const { notifications } = useNotificationState();
  const { facility } = useLocationState();
  useInterval(() => {
    setRerender(Math.random());
  }, 500);

  const getRoomDetailsMemo = useMemo(() => {
    if (facility && values && values.room) {
      return getRoomDetails(facility.id, values.room.id);
    }
  }, [facility, values]);
  const { data: roomData } = useFetcher<Room>(getRoomDetailsMemo, {} as Room);

  const residentNames =
    roomData &&
    roomData.residents &&
    roomData.residents.find(({ status }) => status)
      ? roomData.residents
          .filter(({ status }) => status)
          .map((resident: any) => {
            const formattedName = formatName(resident.name);
            return formattedName;
          })
      : [];

  const residentNotificationStyle = getMarkAndHighlight(
    notifications,
    values.resident?.id
  );
  const isCaregiverAlarm = notifications.some(
    ({ type, personOfInterest }) =>
      type === AlarmTypes.CAREGIVER_ALARM &&
      values.caregiver &&
      personOfInterest?.id === values.caregiver.id
  );

  return (
    <div
      className={classnames(stylesTable.tableRow, {
        [stylesTable.tableRowHighlighted]:
          residentNotificationStyle.isHighlighted || isCaregiverAlarm
      })}
      role="row"
    >
      <div
        className={classnames(stylesTable.tableCell, listItemStyle.cell)}
        role="cell"
      >
        <span className={listItemStyle.textOverflow}>{values.name}</span>
      </div>
      <div
        className={classnames(stylesTable.tableCell, listItemStyle.cell)}
        role="cell"
      >
        <span className={listItemStyle.textOverflow}>
          {getAssignee(values)}
        </span>
        {residentNotificationStyle.hasExclamationMark && (
          <span className={listItemStyle.warning}>
            <ExclamationMark />
          </span>
        )}
      </div>
      <div
        className={classnames(stylesTable.tableCell, listItemStyle.cell)}
        role="cell"
      >
        <span className={listItemStyle.textOverflow}>
          {formatCurrentLocation(values.room)}
        </span>
      </div>
      <div
        className={classnames(stylesTable.tableCell, listItemStyle.cell)}
        role="cell"
      >
        {values?.room && (
          <span className={listItemStyle.textOverflow}>
            {residentNames.join(', ')}
          </span>
        )}
      </div>
      <div
        className={classnames(
          stylesTable.tableCell,
          listItemStyle.cell,
          listItemStyle.rightAlign
        )}
        role="cell"
      >
        <span>{values.timestamp && timeSince(values.timestamp)}</span>
      </div>
    </div>
  );
};
