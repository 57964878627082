import React, { useMemo } from 'react';

import {
  DetailBox,
  PanelHeader,
  ProfileComponent,
  RoundEditButton
} from 'components';
import { useLabelState, useLocationState } from 'Location';
import { Resident } from 'Settings';
import { formatName, formatWardRoom } from 'utils';

import styles from './ResidentDetailsPanel.module.css';

export const ResidentDetailsPanel: React.FunctionComponent<{
  resident: Resident;
  onEditClick: () => void;
}> = ({ resident, onEditClick }) => {
  const label = useLabelState();

  const { selectedWardDetails } = useLocationState();

  const formatBirthday = (birthday: string | undefined) => {
    if (!birthday) {
      return birthday;
    }
    const [year, month, day] = birthday.split('-');
    return `${month}/${day}/${year}`;
  };

  const title = `${label} Details`;
  const numberLabel = `${label} Number`;
  const badgeLabel = `${label} Device Name`;

  const formattedName = useMemo(() => resident && formatName(resident.name), [
    resident
  ]);

  return (
    <>
      <PanelHeader title={title}>
        <RoundEditButton onClick={onEditClick} />
      </PanelHeader>

      <div className={styles.detailsContent}>
        <div className={styles.fixedHeightRow}>
          <ProfileComponent
            name={formattedName}
            label={label}
            photoUrl={resident.photoUrl}
            style={styles.thirdCentered}
            type={resident.gender && resident.gender.toLowerCase()}
          />
          <div className={styles.thirdCentered}>
            <DetailBox
              label="Date of Birth"
              value={formatBirthday(resident.birthday)}
            />
          </div>
          <div className={styles.thirdCentered}>
            <DetailBox label={numberLabel} value={resident.number} />
          </div>
        </div>
        <div className={styles.fixedHeightRow}>
          <div className={styles.third}>
            <DetailBox
              label="Ward - Room"
              value={formatWardRoom(resident.room)}
            />
          </div>
          {selectedWardDetails?.services.rtls.features.rtlsPatient
            .isEnabled && (
            <div className={styles.third}>
              <DetailBox
                label={badgeLabel}
                value={resident.badge && resident.badge.name}
                isLink={true}
              />
            </div>
          )}
          <div className={styles.third}>
            <DetailBox
              label="Cell Phone"
              value={resident.cellPhoneNumber}
              isLink={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
