import { axiosWrapper } from 'utils';

export const authenticateUser = async (username: string, password: string) => {
  const response = await axiosWrapper('/token', 'POST', null, {
    username,
    password
  });

  return response.headers.authorization.replace('Bearer ', '');
};
