import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import {
  getCaregiversByWard,
  getStaffMembers,
  StaffMember,
  StaffMemberDetail,
  StatusTypes,
  useCaregiversStatus
} from 'Caregivers';
import {
  ChatRoom,
  CommunicationActionTypes,
  ConversationTypes,
  getChatRooms,
  useCommunicationDispatch,
  useMissedCallsState,
  useMissedMessagesState
} from 'Communication';
import { FetchError, LoadingPlaceholder, SearchHeader } from 'components';
import { useFetcher, useModal } from 'hooks';
import { useLocationState } from 'Location';
import { AlarmTypes, useNotificationState } from 'Notifications';
import { Caregiver } from 'Settings';
import { getCallCount } from 'utils';

import { CareTeamListEntry } from './CareTeamListEntry';
import { CareTeamListHeader } from './CareTeamListHeader';

import style from './CareTeamList.module.css';

export const CareTeamList: React.FunctionComponent<{
  history: any;
}> = ({ history }) => {
  const [careTeamList, setCareTeamList] = useState([] as StaffMemberDetail[]);
  const [searchValue, setSearchValue] = useState('');
  const [content, setContent] = useState<JSX.Element[]>([]);

  const { isShowing: isSearching, toggle: toggleSearch } = useModal();
  const { loggedUser, loginId } = useAuthState();
  const { state: missedCalls } = useMissedCallsState();
  const { state: missedMessages } = useMissedMessagesState();
  const conversationDispatch = useCommunicationDispatch();

  useEffect(() => {
    conversationDispatch({
      type: CommunicationActionTypes.CLEAR_COMMUNICATION
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    facility,
    ward: wardId,
    devicesLocation,
    rtlsError,
    rtlsLoading
  } = useLocationState();

  const { notifications } = useNotificationState();
  const {
    data: caregiversStatusMap,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const caregiversByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getCaregiversByWard(facility.id, wardId);
    }
  }, [facility.id, wardId]);

  const getChatRoomsAction = useMemo(() => {
    if (loggedUser && loggedUser.type === 'CAREGIVER') {
      return getChatRooms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, missedCalls, missedMessages]);

  const {
    data: chatRooms,
    loading: chatRoomLoading,
    error: chatRoomError
  } = useFetcher<ChatRoom[]>(getChatRoomsAction, []);

  const {
    data: caregiversByWard,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<Caregiver[]>(caregiversByWardAction, []);

  useEffect(() => {
    const list = getStaffMembers(
      caregiversByWard,
      devicesLocation,
      caregiversStatusMap
    );
    const availableStaff = list.filter(
      ({ status }) => status !== StatusTypes.OFF_DUTY
    );
    setCareTeamList(availableStaff);
  }, [caregiversByWard, devicesLocation, caregiversStatusMap]);

  const filteredCareTeam = useMemo(() => {
    if (searchValue.length < 3 || !isSearching) {
      return careTeamList;
    }
    return careTeamList.filter(
      ({ name, role }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()) ||
        (role as string).toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [careTeamList, searchValue, isSearching]);

  useEffect(() => {
    const entries = filteredCareTeam.map((staffMember: StaffMember) => {
      const selectedCaregiver = caregiversByWard.find(
        entry => entry.id === staffMember.id
      );

      const activeChat = chatRooms.find(
        chat =>
          chat.chatRoomType.toLowerCase() === ConversationTypes.CAREGIVER &&
          chat.participants.some(
            participantLoginId => participantLoginId === loginId
          ) &&
          chat.participants.some(
            participantLoginId =>
              participantLoginId === selectedCaregiver?.loginId
          )
      );

      const isHighlighted = notifications.some(
        ({ type, personOfInterest }) =>
          type === AlarmTypes.CAREGIVER_ALARM &&
          personOfInterest?.id === staffMember.id
      );

      const callCount = getCallCount(activeChat?.id, missedCalls);
      const messagesCount = missedMessages.find(
        counts => counts.chatRoomId === activeChat?.id
      )?.messages;

      const onClick = () => {
        history.push(
          `/CaregiverDashboard?facility=${facility.id}&ward=${wardId}&caregiver=${staffMember.id}`
        );
      };

      return (
        <CareTeamListEntry
          key={staffMember.id}
          isHighlighted={isHighlighted}
          activeChat={activeChat}
          staffMember={staffMember}
          callCount={callCount}
          messagesCount={messagesCount}
          onClick={onClick}
        />
      );
    });

    setContent(entries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    missedCalls,
    filteredCareTeam,
    missedMessages,
    notifications,
    chatRooms,
    facility.id,
    wardId
  ]);

  const fetchError =
    caregiversError || rtlsError || statusError || chatRoomError;
  const loading =
    caregiversLoading || rtlsLoading || statusLoading || chatRoomLoading;

  const careTeamListContent =
    filteredCareTeam.length > 0 ? (
      <ul>{content}</ul>
    ) : (
      <p className={style.listPlaceholder}>
        {isSearching
          ? 'The search value returned no results.'
          : 'No available caregivers for this ward.'}
      </p>
    );

  return (
    <div className={classnames(style.list, { [style.hasError]: !!fetchError })}>
      {!fetchError && (
        <>
          {isSearching ? (
            <SearchHeader
              searchInputName="caregivers"
              onClose={toggleSearch}
              searchValue={searchValue}
              onSearch={setSearchValue}
            />
          ) : (
            <CareTeamListHeader toggleSearch={toggleSearch} />
          )}
        </>
      )}
      {loading && !fetchError && <LoadingPlaceholder />}
      {!loading && !fetchError && careTeamListContent}
      {fetchError && !loading && <FetchError error={fetchError} />}
    </div>
  );
};
