import React, { useCallback, useState } from 'react';

import {
  DetailsPlaceholder,
  PanelPlaceholder,
  TwoColumnLayout
} from 'components';
import { Idn } from 'Settings/types';

import { FacilityList } from './FacilityList';
import { IdnDetails } from './IdnDetails';
import { IdnList } from './IdnList';

import idnStyles from './IdnSettings.module.css';

export const IdnSettings: React.FunctionComponent = () => {
  const [selectedIdnId, setSelectedIdnId] = useState<{ id: string }>({
    id: ''
  });
  const [selectedIdn, setSelectedIdn] = useState<Idn>({} as Idn);

  const handleIdnChange = useCallback((idn: Idn) => {
    setSelectedIdn(idn);
    setSelectedIdnId({ id: idn.id });
  }, []);

  const handleIdnChangeFacilities = useCallback((idn: Idn) => {
    setSelectedIdn(idn);
  }, []);

  const selectIdn = (idnId: string) => {
    setSelectedIdnId({ id: idnId });
  };

  return (
    <>
      <TwoColumnLayout
        left={<IdnList selectedIdn={selectedIdn} selectIdn={selectIdn} />}
        right={
          <>
            <div className={idnStyles.panelHeight}>
              {selectedIdnId.id && (
                <IdnDetails
                  idnId={selectedIdnId.id}
                  onIdnChange={handleIdnChange}
                />
              )}
              {!selectedIdnId.id && (
                <DetailsPlaceholder
                  message="Please select IDN to display details"
                  title="IDN Details"
                />
              )}
            </div>
            {selectedIdnId.id && (
              <FacilityList
                selectedIdnId={selectedIdnId}
                onIdnChange={handleIdnChangeFacilities}
              />
            )}
            {!selectedIdnId.id && (
              <PanelPlaceholder
                message="Please select IDN to display facilities"
                title="Facilities"
              />
            )}
          </>
        }
      />
    </>
  );
};
