import classnames from 'classnames';
import React from 'react';

import { FetchError } from 'components';
import { HomeCssClasses } from 'Home/types';
import { getPriorityStyle } from 'Home/utils/home-view.util';
import { useLocationContext } from 'Location';
import {
  AlarmsPriority,
  NotificationDetail,
  NotificationEntry,
  NotificationsLoadingPlaceholder
} from 'Notifications';
import { isResidentPanicAlarm } from 'Notifications/utils';

import styles from './AlarmPanel.module.css';

interface IAlarmPanelProps {
  title: string;
  alarms: NotificationDetail[];
  onDismiss: (id: string) => void;
  isLoading: boolean;
  error: Error | null;
  onErrorClose: () => void;
  actionDisabled: boolean;
  customStyle: string;
  priority: AlarmsPriority;
}

export const AlarmPanel: React.FunctionComponent<IAlarmPanelProps> = ({
  title,
  alarms,
  onDismiss,
  isLoading,
  error,
  actionDisabled,
  onErrorClose,
  customStyle,
  priority
}) => {
  const { label } = useLocationContext();
  const notificationsList = alarms.map(alarm => {
    const alarmTitle = isResidentPanicAlarm(alarm)
      ? `${label} Alarm`
      : alarm.title;
    return (
      <NotificationEntry
        key={alarm.id}
        notification={alarm}
        onDismiss={onDismiss}
        disabledAction={actionDisabled}
        title={alarmTitle}
        priority={priority}
        customStyle={customStyle}
        timestamp={alarm.created}
      />
    );
  });
  const isDataAvailable = notificationsList.length > 0;

  const priorityCssClass = getPriorityStyle(priority);

  const listContent = isDataAvailable ? (
    <ul className={styles.alarmList}>{notificationsList}</ul>
  ) : (
    <p className={styles.emptyPlaceholder}>
      There are no {title} at this time.
    </p>
  );

  return (
    <div
      className={classnames(
        styles.alarmPanel,
        styles[customStyle],
        alarms.length <= 2 && styles.lowestHeight,
        alarms.length === 0 && styles.empty
      )}
    >
      {error && (
        <div className={styles.errorWrapper}>
          <FetchError error={error} closable={true} onClose={onErrorClose} />
        </div>
      )}
      {!error && customStyle === HomeCssClasses.REGULAR && (
        <h2 className={classnames(styles.alarmTitle, styles[priorityCssClass])}>
          {title}
        </h2>
      )}
      {!error && isLoading && <NotificationsLoadingPlaceholder />}
      {!error && !isLoading && listContent}
    </div>
  );
};
