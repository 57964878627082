export const createFacilityDTO = (valuesToBeFormated: any) => {
  const { idnName, name, ...rest } = valuesToBeFormated;

  const valuesWithoutIdn = { name: name.trim(), ...rest };

  return idnName
    ? {
        ...valuesWithoutIdn,
        idn: {
          id: idnName
        }
      }
    : valuesWithoutIdn;
};
