import { ActivityType, BasicActivity } from 'Residents';
import { axiosWrapper } from 'utils';

export const getDailyActivityCounter = (
  facilityId: string,
  residentId: string,
  type: ActivityType,
  startDate: string,
  endDate: string
) => async () => {
  const intervalParams = new URLSearchParams({
    start: startDate,
    end: endDate
  });
  const response = await axiosWrapper<BasicActivity[]>(
    `/facilities/${facilityId}/residents/${residentId}/daily-activity/${type}?${intervalParams}`
  );

  return response.data;
};
