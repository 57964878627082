import { StatusType, StatusTypes } from 'Caregivers';
import {
  ChatMessage,
  FILE_MESSAGE_TEXT,
  LastMessage,
  Message
} from 'Communication';
import { Caregiver, ResidentContact } from 'Settings';
import { formatName } from 'utils';
import { Reader } from './../types/chat.type';

import { ChatMessageTypes } from '../types';

export const getMessageContent = (
  entry: Message | LastMessage,
  participantName?: string,
  senderName?: string
) => {
  switch (entry.messageType) {
    case ChatMessageTypes.FILE_MESSAGE: {
      return FILE_MESSAGE_TEXT;
    }
    case ChatMessageTypes.TEXT_MESSAGE: {
      return entry.textMessage;
    }
    case ChatMessageTypes.PARTICIPANT_ADDED: {
      if (senderName && senderName !== participantName && participantName) {
        return `${formatName(senderName)} added ${formatName(
          participantName
        )} to the chat.`;
      } else if (participantName) {
        return `${formatName(participantName)} joined the chat.`;
      }
      return 'default message';
    }
    case ChatMessageTypes.PARTICIPANT_REMOVED: {
      if (senderName && senderName !== participantName && participantName) {
        return `${formatName(senderName)} removed ${formatName(
          participantName
        )} from the chat.`;
      } else if (participantName) {
        return `${formatName(participantName)} left the chat`;
      }
      return 'default message';
    }
    default:
      return 'default message';
  }
};

const generateMessage = (
  message: Message,
  sender: any,
  caregiversWardStatusMap: { [caregiverId: string]: StatusType },
  content: string,
  readBy: Array<Caregiver | ResidentContact>
): ChatMessage => ({
  id: message.id,
  conversationId: message.chatRoomId,
  content,
  type: message.messageType,
  sentAt: message.timestamp.toString(),
  readBy: readBy as Reader[],
  author: {
    loginId: sender?.loginId,
    id: sender?.id,
    name: sender?.name,
    status: sender ? caregiversWardStatusMap[sender.id] : StatusTypes.OFF_DUTY,
    photoUrl: sender?.photoUrl,
    role: sender?.role,
    relationship: sender?.relationship,
    residentId: sender?.residentId
  },
  fileUri: message.fileUri,
  fileName: message.fileName
});

export const allowedMessageTypes = [
  ChatMessageTypes.TEXT_MESSAGE,
  ChatMessageTypes.FILE_MESSAGE,
  ChatMessageTypes.PARTICIPANT_ADDED,
  ChatMessageTypes.PARTICIPANT_REMOVED
];

interface IFormatParams {
  loginId: string;
  relatedContacts: ResidentContact[];
  caregivers: Caregiver[];
  caregiversWardStatusMap: { [caregiverId: string]: StatusType };
  messages: Message[];
}

type FormatFunction = (p: IFormatParams) => ChatMessage[];

export const formatMessages: FormatFunction = ({
  loginId,
  caregivers,
  caregiversWardStatusMap,
  messages,
  relatedContacts
}) => {
  const allContacts = [...caregivers, ...relatedContacts];

  const filteredMessages = messages.filter(entry =>
    allowedMessageTypes.includes(entry.messageType)
  );

  const messageSeenMessages = messages.filter(
    ({ messageType }) => messageType === ChatMessageTypes.MESSAGE_SEEN_MESSAGE
  );

  const myLastMessageId = filteredMessages.find(
    ({ loginSenderId }) => loginSenderId === loginId
  )?.id;

  return filteredMessages.map(entry => {
    const sender = allContacts.find(
      contactEntry => contactEntry.loginId === entry.loginSenderId
    );
    const formattedSenderName = sender && formatName(sender.name);

    const participant = allContacts.find(
      contactEntry => contactEntry.loginId === entry.participantLoginId
    );
    const formattedParticipantName =
      participant && formatName(participant.name);

    const content = getMessageContent(
      entry,
      formattedParticipantName,
      formattedSenderName
    );

    const readBy = composeReadBy(
      entry,
      myLastMessageId,
      messageSeenMessages,
      allContacts
    );

    return generateMessage(
      entry,
      sender,
      caregiversWardStatusMap,
      content,
      readBy
    );
  });
};

const composeReadBy = (
  message: Message,
  myLastMessageId: string | undefined,
  seenMessages: Message[],
  allContacts: Array<Caregiver | ResidentContact>
): Array<Caregiver | ResidentContact> => {
  if (message.id === myLastMessageId && myLastMessageId) {
    const readersIds = seenMessages
      .filter(
        ({ messageThatWasSeen }) => messageThatWasSeen === myLastMessageId
      )
      .map(({ loginSenderId }) => loginSenderId);

    return allContacts.filter(contact => readersIds.includes(contact.loginId));
  }

  return [];
};
