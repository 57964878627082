import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { FacilityListResponse } from '../types/facility.type';

export const getFacilityList = async (idnId?: string) => {
  const initialUrl = `/facilities?size=${MAX_PAGE_SIZE}`;
  const url = idnId ? `${initialUrl}&idnId=${idnId}` : initialUrl;
  const response = await axiosWrapper<FacilityListResponse>(url);

  return response.data.items;
};

export const getFacilitiesByQuery = async (
  searchQuery: string,
  page?: string
) => {
  const initialUrl = `/facilities?facilitySearchQuery=${searchQuery}`;
  const url = page ? `${initialUrl}&page=${page}` : initialUrl;
  const response = await axiosWrapper<FacilityListResponse>(url);

  return response.data;
};

export const getFacilityByQueryFromIdn = async (
  searchQuery: string,
  idnId: string,
  page: string
) => {
  const response = await axiosWrapper<FacilityListResponse>(
    `/facilities?facilitySearchQuery=${searchQuery}&idnId=${idnId}&page=${page}`
  );

  return response.data;
};
