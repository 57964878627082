import React, { useEffect, useMemo, useState } from 'react';

import { FetchError } from 'components';
import { LoadingPlaceholderCard } from 'Home';
import { useFetcher, useInfiniteScroll } from 'hooks';
import { useLocationState } from 'Location';
import { Caregiver } from 'Settings';

import { searchCaregiversByName } from './actions';
import { CaregiverResultCard } from './CaregiverResultCard';
import { useSearchState } from './search.context';

import styles from './SearchResultsList.module.css';

const NO_RESULTS_MESSAGE = 'We could not find any results for your search';

export const CaregiverResultsList: React.FunctionComponent = () => {
  const [page, setPage] = useState(0);
  const [caregivers, setCaregivers] = useState<Caregiver[]>([]);

  const { searchTerm } = useSearchState();
  const { facilities } = useLocationState();

  const getCaregiversAction = useMemo(() => {
    if (searchTerm && searchTerm.length >= 3) {
      return searchCaregiversByName(searchTerm, page);
    }
    return () => Promise.resolve({ items: [] });
  }, [page, searchTerm]);

  const {
    data: caregiverData,
    error: caregiverError,
    loading: caregiverLoading
  } = useFetcher<{
    items: Caregiver[];
    nextPage?: string;
  }>(getCaregiversAction, { items: [] });

  const scrollAction = () => {
    if (isFetching && caregiverData.items.length && caregiverData.nextPage) {
      setPage(parseInt(caregiverData.nextPage, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    caregiverLoading
  );

  useEffect(() => {
    if (caregiverData.items.length !== 0) {
      setCaregivers(prevState => [...prevState, ...caregiverData.items]);
    }
  }, [caregiverData]);

  useEffect(() => {
    setCaregivers([]);
    setPage(0);
  }, [searchTerm]);

  const caregiverCards = useMemo(
    () =>
      caregivers.map(caregiver => {
        const { facilityId, id: caregiverId } = caregiver;
        const dashboardLink = `/CaregiverDashboard?facility=${facilityId}&caregiver=${caregiverId}`;
        const facility = facilities.find(({ id }) => id === facilityId);

        return (
          <CaregiverResultCard
            key={caregiverId}
            caregiver={caregiver}
            link={dashboardLink}
            facility={facility ? facility.name : ''}
          />
        );
      }),
    [caregivers, facilities]
  );

  const placeholderMessage =
    searchTerm && searchTerm.length >= 3 && !caregivers.length
      ? NO_RESULTS_MESSAGE
      : 'Start searching for caregivers';

  const isDataAvailable = caregivers.length > 0;
  const CaregiversLoadingPlaceholder = (
    <>
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
    </>
  );

  return (
    <div className={styles.resultsContainer}>
      <h1 className={styles.title}>Caregivers Search Results</h1>
      {!caregiverError && (
        <div className={styles.content} ref={scrollRef}>
          {(!caregiverLoading || isDataAvailable) && caregiverCards}
          {caregiverLoading && CaregiversLoadingPlaceholder}
        </div>
      )}
      {!caregiverError && !caregiverLoading && !isDataAvailable && (
        <p className={styles.emptyListPlaceholder}>{placeholderMessage}</p>
      )}
      {caregiverError && !caregiverLoading && (
        <FetchError error={caregiverError} />
      )}
    </div>
  );
};
