import {
  DraggableStateSnapshot,
  DraggingStyle,
  DroppableStateSnapshot,
  NotDraggingStyle
} from 'react-beautiful-dnd';

export const getDraggableStyle = (
  snapshot: DraggableStateSnapshot,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => {
  const itemStyles = {
    ...draggableStyle,
    background: 'white',
    borderRadius: '5px',
    border: '1px solid lightGrey'
  };
  if (!snapshot.isDragging) {
    return {};
  }
  if (!snapshot.isDropAnimating) {
    return itemStyles;
  }

  return {
    ...itemStyles,
    transitionDuration: `0.001s`
  };
};

export const getDroppableStyle = (snapshot: DroppableStateSnapshot) => {
  return {
    background:
      snapshot.isDraggingOver && !snapshot.draggingFromThisWith
        ? 'rgba(44, 157, 204, 0.1)'
        : 'white'
  };
};
