import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import { useCaregiversStatus } from 'Caregivers';
import { FetchError, LoadingPlaceholder } from 'components';
import { useFetcher, useInfiniteScroll } from 'hooks';
import { useLocationState } from 'Location';
import { getResidentsByWard } from 'Residents';
import { Caregiver, Resident, ResidentContact } from 'Settings';

import {
  getPaginatedChatRooms,
  getRelatedContacts,
  markMessageSeen,
  saveSeenMessagesForMissedCallsInChatRoom
} from './actions';
import {
  CommunicationActionTypes,
  useCommunicationContext,
  useMessagingContext,
  useMissedCallsState,
  useMissedCountState
} from './contexts';
import { MessagingHistoryHeader } from './MessagingHistoryHeader';
import { MessagingHistoryListEntry } from './MessagingHistoryListEntry';

import {
  ChatRoom,
  ConversationTypes,
  MessagingActionTypes,
  MessagingHistoryConversation
} from './types';
import { byISOdateNewest, getFilteredConversations } from './utils';

import styles from './MessagingHistoryList.module.css';

interface IMessagingHistoryListProps {
  onAdd?: () => void;
  onAdHocOpen?: () => void;
  isSearchOn: boolean;
  onSelect: () => void;
  caregivers: Caregiver[];
}

export const MessagingHistoryList: React.FunctionComponent<IMessagingHistoryListProps> = ({
  onAdd,
  isSearchOn,
  onSelect,
  onAdHocOpen,
  caregivers
}) => {
  const [page, setPage] = useState<string | undefined>(undefined);
  const [someLoading, setSomeLoading] = useState<boolean>(true);
  const [someError, setSomeError] = useState<boolean>(true);
  const [conversationEntries, setConversationEntries] = useState<JSX.Element[]>(
    []
  );
  const { state: missedCalls, refetchMissedCalls } = useMissedCallsState();
  const { fetchMissedCounts } = useMissedCountState();
  const [firstRun, setFirstRun] = useState<boolean>(true);

  const [
    { activeConversation, conversations },
    dispatch
  ] = useCommunicationContext();

  const { id: activeConversationId } = activeConversation;

  const [
    { chatRooms: messagingChatRooms },
    messagingDispatch
  ] = useMessagingContext();

  const { loginId } = useAuthState();

  useEffect(() => {
    return () => {
      messagingDispatch({
        type: MessagingActionTypes.RESET_CHAT_ROOMS
      });
      dispatch({
        type: CommunicationActionTypes.CLEAR_COMMUNICATION
      });
    };
  }, [dispatch, messagingDispatch]);

  const {
    facility: { id: facilityId },
    filtersLoading,
    filtersError,
    ward: wardId,
    selectedWardDetails
  } = useLocationState();

  const {
    data: caregiversStatusMap,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const [fetchActionStatus, setFetchActionStatus] = useState<{
    loading: boolean;
    error: Error | null;
  }>({ loading: false, error: null });

  const residentsByWardAction = useMemo(() => {
    if (facilityId && wardId) {
      return getResidentsByWard(facilityId, wardId, false);
    }
  }, [facilityId, wardId]);

  const {
    data: { items: residentsByWard },
    error: residentsError,
    loading: residentsLoading
  } = useFetcher<{ items: Resident[] | undefined }>(residentsByWardAction, {
    items: undefined
  });

  const chatRoomsAction = useMemo(() => {
    if (page !== undefined) {
      return getPaginatedChatRooms(page);
    }
  }, [page]);

  useEffect(() => {
    if (facilityId && wardId) {
      setPage(undefined);
    }
  }, [facilityId, wardId]);

  const {
    data: { items: chatRooms, nextPage },
    loading: chatRoomLoading,
    error: chatRoomError
  } = useFetcher<{
    items: ChatRoom[] | undefined;
    nextPage: string | undefined;
  }>(chatRoomsAction, {
    items: [] as ChatRoom[],
    nextPage: '0'
  });

  const relatedContactsAction = useMemo(() => {
    if (residentsByWard && residentsByWard.length > 0) {
      return getRelatedContacts(residentsByWard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentsByWard]);

  const {
    data: relatedContacts,
    loading: relatedContactsLoading,
    error: relatedContactsError
  } = useFetcher<ResidentContact[] | undefined>(
    relatedContactsAction,
    undefined
  );

  useEffect(() => {
    if (
      chatRoomLoading ||
      statusLoading ||
      residentsLoading ||
      relatedContactsLoading ||
      filtersLoading
    ) {
      setSomeLoading(true);
    } else {
      setSomeLoading(false);
    }
  }, [
    chatRoomLoading,
    statusLoading,
    residentsLoading,
    relatedContactsLoading,
    filtersLoading
  ]);

  useEffect(() => {
    if (
      filtersError ||
      statusError ||
      chatRoomError ||
      residentsError ||
      relatedContactsError
    ) {
      setSomeError(true);
    } else {
      setSomeError(false);
    }
  }, [
    filtersError,
    statusError,
    chatRoomError,
    residentsError,
    relatedContactsError
  ]);

  useEffect(() => {
    if (chatRooms && chatRooms.length > 0) {
      messagingDispatch({
        type: MessagingActionTypes.SET_CHAT_ROOMS,
        payload: chatRooms
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRooms]);

  useEffect(() => {
    if (someLoading || someError) {
      return undefined;
    }

    if (messagingChatRooms.length === 0) {
      return dispatch({
        type: CommunicationActionTypes.SET_CONVERSATIONS,
        payload: []
      });
    }

    setFetchActionStatus({ loading: true, error: null });

    const list = getFilteredConversations({
      caregivers,
      caregiversStatusMap,
      chats: messagingChatRooms,
      currentUserLoginId: loginId,
      relatedContacts: relatedContacts ?? [],
      residents: residentsByWard ?? []
    });

    dispatch({
      type: CommunicationActionTypes.SET_CONVERSATIONS,
      payload: list.sort(byISOdateNewest)
    });
    setFetchActionStatus(prevState => ({
      ...prevState,
      loading: false
    }));
  }, [
    caregivers,
    caregiversStatusMap,
    dispatch,
    loginId,
    messagingChatRooms,
    relatedContacts,
    residentsByWard,
    someError,
    someLoading
  ]);

  const selectConversation = useCallback(
    (conversation: MessagingHistoryConversation) => {
      if (activeConversationId !== conversation.id || !activeConversationId) {
        onSelect();
        dispatch({
          type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION_ID,
          payload: conversation.id
        });
      }

      if (missedCalls.some(call => call.chatRoomId === conversation.id)) {
        saveSeenMessagesForMissedCallsInChatRoom(conversation.id)
          .then(fetchMissedCounts)
          .finally(refetchMissedCalls);
      }

      if (conversation.hasUnreadMessages && conversation.lastMessage) {
        markMessageSeen(conversation.id, conversation.lastMessage.id);
      }
    },
    [
      activeConversationId,
      dispatch,
      fetchMissedCounts,
      missedCalls,
      onSelect,
      refetchMissedCalls
    ]
  );

  const scrollAction = () => {
    if (!someLoading && isFetching && nextPage) {
      setPage(nextPage);
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    someLoading
  );

  /**
   * we want to send a message seen when we navigate to Communication page from clicking the messages icon in another page
   */
  useEffect(() => {
    if (conversations.length === 0) {
      return undefined;
    }

    if (
      firstRun &&
      missedCalls.some(call => call.chatRoomId === activeConversation?.id)
    ) {
      saveSeenMessagesForMissedCallsInChatRoom(activeConversation.id)
        .then(fetchMissedCounts)
        .finally(refetchMissedCalls);
    }

    const active =
      activeConversation.hasUnreadMessages &&
      conversations.find(chat => chat.id === activeConversation?.id);

    if (firstRun && active && active.lastMessage) {
      setFirstRun(false);
      markMessageSeen(activeConversation.id, active.lastMessage.id);
    } else {
      setFirstRun(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, firstRun]);

  useEffect(() => {
    if (fetchActionStatus.error || fetchActionStatus.loading) {
      return undefined;
    }
    const list: JSX.Element[] = conversations.reduce((acc, item) => {
      if (
        !selectedWardDetails?.services.secureCommunication.features.scMessaging
          .isEnabled &&
        item.chatRoomType === ConversationTypes.CAREGIVER
      ) {
        return acc;
      }

      if (
        !selectedWardDetails?.services.secureCommunication.features.scAdhoc
          .isEnabled &&
        item.chatRoomType === ConversationTypes.AD_HOC
      ) {
        return acc;
      }

      if (
        !selectedWardDetails?.services.secureCommunication.features.scCareTeam
          .isEnabled &&
        item.chatRoomType === ConversationTypes.CARE_TEAM
      ) {
        return acc;
      }

      if (
        !selectedWardDetails?.services.secureCommunication.features
          .scRelatedContact.isEnabled &&
        item.chatRoomType === ConversationTypes.RELATED
      ) {
        return acc;
      }

      if (
        !selectedWardDetails?.services.patientResidentManagement.features
          .prmRelatedContact.isEnabled &&
        item.chatRoomType === ConversationTypes.RELATED
      ) {
        return acc;
      }

      const isSelected = activeConversationId === item.id;

      acc.push(
        <MessagingHistoryListEntry
          key={item.id}
          data={item as MessagingHistoryConversation}
          isSelected={isSelected}
          onSelect={selectConversation}
          hasUnreadMessages={item.hasUnreadMessages}
          currentUserLoginId={loginId}
        />
      );

      return acc;
    }, [] as JSX.Element[]);
    setConversationEntries(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, activeConversationId, fetchActionStatus]);

  return (
    <div className={classnames(styles.list, { [styles.hasError]: someError })}>
      {!someError && (
        <MessagingHistoryHeader
          toggleNewMessage={onAdd}
          onAdHocClick={onAdHocOpen}
          isButtonHidden={isSearchOn}
        />
      )}
      {!someError && (
        <ul ref={nextPage ? scrollRef : undefined}>{conversationEntries}</ul>
      )}
      {!someLoading && !someError && conversationEntries.length === 0 && (
        <p className={styles.listPlaceholder}>You don't have any messages</p>
      )}
      {someLoading && !someError && (
        <LoadingPlaceholder withTransparency={true} />
      )}
      {someError && !someLoading && <FetchError error={someError} />}
      {!someLoading && isFetching && nextPage && (
        <LoadingPlaceholder
          fullHeight={false}
          withTransparency={true}
          customStyles={styles.pageLoading}
        />
      )}
    </div>
  );
};
