import React, { useCallback, useEffect, useState } from 'react';

import { FetchError } from 'components';
import { useLocationContext } from 'Location';

import {
  dismissNotificationFetch,
  useNotificationContext
} from './notification.context';
import { NotificationEntry } from './NotificationEntry';
import { NotificationsLoadingPlaceholder } from './NotificationsLoadingPlaceholder';
import { NotificationActionTypes } from './types';
import { getNotificationPriority, isResidentPanicAlarm } from './utils';

import styles from './NotificationList.module.css';

export const NotificationList: React.FunctionComponent = () => {
  const [localList, setLocalList] = useState<JSX.Element[]>([]);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);

  const [
    { notifications, fetchLoading, dismissLoading, fetchError },
    dispatch
  ] = useNotificationContext();

  const {
    location: {
      ward: wardId,
      facility: { id: facilityId }
    },
    label
  } = useLocationContext();

  const dismiss = useCallback(
    async (id: string) => {
      await dismissNotificationFetch(dispatch, {
        notificationId: id,
        facilityId,
        wardId
      });
    },
    [dispatch, facilityId, wardId]
  );

  const handleCloseError = useCallback(() => {
    dispatch({ type: NotificationActionTypes.SET_REFETCH });
  }, [dispatch]);

  useEffect(() => {
    if (fetchLoading || dismissLoading || fetchError) {
      return undefined;
    }

    const notificationsList = notifications.map(notification => {
      const priority = getNotificationPriority(notification.colorCode);
      const title = isResidentPanicAlarm(notification)
        ? `${label} Alarm`
        : notification.title;

      return (
        <NotificationEntry
          key={notification.id}
          notification={notification}
          onDismiss={dismiss}
          disabledAction={dismissLoading}
          title={title}
          timestamp={notification.created}
          customStyle="global"
          priority={priority}
        />
      );
    });

    setLocalList(notificationsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissLoading, fetchError, fetchLoading, notifications, label]);

  useEffect(() => {
    setIsDataAvailable(Boolean(notifications.length > 0 && wardId));
  }, [notifications, wardId]);

  return (
    <div
      role="region"
      aria-label="alarms-dropdown"
      className={styles.notificationsContainer}
    >
      {fetchError && (
        <div className={styles.errorWrapper}>
          <FetchError
            error={fetchError}
            closable={true}
            onClose={handleCloseError}
          />
        </div>
      )}
      {!fetchError && fetchLoading && <NotificationsLoadingPlaceholder />}
      {!fetchLoading && !fetchError && (
        <>
          {isDataAvailable ? (
            <ul className={styles.notificationsList}>{localList}</ul>
          ) : (
            <p className={styles.emptyPlaceholder}>
              There are no open notifications at this time.
            </p>
          )}
        </>
      )}
    </div>
  );
};
