import moment from 'moment';
import React from 'react';

import { ReactComponent as Bath } from 'icons/last-bath.svg';
import { LastBath } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './LastBathCard.module.css';

export const LastBathCard: React.FunctionComponent<{
  lastBath: LastBath | undefined;
  onClick: () => void;
}> = ({ lastBath, onClick }) => {
  const { value, timestamp, unit } = lastBath || {};
  const hasReading = value && timestamp;
  let reading;
  let actualUnit: string | undefined = unit;
  if (hasReading) {
    const readingDate = moment(timestamp);
    const currentDateWithTimezone = moment().utcOffset(timestamp as string);
    const daysAgo = currentDateWithTimezone
      .startOf('day')
      .diff(readingDate.startOf('day'), 'days');

    reading = daysAgo === 0 ? 'Today' : daysAgo;
    actualUnit =
      daysAgo > 1 ? 'days ago' : daysAgo === 1 ? 'day ago' : undefined;
  }

  return (
    <div className={style.lastBath} onClick={onClick}>
      <ActivityInfoCard reading={reading} unit={actualUnit} type="lastBath">
        <Bath />
      </ActivityInfoCard>
    </div>
  );
};
