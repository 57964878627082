import { AxiosResponse } from 'axios';
import { axiosWrapper } from 'utils';

export const dismissNotification = (
  alarmId: string,
  facilityId: string,
  wardId: string
): Promise<AxiosResponse<{ id: string }>> =>
  axiosWrapper(
    `/facilities/${facilityId}/wards/${wardId}/alarms/${alarmId}`,
    'DELETE',
    null
  );
