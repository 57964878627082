import React, { useEffect, useRef } from 'react';

import { TextStatus } from 'components';
import { Device } from 'Settings/types';
import { deviceTypes } from 'utils';

import stylesTable from 'components/Table/Table.module.css';

export const DeviceTableRow: React.FunctionComponent<{
  values: Device;
  onClickDevice: (id: string) => void;
  isSelected: boolean;
  facilityType: string;
}> = ({ values, isSelected, onClickDevice, facilityType }) => {
  const {
    id,
    mac,
    name,
    type,
    caregiver,
    resident,
    room,
    isActive,
    description
  } = values;
  let assignedTo;

  switch (type) {
    case 'caregiver_badge':
    case 'caregiver_phone':
      if (caregiver) {
        assignedTo = caregiver.name;
      }
      break;
    case 'iot_gateway':
      if (room) {
        const wardName = room.ward
          ? room.ward.name
            ? room.ward.name
            : ''
          : '';
        const line = wardName ? ' - ' : '';
        assignedTo = wardName + line + 'Room - ' + room.number;
      }
      break;
    case 'resident_badge':
    case 'resident_bracelet':
      if (resident) {
        assignedTo = resident.name;
      }
      break;
    case 'asset_tracker':
      if (description) {
        assignedTo = description;
      }
      break;
    default:
      assignedTo = 'N/A';
      break;
  }
  const handleClick = () => {
    onClickDevice(id);
  };

  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  const row = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && row.current) {
      row.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={row} className={rowClass} role="row" onClick={handleClick}>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2.5 }}
        role="cell"
      >
        {mac}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1.8 }}
        role="cell"
      >
        {name}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1.8 }}
        role="cell"
      >
        {deviceTypes(facilityType)[type]}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        {assignedTo || 'N/A'}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        <TextStatus status={isActive} />
      </div>
    </div>
  );
};
