import { DropdownOption } from 'components/types';
import { ERROR_COLOR, GREEN_COLOR } from 'consts';

export const activeOption: DropdownOption = {
  value: true,
  label: 'Active',
  color: GREEN_COLOR
};

export const inactiveOption: DropdownOption = {
  value: false,
  label: 'Inactive',
  color: ERROR_COLOR
};
