import classnames from 'classnames';
import { MomentInput } from 'moment';
import React, { useEffect, useState } from 'react';

import { RoundCrossButtonRed, TimePassed } from 'components';
import { AlarmsPriority, AlarmTypes, NotificationDetail } from './types';
import { formatNotificationUserData } from './utils';

import { HomeCssClasses, PriorityCssClasses } from 'Home/types';
import { getPriorityStyle } from 'Home/utils/home-view.util';

import styles from './NotificationEntry.module.css';

export const NotificationEntry: React.FunctionComponent<{
  notification: NotificationDetail;
  disabledAction: boolean;
  onDismiss: (id: string) => void;
  title: string;
  customStyle?: string;
  timestamp: MomentInput;
  priority?: AlarmsPriority;
}> = ({
  notification,
  disabledAction,
  onDismiss,
  title,
  customStyle = '',
  timestamp,
  priority = AlarmsPriority.PRIORITY_LOW
}) => {
  const [priorityCssClass, setPriorityCssClass] = useState<PriorityCssClasses>(
    PriorityCssClasses.LOW
  );
  const { id, type } = notification;

  useEffect(() => {
    setPriorityCssClass(getPriorityStyle(priority));
  }, [priority]);

  const handleDismissMotification = () => {
    onDismiss(id);
  };

  return (
    <li
      className={classnames(
        styles.notificationEntry,
        styles[priorityCssClass],
        customStyle && styles[customStyle]
      )}
      key={id}
    >
      {customStyle === HomeCssClasses.ALARMS_ONLY && (
        <div className={styles.priority}>
          <span className={styles[priorityCssClass]}>{priority}</span>
          <TimePassed
            timestamp={timestamp}
            cssClasses={[styles.timeAlarmsOnly]}
          />
        </div>
      )}
      <div className={classnames(styles.details, styles[priorityCssClass])}>
        <span className={styles.notificationTitle}>{title}</span>
        <span className={styles.userInfo}>
          {formatNotificationUserData(notification)}
        </span>
      </div>
      <div
        className={classnames(styles.actions, {
          [styles.roomAlarm]: type === AlarmTypes.ROOM_ALARM
        })}
      >
        {customStyle !== HomeCssClasses.ALARMS_ONLY && (
          <TimePassed timestamp={timestamp} cssClasses={[styles.timeRegular]} />
        )}
        {type !== AlarmTypes.ROOM_ALARM && (
          <RoundCrossButtonRed
            small={true}
            onClick={handleDismissMotification}
            disabled={disabledAction}
            testId="dismiss-button"
          />
        )}
      </div>
    </li>
  );
};
