import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import { useFetcher } from 'hooks';
import { Device, Facility, getDevicesByFacility } from 'Settings';
import {
  formatSelectedValues,
  sortByKey,
  stripIdForDropdownValue
} from 'utils';

import formStyles from 'styles/form.module.css';

export const DeviceFetchDropdown: React.FunctionComponent<{
  name: string;
  value: any;
  label: string;
  onChange: (name: any, value: any, revalidate?: boolean) => void;
  register: (param: any) => void;
  hasError: boolean;
  facility: Facility;
  changeStatus: (status: any) => void;
  filterFunction: (devices: Device[], facilityType: string) => Device[];
  required?: boolean;
  isVisible?: boolean;
  cssClass?: string;
  defaultValue?: any;
  sortBy?: string;
  filterSelectedDevices?: any;
}> = ({
  name,
  value,
  label,
  facility,
  hasError,
  changeStatus,
  onChange,
  register,
  required,
  filterFunction,
  defaultValue,
  isVisible = true,
  cssClass = formStyles.selectGroupQuarter,
  sortBy,
  children,
  filterSelectedDevices
}) => {
  const getDevicesMemo = useMemo(() => getDevicesByFacility(facility.id), [
    facility.id
  ]);
  const { data: allDevices, loading, error } = useFetcher<Device[]>(
    getDevicesMemo,
    []
  );

  const getAvailableDeviceOptions = () => {
    const filteredDevices: Device[] = filterFunction
      ? filterFunction(allDevices, facility.type)
      : allDevices;

    const notSelectedDevices: Device[] = filterSelectedDevices
      ? filterSelectedDevices(filteredDevices, value?.id)
      : filteredDevices;

    let options = notSelectedDevices.map(device => ({
      value: device.id,
      label: sortBy ? device[sortBy] : device.name
    }));

    if (defaultValue && value && defaultValue.id === value.id) {
      const correspondingValue = notSelectedDevices.find(
        ({ id }) => id === defaultValue.id
      );
      if (!correspondingValue) {
        options = [
          ...options,
          { value: defaultValue.id, label: defaultValue.name }
        ];
      }
    }
    return sortByKey(options, 'label');
  };

  const deviceOptions = getAvailableDeviceOptions();

  useEffect(() => {
    changeStatus({ loading, error });
  }, [error, loading, changeStatus]);

  const handleChange = (
    fieldName: string,
    selected: string,
    revalidate: boolean
  ) => {
    const formattedValue = formatSelectedValues(selected, deviceOptions);
    onChange(fieldName, formattedValue, revalidate);
  };

  const style = isVisible ? { display: 'flex' } : { display: 'none' };

  return (
    <div className={cssClass} style={style}>
      <Select
        name={name}
        label={label}
        options={deviceOptions}
        value={stripIdForDropdownValue(value)}
        register={register}
        hasError={hasError}
        onChange={handleChange}
        required={required}
      >
        {children}
      </Select>
    </div>
  );
};
