import { useEffect, useState } from 'react';

import { Idn } from 'Settings/types';

export const useParentIdn = (
  parentIdnId: string,
  idns: Idn[],
  initialValue: string = ''
) => {
  const [idnNumber, setIdnNumber] = useState(initialValue);

  useEffect(() => {
    if (!parentIdnId) {
      setIdnNumber('');
      return;
    }

    const currentIdn = idns.find(idn => idn.id === parentIdnId);
    setIdnNumber(currentIdn ? currentIdn.number : '');
  }, [parentIdnId, idns]);

  return { idnNumber };
};
