import { LastMessage, Participant } from 'Communication';
import { LoginId } from 'types';
import { ChatContact } from '.';

export enum ConversationTypes {
  CAREGIVER = 'caregiver',
  CARE_TEAM = 'care_team',
  RELATED = 'related_contact',
  AD_HOC = 'ad_hoc_group',
  UNKNOWN = 'unknown'
}

export type ConversationType =
  | ConversationTypes.CAREGIVER
  | ConversationTypes.CARE_TEAM
  | ConversationTypes.RELATED
  | ConversationTypes.AD_HOC
  | ConversationTypes.UNKNOWN;

export interface MessagingResident {
  id: string;
  name: string;
  room: {
    id: string;
    number: string;
    ward: {
      id: string;
      name: string;
    };
  };
}

export interface MessagingHistoryConversation {
  id: string;
  chatRoomType: ConversationType;
  resident?: MessagingResident;
  participants: Participant[];
  hasUnreadMessages: boolean;
  loginSenderId: LoginId;
  lastMessage?: LastMessage;
  description: string;
}

export const isCareTeamConversation = (
  conversation?: MessagingHistoryConversation,
  chatContact?: ChatContact
) =>
  conversation?.chatRoomType === ConversationTypes.CARE_TEAM ||
  chatContact?.chatRoomType === ConversationTypes.CARE_TEAM;

export const isRelatedContactConversation = (
  conversation?: MessagingHistoryConversation,
  chatContact?: ChatContact
) =>
  conversation?.chatRoomType === ConversationTypes.RELATED ||
  chatContact?.chatRoomType === ConversationTypes.RELATED;

export const isCaregiverConversation = (
  conversation?: MessagingHistoryConversation,
  chatContact?: ChatContact
) =>
  conversation?.chatRoomType === ConversationTypes.CAREGIVER ||
  chatContact?.chatRoomType === ConversationTypes.CAREGIVER;

export const isAdHocConversation = (
  conversation?: MessagingHistoryConversation,
  chatContact?: ChatContact
) =>
  conversation?.chatRoomType === ConversationTypes.AD_HOC ||
  chatContact?.chatRoomType === ConversationTypes.AD_HOC;
