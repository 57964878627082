export interface ErrorMessage {
  statusCode: number;
  type?: string; // room, device, caregiver etc.
  code?: string; // error code returned by API
  message: string;
}

export const isErrorMessage = (object: any): object is ErrorMessage => {
  if (!object || typeof object !== 'object') {
    return false;
  }
  return 'statusCode' in object && 'message' in object;
};
