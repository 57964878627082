import React from 'react';

import {
  BackToggleButton,
  RoundCheckButton,
  RoundCrossButton
} from 'components';

import style from './AddReadingHeader.module.css';

export const AddReadingHeader: React.FunctionComponent<{
  title: string;
  onSave: (formData: any) => void;
  onCancel: () => void;
  savingDisabled: boolean;
}> = ({ title, onSave, onCancel, savingDisabled }) => {
  return (
    <header className={style.header}>
      <BackToggleButton title={title} onClick={onCancel} />
      <div className={style.actionButtons}>
        <RoundCrossButton onClick={onCancel} />
        <RoundCheckButton onClick={onSave} disabled={savingDisabled} />
      </div>
    </header>
  );
};
