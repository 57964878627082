import classnames from 'classnames';
import React, { useMemo } from 'react';

import { caregiverStatusMap } from 'Caregivers';
import { ProfilePlaceholder } from 'components';
import { formatName } from 'utils';

import {
  ConversationTypes,
  isAdHocConversation,
  isCareTeamConversation
} from './types';
import { ChatContact } from './types/chat.type';

import styles from './SearchContactEntry.module.css';

const getResidentDetails = (resident: any) => {
  if (!resident) {
    return undefined;
  }
  const { room, name } = resident;
  const formattedName = formatName(name);

  if (!room) {
    return formattedName;
  }
  return `${formattedName} - ${room.ward.name} - ${room.number}`;
};

export const SearchContactEntry: React.FunctionComponent<{
  data: ChatContact;
  isSelected: boolean;
  onSelect: (data: any) => void;
}> = ({ data, isSelected, onSelect }) => {
  const {
    name,
    role,
    status,
    resident,
    photoUrl,
    relationship,
    chatRoomType
  } = data;

  const formattedName = useMemo(
    () =>
      chatRoomType === ConversationTypes.RELATED ? formatName(name) : name,
    [name, chatRoomType]
  );

  const avatarBorder =
    status && chatRoomType !== ConversationTypes.CARE_TEAM
      ? caregiverStatusMap[status]
      : undefined;
  const residentInfo = getResidentDetails(resident);

  const onClick = () => {
    onSelect(data);
  };

  const customStyle =
    isCareTeamConversation(undefined, data) ||
    isAdHocConversation(undefined, data)
      ? styles.groupChat
      : undefined;

  return (
    <li
      className={classnames(styles.entry, {
        [styles.selected]: isSelected
      })}
      onClick={onClick}
    >
      <ProfilePlaceholder
        name={name}
        type={avatarBorder}
        photoUrl={photoUrl}
        customClass={customStyle}
      />
      <div className={styles.details}>
        <span className={styles.name}>{formattedName}</span>

        {role && (
          <div className={styles.additionalInfo}>
            <span className={styles.accent}>Role:</span>
            <span className={styles.labelInfo}>{role}</span>
          </div>
        )}
        {relationship && (
          <>
            <div className={styles.additionalInfo}>
              <span className={styles.accent}>For:</span>
              <span className={styles.labelInfo}>
                {residentInfo || 'Multiple Residents'}
              </span>
            </div>
            <div className={styles.additionalInfo}>
              <span className={styles.accent}>Relationship:</span>
              <span className={styles.labelInfo}>{relationship}</span>
            </div>
          </>
        )}
      </div>
    </li>
  );
};
