import moment from 'moment';
import React, { useState } from 'react';

import { useInterval } from 'hooks';
import { DeviceLocation, useLocationState } from 'Location';
import { formatLogDate } from 'Residents';
import { timeSince } from 'utils';

import { HistoryLogEntry } from './HistoryLogEntry';
import { HistoryValue } from './HistoryValue';

import styles from './HistoryLogEntry.module.css';

export const ResidentCurrentLocationLog: React.FunctionComponent<{
  lastKnownLocation: DeviceLocation;
}> = ({ lastKnownLocation }) => {
  const [, setRerender] = useState(Math.random());

  const { facility } = useLocationState();

  useInterval(() => {
    setRerender(Math.random());
  }, 500);

  const { timestamp, room } = lastKnownLocation;
  const { timestamp: time, date } = formatLogDate(
    facility.timezone
      ? moment.tz(facility.timezone).toISOString(true)
      : moment().toISOString(true)
  );

  return (
    <HistoryLogEntry timestamp={time} date={date}>
      {room && (
        <div className={styles.additionalInfo}>
          <span className={styles.topSpan}>{room.ward.name}</span>
          <span className={styles.bottomSpan}>{room.number}</span>
        </div>
      )}
      <HistoryValue value={timeSince(timestamp)} />
    </HistoryLogEntry>
  );
};
