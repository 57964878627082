import { axiosWrapper } from 'utils';

export const addVitalReading = (
  facilityId: string,
  residentId: string,
  type: string,
  body: any
) => async () => {
  const response = await axiosWrapper(
    `/facilities/${facilityId}/residents/${residentId}/vitals/${type}`,
    'POST',
    null,
    body
  );

  return response.data;
};
