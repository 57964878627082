import moment from 'moment';

import { DailySteps, emptyWeek } from 'Residents';

const convertReadings = (day: string, readings: DailySteps[]) => {
  const stepsReadings = readings.find(
    entry => moment(entry.timestamp).format('ddd') === day
  );

  const steps = stepsReadings ? Number(stepsReadings.value.count) : 0;

  return {
    steps,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toWeekData = (data: DailySteps[]) =>
  emptyWeek.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
