import { axiosWrapper } from 'utils';

import { Caregiver } from 'Settings/types';

export const getCaregiverDetails = (
  caregiverId: string,
  facilityId: string
) => async () => {
  const response = await axiosWrapper<Caregiver>(
    `/facilities/${facilityId}/caregivers/${caregiverId}`
  );
  return response.data;
};
