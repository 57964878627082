import React, { useRef, useState } from 'react';

import { Modal } from 'components';
import { useModal } from 'hooks';
import {
  getLastWeekHistoryFilters,
  getThisMonthHistoryFilters,
  getThisWeekHistoryFilters,
  HistoryLabel,
  SittingHours
} from 'Residents';

import { SittingHoursCard } from './Cards';
import { AddActivityReading } from './History/AddReadings/AddActivityReading';
import { SittingHoursReadingForm } from './History/AddReadings/SittingHoursReadingForm';
import { SittingHoursHistory } from './History/SittingHoursHistory';

import style from 'Residents/History/History.module.css';

export const SittingHoursDetails: React.FunctionComponent<{
  sittingHours: SittingHours;
  facilityId: string;
  residentId: string;
  onReadingAdded: () => void;
}> = ({ sittingHours, facilityId, residentId, onReadingAdded }) => {
  const [historyFilters, setHistoryFilters] = useState(
    getThisWeekHistoryFilters()
  );
  const hasNewReading = useRef(false);
  const { isShowing: isHistoryOpen, toggle: toggleHistoryView } = useModal();
  const { isShowing: isAddFormOpen, toggle: toggleAddReading } = useModal();

  const onAddReading = () => {
    toggleAddReading();
    hasNewReading.current = true;
    // update history filters to This Week
    setHistoryFilters(getThisWeekHistoryFilters());
  };

  const onCloseHistoryView = () => {
    if (hasNewReading.current) {
      // let parent know to refetch on add
      onReadingAdded();
    }
    setHistoryFilters(getThisWeekHistoryFilters());
    toggleHistoryView();
  };

  const changeHistoryFilter = (historyLabel: HistoryLabel) => {
    switch (historyLabel) {
      case 'This Week':
        setHistoryFilters(getThisWeekHistoryFilters());
        break;
      case 'Last Week':
        setHistoryFilters(getLastWeekHistoryFilters());
        break;
      case 'This Month':
        setHistoryFilters(getThisMonthHistoryFilters());
        break;
    }
  };

  const onAdd = () => {
    if (!isAddFormOpen) {
      toggleAddReading();
    }
  };

  return (
    <>
      <SittingHoursCard
        onClick={toggleHistoryView}
        sittingHours={sittingHours}
      />
      <Modal isShowing={isHistoryOpen} toggle={toggleHistoryView}>
        <div className={style.modalBackdrop} id="HistoryModal">
          <div className={style.modalContent}>
            <SittingHoursHistory
              onClose={onCloseHistoryView}
              onAdd={onAdd}
              historyFilters={historyFilters}
              facilityId={facilityId}
              residentId={residentId}
              changeHistoryFilter={changeHistoryFilter}
            />
          </div>
        </div>
      </Modal>
      <AddActivityReading
        isOpen={isAddFormOpen}
        onCancel={toggleAddReading}
        onSave={onAddReading}
        facilityId={facilityId}
        residentId={residentId}
        FormComponent={SittingHoursReadingForm}
      />
    </>
  );
};
