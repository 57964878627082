import classnames from 'classnames';
import React from 'react';

import styles from './Snackbar.module.css';

import { ReactComponent as CrossIcon } from 'icons/crossmark.svg';

export const Snackbar: React.FunctionComponent<{
  message: string;
  onClose: () => void;
  isOpen: boolean;
  type?: 'error' | 'succes';
  reloadAction?: () => void;
}> = ({ type = 'error', message, isOpen, onClose, reloadAction }) => (
  <div
    className={classnames(styles.snackbarContainer, {
      [styles.snackbar]: true,
      [styles[type]]: true,
      [styles.visible]: isOpen
    })}
  >
    <h5 className={styles.message}>{message}</h5>
    {reloadAction && (
      <button className={styles.reloadButton} onClick={reloadAction}>
        Reload
      </button>
    )}
    <CrossIcon onClick={onClose} className={styles.closeButton} />
  </div>
);
