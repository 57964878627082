import React, { useEffect, useState } from 'react';

import { Snackbar } from 'components';
import { usePoster } from 'hooks';
import { giveUserFacilityAccess, removeUserFacilityAccess } from 'Settings';

import { UserFacilitiesAccessListEntry } from './UserFacilitiesAccessListEntry';
import { UserFacilitesAccessListHeader } from './UserFacilitiesAccessListHeader';

import style from './UserFacilitiesAccessList.module.css';

export const UserFacilitiesAccessList: React.FunctionComponent<{
  facilitiesAccessList: Array<{ name: string; id: string; hasAccess: boolean }>;
  userId: string;
  placeholder: string;
  onFacilityAccessToggle: (value: boolean, facilityIds: string[]) => void;
}> = ({
  facilitiesAccessList,
  userId,
  placeholder,
  onFacilityAccessToggle
}) => {
  const [isErrorShowing, setIsErrorShowing] = useState(false);

  const { setAction, loading: accessLoading, error: accessError } = usePoster();

  const headerStatus = facilitiesAccessList.every(
    facility => facility.hasAccess === true
  );

  const dismissError = () => setIsErrorShowing(false);

  const handleToggle = (value: boolean, facilityId: string) => {
    onFacilityAccessToggle(value, [facilityId]);
    setAction(
      value
        ? giveUserFacilityAccess(userId, facilityId)
        : removeUserFacilityAccess(userId, facilityId)
    );
  };

  const onHeaderToggle = (value: boolean) => {
    facilitiesAccessList.forEach(({ id }) =>
      setAction(
        value
          ? giveUserFacilityAccess(userId, id)
          : removeUserFacilityAccess(userId, id)
      )
    );
    const facilitiesAccessListIds = facilitiesAccessList.map(({ id }) => id);
    onFacilityAccessToggle(value, facilitiesAccessListIds);
  };

  useEffect(() => setIsErrorShowing(!!accessError), [accessError]);

  const list = facilitiesAccessList.map(facilityAccess => {
    return (
      <UserFacilitiesAccessListEntry
        facilityAccess={facilityAccess}
        key={facilityAccess.id}
        loading={accessLoading}
        selectedUser={{ id: userId }}
        handleToggle={handleToggle}
      />
    );
  });

  if (facilitiesAccessList.length === 0) {
    return <div className={style.emptyPlaceholder}>{placeholder}</div>;
  }

  return (
    <>
      <UserFacilitesAccessListHeader
        onToggle={onHeaderToggle}
        loading={accessLoading}
        headerStatus={headerStatus}
        selectedUser={{ id: userId }}
      />
      <ul className={style.list}>{list}</ul>
      <Snackbar
        message={
          'An error has occured  while trying to modify user access. Please try again.'
        }
        isOpen={isErrorShowing}
        onClose={dismissError}
      />
    </>
  );
};
