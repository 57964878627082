import { axiosWrapper } from 'utils';

import { Device } from 'Settings/types';

export const getDeviceDetails = (
  deviceId: string,
  facilityId: string
) => async () => {
  const response = await axiosWrapper<Device>(
    `/facilities/${facilityId}/devices/${deviceId}`
  );
  return response.data;
};
