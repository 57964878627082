import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { SOCKET_SUBSCRIPTION_ACCESS } from 'Auth';
import { useWebSocket } from 'hooks';
import { useLocationContext } from 'Location';
import { ChatId, LoginId } from 'types';
import { SOCKET_UPDATES_URL } from 'utils';

import {
  ACTION_TYPES,
  RtlsUpdate,
  SOCKET_MESSAGE_TYPE,
  SocketActions,
  SocketMessageTypes
} from './types';

const SOCKET_UPDATE_ACTIONS: { [key in SOCKET_MESSAGE_TYPE]: string } = {
  [SocketMessageTypes.ALARM_VIEW]: SocketActions.SET_ALARM_UPDATE,
  [SocketMessageTypes.ALARM_DISMISSAL_VIEW]:
    SocketActions.SET_ALARM_DISMISS_UPDATE,
  [SocketMessageTypes.LOCATION_OUTCOME_VIEW]: SocketActions.SET_RTLS_UPDATE,
  [SocketMessageTypes.MESSAGE_VIEW]: SocketActions.SET_COM_UPDATE
};

export type Action =
  | { type: SocketActions.SET_ALARM_UPDATE; payload: any }
  | { type: SocketActions.SET_ALARM_DISMISS_UPDATE; payload: any }
  | { type: SocketActions.SET_RTLS_UPDATE; payload: any }
  | { type: SocketActions.SET_COM_UPDATE; payload: any }
  | { type: SocketActions.SET_CONNECTION_STATE; payload: boolean }
  | { type: SocketActions.RESET_SOCKET };

export enum ComUpdateTypes {
  TEXT_MESSAGE = 'TEXT_MESSAGE',
  FILE_MESSAGE = 'FILE_MESSAGE',
  CALL_MESSAGE = 'CALL_MESSAGE',
  CALL_STARTED_MESSAGE = 'CALL_STARTED_MESSAGE',
  CALL_ENDED_MESSAGE = 'CALL_ENDED_MESSAGE',
  CALL_DISMISS_MESSAGE = 'CALL_DISMISS_MESSAGE',
  MESSAGE_SEEN_MESSAGE = 'MESSAGE_SEEN_MESSAGE',
  PARTICIPANT_ADDED_MESSAGE = 'PARTICIPANT_ADDED_MESSAGE',
  PARTICIPANT_DELETED_MESSAGE = 'PARTICIPANT_DELETED_MESSAGE',
  CALL_INITIATION_MESSAGE = 'CALL_INITIATION_MESSAGE',
  CALL_ACCEPTED_MESSAGE = 'CALL_ACCEPTED_MESSAGE',
  JOIN_CALL_MESSAGE = 'JOIN_CALL_MESSAGE',
  LEAVE_CALL_MESSAGE = 'LEAVE_CALL_MESSAGE',
  CHAT_ROOM_RENAMED_MESSAGE = 'CHAT_ROOM_RENAMED_MESSAGE'
}

export type ComMessageType =
  | ComUpdateTypes.CALL_ACCEPTED_MESSAGE
  | ComUpdateTypes.CALL_DISMISS_MESSAGE
  | ComUpdateTypes.CALL_ENDED_MESSAGE
  | ComUpdateTypes.CALL_INITIATION_MESSAGE
  | ComUpdateTypes.CALL_MESSAGE
  | ComUpdateTypes.CALL_STARTED_MESSAGE
  | ComUpdateTypes.FILE_MESSAGE
  | ComUpdateTypes.JOIN_CALL_MESSAGE
  | ComUpdateTypes.LEAVE_CALL_MESSAGE
  | ComUpdateTypes.MESSAGE_SEEN_MESSAGE
  | ComUpdateTypes.PARTICIPANT_ADDED_MESSAGE
  | ComUpdateTypes.CHAT_ROOM_RENAMED_MESSAGE
  | ComUpdateTypes.PARTICIPANT_DELETED_MESSAGE
  | ComUpdateTypes.TEXT_MESSAGE;

export interface ComMessageUpdate {
  [key: string]: any;
  chatRoomId: ChatId;
  chatRoomType: 'CAREGIVER' | 'AD_HOC' | 'CARE_TEAM' | 'RELATED_CONTACT';
  loginSenderId: LoginId;
  receivers: LoginId[];
  messageType: ComMessageType;
}

export interface State {
  isConnectionOpen: boolean;
  alarmUpdate: any;
  alarmDismissal: any;
  rtlsUpdate: RtlsUpdate;
  comUpdate: ComMessageUpdate | null;
}
export type Dispatch = (action: Action) => void;
export interface DispatchActions {
  dispatch: Dispatch;
  [key: string]: (param?: any) => void;
}

const SocketStateContext = createContext<State | undefined>(undefined);
const SocketDispatchContext = createContext<DispatchActions | undefined>(
  undefined
);

const initialSocketState = {
  alarmUpdate: null,
  alarmDismissal: null,
  rtlsUpdate: null,
  comUpdate: null,
  isConnectionOpen: false
} as State;

const socketReducer = (state: State, action: Action) => {
  switch (action.type) {
    case SocketActions.SET_COM_UPDATE: {
      return {
        ...initialSocketState,
        isConnectionOpen: state.isConnectionOpen,
        comUpdate: { ...action.payload }
      };
    }
    case SocketActions.SET_ALARM_UPDATE: {
      return {
        ...initialSocketState,
        isConnectionOpen: state.isConnectionOpen,
        alarmUpdate: { ...action.payload }
      };
    }
    case SocketActions.SET_RTLS_UPDATE: {
      return {
        ...initialSocketState,
        isConnectionOpen: state.isConnectionOpen,
        rtlsUpdate: { ...action.payload }
      };
    }
    case SocketActions.SET_CONNECTION_STATE: {
      return {
        ...initialSocketState,
        isConnectionOpen: action.payload
      };
    }
    case SocketActions.SET_ALARM_DISMISS_UPDATE: {
      return {
        ...initialSocketState,
        isConnectionOpen: state.isConnectionOpen,
        alarmDismissal: { ...action.payload }
      };
    }
    case SocketActions.RESET_SOCKET: {
      return {
        isConnectionOpen: state.isConnectionOpen,
        alarmUpdate: null,
        alarmDismissal: null,
        rtlsUpdate: null,
        comUpdate: null
      };
    }
    default:
      return initialSocketState;
  }
};

export const SocketProvider: (props: {
  children: React.ReactNode;
  initialState?: State;
}) => any = ({ children, initialState = initialSocketState }) => {
  const [state, dispatch] = useReducer(socketReducer, initialState);

  const { data, subscribeToUpdates, isConnectionOpen, close } = useWebSocket(
    SOCKET_UPDATES_URL
  );

  const {
    location: {
      facility: { id: facilityId }
    },
    dispatch: locationDispatch
  } = useLocationContext();

  useEffect(() => {
    dispatch({
      type: SocketActions.SET_CONNECTION_STATE,
      payload: isConnectionOpen
    });
  }, [isConnectionOpen, dispatch]);

  useEffect(() => {
    if (isConnectionOpen) {
      const { alarm, com } = SOCKET_SUBSCRIPTION_ACCESS;
      subscribeToUpdates(alarm.key, {}, alarm.roles);
      subscribeToUpdates(com.key, {}, com.roles);
    }
  }, [isConnectionOpen, subscribeToUpdates]);

  useEffect(() => {
    if (isConnectionOpen && facilityId) {
      const { rtls } = SOCKET_SUBSCRIPTION_ACCESS;
      subscribeToUpdates(rtls.key, { facilityId }, rtls.roles);
    }
  }, [isConnectionOpen, facilityId, subscribeToUpdates]);

  useEffect(() => {
    const isAcceptedType =
      data && Object.keys(SOCKET_UPDATE_ACTIONS).includes(data.type);
    if (isAcceptedType) {
      const action = SOCKET_UPDATE_ACTIONS[data.type as SOCKET_MESSAGE_TYPE];
      dispatch({ type: action as ACTION_TYPES, payload: data.value });
    }
  }, [data]);

  useEffect(() => {
    if (state.rtlsUpdate) {
      locationDispatch({
        type: 'UPDATE_DEVICE_LOCATION',
        payload: state.rtlsUpdate
      });
    }
  }, [state.rtlsUpdate, locationDispatch]);

  return (
    <SocketStateContext.Provider value={state}>
      <SocketDispatchContext.Provider value={{ dispatch, close }}>
        {children}
      </SocketDispatchContext.Provider>
    </SocketStateContext.Provider>
  );
};

export const useSocketState = () => {
  const context = useContext(SocketStateContext);
  if (context === undefined) {
    throw new Error('useSocketState must be used within a SocketProvider');
  }

  return context;
};

export const useSocketDispatch = () => {
  const context = useContext(SocketDispatchContext);
  if (context === undefined) {
    throw new Error('useSocketDispatch must be used within a SocketProvider');
  }
  return context;
};

export const useSocketContext = (): [State, DispatchActions] => {
  return [useSocketState(), useSocketDispatch()];
};
