import { useLocationState } from 'Location';
import moment from 'moment';
import React from 'react';
import useForm from 'react-hook-form';

import { AddReadingForm, ReadingInput } from 'Residents';

interface BloodPressureReadingFormData {
  systolicPressure: string;
  diastolicPressure: string;
  heartRate: string;
}

export const BloodPressureReadingForm: React.FunctionComponent<{
  isOpen: boolean;
  onSave: (formData: any) => void;
  onCancel: () => void;
  loading: boolean;
}> = ({ isOpen, onSave, onCancel, loading, children }) => {
  const { selectedWardDetails } = useLocationState();

  const { register, errors, getValues, triggerValidation } = useForm<
    BloodPressureReadingFormData
  >({
    mode: 'onBlur',
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const { systolicPressure, diastolicPressure, heartRate } = getValues();
      const measuredAt = moment().toISOString(true);

      onSave({
        systolicPressure: parseInt(systolicPressure, 10),
        diastolicPressure: parseInt(diastolicPressure, 10),
        heartRate: heartRate ? parseInt(heartRate, 10) : undefined,
        measuredAt,
        type: 'BLOOD_PRESSURE'
      });
    }
  };

  return (
    <AddReadingForm
      isOpen={isOpen}
      onSave={handleSubmit}
      onCancel={onCancel}
      title="Add Blood Pressure Reading"
      savingDisabled={loading}
    >
      <ReadingInput
        name="systolicPressure"
        label="systolic value"
        register={register}
        hasError={!!errors.systolicPressure}
        unit="(mm Hg)"
        validationRules={{ required: true, pattern: /^([0]|[1-9]+[0-9]*)$/ }}
      />
      <ReadingInput
        name="diastolicPressure"
        label="diastolic value"
        register={register}
        hasError={!!errors.diastolicPressure}
        unit="(mm Hg)"
        validationRules={{ required: true, pattern: /^([0]|[1-9]+[0-9]*)$/ }}
      />
      {selectedWardDetails?.services.patientResidentVitals.features.prvHeartRate
        .isEnabled && (
        <ReadingInput
          name="heartRate"
          label="heart rate"
          register={register}
          hasError={!!errors.heartRate}
          validationRules={{ pattern: /^([0]|[1-9]+[0-9]*)$/ }}
          unit="(bpm)"
        />
      )}
      {children}
    </AddReadingForm>
  );
};
