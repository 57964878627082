import classNames from 'classnames';
import React from 'react';

import { Spinner } from 'components';

import styles from './message.module.css';

export const LoadingPlaceholder: React.FunctionComponent<{
  fullHeight?: boolean;
  withTransparency?: boolean;
  customStyles?: string;
}> = ({ fullHeight = true, withTransparency = false, customStyles }) => (
  <div
    className={classNames(
      fullHeight ? styles.loadingContainer : styles.loadingContainerSmall,
      withTransparency && styles.loadingTransparent,
      customStyles
    )}
    data-testid="loading-placeholder"
  >
    <Spinner />
  </div>
);
