import moment from 'moment';
import React from 'react';

import { weekOfMonth } from 'Residents';

import { ChartLabelType } from './ChartLabel.type';

export const XAxisTick: React.FunctionComponent<{
  x?: number;
  y?: number;
  payload?: { value: ChartLabelType };
  color: string;
  allowHighlight: boolean;
  invertColors?: boolean;
}> = ({
  x = 0,
  y = 0,
  color,
  payload = { value: undefined },
  allowHighlight,
  invertColors = false
}) => {
  const today = moment().format('ddd') === payload.value; // this works due to the fact that we define the payload.value time as one of 11 unique possibilities

  const thisWeek = 'Week ' + weekOfMonth(moment()) === payload.value;
  // this works due to the fact that we define the payload.value time as one of 11 unique possibilities

  const isHighlight = allowHighlight && (today || thisWeek); // checks if it is current month or day so that it can be highlighted

  const highlightBackground = invertColors ? color : '#FFF';
  const highlightText = invertColors ? '#FFF' : color;

  return (
    <g transform={`translate(${x},${y})`}>
      {today && allowHighlight && (
        <svg
          width="50px"
          height="18px"
          viewBox="0 0 396.8 204.5"
          x="-25px"
          y="2px"
        >
          <path
            stroke="#FFF"
            fill={highlightBackground}
            d="M291.9,201.3h-187c-54.7,0-99-44.3-99-99v0c0-54.7,44.3-99,99-99h187c54.7,0,99,44.3,99,99v0
    C390.9,157,346.6,201.3,291.9,201.3z"
          />
        </svg>
      )}
      {thisWeek && allowHighlight && (
        <svg
          width="60px"
          height="28px"
          viewBox="0 0 396.8 204.5"
          x="-29px"
          y="-3px"
        >
          <path
            stroke="#FFF"
            fill={highlightBackground}
            d="M291.9,201.3h-187c-54.7,0-99-44.3-99-99v0c0-54.7,44.3-99,99-99h187c54.7,0,99,44.3,99,99v0
    C390.9,157,346.6,201.3,291.9,201.3z"
          />
        </svg>
      )}

      <text
        dy={16}
        textAnchor="middle"
        fontSize={13}
        fill={isHighlight ? highlightText : highlightBackground}
      >
        {payload.value}
      </text>
    </g>
  );
};
