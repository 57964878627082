import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelList,
  Table
} from 'components';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';
import { getHeight } from 'utils';

import { getIdnsByQuery, getPaginatedIdns } from '../actions';
import {
  DEFAULT_SEARCH_TERM,
  IDN_LIST_PLACEHOLDER,
  IDN_SEARCH_INPUT_NAME,
  IDN_TITLE,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH
} from '../constants/index';
import { Idn } from '../types/idn.type';
import { AddIdnForm } from './AddIdnForm';
import { IdnTableRow } from './IdnTableRow';

import stylesModal from 'components/Modal/Modal.module.css';

const IdnListTableColumns: Column[] = [
  { name: 'IDN Name', align: 'left', cols: 3 },
  { name: 'Full Address', align: 'left', cols: 4 },
  { name: 'Facilities', align: 'center', cols: 1 },
  { name: 'Status', align: 'center', cols: 1 }
];

export const IdnList: React.FunctionComponent<{
  selectIdn: (id: string) => void;
  selectedIdn: Idn;
}> = ({ selectedIdn, selectIdn }) => {
  const [idns, setIdns] = useState<Idn[]>([]);
  const [page, setPage] = useState(0);
  const [searchedIdnsPage, setSearchedIdnsPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getIdnsMemo = useMemo(() => getPaginatedIdns(page), [page]);

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getIdnsByQuery(searchTerm, searchedIdnsPage);
    }
  }, [searchTerm, searchedIdnsPage]);

  const {
    data: { items: idnList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Idn[];
    nextPage?: string;
  }>(getIdnsMemo, { items: [] });

  const {
    data: { items: searchedIdns, nextPage: nextPageForSearchedIdns },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{ items: Idn[]; nextPage?: string }>(memoSearchAction, {
    items: []
  });

  const { isShowing, toggle } = useModal();

  useEffect(() => {
    if (idnList.length !== 0) {
      setIdns(prevState => [...prevState, ...idnList]);
    }
  }, [idnList]);

  useEffect(() => {
    if (searchError) {
      return setIdns([]);
    }
    if (searchedIdnsPage > 0) {
      setIdns(prevState => [...prevState, ...searchedIdns]);
    } else {
      setIdns(searchedIdns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedIdns, searchError]);

  const modalClosed = () => {
    setIdns([]);
    if (page === 0) {
      setRefetch();
    } else {
      setPage(0);
    }
    toggle();
  };

  const scrollAction = () => {
    if (!loading && isFetching && idnList.length && nextPage && !isSearching) {
      setPage(parseInt(nextPage, 10));
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedIdns.length &&
      nextPageForSearchedIdns &&
      isSearching
    ) {
      setSearchedIdnsPage(parseInt(nextPageForSearchedIdns, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const onAdd = () => {
    toggle();
  };

  const render = (rowValues: Idn) => {
    return (
      <IdnTableRow
        key={rowValues.id}
        values={rowValues}
        selectIdn={selectIdn}
        isSelected={rowValues.id === selectedIdn.id}
      />
    );
  };

  const isDataAvailable = idns.length > 0;

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedIdnsPage(0);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setIdns([]);
      setPage(0);
      setRefetch();
    }
  };

  return (
    <PanelList
      title={IDN_TITLE}
      onAdd={onAdd}
      hasError={Boolean(error)}
      onSearch={handleSearch}
      searchInputName={IDN_SEARCH_INPUT_NAME}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<Idn>
          columns={IdnListTableColumns}
          rows={idns}
          placeholder={IDN_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}
      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.addFacilityModalContentLeft}>
            <div
              className={stylesModal.facilityModalLeft}
              style={{ marginTop: getHeight('IDNs') }}
            >
              <AddIdnForm toggle={modalClosed} selectIdnId={selectIdn} />
            </div>
          </div>
        </div>
      </Modal>
    </PanelList>
  );
};
