import React, { useMemo } from 'react';

import { useAuthState } from 'Auth';
import {
  caregiverStatusMap,
  StaffMember as ResidentCaregiver
} from 'Caregivers';
import {
  ProfilePlaceholder,
  RoundCrossButtonRed,
  RoundPlusButton,
  WithPermissions
} from 'components';
import { formatName } from 'utils';

import styles from './CaregiverListEntry.module.css';

export const AssignableCaregiver: React.FunctionComponent<{
  data: ResidentCaregiver;
  isAssigned: boolean;
  handleAssignment: (id: string) => void;
}> = ({ data, isAssigned, handleAssignment }) => {
  const { loggedUser } = useAuthState();

  const { id, name, role, status, photoUrl } = data;
  const formattedName = useMemo(() => formatName(name), [name]);

  const type = caregiverStatusMap[status];

  const onClick = () => {
    handleAssignment(id);
  };

  return (
    <li className={styles.unselectableEntry}>
      <ProfilePlaceholder name={name} type={type} photoUrl={photoUrl} />
      <div className={styles.info}>
        <span className={styles.name}>{formattedName}</span>
        <div className={styles.details}>
          <span className={styles.role}>{role}</span>
        </div>
      </div>
      <div className={styles.action}>
        <WithPermissions
          action="resident-dashboard:manageAssignments"
          data={{ loggedUser, caregiverId: id }}
          yes={
            <>
              {isAssigned ? (
                <RoundCrossButtonRed onClick={onClick} size={32} />
              ) : (
                <RoundPlusButton onClick={onClick} />
              )}
            </>
          }
        />
      </div>
    </li>
  );
};
