import React from 'react';

import { AutocompleteSelect } from 'components/AutocompleteSelect/AutocompleteSelect';
import { DropdownOption } from 'components/types';
import styles from './fetcherDropdown.module.css';

import { ReactComponent as CrossIcon } from 'icons/crossmark.svg';

export const FetcherDropdown: React.FunctionComponent<{
  style?: { height: string; width: string };
  error: Error | null;
  loading: boolean;
  options: DropdownOption[];
  onChange: (selected: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  autocomplete?: boolean;
  value?: any;
  hasSideBorders?: boolean;
  placeholder?: string;
  dropdownStyles?: {
    [key: string]: { [key: string]: string };
  };
  isClearable: boolean;
  isDisabled?: boolean;
}> = ({ error, loading, style, ...props }) => {
  return (
    <>
      {error && (
        <div className={styles.errorPlaceholder} style={style}>
          <CrossIcon style={style && { height: style.height }} />
        </div>
      )}
      {loading && !error && (
        <div className={styles.loadingPlaceholderPulse} style={style} />
      )}
      {!error && !loading && <AutocompleteSelect {...props} />}
    </>
  );
};
