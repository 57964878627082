import React from 'react';

import { RoundEditButton, RoundSearchButton } from 'components';

import classnames from 'classnames';
import styles from './Panel.module.css';

export const PanelDetail: React.FunctionComponent<{
  title: string;
  children: React.ReactNode;
  onAction?: () => void;
  hasError?: boolean;
  actionType?: string;
  customStyles?: string;
  childrenStyles?: string;
}> = props => {
  const ActionButton =
    props.actionType === 'search' ? RoundSearchButton : RoundEditButton;

  const panelHeaderStyle = props.hasError
    ? `${styles.panelHeader} ${styles.panelHeaderHidden}`
    : styles.panelHeader;

  return (
    <section
      className={classnames(styles.panel, props.customStyles)}
      id={props.title}
    >
      <header className={panelHeaderStyle}>
        <title className={styles.panelTitle}>{props.title}</title>
        <div className={styles.panelButtonsContainer}>
          {props.onAction && (
            <ActionButton
              onClick={props.onAction}
              testId="action-test-button"
            />
          )}
        </div>
      </header>
      <div className={classnames(styles.panelChildren, props.childrenStyles)}>
        {props.children}
      </div>
    </section>
  );
};
