import React, { createContext, useContext, useReducer } from 'react';

import { Resident } from 'Settings';
type Action =
  | {
      type: 'UPDATE_RESIDENT';
      payload: Resident;
    }
  | {
      type: 'RESET_RESIDENT';
    };
interface State {
  residentDetails: Resident;
}
type Dispatch = (action: Action) => void;

const ResidentStateContext = createContext<State | undefined>(undefined);
const ResidentDispatchContext = createContext<Dispatch | undefined>(undefined);

export const residentReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_RESIDENT': {
      return {
        ...state,
        residentDetails: { ...action.payload }
      };
    }
    case 'RESET_RESIDENT': {
      return {
        ...state,
        residentDetails: {} as Resident
      };
    }
  }
};

export const ResidentProvider: (props: {
  children: React.ReactNode;
}) => any = ({ children }) => {
  const [state, dispatch] = useReducer(residentReducer, {
    residentDetails: {} as Resident
  });
  return (
    <ResidentStateContext.Provider value={state}>
      <ResidentDispatchContext.Provider value={dispatch}>
        {children}
      </ResidentDispatchContext.Provider>
    </ResidentStateContext.Provider>
  );
};

export const useResidentState = () => {
  const context = useContext(ResidentStateContext);
  if (context === undefined) {
    throw new Error('useResidentState must be used within a ResidentProvider');
  }
  return context;
};

export const useResidentDispatch = () => {
  const context = useContext(ResidentDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useResidentDispatch must be used within a ResidentProvider'
    );
  }
  return context;
};

export const useResidentContext = (): [State, Dispatch] => {
  return [useResidentState(), useResidentDispatch()];
};
