export enum SocketMessageTypes {
  ALARM_VIEW = 'AlarmView',
  ALARM_DISMISSAL_VIEW = 'AlarmDismissalView',
  LOCATION_OUTCOME_VIEW = 'LocationOutcomeView',
  MESSAGE_VIEW = 'MessageView'
}

export enum SocketActions {
  SET_ALARM_UPDATE = 'SET_ALARM_UPDATE',
  SET_ALARM_DISMISS_UPDATE = 'SET_ALARM_DISMISS_UPDATE',
  SET_RTLS_UPDATE = 'SET_RTLS_UPDATE',
  SET_COM_UPDATE = 'SET_COM_UPDATE',
  SET_CONNECTION_STATE = 'SET_CONNECTION_STATE',
  RESET_SOCKET = 'RESET_SOCKET'
}

export type SOCKET_MESSAGE_TYPE =
  | SocketMessageTypes.ALARM_VIEW
  | SocketMessageTypes.ALARM_DISMISSAL_VIEW
  | SocketMessageTypes.LOCATION_OUTCOME_VIEW
  | SocketMessageTypes.MESSAGE_VIEW;

export type ACTION_TYPES =
  | SocketActions.SET_ALARM_UPDATE
  | SocketActions.SET_RTLS_UPDATE
  | SocketActions.SET_COM_UPDATE;

export type MESSAGE_TYPE = string;
