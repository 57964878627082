import React from 'react';

import { StatusType } from 'Caregivers';
import { StatusToggle } from 'components';
import { Caregiver } from 'Settings';

import { getChangedStatus } from './actions/getChangedStatus.action';
import { StaffMemberPlaceholder } from './StaffMemberPlaceholder';

import style from './StaffListEntry.module.css';

export const StaffListEntry: React.FunctionComponent<{
  staffMember: Caregiver;
  status: StatusType;
  isActionDisabled: boolean;
  onStatusChange: (caregiverId: string, newStatus: StatusType) => void;
  draggableRef?: any;
  style?: any;
}> = ({
  staffMember,
  status,
  isActionDisabled,
  onStatusChange,
  draggableRef,
  ...props
}) => {
  const handleStatusToggle = (currentStatus: StatusType) => {
    const newStatus = getChangedStatus(currentStatus);
    onStatusChange(staffMember.id, newStatus);
  };

  return (
    <li className={style.entry} ref={draggableRef} {...props}>
      <StaffMemberPlaceholder staffMember={staffMember} status={status}>
        {!isActionDisabled && (
          <StatusToggle
            id={staffMember.id}
            status={status}
            onStatusChange={handleStatusToggle}
          />
        )}
      </StaffMemberPlaceholder>
    </li>
  );
};
