import classnames from 'classnames';
import {
  ChatContact,
  ChatRoom,
  ConversationTypes,
  IMissedCallCount,
  IMissedMessage
} from 'Communication';
import { FetchError, LoadingPlaceholder, PanelHeader } from 'components';
import { RelatedContactStatuses } from 'consts';
import { useFetcher } from 'hooks';
import { useLabelState } from 'Location';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useResidentState } from 'Residents';
import { getResidentContacts, ResidentContact } from 'Settings';
import { getCallCount } from 'utils';

import { RelatedContactListEntry } from './RelatedContactListEntry';

import style from './RelatedContactsList.module.css';

const DEFAULT_NO_DATA = 'No related contact information available';

interface IRelatedContactLisProps {
  onContactSelect: (contact: ChatContact) => void;
  selectedContact: ChatContact | undefined;
  relatedChatRooms: ChatRoom[];
  fetching: boolean;
  externalError: Error | null;
  missedMessages: IMissedMessage[];
  missedCalls: IMissedCallCount[];
}

export const RelatedContactsList: React.FunctionComponent<IRelatedContactLisProps> = ({
  onContactSelect,
  selectedContact,
  relatedChatRooms,
  fetching,
  externalError,
  missedCalls,
  missedMessages
}) => {
  const [contentList, setContentList] = useState<JSX.Element[]>([]);
  const [noDataMessage, setNoDataMessage] = useState<string>(DEFAULT_NO_DATA);

  const label = useLabelState();
  const { residentDetails } = useResidentState();
  const { id: selectedResidentId } = residentDetails;

  const relatedContactsAction = useMemo(() => {
    if (selectedResidentId) {
      return getResidentContacts(selectedResidentId);
    }
  }, [selectedResidentId]);

  const {
    data: { items: relatedContactList },
    error: relatedContactError,
    loading: relatedContactLoading
  } = useFetcher<{
    items: ResidentContact[];
  }>(relatedContactsAction, {
    items: []
  });

  const relatedContacts = useMemo(
    () =>
      relatedContactList.filter(
        ({ status }) => status === RelatedContactStatuses.ACTIVE
      ),
    [relatedContactList]
  );

  const handleSelectRelatedContact = useCallback(
    (contact: ResidentContact) => {
      if (contact.loginId === selectedContact?.loginId) {
        return undefined;
      }

      const rel: ChatContact = {
        chatRoomType: ConversationTypes.RELATED,
        name: contact.name,
        relationship: contact.relationship,
        role: undefined,
        userId: contact.id,
        loginId: contact.loginId,
        photoUrl: contact.photoUrl,
        residentId: contact.residentId,
        resident: undefined,
        status: contact.status
      };

      onContactSelect(rel);
    },
    [onContactSelect, selectedContact]
  );

  useEffect(() => {
    const content = relatedContacts.map((contact: ResidentContact) => {
      const contactChat = relatedChatRooms.find(chat =>
        chat.participants.includes(contact.loginId)
      );

      const callCount = getCallCount(contactChat?.id, missedCalls);

      const missedCount = missedMessages.find(
        counts => counts.chatRoomId === contactChat?.id
      )?.messages;

      return (
        <RelatedContactListEntry
          activeChatId={contactChat?.id}
          key={contact.id}
          data={contact}
          callCount={callCount}
          isSelected={selectedContact?.loginId === contact.loginId}
          resident={residentDetails}
          onSelect={handleSelectRelatedContact}
          missedMessages={missedCount}
        />
      );
    });

    setContentList(content);
  }, [
    relatedChatRooms,
    handleSelectRelatedContact,
    missedCalls,
    missedMessages,
    relatedContacts,
    residentDetails,
    selectedContact
  ]);

  useEffect(() => {
    if (selectedResidentId) {
      setNoDataMessage(
        `There are no related contacts for this ${label.toLowerCase()}`
      );
    } else {
      setNoDataMessage(DEFAULT_NO_DATA);
    }
  }, [selectedResidentId, label]);

  const RelatedContactsContent =
    contentList.length && selectedResidentId ? (
      <ul>{contentList}</ul>
    ) : (
      <p className={style.listPlaceholder}>{noDataMessage}</p>
    );

  const loading = relatedContactLoading || fetching;
  const error = relatedContactError || externalError;

  return (
    <div className={classnames(style.list, { [style.hasError]: !!error })}>
      {!error && <PanelHeader title="Related Contacts" />}
      {loading && !error && <LoadingPlaceholder />}
      {!loading && !error && RelatedContactsContent}
      {error && !loading && <FetchError error={error} />}
    </div>
  );
};
