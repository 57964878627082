import { removeExtraSpaces } from 'utils';

export const createDeviceDTO = (
  formValues: any,
  facilityId: string,
  requirment: {
    isRoomRequired: boolean;
    isCaregiverRequired: boolean;
    isResidentRequired: boolean;
    isDescriptionRequired: boolean;
    isGateway: boolean;
  }
) => {
  const initialValues = {
    ...formValues,
    facilityId
  };
  const {
    isRoomRequired,
    isCaregiverRequired,
    isResidentRequired,
    isDescriptionRequired,
    isGateway
  } = requirment;
  const {
    room,
    ward,
    caregiver,
    resident,
    name,
    description,
    rssiTriggerLevel,
    ...deviceDTO
  } = initialValues;
  deviceDTO.name = removeExtraSpaces(name);

  if (isRoomRequired && room) {
    deviceDTO.room = formValues.room;
    if (ward) {
      deviceDTO.room.ward = formValues.ward;
    }
  }
  if (isCaregiverRequired && caregiver) {
    deviceDTO.caregiver = formValues.caregiver;
  }
  if (isResidentRequired && resident) {
    deviceDTO.resident = formValues.resident;
  }
  if (isDescriptionRequired && description) {
    deviceDTO.description = formValues.description;
  }
  if (isGateway && rssiTriggerLevel) {
    deviceDTO.rssiTriggerLevel = formValues.rssiTriggerLevel;
  }
  return deviceDTO;
};
