import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { FetchError, LoadingPlaceholder, SearchHeader } from 'components';
import { DEAFULT_PLACEHOLDER_MESSAGE } from 'consts';
import { useAddEventListener, useModal } from 'hooks';
import { useLabelState } from 'Location';
import { URLQueryValueType } from 'Residents';
import { debounce, generateSetTriggerFunction, triggerPagination } from 'utils';

import { Card, useCardsContext } from './contexts';
import { ResidentListEntry } from './ResidentListEntry';
import { ResidentListHeader } from './ResidentListHeader';

import style from './ResidentsList.module.css';

export const ResidentsList: React.FunctionComponent<{
  selectedResidentId: string | null;
  selectedRoomId: string | null;
  onSelectListItem: (key: URLQueryValueType, value: string | undefined) => void;
}> = ({ selectedResidentId, selectedRoomId, onSelectListItem }) => {
  const [content, setContent] = useState<JSX.Element[]>([]);
  const [placeholderMessage, setPlaceholderMessage] = useState<string>(
    DEAFULT_PLACEHOLDER_MESSAGE
  );
  const [searchInputName, setSearchInputName] = useState<string>('');
  const [
    ResidentsByRoomList,
    setResidentsByRoomList
  ] = useState<JSX.Element | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [trigger, setTrigger] = useState<boolean>(false);
  const [initialPagination, setInitialPagination] = useState<boolean>(true);

  const wrapper = useRef<HTMLDivElement>(null);
  const { current } = wrapper;

  const label = useLabelState();
  const { isShowing: isSearching, toggle: toggleSearch } = useModal();
  const { cards, fetchMoreCards, fetching, fetchError } = useCardsContext();

  useAddEventListener(
    current,
    'scroll',
    debounce(generateSetTriggerFunction(current!, setTrigger)),
    !initialPagination
  );

  useEffect(() => {
    if (
      selectedResidentId ||
      selectedRoomId ||
      cards.length === 0 ||
      fetching ||
      fetchError
    ) {
      return undefined;
    }
    const firstItem = cards[0];

    onSelectListItem(firstItem.cardType, firstItem.cardId);
  }, [
    selectedRoomId,
    selectedResidentId,
    onSelectListItem,
    fetching,
    fetchError,
    cards
  ]);

  useEffect(() => {
    setSearchInputName(`${label.toLocaleLowerCase()}s or rooms`);
  }, [label]);

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
      fetchMoreCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (trigger || !current) {
      return undefined;
    }
    const flag = triggerPagination(current);
    setTrigger(flag);
    setInitialPagination(flag);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, current]);

  const checkIfSelected = useCallback(
    (item: Card) => {
      return (
        item.cardId === selectedResidentId || item.cardId === selectedRoomId
      );
    },
    [selectedRoomId, selectedResidentId]
  );

  const filteredCards = useMemo(() => {
    if (searchValue.length < 3 || !isSearching) {
      return cards;
    }

    return cards.filter(({ cardName, roomNumber }) => {
      return (
        cardName.toLowerCase().includes(searchValue.toLowerCase()) ||
        roomNumber.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  }, [cards, searchValue, isSearching]);

  useEffect(() => {
    const list = filteredCards.map((card: Card) => (
      <ResidentListEntry
        key={card.cardId}
        data={card}
        isSelected={checkIfSelected(card)}
        onSelect={onSelectListItem}
      />
    ));

    setContent(list);
  }, [checkIfSelected, filteredCards, onSelectListItem]);

  useEffect(() => {
    if (isSearching) {
      setPlaceholderMessage(DEAFULT_PLACEHOLDER_MESSAGE);
    } else {
      setPlaceholderMessage(`No ${label.toLowerCase()}s for this ward.`);
    }
  }, [isSearching, label]);

  useEffect(() => {
    setSearchInputName(`${label.toLocaleLowerCase()}s or rooms`);
  }, [label]);

  useEffect(() => {
    if (content.length > 0) {
      setResidentsByRoomList(
        <div className={style.listContainer}>{content}</div>
      );
    } else {
      setResidentsByRoomList(
        <p className={style.listPlaceholder}>{placeholderMessage}</p>
      );
    }
  }, [content, placeholderMessage]);

  return (
    <div className={classnames(style.list, { [style.hasError]: !!fetchError })}>
      {!fetchError && (
        <>
          {isSearching ? (
            <SearchHeader
              searchInputName={searchInputName}
              onClose={toggleSearch}
              searchValue={searchValue}
              onSearch={setSearchValue}
            />
          ) : (
            <ResidentListHeader toggleSearch={toggleSearch} />
          )}
        </>
      )}
      <div className={style.wrapper} ref={wrapper}>
        {!fetchError && ResidentsByRoomList}
        {(initialPagination || fetching) && !fetchError && (
          <LoadingPlaceholder
            fullHeight={true}
            withTransparency={true}
            customStyles={style.customPlaceholder}
          />
        )}
        {fetchError && !fetching && <FetchError error={fetchError} />}
      </div>
    </div>
  );
};
