import React, { createContext, useContext, useReducer } from 'react';

import { User } from 'Settings';
type Action =
  | {
      type: 'UPDATE_USER';
      payload: User;
    }
  | {
      type: 'RESET_USER';
    };
interface State {
  userDetails: User;
}
type Dispatch = (action: Action) => void;

const UserStateContext = createContext<State | undefined>(undefined);
const UserDispatchContext = createContext<Dispatch | undefined>(undefined);

export const userReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_USER': {
      return {
        ...state,
        userDetails: { ...action.payload, password: '' }
      };
    }
    case 'RESET_USER': {
      return {
        ...state,
        userDetails: {} as User
      };
    }
  }
};

export const UserProvider: (props: { children: React.ReactNode }) => any = ({
  children
}) => {
  const [state, dispatch] = useReducer(userReducer, {
    userDetails: {} as User
  });
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
};

export const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
};

export const useUserContext = (): [State, Dispatch] => {
  return [useUserState(), useUserDispatch()];
};
