import React from 'react';
import useForm from 'react-hook-form';

import {
  FormHeader,
  Input,
  LoadingPlaceholder,
  Select,
  StatusDropdown
} from 'components';
import {
  accountOwnerOptions,
  distributorNameOptions,
  facilities,
  regionalManagerOptions,
  regions,
  states
} from 'consts';
import { useParentIdn } from 'hooks';
import { Facility, Idn } from 'Settings';
import { sortByKey } from 'utils';

import { createFacilityDTO } from './createFacilityDTO';
import { timezoneOptions } from './facility.utils';

import groupStyles from 'Settings/Settings.module.css';
import formStyles from 'styles/form.module.css';
import { removeSpaces } from 'utils/stringManipulation';

interface AddFacilityFormData {
  accountOwner: string;
  additionalData4: string;
  additionalData1: string;
  additionalData3: string;
  additionalData2: string;
  address: string;
  city: string;
  distributorName: string;
  type: string;
  name: string;
  region: string;
  regionalManager: string;
  state: string;
  isActive: boolean;
  website: string;
  zip: string;
  idnName: string;
  timezone: string;
}

export const AddFacilityForm: React.FunctionComponent<{
  onCancel: () => void;
  idns: Idn[];
  onSave: (facility: Facility) => void;
  loading: boolean;
}> = ({ onCancel, idns, onSave, loading, children }) => {
  const {
    register,
    errors,
    setValue,
    watch,
    triggerValidation,
    getValues
  } = useForm<AddFacilityFormData>({
    mode: 'onBlur',
    defaultValues: {
      isActive: true
    },
    submitFocusError: false
  });
  const { idnNumber } = useParentIdn(watch('idnName') as string, idns);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const formData = removeSpaces(getValues(), ['zip', 'website']);
      onSave(createFacilityDTO(formData));
    }
  };

  const idnsOptions = sortByKey(
    idns.map(idn => ({
      value: idn.id,
      label: idn.name
    })),
    'label'
  );

  const stateOptions = states.map(state => ({
    value: state.value,
    label: state.value
  }));

  const facilityOptions = facilities.map(facility => ({
    value: facility,
    label: facility
  }));
  const regionOptions = regions.map(region => ({
    value: region.label,
    label: region.label
  }));

  return (
    <>
      <FormHeader
        title="Add New Facility"
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitDisabled={loading}
      />
      {loading ? (
        <LoadingPlaceholder fullHeight={true} />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={formStyles.firstFormRow}>
            <div className={formStyles.inputGroupHalf}>
              <Input
                name="name"
                label="Facility Name"
                register={register}
                validationRules={{ required: true }}
                hasError={!!errors.name}
                testId="fi-name"
              />
            </div>
            <div className={formStyles.selectGroupStatusSmall}>
              <StatusDropdown
                register={register}
                onChange={setValue}
                value={watch('isActive')}
                hasError={!!errors.isActive}
              />
            </div>
          </div>
          <div className={formStyles.formRow}>
            <div className={formStyles.inputGroupQuarter}>
              <Input
                name="address"
                label="Address"
                register={register}
                hasError={!!errors.address}
              />
            </div>
            <div className={formStyles.inputGroupQuarter}>
              <Input
                name="city"
                label="City"
                register={register}
                hasError={!!errors.city}
              />
            </div>
            <div className={formStyles.selectGroupEighth}>
              <Select
                name="state"
                label="State"
                options={stateOptions}
                hasError={!!errors.state}
                register={register}
                onChange={setValue}
                value={watch('state')}
              />
            </div>
            <div className={formStyles.inputGroupEighth}>
              <Input
                name="zip"
                label="Zip"
                register={register}
                hasError={!!errors.zip}
              />
            </div>
            <div className={formStyles.selectGroupQuarter}>
              <Select
                name="timezone"
                label="Time Zone"
                options={timezoneOptions}
                hasError={!!errors.timezone}
                register={register}
                onChange={setValue}
                value={watch('timezone')}
                required={true}
              />
            </div>
          </div>
          <div className={formStyles.formRow}>
            <div className={formStyles.selectGroupQuarter}>
              <Select
                name="type"
                label="Facility Type"
                options={facilityOptions}
                hasError={!!errors.type}
                register={register}
                onChange={setValue}
                value={watch('type')}
                required={true}
              />
            </div>
            <div className={formStyles.inputGroupQuarter}>
              <Input
                name="website"
                label="Website"
                register={register}
                hasError={!!errors.website}
              />
            </div>
            <div className={groupStyles.panelGroupQuarterForm}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  IDN Parent Number
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {idnNumber || 'Parent Number'}
              </div>
            </div>
            <div className={formStyles.selectGroupQuarter}>
              <Select
                name="idnName"
                label="IDN Parent"
                options={idnsOptions}
                hasError={!!errors.idnName}
                register={register}
                onChange={setValue}
                value={watch('idnName')}
              />
            </div>
          </div>
          <div className={formStyles.formRow}>
            <div className={formStyles.selectGroupQuarter}>
              <Select
                name="distributorName"
                label="Distributor Name"
                options={distributorNameOptions}
                hasError={!!errors.distributorName}
                register={register}
                onChange={setValue}
                value={watch('distributorName')}
                required={true}
              />
            </div>
            <div className={formStyles.selectGroupQuarter}>
              <Select
                name="accountOwner"
                label="Account Owner"
                options={accountOwnerOptions}
                hasError={!!errors.accountOwner}
                register={register}
                onChange={setValue}
                value={watch('accountOwner')}
                required={true}
              />
            </div>
            <div className={formStyles.selectGroupQuarter}>
              <Select
                name="regionalManager"
                label="Regional Manager"
                options={regionalManagerOptions}
                hasError={!!errors.regionalManager}
                register={register}
                onChange={setValue}
                value={watch('regionalManager')}
                required={true}
              />
            </div>
            <div className={formStyles.selectGroupQuarter}>
              <Select
                name="region"
                label="Region"
                options={regionOptions}
                hasError={!!errors.region}
                register={register}
                onChange={setValue}
                value={watch('region')}
                required={true}
              />
            </div>
          </div>
          <div className={formStyles.formRow}>
            <div className={formStyles.selectGroupQuarter}>
              <Input
                name="additionalData1"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData1}
              />
            </div>
            <div className={formStyles.inputGroupQuarter}>
              <Input
                name="additionalData2"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData2}
              />
            </div>
            <div className={formStyles.inputGroupQuarter}>
              <Input
                name="additionalData3"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData3}
              />
            </div>
            <div className={formStyles.inputGroupQuarter}>
              <Input
                name="additionalData4"
                label="Additional Data"
                register={register}
                hasError={!!errors.additionalData4}
              />
            </div>
          </div>
          {children}
        </form>
      )}
    </>
  );
};
