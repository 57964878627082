import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  PanelList,
  Table
} from 'components';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';
import { getPaginatedUsers, User } from 'Settings';

import { AddNewUser } from './AddNewUser';
import { useUserState } from './user.context';
import { UserTableRow } from './UserTableRow';

const UserListTableColumns: Column[] = [
  { name: 'Full Name', align: 'left', cols: 2 },
  { name: 'Title', align: 'left', cols: 2 },
  { name: 'E-mail Address', align: 'left', cols: 3 },
  { name: 'Status', align: 'center', cols: 1 }
];

export const UsersList: React.FunctionComponent<{
  selectUser: (user: User) => void;
  selectedUserId: string;
}> = ({ selectUser, selectedUserId }) => {
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const { toggle, isShowing } = useModal();
  const { userDetails } = useUserState();

  const scrollAction = () => {
    if (isFetching && data.items.length && data.nextPage) {
      setPage(parseInt(data.nextPage, 10));
      setIsFetching(false);
    }
  };

  const getUsersMemo = useMemo(() => getPaginatedUsers(page), [page]);

  const { data, error, loading, setRefetch } = useFetcher<{
    items: User[];
    nextPage?: string;
  }>(getUsersMemo, { items: [] });

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const modalClosed = () => {
    setUsers([]);
    if (page === 0) {
      setRefetch();
    } else {
      setPage(0);
    }
    toggle();
  };

  useEffect(() => {
    if (data.items.length !== 0) {
      setUsers(prevState => [...prevState, ...data.items]);
    }
  }, [data]);

  useEffect(() => {
    setUsers(currentUsers => {
      return currentUsers.map(user => {
        return user.id === userDetails.id ? userDetails : user;
      });
    });
  }, [userDetails]);

  const render = (rowValues: any) => (
    <UserTableRow
      key={rowValues.id}
      values={rowValues}
      selectUser={selectUser}
      isSelected={selectedUserId === rowValues.id}
    />
  );

  const isDataAvailable = users.length > 0;

  return (
    <PanelList title="Users" onAdd={toggle} hasError={!!error}>
      {!error && (!loading || isDataAvailable) && (
        <Table<User>
          columns={UserListTableColumns}
          rows={users}
          placeholder="No data available"
          render={render}
          listRef={scrollRef}
        />
      )}
      {loading && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}
      <AddNewUser toggle={modalClosed} isOpen={isShowing} onAdd={selectUser} />
    </PanelList>
  );
};
