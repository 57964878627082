import React from 'react';

import styles from './Spinner.module.css';

export const Spinner: React.FunctionComponent = () => (
  <div className={styles.loadingContainer}>
    <div className={styles.first} />
    <div className={styles.second} />
    <div className={styles.third} />
  </div>
);
