import React from 'react';

import styles from './HistoryValue.module.css';

export const HistoryValue: React.FunctionComponent<{
  value: string;
  detail?: string;
}> = ({ value, detail }) => {
  return (
    <div className={styles.readingWrapper}>
      {value && <h3 className={styles.value}>{value}</h3>}
      {!value && <h3 className={styles.value}>&#8212;</h3>}
      {value && detail && <span className={styles.detail}>{detail}</span>}
    </div>
  );
};
