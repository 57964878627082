import React, { useState } from 'react';

import { Modal } from 'components';
import { useModal } from 'hooks';
import {
  getLastWeekHistoryFilters,
  getThisMonthHistoryFilters,
  getThisWeekHistoryFilters,
  HistoryLabel,
  StaffVisit
} from 'Residents';

import { StaffVisitsCard } from './Cards';
import { StaffVisitsHistory } from './History/StaffVisitsHistory';

import style from 'Residents/History/History.module.css';

export const StaffVisitsDetails: React.FunctionComponent<{
  staffVisits: StaffVisit;
  facilityId: string;
  residentId: string;
}> = ({ staffVisits, facilityId, residentId }) => {
  const [historyFilters, setHistoryFilters] = useState(
    getThisWeekHistoryFilters()
  );
  const { isShowing: isHistoryOpen, toggle: toggleHistoryView } = useModal();

  const onCloseHistoryView = () => {
    setHistoryFilters(getThisWeekHistoryFilters());
    toggleHistoryView();
  };

  const changeHistoryFilter = (historyLabel: HistoryLabel) => {
    switch (historyLabel) {
      case 'This Week':
        setHistoryFilters(getThisWeekHistoryFilters());
        break;
      case 'Last Week':
        setHistoryFilters(getLastWeekHistoryFilters());
        break;
      case 'This Month':
        setHistoryFilters(getThisMonthHistoryFilters());
        break;
    }
  };

  return (
    <>
      <StaffVisitsCard onClick={toggleHistoryView} staffVisits={staffVisits} />
      <Modal isShowing={isHistoryOpen} toggle={toggleHistoryView}>
        <div className={style.modalBackdrop} id="HistoryModal">
          <div className={style.modalContent}>
            <StaffVisitsHistory
              onClose={onCloseHistoryView}
              historyFilters={historyFilters}
              facilityId={facilityId}
              residentId={residentId}
              changeHistoryFilter={changeHistoryFilter}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
