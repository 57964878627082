import React from 'react';

import { DeviceLocation } from 'Location';
import {
  formatLogDate,
  formatSeconds,
  HistoryLogsList,
  ResidentLocation
} from 'Residents';

import { HistoryLogEntry } from './HistoryLogEntry';
import { HistoryValue } from './HistoryValue';
import { ResidentCurrentLocationLog } from './ResidentCurrentLocationLog';

import styles from './HistoryLogs.module.css';

export const ResidentLocationHistoryLogs: React.FunctionComponent<{
  historyLogs: ResidentLocation[];
  lastKnownLocation: DeviceLocation | undefined;
  scrollRef?: any;
}> = ({ historyLogs, lastKnownLocation, scrollRef }) => {
  const logEntries = historyLogs.map(log => {
    const { timestamp, value } = log;
    const { roomName, wardName, duration } = value || {};
    const { timestamp: time, date } = formatLogDate(timestamp);
    const actualValue = formatSeconds(parseFloat(duration));

    return (
      <HistoryLogEntry key={log.timestamp} timestamp={time} date={date}>
        {roomName && (
          <div className={styles.additionalInfo}>
            <span className={styles.topSpan}>{wardName}</span>
            <span className={styles.bottomSpan}>{roomName}</span>
          </div>
        )}
        <HistoryValue value={actualValue} />
      </HistoryLogEntry>
    );
  });

  const hasLocation = lastKnownLocation && lastKnownLocation.room;

  return historyLogs.length > 0 || hasLocation ? (
    <HistoryLogsList scrollRef={scrollRef}>
      {hasLocation && (
        <ResidentCurrentLocationLog lastKnownLocation={lastKnownLocation!} />
      )}
      {logEntries}
    </HistoryLogsList>
  ) : (
    <p className={styles.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
