import React, { useEffect, useMemo, useState } from 'react';

import { List, WithSimpleFetch } from 'components';
import { useFetcher, usePoster } from 'hooks';
import { debounce, sortByKey } from 'utils';

import {
  getCaregiverAccessToWards,
  getWardsByFacility,
  giveCaregiverWardAccess,
  removeCaregiverWardAccess
} from '../actions';
import { CaregiverFacilityAccess, CaregiverWardAccess, Ward } from '../types';

import { WardAccessListItem } from './WardAccessListItem';

import caregiverStyles from './Caregivers.module.css';

import warning from 'icons/warning.svg';

const ListWithFetch = WithSimpleFetch(List);
const emptyWardListDefault = 'There are no wards for this facility';

export const WardsAccessList: React.FunctionComponent<{
  facilityAccess: CaregiverFacilityAccess;
  caregiver: { id: string };
  hasParentIdn: boolean;
  onAccessChange: (facilityId: string, hasAccess: boolean) => void;
  onAccessError: () => void;
  listPlaceholder?: string;
}> = ({
  facilityAccess,
  caregiver,
  hasParentIdn,
  onAccessChange,
  onAccessError,
  listPlaceholder
}) => {
  const [accessChanged, setAccessChanged] = useState(false);
  const [isAccessError, setIsAccessError] = useState(false);
  const [wardsAccess, setWardsAccess] = useState<CaregiverWardAccess[]>([]);
  const {
    setAction,
    loading: wardAccessChangeLoading,
    error: wardAccessChangeError
  } = usePoster();

  const getWardsMemo = useMemo(() => {
    if (facilityAccess.facilityId) {
      return getWardsByFacility(facilityAccess.facilityId);
    }
  }, [facilityAccess]);
  const {
    data: facilityWards,
    error: facilityWardsError,
    loading: facilityWardsLoading
  } = useFetcher<Ward[]>(getWardsMemo, []);

  const getWardsAccessMemo = useMemo(() => {
    if (facilityAccess.facilityId) {
      return getCaregiverAccessToWards(caregiver.id);
    }
  }, [caregiver, facilityAccess]);
  const {
    data: wardsAccessResponse,
    error: wardsAccessError,
    loading: wardsAccessLoading
  } = useFetcher<CaregiverWardAccess[]>(getWardsAccessMemo, []);

  useEffect(() => {
    if (!facilityAccess.facilityId) {
      return;
    }
    const caregiverWardsAccess = facilityWards.map(({ id, name }) => {
      const wardAccess = wardsAccessResponse.find(
        ({ wardId }) => wardId === id
      );
      return { wardId: id, wardName: name, hasAccess: !!wardAccess };
    });

    setWardsAccess(sortByKey(caregiverWardsAccess, 'wardName'));
    setIsAccessError(false);
  }, [wardsAccessResponse, facilityWards, facilityAccess]);

  useEffect(() => {
    setIsAccessError(!!wardAccessChangeError);
  }, [wardAccessChangeError]);

  useEffect(() => {
    setWardsAccess([]);
    setIsAccessError(false);
  }, [caregiver]);

  const handleWardAccessChange = (id: string, hasAccess: boolean) => {
    const currentWardAccess = wardsAccess.find(({ wardId }) => wardId === id);
    if (currentWardAccess && currentWardAccess.hasAccess === hasAccess) {
      return;
    }

    const accessAction = hasAccess
      ? giveCaregiverWardAccess
      : removeCaregiverWardAccess;
    setAction(accessAction(caregiver.id, id));

    setWardsAccess(wards =>
      wards.map(item =>
        item.wardId === id ? { ...item, hasAccess: !item.hasAccess } : item
      )
    );

    if (hasParentIdn) {
      onAccessChange(facilityAccess.facilityId, hasAccess);
    }

    setAccessChanged(true);
  };

  const wardsFetchLoading = facilityWardsLoading || wardsAccessLoading;
  const wardsFetchError = !!facilityWardsError || !!wardsAccessError;

  const renderWardsAccess = (values: any) => (
    <WardAccessListItem
      key={values.wardId}
      values={values}
      onToggle={debounce(handleWardAccessChange, 500)}
    />
  );

  if (accessChanged && isAccessError && !wardAccessChangeLoading) {
    if (hasParentIdn) {
      onAccessError();
    }
    setAccessChanged(false);
  }

  return isAccessError ? (
    <div className={caregiverStyles.errorMessageContainer}>
      <img src={warning} alt="error" />
      <h5 className={caregiverStyles.errorTitle}>
        An error occured while changing access for a ward.
      </h5>
      <h5 className={caregiverStyles.errorDetails}>
        Please reload to see latest changes.
      </h5>
    </div>
  ) : (
    <ListWithFetch
      loading={wardsFetchLoading}
      error={wardsFetchError}
      title="Ward Name"
      items={wardsAccess}
      placeholder={
        facilityAccess.facilityId && !wardsAccess.length
          ? emptyWardListDefault
          : listPlaceholder
      }
      render={renderWardsAccess}
    />
  );
};
