import { MomentInput } from 'moment';
import { Caregiver, Resident, Room, Ward } from 'Settings';
import { RoomId } from 'types';

export enum NotificationSounds {
  HIGH = '/sounds/high-priority.mp3',
  MEDIUM = '/sounds/medium-priority.mp3',
  LOW = '/sounds/low-priority.mp3'
}

export type NotificationSound =
  | NotificationSounds.HIGH
  | NotificationSounds.MEDIUM
  | NotificationSounds.LOW;

export enum NotificationActionTypes {
  DISMISS = 'DISMISS_NOTIFICATION',
  REMOTE_DISMISS = 'REMOTE_DISMISS_NOTIFICATION',
  FAIL_DISMISS = 'FAIL_DISMISS',
  FINISH_DISMISS = 'FINISH_DISMISS',
  FETCH = 'FETCH_NOTIFICATIONS',
  FINISH_FETCH = 'FINISH_FETCH',
  FAIL_FETCH = 'FAIL_FETCH',
  SET_REFETCH = 'SET_REFETCH',
  ADD = 'ADD_NOTIFICATION',
  CLEAR = 'CLEAR_NOTIFICATIONS',
  EDIT = 'EDIT_NOTIFICATION',
  SOUND = 'PLAY_SOUND'
}

export enum AlarmTypes {
  CAREGIVER_ALARM = 'CAREGIVER_ALARM',
  RESIDENT_ALARM = 'RESIDENT_ALARM',
  RESIDENT_BADGE_SOS_ALARM = 'RESIDENT_BADGE_SOS_ALARM',
  RESIDENT_WELL_BEING_ALARM = 'RESIDENT_WELL_BEING_ALARM',
  ROOM_ALARM = 'ROOM_ALARM'
}

export enum AlarmsPriority {
  HIGH_PRIORITY_ALARMS = 'High Priority Alarms',
  MEDIUM_PRIORITY_ALARMS = 'Medium Priority Alarms',
  LOW_PRIORITY_ALARMS = 'Low Priority Alarms',
  PRIORITY_HIGH = 'High Priority',
  PRIORITY_MEDIUM = 'Medium Priority',
  PRIORITY_LOW = 'Low Priority'
}

export type NotificationPriority =
  | AlarmsPriority.HIGH_PRIORITY_ALARMS
  | AlarmsPriority.MEDIUM_PRIORITY_ALARMS
  | AlarmsPriority.LOW_PRIORITY_ALARMS;

export enum ColorCodes {
  RED = 'RED',
  ORANGE = 'ORANGE',
  DARK_ORANGE = 'DARK_ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN'
}

export type ColorCode =
  | ColorCodes.DARK_ORANGE
  | ColorCodes.ORANGE
  | ColorCodes.RED
  | ColorCodes.GREEN
  | ColorCodes.YELLOW;

export type NotificationType =
  | AlarmTypes.CAREGIVER_ALARM
  | AlarmTypes.RESIDENT_ALARM
  | AlarmTypes.RESIDENT_BADGE_SOS_ALARM
  | AlarmTypes.ROOM_ALARM
  | AlarmTypes.RESIDENT_WELL_BEING_ALARM;

export interface Notification {
  id: string;
  title: string;
  created: MomentInput;
  colorCode: ColorCode;
  room: RoomId;
  roomView: Room;
  residentView?: Resident;
  caregiverView?: Caregiver;
  wardView: Omit<Ward, 'toggleFeature'>;
  type: NotificationType;
  personOfInterest?: string;
  alarmVisibility?: string[]; // list of ward ids
}

export interface NotificationDetail
  extends Omit<
    Notification,
    'resident' | 'room' | 'caregiver' | 'personOfInterest'
  > {
  personOfInterest?: { id: string; name: string };
  room: { id: string; number: string };
}

export interface NotificationListResponse {
  items: Notification[];
}
