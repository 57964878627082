import { CaregiverStatusListResponse } from 'Caregivers';
import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

export const getCaregiverStatus = (
  caregiverId: string,
  facilityId: string,
  wardId?: string
) => async () => {
  const additionalQueryParams = wardId
    ? new URLSearchParams({
        wardId,
        size: MAX_PAGE_SIZE.toString()
      })
    : new URLSearchParams({
        size: MAX_PAGE_SIZE.toString()
      });
  const response = await axiosWrapper<CaregiverStatusListResponse>(
    `/facilities/${facilityId}/caregivers/${caregiverId}/caregiver-statuses?${additionalQueryParams}`
  );

  return response.data.items;
};
