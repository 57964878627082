import React, { useEffect, useMemo, useState } from 'react';

import { DetailsPlaceholder, TwoColumnLayout } from 'components';
import { useFetcher } from 'hooks';
import { getFacilityDetails } from 'Settings/actions';
import { Facility } from 'Settings/types';

import { FacilityDetails } from './FacilityDetails';
import { FacilityList } from './FacilityList';

import facilityStyles from './FacilitiesSettings.module.css';

export const FacilitiesSettings: React.FunctionComponent = () => {
  const [selectedFacilityId, setSelectedFacilityId] = useState('');
  const [selectedFacility, setSelectedFacility] = useState<Facility>({
    idn: {
      name: '',
      number: '',
      id: ''
    },
    id: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    wards: 0,
    numberOfResidents: 0,
    numberOfWards: 0,
    isActive: false,
    website: '',
    type: '',
    accountOwner: '',
    regionalManager: '',
    region: '',
    distributorName: '',
    additionalData1: '',
    additionalData2: '',
    additionalData3: '',
    additionalData4: '',
    timezone: ''
  });

  const fetchFacility = (id: string) => {
    if (!id) {
      return;
    }
    return getFacilityDetails(id);
  };

  const getFacilityDetailsMemo = useMemo(
    () => fetchFacility(selectedFacilityId),
    [selectedFacilityId]
  );

  const { data, setRefetch: refetchFacility } = useFetcher<Facility>(
    getFacilityDetailsMemo,
    {} as Facility
  );

  useEffect(() => {
    if (data) {
      setSelectedFacility(data);
    }
  }, [data]);
  return (
    <>
      <TwoColumnLayout
        left={
          <FacilityList
            selectedFacility={selectedFacility}
            selectFacility={setSelectedFacilityId}
          />
        }
        right={
          <div className={facilityStyles.panelHeight}>
            {selectedFacilityId && (
              <FacilityDetails
                facilityId={selectedFacilityId}
                refetchOnChange={refetchFacility}
              />
            )}
            {!selectedFacilityId && (
              <DetailsPlaceholder
                title="Facility Details"
                message="Please select facility to display details"
              />
            )}
          </div>
        }
      />
    </>
  );
};
