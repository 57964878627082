import classnames from 'classnames';
import React, { useMemo, useState } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable
} from 'react-beautiful-dnd';
import { DateUtils } from 'react-day-picker';

import { getCaregiversByWard, StatusType } from 'Caregivers';
import { FetchError, LoadingPlaceholder, SearchHeader } from 'components';
import { useFetcher, useModal } from 'hooks';
import { useLocationState } from 'Location';
import { Caregiver } from 'Settings';

import { getDraggableStyle, getDroppableStyle } from './dragNdrop.utils';
import { useScheduleState } from './schedule.context';
import { getCaregiversByStatus } from './schedule.utils';
import { StaffListEntry } from './StaffListEntry';
import { StaffListHeader } from './StaffListHeader';

import style from './StaffList.module.css';

export const StaffList: React.FunctionComponent<{
  selectedDay: Date;
  isDragDisabled: boolean;
  onStatusChange: (caregiverId: string, newStatus: StatusType) => void;
}> = ({ selectedDay, isDragDisabled, onStatusChange }) => {
  const [searchValue, setSearchValue] = useState('');

  const { facility, ward: wardId } = useLocationState();
  const { isShowing: isSearching, toggle: toggleSearch } = useModal();
  const {
    caregiversStatus,
    statusLoading,
    statusFetchError
  } = useScheduleState();

  const caregiversByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getCaregiversByWard(facility.id, wardId);
    }
  }, [facility.id, wardId]);

  const {
    data: caregiversByWard,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<Caregiver[]>(caregiversByWardAction, []);

  const loading = caregiversLoading || statusLoading;
  const fetchError = caregiversError || statusFetchError;

  const staffMembers = useMemo(() => {
    const { available, unavailable } = getCaregiversByStatus(
      caregiversByWard,
      caregiversStatus
    );
    return [...available, ...unavailable];
  }, [caregiversByWard, caregiversStatus]);

  const filteredCaregivers = useMemo(() => {
    if (searchValue.length < 3 || !isSearching) {
      return staffMembers;
    }
    return staffMembers.filter(
      ({ name, role }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()) ||
        role.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [isSearching, searchValue, staffMembers]);

  const getRenderItem = (items: Caregiver[]) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: any
  ) => {
    const item = items[rubric.source.index];

    return (
      <StaffListEntry
        key={item.id}
        staffMember={item}
        status={caregiversStatus[item.id]}
        isActionDisabled={
          DateUtils.isPastDay(selectedDay) || DateUtils.isFutureDay(selectedDay)
        }
        onStatusChange={onStatusChange}
        draggableRef={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getDraggableStyle(snapshot, provided.draggableProps.style)}
      />
    );
  };

  const renderListContent = () =>
    filteredCaregivers.map((caregiver, index) => (
      <Draggable
        key={caregiver.id}
        draggableId={`staffList-${JSON.stringify(caregiver)}`}
        index={index}
        isDragDisabled={DateUtils.isPastDay(selectedDay) || isDragDisabled}
      >
        {getRenderItem(filteredCaregivers)}
      </Draggable>
    ));

  const emptyListPlaceholder = isSearching
    ? 'The search value returned no results.'
    : 'No caregivers for this ward.';

  return (
    <div className={classnames(style.list, { [style.hasError]: !!fetchError })}>
      {!fetchError && (
        <>
          {isSearching ? (
            <SearchHeader
              searchInputName="caregivers"
              onClose={toggleSearch}
              searchValue={searchValue}
              onSearch={setSearchValue}
            />
          ) : (
            <StaffListHeader toggleSearch={toggleSearch} />
          )}
        </>
      )}
      {loading && !fetchError && <LoadingPlaceholder />}
      {!loading && !fetchError && (
        <Droppable droppableId="staffList">
          {(provided, snapshot) => (
            <>
              {filteredCaregivers.length ? (
                <ul ref={provided.innerRef} style={getDroppableStyle(snapshot)}>
                  {renderListContent()}
                  {provided.placeholder}
                </ul>
              ) : (
                <p className={style.listPlaceholder}>{emptyListPlaceholder}</p>
              )}
            </>
          )}
        </Droppable>
      )}
      {fetchError && !loading && <FetchError error={fetchError} />}
    </div>
  );
};
