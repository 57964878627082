import React, { FunctionComponent } from 'react';

import {
  ProfilePlaceholder,
  RoundCrossButtonRed,
  RoundPlusButton
} from 'components';
import { LoginId } from 'types';
import { formatName } from 'utils';

import styles from './AdHocMember.module.css';

interface IAdHocMemberProps {
  name: string;
  loginId: LoginId;
  role: string;
  photoUrl?: string;
  isSelected: boolean;
  onRemove: (loginId: LoginId) => void;
  onAdd: (loginId: LoginId) => void;
}

export const AdHocMember: FunctionComponent<IAdHocMemberProps> = ({
  loginId,
  name,
  photoUrl,
  onRemove,
  onAdd,
  role,
  isSelected
}) => {
  const handleRemoveMember = () => {
    if (isSelected) {
      onRemove(loginId);
    }
  };
  const handleAddMember = () => {
    if (!isSelected) {
      onAdd(loginId);
    }
  };

  return (
    <li className={styles.participantDetails}>
      <ProfilePlaceholder
        name={formatName(name)}
        type={'available'}
        photoUrl={photoUrl}
      />

      <div className={styles.info}>
        <span className={styles.participantName}>{name}</span>
        <span className={styles.participantRole}>{role}</span>
      </div>

      <div className={styles.buttons}>
        {isSelected ? (
          <RoundCrossButtonRed
            onClick={handleRemoveMember}
            testId="adhoc-member"
            size={32}
          />
        ) : (
          <RoundPlusButton onClick={handleAddMember} />
        )}
      </div>
    </li>
  );
};
