import React from 'react';

import { RoundCheckButton, RoundPlusButton } from 'components';

import style from './AssignButton.module.css';

export const AssignButton: React.FunctionComponent<{
  isAssigned: boolean;
  action: () => void;
  loading: boolean;
  isPastDay: boolean;
}> = ({ isAssigned, action, isPastDay, loading }) => {
  return (
    <>
      {!loading ? (
        <>
          {isAssigned ? (
            <div className={style.button}>
              <RoundCheckButton onClick={action} disabled={isPastDay} />
            </div>
          ) : (
            !isPastDay && (
              <div className={style.button}>
                <RoundPlusButton onClick={action} />
              </div>
            )
          )}
        </>
      ) : (
        (!isPastDay || (isAssigned && isPastDay)) && (
          <div className={style.button}>
            <div className={style.loading} />
          </div>
        )
      )}
    </>
  );
};
