import moment from 'moment';
import React from 'react';
import useForm from 'react-hook-form';

import { AddReadingForm, ReadingInput } from 'Residents';

interface OxygenSaturationReadingFormData {
  oxygenSaturation: string;
}

export const OxygenSaturationReadingForm: React.FunctionComponent<{
  isOpen: boolean;
  onSave: (formData: any) => void;
  onCancel: () => void;
  loading: boolean;
}> = ({ isOpen, onSave, onCancel, loading, children }) => {
  const { register, errors, getValues, triggerValidation } = useForm<
    OxygenSaturationReadingFormData
  >({
    mode: 'onBlur',
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const { oxygenSaturation } = getValues();
      const measuredAt = moment().toISOString(true);

      onSave({
        oxygenSaturation: parseInt(oxygenSaturation, 10),
        measuredAt,
        type: 'OXYGEN_SATURATION'
      });
    }
  };

  return (
    <AddReadingForm
      isOpen={isOpen}
      onSave={handleSubmit}
      onCancel={onCancel}
      title="Add Oxygen Saturation Reading"
      savingDisabled={loading}
    >
      <ReadingInput
        name="oxygenSaturation"
        label="oxygen saturation"
        register={register}
        hasError={!!errors.oxygenSaturation}
        unit="(%)"
        validationRules={{ required: true, pattern: /^([0]|[1-9]+[0-9]*)$/ }}
      />
      {children}
    </AddReadingForm>
  );
};
