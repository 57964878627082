import { useCallback, useEffect, useRef, useState } from 'react';

import { getToken, useAuthState, UserRole } from 'Auth';

interface SocketMessage {
  type: string;
  value: any;
}

const DEFAULT_RECONNECT_INTERVAL = 1000;
const DEFAULT_RECONNECT_ATTEMPTS = 20;
const DEFAULT_CONNECTION_CHECK_INTERVAL = 20000;

type SocketSubscription = () => void;

export const useWebSocket = (url: string) => {
  const ws = useRef<any>(null);
  const [data, setData] = useState<SocketMessage>({} as SocketMessage);
  const [isSocketOpen, setIsSocketOpen] = useState(false);
  const didMount = useRef(true);
  const reconnectAttempts = useRef(DEFAULT_RECONNECT_ATTEMPTS);
  const closingScheduled = useRef(false);
  const pendingSubscriptions = useRef<SocketSubscription[]>([]);

  const { isAuthenticated, role } = useAuthState();

  const keepConnectionAlive = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send('PING');
    }
  };

  const connectIfClosed = useCallback(() => {
    const connect = () => {
      let reconnectInterval: number;
      let connectionCheckInterval: number; // used for keeping the connection alive

      ws.current = new WebSocket(url);

      ws.current.onopen = () => {
        if (didMount.current) {
          setIsSocketOpen(true);
        }
        clearTimeout(reconnectInterval);

        if (closingScheduled.current) {
          // close if previously attempted to close while in CONNECTING state
          ws.current.close();
          reconnectAttempts.current = 0;
          return;
        }

        if (pendingSubscriptions.current.length) {
          pendingSubscriptions.current.forEach(subscription => subscription());
          pendingSubscriptions.current = [];
        }

        reconnectAttempts.current = DEFAULT_RECONNECT_ATTEMPTS;

        connectionCheckInterval = window.setInterval(
          keepConnectionAlive,
          DEFAULT_CONNECTION_CHECK_INTERVAL
        );
      };
      ws.current.onmessage = (message: MessageEvent) => {
        clearInterval(connectionCheckInterval);
        connectionCheckInterval = window.setInterval(
          keepConnectionAlive,
          DEFAULT_CONNECTION_CHECK_INTERVAL
        );

        if (message.data === 'PONG') {
          // connection is still open, no action needed
          return;
        }

        const parsedData = JSON.parse(message.data);
        setData(parsedData);
      };
      ws.current.onclose = () => {
        const authToken = getToken();
        if (authToken && didMount.current && reconnectAttempts.current > 0) {
          reconnectInterval = window.setTimeout(
            connectIfClosed,
            DEFAULT_RECONNECT_INTERVAL
          );
        }

        clearTimeout(connectionCheckInterval);
        closingScheduled.current = false;
        pendingSubscriptions.current = [];
        if (didMount.current) {
          setIsSocketOpen(false);
        }
      };
      ws.current.onerror = () => {
        if (reconnectAttempts.current > 0) {
          reconnectAttempts.current -= 1;
        }
      };
    };

    if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
      connect();
    }
  }, [url]);

  const close = useCallback(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
      reconnectAttempts.current = 0;
    } else if (ws.current && ws.current.readyState === WebSocket.CONNECTING) {
      closingScheduled.current = true;
    }
  }, []);

  const isConnectionOpen = useCallback(
    () => !!ws.current && ws.current.readyState === WebSocket.OPEN,
    []
  );

  const subscribeToUpdates = useCallback(
    (
      type: string,
      value: { [key: string]: any } = {},
      acceptedRoles: UserRole[]
    ) => {
      const authToken = getToken();
      const roleReceivesUpdates = role && acceptedRoles.includes(role);

      if (authToken && roleReceivesUpdates) {
        const subscription = () => {
          ws.current.send(
            JSON.stringify({
              type,
              value: { ...value, authorization: `Bearer ${authToken}` }
            })
          );
        };
        if (isConnectionOpen()) {
          subscription();
        } else {
          pendingSubscriptions.current = [
            ...pendingSubscriptions.current,
            subscription
          ];
          connectIfClosed();
        }
      }
    },
    [connectIfClosed, isConnectionOpen, role]
  );

  useEffect(() => {
    if (isAuthenticated) {
      connectIfClosed();
    }

    return () => {
      didMount.current = false;
      close();
    };
    // we want to call this once, on mount, ignoring dependencies, since we only care about initial state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      close();
      return;
    }
    connectIfClosed();
  }, [close, connectIfClosed, isAuthenticated]);

  return {
    data,
    subscribeToUpdates,
    isConnectionOpen: isSocketOpen,
    close
  };
};
