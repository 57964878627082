import moment from 'moment';
import React, { useMemo, useState } from 'react';

import { StaffMember as ResidentCaregiver, StatusType } from 'Caregivers';
import { Modal, SearchHeader } from 'components';
import { useModalMeasurements, usePoster, useSubmitError } from 'hooks';
import { assignCaregiverToRoom, unassignCaregiverFromRoom } from 'Residents';
import { Caregiver } from 'Settings';

import { AssignableCaregiver } from './AssignableCaregiver';

import styles from './ManageCaregiversList.module.css';

export const ManageCaregiversList: React.FunctionComponent<{
  availableCaregivers: Caregiver[];
  assignedCaregivers: ResidentCaregiver[];
  isOpen: boolean;
  onClose: () => void;
  onChange: () => void;
  wardId: string;
  roomId: string;
  caregiversStatus: { [caregiverId: string]: StatusType };
}> = ({
  assignedCaregivers,
  isOpen,
  onClose,
  onChange,
  wardId,
  roomId,
  availableCaregivers,
  caregiversStatus
}) => {
  const [searchValue, setSearchValue] = useState('');
  const {
    modalOffsetTop,
    modalHeight,
    scrollableAncestorHeight,
    modalOffsetLeft
  } = useModalMeasurements('CaregiversList', 'MainContent', isOpen);

  const computedModalHeight =
    modalHeight > scrollableAncestorHeight - 60
      ? scrollableAncestorHeight - 60
      : modalHeight;

  const { setAction, loading: onSaveLoading, error } = usePoster();
  const { submitted, setSubmitted } = useSubmitError(error, onSaveLoading);

  const unassignCaregiver = (caregiverId: string) => {
    const workingDay = moment().format('YYYY-MM-DD');
    setAction(
      unassignCaregiverFromRoom({ wardId, roomId, caregiverId, workingDay })
    );
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const assignCaregiver = (caregiverId: string) => {
    const workingDay = moment().format('YYYY-MM-DD');
    setAction(
      assignCaregiverToRoom({ wardId, roomId, caregiverId, workingDay })
    );
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const filteredCaregivers = useMemo(() => {
    if (searchValue.length < 3) {
      return [];
    }
    return availableCaregivers.filter(
      ({ name, role }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()) ||
        role.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, availableCaregivers]);

  if (submitted && !onSaveLoading && !error) {
    onChange();
    setSubmitted(false);
  }

  const assignedCaregiversContent = assignedCaregivers.map(
    (caregiver: ResidentCaregiver) => {
      const photoUrl = caregiver.photoUrl;
      const caregiverData = { ...caregiver, photoUrl };
      return (
        <AssignableCaregiver
          key={caregiver.id}
          data={caregiverData}
          isAssigned={true}
          handleAssignment={unassignCaregiver}
        />
      );
    }
  );

  const filteredCaregiversContent = filteredCaregivers.map(
    (caregiver: Caregiver) => {
      const { id, name, role, loginId, photoUrl } = caregiver;
      const isAssigned = assignedCaregivers.find(
        ({ id: idToMatch }) => id === idToMatch
      );
      const action = isAssigned ? unassignCaregiver : assignCaregiver;
      return (
        <AssignableCaregiver
          key={id}
          data={{
            id,
            name,
            role,
            status: caregiversStatus[id],
            loginId,
            photoUrl,
            relationship: undefined
          }}
          isAssigned={!!isAssigned}
          handleAssignment={action}
        />
      );
    }
  );

  return (
    <Modal isShowing={isOpen} toggle={onClose}>
      <div className={styles.modalBackdrop}>
        <div
          className={styles.modalContent}
          style={{
            marginTop: modalOffsetTop + 110,
            height: computedModalHeight,
            marginLeft: modalOffsetLeft
          }}
        >
          <div className={styles.listsContainer}>
            <SearchHeader
              searchInputName="caregiver"
              onClose={onClose}
              onSearch={setSearchValue}
              searchValue={searchValue}
            />
            {!!filteredCaregivers.length && (
              <ul className={styles.availableCaregiversList}>
                {filteredCaregiversContent}
              </ul>
            )}
            {!!assignedCaregivers.length && (
              <ul className={styles.assignedCaregiversList}>
                {assignedCaregiversContent}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
