import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  PanelList,
  Table
} from 'components';
import { useFetcher, useInfiniteScroll } from 'hooks';

import { Caregiver, Facility } from 'Settings/types';

import { getCaregiversByQuery, getPaginatedCaregivers } from '../../actions';
import {
  CAREGIVERS_LIST_PLACEHOLDER,
  CAREGIVERS_SEARCH_INPUT_NAME,
  CAREGIVERS_TITLE,
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH
} from '../../constants/index';

import { CaregiverTableRow } from './CaregiverTableRow';

const CaregiverListTableColumns: Column[] = [
  { name: 'Caregiver Name', align: 'left', cols: 3 },
  { name: 'Role', align: 'left', cols: 2 },
  { name: 'Status', align: 'center', cols: 1 }
];

export const CaregiverList: React.FunctionComponent<{
  facility: Facility;
  setActiveTab: (tab: string) => void;
  onSelect: (id: string) => void;
  selectedCaregiverId: string;
}> = ({ facility, setActiveTab, onSelect, selectedCaregiverId }) => {
  const [page, setPage] = useState(0);
  const [searchedCaregiversPage, setSearchedCaregiversPage] = useState(0);
  const [caregivers, setCaregivers] = useState<Caregiver[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getCaregiversMemo = useMemo(
    () => getPaginatedCaregivers(facility.id, page),
    [facility.id, page]
  );

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getCaregiversByQuery(
        facility.id,
        searchTerm,
        searchedCaregiversPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedCaregiversPage]);

  const {
    data: { items: caregiverList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Caregiver[];
    nextPage?: string;
  }>(getCaregiversMemo, { items: [] });

  const {
    data: {
      items: searchedCaregivers,
      nextPage: nextPageForSearchedCaregivers
    },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{
    items: Caregiver[];
    nextPage?: string;
  }>(memoSearchAction, { items: [] });

  useEffect(() => {
    if (caregiverList.length !== 0) {
      setCaregivers(prevState => [...prevState, ...caregiverList]);
    }
  }, [caregiverList]);

  useEffect(() => {
    if (searchError) {
      return setCaregivers([]);
    }
    if (searchedCaregiversPage > 0) {
      setCaregivers(prevState => [...prevState, ...searchedCaregivers]);
    } else {
      setCaregivers(searchedCaregivers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedCaregivers, searchError]);

  const scrollAction = () => {
    if (isFetching && caregiverList.length && nextPage && !isSearching) {
      setPage(parseInt(nextPage, 10));
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedCaregivers.length &&
      nextPageForSearchedCaregivers &&
      isSearching
    ) {
      setSearchedCaregiversPage(parseInt(nextPageForSearchedCaregivers, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedCaregiversPage(0);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setCaregivers([]);
      setPage(0);
      setRefetch();
    }
  };

  const render = (rowValues: any) => (
    <CaregiverTableRow
      key={rowValues.id}
      values={rowValues}
      onSelect={onSelect}
      isSelected={selectedCaregiverId === rowValues.id}
      setActiveTab={setActiveTab}
    />
  );

  const isDataAvailable = caregivers.length > 0;

  return (
    <PanelList
      title={CAREGIVERS_TITLE}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={CAREGIVERS_SEARCH_INPUT_NAME}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<Caregiver>
          columns={CaregiverListTableColumns}
          rows={caregivers}
          placeholder={CAREGIVERS_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}
    </PanelList>
  );
};
