import React from 'react';
import useForm from 'react-hook-form';
import { Link, RouteProps } from 'react-router-dom';

import { useAuthState } from 'Auth';
import { LoadingPlaceholder } from 'components';
import { usePoster } from 'hooks';
import { ReactComponent as Chevron } from 'icons/chevron-left.svg';

import { triggerResetPassword } from './actions';
import { LoginInput } from './LoginInput';
import { LoginTemplate } from './LoginTemplate';

import styles from './ForgotPassword.module.css';

export const ForgotPassword: React.FunctionComponent<RouteProps> = ({
  location
}) => {
  const { isAuthenticated } = useAuthState();
  const { register, errors, triggerValidation, getValues } = useForm<{
    email: string;
  }>({
    mode: 'onBlur',
    submitFocusError: false
  });
  const { data: status, setAction, error, loading } = usePoster();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const { email } = getValues();
      setAction(triggerResetPassword(email));
    }
  };

  const explicitEmailError =
    errors.email && errors.email.type === 'pattern'
      ? 'please enter a valid e-mail address.'
      : undefined;

  const explicitError = error && 'something went wrong, please try again.';

  const successMessage =
    status === 204 ? (
      <h2 className={styles.success}>email sent.</h2>
    ) : (
      undefined
    );

  const feedbackMessage = explicitEmailError || explicitError || successMessage;

  return (
    <>
      <LoginTemplate isAuthenticated={isAuthenticated} location={location}>
        <h1 className={styles.title}>forgot your password?</h1>
        <h1 className={styles.details}>
          please enter your email address and we'll email you a link to change
          it.
        </h1>
        <form onSubmit={handleSubmit}>
          <LoginInput
            name="email"
            placeholder="email address"
            requiredValueMissing={!!errors.email}
            feedbackMessage={feedbackMessage}
            forwardedRef={register({
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'please enter a valid e-mail address.'
              }
            })}
          />
          {loading ? (
            <div className={styles.loading}>
              <LoadingPlaceholder />
            </div>
          ) : (
            <button
              className={styles.confirm}
              onClick={handleSubmit}
              disabled={loading || !!errors.email}
            >
              send password link
            </button>
          )}
        </form>
        <Link
          to={'/login'}
          data-testid="back-test-button"
          className={styles.back}
        >
          <Chevron className={styles.backIcon} />
        </Link>
      </LoginTemplate>
    </>
  );
};
