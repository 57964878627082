const RESIDENT = 'Resident';
const PATIENT = 'Patient';

export const residentLabels: { [key: string]: string } = {
  'Assisted Living': RESIDENT,
  'Care at Home': RESIDENT,
  Clinic: PATIENT,
  'Critical Access': PATIENT,
  Hospice: RESIDENT,
  Hospital: PATIENT,
  'Independent Living': RESIDENT,
  'Mental Health Care': PATIENT,
  'Rehabilitation Center': PATIENT,
  'Retirement Community': RESIDENT,
  'Skilled Nursing': RESIDENT,
  'Veteran Administration': PATIENT
};
