import React, { FunctionComponent, useMemo } from 'react';

import { RoundCrossButton } from 'components';
import { LoginId } from 'types';
import { formatName } from 'utils';

import styles from './ParticipantDetails.module.css';

interface IParticipantDetails {
  name: string;
  loginId: LoginId;
  role: string;
  onRemove: (loginId: LoginId) => void;
}

export const ParticipantDetails: FunctionComponent<IParticipantDetails> = ({
  name,
  loginId,
  role,
  onRemove
}) => {
  const handleClick = () => {
    onRemove(loginId);
  };
  const formattedName = useMemo(() => formatName(name), [name]);

  return (
    <li className={styles.participantDetails}>
      <RoundCrossButton onClick={handleClick} testId="remove-participant" />
      <span className={styles.participantName}>{formattedName}</span>
      <span>{role}</span>
    </li>
  );
};
