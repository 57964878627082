import React, { useCallback, useMemo, useState } from 'react';

import {
  AccessMessage,
  FetchError,
  LoadingPlaceholder,
  Snackbar
} from 'components';
import { useLocationState } from 'Location';

import { AdHocChatDetails } from './AdHocChatDetails';
import { AdHocCreate } from './AdHocCreate';
import { ChatWindow } from './ChatWindow';
import { useAdHocContext } from './contexts';
import {
  FacilityCaregiversProvider,
  useFacilityCaregiversContext
} from './contexts/facility-caregivers.context';
import { MessagingHistoryList } from './MessagingHistoryList';
import { NewMessageWindow } from './NewMessageWindow';

import styles from './Communication.module.css';

const Communication: React.FunctionComponent = () => {
  const [error, setError] = useState(false);
  const [showNewMessage, setShowNewMessage] = useState<boolean>(false);
  const [showCreateAdHoc, setShowCreateAdHoc] = useState<boolean>(false);

  const { adHocChatId, adHocName } = useAdHocContext();
  const { facilityCaregivers } = useFacilityCaregiversContext();

  const {
    filtersLoading,
    filtersError,
    ward: wardId,
    facility: { id: facilityId },
    selectedWardDetails
  } = useLocationState();

  const selectConversation = useCallback(() => {
    setShowNewMessage(false);
    setShowCreateAdHoc(false);
  }, []);

  const toggleCreateAdHoc = useMemo(() => {
    if (
      !selectedWardDetails?.services.secureCommunication.features.scAdhoc
        .isEnabled
    ) {
      return undefined;
    }
    return () => setShowCreateAdHoc(!showCreateAdHoc);
  }, [selectedWardDetails, showCreateAdHoc]);

  const toggleShowNew = useMemo(() => {
    if (
      selectedWardDetails?.services.secureCommunication.features.scMessaging
        .isEnabled ||
      selectedWardDetails?.services.secureCommunication.features.scCareTeam
        .isEnabled ||
      selectedWardDetails?.services.secureCommunication.features
        .scRelatedContact.isEnabled
    ) {
      return () => {
        setShowNewMessage(!showNewMessage);
      };
    }
  }, [selectedWardDetails, showNewMessage]);

  const dismissError = () => setError(false);

  return (
    <>
      <div className={styles.communicationContainer}>
        {wardId && !filtersLoading && !filtersError && (
          <>
            <MessagingHistoryList
              onAdHocOpen={toggleCreateAdHoc}
              caregivers={facilityCaregivers}
              onSelect={selectConversation}
              onAdd={toggleShowNew}
              isSearchOn={showNewMessage || showCreateAdHoc}
            />
            <div className={styles.chatArea}>
              {!showCreateAdHoc && (
                <ChatWindow
                  title={adHocName}
                  caregivers={facilityCaregivers}
                  showButtons={Boolean(!adHocChatId)}
                />
              )}
              {showCreateAdHoc && !showNewMessage && toggleCreateAdHoc && (
                <AdHocCreate onClose={toggleCreateAdHoc} />
              )}

              {adHocChatId && !showCreateAdHoc && (
                <AdHocChatDetails
                  chatId={adHocChatId}
                  chatName={adHocName}
                  facilityId={facilityId}
                />
              )}
            </div>

            {!showCreateAdHoc && toggleShowNew && (
              <NewMessageWindow
                isOpen={showNewMessage}
                onClose={toggleShowNew}
                setOnError={setError}
                caregivers={facilityCaregivers}
              />
            )}
          </>
        )}
        {!filtersLoading && <AccessMessage />}
        {filtersLoading && !filtersError && <LoadingPlaceholder />}
        {!filtersLoading && filtersError && <FetchError />}
      </div>
      <Snackbar
        isOpen={error}
        message="An error has occurred. Please try again."
        onClose={dismissError}
      />
    </>
  );
};

export const CommunicationWithProvider = () => {
  return (
    <FacilityCaregiversProvider>
      <Communication />
    </FacilityCaregiversProvider>
  );
};
