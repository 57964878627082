import React from 'react';
import { Link } from 'react-router-dom';

import styles from './BackButton.module.css';

import { ReactComponent as Chevron } from 'icons/chevron-left.svg';

export const BackButton: React.FunctionComponent<{
  title?: string;
  link: string;
}> = ({ title, link }) => (
  <div className={styles.backButton}>
    <Link to={link} data-testid="back-test-button">
      <Chevron className={styles.chevron} />
    </Link>
    {title && <span className={styles.title}>{title}</span>}
  </div>
);
