import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { ActivityChartLine } from '../ActivityChartLine';
import { LAST_BATH_COLOR } from '../chartColors.const';
import { lastBathInterval } from '../Intervals';
import { LastBathTooltip } from '../Tooltips/LastBathTooltip';

export const LastBathChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    steps?: number;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={lastBathInterval}
      data={data}
      tooltip={LastBathTooltip}
      lineOne={ActivityChartLine(LAST_BATH_COLOR, 'baths')}
      color={LAST_BATH_COLOR}
      allowHighlight={allowHighlight}
      invertColors={true}
    />
  );
};
