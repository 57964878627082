import {
  ChatContact,
  ChatMessage,
  ChatMessageTypes,
  Message,
  Reader
} from 'Communication/types';
import { allowedMessageTypes, getMessageContent } from 'Communication/utils';
import { CaregiverProfileDetails } from 'UserProfile/types';
import { formatName } from 'utils';

export const formatResidentDashMessages = (
  contact: ChatContact,
  user: CaregiverProfileDetails & {
    status: undefined;
  },
  rawMessages: Message[],
  loginId: string
): ChatMessage[] => {
  const filteredRawMessages = rawMessages.filter(message =>
    allowedMessageTypes.includes(message.messageType)
  );
  const messageSeenMessages = rawMessages.filter(
    ({ messageType }) => messageType === ChatMessageTypes.MESSAGE_SEEN_MESSAGE
  );

  const myLastMessageId = filteredRawMessages.find(
    ({ loginSenderId }) => loginSenderId === loginId
  )?.id;

  return filteredRawMessages.map(rawMessage => {
    const sender =
      rawMessage.loginSenderId === contact.loginId ? contact : user;
    const formattedSenderName = formatName(sender.name);

    const participant =
      rawMessage.participantLoginId === contact.loginId ? contact : user;
    const formattedParticipantName = formatName(participant.name);

    const textContent = getMessageContent(
      rawMessage,
      formattedParticipantName,
      formattedSenderName
    );

    const readBy = composeReadBy(
      rawMessage,
      myLastMessageId,
      messageSeenMessages,
      contact
    );

    return {
      author: {
        id:
          (sender as ChatContact).userId ||
          (sender as CaregiverProfileDetails).id,
        loginId: sender.loginId!,
        name: sender.name,
        photoUrl: sender.photoUrl,
        role: sender.role,
        status: sender.status,
        relationship: sender.relationship,
        residentId: (sender as ChatContact).residentId
      },
      content: textContent,
      conversationId: rawMessage.chatRoomId,
      id: rawMessage.id,
      readBy: readBy as Reader[],
      sentAt: rawMessage.timestamp as string,
      type: rawMessage.messageType,
      fileName: rawMessage.fileName,
      fileUri: rawMessage.fileUri
    };
  });
};

const composeReadBy = (
  message: Message,
  myLastMessageId: string | undefined,
  seenMessages: Message[],
  contact: ChatContact
): ChatContact[] => {
  if (message.id === myLastMessageId) {
    const messageWasRead = seenMessages.find(
      ({ messageThatWasSeen }) => messageThatWasSeen === myLastMessageId
    );

    return messageWasRead ? [contact] : [];
  }
  return [];
};
