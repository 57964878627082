import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import { isLastMessageSeen } from 'Communication';
import {
  createChatRoom,
  getMessageHistory,
  markMessageSeen,
  sendChatMessage,
  uploadFile
} from 'Communication/actions';
import { ChatMessages } from 'Communication/ChatMessages';
import { MessageInputBox } from 'Communication/MessageInputBox';
import {
  ChatContact,
  ChatMessage,
  ChatMessageType,
  ChatMessageTypes,
  ChatRoom,
  ConversationTypes,
  Message,
  Participant,
  Reader
} from 'Communication/types';
import { FetchError } from 'components';
import { useFetcher } from 'hooks';
import { formatResidentDashMessages } from 'Residents/utils';
import { ComUpdateTypes, useSocketState } from 'Socket';
import { ChatId } from 'types';
import { CaregiverProfileDetails } from 'UserProfile';
import { formatName } from 'utils';

import styles from './ResidentDashChatWindow.module.css';

interface IResidentDashChatWindowProps {
  chatContact?: ChatContact;
  currentChat?: ChatRoom;
  chatFetching: boolean;
  chatError: Error | null;
}

export const ResidentDashChatWindow: React.FunctionComponent<IResidentDashChatWindowProps> = ({
  chatContact,
  currentChat,
  chatError,
  chatFetching
}) => {
  const [title, setTitle] = useState<string>('Messaging');
  const [currentChatId, setCurrentChatId] = useState<ChatId | undefined>(
    undefined
  );
  const [page, setPage] = useState<string>('0');
  const [formattedMessages, setFormattedMessages] = useState<ChatMessage[]>([]);
  const [scrollToNewestMessage, setScrollToNewestMessage] = useState(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [receivedNewMessage, setReceivedNewMessage] = useState<boolean>(false);
  const [lastMessageWasSeen, setLastMessageWasSeen] = useState<boolean>(false);

  const { loggedUser, loginId: currentUserLoginId } = useAuthState();
  const { comUpdate } = useSocketState();

  useEffect(() => {
    setCurrentChatId(currentChat?.id);
    setFormattedMessages([]);
    setPage('0');
  }, [currentChat]);

  const getMessagesAction = useMemo(() => {
    if (page === undefined || !currentChatId) {
      return undefined;
    }
    return getMessageHistory(currentChatId, page);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentChatId]);

  const {
    data: { items: fetchedMessages, nextPage: messagesNextPage },
    loading: messagesFetching,
    error: messagesError
  } = useFetcher<{ items: Message[]; nextPage?: string }>(getMessagesAction, {
    items: [],
    nextPage: undefined
  });

  useEffect(() => {
    if (chatContact) {
      const formattedName = formatName(chatContact.name);
      setTitle(`Messaging with ${formattedName}`);
    } else {
      setTitle('Messaging');
    }
  }, [chatContact]);

  const handlePagination = () => {
    if (fetching || error || !messagesNextPage) {
      return undefined;
    }
    setPage(messagesNextPage);
  };

  useEffect(() => {
    if (chatFetching || messagesFetching) {
      setFetching(true);
    } else {
      setFetching(false);
    }
  }, [chatFetching, messagesFetching]);

  useEffect(() => {
    if (chatError || messagesError) {
      setError(chatError || messagesError);
    } else {
      setError(null);
    }
  }, [chatError, messagesError]);

  useEffect(() => {
    if (
      chatContact &&
      fetchedMessages &&
      currentUserLoginId &&
      !messagesFetching &&
      !messagesError
    ) {
      const filteredMessages = fetchedMessages.filter(
        msg => !formattedMessages.find(prevMsg => msg.id === prevMsg.id)
      );

      const newMessages = formatResidentDashMessages(
        chatContact,
        loggedUser as CaregiverProfileDetails & {
          status: undefined;
        },
        filteredMessages,
        currentUserLoginId
      );
      setFormattedMessages(prevMessages => [...newMessages, ...prevMessages]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesError, messagesFetching, fetchedMessages, currentUserLoginId]);

  // receive a message
  useEffect(() => {
    if (
      !comUpdate ||
      messagesFetching ||
      messagesError ||
      (comUpdate.messageType !== ComUpdateTypes.FILE_MESSAGE &&
        comUpdate.messageType !== ComUpdateTypes.TEXT_MESSAGE)
    ) {
      return undefined;
    }

    if (!currentChatId) {
      return undefined;
    }

    if (currentChatId && comUpdate.chatRoomId !== currentChatId) {
      return undefined;
    }

    let authorId;

    switch (comUpdate.loginSenderId) {
      case currentUserLoginId: {
        authorId = loggedUser?.id;
        break;
      }
      case chatContact?.loginId: {
        authorId = chatContact?.userId;
        break;
      }
      default: {
        authorId = '';
        break;
      }
    }

    const author: Participant = {
      id: authorId as string,
      loginId: comUpdate.loginSenderId,
      name:
        comUpdate.loginSenderId === currentUserLoginId
          ? loggedUser!.name
          : chatContact!.name!,
      photoUrl:
        comUpdate.loginSenderId === currentUserLoginId
          ? loggedUser!.photoUrl
          : chatContact!.photoUrl,
      role:
        comUpdate.loginSenderId === currentUserLoginId
          ? loggedUser!.role
          : chatContact!.role,
      status:
        comUpdate.loginSenderId === currentUserLoginId
          ? undefined
          : chatContact!.status,
      relationship:
        comUpdate.loginSenderId === currentUserLoginId
          ? undefined
          : chatContact?.relationship,
      residentId:
        comUpdate.loginSenderId === currentUserLoginId
          ? undefined
          : chatContact?.relationship
    };

    const receivedMessage = {
      id: comUpdate.id,
      author,
      content: comUpdate.values?.textMessage,
      conversationId: comUpdate.chatRoomId,
      readBy: [],
      sentAt: comUpdate.timestamp,
      type: comUpdate.messageType.toLowerCase() as ChatMessageType,
      fileName: comUpdate.values?.fileName,
      fileUri: comUpdate.values?.fileUri
    };

    if (receivedMessage.author.loginId !== currentUserLoginId) {
      setReceivedNewMessage(true);
    }

    setFormattedMessages(previousMessages => [
      receivedMessage as ChatMessage,
      ...previousMessages
    ]);

    setScrollToNewestMessage(true);
  }, [
    chatContact,
    comUpdate,
    currentChatId,
    currentUserLoginId,
    loggedUser,
    messagesError,
    messagesFetching
  ]);

  useEffect(() => {
    if (!comUpdate) {
      return undefined;
    }

    if (comUpdate.messageType === ComUpdateTypes.MESSAGE_SEEN_MESSAGE) {
      const newMessages = formattedMessages.reduce((acc, msg) => {
        if (
          msg.author.loginId === currentUserLoginId &&
          comUpdate.values.messageThatWasSeen === msg.id
        ) {
          acc.push({
            ...msg,
            readBy: [chatContact as Reader]
          });
        } else {
          acc.push(msg);
        }
        return acc;
      }, [] as ChatMessage[]);
      setFormattedMessages(newMessages);
      setScrollToNewestMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedMessages, comUpdate]);

  const handleSendText = useCallback(
    async (textMessage: string) => {
      if (chatContact?.loginId === undefined) {
        return undefined;
      }

      if (currentChatId) {
        setFetching(true);
        try {
          await sendChatMessage(currentChatId, textMessage);
        } catch (error) {
          /**
           * @description if we have a sending error add an errorId to the message
           * and add the message to the list
           * the MessageError component will appear
           */
          setFormattedMessages(prev => [
            ...prev,
            {
              author: {
                id: loggedUser!.id,
                loginId: loggedUser!.loginId,
                name: loggedUser!.name,
                photoUrl: loggedUser?.photoUrl,
                role: loggedUser?.role,
                status: undefined,
                relationship: chatContact.relationship,
                residentId: chatContact.residentId
              },
              errorId: Math.random().toString(),
              content: textMessage,
              conversationId: currentChatId,
              id: Date.now().toString(),
              sentAt: new Date().toISOString(),
              readBy: [],
              type: ChatMessageTypes.TEXT_MESSAGE
            }
          ]);
        } finally {
          setFetching(false);
        }
      } else if (!fetching) {
        setFetching(true);
        try {
          const description = `${loggedUser?.name} and ${chatContact.name} chat room`;
          const chatType = !!chatContact.residentId
            ? ConversationTypes.RELATED
            : ConversationTypes.CAREGIVER;

          await createChatRoom(
            [chatContact.loginId, currentUserLoginId],
            chatType,
            textMessage,
            description,
            chatContact.residentId
          );
        } catch (error) {
          setError(error as Error);
        } finally {
          setFetching(false);
        }
      }
    },
    [chatContact, currentChatId, currentUserLoginId, fetching, loggedUser]
  );

  const handleSendFile = useCallback(
    async (file: File) => {
      if (chatContact?.loginId === undefined) {
        return undefined;
      }

      if (currentChatId && !fetching) {
        setFetching(true);
        try {
          await uploadFile(file, currentChatId, currentUserLoginId);
        } catch (error) {
          /**
           * @description if we have a sending error add an errorId to the message
           * and add the message to the list
           * the MessageError component will appear
           */
          setFormattedMessages(prev => [
            ...prev,
            {
              author: {
                id: loggedUser!.id,
                loginId: loggedUser!.loginId,
                name: loggedUser!.name,
                photoUrl: loggedUser?.photoUrl,
                role: loggedUser?.role,
                status: undefined,
                relationship: chatContact.relationship,
                residentId: chatContact.residentId
              },
              errorId: Math.random().toString(),
              content: file,
              conversationId: currentChatId,
              id: Date.now().toString(),
              sentAt: new Date().toISOString(),
              readBy: [],
              type: ChatMessageTypes.FILE_MESSAGE
            }
          ]);
        } finally {
          setFetching(false);
        }
      } else if (!fetching) {
        setFetching(true);
        try {
          const description = `${loggedUser?.name} and ${chatContact.name} chat room`;
          const chatType = !!chatContact.residentId
            ? ConversationTypes.RELATED
            : ConversationTypes.CAREGIVER;

          const { chatRoomId: newChatId } = await createChatRoom(
            [chatContact.loginId, currentUserLoginId],
            chatType,
            null,
            description,
            chatContact.residentId
          );

          await uploadFile(file, newChatId, currentUserLoginId);
        } catch (error) {
          setError(error as Error);
        } finally {
          setFetching(false);
        }
      }
    },
    [chatContact, currentChatId, currentUserLoginId, fetching, loggedUser]
  );

  const handleCancelMessage = async (message: ChatMessage) => {
    const filteredMessages = formattedMessages.filter(
      entry => entry.errorId !== message.errorId
    );

    setFormattedMessages(filteredMessages);
  };

  const handleRetrySend = async (message: ChatMessage) => {
    await handleCancelMessage(message);
    if (message.type === ChatMessageTypes.TEXT_MESSAGE) {
      await handleSendText(message.content);
    } else {
      await handleSendFile(message.content);
    }
  };

  const handleMessageInputFocus = () => {
    if (!currentChat) {
      return undefined;
    }
    // check if last message was already seen
    if (
      !isLastMessageSeen(currentChat, currentUserLoginId) &&
      currentChat.lastMessage &&
      !lastMessageWasSeen
    ) {
      // if not, send message_seen_message
      markMessageSeen(currentChat.id, currentChat.lastMessage.id);
      setLastMessageWasSeen(true);
    }

    // if we receive a new message on ComUpdate
    if (receivedNewMessage) {
      // if so, send message_seen_message for new message that we received
      markMessageSeen(currentChat.id, formattedMessages[0].id);
      setReceivedNewMessage(false);
    }
  };

  return (
    <section
      aria-labelledby="messaging"
      className={classnames(styles.chatWindowContainer, {
        [styles.hidden]: chatContact?.loginId === currentUserLoginId
      })}
    >
      {chatContact?.loginId !== currentUserLoginId && (
        <>
          <header className={styles.header}>
            <span id="messaging" className={styles.title}>
              {title}
            </span>
          </header>
          <div className={styles.chatBody}>
            {!currentChatId && (
              <h4 className={styles.emptyPlaceholder}>
                Please select an entry from the conversations list to view
                latest messages
              </h4>
            )}
            <ChatMessages
              messages={formattedMessages}
              onCancel={handleCancelMessage}
              onRetry={handleRetrySend}
              onTrigger={handlePagination}
              error={error}
              scrollToNewestMessage={scrollToNewestMessage}
              resetScroll={setScrollToNewestMessage}
              loading={fetching}
              chatId={currentChatId}
            />
            <MessageInputBox
              onSend={handleSendText}
              onFileUpload={handleSendFile}
              clearOnSend={true}
              disabled={!chatContact || fetching}
              onInputFocus={handleMessageInputFocus}
            />
            {!fetching && error && (
              <div className={styles.errorContainer}>
                <FetchError />
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};
