import React from 'react';

import { FetchError, LoadingPlaceholder } from 'components';

export const WithSimpleFetch = (Component: any) => {
  return ({
    loading,
    error,
    ...rest
  }: {
    loading: boolean;
    error: boolean;
    [key: string]: any;
  }) => {
    if (loading) {
      return <LoadingPlaceholder />;
    } else if (error) {
      return <FetchError />;
    }
    return <Component {...rest} />;
  };
};
