import { axiosWrapper } from 'utils';

import { Facility, FacilityListResponse } from 'Settings';

export const getPaginatedFacilities = (
  page: string,
  idnId?: string
) => async () => {
  const initialUrl = `/facilities?page=${page}`;
  const url = idnId ? `${initialUrl}&idnId=${idnId}` : initialUrl;

  const response = await axiosWrapper<
    FacilityListResponse | { items: Facility[]; nextPage: string }
  >(url);
  return response.data;
};
