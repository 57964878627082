import React from 'react';

import { RoundButton } from './RoundButton';

import styles from './RoundCrossButton.module.css';

import { ReactComponent as CrossIcon } from 'icons/crossmark.svg';

export const RoundCrossButton: React.FunctionComponent<{
  onClick: () => void;
  testId?: string;
}> = ({ onClick, testId }) => (
  <RoundButton
    className={styles.roundCrossButton}
    onClick={onClick}
    testId={testId}
  >
    <CrossIcon />
  </RoundButton>
);
