import React from 'react';

import { ReactComponent as Location } from 'icons/location.svg';
import { DeviceLocation } from 'Location';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './ResidentLocationCard.module.css';

export const ResidentLocationCard: React.FunctionComponent<{
  residentLocation: DeviceLocation | undefined;
  onClick: () => void;
}> = ({ residentLocation, onClick }) => {
  const { room } = residentLocation || {};
  const roomName = room && room.number;
  const wardName = room && room.ward && room.ward.name;

  return (
    <div className={style.residentLocation} onClick={onClick}>
      <ActivityInfoCard
        reading={wardName}
        unit={roomName}
        type="residentLocation"
      >
        <Location />
      </ActivityInfoCard>
    </div>
  );
};
