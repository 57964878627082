import { CallTypes, IMissedCall, IMissedCallCount } from 'Communication/types';

export const missedCallsReducerFunction = (
  acc: IMissedCallCount[],
  call: IMissedCall
) => {
  const incrementAudio = Number(call.callType === CallTypes.AUDIO);
  const incrementVideo = Number(call.callType === CallTypes.VIDEO);

  const chat = acc.find(accItem => call.chatRoomId === accItem.chatRoomId);

  if (chat) {
    chat.audioMissedCallCount += incrementAudio;
    chat.videoMissedCallCount += incrementVideo;
  } else {
    acc.push({
      chatRoomId: call.chatRoomId,
      audioMissedCallCount: incrementAudio,
      videoMissedCallCount: incrementVideo
    });
  }

  return acc;
};
