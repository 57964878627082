import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { FacilityAccessListResponse } from '../types/access.type';

export const getCaregiverAccessToFacilities = (
  caregiverId: string
) => async () => {
  const response = await axiosWrapper<FacilityAccessListResponse>(
    `/caregivers/${caregiverId}/accesses/facilities?size=${MAX_PAGE_SIZE}`
  );
  return response.data.items;
};
