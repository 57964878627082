import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { DeviceLocationListResponse } from '../types';

export const getDevicesLocation = (
  facilityId: string,
  wardId: string
) => async () => {
  const additionalQueryParams = wardId
    ? new URLSearchParams({
        wardId,
        size: MAX_PAGE_SIZE.toString()
      })
    : new URLSearchParams({
        size: MAX_PAGE_SIZE.toString()
      });

  const urlString = `/facilities/${facilityId}/devices-location?${additionalQueryParams}`;

  const response = await axiosWrapper<DeviceLocationListResponse>(urlString);

  return response.data.items;
};
