import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { RoundTrashButton } from 'components';

import { blobToBase64 } from 'utils';
import styles from './FilePreview.module.css';

const convertAndSetFile = async (
  file: Blob,
  setter: Dispatch<SetStateAction<FileReader['result']>>
) => {
  const imageUrl = await blobToBase64(file);
  setter(imageUrl);
};

export const FilePreview: React.FunctionComponent<{
  file: File;
  onSaveFile: (file: File) => void;
  onRemoveFile: () => void;
}> = ({ file, onSaveFile, onRemoveFile }) => {
  const [preview, setPreview] = useState<FileReader['result']>(null);

  useEffect(() => {
    convertAndSetFile(file as Blob, setPreview);
  }, [file]);

  const handleUploadClick = () => {
    onSaveFile(file);
  };

  return (
    <div className={styles.previewContainer}>
      <div
        className={styles.imagePreview}
        style={{ backgroundImage: `url('${preview}')` }}
      >
        <RoundTrashButton onClick={onRemoveFile} />
      </div>
      <button className={styles.uploadButton} onClick={handleUploadClick}>
        Upload File
      </button>
    </div>
  );
};
