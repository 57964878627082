import { ChatId, LoginId } from 'types';
import { axiosWrapper } from 'utils';

export const deleteParticipantFromChatRoom = async (
  chatRoomId: ChatId,
  participantId: LoginId
) => {
  const url = `/chat-rooms/${chatRoomId}/participants/${participantId}`;

  await axiosWrapper(url, 'delete');
};
