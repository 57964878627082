import { useCallback, useEffect, useState } from 'react';

interface SubmitErrorHook {
  isErrorShowing: boolean;
  submitted: boolean;
  setSubmitted: any;
  dismissError: () => void;
}
export function useSubmitError(
  error: any,
  loadingOnSubmit: boolean
): SubmitErrorHook {
  const [isErrorShowing, setIsErrorShowing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const dismissError = useCallback(() => {
    setIsErrorShowing(false);
    setSubmitted(false);
  }, []);

  useEffect(() => {
    if (submitted && error && !loadingOnSubmit) {
      setIsErrorShowing(true);
    }
    if (!error) {
      setIsErrorShowing(false);
    }
  }, [error, submitted, loadingOnSubmit]);

  return {
    isErrorShowing,
    submitted,
    setSubmitted,
    dismissError
  };
}
