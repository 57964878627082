import classnames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';

import { RoomAssignment } from 'Caregivers';
import { FetchError, LoadingPlaceholder } from 'components';
import { useFetcher, useModal } from 'hooks';
import { useLocationContext } from 'Location';
import { NotificationDetail, useNotificationState } from 'Notifications';
import {
  getResidentsByWard,
  getRoomAssignments,
  getRoomsByWard,
  ResidentListItem
} from 'Residents';
import { Resident, Room } from 'Settings';

import { ManageResidentsList } from './ManageResidentsList';
import { ResidentListEntry } from './ResidentListEntry';
import { ResidentListHeader } from './ResidentListHeader';

import style from './AssignedResidentsList.module.css';

export const AssignedResidentsList: React.FunctionComponent<{
  caregiverId: string;
}> = ({ caregiverId }) => {
  const {
    location: { facility, ward: wardId },
    label
  } = useLocationContext();
  const { notifications } = useNotificationState();
  const {
    isShowing: isActionModalOpen,
    toggle: toggleActionModal
  } = useModal();

  const roomAssignmentsAction = useMemo(() => {
    if (caregiverId) {
      const workingDay = moment().format('YYYY-MM-DD');
      return getRoomAssignments({ workingDay, caregiverId });
    }
  }, [caregiverId]);

  const residentsByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getResidentsByWard(facility.id, wardId, true);
    }
  }, [facility.id, wardId]);

  const roomsByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getRoomsByWard(facility.id, wardId);
    }
  }, [facility.id, wardId]);

  const {
    data: roomAssignments,
    error: roomAssignmentsError,
    loading: roomAssignmentsLoading,
    setRefetch: refetchRoomAssignments
  } = useFetcher<RoomAssignment[]>(roomAssignmentsAction, []);

  const {
    data: residentsByWard,
    error: residentsError,
    loading: residentsLoading
  } = useFetcher<{
    items: Resident[];
  }>(residentsByWardAction, { items: [] });

  const {
    data: roomsByWard,
    error: roomsError,
    loading: roomsLoading
  } = useFetcher<{
    items: Room[];
  }>(roomsByWardAction, { items: [] });

  const loading = roomAssignmentsLoading || residentsLoading || roomsLoading;
  const fetchError = roomAssignmentsError || residentsError || roomsError;

  const assignedResidents = useMemo(() => {
    if (!roomsByWard.items.length || !roomAssignments.length) {
      return [];
    }

    return roomsByWard.items.reduce(
      (roomResidents: ResidentListItem[], currentRoom) => {
        const { id: currentRoomId, number: roomNumber, ward } = currentRoom;
        if (
          currentRoomId &&
          !roomAssignments.some(({ roomId }) => currentRoomId === roomId)
        ) {
          return roomResidents;
        }
        const residentsFromRoom: ResidentListItem[] = residentsByWard.items.reduce(
          (items: ResidentListItem[], resident) => {
            const { id, room, gender, name, photoUrl } = resident;

            if (room && room.ward && room.id === currentRoomId) {
              return [
                ...items,
                {
                  resident: {
                    id,
                    name,
                    gender,
                    photoUrl
                  },
                  room,
                  hasWarning: notifications.some(
                    (notification: NotificationDetail) =>
                      notification.personOfInterest?.id === id
                  )
                } as ResidentListItem
              ];
            }
            return items;
          },
          [] as ResidentListItem[]
        );

        const currentRoomResidents = residentsFromRoom.length
          ? residentsFromRoom
          : [
              {
                room: { id: currentRoomId, number: roomNumber, ward }
              } as ResidentListItem
            ];

        return [...roomResidents, ...currentRoomResidents];
      },
      []
    );
  }, [
    roomsByWard.items,
    roomAssignments,
    residentsByWard.items,
    notifications
  ]);

  const content = assignedResidents.map((listItem: ResidentListItem) => (
    <ResidentListEntry
      key={(listItem.resident && listItem.resident.id) || listItem.room.id}
      data={listItem}
    />
  ));

  const noDataMessage = caregiverId
    ? `There are no ${label.toLowerCase()}s assigned to this caregiver.`
    : `No ${label.toLowerCase()} information available.`;

  const ResidentsList = content.length ? (
    <ul>{content}</ul>
  ) : (
    <p className={style.listPlaceholder}>{noDataMessage}</p>
  );

  return (
    <>
      <div
        className={classnames(style.list, { [style.hasError]: !!fetchError })}
        id={`ResidentsList`}
      >
        {!fetchError && (
          <ResidentListHeader
            onEdit={toggleActionModal}
            caregiverId={caregiverId}
          />
        )}
        {loading && !fetchError && <LoadingPlaceholder />}
        {!loading && !fetchError && ResidentsList}
        {fetchError && !loading && <FetchError error={fetchError} />}
      </div>
      <ManageResidentsList
        availableResidents={residentsByWard.items}
        availableRooms={roomsByWard.items}
        assignedResidents={assignedResidents}
        isOpen={isActionModalOpen}
        onClose={toggleActionModal}
        onChange={refetchRoomAssignments}
        wardId={wardId}
        caregiverId={caregiverId}
      />
    </>
  );
};
