import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelList,
  Table
} from 'components';
import {
  useFetcher,
  useInfiniteScroll,
  useModal,
  useModalMeasurements
} from 'hooks';
import {
  getPaginatedResidentContacts,
  getResidentContactByQuery,
  ResidentContact
} from 'Settings';

import { AddRelatedContactForm } from './AddRelatedContactForm';
import { EditRelatedContact } from './EditRelatedContact';
import { ResidentContactTableRow } from './ResidentContactTableRow';

import stylesModal from 'components/Modal/Modal.module.css';
import {
  DEFAULT_PAGE,
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH,
  RELATED_CONTACT_LIST_PLACEHOLDER,
  RELATED_CONTACT_SEARCH_INPUT_NAME,
  RELATED_CONTACT_TITLE
} from 'Settings/constants';

const ResidentListTableColumns: Column[] = [
  { name: 'Full Name', align: 'left', cols: 2 },
  { name: 'Relationship', align: 'left', cols: 2 },
  { name: 'E-mail Address', align: 'left', cols: 3 },
  { name: 'Phone Number', align: 'left', cols: 2 },
  { name: 'Status', align: 'center', cols: 1 }
];

export const ResidentContactList: React.FunctionComponent<{
  residentId: string;
  label: string;
}> = ({ residentId, label }) => {
  const [page, setPage] = useState<string>(DEFAULT_PAGE);
  const [
    searchedResidentContactsPage,
    setSearchedResidentContactsPage
  ] = useState(DEFAULT_PAGE);
  const [residentContacts, setResidentContacts] = useState<ResidentContact[]>(
    []
  );
  const [selectedContact, setSelectedContact] = useState({ id: '' });
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  useEffect(() => {
    setPage(DEFAULT_PAGE);
    setResidentContacts([]);
  }, [residentId]);

  const getContactsMemo = useMemo(
    () => getPaginatedResidentContacts(residentId, page),
    [residentId, page]
  );

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getResidentContactByQuery(
        residentId,
        searchTerm,
        searchedResidentContactsPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedResidentContactsPage]);

  const {
    data: { items: residentContactList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: ResidentContact[];
    nextPage?: string;
  }>(getContactsMemo, { items: [] });

  const {
    data: {
      items: searchedResidentContacts,
      nextPage: nextPageForSearchedResidentContacts
    },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{ items: ResidentContact[]; nextPage?: string }>(
    memoSearchAction,
    { items: [] }
  );

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchedResidentContactsPage(DEFAULT_PAGE);
      setSearchTerm(searchValue);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setResidentContacts([]);
      setPage(DEFAULT_PAGE);
      setRefetch();
    }
  };

  useEffect(() => {
    if (searchError) {
      return setResidentContacts([]);
    }
    if (searchedResidentContactsPage > DEFAULT_PAGE) {
      setResidentContacts(prevState => [
        ...prevState,
        ...searchedResidentContacts
      ]);
    } else {
      setResidentContacts(searchedResidentContacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedResidentContacts, searchError]);

  const { isShowing: isAddViewOpen, toggle: toggleAddView } = useModal();
  const { isShowing: isEditViewOpen, toggle: toggleEditView } = useModal();
  const { modalOffsetTop: addModalOffsetTop } = useModalMeasurements(
    'Related Contacts',
    'MainContent',
    isAddViewOpen
  );

  const modalClosed = () => {
    setResidentContacts([]);
    if (page === DEFAULT_PAGE) {
      setRefetch();
    } else {
      setPage(DEFAULT_PAGE);
    }
    toggleAddView();
  };

  const onContactChange = (updates: ResidentContact) => {
    const updatedContacts = residentContacts.map(contact =>
      contact.id === updates.id ? updates : contact
    );
    setResidentContacts(updatedContacts);
    toggleEditView();
  };

  const selectContact = (id: string) => {
    setSelectedContact({ id });
    toggleEditView();
  };

  const scrollAction = () => {
    if (isFetching && residentContactList.length && nextPage && !isSearching) {
      setPage(nextPage);
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedResidentContacts.length &&
      nextPageForSearchedResidentContacts &&
      isSearching
    ) {
      setSearchedResidentContactsPage(nextPageForSearchedResidentContacts);
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  useEffect(() => {
    if (residentContactList.length !== 0) {
      setResidentContacts(prevState => [...prevState, ...residentContactList]);
    }
  }, [residentContactList]);

  const render = (rowValues: any) => (
    <ResidentContactTableRow
      key={rowValues.id}
      values={rowValues}
      selectContact={selectContact}
      isSelected={selectedContact.id === rowValues.id}
    />
  );
  const isDataAvailable = residentContacts.length > 0;

  return (
    <PanelList
      title={RELATED_CONTACT_TITLE}
      onAdd={toggleAddView}
      hasError={!!error}
      onSearch={handleSearch}
      resetHeaderKey={residentId}
      searchInputName={RELATED_CONTACT_SEARCH_INPUT_NAME}
    >
      {(!loading || isDataAvailable) && !error && (
        <Table<ResidentContact>
          columns={ResidentListTableColumns}
          rows={residentContacts}
          placeholder={RELATED_CONTACT_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}

      <Modal isShowing={isAddViewOpen} toggle={toggleAddView}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentRightAlign}>
            <div
              className={stylesModal.modalRightWithVerticalBar}
              style={{
                marginTop: addModalOffsetTop
              }}
            >
              <AddRelatedContactForm
                close={modalClosed}
                residentId={residentId}
                label={label}
              />
            </div>
          </div>
        </div>
      </Modal>
      {selectedContact.id && (
        <EditRelatedContact
          residentId={residentId}
          contactId={selectedContact.id}
          isOpen={isEditViewOpen}
          onClose={toggleEditView}
          onSave={onContactChange}
          label={label}
        />
      )}
    </PanelList>
  );
};
