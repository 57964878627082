import React from 'react';

import { ReactComponent as Logo } from 'icons/th-logo-small.svg';

import authStyles from './AuthErrorScreen.module.css';

export const AuthErrorScreen: React.FunctionComponent<{
  onReload: () => void;
}> = ({ onReload }) => (
  <div className={authStyles.authErrorContainer}>
    <Logo className={authStyles.authErrorLogo} />
    <h1 className={authStyles.authErrorTitle}>
      Looks like your current session expired.
    </h1>
    <h3 className={authStyles.authErrorSubtitle}>
      Please reload to re-authenticate.
    </h3>
    <button className={authStyles.authButton} onClick={onReload}>
      reload
    </button>
  </div>
);
