import React, { useMemo } from 'react';

import { useLocationState } from 'Location';

import {
  AlarmsPriority,
  ColorCodes,
  dismissNotificationFetch,
  NotificationActionTypes,
  useNotificationContext
} from 'Notifications';

import { HomeView } from 'Home/types/homeview.types';
import { getHomePageStyle } from 'Home/utils/home-view.util';
import { AlarmPanel } from './AlarmPanel';

import styles from './HomepageAlarms.module.css';

export const HomepageAlarms: React.FunctionComponent<{
  view: HomeView;
}> = ({ view }) => {
  const [
    { notifications, fetchLoading, dismissLoading, fetchError: error },
    dispatch
  ] = useNotificationContext();

  const {
    ward: wardId,
    facility: { id: facilityId }
  } = useLocationState();

  const highPriorityAlarms = useMemo(
    () =>
      notifications.filter(({ colorCode }) => {
        return colorCode === ColorCodes.RED;
      }),
    [notifications]
  );

  const mediumPriorityAlarms = useMemo(
    () =>
      notifications.filter(({ colorCode }) => {
        return (
          colorCode === ColorCodes.ORANGE ||
          colorCode === ColorCodes.DARK_ORANGE
        );
      }),
    [notifications]
  );

  const lowPriorityAlarms = useMemo(
    () =>
      notifications.filter(({ colorCode }) => {
        return (
          colorCode !== ColorCodes.RED &&
          colorCode !== ColorCodes.ORANGE &&
          colorCode !== ColorCodes.DARK_ORANGE
        );
      }),
    [notifications]
  );

  const dismiss = async (id: string) => {
    await dismissNotificationFetch(dispatch, {
      notificationId: id,
      facilityId,
      wardId
    });
  };

  const refetch = () => {
    dispatch({ type: NotificationActionTypes.SET_REFETCH });
  };

  const customStyle = getHomePageStyle(view);

  return (
    <div className={styles.alarmsContainer}>
      <AlarmPanel
        title={AlarmsPriority.HIGH_PRIORITY_ALARMS}
        priority={AlarmsPriority.PRIORITY_HIGH}
        alarms={highPriorityAlarms}
        isLoading={fetchLoading}
        error={error}
        onDismiss={dismiss}
        actionDisabled={dismissLoading}
        onErrorClose={refetch}
        customStyle={customStyle}
      />
      <AlarmPanel
        title={AlarmsPriority.MEDIUM_PRIORITY_ALARMS}
        priority={AlarmsPriority.PRIORITY_MEDIUM}
        alarms={mediumPriorityAlarms}
        isLoading={fetchLoading}
        error={error}
        onDismiss={dismiss}
        actionDisabled={dismissLoading}
        onErrorClose={refetch}
        customStyle={customStyle}
      />
      <AlarmPanel
        title={AlarmsPriority.LOW_PRIORITY_ALARMS}
        priority={AlarmsPriority.PRIORITY_LOW}
        alarms={lowPriorityAlarms}
        isLoading={fetchLoading}
        error={error}
        onDismiss={dismiss}
        actionDisabled={dismissLoading}
        onErrorClose={refetch}
        customStyle={customStyle}
      />
    </div>
  );
};
