import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { ActivityChartLine } from '../ActivityChartLine';
import { SLEEPING_HOURS_COLOR } from '../chartColors.const';
import { sleepingHoursInterval } from '../Intervals';
import { SleepingHoursTooltip } from '../Tooltips/SleepingHoursTooltip';

export const SleepingHoursChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    seconds?: number;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={sleepingHoursInterval}
      data={data}
      tooltip={SleepingHoursTooltip}
      lineOne={ActivityChartLine(SLEEPING_HOURS_COLOR, 'hours')}
      color={SLEEPING_HOURS_COLOR}
      allowHighlight={allowHighlight}
      invertColors={true}
    />
  );
};
