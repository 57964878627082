import React from 'react';

import { VitalCard } from './VitalCard';

import { ReactComponent as Thermometer } from 'icons/thermometer.svg';
import { BodyTemperature, convertTimestamp } from 'Residents';

import style from './BodyTemperatureVitalCard.module.css';

export const BodyTemperatureVitalCard: React.FunctionComponent<{
  bodyTemperature: BodyTemperature | undefined;
  onClick: () => void;
}> = ({ bodyTemperature, onClick }) => {
  const { measuredAt, value, code, unit } = bodyTemperature || {};
  const reading = value && value.bodyTemperature;
  const hasWarning = !!code && code !== 'GREEN' && code !== 'YELLOW';

  return (
    <div className={style.bodyTemp} onClick={onClick}>
      <VitalCard
        title="Body Temperature"
        reading={reading}
        timestamp={measuredAt && convertTimestamp(measuredAt)}
        hasWarning={hasWarning}
        unit={unit}
      >
        <Thermometer />
      </VitalCard>
    </div>
  );
};
