import { useCallback, useEffect, useState } from 'react';

import { useAuthDispatch } from 'Auth';
import { handleAuthError, handleError } from 'utils';

export function useFetcher<T>(
  action: any,
  initialData: T
): {
  data: T;
  loading: boolean;
  error: Error | null;
  refetching: boolean;
  setRefetch: () => void;
} {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState(initialData);
  const [refetch, setRefetch] = useState({ redo: false });
  const { dispatch } = useAuthDispatch();

  const toggleRefetch = useCallback(() => {
    setRefetch({ redo: true });
  }, []);

  useEffect(() => {
    let unmounted = false;

    async function loadData() {
      try {
        if (!action) {
          setData(initialData);
          return;
        }
        setLoading(true);
        const actionData =
          typeof action === 'function' ? await action() : await action;
        if (!unmounted) {
          setData(actionData);
          setError(null);
        }
      } catch (error) {
        if (unmounted) {
          return;
        }
        setError(handleError(error));
        dispatch({ type: 'SET_AUTH_ERROR', payload: handleAuthError(error) });
      } finally {
        if (!unmounted) {
          setLoading(false);
        }
      }
    }
    loadData();
    return function cancel() {
      unmounted = true;
    };
    /**
     * DO NOT add initialData to deps array
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, dispatch, refetch]);

  return {
    data,
    error,
    loading,
    refetching: refetch.redo,
    setRefetch: toggleRefetch
  };
}
