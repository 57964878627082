import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { DatePickerCaption } from './DatePickerCaption';

import styles from './DatePicker.module.css';

const DATE_FORMAT = 'MM/DD/YYYY';
const { overlay, overlayWrapper, container } = styles;

export const DatePicker: React.FunctionComponent<{
  InputComponent: any;
  onChange: (date: Date | undefined) => void;
  initialValue?: Date;
}> = ({ InputComponent, onChange, initialValue }) => {
  const containerId = useRef(`datePicker-${Math.random()}`);
  const datePickerRef = useRef<DayPickerInput | null>(null);
  const datePickerContainerRef = useRef<HTMLElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialValue
  );
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  // on mount and unmount
  useEffect(() => {
    datePickerContainerRef.current = document.getElementById(
      containerId.current
    );

    const handleClick = (evt: any) => {
      if (
        datePickerRef.current !== null &&
        datePickerContainerRef.current !== null &&
        !datePickerContainerRef.current.contains(evt.target)
      ) {
        setIsOverlayVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  useEffect(() => {
    if (!isOverlayVisible && datePickerRef.current) {
      datePickerRef.current.hideDayPicker();
      onChange(selectedDate);
    }
  }, [isOverlayVisible, onChange, selectedDate]);

  const formatDate = (date: Date, format: string = DATE_FORMAT) => {
    return moment(date).format(format);
  };
  const parseDate = (date: string, format: string, locale: string) => {
    const formattedDate = moment(date, format, true);
    if (formattedDate.isValid()) {
      return formattedDate.toDate();
    }
    return undefined;
  };

  const onDayPickerShow = () => {
    setIsOverlayVisible(true);

    if (datePickerRef.current) {
      const { wrapper } = datePickerRef.current.getDayPicker() as any;
      if (wrapper) {
        setTimeout(() => {
          wrapper.focus();
        }, 0);
      }
    }
  };

  return (
    <div id={containerId.current}>
      <DayPickerInput
        value={selectedDate}
        ref={datePickerRef}
        component={InputComponent}
        classNames={{ container, overlayWrapper, overlay }}
        dayPickerProps={{
          captionElement: ({ localeUtils }) => (
            <DatePickerCaption
              date={selectedDate}
              localeUtils={localeUtils}
              onSelect={setSelectedDate}
            />
          ),
          month: selectedDate,
          weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          canChangeMonth: false,
          classNames: styles as any
        }}
        onDayChange={setSelectedDate}
        formatDate={formatDate}
        format={DATE_FORMAT}
        parseDate={parseDate}
        onDayPickerShow={onDayPickerShow}
        hideOnDayClick={false}
        placeholder={DATE_FORMAT}
      />
    </div>
  );
};
