import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import { StatusType, StatusTypes, useCaregiversStatus } from 'Caregivers';
import { LoadingPlaceholder, Modal } from 'components';
import {
  useFetcher,
  useModalMeasurements,
  usePoster,
  useSubmitError
} from 'hooks';
import { useLocationState } from 'Location';
import { getResidentsByWard } from 'Residents';
import {
  Caregiver,
  RelatedContactStatus,
  Resident,
  ResidentContact
} from 'Settings';
import { MESSAGE_TYPE } from 'Socket';
import { sortByKey } from 'utils';

import {
  createChatRoom,
  getChatRoomByCorrespondentIdAndChatRoomType,
  getChatRoomByResidentId,
  getRelatedContacts,
  sendChatMessage,
  uploadFile
} from './actions';
import { CommunicationActionTypes, useCommunicationContext } from './contexts';
import { MessageInputBox } from './MessageInputBox';
import { SearchContactEntry } from './SearchContactEntry';
import { SearchContactHeader } from './SearchContactHeader';
import {
  ChatContact,
  ChatMessageTypes,
  ChatRoom,
  ConversationTypes,
  MessagingHistoryConversation,
  MessagingResident,
  Participant
} from './types';

import { ChatId } from 'types';
import styles from './NewMessageWindow.module.css';

const FILE_MESSAGE_TEXT = 'View Image';

interface INewMessageWindowProps {
  isOpen: boolean;
  onClose: () => void;
  setOnError: (value: boolean) => void;
  caregivers: Caregiver[];
}

export const NewMessageWindow: React.FunctionComponent<INewMessageWindowProps> = ({
  isOpen,
  onClose,
  setOnError,
  caregivers
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [fetchActionStatus, setFetchActionStatus] = useState<{
    loading: boolean;
    error: Error | null;
  }>({ loading: false, error: null });
  const [selectedContact, setSelectedContact] = useState<ChatContact | null>();
  const [existingChatInfo, setExisitingChatInfo] = useState<{
    message: any;
    chat: MessagingHistoryConversation;
  }>();
  const [availableRelatedContacts, setAvailableRelatedContacts] = useState<
    ChatContact[]
  >([]);
  const [contactList, setContactList] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [careTeams, setCareTeams] = useState<ChatContact[]>([]);

  const [{ conversations }, dispatch] = useCommunicationContext();
  const { loggedUser, loginId } = useAuthState();
  const { modalOffsetTop, modalOffsetLeft } = useModalMeasurements(
    'ChatWindow',
    'MainContent',
    isOpen
  );
  const {
    facility: { id: facilityId },
    ward: wardId,
    selectedWardDetails
  } = useLocationState();

  const {
    data: caregiversWardStatusMap,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const residentsByWardAction = useMemo(() => {
    if (facilityId && wardId) {
      return getResidentsByWard(facilityId, wardId, false);
    }
  }, [facilityId, wardId]);

  const {
    data: { items: residentsByWard },
    error: residentsError,
    loading: residentsLoading
  } = useFetcher<{ items: Resident[] }>(residentsByWardAction, {
    items: []
  });

  useEffect(() => {
    if (
      residentsByWard.length === 0 ||
      !selectedWardDetails?.services.secureCommunication.features.scCareTeam
        .isEnabled
    ) {
      return undefined;
    }

    setLoading(true);

    const promises = residentsByWard.map(res => {
      return getChatRoomByResidentId(res.id);
    });

    Promise.all(promises)
      .then(careTeamsArr => {
        const chats = careTeamsArr.flat().map(
          (chat): ChatContact => {
            return {
              chatRoomType: ConversationTypes.CARE_TEAM,
              name: chat.description,
              relationship: undefined,
              role: undefined,
              chatId: chat.id,
              loginId: undefined,
              photoUrl: undefined,
              resident: undefined,
              residentId: chat.intendedResidentId,
              status: undefined,
              userId: undefined
            };
          }
        );
        setCareTeams(chats);
      })
      .catch(err => setError(err))
      .finally(() => setLoading(false));
  }, [residentsByWard, selectedWardDetails]);

  const relatedContactsAction = useMemo(() => {
    if (!residentsLoading && residentsByWard.length && !residentsError) {
      return getRelatedContacts(residentsByWard);
    }
  }, [residentsByWard, residentsLoading, residentsError]);

  const {
    data: relatedContacts,
    loading: relatedContactsLoading,
    error: relatedContactsError
  } = useFetcher<ResidentContact[]>(relatedContactsAction, []);

  const activeCaregiverContacts = useMemo<ChatContact[]>(() => {
    if (
      !selectedWardDetails?.services.secureCommunication.features.scMessaging
        .isEnabled
    ) {
      return [];
    }

    return caregivers
      .filter(
        ({ isActive, id }) =>
          isActive &&
          loggedUser &&
          id !== loggedUser.id &&
          caregiversWardStatusMap[id]
      )
      .map(
        ({
          id: caregiverId,
          name,
          role,
          loginId: caregiverLoginId,
          photoUrl
        }) => ({
          loginId: caregiverLoginId,
          chatRoomType: ConversationTypes.CAREGIVER,
          chatId: undefined,
          userId: caregiverId,
          name,
          role,
          status: caregiversWardStatusMap[caregiverId] || StatusTypes.OFF_DUTY,
          type: 'caregiver',
          relationship: undefined,
          resident: undefined,
          residentId: undefined,
          photoUrl
        })
      );
  }, [caregivers, caregiversWardStatusMap, loggedUser, selectedWardDetails]);

  const {
    setAction,
    error: sendMessageError,
    loading: onSaveLoading
  } = usePoster();

  const { submitted, setSubmitted } = useSubmitError(
    sendMessageError,
    onSaveLoading
  );

  useEffect(() => {
    if (
      !selectedWardDetails?.services.secureCommunication.features
        .scRelatedContact.isEnabled
    ) {
      return setAvailableRelatedContacts([]);
    }

    const list: ChatContact[] = relatedContacts.map(relatedContact => {
      const {
        id: relatedContactId,
        name,
        relationship,
        loginId: relatedContactLoginId,
        photoUrl
      } = relatedContact;

      const resident = residentsByWard.find(
        entry => entry.id === relatedContact.residentId
      );

      if (!resident) {
        return {
          chatId: undefined,
          userId: relatedContactId,
          name,
          loginId: relatedContactLoginId,
          role: undefined,
          relationship,
          type: ConversationTypes.RELATED,
          chatRoomType: ConversationTypes.RELATED,
          photoUrl
        } as ChatContact;
      }

      return {
        chatId: undefined,
        userId: relatedContactId,
        name,
        loginId: relatedContactLoginId,
        role: undefined,
        relationship,
        type: ConversationTypes.RELATED,
        chatRoomType: ConversationTypes.RELATED,
        resident: {
          id: resident.id,
          name: resident.name,
          room: resident.room
        },
        photoUrl,
        residentId: resident?.id
      };
    });

    setAvailableRelatedContacts(list);
  }, [relatedContacts, residentsByWard, selectedWardDetails]);

  const contactsSearchResults = useMemo(() => {
    const availableContacts: ChatContact[] = sortByKey(
      [...activeCaregiverContacts, ...availableRelatedContacts, ...careTeams],
      'name'
    );

    if (searchValue.length < 3) {
      return availableContacts;
    }

    return availableContacts.filter(({ name }) =>
      name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [
    activeCaregiverContacts,
    availableRelatedContacts,
    careTeams,
    searchValue
  ]);

  useEffect(() => {
    if (!isOpen) {
      setSearchValue('');
      setSelectedContact(null);
    }
  }, [isOpen]);

  const handleSendMessage = async (message: MESSAGE_TYPE) => {
    if (!selectedContact) {
      return;
    }

    const author: Participant = {
      loginId,
      name: loggedUser!.name,
      id: loggedUser!.id,
      photoUrl: loggedUser?.photoUrl,
      relationship: undefined,
      residentId: undefined,
      role: loggedUser?.role,
      status: caregiversWardStatusMap[loggedUser!.id]
    };

    const newChatMessage = {
      id: undefined,
      content: message,
      type: ChatMessageTypes.TEXT_MESSAGE,
      readBy: [],
      author,
      sentAt: moment().toISOString(true)
    };

    let existingChat:
      | ChatRoom
      | MessagingHistoryConversation
      | ChatContact
      | undefined;

    existingChat = conversations.find(
      (conversation: MessagingHistoryConversation) => {
        const { participants, id, chatRoomType: convoType } = conversation;
        return (
          convoType === selectedContact.chatRoomType &&
          (id === selectedContact.chatId ||
            participants.every(
              participant =>
                participant.loginId === selectedContact.loginId ||
                participant.loginId === loginId
            ))
        );
      }
    );

    if (!existingChat) {
      existingChat = careTeams.find(contact => {
        return selectedContact.chatId === contact.chatId;
      });
    }

    if (!existingChat) {
      existingChat = await getChatRoomByCorrespondentIdAndChatRoomType(
        selectedContact.loginId,
        selectedContact.resident?.id,
        selectedContact.chatRoomType
      );
    }

    const { content, sentAt } = newChatMessage;

    if (existingChat) {
      const chatId: ChatId =
        (existingChat as MessagingHistoryConversation).id ||
        ((existingChat as ChatContact).chatId as string);

      const messageId = await sendChatMessage(chatId, content);
      setExisitingChatInfo({
        message: {
          ...newChatMessage,
          id: messageId
        },
        chat: {
          ...existingChat,
          id: chatId,
          description:
            (existingChat as MessagingHistoryConversation).description ||
            (existingChat as ChatContact).name,
          participants: [selectedContact, author],
          hasUnreadMessages: false,
          loginSenderId: loginId,
          lastMessage: {
            ...newChatMessage,
            chatRoomId: chatId,
            loginSenderId: loginId,
            messageType: ChatMessageTypes.TEXT_MESSAGE,
            textMessage: content,
            timestamp: new Date().toISOString(),
            fileName: undefined,
            fileUri: undefined,
            participantLoginId: selectedContact.loginId,
            id: messageId
          }
        } as MessagingHistoryConversation
      });
      setSubmitted(true);
    } else if (!!selectedContact) {
      const { resident, ...otherData } = selectedContact;

      const description = `${loggedUser?.name} and ${selectedContact.name} chat room`;

      try {
        setFetchActionStatus({ loading: true, error: null });

        const { chatRoomId, messageId } = await createChatRoom(
          [loginId, selectedContact.loginId as string],
          selectedContact.chatRoomType,
          content,
          description,
          resident?.id
        );

        if (chatRoomId && messageId) {
          const newConversation: MessagingHistoryConversation = {
            id: chatRoomId,
            chatRoomType: selectedContact.chatRoomType,
            lastMessage: {
              chatRoomId,
              loginSenderId: loginId,
              messageType: ChatMessageTypes.TEXT_MESSAGE,
              timestamp: sentAt,
              textMessage: content,
              id: messageId
            },
            hasUnreadMessages: false,
            loginSenderId: loginId,
            participants: [
              {
                loginId: otherData.loginId as string,
                id: otherData.userId as string,
                name: otherData.name as string,
                status: otherData.status as StatusType | RelatedContactStatus,
                photoUrl: otherData.photoUrl,
                relationship: otherData.relationship,
                residentId: otherData.residentId,
                role: otherData.role
              },
              author
            ],
            resident: resident as MessagingResident,
            description
          };

          dispatch({
            type: CommunicationActionTypes.SET_CONVERSATIONS,
            payload: [newConversation, ...conversations]
          });
          dispatch({
            type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
            payload: newConversation
          });
          dispatch({
            type: CommunicationActionTypes.SEND_MESSAGE,
            payload: {
              ...newChatMessage,
              id: messageId,
              conversationId: chatRoomId
            }
          });

          onClose();
        }
      } catch (error) {
        setFetchActionStatus(prevState => ({
          ...prevState,
          error: error as Error
        }));
      } finally {
        setFetchActionStatus(prevState => ({
          ...prevState,
          loading: false
        }));
      }
    }
  };

  const onFileUpload = async (file: File) => {
    if (!selectedContact) {
      return;
    }

    const author: Participant = {
      loginId,
      name: loggedUser!.name,
      id: loggedUser!.id,
      photoUrl: loggedUser?.photoUrl,
      relationship: undefined,
      residentId: undefined,
      role: loggedUser?.role,
      status: caregiversWardStatusMap[loggedUser!.id]
    };

    const newChatMessage = {
      id: undefined,
      content: FILE_MESSAGE_TEXT,
      type: ChatMessageTypes.FILE_MESSAGE,
      readBy: [],
      author,
      sentAt: moment().toISOString(true)
    };

    let existingChat;

    existingChat = conversations.find(
      (conversation: MessagingHistoryConversation) => {
        const { participants, id, chatRoomType: convoType } = conversation;
        return (
          convoType === selectedContact.chatRoomType &&
          (id === selectedContact.chatId ||
            participants.find(
              participant => participant.loginId === selectedContact.loginId
            ))
        );
      }
    );

    if (!existingChat) {
      existingChat = await getChatRoomByCorrespondentIdAndChatRoomType(
        selectedContact.loginId,
        selectedContact.resident?.id,
        selectedContact.chatRoomType
      );
    }

    if (existingChat?.id && file && selectedContact.loginId) {
      setAction(uploadFile(file, existingChat.id, loginId));

      const newParticipant: Participant = {
        id: selectedContact.userId as string,
        loginId: selectedContact.loginId,
        name: selectedContact.name,
        photoUrl: selectedContact.photoUrl,
        relationship: selectedContact.relationship,
        residentId: selectedContact.residentId,
        role: selectedContact.role,
        status: selectedContact.status
      };

      setExisitingChatInfo({
        message: newChatMessage,
        chat: {
          ...existingChat,
          participants: [newParticipant, author],
          hasUnreadMessages: false,
          loginSenderId: loginId,
          lastMessage: {
            ...newChatMessage,
            chatRoomId: existingChat.id,
            loginSenderId: loginId,
            messageType: ChatMessageTypes.FILE_MESSAGE,
            textMessage: '',
            timestamp: moment().toISOString(true),
            fileName: file.name,
            participantLoginId: selectedContact.loginId,
            fileUri: undefined,
            id: ''
          }
        }
      });
      setSubmitted(true);
    } else if (!!loggedUser) {
      const { resident, ...otherData } = selectedContact;

      const description = `${loggedUser.name} and ${selectedContact.name} chat room`;

      try {
        setFetchActionStatus({ loading: true, error: null });

        const { chatRoomId } = await createChatRoom(
          [loginId, selectedContact.loginId as string],
          selectedContact.chatRoomType,
          null,
          description,
          resident && resident.id ? resident.id : undefined
        );

        if (file && chatRoomId && loggedUser && loggedUser.id) {
          const newConversation: MessagingHistoryConversation = {
            id: chatRoomId,
            chatRoomType: selectedContact.chatRoomType,
            lastMessage: {
              chatRoomId,
              id: '',
              loginSenderId: loginId,
              messageType: ChatMessageTypes.FILE_MESSAGE,
              timestamp: moment().toISOString(true),
              textMessage: FILE_MESSAGE_TEXT
            },
            loginSenderId: loginId,
            hasUnreadMessages: false,
            participants: [{ ...otherData } as any],
            resident: resident as any,
            description
          };

          dispatch({
            type: CommunicationActionTypes.SET_CONVERSATIONS,
            payload: [newConversation, ...conversations]
          });
          dispatch({
            type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
            payload: newConversation
          });

          setAction(uploadFile(file, chatRoomId, loginId));
        }

        onClose();
      } catch (error) {
        setFetchActionStatus(prevState => ({
          ...prevState,
          error: error as Error
        }));
      } finally {
        setFetchActionStatus(prevState => ({
          ...prevState,
          loading: false
        }));
      }
    }
  };

  useEffect(() => {
    if (submitted && !onSaveLoading && !sendMessageError && existingChatInfo) {
      dispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: existingChatInfo.chat
      });
      dispatch({
        type: CommunicationActionTypes.SEND_MESSAGE,
        payload: {
          ...existingChatInfo.message,
          conversationId: existingChatInfo.chat.id
        }
      });

      onClose();
      setSubmitted(false);
    }
  }, [
    dispatch,
    existingChatInfo,
    onClose,
    onSaveLoading,
    sendMessageError,
    setSubmitted,
    submitted
  ]);

  useEffect(() => setOnError(!!fetchActionStatus.error || !!sendMessageError), [
    sendMessageError,
    fetchActionStatus.error,
    setOnError
  ]);

  const handleContactSelection = (contact: ChatContact) => {
    return setSelectedContact(contact);
  };

  useEffect(() => {
    if (contactsSearchResults.length === 0) {
      setContactList(
        <p className={styles.emptyPlaceholder}>No contacts match the search</p>
      );
    }

    setContactList(
      <ul>
        {contactsSearchResults.map(contact => {
          const isSelected = Boolean(
            (selectedContact?.chatId &&
              contact.chatId === selectedContact.chatId) ||
              (selectedContact?.userId &&
                contact.userId === selectedContact.userId)
          );
          return (
            <SearchContactEntry
              key={contact.chatId || contact.userId}
              data={contact}
              isSelected={isSelected}
              onSelect={handleContactSelection}
            />
          );
        })}
      </ul>
    );
  }, [contactsSearchResults, selectedContact]);

  useEffect(() => {
    setLoading(
      statusLoading || relatedContactsLoading || fetchActionStatus.loading
    );
  }, [statusLoading, relatedContactsLoading, fetchActionStatus.loading]);

  useEffect(() => {
    setError(statusError || relatedContactsError);
  }, [statusError, relatedContactsError]);

  return (
    <Modal isShowing={isOpen} toggle={onClose}>
      <div
        className={styles.newChatContainer}
        style={{
          marginTop: modalOffsetTop + 110,
          marginLeft: modalOffsetLeft
        }}
      >
        <SearchContactHeader
          searchValue={searchValue}
          onCancel={onClose}
          onSearch={setSearchValue}
          disabled={!!error}
        />
        <>
          {loading && !error && <LoadingPlaceholder />}
          {!loading && error && (
            <p className={styles.error}>
              Could not load contacts. Please try again.
            </p>
          )}
          {!loading && !error && contactList}
        </>
        <MessageInputBox
          onSend={handleSendMessage}
          onFileUpload={onFileUpload}
          clearOnSend={!!selectedContact}
          disabled={loading || !!error || onSaveLoading}
        />
      </div>
    </Modal>
  );
};
