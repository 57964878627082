import React, { useState } from 'react';

import { ReactComponent as Chevron } from 'icons/chevron-left.svg';

import styles from './SearchHeader.module.css';

export const SearchHeader: React.FunctionComponent<{
  searchInputName: string;
  onClose: () => void;
  searchValue: string;
  onSearch: (value: string) => void;
  customStyle?: {};
}> = ({ searchInputName, onClose, searchValue, onSearch, customStyle }) => {
  const [inputValue, setInputValue] = useState(searchValue);

  const onInputChange = (evt: any) => {
    const { value } = evt.target;
    onSearch(value);
    setInputValue(value);
  };

  const onClick = (e: any) => {
    e.preventDefault();
    onClose();
  };

  return (
    <header className={styles.header} style={customStyle}>
      <Chevron className={styles.backButton} onClick={onClick} />
      <input
        className={styles.searchInput}
        name={searchInputName}
        type="text"
        placeholder={`Search here for ${searchInputName}`}
        onChange={onInputChange}
        value={inputValue}
        autoFocus={true}
      />
    </header>
  );
};
