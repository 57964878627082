import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import { StatusTypes, useCaregiversStatus } from 'Caregivers';
import { FetchError, Snackbar } from 'components';
import { useDidMount, useFetcher, usePoster, useSubmitError } from 'hooks';
import { useLocationState } from 'Location';
import { Caregiver, getResidentContacts, ResidentContact } from 'Settings';
import { ComUpdateTypes, SocketActions, useSocketContext } from 'Socket';
import { isIEorEdge } from 'utils';
import {
  getMessageHistory,
  initiateCall,
  sendChatMessage,
  uploadFile
} from './actions';
import { ChatMessages } from './ChatMessages';
import { ChatWindowHeader } from './ChatWindowHeader';
import { FILE_MESSAGE_TEXT } from './constants';
import { useAdHocDispatch } from './contexts';
import {
  CommunicationActionTypes,
  useCommunicationContext
} from './contexts/communication.context';
import { MessageInputBox } from './MessageInputBox';
import {
  AdHocActionTypes,
  CallerDetails,
  CallTypes,
  ChatMessage,
  ChatMessageType,
  ConversationTypes,
  isAdHocConversation,
  isCareTeamConversation,
  Message,
  Participant,
  Reader,
  StartCallType,
  StartCallTypes
} from './types';
import {
  formatMessages,
  getConversationName,
  populateChatMessage,
  populateMessage
} from './utils';
import { getCallerDetails, getCaregiverStatus } from './utils/chatWindow.utils';

import styles from './ChatWindow.module.css';

interface IChatWindowProps {
  title?: string;
  callOptionsDisabled?: boolean;
  compactView?: boolean;
  caregivers: Caregiver[];
  showButtons: boolean;
}

export const ChatWindow: React.FunctionComponent<IChatWindowProps> = ({
  title,
  callOptionsDisabled,
  compactView,
  caregivers,
  showButtons
}) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [messageToSend, setMessageToSend] = useState<ChatMessage>(
    {} as ChatMessage
  );
  const [page, setPage] = useState<string | undefined>(undefined);
  const [callType, setCallType] = useState<StartCallType>(
    StartCallTypes.START_AUDIO_CALL
  );
  const [scrollToNewestMessage, setScrollToNewestMessage] = useState(false);
  const [formattingMessages, setFormattingMessages] = useState<boolean>(false);
  const [someLoading, setSomeLoading] = useState<boolean>(false);
  const [someError, setSomeError] = useState<Error | null>(null);

  const [{ comUpdate }, { dispatch: socketDispatch }] = useSocketContext();
  const messageType = Boolean(comUpdate) ? comUpdate?.messageType : null;
  const [{ activeConversation }, dispatch] = useCommunicationContext();
  const {
    facility: { id: facilityId },
    ward: wardId,
    selectedWardDetails
  } = useLocationState();
  const { loggedUser, loginId } = useAuthState();

  const {
    id: activeConversationId,
    chatRoomType: conversationType,
    resident,
    participants
  } = activeConversation;

  const chatTypeIsValid =
    isAdHocConversation(activeConversation) ||
    isCareTeamConversation(activeConversation);

  const adHocDispatch = useAdHocDispatch();

  const {
    data: caregiversWardStatusMap,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const { setAction, loading: onSaveLoading, error: sendError } = usePoster();

  const { submitted, setSubmitted } = useSubmitError(sendError, onSaveLoading);
  const isComponentMounted = useDidMount();

  const {
    setAction: setStartAction,
    error: startError,
    loading: startLoading
  } = usePoster();

  const {
    submitted: startSubmitted,
    setSubmitted: setStartSubmitted,
    isErrorShowing: isStartErrorShowing,
    dismissError: dismissStartError
  } = useSubmitError(startError, startLoading);

  const relatedContactsAction = useMemo(() => {
    if (resident) {
      return getResidentContacts(resident.id);
    }
  }, [resident]);

  const {
    data: { items: relatedContacts },
    loading: relatedContactsLoading,
    error: relatedContactsError
  } = useFetcher<{
    items: ResidentContact[];
  }>(relatedContactsAction, {
    items: []
  });

  const getMessagesAction = useMemo(() => {
    if (page !== undefined && activeConversationId) {
      return getMessageHistory(activeConversationId, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const {
    data: { items: fetchedMessages, nextPage: messagesNextPage },
    loading: messagesLoading,
    error: messagesError
  } = useFetcher<{ items: Message[]; nextPage?: string }>(getMessagesAction, {
    items: [],
    nextPage: '0'
  });

  useEffect(() => {
    if (
      statusLoading ||
      relatedContactsLoading ||
      messagesLoading ||
      onSaveLoading
    ) {
      setSomeLoading(true);
    } else {
      setSomeLoading(false);
    }
  }, [statusLoading, relatedContactsLoading, messagesLoading, onSaveLoading]);

  useEffect(() => {
    if (relatedContactsError || statusError || messagesError || sendError) {
      setSomeError(relatedContactsError || statusError || messagesError);
    } else {
      setSomeError(null);
    }
  }, [relatedContactsError, statusError, messagesError, sendError]);

  useEffect(() => {
    if (isComponentMounted) {
      dispatch({ type: CommunicationActionTypes.CLEAR_COMMUNICATION });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId, wardId]);

  useEffect(() => {
    if (!activeConversationId || !wardId || !facilityId) {
      return undefined;
    }
    setMessages([]);
    setPage(undefined);
  }, [activeConversationId, wardId, facilityId]);

  /**
   * when we receive an ad hoc web socket message
   */
  useEffect(() => {
    if (
      activeConversation.chatRoomType === ConversationTypes.AD_HOC &&
      (messageType === ComUpdateTypes.PARTICIPANT_ADDED_MESSAGE ||
        messageType === ComUpdateTypes.PARTICIPANT_DELETED_MESSAGE) &&
      comUpdate &&
      comUpdate.chatRoomId === activeConversation.id
    ) {
      setFormattingMessages(true);

      const message = populateMessage(comUpdate);

      const [formattedMessage] = formatMessages({
        loginId,
        caregivers,
        caregiversWardStatusMap,
        messages: [message],
        relatedContacts
      });

      setMessageToSend(formattedMessage);

      setTimeout(() => {
        setSubmitted(true);
      });

      socketDispatch({
        type: SocketActions.RESET_SOCKET
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeConversation,
    comUpdate,
    loginId,
    relatedContacts,
    caregivers,
    caregiversWardStatusMap,
    conversationType,
    facilityId,
    socketDispatch
  ]);

  useEffect(() => {
    if (!comUpdate) {
      return undefined;
    }

    if (comUpdate.messageType === ComUpdateTypes.MESSAGE_SEEN_MESSAGE) {
      const newReader = [...caregivers, ...relatedContacts].find(
        entry => entry.loginId === comUpdate.loginSenderId
      ) as Reader;

      const newMessages = messages.reduce((acc, msg) => {
        if (
          msg.author.loginId === loginId &&
          comUpdate.values.messageThatWasSeen === msg.id
        ) {
          acc.push({
            ...msg,
            readBy: [newReader, ...msg.readBy]
          });
        } else {
          acc.push(msg);
        }
        return acc;
      }, [] as ChatMessage[]);

      setMessages(newMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, messageType]);

  // SET LOCAL MESSAGES
  useEffect(() => {
    if (
      someLoading ||
      someError ||
      fetchedMessages.length === 0 ||
      (caregivers.length === 0 && relatedContacts.length === 0)
    ) {
      return undefined;
    }
    setFormattingMessages(true);

    const filteredMessages = fetchedMessages.filter(
      msg => !messages.find(prevMsg => msg.id === prevMsg.id)
    );

    const newMessages = formatMessages({
      loginId,
      caregivers,
      caregiversWardStatusMap,
      messages: filteredMessages,
      relatedContacts
    });

    setMessages(prevMessages => [...newMessages, ...prevMessages]);
    setFormattingMessages(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedMessages, someLoading, someError, caregivers]);

  /* SEND A MESSAGE */
  const sendNewMessage = (message: any, type: ChatMessageType) => {
    if (loggedUser === null) {
      return undefined;
    }

    const author: Participant = {
      loginId: loggedUser.id,
      id: loggedUser.id,
      name: loggedUser.name,
      photoUrl: loggedUser.photoUrl,
      relationship: undefined,
      residentId: undefined,
      role: loggedUser.role,
      status: caregiversWardStatusMap[loggedUser.id]
    };

    const newChatMessage: ChatMessage = {
      id: Date.now().toString(),
      conversationId: activeConversation ? activeConversationId : undefined,
      content: message,
      type,
      readBy: [] as Reader[],
      author,
      sentAt: moment().toISOString(true)
    };

    if (newChatMessage.conversationId) {
      setAction(
        sendChatMessage(newChatMessage.conversationId, newChatMessage.content)
      );
    }
  };

  const onFileUpload = useCallback(
    async (file: File) => {
      if (file && activeConversationId && loggedUser && loggedUser.id) {
        await setAction(uploadFile(file, activeConversationId, loggedUser.id));
      }
    },
    [activeConversationId, loggedUser, setAction]
  );

  const onCancel = (message: ChatMessage) => {
    const filteredMessages = messages.filter(
      entry => entry.errorId !== message.errorId
    );
    setMessages(filteredMessages);
  };

  const onRetry = (message: ChatMessage) => {
    const { conversationId, content } = message;
    const { errorId, ...messageWithNoError } = message;

    if (conversationId) {
      onCancel(message);
      setAction(sendChatMessage(conversationId, content));
      setMessageToSend(messageWithNoError);
    }
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  /* RECEIVE MESSAGE EFFECT */
  useEffect(() => {
    if (!comUpdate) {
      return undefined;
    }

    if (
      loginId === comUpdate.loginSenderId &&
      (comUpdate.messageType === ComUpdateTypes.FILE_MESSAGE ||
        comUpdate.messageType === ComUpdateTypes.TEXT_MESSAGE)
    ) {
      const senderCaregiver = caregivers.find(
        caregiver => comUpdate.loginSenderId === caregiver.loginId
      );

      const senderRelatedContact = relatedContacts.find(
        caregiver => comUpdate.loginSenderId === caregiver.loginId
      );

      const sender = senderCaregiver || senderRelatedContact;

      if (!sender) {
        return undefined;
      }

      const content =
        messageType === ComUpdateTypes.TEXT_MESSAGE
          ? comUpdate.values.textMessage
          : FILE_MESSAGE_TEXT;

      const status =
        senderCaregiver && caregiversWardStatusMap[senderCaregiver.id];

      const receivedMessage = populateChatMessage(
        comUpdate,
        sender,
        status,
        content
      );

      setMessages(prevMessages => [receivedMessage, ...prevMessages]);

      return setScrollToNewestMessage(true);
    }

    if (loginId !== comUpdate.loginSenderId) {
      const senderCaregiver = caregivers.find(
        caregiver => comUpdate.loginSenderId === caregiver.loginId
      );

      const senderRelatedContact = relatedContacts.find(
        caregiver => comUpdate.loginSenderId === caregiver.loginId
      );

      const sender = senderCaregiver || senderRelatedContact;

      if (!sender) {
        return undefined;
      }

      const content =
        messageType === ComUpdateTypes.TEXT_MESSAGE
          ? comUpdate.values.textMessage
          : FILE_MESSAGE_TEXT;

      const status =
        senderCaregiver && caregiversWardStatusMap[senderCaregiver.id];

      const receivedMessage = populateChatMessage(
        comUpdate,
        sender,
        status,
        content
      );

      if (
        // user receive message on the active conversation
        comUpdate.chatRoomId === activeConversationId &&
        Boolean(receivedMessage) &&
        (messageType === ComUpdateTypes.TEXT_MESSAGE ||
          messageType === ComUpdateTypes.FILE_MESSAGE)
      ) {
        setMessages(prevMessages => [
          ...prevMessages,
          (receivedMessage as unknown) as ChatMessage
        ]);
      }

      if (
        // user receiving a message on other conversation
        (messageType === ComUpdateTypes.TEXT_MESSAGE ||
          messageType === ComUpdateTypes.FILE_MESSAGE) &&
        Boolean(receivedMessage)
      ) {
        dispatch({
          type: CommunicationActionTypes.SEND_MESSAGE,
          payload: (receivedMessage as unknown) as ChatMessage
        });
      }
      setScrollToNewestMessage(true);
    }

    socketDispatch({
      type: SocketActions.RESET_SOCKET
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType]);

  useEffect(() => {
    if (submitted && !onSaveLoading && !sendError) {
      setMessages(prevMessages => [...prevMessages, messageToSend]);
      dispatch({
        type: CommunicationActionTypes.SEND_MESSAGE,
        payload: {
          ...messageToSend,
          conversationId: messageToSend.conversationId
        }
      });
      setSubmitted(false);
      setFormattingMessages(false);
      setScrollToNewestMessage(true);
    } else if (sendError && !onSaveLoading && submitted) {
      const messageWithError = { ...messageToSend, errorId: sendError.name };
      setMessages(
        prevMessages => [...prevMessages, messageWithError] as ChatMessage[]
      );
      setSubmitted(false);
      setFormattingMessages(false);
      setScrollToNewestMessage(true);
    }
  }, [
    submitted,
    onSaveLoading,
    sendError,
    setSubmitted,
    dispatch,
    messageToSend
  ]);

  const startAudioCall = useCallback(() => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    setStartAction(initiateCall(activeConversationId, CallTypes.AUDIO));

    setTimeout(() => {
      setStartSubmitted(true);
    });
    setCallType(StartCallTypes.START_AUDIO_CALL);
  }, [activeConversationId, dispatch, setStartAction, setStartSubmitted]);

  const startVideoCall = useCallback(() => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }
    setAction(initiateCall(activeConversationId, CallTypes.VIDEO));

    setTimeout(() => {
      setStartSubmitted(true);
    });
    setCallType(StartCallTypes.START_VIDEO_CALL);
  }, [activeConversationId, dispatch, setAction, setStartSubmitted]);

  useEffect(() => {
    if (!startLoading && !startError && startSubmitted) {
      const callerDetails: CallerDetails = getCallerDetails(
        activeConversation,
        loginId
      );

      dispatch({
        type: callType,
        payload: callerDetails
      });
      setStartSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startLoading,
    startError,
    startSubmitted,
    setStartSubmitted,
    callType,
    activeConversation
  ]);

  const [callOptions, setCallOptions] = useState<object>({});

  useEffect(() => {
    if (callOptionsDisabled) {
      setCallOptions({});
    } else {
      const newOptions = {
        onAudioCall:
          selectedWardDetails?.services.secureCommunication.features.scVoice
            .isEnabled && startAudioCall,
        onVideoCall:
          selectedWardDetails?.services.secureCommunication.features.scVideo
            .isEnabled && startVideoCall
      };

      setCallOptions(newOptions);
    }
  }, [
    callOptionsDisabled,
    selectedWardDetails,
    startAudioCall,
    startVideoCall
  ]);

  const chatWindowContainerClass = compactView
    ? styles.chatWindowContainerCompact
    : styles.chatWindowContainer;

  const caregiverStatus = getCaregiverStatus(
    activeConversation,
    caregiversWardStatusMap
  );

  const isCaregiverAvailable =
    conversationType !== ConversationTypes.CAREGIVER ||
    caregiverStatus === StatusTypes.ON_DUTY ||
    caregiverStatus === StatusTypes.ON_BREAK;

  const editAdHocChatDetails =
    activeConversation.chatRoomType === ConversationTypes.AD_HOC
      ? () => {
          adHocDispatch({
            type: AdHocActionTypes.SET_SELECTED_AD_HOC,
            payload: activeConversationId
          });
        }
      : undefined;

  const handlePagination = () => {
    if (messagesNextPage) {
      setPage(messagesNextPage);
    }
  };

  return (
    <div className={chatWindowContainerClass} id="ChatWindow">
      {activeConversationId && (
        <>
          {!someError && (
            <ChatWindowHeader
              title={title || getConversationName(activeConversation, loginId)}
              participants={chatTypeIsValid ? participants : undefined}
              isAvailable={isCaregiverAvailable}
              onEdit={editAdHocChatDetails}
              isVisible={showButtons}
              {...callOptions}
            />
          )}
          <ChatMessages
            messages={messages}
            onCancel={onCancel}
            onRetry={onRetry}
            onTrigger={handlePagination}
            error={someError}
            scrollToNewestMessage={scrollToNewestMessage}
            resetScroll={setScrollToNewestMessage}
            loading={someLoading || formattingMessages || onSaveLoading}
            chatId={activeConversationId}
          />
          {participants.length === 1 && (
            <div className={styles.chatPlaceholder}>
              There are no other participants in this chat room.
            </div>
          )}
          <div className={styles.messageInputContainer}>
            <MessageInputBox
              onSend={sendNewMessage}
              onFileUpload={onFileUpload}
            />
          </div>

          {!someLoading && someError && (
            <div className={styles.errorContainer}>
              <FetchError />
            </div>
          )}
        </>
      )}
      {!activeConversationId && (
        <h4 className={styles.emptyPlaceholder}>
          Please select an entry from the conversations list to view latest
          messages
        </h4>
      )}
      <Snackbar
        message={'Cannot initiate call. Please try again.'}
        isOpen={isStartErrorShowing}
        onClose={dismissStartError}
      />
    </div>
  );
};
