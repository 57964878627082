import classnames from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { VACANT_ROOM_NAME } from 'consts';
import { AvatarsActionTypes, useAvatarsContext } from 'contexts-global';
import { useFetcher } from 'hooks';
import { getUserAvatar } from 'UserProfile';
import { getInitials } from 'utils';

import normalStyles from './ProfilePlaceholder.module.css';
import pulsingStyles from './PulsingProfilePlaceholder.module.css';

export type CaregiverProfileStatus = 'available' | 'unavailable';

export const ProfilePlaceholder: React.FunctionComponent<{
  name?: string;
  type?: CaregiverProfileStatus | string;
  size?: number;
  isPulsing?: boolean;
  photoUrl?: string;
  customClass?: string;
  customStyles?: {
    [key: string]: string;
  };
}> = ({
  name = VACANT_ROOM_NAME,
  type = 'unknown',
  size = 48,
  isPulsing = false,
  photoUrl,
  customClass,
  customStyles
}) => {
  const { avatarsState, avatarDispatch } = useAvatarsContext();

  const defaultStyles = {
    height: size,
    width: size,
    minWidth: size,
    minHeight: size,
    backgroundSize: size
  };
  const profileStyles = isPulsing ? pulsingStyles : normalStyles;

  const getUserAvatarAction = useMemo(() => {
    if (photoUrl && !avatarsState[photoUrl]) {
      return getUserAvatar(photoUrl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoUrl]);

  const {
    data: avatarData,
    error: avatarError,
    loading: avatarLoading
  } = useFetcher<string>(getUserAvatarAction, '');

  useEffect(() => {
    if (avatarData && photoUrl) {
      avatarDispatch({
        type: AvatarsActionTypes.ADD_AVATAR,
        payload: {
          photoUrl,
          avatarData
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarData]);

  return (
    <div
      className={classnames(profileStyles[type], customClass)}
      style={
        photoUrl && !avatarLoading
          ? {
              ...defaultStyles,
              backgroundImage: `url('${avatarsState[photoUrl]}')`
            }
          : { ...defaultStyles, ...customStyles }
      }
    >
      {(!photoUrl || avatarError) && !customClass && (
        <span
          className={
            type === 'unknown'
              ? profileStyles.profilePlaceHolderTextGrey
              : profileStyles.profilePlaceHolderText
          }
          style={{ fontSize: Math.trunc(size / 2.4) }}
        >
          {name !== VACANT_ROOM_NAME ? getInitials(name) : 'VC'}
        </span>
      )}
    </div>
  );
};
