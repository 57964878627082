import React, { Component, ReactNode } from 'react';

import { Tab } from './Tab';

import styles from './Tabs.module.css';

interface TabsProps {
  children: ReactNode[];
  tabNames: string[];
  activeTab: string;
  onTabSelect: (tab: string) => void;
}

export class Tabs extends Component<
  TabsProps,
  { activeTab: string },
  { onTabSelect: (tab: string) => void }
> {
  constructor(props: TabsProps) {
    super(props);

    this.state = {
      activeTab: this.props.activeTab
    };
  }

  public onClickTabItem = (tab: string) => {
    this.props.onTabSelect(tab);
  };

  public render() {
    const {
      onClickTabItem,
      props: { children, activeTab }
    } = this;

    return (
      <>
        <ol className={styles.tabList}>
          {children.map((child, index) => {
            const label = this.props.tabNames[index];

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className={styles.tabContent}>
          {children.map((child, index) => {
            if (index !== this.props.tabNames.indexOf(activeTab)) {
              return undefined;
            }
            return child;
          })}
        </div>
      </>
    );
  }
}
