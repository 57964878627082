export enum Genders {
  FEMALE = 'Female',
  MALE = 'Male',
  OTHER = 'Other',
  UNKNOWN = 'Unknown'
}

export type Gender =
  | Genders.FEMALE
  | Genders.MALE
  | Genders.OTHER
  | Genders.UNKNOWN;

export const genders = Object.values(Genders);
