import React from 'react';

import { TextStatus } from 'components';
import { User } from 'Settings/types';

import stylesTable from 'components/Table/Table.module.css';

export const UserTableRow: React.FunctionComponent<{
  values: User;
  selectUser: (user: User) => void;
  isSelected: boolean;
}> = ({ values, selectUser, isSelected }) => {
  const handleClick = () => {
    selectUser(values);
  };
  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div onClick={handleClick} className={rowClass} role="row">
      <div className={stylesTable.tableCellTwo} role="cell">
        {values.name}
      </div>
      <div className={stylesTable.tableCellTwo} role="cell">
        {values.title}
      </div>
      <div className={stylesTable.tableCellThree} role="cell">
        {values.email}
      </div>
      <div className={stylesTable.tableCellOne} role="cell">
        <TextStatus status={values.isActive} />
      </div>
    </div>
  );
};
