import React from 'react';

import { ReactComponent as LiquidIn } from 'icons/liquid-intake.svg';
import { LiquidIntake } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './LiquidIntakeCard.module.css';

export const LiquidIntakeCard: React.FunctionComponent<{
  liquidIntake: LiquidIntake | undefined;
  onClick: () => void;
}> = ({ liquidIntake, onClick }) => {
  const { value, unit } = liquidIntake || {};
  const reading = value && parseInt(value.count, 10);

  return (
    <div className={style.liquidIntake} onClick={onClick}>
      <ActivityInfoCard reading={reading} unit={unit} type="liquidIntake">
        <LiquidIn />
      </ActivityInfoCard>
    </div>
  );
};
