import { axiosWrapper } from 'utils';

import {
  ResidentContact,
  ResidentContactListResponse
} from '../types/resident.type';

export const getPaginatedResidentContacts = (
  residentId: string,
  page?: string
) => async () => {
  const initialUrl = `/residents/${residentId}/related-contacts`;
  const url = page ? `${initialUrl}?page=${page}` : initialUrl;
  const response = await axiosWrapper<
    ResidentContactListResponse | { items: ResidentContact[]; nextPage: string }
  >(url);
  return response.data;
};
