import { CancelToken } from 'axios';
import { MAX_PAGE_SIZE } from 'consts';
import { Caregiver, CaregiverListResponse } from 'Settings';
import { FacilityId, LoginId } from 'types';
import { axiosWrapper, sortByKey } from 'utils';

export const getCaregiversByWard = (
  facilityId: string,
  wardId: string
) => async () => {
  const response = await axiosWrapper<CaregiverListResponse>(
    `/facilities/${facilityId}/wards/${wardId}/caregivers?size=${MAX_PAGE_SIZE}`
  );

  return sortByKey(response.data.items, 'name');
};

export const getCaregiverByLoginId = async (
  loginId: LoginId | undefined,
  facilityId: FacilityId,
  cancelToken: CancelToken | undefined
): Promise<Caregiver> => {
  const { data } = await axiosWrapper<Caregiver>(
    `/facilities/${facilityId}/caregivers/loginId/${loginId}`,
    'GET',
    undefined,
    undefined,
    undefined,
    undefined,
    cancelToken
  );

  return data;
};
