import { ChatMessageTypes, ConversationType } from 'Communication/types';
import { axiosWrapper } from 'utils';

interface IChatBody {
  participants: string[];
  chatRoomType: ConversationType;
  description: string;
  intendedResidentId?: string;
}

export const createChatRoom = async (
  participants: string[],
  chatRoomType: ConversationType,
  textMessage: string | null,
  description: string,
  intendedResidentId?: string
) => {
  const url = '/chat-rooms';
  const body: IChatBody = {
    participants,
    chatRoomType,
    description
  };

  if (intendedResidentId) {
    body['intendedResidentId'] = intendedResidentId;
  }
  const response = await axiosWrapper(url, 'POST', null, body);

  const chatRoomId: string = response.headers.location.replace(
    '/chat-rooms/',
    ''
  );

  if (textMessage) {
    const messageId: any = await axiosWrapper<string>(
      `/chat-rooms/${chatRoomId}/messages`,
      'POST',
      null,
      {
        textMessage,
        messageType: ChatMessageTypes.TEXT_MESSAGE
      }
    );

    return {
      chatRoomId,
      messageId
    };
  }

  return { chatRoomId };
};
