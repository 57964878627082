import { StatusType, StatusTypes } from 'Caregivers';
import { Caregiver } from 'Settings';
import { sortByKey } from 'utils';

export const getCaregiversByStatus = (
  caregiversList: Caregiver[],
  statusMap: { [caregiverId: string]: StatusType }
): {
  available: Caregiver[];
  unavailable: Caregiver[];
  inactive: Caregiver[];
} => {
  const sortedCaregivers = sortByKey(caregiversList, 'name');

  return sortedCaregivers.reduce(
    (staffMap, currentCaregiver) => {
      const { id, isActive } = currentCaregiver;
      const caregiverStatus = statusMap[id];
      const { available, unavailable, inactive } = staffMap;
      if (!isActive) {
        inactive.push(currentCaregiver);
      } else if (caregiverStatus === StatusTypes.OFF_DUTY) {
        unavailable.push(currentCaregiver);
      } else {
        available.push(currentCaregiver);
      }
      return staffMap;
    },
    { available: [], unavailable: [], inactive: [] }
  );
};
