import { ChatId, LoginId } from 'types';
import { axiosWrapper } from 'utils';

export const addParticipantToChatRoom = async (
  chatRoomId: ChatId,
  participantLoginId: LoginId
) => {
  const url = `/chat-rooms/participants?chatRoomId=${chatRoomId}`;
  const body = {
    participantLoginId
  };
  await axiosWrapper(url, 'POST', null, body);
};
