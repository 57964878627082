import React, { useRef } from 'react';

import { Column, TextStatus } from 'components';
import { Device } from '../../types';

import { deviceTypes } from 'utils';

import stylesTable from 'components/Table/Table.module.css';
import styles from 'styles/typography.module.css';

export const DeviceTableRow: React.FunctionComponent<{
  values: Device;
  isSelected: boolean;
  facilityType: string;
  setActiveTab: (tab: string) => void;
  onSelect: (id: string) => void;
  columns: { [key: string]: Column };
}> = ({
  values,
  isSelected,
  onSelect,
  setActiveTab,
  columns,
  facilityType
}) => {
  const { id, name, type, isActive } = values;
  const linkRef = useRef(null);

  const handleClick = (evt: any) => {
    if (evt.target === linkRef.current && isSelected) {
      return;
    }
    if (id) {
      onSelect(id);
    }
  };

  const handleLink = () => {
    if (id) {
      setActiveTab('Devices');
    }
  };

  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div onClick={handleClick} className={rowClass} role="row">
      <div
        className={`${stylesTable.tableCell} ${styles.regularLinkFont}`}
        style={{
          flexGrow: columns['name'].cols,
          textAlign: columns['name'].align
        }}
        role="cell"
        onClick={handleLink}
        ref={linkRef}
      >
        {name}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{
          flexGrow: columns['type'].cols,
          textAlign: columns['type'].align
        }}
        role="cell"
      >
        {deviceTypes(facilityType)[type]}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{
          flexGrow: columns['isActive'].cols,
          textAlign: columns['isActive'].align
        }}
        role="cell"
      >
        <TextStatus status={isActive} />
      </div>
    </div>
  );
};
