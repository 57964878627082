export const relationships = [
  'Attorney',
  'Child',
  'Child-In-Law',
  'Domestic Partner',
  'Emergency Contact',
  'Extended Family Member',
  'Family Member',
  'Federal Agency',
  'Foster Child',
  'Foster Parent',
  'In-Law',
  'Insurance Company',
  'Legal Guardian',
  'Next-Of-Kin',
  'Niece/Nephew',
  'Parent',
  'Relative',
  'Sibling',
  'Significant Other',
  'Spouse',
  'State Agency',
  'Unrelated Friend'
];
