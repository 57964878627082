import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  PanelList,
  Table
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useInfiniteScroll } from 'hooks';
import { Facility } from 'Settings';

import { getPaginatedWards, getWardsByQuery } from '../../actions';
import {
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH,
  WARD_LIST_PLACEHOLDER,
  WARD_SEARCH_INPUT_NAME,
  WARD_TITLE
} from '../../constants/index';

import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { WardTableRow } from './WardTableRow';

export const WardList: React.FunctionComponent<{
  facility: Facility;
  setActiveTab: (tab: string) => void;
  onSelect: (id: string) => void;
  selectedWardId: string;
  onlyActive?: boolean;
}> = ({
  facility,
  setActiveTab,
  onSelect,
  selectedWardId,
  onlyActive = false
}) => {
  const WardListTableColumns: { [key: string]: Column } = {
    name: { name: 'Ward Name', align: 'left', cols: 2 },
    numberOfRooms: { name: 'Rooms', align: 'center', cols: 1.3 },
    numberOfCaregivers: { name: 'Caregivers', align: 'center', cols: 1.3 },
    numberOfResidents: {
      name: `${residentLabels[facility.type]}s`,
      align: 'center',
      cols: 1.3
    }
  };

  const [page, setPage] = useState(0);
  const [searchedWardsPage, setSearchedWardsPage] = useState(0);
  const [wards, setWards] = useState<MappedWard[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getWardsMemo = useMemo(() => getPaginatedWards(facility.id, page), [
    facility.id,
    page
  ]);

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getWardsByQuery(facility.id, searchTerm, searchedWardsPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedWardsPage]);

  const {
    data: { items: wardList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: MappedWard[];
    nextPage?: string;
  }>(getWardsMemo, { items: [], nextPage: undefined });

  const {
    data: { items: searchedWards, nextPage: nextPageForSearchedWards },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{
    items: MappedWard[];
    nextPage?: string;
  }>(memoSearchAction, { items: [] });

  useEffect(() => {
    if (wardList.length !== 0) {
      setWards(currentWards => [...currentWards, ...wardList]);
    }
  }, [wardList]);

  useEffect(() => {
    if (searchError) {
      return setWards([]);
    }
    if (searchedWardsPage > 0) {
      setWards(prevState => [...prevState, ...searchedWards]);
    } else {
      setWards(searchedWards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedWards, searchError]);

  const scrollAction = () => {
    if (isFetching && wardList.length && nextPage && !isSearching) {
      setPage(parseInt(nextPage, 10));
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedWards.length &&
      nextPageForSearchedWards &&
      isSearching
    ) {
      setSearchedWardsPage(parseInt(nextPageForSearchedWards, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedWardsPage(0);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setWards([]);
      setPage(0);
      setRefetch();
    }
  };

  const render = (rowValues: any) => (
    <WardTableRow
      key={rowValues.id}
      values={rowValues}
      isSelected={selectedWardId === rowValues.id}
      setActiveTab={setActiveTab}
      onSelect={onSelect}
      columns={WardListTableColumns}
    />
  );
  const isDataAvailable = wards.length > 0;
  const filteredWards = onlyActive
    ? wards.filter(({ isActive }) => isActive)
    : wards;

  return (
    <PanelList
      title={WARD_TITLE}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={WARD_SEARCH_INPUT_NAME}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<MappedWard>
          columns={Object.values(WardListTableColumns)}
          rows={filteredWards}
          placeholder={WARD_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}
    </PanelList>
  );
};
