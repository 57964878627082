import React from 'react';

import styles from './HistoryLogsList.module.css';

export const HistoryLogsList: React.FunctionComponent<{
  children: React.ReactNode;
  scrollRef?: any;
}> = ({ children, scrollRef }) => {
  return (
    <ul className={styles.logsList} ref={scrollRef}>
      {children}
    </ul>
  );
};
