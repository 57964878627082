import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { WithPermissions } from 'components';
import { useHomeViewDispatch } from 'Home/contexts';
import { SwitchHomeView } from 'Home/types';
import { useQueryParams } from 'hooks';
import { useLocationState } from 'Location';

import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as CaregiversIcon } from 'icons/caregivers.svg';
import { ReactComponent as CommunicationIcon } from 'icons/communication.svg';
import { ReactComponent as HomeIcon } from 'icons/home.svg';
import { ReactComponent as LocationIcon } from 'icons/location.svg';
import { ReactComponent as ResidentsIcon } from 'icons/residents.svg';
import { ReactComponent as SettingsIcon } from 'icons/settings.svg';

import { DashboardViewSelector } from 'Home/DashboardView/DashboardViewSelector';

import styles from './Sidenav.module.css';

export const Sidenav: React.FunctionComponent = () => {
  const { facility, ward, selectedWardDetails } = useLocationState();
  const queryParams = new URLSearchParams({
    facility: facility.id || '',
    ward
  });
  const query = useQueryParams();
  const residentId = query.get('resident') || '';
  const roomId = query.get('room') || '';
  const caregiverId = query.get('caregiver') || '';
  const isOnResidentDashboard = !!roomId || !!residentId;
  const isOnCaregiverDashboard = !!caregiverId;

  const navRef = useRef<HTMLElement>(null);
  const { current } = navRef;
  const homeViewDispatch = useHomeViewDispatch();
  const { pathname } = useLocation();
  const [showLocationBtn, setShowLocationBtn] = useState(true);
  const [showCommunication, setShowCommunication] = useState<boolean>(true);
  const [showDashboardViewBtn, setShowDashboardViewBtn] = useState<boolean>(
    true
  );

  useEffect(() => {
    const handleScroll = () => {
      homeViewDispatch({
        type: SwitchHomeView.SET_TOP,
        payload: undefined
      });
    };

    if (current) {
      current.addEventListener('scroll', handleScroll, false);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleScroll, false);
      }
    };
  }, [current, homeViewDispatch]);

  useEffect(() => {
    const showLocation = () => {
      if (selectedWardDetails?.services.rtls) {
        return Object.values(selectedWardDetails?.services.rtls.features).some(
          key => key.isEnabled === true
        );
      }
      return true;
    };
    setShowLocationBtn(showLocation);
  }, [selectedWardDetails]);

  useEffect(() => {
    if (!selectedWardDetails) {
      return undefined;
    }
    const commFeatures = Object.values(
      selectedWardDetails?.services.secureCommunication.features
    ).filter(
      feat =>
        feat.id === 'scAdhoc' ||
        feat.id === 'scCareTeam' ||
        feat.id === 'scMessaging' ||
        feat.id === 'scRelatedContact'
    );
    const showComm = commFeatures.some(feat => feat.isEnabled === true);
    setShowCommunication(showComm);
  }, [selectedWardDetails]);

  useEffect(() => {
    if (!selectedWardDetails) {
      return undefined;
    }
    const alarmFeatures = Object.values(
      selectedWardDetails.services.alarmsNotifications.features
    ).some(feat => feat.isEnabled === true);

    setShowDashboardViewBtn(alarmFeatures);
  }, [selectedWardDetails]);

  return (
    <nav className={styles.nav} data-testid="side-nav" ref={navRef}>
      <ul className={styles.iconsList}>
        <li className={styles.iconsListItem}>
          <NavLink
            exact={true}
            activeClassName={styles.iconsListItemActive}
            to={`/Home?${queryParams}`}
            data-testid="side-nav-home-button"
          >
            <HomeIcon />
          </NavLink>
        </li>
        <li
          className={classnames(styles.iconsListItem, {
            [styles.iconsListItemActive]: isOnResidentDashboard
          })}
        >
          <NavLink
            activeClassName={styles.iconsListItemActive}
            to={`/Residents?${queryParams}`}
            data-testid="side-nav-resident-button"
          >
            <ResidentsIcon />
          </NavLink>
        </li>
        <li
          className={classnames(styles.iconsListItem, {
            [styles.iconsListItemActive]: isOnCaregiverDashboard
          })}
        >
          <NavLink
            activeClassName={styles.iconsListItemActive}
            to={`/Caregivers?${queryParams}`}
            data-testid="side-nav-caregivers-button"
          >
            <CaregiversIcon />
          </NavLink>
        </li>
        {showCommunication && (
          <WithPermissions
            action="communication:all"
            yes={
              <li className={styles.iconsListItemLighter}>
                <NavLink
                  activeClassName={styles.iconsListItemActive}
                  to={`/Communication?${queryParams}`}
                  data-testid="side-nav-communication-button"
                >
                  <CommunicationIcon />
                </NavLink>
              </li>
            }
          />
        )}
        {showLocationBtn && (
          <li className={styles.iconsListItem}>
            <NavLink
              activeClassName={styles.iconsListItemActive}
              to={`/Location?${queryParams}`}
              data-testid="side-nav-location-button"
            >
              <LocationIcon />
            </NavLink>
          </li>
        )}
        <WithPermissions
          action="schedule:all"
          yes={
            <li className={styles.iconsListItem}>
              <NavLink
                activeClassName={styles.iconsListItemActive}
                to={`/Schedule?${queryParams}`}
                data-testid="side-nav-schedule-button"
              >
                <CalendarIcon />
              </NavLink>
            </li>
          }
        />
        <WithPermissions
          action="settings:view"
          yes={
            <li className={styles.iconsListItem}>
              <NavLink
                activeClassName={styles.iconsListItemActive}
                to="/Settings/"
                data-testid="side-nav-settings-button"
              >
                <SettingsIcon />
              </NavLink>
            </li>
          }
        />
      </ul>
      {showDashboardViewBtn && pathname.includes('/Home') && (
        <DashboardViewSelector />
      )}
    </nav>
  );
};
