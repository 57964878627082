import { StatusType } from 'Caregivers';
import {
  ChatMessage,
  ChatMessageType,
  ChatRoom,
  ConversationType,
  ConversationTypes,
  getMessageContent,
  Message,
  MessagingHistoryConversation,
  MessagingResident,
  Participant
} from 'Communication';
import {
  Caregiver,
  RelatedContactStatus,
  Resident,
  ResidentContact
} from 'Settings';
import { ComMessageUpdate } from 'Socket';
import { LoginId, ResidentId } from 'types';

interface IFilterCurrentWardParams {
  chats: ChatRoom[];
  residentsIds: ResidentId[];
  wardCaregiversLoginIds: LoginId[];
  facilityCaregiversLoginIds: LoginId[];
  currentUserLoginId: LoginId;
}

type FilterWardFunction = (p: IFilterCurrentWardParams) => ChatRoom[];

interface IFilterParams {
  chats: ChatRoom[];
  caregivers: Caregiver[];
  residents: Resident[];
  relatedContacts: ResidentContact[];
  caregiversStatusMap: {
    [caregiverId: string]: StatusType;
  };
  currentUserLoginId: LoginId;
}

type FilterFunction = (p: IFilterParams) => MessagingHistoryConversation[];

export const isLastMessageSeen = (
  chatRoom: ChatRoom,
  currentUserLoginId: string | null
) => {
  // when a new chat is created
  if (
    !currentUserLoginId ||
    !chatRoom ||
    !chatRoom.messageSeenState ||
    !chatRoom.lastMessage
  ) {
    return true;
  }

  return (
    chatRoom.messageSeenState[currentUserLoginId] === chatRoom.lastMessage.id
  );
};

const filterChatsForCurrentWard: FilterWardFunction = ({
  chats,
  currentUserLoginId,
  facilityCaregiversLoginIds,
  residentsIds,
  wardCaregiversLoginIds
}) => {
  return chats.filter(
    ({ chatRoomType, participants, intendedResidentId, lastMessage }) => {
      const otherParticipants = participants.filter(
        loginId => loginId !== currentUserLoginId
      );

      const isChatForAResidentOnCurrentWard =
        intendedResidentId !== undefined &&
        residentsIds.includes(intendedResidentId);

      const isChatForACaregiverOnCurrentWard =
        chatRoomType === ConversationTypes.CAREGIVER &&
        otherParticipants.every(participantLoginId => {
          return wardCaregiversLoginIds.includes(participantLoginId);
        });

      const adHocChatWithJustTheCurrentUser =
        participants.length === 1 && participants[0] === currentUserLoginId;
      const isChatOfTypeAdhoc =
        chatRoomType === ConversationTypes.AD_HOC &&
        (adHocChatWithJustTheCurrentUser ||
          (otherParticipants.length > 0 &&
            otherParticipants.every(loginId =>
              facilityCaregiversLoginIds.includes(loginId)
            )));

      const chatHasLastMessage = Boolean(lastMessage);

      return (
        chatHasLastMessage &&
        (isChatForACaregiverOnCurrentWard ||
          isChatForAResidentOnCurrentWard ||
          isChatOfTypeAdhoc)
      );
    }
  );
};

export const getFilteredConversations: FilterFunction = ({
  caregivers,
  caregiversStatusMap,
  chats,
  currentUserLoginId,
  relatedContacts,
  residents
}) => {
  const residentsIds = residents.map(resident => resident.id);
  const currentWardCaregiversIds = Object.keys(caregiversStatusMap);
  const currentFacilityCaregiversLoginIds: LoginId[] = [];
  const currentWardCaregiversLoginIds: LoginId[] = [];

  caregivers.forEach(caregiver => {
    if (caregiver.loginId === currentUserLoginId) {
      return undefined;
    }

    if (currentWardCaregiversIds.includes(caregiver.id)) {
      currentWardCaregiversLoginIds.push(caregiver.loginId);
    }

    currentFacilityCaregiversLoginIds.push(caregiver.loginId);
  });

  const filteredChats = filterChatsForCurrentWard({
    chats,
    residentsIds,
    wardCaregiversLoginIds: currentWardCaregiversLoginIds,
    facilityCaregiversLoginIds: currentFacilityCaregiversLoginIds,
    currentUserLoginId
  });

  const allContacts = ([
    ...caregivers,
    ...relatedContacts
  ] as unknown[]) as Participant[];

  return filteredChats.map(chatRoom => {
    const {
      lastMessage,
      intendedResidentId,
      chatRoomType,
      participants: participantLoginIdList
    } = chatRoom;

    const filteredParticipants = allContacts.filter(detail =>
      participantLoginIdList.includes(detail.loginId)
    );

    if (!lastMessage) {
      return {
        id: chatRoom.id,
        chatRoomType: chatRoomType.toLowerCase() as ConversationType,
        participants: filteredParticipants,
        hasUnreadMessages: !isLastMessageSeen(chatRoom, currentUserLoginId),
        description: chatRoom.description,
        loginSenderId: '',
        lastMessage: undefined,
        resident: {
          id: intendedResidentId,
          name: '',
          room: {
            id: '',
            number: '0',
            ward: {
              id: '',
              name: ''
            }
          }
        }
      } as MessagingHistoryConversation;
    }

    const sender = allContacts.find(
      contact => contact?.loginId === lastMessage.loginSenderId
    );
    const participant = allContacts.find(
      contact => contact?.loginId === lastMessage.participantLoginId
    );

    const messageText =
      getMessageContent(lastMessage, participant?.name, sender?.name) ||
      'message default';

    const resident = residents.find(entry => entry.id === intendedResidentId);

    return {
      id: chatRoom.id,
      chatRoomType: chatRoomType.toLowerCase() as ConversationType,
      participants: filteredParticipants,
      hasUnreadMessages: !isLastMessageSeen(chatRoom, currentUserLoginId),
      loginSenderId: lastMessage.loginSenderId,
      description: chatRoom.description,
      resident: resident as MessagingResident,
      lastMessage: {
        chatRoomId: chatRoom.id,
        loginSenderId: lastMessage.loginSenderId,
        participantLoginId: lastMessage.participantLoginId,
        messageType: lastMessage!.messageType,
        id: lastMessage.id,
        timestamp: lastMessage.timestamp,
        textMessage: messageText,
        fileName: lastMessage.fileName,
        fileUri: lastMessage.fileUri
      }
    };
  });
};

export const populateChatMessage = (
  update: ComMessageUpdate,
  sender: Caregiver | ResidentContact,
  senderStatus: StatusType | RelatedContactStatus | undefined,
  content: string
): ChatMessage => ({
  id: update.id,
  author: {
    loginId: sender.loginId,
    id: sender.id,
    name: sender.name,
    status: senderStatus,
    photoUrl: sender.photoUrl,
    role: (sender as Caregiver).role,
    relationship: (sender as ResidentContact).relationship,
    residentId: (sender as ResidentContact).residentId
  },
  /**
   * @descrition readBy gives us little (whatsapp like) notifications about who has seen the message
   * for now we leave it empty
   */
  readBy: [],
  content,
  fileUri: update.values.fileUri,
  fileName: update.values.fileName,
  type: update.messageType.toLowerCase() as ChatMessageType,
  conversationId: update.chatRoomId,
  sentAt: update.timestamp
});

export const populateMessage = (update: ComMessageUpdate): Message => {
  return {
    id: update.id,
    messageType: update.messageType.toLowerCase() as ChatMessageType,
    loginSenderId: update.loginSenderId,
    textMessage: update.values.textMessage,
    timestamp: update.timestamp,
    chatRoomId: update.chatRoomId,
    fileName: update.values?.fileName,
    fileUri: update.values?.fileUri,
    messageThatWasSeen: update.values?.messageThatWasSeen,
    participantLoginId:
      update.values?.participantLoginId || update.participantLoginId
  };
};
