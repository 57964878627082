import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { ActivityChartLine } from '../ActivityChartLine';
import { SITTING_HOURS_COLOR } from '../chartColors.const';
import { sittingHoursInterval } from '../Intervals';
import { SittingHoursTooltip } from '../Tooltips/SittingHoursTooltip';

export const SittingHoursChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    seconds?: number;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={sittingHoursInterval}
      data={data}
      tooltip={SittingHoursTooltip}
      lineOne={ActivityChartLine(SITTING_HOURS_COLOR, 'hours')}
      color={SITTING_HOURS_COLOR}
      allowHighlight={allowHighlight}
      invertColors={true}
    />
  );
};
