import moment, { MomentInput } from 'moment';

const getLocaleTimeString = (source: number | string) => {
  return source.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  });
};

const getHours = (timeStamp: string) => {
  const localTime = new Date();
  const timeStampTime = new Date(timeStamp);

  const hours = Math.floor(
    (localTime.getTime() - timeStampTime.getTime()) / 1000 / 3600
  );

  return hours > 9 ? hours.toString() : `0${hours.toString()}`;
};

const getMinutes = (timeStamp: string) => {
  const localTime = new Date();
  const timeStampTime = new Date(timeStamp);

  const minutes = Math.floor(
    ((localTime.getTime() - timeStampTime.getTime()) / 1000 / 3600 -
      Math.floor(
        (localTime.getTime() - timeStampTime.getTime()) / 1000 / 3600
      )) *
      60
  );

  return minutes > 9 ? minutes.toString() : `0${minutes.toString()}`;
};

const getSeconds = (timeStamp: string) => {
  const localTime = new Date();
  const timeStampTime = new Date(timeStamp);

  const seconds = Math.floor(
    (((localTime.getTime() - timeStampTime.getTime()) / 1000 / 3600 -
      Math.floor(
        (localTime.getTime() - timeStampTime.getTime()) / 1000 / 3600
      )) *
      60 -
      Math.floor(
        ((localTime.getTime() - timeStampTime.getTime()) / 1000 / 3600 -
          Math.floor(
            (localTime.getTime() - timeStampTime.getTime()) / 1000 / 3600
          )) *
          60
      )) *
      60
  );
  return seconds > 9 ? seconds.toString() : `0${seconds.toString()}`;
};

export const timeSince = (timeStamp: string) => {
  const hours = getHours(timeStamp);
  const minutes = getMinutes(timeStamp);
  const seconds = getSeconds(timeStamp);
  if (hours === '00') {
    return `${minutes}:${seconds}`;
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
};

export const getTimeDifference = (timestamp: MomentInput) => {
  const alarmTime = moment.utc(timestamp);

  const timeDifference = moment.utc().diff(alarmTime, 'seconds', false);

  const duration = moment.duration(timeDifference, 'seconds');

  const time = {
    seconds: duration.seconds(),
    minutes: duration.minutes(),
    hours: duration.hours(),
    days: duration.days(),
    months: duration.months(),
    years: duration.years()
  };

  if (time.years >= 1 || time.months >= 1 || time.days >= 1) {
    return duration.humanize();
  }

  const { hours, minutes, seconds } = time;

  if (time.hours) {
    return `${getLocaleTimeString(hours)}:${getLocaleTimeString(
      minutes
    )}:${getLocaleTimeString(seconds)}`;
  } else {
    return `${getLocaleTimeString(minutes)}:${getLocaleTimeString(seconds)}`;
  }
};

export const sortByDate = (arr: any[], fieldWithDate: string) => {
  return [...arr].sort((a, b) => {
    return moment(a[fieldWithDate] as MomentInput).diff(
      b[fieldWithDate] as MomentInput
    );
  });
};

export const formatMessageTimestamp = (timestamp: string) => {
  const dateToCheck = moment(timestamp);
  const TODAY = moment().startOf('day');
  const YESTERDAY = moment()
    .subtract(1, 'days')
    .startOf('day');
  if (dateToCheck.isSame(TODAY, 'd')) {
    return dateToCheck.format('h:mm a');
  } else if (dateToCheck.isSame(YESTERDAY, 'd')) {
    return 'Yesterday';
  }
  return dateToCheck.format('MM/DD/YY');
};
