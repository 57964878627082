import React, { useEffect, useState } from 'react';

import { BackButton, RoundPlusButton, RoundSearchButton } from 'components';
import { debounce } from 'utils';

import { SearchHeader } from 'components/SearchHeader/SearchHeader';
import { DEFAULT_SEARCH_TERM } from 'Settings/constants';

import styles from './Panel.module.css';

export const PanelList: React.FunctionComponent<{
  title: string;
  children: React.ReactNode;
  onAdd?: () => void;
  listRef?: any;
  hasError?: boolean;
  panelStyle?: any;
  backLink?: string;
  listStyle?: string;
  onSearch?: (val: string) => void;
  searchInputName?: string;
  resetHeaderKey?: string;
}> = ({
  title = '',
  children,
  onAdd,
  listRef,
  hasError,
  panelStyle,
  backLink,
  listStyle,
  onSearch,
  searchInputName,
  resetHeaderKey
}) => {
  const [showHeader, setShowHeader] = useState(false);

  const panelWithList = listStyle
    ? `${styles.panelWithList} ${listStyle}`
    : styles.panelWithList;

  const panelHeaderStyle = hasError
    ? `${styles.panelHeader} ${styles.panelHeaderHidden}`
    : styles.panelHeader;

  const computedPanelStyle = panelStyle
    ? `${panelStyle} ${styles.panel}`
    : styles.panel;

  const searchHandler = debounce((val: string) => {
    if (onSearch) {
      onSearch(val);
    }
  }, 500);

  const openSearchHeaderHandler = () => {
    if (!onSearch) {
      return;
    }
    onSearch(DEFAULT_SEARCH_TERM);
    setShowHeader(true);
  };

  const closeSearchHeaderHandler = () => {
    if (onSearch) {
      onSearch(DEFAULT_SEARCH_TERM);
    }
    setShowHeader(false);
  };

  useEffect(() => {
    if (showHeader) {
      setShowHeader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetHeaderKey]);

  return (
    <article className={computedPanelStyle} id={title}>
      {showHeader ? (
        <SearchHeader
          searchInputName={`${searchInputName}`}
          onClose={closeSearchHeaderHandler}
          searchValue={DEFAULT_SEARCH_TERM}
          onSearch={searchHandler}
        />
      ) : (
        <header className={panelHeaderStyle}>
          {backLink ? (
            <BackButton title={title} link={backLink} />
          ) : (
            <title className={styles.panelTitle}>{title}</title>
          )}
          <div className={styles.panelButtonsContainer}>
            <RoundSearchButton
              onClick={openSearchHeaderHandler}
              testId="search-test-button"
            />
            {onAdd && (
              <RoundPlusButton onClick={onAdd} testId="add-test-button" />
            )}
          </div>
        </header>
      )}

      <section className={panelWithList}>{children}</section>
    </article>
  );
};
