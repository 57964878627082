import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { FileUpload, SendButton } from 'components';

import { useCommunicationState } from './contexts/communication.context';
import { ChatMessageType, ChatMessageTypes } from './types';

import styles from './MessageInputBox.module.css';

const isValidMessage = (message: string) =>
  message && message.replace(/\s/g, '') !== '';

export const MessageInputBox: React.FunctionComponent<{
  onSend: (message: any, type: ChatMessageType) => void;
  onFileUpload: (file: File) => void;
  clearOnSend?: boolean;
  disabled?: boolean;
  onInputFocus?: () => void;
}> = ({
  onSend,
  onFileUpload,
  clearOnSend = true,
  disabled = false,
  onInputFocus
}) => {
  const { activeConversation } = useCommunicationState();

  const [newMessage, setNewMessage] = useState('');
  const [hasFocus, setHasFocus] = useState(false);
  const inputParentRef = useRef<HTMLLabelElement | null>(null);

  useEffect(() => {
    setNewMessage('');
  }, [activeConversation]);

  const onInputChange = (evt: any) => {
    const { value, parentNode } = evt.target;

    parentNode.dataset.value = value; // since textarea cannot expand dinamically by itself, we set this to trick it to expand along with its parent height
    setNewMessage(value);
  };

  const onKeyUp = (evt: any) => {
    evt.preventDefault();

    const enterPressed = evt.key === 'Enter' || evt.keyCode === '13';

    if (enterPressed && !evt.shiftKey && isValidMessage(newMessage)) {
      setHasFocus(false);
      onSend(newMessage.trim(), ChatMessageTypes.TEXT);
      if (!clearOnSend) {
        return;
      }
      setNewMessage('');
      if (inputParentRef.current !== null) {
        inputParentRef.current.dataset.value = '';
      }
    }
  };

  const onKeyDown = (evt: any) => {
    if (evt.keyCode === 13 && !evt.shiftKey) {
      // prevent from adding a newline on enter
      evt.preventDefault();
    }
  };

  const onClick = (evt: any) => {
    evt.preventDefault();

    if (isValidMessage(newMessage)) {
      onSend(newMessage.trim(), ChatMessageTypes.TEXT);

      if (clearOnSend) {
        setNewMessage('');
      }
    }
  };

  const onFocus = () => {
    setHasFocus(true);
    if (onInputFocus) {
      onInputFocus();
    }
  };

  const onBlur = () => {
    setHasFocus(false);
  };

  return (
    <div className={styles.messageInputContainer}>
      <FileUpload onFileChange={onFileUpload} disabled={disabled} />
      <label
        htmlFor="newMessage"
        className={classnames(styles.resizableContainer, {
          [styles.hasFocus]: hasFocus
        })}
        ref={inputParentRef}
      >
        <textarea
          className={styles.messageInput}
          name="newMessage"
          placeholder="Message"
          onKeyUp={onKeyUp}
          onChange={onInputChange}
          value={newMessage}
          rows={1}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
      </label>
      <SendButton onClick={onClick} disabled={disabled} />
    </div>
  );
};
