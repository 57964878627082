export enum HomeViews {
  ALARMS_WITH_RESIDENTS = 'ALARMS_WITH_RESIDENTS',
  ALARMS_ONLY = 'ALARMS_ONLY'
}

export enum SwitchHomeView {
  SWITCH_VIEW = 'SWITCH_VIEW',
  SET_TOP = 'SET_TOP'
}

export type HomeView = HomeViews.ALARMS_ONLY | HomeViews.ALARMS_WITH_RESIDENTS;
