import { AlarmsPriority, ColorCode, ColorCodes } from 'Notifications/types';

export const getNotificationPriority = (colorCode: ColorCode) => {
  switch (colorCode) {
    case ColorCodes.RED: {
      return AlarmsPriority.PRIORITY_HIGH;
    }

    case ColorCodes.DARK_ORANGE:
    case ColorCodes.ORANGE: {
      return AlarmsPriority.PRIORITY_MEDIUM;
    }

    default: {
      return AlarmsPriority.PRIORITY_LOW;
    }
  }
};
