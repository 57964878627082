import classnames from 'classnames';
import React from 'react';

import styles from './LoginInput.module.css';

export const LoginInput: React.FunctionComponent<{
  name: string;
  placeholder: string;
  type?: string;
  requiredValueMissing: boolean;
  feedbackMessage?: string | JSX.Element;
  onFocus?: () => void;
  onChange?: () => void;
  forwardedRef?: any;
  onKeyPress?: (e: any) => void;
}> = ({
  name,
  placeholder,
  requiredValueMissing,
  forwardedRef,
  feedbackMessage = '',
  type = 'text',
  onKeyPress,
  ...props
}) => {
  const messageType = typeof feedbackMessage;
  return (
    <div className={styles.inputContainer}>
      <input
        className={styles.loginInput}
        name={name}
        placeholder={placeholder}
        type={type}
        data-haserror={requiredValueMissing}
        ref={forwardedRef}
        onKeyDown={onKeyPress}
        autoComplete="new-password"
        {...props}
      />
      <h4
        className={classnames(styles.errorMessage, {
          [styles.visible]: !!feedbackMessage,
          [styles.errorMessage]: messageType === 'string'
        })}
      >
        {feedbackMessage}
      </h4>
    </div>
  );
};
