import {
  AD_HOC_NAME_MINIMUM_LENGTH,
  SEARCH_TERM_MINIMUM_LENGTH
} from 'Communication/constants';

export const isValidSearchTerm = (searchTerm: string) => {
  return searchTerm.trim().length >= SEARCH_TERM_MINIMUM_LENGTH;
};

export const isValidAdHocName = (name: string) => {
  return name.trim().length >= AD_HOC_NAME_MINIMUM_LENGTH;
};
