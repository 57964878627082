/* eslint-disable */
import {
  IToggleFeature,
  MappedWard
} from 'Settings/actions/wardsMapping.utils';
import { FacilityId, WardId } from 'types';
import { axiosWrapper } from 'utils';

export const editWard = (ward: MappedWard) => async () => {
  const response = await axiosWrapper(
    `/facilities/${ward.facilityId}/wards/${ward.id}`,
    'PUT',
    null,
    ward
  );
  return response.data;
};

export const editWardFeature = async (
  services: IToggleFeature,
  facilityId: FacilityId,
  wardId: WardId
) => {
  await axiosWrapper(
    `/facilities/${facilityId}/wards/${wardId}/toggle-feature`,
    'PUT',
    null,
    services
  );
  return wardId;
};
