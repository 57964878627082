import React from 'react';

import { ReactComponent as Oxygen } from 'icons/oxygen.svg';
import { convertTimestamp, OxygenSaturation } from 'Residents';

import { VitalCard } from './VitalCard';

import style from './OxygenSaturationVitalCard.module.css';

export const OxygenSaturationVitalCard: React.FunctionComponent<{
  onClick: () => void;
  oxygenSaturation: OxygenSaturation;
}> = ({ onClick, oxygenSaturation }) => {
  const { measuredAt, value, code, unit } = oxygenSaturation || {};
  const hasWarning = !!code && code !== 'GREEN';

  return (
    <div className={style.oxygenSaturation} onClick={onClick}>
      <VitalCard
        title="Oxygen Saturation"
        timestamp={measuredAt && convertTimestamp(measuredAt)}
        reading={value && value.oxygenSaturation}
        hasWarning={hasWarning}
        unit={unit}
      >
        <Oxygen />
      </VitalCard>
    </div>
  );
};
