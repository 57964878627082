import { MessageListResponse } from 'Communication';
import { MESSAGES_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

export const getMessageHistory = (
  chatRoomId: string,
  page: string
) => async () => {
  const response = await axiosWrapper<MessageListResponse>(
    `/chat-rooms/${chatRoomId}/messages?page=${page}&size=${MESSAGES_PAGE_SIZE}`
  );

  const { items, nextPage } = response.data;

  return {
    items,
    nextPage
  };
};
