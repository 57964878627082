import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { ActivityChartLine } from '../ActivityChartLine';
import { STAFF_VISITS_COLOR } from '../chartColors.const';
import { staffVisitsInterval } from '../Intervals';
import { StaffVisitsTooltip } from '../Tooltips/StaffVisitsTooltip';

export const StaffVisitsChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    visits?: number;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={staffVisitsInterval}
      data={data}
      tooltip={StaffVisitsTooltip}
      lineOne={ActivityChartLine(STAFF_VISITS_COLOR, 'visits')}
      color={STAFF_VISITS_COLOR}
      allowHighlight={allowHighlight}
      invertColors={true}
    />
  );
};
