import React, { useMemo } from 'react';

import { AccessMessage, FetchError, LoadingPlaceholder } from 'components';
import { useFetcher } from 'hooks';
import { useLocationState } from 'Location';
import { Caregiver } from 'Settings';

import { useCaregiversStatus } from 'Caregivers';
import { getCaregiversByWard } from './actions';
import { CaregiverCard } from './CaregiverCard';
import { StaffMember } from './types';
import { getStaffMembers } from './utils';

import style from './CaregiversOverview.module.css';

export const CaregiversOverview: React.FunctionComponent = () => {
  const {
    facility,
    ward: wardId,
    filtersLoading: locationFiltersLoading,
    filtersError: locationFiltersError,
    rtlsError,
    rtlsLoading,
    devicesLocation
  } = useLocationState();

  const {
    data: caregiversStatusMap,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const caregiversByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getCaregiversByWard(facility.id, wardId);
    }
  }, [facility.id, wardId]);

  const {
    data: caregiversByWard,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<Caregiver[]>(caregiversByWardAction, []);

  const staffMembers = getStaffMembers(
    caregiversByWard,
    devicesLocation,
    caregiversStatusMap
  );

  const content = staffMembers.map((staff: StaffMember) => {
    return (
      <CaregiverCard
        key={staff.id}
        caregiver={staff}
        link={`/CaregiverDashboard?facility=${facility.id}&ward=${wardId}&caregiver=${staff.id}`}
      />
    );
  });

  const caregiverList = content.length ? (
    content
  ) : (
    <p className={style.listPlaceholder}>No caregivers for this ward</p>
  );

  const loading =
    rtlsLoading || caregiversLoading || locationFiltersLoading || statusLoading;
  const fetchError =
    rtlsError || caregiversError || locationFiltersError || statusError;

  return (
    <div className={style.content}>
      {loading && !fetchError && <LoadingPlaceholder />}
      {!loading && !fetchError && wardId && caregiverList}
      {fetchError && !loading && <FetchError error={fetchError} />}
      <AccessMessage />
    </div>
  );
};
