import { axiosWrapper } from 'utils';

import { Device, DeviceListResponse } from '../types/device.type';

export const getPaginatedDevices = (
  facilityId: string,
  page: number
) => async () => {
  const initialUrl = `/facilities/${facilityId}/devices`;
  const url = page ? `${initialUrl}?page=${page}` : initialUrl;
  const response = await axiosWrapper<
    DeviceListResponse | { items: Device[]; nextPage: string }
  >(url);

  return response.data;
};
