import classnames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateUtils } from 'react-day-picker';

import { RoomAssignment } from 'Caregivers';
import { ProfilePlaceholder, Snackbar } from 'components';
import { VACANT_ROOM_NAME } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { ReactComponent as ExclamationMark } from 'icons/exclamationMark.svg';
import { useNotificationState } from 'Notifications';
import {
  assignCaregiverToRoom,
  ResidentListItem,
  unassignCaregiverFromRoom
} from 'Residents';
import { formatName } from 'utils';
import { getMarkAndHighlight } from 'utils/checkAlarms';

import { AssignButton } from './AssignButton';
import { useScheduleState } from './schedule.context';

import style from './ResidentListEntry.module.css';

interface IResidentListEntryProps {
  data: ResidentListItem;
  currentDragged: string;
  selectedDay: Date;
  scrollPosition: number;
  caregiverClicked: string;
  roomClicked: string;
  allButtonsDisabled: boolean;
  roomAssignments: RoomAssignment[];
  roomAssignmentsError: Error | null;
  roomAssignmentsLoading: boolean;
  setAssignmentToRoomError: (value: Error | null) => void;
  setCaregiverClicked: (value: string) => void;
  setRoomClicked: (value: string) => void;
  refetch: () => void;
  setAllButtonsDisabled: (status: boolean) => void;
  refetchRoomAssignments: () => void;
}

export const ResidentListEntry: React.FunctionComponent<IResidentListEntryProps> = ({
  allButtonsDisabled,
  data,
  currentDragged,
  selectedDay,
  scrollPosition,
  setCaregiverClicked,
  setRoomClicked,
  roomClicked,
  caregiverClicked,
  refetch,
  setAssignmentToRoomError,
  setAllButtonsDisabled,
  roomAssignments,
  roomAssignmentsError,
  roomAssignmentsLoading,
  refetchRoomAssignments
}) => {
  const { room, resident } = data;
  const { notifications } = useNotificationState();
  const { assignedCaregivers } = useScheduleState();
  const [allButtonsLoading, setAllButtonsLoading] = useState(true);

  const { setAction, loading: onSaveLoading, error: submitError } = usePoster();
  const {
    setSubmitted,
    isErrorShowing,
    dismissError,
    submitted
  } = useSubmitError(submitError, onSaveLoading);

  const workingDay = useMemo(() => moment(selectedDay).format('YYYY-MM-DD'), [
    selectedDay
  ]);

  useEffect(() => {
    setAssignmentToRoomError(roomAssignmentsError);
  });

  useEffect(() => {
    setAllButtonsDisabled(isErrorShowing);
  }, [isErrorShowing, setAllButtonsDisabled]);

  useEffect(() => {
    if (!onSaveLoading && !submitError && submitted) {
      setRoomClicked(room.id);
      setSubmitted(false);
    }
  }, [
    onSaveLoading,
    room.id,
    setRoomClicked,
    setSubmitted,
    submitError,
    submitted
  ]);

  useEffect(() => {
    if (roomClicked === room.id) {
      setAllButtonsLoading(false);
      refetchRoomAssignments();
      setRoomClicked('');
    }
  }, [room.id, refetchRoomAssignments, roomClicked, setRoomClicked]);

  const residentData = useMemo(
    () =>
      resident || {
        id: '',
        name: VACANT_ROOM_NAME,
        gender: 'Unknown',
        photoUrl: ''
      },
    [resident]
  );

  const { id, name, gender, photoUrl } = residentData;
  const formattedName = useMemo(() => formatName(name), [name]);

  const type = gender.toLowerCase();

  const residentNotificationStyle = useMemo(
    () => getMarkAndHighlight(notifications, id),
    [id, notifications]
  );

  const handleAssign = useCallback(
    (
      dataToAssign: {
        caregiverId: string;
        wardId: string;
        roomId: string;
        workingDay: string;
      },
      isAssigned: boolean
    ) => {
      if (isAssigned) {
        setAction(unassignCaregiverFromRoom(dataToAssign));
      } else {
        setAction(assignCaregiverToRoom(dataToAssign));
      }
      setTimeout(() => {
        setSubmitted(true);
      });
    },
    [setAction, setSubmitted]
  );

  const buttons = useMemo(
    () =>
      assignedCaregivers.map(caregiver => {
        const isAssigned = !roomAssignments
          ? false
          : roomAssignments.filter(
              ({ caregiverId, roomId }) =>
                caregiverId === caregiver.id && roomId === room.id
            ).length > 0;
        const action = () => {
          handleAssign(
            {
              caregiverId: caregiver.id,
              wardId: room.ward.id,
              roomId: room.id,
              workingDay
            },
            isAssigned
          );
          setCaregiverClicked(caregiver.id);
        };

        if (currentDragged !== caregiver.id) {
          return (
            <AssignButton
              key={caregiver.id}
              isAssigned={isAssigned}
              loading={
                (roomAssignmentsLoading && allButtonsLoading) ||
                (roomAssignmentsLoading && caregiverClicked === caregiver.id)
              }
              action={action}
              isPastDay={DateUtils.isPastDay(selectedDay)}
            />
          );
        }
        return null;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allButtonsLoading,
      assignedCaregivers,
      caregiverClicked,
      currentDragged,
      room.id,
      room.ward.id,
      roomAssignments,
      roomAssignmentsLoading,
      selectedDay,
      workingDay
    ]
  );

  return (
    <>
      <li className={style.entry}>
        <div
          className={classnames(style.resident, {
            [style.withRightBorder]: scrollPosition > 0
          })}
          style={{ left: scrollPosition }}
        >
          {residentNotificationStyle.isHighlighted && (
            <div className={style.highlight} />
          )}
          <ProfilePlaceholder name={name} type={type} photoUrl={photoUrl} />
          <div className={style.details}>
            <span className={style[type]}>{formattedName}</span>
            <span className={style.room}>{`Room: ${room.number}`}</span>
          </div>
          {residentNotificationStyle.hasExclamationMark && (
            <div className={style.warning} data-testid="warning-test">
              <ExclamationMark />
            </div>
          )}
        </div>
        <div
          className={classnames(style.buttons, {
            [style.disabled]: roomAssignmentsLoading || allButtonsDisabled
          })}
        >
          {buttons}
        </div>
      </li>
      <Snackbar
        isOpen={isErrorShowing}
        onClose={dismissError}
        message="An error occurred while managing assignments. Please reload to see latest changes."
        reloadAction={refetch}
      />
    </>
  );
};
