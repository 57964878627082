import React from 'react';

import { ReactComponent as FoodIn } from 'icons/food-intake.svg';
import { FoodIntake } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './FoodIntakeCard.module.css';

export const FoodIntakeCard: React.FunctionComponent<{
  foodIntake: FoodIntake | undefined;
  onClick: () => void;
}> = ({ foodIntake, onClick }) => {
  const { value, unit } = foodIntake || {};
  const reading = value && parseInt(value.count, 10);

  return (
    <div className={style.foodIntake} onClick={onClick}>
      <ActivityInfoCard reading={reading} unit={unit} type="foodIntake">
        <FoodIn />
      </ActivityInfoCard>
    </div>
  );
};
