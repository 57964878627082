import React from 'react';
import { Tooltip } from 'recharts';
import style from './ToolTip.module.css';

const Content: React.FunctionComponent<{
  payload?: any;
}> = props => {
  if (props.payload[0]) {
    return (
      <div className={style.bloodPressureTooltip}>
        <span>{`${props.payload[0].payload.label} `}</span>
        <span>
          Systolic Value: <b>{`${props.payload[0].payload.systolicValue}`}</b>
          {` ${props.payload[0].payload.unit}`}
        </span>
        <span>
          Diastolic Value: <b>{`${props.payload[0].payload.diastolicValue}`}</b>{' '}
          {` ${props.payload[0].payload.unit}`}
        </span>
      </div>
    );
  }
  return <></>;
};

export const BloodPressureToolTip = <Tooltip content={Content} />;
