import React from 'react';

import { ReactComponent as Blood } from 'icons/blood.svg';
import { BloodPressure, convertTimestamp } from 'Residents';

import { VitalCard } from './VitalCard';

import style from './BloodPressureVitalCard.module.css';

const warningCodes = ['ORANGE', 'DARK_ORANGE', 'RED'];

export const BloodPressureVitalCard: React.FunctionComponent<{
  bloodPressure: BloodPressure | undefined;
  onClick: () => void;
}> = ({ bloodPressure, onClick }) => {
  const { measuredAt, value, code } = bloodPressure || {};
  const reading =
    value && `${value.systolicPressure}/${value.diastolicPressure}`;
  const hasWarning = !!code && warningCodes.includes(code);

  return (
    <div className={style.bloodPressure} onClick={onClick}>
      <VitalCard
        title="Blood Pressure"
        timestamp={measuredAt && convertTimestamp(measuredAt)}
        reading={reading}
        hasWarning={hasWarning}
      >
        <Blood />
      </VitalCard>
    </div>
  );
};
