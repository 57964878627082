import moment from 'moment-timezone';

import { usTimezones } from 'consts';

export const formatTimezone = (timezone: string) => {
  const timezoneObject = moment.tz(timezone);
  const timezoneOffset = timezoneObject.format('Z');
  const timezoneAbbr = timezoneObject.format('z');

  return `(UTC${timezoneOffset}) ${timezone} (${timezoneAbbr})`;
};

export const timezoneOptions = usTimezones.map(timezone => ({
  value: timezone,
  label: formatTimezone(timezone)
}));
