import React, { useMemo } from 'react';

import { FetchError } from 'components';
import { useFetcher, useModalMeasurements } from 'hooks';
import {
  BloodSugar,
  getVitalsHistory,
  HistoryHeader,
  HistoryLabel,
  HistoryLoadingPlaceholder
} from 'Residents';

import { BloodSugarHistoryLogs } from './BloodSugarHistoryLogs';
import { BloodSugarHistoryChart } from './Charts';

import style from 'Residents/History/History.module.css';

export const BloodSugarHistory: React.FunctionComponent<{
  onClose: () => void;
  facilityId: string;
  residentId: string;
  onAdd: () => void;
  historyFilters: {
    start: string;
    end: string;
    label: HistoryLabel;
  };
  changeHistoryFilter: (filter: HistoryLabel) => void;
}> = ({
  onClose,
  facilityId,
  residentId,
  onAdd,
  historyFilters,
  changeHistoryFilter
}) => {
  const { modalOffsetTop, modalOffsetLeft, modalWidth } = useModalMeasurements(
    'Vitals',
    'MainContent',
    !!residentId
  );
  const computedOffsetTop = modalOffsetTop >= 0 ? modalOffsetTop : 0;
  const computedOffsetLeft = modalOffsetLeft >= 0 ? modalOffsetLeft - 15 : 0;

  const vitalsHistoryLogAction = useMemo(() => {
    if (facilityId && residentId) {
      const { start, end } = historyFilters;

      return getVitalsHistory(
        facilityId,
        residentId,
        'BLOOD_SUGAR',
        start,
        end
      );
    }
  }, [facilityId, residentId, historyFilters]);

  const { data: vitalsHistory, loading, error } = useFetcher<BloodSugar[]>(
    vitalsHistoryLogAction,
    []
  );

  return (
    <div
      className={style.history}
      style={{
        marginTop: computedOffsetTop,
        marginLeft: computedOffsetLeft,
        width: modalWidth
      }}
      id="History"
    >
      {!error && (
        <HistoryHeader
          title="Blood Sugar History"
          toggle={onClose}
          onAdd={onAdd}
        />
      )}
      {!loading && error && (
        <FetchError error={error} closable={true} onClose={onClose} />
      )}
      {!error && (
        <>
          <BloodSugarHistoryChart
            changeHistoryFilter={changeHistoryFilter}
            activeHistoryTab={historyFilters.label}
            readings={vitalsHistory as BloodSugar[]}
            loading={loading}
          />
          {loading && <HistoryLoadingPlaceholder />}
          {!loading && (
            <BloodSugarHistoryLogs
              historyLogs={vitalsHistory as BloodSugar[]}
            />
          )}
        </>
      )}
    </div>
  );
};
