import React from 'react';

import { RoundEditButton } from 'components';

import style from './CaregiverListHeader.module.css';

export const CaregiverListHeader: React.FunctionComponent<{
  onEdit: () => void;
}> = ({ onEdit }) => {
  return (
    <header className={style.header}>
      <title className={style.title}>Care Team</title>
      <RoundEditButton onClick={onEdit} />
    </header>
  );
};
