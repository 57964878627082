import React, { useMemo } from 'react';

import { FetchError, LoadingPlaceholder, Modal, PanelDetail } from 'components';
import { residentLabels, VACANT_ROOM_NAME } from 'consts';
import { useFetcher, useModal } from 'hooks';
import { Facility, getRoomDetails, Room } from 'Settings';
import { getHeight } from 'utils';

import { EditRoomForm } from './EditRoomForm';
import { RoomDeviceDetailEntry } from './RoomDeviceDetailEntry';

import stylesModal from 'components/Modal/Modal.module.css';
import styles from 'Settings/Settings.module.css';

export const RoomDetails: React.FunctionComponent<{
  facility: Facility;
  selectedRoomId: string;
  notifyRoomChange: (room: Room) => void;
}> = ({ facility, selectedRoomId, notifyRoomChange }) => {
  const getRoomDetailsMemo = useMemo(
    () => getRoomDetails(facility.id, selectedRoomId),
    [facility.id, selectedRoomId]
  );
  const { data, error, loading, setRefetch } = useFetcher<Room>(
    getRoomDetailsMemo,
    {} as Room
  );

  const { isShowing, toggle } = useModal();

  const onEdit = () => {
    toggle();
  };

  const modalClosed = () => {
    toggle();
    setRefetch();
  };

  const mapResidents = (residents: any[]) =>
    residents
      .filter(({ status }) => status)
      .map(({ name }) => name)
      .join(', ');

  const renderDeviceFields = () => {
    const deviceNumbers =
      data.devices && data.devices.length ? data.devices.map((_, i) => i) : [0];
    const renderDeviceDetailGroup = (device: number) => {
      const existingDeviceForNumber = data.devices.length
        ? data.devices[device]
        : undefined;

      return (
        <RoomDeviceDetailEntry
          deviceNumber={device + 1}
          device={existingDeviceForNumber}
        />
      );
    };

    return deviceNumbers.map(index => {
      const key = `deviceRow-${index + 1}`;
      return (
        <div className={styles.detailRow} key={key}>
          {renderDeviceDetailGroup(index)}
        </div>
      );
    });
  };

  return (
    <>
      <PanelDetail title="Room Details" onAction={onEdit} hasError={!!error}>
        {selectedRoomId && !error && !loading && (
          <>
            <div className={styles.detailRow}>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Room Number</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>{data.number}</span>
              </div>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Ward</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {data.ward && data.ward.name ? data.ward.name : 'N/A'}
                </span>
              </div>
              <div className={styles.panelGroupHalf}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>
                    {`${residentLabels[facility.type]}(s)`}
                  </h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {data.residents &&
                  data.residents.length &&
                  data.residents.find(({ status }) => status)
                    ? mapResidents(data.residents)
                    : VACANT_ROOM_NAME}
                </span>
              </div>
            </div>
            {renderDeviceFields()}
          </>
        )}
        {loading && <LoadingPlaceholder />}
        {error && !loading && <FetchError error={error} />}
        <Modal isShowing={isShowing} toggle={toggle}>
          <div className={stylesModal.modalBackdrop}>
            <div className={stylesModal.modalContentRightAlign}>
              <div
                className={stylesModal.modalRightWithVerticalBar}
                style={{
                  marginTop: getHeight('Room Details')
                }}
              >
                <EditRoomForm
                  facility={facility}
                  toggle={modalClosed}
                  room={data}
                  notifyRoomChange={notifyRoomChange}
                />
              </div>
            </div>
          </div>
        </Modal>
      </PanelDetail>
    </>
  );
};
