import React, { useEffect, useState } from 'react';

import { DetailsPlaceholder, TwoColumnLayout } from 'components';
import { Caregiver, Facility } from 'Settings/types';

import { CaregiverDetails } from './CaregiverDetails';
import { CaregiverList } from './CaregiverList';
import { CaregiverWardsAccess } from './CaregiverWardsAccess';

import caregiverStyles from './CaregiversSettings.module.css';

export const CaregiversSettings: React.FunctionComponent<{
  facility: Facility;
  activeCaregiver: string;
  resetActiveCaregiver: () => void;
}> = ({ facility, activeCaregiver, resetActiveCaregiver }) => {
  const [selectedCaregiver, setSelectedCaregiver] = useState<{ id: string }>({
    id: activeCaregiver
  });
  const [modifiedCaregiver, setModifiedCaregiver] = useState<Caregiver>(
    {} as Caregiver
  );

  const selectCaregiver = (caregiverId: string) => {
    setSelectedCaregiver({ id: caregiverId });
  };

  useEffect(() => {
    selectCaregiver(activeCaregiver);
    setModifiedCaregiver({} as Caregiver);
  }, [activeCaregiver]);

  return (
    <TwoColumnLayout
      left={
        <CaregiverList
          facility={facility}
          selectCaregiver={selectCaregiver}
          selectedCaregiverId={selectedCaregiver.id}
          modifiedCaregiver={modifiedCaregiver}
          resetActiveCaregiver={resetActiveCaregiver}
        />
      }
      right={
        <>
          <div className={caregiverStyles.panelHeight}>
            {selectedCaregiver.id && (
              <CaregiverDetails
                facility={facility}
                caregiver={selectedCaregiver}
                onCaregiverChange={setModifiedCaregiver}
              />
            )}
            {!selectedCaregiver.id && (
              <DetailsPlaceholder
                message="Please select Caregiver to display details"
                title="Caregiver Details"
              />
            )}
          </div>
          {selectedCaregiver.id && (
            <CaregiverWardsAccess
              parentFacility={facility}
              caregiver={selectedCaregiver}
            />
          )}
          {!selectedCaregiver.id && (
            <DetailsPlaceholder
              message="Please select Caregiver to manage their access to wards"
              title="Facilities & Wards Access"
              actionType="search"
            />
          )}
        </>
      }
    />
  );
};
