import React from 'react';

import {
  BodyTemperature,
  GraphLoadingPlaceholder,
  HistoryFilterTabs,
  HistoryLabel
} from 'Residents';

import styles from '../Charts.module.css';
import { BodyTemperatureChart } from './BodyTemperatureChart';
import { toMonthData } from './toMonthData';
import { toWeekData } from './toWeekData';

export const BodyTemperatureHistoryChart: React.FunctionComponent<{
  changeHistoryFilter: (filter: HistoryLabel) => void;
  activeHistoryTab: HistoryLabel;
  readings: BodyTemperature[];
  loading: boolean;
}> = ({ changeHistoryFilter, activeHistoryTab, loading, readings }) => {
  return (
    <div className={styles.chartBodyTemperature}>
      <HistoryFilterTabs
        onSelect={changeHistoryFilter}
        activeTab={activeHistoryTab}
      />
      {!loading && (
        <BodyTemperatureChart
          data={
            activeHistoryTab !== 'This Month'
              ? toWeekData(readings)
              : toMonthData(readings)
          }
          allowHighlight={activeHistoryTab !== 'Last Week'}
        />
      )}
      {loading && <GraphLoadingPlaceholder />}
    </div>
  );
};
