import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { ActivityChartLine } from '../ActivityChartLine';
import { LIQUID_OUTPUT_COLOR } from '../chartColors.const';
import { liquidBalanceInterval } from '../Intervals';
import { LiquidOutputTooltip } from '../Tooltips/LiquidOutputTooltip';

export const LiquidOutputChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    fluids?: number;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={liquidBalanceInterval}
      data={data}
      tooltip={LiquidOutputTooltip}
      lineOne={ActivityChartLine(LIQUID_OUTPUT_COLOR, 'fluids')}
      color={LIQUID_OUTPUT_COLOR}
      allowHighlight={allowHighlight}
      invertColors={true}
    />
  );
};
