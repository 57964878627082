import classnames from 'classnames';
import React, { useCallback, useState } from 'react';

import { useAuthDispatch } from 'Auth';
import { StatusType } from 'Caregivers';
import {
  CommunicationActionTypes,
  useCommunicationDispatch
} from 'Communication';
import { ConfirmationMessage, DismissibleError } from 'components';
import { usePoster, useSubmitError } from 'hooks';
import {
  NotificationActionTypes,
  useNotificationDispatch
} from 'Notifications';

import { editCaregiverAccount, editUserAccount } from './actions';
import {
  CaregiverProfileDetails,
  CaregiverProfileFormData,
  isCaregiverProfile,
  UserProfileDetails,
  UserProfileFormData
} from './types';
import { UserAccountForm } from './UserAccountForm';

import styles from './ManageAccount.module.css';
export const ManageAccount: React.FunctionComponent<{
  onClose: () => void;
  onSave: (reload: boolean) => void;
  isOpen: boolean;
  userDetails: UserProfileDetails | CaregiverProfileDetails;
  status: StatusType | undefined;
}> = ({ onClose, onSave, userDetails, isOpen, status }) => {
  const [shouldLogout, setShouldLogout] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const { logout } = useAuthDispatch();

  const { setAction, loading: onSaveLoading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, onSaveLoading);
  const notificationDispatch = useNotificationDispatch();
  const comDispatch = useCommunicationDispatch();

  const onSaveChanges = async (
    updates: UserProfileFormData | CaregiverProfileFormData
  ) => {
    setChangesSaved(false);
    const { newPassword, email, name, role, photoUrl } = updates;

    const emailAddressChanged = email !== userDetails.email;
    const vitalChanges = name !== userDetails.name || role !== userDetails.role;
    if (vitalChanges) {
      setShouldReload(true);
    }
    if (newPassword || emailAddressChanged) {
      setShouldLogout(true);
    }

    if (photoUrl && userDetails.photoUrl !== updates.photoUrl) {
      updates.photoUrl = photoUrl;
    }

    if (isCaregiverProfile(updates)) {
      setAction(editCaregiverAccount(updates.facilityId, updates));
    } else {
      setAction(editUserAccount(updates));
    }
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const onCancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    onClose();
  };

  const dismissMessage = () => {
    setChangesSaved(false);
  };

  const dismissAllMessages = useCallback(() => {
    setChangesSaved(false);
    dismissError();
  }, [dismissError]);

  if (submitted && !onSaveLoading && !error) {
    if (shouldLogout) {
      // logout if email/password changed
      logout();
      notificationDispatch({ type: NotificationActionTypes.CLEAR });
      comDispatch({ type: CommunicationActionTypes.CLEAR_COMMUNICATION });
    } else {
      setChangesSaved(true);
      setSubmitted(false);
      dismissError();
      onSave(shouldReload);
    }
  }

  return (
    <div
      className={classnames(styles.modalBackdrop, { [styles.hidden]: !isOpen })}
      id="ManageUserAccount"
    >
      <div className={styles.modalContent}>
        <UserAccountForm
          onSave={onSaveChanges}
          onCancel={onCancel}
          onSaveLoading={onSaveLoading}
          userData={userDetails}
          dismissMessages={dismissAllMessages}
          status={status}
        >
          {error && (
            <DismissibleError
              name="Profile changes"
              visible={isErrorShowing}
              error={error}
              dismiss={dismissError}
            />
          )}
          <ConfirmationMessage
            visible={changesSaved}
            dismiss={dismissMessage}
          />
        </UserAccountForm>
      </div>
    </div>
  );
};
