import { MissedMessagesCountsResponse } from 'Communication/types';
import { axiosWrapper } from 'utils';

export const getMissedMessagesCounts = async (page: number) => {
  const response = await axiosWrapper<MissedMessagesCountsResponse>(
    `/missed-messages?page=${page}`
  );
  return {
    items: response.data.items,
    nextPage: response?.data?.nextPage
      ? Number(response.data.nextPage)
      : undefined
  };
};
