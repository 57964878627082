import { axiosWrapper } from 'utils';

import { Room } from 'Settings/types';

export const editRoom = (room: Room) => async () => {
  const response = await axiosWrapper(
    `/facilities/${room.facilityId}/rooms/${room.id}`,
    'PUT',
    null,
    room
  );
  return response.data;
};
