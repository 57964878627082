import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { WardAccessListResponse } from '../types/access.type';

export const getCaregiverAccessToWards = (caregiverId: string) => async () => {
  const response = await axiosWrapper<WardAccessListResponse>(
    `/caregivers/${caregiverId}/accesses/wards?size=${MAX_PAGE_SIZE}`
  );
  return response.data.items;
};
