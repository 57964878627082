import React, { useMemo } from 'react';

import { FetchError } from 'components';
import { useFetcher } from 'hooks';
import { useLocationContext } from 'Location';
import { getResidentsByWard, ResidentCard } from 'Residents';
import { Resident } from 'Settings';
import { sortByKey } from 'utils';

import { ResidentsLoadingPlaceholder } from './ResidentsLoadingPlaceholder';

import styles from './ResidentsList.module.css';

export const ResidentsList: React.FunctionComponent = () => {
  const {
    location: {
      facility,
      ward: wardId,
      rtlsLoading,
      rtlsError,
      devicesLocation,
      selectedWardDetails
    },
    label
  } = useLocationContext();

  const residentsByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getResidentsByWard(facility.id, wardId, true);
    }
  }, [facility.id, wardId]);

  const {
    data: { items: activeWardResidents },
    error: residentsError,
    loading: residentsLoading
  } = useFetcher<{
    items: Resident[];
  }>(residentsByWardAction, { items: [] });

  const residentCards = useMemo(() => {
    const sortedByAssignedRoom = selectedWardDetails?.services.rtls.features
      .rtlsPatient.isEnabled
      ? sortByKey(activeWardResidents, 'name')
      : sortByKey(activeWardResidents, 'room.number');

    return sortedByAssignedRoom.map(({ id, name, gender, photoUrl, room }) => {
      const location = devicesLocation.find(
        ({ resident, room: deviceRoom }) =>
          resident && deviceRoom && resident.id === id
      );

      const locationLabel = !selectedWardDetails?.services.rtls.features
        .rtlsPatient.isEnabled
        ? `${room.ward.name} - ${room.number}`
        : location
        ? `${location.room.ward.name} - ${location.room.number}`
        : 'Unknown';

      const dashboardLink = `/ResidentDashboard?facility=${facility.id}&ward=${wardId}&resident=${id}`;

      return (
        <ResidentCard
          key={id}
          id={id}
          name={name}
          type={gender.toLowerCase()}
          link={dashboardLink}
          photoUrl={photoUrl}
          location={locationLabel}
        />
      );
    });
  }, [
    activeWardResidents,
    devicesLocation,
    facility.id,
    wardId,
    selectedWardDetails
  ]);

  const loading = residentsLoading || rtlsLoading;
  const fetchError = residentsError || rtlsError;
  const placeholder = `No ${label.toLowerCase()}s for this ward.`;

  const title = selectedWardDetails?.services.rtls.features.rtlsPatient
    .isEnabled
    ? `${label}s and Their Current Location`
    : `${label}s`;

  const ResidentsListContent = residentCards.length ? (
    <div className={styles.content}>{residentCards}</div>
  ) : (
    <p className={styles.emptyListPlaceholder}>{placeholder}</p>
  );

  return (
    <div className={styles.residentsContainer}>
      <h1 className={styles.title}>{title}</h1>
      {loading && !fetchError && <ResidentsLoadingPlaceholder />}
      {!loading && !fetchError && ResidentsListContent}
      {fetchError && !loading && <FetchError error={fetchError} />}
    </div>
  );
};
