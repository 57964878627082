import React from 'react';

import { ReactComponent as HeartRateIcon } from 'icons/heart-rate.svg';
import { convertTimestamp, HeartRate } from 'Residents';

import { VitalCard } from './VitalCard';

import style from './HeartRateVitalCard.module.css';

export const HeartRateVitalCard: React.FunctionComponent<{
  heartRate: HeartRate | undefined;
  onClick: () => void;
}> = ({ heartRate, onClick }) => {
  const { measuredAt, value, code, unit } = heartRate || {};

  const hasWarning = !!code && (code === 'ORANGE' || code === 'RED');

  return (
    <div className={style.heartRate} onClick={onClick}>
      <VitalCard
        title="Heart Rate"
        timestamp={measuredAt && convertTimestamp(measuredAt)}
        reading={value && value.heartRate}
        hasWarning={hasWarning}
        unit={unit}
      >
        <HeartRateIcon />
      </VitalCard>
    </div>
  );
};
