import React from 'react';

import styles from './History.module.css';

export const HistoryLoadingPlaceholder: React.FunctionComponent = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingPlaceholderLogs} />
      <div className={styles.loadingPlaceholderLogs} />
      <div className={styles.loadingPlaceholderLogs} />
      <div className={styles.loadingPlaceholderLogs} />
      <div className={styles.loadingPlaceholderLogs} />
    </div>
  );
};
