import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { IWardService } from './WardFeatures';

import styles from './styles/WardService.module.css';
interface IWardServiceProps {
  service: IWardService;
  selectedServiceId: string | null;
  onClick: (service: string) => void;
}

export const WardService: React.FunctionComponent<IWardServiceProps> = ({
  selectedServiceId,
  service,
  onClick
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(selectedServiceId === service.id);
  }, [selectedServiceId, service.id]);

  const handleServiceClick = useCallback(() => {
    if (!isSelected) {
      onClick(service.id);
    }
  }, [isSelected, onClick, service.id]);

  return (
    <li
      className={classNames(styles.serviceItem, {
        [styles.serviceSelected]: isSelected
      })}
      onClick={handleServiceClick}
    >
      <p>{service.name}</p>
      <div className={styles.serviceFeatures}>
        <span className={styles.featureEnabled}>
          {service.noOfEnabledFeatures}
        </span>
        <span>&nbsp;/&nbsp;{service.noOfFeatures}</span>
      </div>
    </li>
  );
};
