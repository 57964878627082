import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';

import { Gender, VACANT_ROOM_NAME } from 'consts';
import { useFetcher } from 'hooks';
import { useLocationState } from 'Location';
import { getPaginatedRooms, URLQueryValues } from 'Residents';
import { getResidentDetails } from 'Settings/actions';
import { Room } from 'Settings/types';
import { FacilityId, ResidentId, RoomId, WardId } from 'types';
import { sortByKey } from 'utils';

export interface Card {
  cardId: ResidentId | RoomId;
  cardType: URLQueryValues;
  cardName: string;
  cardLink: string;
  wardName: string;
  roomNumber: string;
  roomId: RoomId;
  residentGender: Gender | undefined;
  residentPhotoUrl: string | undefined;
}

export type PartialCard = Pick<Card, 'wardName' | 'roomNumber' | 'cardId'> & {
  oldRoomNumber: string;
  oldRoomId: RoomId;
  newRoomId: RoomId;
  wardId: WardId;
  facilityId: FacilityId;
};

const getLinkForCard = (
  cardId: ResidentId | RoomId,
  cardType: URLQueryValues,
  facilityId: FacilityId,
  wardId: WardId
) => {
  return `/ResidentDashboard?facility=${facilityId}&ward=${wardId}&${cardType}=${cardId}`;
};

export enum CardsActionTypes {
  ADD_CARDS = 'ADD_CARDS',
  CLEAR_CARDS = 'CLEAR_CARDS',
  EDIT_CARDS = 'EDIT_CARDS'
}

interface ICardsContext {
  cards: Card[];
  fetchMoreCards: () => void;
  fetching: boolean;
  fetchError: Error | null;
}

const InitialContext: ICardsContext = {
  cards: [],
  fetchMoreCards: () => undefined,
  fetching: false,
  fetchError: null
};

type CardsActionType =
  | {
      type: CardsActionTypes.ADD_CARDS;
      payload: Card[];
    }
  | {
      type: CardsActionTypes.CLEAR_CARDS;
      payload: void;
    }
  | {
      type: CardsActionTypes.EDIT_CARDS;
      payload: PartialCard;
    };

const CardsContext = createContext<ICardsContext>(InitialContext);
const CardsDispatch = createContext<Dispatch<CardsActionType>>(() => undefined);

const cardsReducer: Reducer<Card[], CardsActionType> = (state, action) => {
  const { type } = action;

  switch (type) {
    case CardsActionTypes.ADD_CARDS: {
      return state.concat(action.payload as Card[]);
    }
    case CardsActionTypes.CLEAR_CARDS: {
      return [];
    }
    case CardsActionTypes.EDIT_CARDS: {
      const { payload } = action as { payload: PartialCard };

      const newState = state.reduce((acc, card, index) => {
        /**
         * @description if we are moving the resident into a room that was previosuly empty
         * @returns we have to remove the Vacant Room card from the state
         */
        if (
          card.cardType === URLQueryValues.ROOM_KEY &&
          card.roomId === payload.newRoomId
        ) {
          return acc;
        }

        /**
         * @description if the current card has the same id as the payload
         * @returns just change the details of the card
         */
        if (card.cardId === payload.cardId) {
          acc.push({
            ...card,
            wardName: payload.wardName,
            roomNumber: payload.roomNumber,
            roomId: payload.newRoomId
          });
        } else {
          acc.push(card);
        }

        /**
         * @description if we are moving the only resident from a room that is now left empty
         * @returns we have to add a new Vacant Room card to the state
         */
        if (
          index === state.length - 1 &&
          !acc.find(item => item.roomId === payload.oldRoomId)
        ) {
          acc.push({
            cardId: payload.oldRoomId,
            roomId: payload.oldRoomId,
            cardLink: getLinkForCard(
              payload.oldRoomId,
              URLQueryValues.ROOM_KEY,
              payload.facilityId,
              payload.wardId
            ),
            cardName: VACANT_ROOM_NAME,
            cardType: URLQueryValues.ROOM_KEY,
            residentGender: undefined,
            residentPhotoUrl: undefined,
            roomNumber: payload.oldRoomNumber,
            wardName: payload.wardName
          });
        }
        return acc;
      }, [] as Card[]);

      return newState;
    }
    default: {
      return state;
    }
  }
};

export const CardsProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(cardsReducer, []);

  const [page, setPage] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  const {
    facility: { id: facilityId },
    ward: wardId
  } = useLocationState();

  const getRoomsAction = useCallback(
    getPaginatedRooms(facilityId, wardId, page),
    [facilityId, wardId, page]
  );

  const {
    data: { items: rooms, nextPage: roomsNextPage },
    error,
    loading
  } = useFetcher<{
    items: Room[];
    nextPage?: string;
  }>(getRoomsAction, { items: [], nextPage: '0' });

  useEffect(() => {
    setFetchError(error);
    setFetching(loading);
  }, [loading, error]);

  useEffect(() => {
    dispatch({
      type: CardsActionTypes.CLEAR_CARDS,
      payload: undefined
    });
    setPage('0');
  }, [facilityId, wardId]);

  useEffect(() => {
    if (loading || error || !rooms || rooms.length === 0) {
      return undefined;
    }

    rooms.forEach(async room => {
      const { residents, id, ward, number: roomNumber } = room;

      if (!id || !ward || !ward.name) {
        return undefined;
      }

      if (residents.length === 0) {
        dispatch({
          type: CardsActionTypes.ADD_CARDS,
          payload: [
            {
              cardId: id,
              cardLink: getLinkForCard(
                id,
                URLQueryValues.ROOM_KEY,
                facilityId,
                wardId
              ),
              cardName: VACANT_ROOM_NAME,
              cardType: URLQueryValues.ROOM_KEY,
              residentGender: undefined,
              residentPhotoUrl: undefined,
              roomId: id,
              roomNumber,
              wardName: ward.name!
            }
          ]
        });
      } else {
        setFetching(true);
        try {
          const promises = residents.map(resident => {
            return getResidentDetails(facilityId, resident.id)();
          });

          const results = await Promise.all(promises);

          const items: Card[] = results.map(res => {
            return {
              cardId: res.id,
              cardLink: getLinkForCard(
                res.id,
                URLQueryValues.RESIDENT_KEY,
                facilityId,
                wardId
              ),
              cardName: res.name,
              cardType: URLQueryValues.RESIDENT_KEY,
              residentGender: res.gender as Gender,
              wardName: ward.name!,
              residentPhotoUrl: res.photoUrl,
              roomId: id,
              roomNumber
            };
          });
          dispatch({
            type: CardsActionTypes.ADD_CARDS,
            payload: items
          });
        } catch (error) {
          setFetchError(error as Error);
          return undefined;
        } finally {
          setFetching(false);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms, error, loading]);

  const handlePagination = () => {
    if (roomsNextPage) {
      setPage(roomsNextPage);
    } else {
      setFetching(false);
    }
  };

  return (
    <CardsContext.Provider
      value={{
        cards: sortByKey(state, 'roomNumber'),
        fetchMoreCards: handlePagination,
        fetching,
        fetchError
      }}
    >
      <CardsDispatch.Provider value={dispatch}>
        {children}
      </CardsDispatch.Provider>
    </CardsContext.Provider>
  );
};

export const useCardsContext = () => {
  const { cards, fetchMoreCards, fetching, fetchError } = useContext<
    ICardsContext
  >(CardsContext);

  if (cards === undefined) {
    throw new Error('useCardsContext must be used within a CardsProvider');
  }

  return {
    cards,
    fetchMoreCards,
    fetching,
    fetchError
  };
};

export const useCardsDispatch = () => {
  const dispatch = useContext<Dispatch<CardsActionType>>(CardsDispatch);

  if (dispatch === undefined) {
    throw new Error('useCardsContext must be used within a CardsProvider');
  }

  return dispatch;
};

export const useCardsProvider = () => {
  const { cards, fetchMoreCards } = useCardsContext();
  const cardsDispatch = useCardsDispatch();

  return {
    cards,
    fetchMoreCards,
    cardsDispatch
  };
};
