import { LoginId } from 'types';

export interface CaregiverProfileDetails {
  id: string;
  name: string;
  role: string;
  relationship: undefined;
  email: string;
  homePhone: string;
  mobilePhone: string;
  type: 'CAREGIVER';
  facilityId: string;
  photoUrl?: string;
  loginId: LoginId;
}

export interface UserProfileDetails {
  id: string;
  name: string;
  role: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  type: 'ADMIN';
  photoUrl?: string;
  loginId: LoginId;
}

export const isCaregiverProfile = (
  user:
    | CaregiverProfileFormData
    | UserProfileFormData
    | CaregiverProfileDetails
    | UserProfileDetails
): user is CaregiverProfileDetails =>
  (user as CaregiverProfileDetails).type === 'CAREGIVER';

export interface CaregiverProfileFormData extends CaregiverProfileDetails {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export interface UserProfileFormData extends UserProfileDetails {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}
