import { axiosWrapper } from 'utils';

export const resetPassword = async (
  passwordResetId: string,
  newPassword: string,
  confirmNewPassword: string
) => {
  const response = await axiosWrapper('/password-reset', 'POST', null, {
    passwordResetId,
    newPassword,
    confirmNewPassword
  });
  return response.status;
};
