import classnames from 'classnames';
import React from 'react';

import { RelatedContactStatuses } from 'consts/relatedContacts.constant';
import { ResidentContact } from 'Settings';

import stylesTable from 'components/Table/Table.module.css';
import fontStyles from 'styles/typography.module.css';

export const ResidentContactTableRow: React.FunctionComponent<{
  values: ResidentContact;
  isSelected: boolean;
  selectContact: (id: string) => void;
}> = ({ values, isSelected, selectContact }) => {
  const { id, name, relationship, phoneNumber, email, status } = values;
  const capitalizedStatus = status.slice(0, 1).toUpperCase() + status.slice(1);

  const handleClick = () => selectContact(id);
  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div className={rowClass} role="row" onClick={handleClick}>
      <div
        className={stylesTable.tableCellBlue}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {name || 'N/A'}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {relationship || 'N/A'}
      </div>
      <div
        className={stylesTable.tableCellBlue}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        {email || 'N/A'}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {phoneNumber || 'N/A'}
      </div>
      <div
        className={classnames(stylesTable.tableCell, {
          [fontStyles.greenFontColor]: status === RelatedContactStatuses.ACTIVE,
          [fontStyles.redFontColor]: status === RelatedContactStatuses.INACTIVE
        })}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        {capitalizedStatus}
      </div>
    </div>
  );
};
