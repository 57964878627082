import React, { createContext, useContext, useReducer } from 'react';

type base64 = string;

interface IAvatarsMap {
  [photoUrl: string]: base64;
}

export enum AvatarsActionTypes {
  ADD_AVATAR = 'ADD_AVATAR'
}

interface AvatarsAction {
  type: AvatarsActionTypes.ADD_AVATAR;
  payload: {
    photoUrl: string;
    avatarData: base64;
  };
}

const InitialState = {} as IAvatarsMap;

const AvatarsContext = createContext<IAvatarsMap>(InitialState);
const AvatarsDispatchContext = createContext<React.Dispatch<AvatarsAction>>(
  () => undefined
);

const avatarsReducer = (state: IAvatarsMap, action: AvatarsAction) => {
  const { type, payload } = action;

  switch (type) {
    case AvatarsActionTypes.ADD_AVATAR:
      return {
        ...state,
        [payload.photoUrl]: payload.avatarData
      };
    default:
      return state;
  }
};

export const AvatarsProvider: (props: {
  children: React.ReactNode;
  initialState?: IAvatarsMap;
}) => React.ReactElement = ({ children, initialState = InitialState }) => {
  const [state, dispatch] = useReducer(avatarsReducer, initialState);

  return (
    <AvatarsContext.Provider value={state}>
      <AvatarsDispatchContext.Provider value={dispatch}>
        {children}
      </AvatarsDispatchContext.Provider>
    </AvatarsContext.Provider>
  );
};

export const useAvatarsState = () => {
  const context = useContext(AvatarsContext);
  if (context === undefined) {
    throw new Error('useAvatarsState must be used within a AvatarsProvider');
  }
  return context;
};
export const useAvatarsDispatch = () => {
  const context = useContext(AvatarsDispatchContext);
  if (context === undefined) {
    throw new Error('useAvatarsDispatch must be used within a AvatarsProvider');
  }
  return context;
};

export const useAvatarsContext = (): {
  avatarsState: IAvatarsMap;
  avatarDispatch: React.Dispatch<AvatarsAction>;
} => {
  return {
    avatarsState: useAvatarsState(),
    avatarDispatch: useAvatarsDispatch()
  };
};
