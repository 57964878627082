import classnames from 'classnames';
import React from 'react';

import styles from './ResidentListHeader.module.css';

export const ResidentListHeader: React.FunctionComponent<{
  scrollPosition: number;
}> = ({ scrollPosition }) => {
  return (
    <header className={styles.header}>
      <span
        className={classnames(styles.title, {
          [styles.withRightBorder]: scrollPosition > 0
        })}
        style={{ left: scrollPosition }}
      >
        Staff Assignments
      </span>
    </header>
  );
};
