import { axiosWrapper } from 'utils';

import { Idn, IdnListResponse } from 'Settings';

export const getPaginatedIdns = (page: number) => async () => {
  const response = await axiosWrapper<
    IdnListResponse | { items: Idn[]; nextPage: string }
  >(`/idns?page=${page}`);
  return response.data;
};
