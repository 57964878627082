import React, { useCallback, useEffect, useState } from 'react';

import { DismissibleError, Modal } from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { editUser, User } from 'Settings';
import { getHeight } from 'utils';

import { EditUserForm } from './EditUserForm';
import { useUserContext } from './user.context';

import styles from './EditUserDetails.module.css';

export const EditUserDetails: React.FunctionComponent<{
  toggle: () => void;
  isOpen: boolean;
}> = ({ toggle, isOpen }) => {
  const [{ userDetails }, dispatch] = useUserContext();
  const [updatedUser, setUpdateUser] = useState<User>(userDetails);
  const modalOffset = getHeight('User Details') + 110;

  const { setAction, loading: onSaveLoading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, onSaveLoading);

  const onUpdate = (updatedValues: User) => {
    setAction(editUser(updatedValues));
    setUpdateUser({ ...updatedValues, password: '' });
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const onCancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    toggle();
  };

  const handleContextChange = useCallback(() => {
    const updates = updatedUser ? updatedUser : ({} as User);
    dispatch({ type: 'UPDATE_USER', payload: updates });
  }, [dispatch, updatedUser]);

  useEffect(() => {
    if (submitted && !onSaveLoading && !error) {
      handleContextChange();
      toggle();
      setSubmitted(false);
    }
  }, [
    error,
    handleContextChange,
    onSaveLoading,
    setSubmitted,
    submitted,
    toggle
  ]);

  return (
    <Modal isShowing={isOpen} toggle={toggle}>
      <div className={styles.modalBackdrop}>
        <div className={styles.modalContent} style={{ marginTop: modalOffset }}>
          <EditUserForm
            onSave={onUpdate}
            onCancel={onCancel}
            user={userDetails}
            onSaveLoading={onSaveLoading}
          >
            {error && (
              <DismissibleError
                name="User"
                visible={isErrorShowing}
                error={error}
                dismiss={dismissError}
              />
            )}
          </EditUserForm>
        </div>
      </div>
    </Modal>
  );
};
