import React from 'react';

import { ReactComponent as CheckIcon } from 'icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'icons/crossmark.svg';

import styles from './ConfirmationMessage.module.css';

export const ConfirmationMessage: React.FunctionComponent<{
  dismiss: () => void;
  visible: boolean;
}> = ({ dismiss, visible }) =>
  visible ? (
    <div className={styles.messageWrapper}>
      <div className={styles.checkIcon}>
        <CheckIcon />
      </div>
      <h4 className={styles.message}>Changes saved successfully!</h4>
      <CrossIcon onClick={dismiss} />
    </div>
  ) : null;
