import moment from 'moment';
import React from 'react';
import useForm from 'react-hook-form';

import { AddReadingForm, ReadingInput } from 'Residents';

interface BodyTemperatureReadingFormData {
  bodyTemperature: string;
}

export const BodyTemperatureReadingForm: React.FunctionComponent<{
  isOpen: boolean;
  onSave: (formData: any) => void;
  onCancel: () => void;
  loading: boolean;
}> = ({ isOpen, onSave, onCancel, loading, children }) => {
  const { register, errors, getValues, triggerValidation } = useForm<
    BodyTemperatureReadingFormData
  >({
    mode: 'onBlur',
    submitFocusError: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const { bodyTemperature } = getValues();
      const measuredAt = moment().toISOString(true);

      onSave({
        bodyTemperature: parseFloat(bodyTemperature),
        measuredAt,
        type: 'BODY_TEMPERATURE'
      });
    }
  };

  return (
    <AddReadingForm
      isOpen={isOpen}
      onSave={handleSubmit}
      onCancel={onCancel}
      title="Add Body Temperature Reading"
      savingDisabled={loading}
    >
      <ReadingInput
        name="bodyTemperature"
        label="body temperature"
        register={register}
        hasError={!!errors.bodyTemperature}
        unit="(°F)"
        validationRules={{
          required: true,
          pattern: /^(?!0\d)(?:\d+|\d{1,3}(?:,\d{3})+)(?:[.]\d{1})?$/
        }}
      />
      {children}
    </AddReadingForm>
  );
};
