import { authenticateUser } from 'Login/actions';
import { roles as allowedRoles } from './roleBasedAccess';

const tokenKey = 'auth-token';

export const setToken = (token: string) => {
  window.localStorage.setItem(tokenKey, token);
};

export const getToken = () => {
  return window.localStorage.getItem(tokenKey);
};

export const login = async (form: { username: string; password: string }) => {
  try {
    const responseToken = await authenticateUser(form.username, form.password);
    if (responseToken) {
      setToken(responseToken);
    }
  } catch (error) {
    throw error;
  }
};

export const logout = () => {
  window.localStorage.removeItem(tokenKey);
};

export const getDecodedToken = (token: any) => {
  const base64Url = token ? token.split('.')[1] : null;

  return base64Url ? JSON.parse(window.atob(base64Url)) : null;
};

export const getUserRoleByToken = (token: any) => {
  const decodedToken = getDecodedToken(token);
  if (!decodedToken) {
    return null;
  }
  const { authorities } = decodedToken;
  const isManager = decodedToken['caregiver-is-manager'];

  const role = authorities[0];
  if (role === 'ROLE_CAREGIVER' && isManager) {
    return 'ROLE_CAREGIVER_MANAGER';
  }
  return role;
};

export const getUserLoginIdByToken = (token: any) => {
  const decodedToken = getDecodedToken(token);
  if (!decodedToken) {
    return null;
  }

  return decodedToken.sub;
};

export const roleHasAccess = (role: any) =>
  !!role && allowedRoles.includes(role);

export const userHasAccess = (token: any) =>
  roleHasAccess(getUserRoleByToken(token));

type enumType = number | string;

export const getValues = <T extends enumType>(
  enumObject: Record<string, T>
): T[] => {
  return Object.values(enumObject);
};
