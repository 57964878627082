import React, { useMemo } from 'react';

import { EditIdnForm } from './EditIdnForm';

import {
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelDetail,
  TextStatus
} from 'components';
import { useFetcher, useModal } from 'hooks';
import { Idn } from 'Settings/types';
import { getHeight } from 'utils';
import { getIdnDetails } from '../actions';

import stylesModal from 'components/Modal/Modal.module.css';
import groupStyles from 'Settings/Settings.module.css';

export const IdnDetails: React.FunctionComponent<{
  idnId: string;
  onIdnChange: (idn: Idn) => void;
}> = ({ idnId, onIdnChange }) => {
  const getIdnDetailsMemo = useMemo(() => getIdnDetails(idnId), [idnId]);

  const { data: idn, error, loading, setRefetch } = useFetcher<Idn>(
    getIdnDetailsMemo,
    {} as Idn
  );

  const { isShowing, toggle } = useModal();

  const onEdit = () => {
    toggle();
  };

  const modalClosed = () => {
    toggle();
    setRefetch();
  };

  return (
    <PanelDetail title="IDN Details" onAction={onEdit} hasError={!!error}>
      {idn && !error && !loading && (
        <>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupHalf}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>IDN Name</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>{idn.name}</div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>IDN Number</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>{idn.number}</div>
            </div>
            <div className={groupStyles.panelGroupStatus}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Status</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                <TextStatus status={idn.isActive} />
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Address</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {idn.address || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>City</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {idn.city || 'N/A'}
              </div>
            </div>

            <div className={groupStyles.panelGroupEighth}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>State</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {idn.state || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Zip</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {idn.zip || 'N/A'}
              </div>
            </div>
            <div className={groupStyles.panelGroupAutoRight}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Residents</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {idn.numberOfResidents || 0}
              </div>
            </div>
          </div>

          <Modal isShowing={isShowing} toggle={toggle}>
            <div className={stylesModal.modalBackdrop}>
              <div className={stylesModal.modalContentRightAlign}>
                <div
                  className={stylesModal.facilityModal}
                  style={{ marginTop: getHeight('IDN Details') }}
                >
                  <EditIdnForm
                    toggle={modalClosed}
                    selectedIdn={idn}
                    onIdnChange={onIdnChange}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      {loading && <LoadingPlaceholder />}
      {error && !loading && <FetchError error={error} />}
    </PanelDetail>
  );
};
