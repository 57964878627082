import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  PanelList,
  Table
} from 'components';
import { useFetcher, useInfiniteScroll } from 'hooks';

import { Device, Facility } from 'Settings/types';

import { getDevicesByQuery, getPaginatedDevices } from '../../actions';

import {
  DEFAULT_SEARCH_TERM,
  DEVICES_LIST_PLACEHOLDER,
  DEVICES_SEARCH_INPUT_NAME,
  DEVICES_TITLE,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH
} from 'Settings/constants';
import { DeviceTableRow } from './DeviceTableRow';

const DeviceListTableColumns: { [key: string]: Column } = {
  name: { name: 'Device Name', align: 'left', cols: 3 },
  type: { name: 'Device Type', align: 'left', cols: 3 },
  isActive: { name: 'Status', align: 'center', cols: 1 }
};

export const DeviceList: React.FunctionComponent<{
  facility: Facility;
  setActiveTab: (tab: string) => void;
  onSelect: (id: string) => void;
  selectedDeviceId: string;
}> = ({ facility, setActiveTab, onSelect, selectedDeviceId }) => {
  const [page, setPage] = useState(0);
  const [searchedDevicesPage, setSearchedDevicesPage] = useState(0);
  const [devices, setDevices] = useState<Device[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getDevicesMemo = useMemo(() => getPaginatedDevices(facility.id, page), [
    facility.id,
    page
  ]);

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getDevicesByQuery(facility.id, searchTerm, searchedDevicesPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedDevicesPage]);

  const {
    data: { items: devicesList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Device[];
    nextPage?: string;
  }>(getDevicesMemo, { items: [] });

  const {
    data: { items: searchedDevices, nextPage: nextPageForSearchedDevices },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{ items: Device[]; nextPage?: string }>(memoSearchAction, {
    items: []
  });

  useEffect(() => {
    if (devicesList.length !== 0) {
      setDevices(currentDevices => [...currentDevices, ...devicesList]);
    }
  }, [devicesList]);

  useEffect(() => {
    if (searchError) {
      return setDevices([]);
    }
    if (searchedDevicesPage > 0) {
      setDevices(prevState => [...prevState, ...searchedDevices]);
    } else {
      setDevices(searchedDevices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedDevices, searchError]);

  const scrollAction = () => {
    if (
      !loading &&
      isFetching &&
      devicesList.length &&
      nextPage &&
      !isSearching
    ) {
      setPage(parseInt(nextPage, 10));
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedDevices.length &&
      nextPageForSearchedDevices &&
      isSearching
    ) {
      setSearchedDevicesPage(parseInt(nextPageForSearchedDevices, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedDevicesPage(0);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setDevices([]);
      setPage(0);
      setRefetch();
    }
  };

  const render = (rowValues: any) => (
    <DeviceTableRow
      key={rowValues.id}
      facilityType={facility.type}
      values={rowValues}
      isSelected={selectedDeviceId === rowValues.id}
      setActiveTab={setActiveTab}
      onSelect={onSelect}
      columns={DeviceListTableColumns}
    />
  );

  const isDataAvailable = devices.length > 0;

  return (
    <PanelList
      title={DEVICES_TITLE}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={DEVICES_SEARCH_INPUT_NAME}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<Device>
          columns={Object.values(DeviceListTableColumns)}
          rows={devices}
          placeholder={DEVICES_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}
    </PanelList>
  );
};
