import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';

import { residentLabels } from 'consts';
import { useFetcher } from 'hooks';
import { Facility } from 'Settings';

import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { getDevicesLocation } from './actions';
import { DeviceLocation } from './types';

type Action =
  | {
      type: 'CHANGE_FACILITY';
      payload: Facility;
    }
  | { type: 'CHANGE_WARD'; payload: string }
  | { type: 'SET_FILTERS_ERROR'; payload: Error | null }
  | { type: 'SET_FILTERS_LOADING'; payload: boolean }
  | { type: 'SET_DEVICES_LOCATION'; payload: DeviceLocation[] }
  | { type: 'UPDATE_DEVICE_LOCATION'; payload: DeviceLocation }
  | { type: 'FETCH_DEVICES_LOCATION' }
  | { type: 'FAIL_RTLS_FETCH'; payload: Error }
  | { type: 'FINISH_RTLS_FETCH'; payload: DeviceLocation[] }
  | { type: 'SET_FACILITIES'; payload: Facility[] }
  | { type: 'SET_WARDS_DETAILS'; payload: MappedWard | undefined };

interface State {
  facility: Facility;
  ward: string;
  filtersError: Error | null;
  filtersLoading: boolean;
  devicesLocation: DeviceLocation[];
  rtlsError: Error | null;
  rtlsLoading: boolean;
  facilities: Facility[];
  selectedWardDetails: MappedWard | undefined;
}
type Dispatch = (action: Action) => void;

const LocationStateContext = createContext<State | undefined>(undefined);
const LocationDispatchContext = createContext<Dispatch | undefined>(undefined);

const locationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'CHANGE_FACILITY': {
      return {
        ...state,
        facility: action.payload,
        ward: ''
      };
    }
    case 'CHANGE_WARD': {
      return {
        ...state,
        ward: action.payload
      };
    }
    case 'SET_FILTERS_ERROR': {
      return {
        ...state,
        filtersError: action.payload
      };
    }
    case 'SET_FILTERS_LOADING': {
      return {
        ...state,
        filtersLoading: action.payload
      };
    }
    case 'SET_DEVICES_LOCATION': {
      return {
        ...state,
        devicesLocation: [...action.payload]
      };
    }
    case 'FETCH_DEVICES_LOCATION': {
      return {
        ...state,
        devicesLocation: [] as DeviceLocation[],
        rtlsLoading: true,
        rtlsError: null
      };
    }
    case 'UPDATE_DEVICE_LOCATION': {
      return {
        ...state,
        devicesLocation: state.devicesLocation.map(location =>
          location.id === action.payload.id ? { ...action.payload } : location
        )
      };
    }
    case 'FINISH_RTLS_FETCH': {
      return {
        ...state,
        rtlsLoading: false,
        rtlsError: null,
        devicesLocation: [...action.payload]
      };
    }
    case 'FAIL_RTLS_FETCH': {
      return {
        ...state,
        rtlsLoading: false,
        rtlsError: action.payload
      };
    }
    case 'SET_FACILITIES': {
      return {
        ...state,
        facilities: action.payload
      };
    }
    case 'SET_WARDS_DETAILS': {
      return {
        ...state,
        selectedWardDetails: action.payload
      };
    }
  }
};

export const LocationProvider: (props: {
  children: React.ReactNode;
  initialState?: State;
}) => any = ({
  children,
  initialState = {
    facility: {} as Facility,
    ward: '',
    filtersError: null,
    filtersLoading: true,
    devicesLocation: [],
    rtlsLoading: true,
    rtlsError: null,
    facilities: [],
    selectedWardDetails: undefined
  }
}) => {
  const [state, dispatch] = useReducer(locationReducer, initialState);

  const {
    facility: { id: facilityId },
    ward: wardId
  } = state;

  const getLocationAction = useMemo(() => {
    if (facilityId && wardId) {
      dispatch({ type: 'FETCH_DEVICES_LOCATION' });
      return getDevicesLocation(facilityId, wardId);
    }
  }, [facilityId, wardId]);

  const { data, error, loading } = useFetcher<DeviceLocation[]>(
    getLocationAction,
    []
  );

  useEffect(() => {
    if (error) {
      dispatch({ type: 'FAIL_RTLS_FETCH', payload: error });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (loading || error) {
      return;
    }
    dispatch({ type: 'FINISH_RTLS_FETCH', payload: data });
  }, [data, dispatch, error, loading]);

  return (
    <LocationStateContext.Provider value={state}>
      <LocationDispatchContext.Provider value={dispatch}>
        {children}
      </LocationDispatchContext.Provider>
    </LocationStateContext.Provider>
  );
};

export const useLocationState = () => {
  const context = useContext(LocationStateContext);
  if (context === undefined) {
    throw new Error('useLocationState must be used within a LocationProvider');
  }
  return context;
};

export const useLocationDispatch = () => {
  const context = useContext(LocationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useLocationDispatch must be used within a LocationProvider'
    );
  }
  return context;
};

export const useLabelState = () => {
  const context = useContext(LocationStateContext);
  if (context === undefined) {
    throw new Error('useLocationState must be used within a LocationProvider');
  }

  return residentLabels[context.facility.type] || 'Resident';
};

export const useLocationContext = (): {
  location: State;
  dispatch: Dispatch;
  label: string;
} => {
  return {
    location: useLocationState(),
    dispatch: useLocationDispatch(),
    label: useLabelState()
  };
};
