import { RelatedContactStatuses } from 'consts/relatedContacts.constant';
import { getResidentContacts, Resident } from 'Settings';

export const getRelatedContacts = async (residentsByWard: Resident[]) => {
  const promises = residentsByWard.map(({ id: residentId }) => {
    return getResidentContacts(residentId);
  });

  const contacts = await Promise.all(promises).then(arrRawContacs => {
    const items = arrRawContacs.map(response => response.items);
    return items
      .flat()
      .filter(contact => contact.status !== RelatedContactStatuses.PENDING);
  });

  return contacts;
};
