import classnames from 'classnames';
import React from 'react';

import styles from 'styles/layout.module.css';

export const TwoColumnLayout: React.FunctionComponent<{
  left: React.ReactNode;
  right: React.ReactNode;
  customStyles?: string;
  customStylesLeft?: string;
  customStylesRight?: string;
}> = ({ left, right, customStyles, customStylesLeft, customStylesRight }) => (
  <div className={classnames(styles.twoColumns, customStyles)}>
    <div className={classnames(styles.column, customStylesLeft)}>{left}</div>
    <div className={classnames(styles.column, customStylesRight)}>{right}</div>
  </div>
);
