import React, { Component } from 'react';

import styles from './Tabs.module.css';

interface TabProps {
  activeTab: string;
  label: string;
  onClick: (label: string) => void;
}
export class VerticalTab extends Component<TabProps> {
  public onClick = () => {
    const { label, onClick }: TabProps = this.props;
    onClick(label);
  };

  public render() {
    const {
      onClick,
      props: { activeTab, label }
    } = this;

    if (activeTab === label) {
      return (
        <li className={styles.verticalTabListItemActive} onClick={onClick}>
          <span>{label}</span>
        </li>
      );
    }

    return (
      <li className={styles.verticalTabListItem} onClick={onClick}>
        <span>{label}</span>
      </li>
    );
  }
}
