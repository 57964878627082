import { IMissedCallCount } from 'Communication/types';
import { ChatId } from 'types';

export const getCallCount = (
  activeChatId: ChatId | undefined,
  missedCalls: IMissedCallCount[]
) => {
  let callCount = {};

  const currentCount = missedCalls.find(
    call => call.chatRoomId === activeChatId
  );

  if (currentCount) {
    callCount = {
      audioCount: currentCount.audioMissedCallCount,
      videoCount: currentCount.videoMissedCallCount
    };
  }

  return callCount;
};
