import { DeviceLocation } from 'Location';
import { getResidentDetails, Resident } from 'Settings';
import { MappedServices } from 'Settings/actions/wardsMapping.utils';
import { UNATTENDED_ALARM_TITLE } from 'Settings/constants';
import { RtlsUpdate } from 'Socket';

import {
  AlarmTypes,
  ColorCodes,
  Notification,
  NotificationDetail
} from './types';

export const loadDetailedCaregiverAlarm = (
  alarm: Notification,
  devicesLocation: DeviceLocation[]
) => {
  const { personOfInterest, roomView, ...initialAlarmData } = alarm;
  const caregiverDetails = {
    id: alarm.caregiverView?.id,
    name: alarm.caregiverView?.name
  };

  const location = devicesLocation.find(
    (loc: any) => loc.caregiver && loc.caregiver.id === personOfInterest
  );

  const roomDetails = {
    id: location && location.room ? location.room.id : alarm.roomView.id,
    number:
      location && location.room ? location.room.number : alarm.roomView.number,
    ward:
      location && location.room
        ? { ...location.room.ward }
        : { ...alarm.wardView }
  };

  return {
    ...initialAlarmData,
    personOfInterest: {
      id: caregiverDetails ? caregiverDetails.id : '',
      name: caregiverDetails ? caregiverDetails.name : ''
    },
    room: {
      id: roomDetails.id,
      number: roomDetails.number
    },
    wardView: roomDetails.ward
  } as NotificationDetail;
};

export const byISOdate = (a: NotificationDetail, b: NotificationDetail) => {
  if (a.created && b.created) {
    return a.created < b.created ? -1 : a.created > b.created ? 1 : 0;
  }

  return 0;
};

const isPanicAlarm = (
  notification: NotificationDetail,
  availableServices: MappedServices
) => {
  const { type } = notification;

  if (
    type === AlarmTypes.CAREGIVER_ALARM &&
    availableServices.rtls.features.rtlsStaff.isEnabled &&
    availableServices.alarmsNotifications.features.anStaff.isEnabled
  ) {
    return true;
  }

  if (
    type === AlarmTypes.RESIDENT_BADGE_SOS_ALARM &&
    availableServices.rtls.features.rtlsPatient.isEnabled &&
    availableServices.alarmsNotifications.features.anPatient.isEnabled
  ) {
    return true;
  }

  return false;
};

const isHighPriorityAlarm = (
  notification: NotificationDetail,
  availableServices: MappedServices
) => {
  const { type, colorCode, title } = notification;

  if (
    colorCode === ColorCodes.RED &&
    type === AlarmTypes.RESIDENT_ALARM &&
    title !== UNATTENDED_ALARM_TITLE &&
    availableServices.alarmsNotifications.features.anResident.isEnabled
  ) {
    return true;
  }

  if (
    colorCode === ColorCodes.RED &&
    type === AlarmTypes.RESIDENT_ALARM &&
    title === UNATTENDED_ALARM_TITLE &&
    availableServices.alarmsNotifications.features.anRounding.isEnabled &&
    availableServices.rtls.features.rtlsStaff.isEnabled
  ) {
    return true;
  }

  if (
    colorCode === ColorCodes.RED &&
    type === AlarmTypes.ROOM_ALARM &&
    availableServices.alarmsNotifications.features.anRoom.isEnabled
  ) {
    return true;
  }

  return false;
};

const isWellBeingAlarm = (
  notification: NotificationDetail,
  availableServices: MappedServices
) => {
  const { type } = notification;

  if (
    type === AlarmTypes.RESIDENT_WELL_BEING_ALARM &&
    availableServices.alarmsNotifications.features.anResidentWellBeing.isEnabled
  ) {
    return true;
  }

  return false;
};

const isMediumPriorityAlarm = (
  notification: NotificationDetail,
  availableServices: MappedServices
) => {
  const { type, colorCode, title } = notification;

  if (
    type === AlarmTypes.RESIDENT_ALARM &&
    title !== UNATTENDED_ALARM_TITLE &&
    (colorCode === ColorCodes.ORANGE || colorCode === ColorCodes.DARK_ORANGE) &&
    availableServices.alarmsNotifications.features.anResident.isEnabled
  ) {
    return true;
  }

  if (
    type === AlarmTypes.RESIDENT_ALARM &&
    (colorCode === ColorCodes.ORANGE || colorCode === ColorCodes.DARK_ORANGE) &&
    title === UNATTENDED_ALARM_TITLE &&
    availableServices.alarmsNotifications.features.anRounding.isEnabled &&
    availableServices.rtls.features.rtlsStaff.isEnabled
  ) {
    return true;
  }

  if (
    type === AlarmTypes.ROOM_ALARM &&
    (colorCode === ColorCodes.ORANGE || colorCode === ColorCodes.DARK_ORANGE) &&
    availableServices.alarmsNotifications.features.anRoom.isEnabled
  ) {
    return true;
  }

  return false;
};

const isOtherAlarm = (
  notification: NotificationDetail,
  availableServices: MappedServices
) => {
  const { type, colorCode, title } = notification;
  const { alarmsNotifications, rtls } = availableServices;

  if (
    (colorCode === ColorCodes.YELLOW || colorCode === ColorCodes.GREEN) &&
    type === AlarmTypes.RESIDENT_ALARM &&
    alarmsNotifications.features.anResident.isEnabled &&
    title !== UNATTENDED_ALARM_TITLE
  ) {
    return true;
  }

  if (
    (colorCode === ColorCodes.YELLOW || colorCode === ColorCodes.GREEN) &&
    type === AlarmTypes.RESIDENT_ALARM &&
    title === UNATTENDED_ALARM_TITLE &&
    alarmsNotifications.features.anRounding.isEnabled &&
    rtls.features.rtlsStaff.isEnabled
  ) {
    return true;
  }

  if (
    (colorCode === ColorCodes.YELLOW || colorCode === ColorCodes.GREEN) &&
    type === AlarmTypes.ROOM_ALARM &&
    alarmsNotifications.features.anRoom.isEnabled
  ) {
    return true;
  }

  return false;
};

export const sortNotifications = (
  notifications: NotificationDetail[],
  availableServices: MappedServices
) => {
  const sortedByOldestDate = [...notifications].sort(byISOdate);

  const panicAlarms: NotificationDetail[] = []; // type = CAREGIVER_ALARM and RESIDENT_BADGE_SOS_ALARM
  const highPriorityAlarms: NotificationDetail[] = []; // type = RESIDENT_ALARM & code = RED
  const wellBeingAlarms: NotificationDetail[] = []; // type = RESIDENT_WELL_BEING_ALARM
  const mediumPriority: NotificationDetail[] = []; // code = ORANGE and DARK_ORANGE
  const otherAlarms: NotificationDetail[] = []; // other threshold anomalies

  sortedByOldestDate.forEach(notification => {
    if (isPanicAlarm(notification, availableServices)) {
      return panicAlarms.push(notification);
    }
    if (isHighPriorityAlarm(notification, availableServices)) {
      return highPriorityAlarms.push(notification);
    }
    if (isWellBeingAlarm(notification, availableServices)) {
      return wellBeingAlarms.push(notification);
    }
    if (isMediumPriorityAlarm(notification, availableServices)) {
      return mediumPriority.push(notification);
    }
    if (isOtherAlarm(notification, availableServices)) {
      return otherAlarms.push(notification);
    }
  });

  return [
    ...panicAlarms,
    ...highPriorityAlarms,
    ...wellBeingAlarms,
    ...mediumPriority,
    ...otherAlarms
  ];
};

export const getResidentNotifications = async (
  wardNotifications: Notification[],
  residentsByWard: Resident[],
  facilityId: string
) =>
  Promise.all(
    wardNotifications
      .filter(
        ({ type }) =>
          type === AlarmTypes.RESIDENT_ALARM ||
          type === AlarmTypes.RESIDENT_WELL_BEING_ALARM
      ) // contains notifications like threshold anomalies & well being check requests
      .map(async notification => {
        const { personOfInterest, roomView } = notification;

        let resident = {
          room: { id: '', number: '', ward: { id: '', name: '' } },
          name: '',
          id: ''
        } as Resident;

        const residentFromWard = residentsByWard.find(
          ({ id }) => id === personOfInterest
        );

        if (residentFromWard) {
          resident = residentFromWard;
        } else if (facilityId) {
          const residentDetails = await getResidentDetails(
            facilityId,
            personOfInterest!
          )();
          if (residentDetails) {
            resident = residentDetails;
          }
        }

        const { room, name, id: residentId } = resident;

        return {
          ...notification,
          personOfInterest: { id: residentId, name },
          room: {
            id: room ? room.id : roomView.id,
            number: room ? room.number : roomView.number
          }
        } as NotificationDetail;
      })
  );

export const loadDetailedResidentAlarm = (
  alarm: Notification,
  deviceLocation: DeviceLocation[]
) => {
  let roomDetails;
  const { personOfInterest, roomView, ...initialAlarmData } = alarm;

  const residentDetails = {
    id: alarm.residentView?.id,
    name: alarm.residentView?.name
  };

  if (alarm.type === AlarmTypes.RESIDENT_BADGE_SOS_ALARM) {
    const location = deviceLocation.find(
      (loc: any) => loc.resident && loc.resident.id === personOfInterest
    );

    roomDetails = {
      id: location && location.room ? location.room.id : alarm.roomView.id,
      number:
        location && location.room
          ? location.room.number
          : alarm.roomView.number,
      ward:
        location && location.room
          ? { ...location.room.ward }
          : { ...alarm.wardView }
    };
  } else {
    roomDetails = {
      id: alarm.roomView.id,
      number: alarm.roomView.number,
      ward: { ...alarm.roomView.ward }
    };
  }

  return {
    ...initialAlarmData,
    personOfInterest: {
      id: residentDetails ? residentDetails.id : '',
      name: residentDetails ? residentDetails.name : ''
    },
    room: {
      id: roomDetails.id,
      number: roomDetails.number
    },
    wardView: roomDetails.ward
  } as NotificationDetail;
};

export const loadDetailedRoomAlarm = (
  alarm: Notification
): NotificationDetail => {
  return {
    colorCode: alarm.colorCode,
    created: alarm.created,
    id: alarm.id,
    personOfInterest: undefined,
    room: {
      id: alarm.roomView.id!,
      number: alarm.roomView.number
    },
    roomView: {
      devices: alarm.roomView.devices,
      facilityId: alarm.roomView.facilityId,
      number: alarm.roomView.number,
      residents: alarm.roomView.residents,
      id: alarm.roomView.id!,
      ward: alarm.roomView.ward
    },
    title: alarm.title,
    type: AlarmTypes.ROOM_ALARM,
    wardView: {
      facilityId: alarm.wardView.facilityId,
      isActive: alarm.wardView.isActive,
      name: alarm.wardView.name,
      id: alarm.wardView.id!,
      numberOfCaregivers: alarm.wardView.numberOfCaregivers,
      numberOfDevices: alarm.wardView.numberOfDevices,
      numberOfResidents: alarm.wardView.numberOfResidents,
      numberOfRooms: alarm.wardView.numberOfRooms
    },
    alarmVisibility: alarm.alarmVisibility,
    caregiverView: alarm.caregiverView,
    residentView: alarm.residentView
  };
};

export const updateAlarmFromRtlsUpdate = (
  notification: NotificationDetail,
  update: RtlsUpdate
): NotificationDetail => {
  return {
    ...notification,
    room: {
      id: update!.room!.id as string,
      number: update!.room!.number as string
    },
    wardView: {
      ...notification.wardView,
      name: update!.room!.ward!.name as string
    }
  };
};
