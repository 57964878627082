import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  DismissibleError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  Select,
  StatusDropdown
} from 'components';
import { states } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { Idn } from 'Settings/types';
import { getFormValidationErrors } from 'utils';
import { removeSpaces } from 'utils/stringManipulation';

import { editIdn } from '../actions';

import groupStyles from 'Settings/Settings.module.css';
import formGenericStyles from 'styles/form.module.css';

interface EditIdnFormData {
  address: string;
  city: string;
  name: string;
  state: string;
  isActive: boolean;
  zip: string;
}

export const EditIdnForm: React.FunctionComponent<{
  toggle: () => void;
  selectedIdn: Idn;
  onIdnChange: (idn: Idn) => void;
}> = ({ toggle, selectedIdn, onIdnChange }) => {
  const [modifiedIdn, setModifiedIdn] = useState(selectedIdn);
  const [validationErrors, setValidationErrors] = useState<any[] | null>();

  const {
    register,
    getValues,
    errors,
    setValue,
    watch,
    triggerValidation
  } = useForm<EditIdnFormData>({
    mode: 'onBlur',
    defaultValues: {
      name: selectedIdn.name,
      address: selectedIdn.address || '',
      city: selectedIdn.city || '',
      state: selectedIdn.state || '',
      zip: selectedIdn.zip || '',
      isActive: selectedIdn.isActive
    },
    submitFocusError: false
  });
  const { setAction, loading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, loading);

  const saveIdn = (idn: any) => {
    setModifiedIdn(idn);
    setAction(editIdn(idn));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const formData = removeSpaces(getValues(), ['zip']);
      saveIdn({ ...selectedIdn, ...formData });
    }
  };

  if (submitted && !loading && !error) {
    onIdnChange(modifiedIdn);
    toggle();
  }

  const stateOptions = states.map(state => ({
    value: state.value,
    label: state.value
  }));

  useEffect(() => {
    const formValidationErrors = getFormValidationErrors(errors);
    setValidationErrors(formValidationErrors);
  }, [errors]);

  return (
    <>
      <FormHeader
        title="Edit IDN Details"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={loading}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={formGenericStyles.formRow}>
            <div className={formGenericStyles.groupHalf}>
              <div className={formGenericStyles.inputGroup}>
                <Input
                  name="name"
                  label="IDN Name"
                  register={register}
                  validationRules={{ required: true }}
                  hasError={!!errors.name}
                  testId="fi-name"
                />
              </div>
            </div>
            <div className={groupStyles.panelGroupQuarterForm}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>IDN Number</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {selectedIdn.number}
              </div>
            </div>
            <div className={formGenericStyles.selectGroupStatus}>
              <StatusDropdown
                register={register}
                onChange={setValue}
                value={watch('isActive')}
                hasError={!!errors.isActive}
              />
            </div>
          </div>
          <div className={formGenericStyles.formRow}>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="address"
                label="Address"
                register={register}
                hasError={!!errors.address}
              />
            </div>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="city"
                label="City"
                register={register}
                hasError={!!errors.city}
              />
            </div>
            <div className={formGenericStyles.selectGroupEighth}>
              <Select
                name="state"
                label="State"
                options={stateOptions}
                hasError={!!errors.state}
                register={register}
                onChange={setValue}
                value={watch('state')}
              />
            </div>
            <div className={formGenericStyles.inputGroupQuarter}>
              <Input
                name="zip"
                label="Zip"
                register={register}
                hasError={!!errors.zip}
              />
            </div>
            <div className={groupStyles.panelGroupAutoRight}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Residents</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <div className={groupStyles.panelDetailSpan}>
                {selectedIdn.numberOfResidents || 0}
              </div>
            </div>
          </div>

          <DismissibleError
            name="IDN"
            visible={isErrorShowing}
            error={error}
            dismiss={dismissError}
          />
        </form>
      )}
      {validationErrors && (
        <div className={formGenericStyles.errorsWrapper}>
          {validationErrors}
        </div>
      )}
    </>
  );
};
