import React from 'react';

import { BackButton, RoundSearchButton } from 'components';
import { useLabelState, useLocationState } from 'Location';

import style from './ResidentListHeader.module.css';

export const ResidentListHeader: React.FunctionComponent<{
  toggleSearch: () => void;
}> = ({ toggleSearch }) => {
  const label = useLabelState();
  const title = `${label} List`;

  const {
    facility: { id: facilityId },
    ward: wardId
  } = useLocationState();

  return (
    <header className={style.header}>
      <BackButton
        title={title}
        link={`/Residents?facility=${facilityId}&ward=${wardId}`}
      />
      <RoundSearchButton onClick={toggleSearch} testId="search-test-button" />
    </header>
  );
};
