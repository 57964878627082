import React from 'react';

import { ProfilePlaceholder } from 'components';
import { VACANT_ROOM_NAME } from 'consts';

import styles from './ProfileComponent.module.css';

export const ProfileComponent: React.FunctionComponent<{
  name: string;
  label: string;
  style: any;
  type?: string;
  photoUrl?: string;
}> = ({ name, label, style, type, photoUrl }) => {
  return (
    <div className={style}>
      <ProfilePlaceholder name={name} type={type} photoUrl={photoUrl} />
      <div className={styles.info}>
        <span className={styles.label}>{`${label} Name`}</span>
        <span className={styles.name}>{name || VACANT_ROOM_NAME}</span>
      </div>
    </div>
  );
};
