import { CaregiverProfileFormData } from 'UserProfile';
import { axiosWrapper } from 'utils';

export const editCaregiverAccount = (
  facilityId: string,
  caregiver: CaregiverProfileFormData
) => async () => {
  const url = `/facilities/${facilityId}/caregivers/${caregiver.id}`;
  const response = await axiosWrapper(url, 'PATCH', null, caregiver);
  return response.data;
};
