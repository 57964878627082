import moment from 'moment';

import { emptyWeek, FoodIntake } from 'Residents';

const convertReadings = (day: string, readings: FoodIntake[]) => {
  const foodsReadings = readings.find(
    entry => moment(entry.timestamp).format('ddd') === day
  );

  const foods = Number(foodsReadings?.value.count);

  return {
    foods: Number.isNaN(foods) ? 0 : foods,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toWeekData = (data: FoodIntake[]) =>
  emptyWeek.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
