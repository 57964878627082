import { RoomDevice } from 'Settings/types';
import { removeExtraSpaces } from 'utils';

export const createRoomDTO = (formValues: any, facilityId: string) => {
  const {
    ward,
    residents,
    // tslint:disable-next-line variable-name
    number,
    ...addedDevices
  } = formValues;

  const devices: RoomDevice[] = Object.values(addedDevices).filter(
    value => value !== '' && value !== undefined
  ) as RoomDevice[];

  return {
    number: removeExtraSpaces(number),
    residents: residents || [],
    devices,
    ward,
    facilityId
  };
};
