export const usTimezones = [
  'America/Adak',
  'America/Anchorage',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/New_York',
  'America/Phoenix',
  'America/Puerto_Rico',
  'Pacific/Honolulu'
];
