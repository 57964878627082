import { StaffMember, StatusType, StatusTypes } from 'Caregivers';
import { DeviceLocation } from 'Location';
import { Caregiver } from 'Settings';
import { sortByKey } from 'utils';

export const getStaffMembers = (
  caregiversByWard: Caregiver[],
  devicesLocation: DeviceLocation[],
  caregiversStatus: { [caregiverId: string]: StatusType }
) => {
  const availableStaff: StaffMember[] = [];
  const unavailableStaff: StaffMember[] = [];

  caregiversByWard
    .filter(({ isActive }) => isActive)
    .forEach((caregiver: Caregiver) => {
      const location = devicesLocation.find(
        entry =>
          entry.caregiver && caregiver.id === entry.caregiver.id && entry.room
      );
      const status: StatusType =
        caregiversStatus[caregiver.id] || StatusTypes.OFF_DUTY;

      const caregiverData = {
        id: caregiver.id,
        name: caregiver.name,
        role: caregiver.role,
        relationship: undefined,
        status,
        location: location && {
          room: location.room.number,
          ward: location.room.ward.name
        },
        loginId: caregiver.loginId,
        photoUrl: caregiver.photoUrl
      };

      if (status === StatusTypes.OFF_DUTY) {
        unavailableStaff.push(caregiverData);
      } else {
        availableStaff.push(caregiverData);
      }
    });

  return [
    ...sortByKey(availableStaff, 'name'),
    ...sortByKey(unavailableStaff, 'name')
  ];
};
