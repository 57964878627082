import { Resident, ResidentListResponse } from 'Settings';
import { axiosWrapper } from 'utils';

export const searchResidentsByName = (
  searchValue: string,
  page: number
) => async () => {
  const initialUrl = `/residents?residentName=${searchValue}&size=30`;
  const url = page ? `${initialUrl}&page=${page}` : initialUrl;
  const response = await axiosWrapper<
    ResidentListResponse | { items: Resident[]; nextPage: string }
  >(url);

  return response.data;
};
