import { Ward } from 'Settings';
import {
  IToggleFeature,
  MappedServices,
  MappedWard
} from 'Settings/actions/wardsMapping.utils';

export const ServiceNameMappings = {
  rtls: 'Real-Time Location System',
  alarmsNotifications: 'Alarms & Notifications',
  patientResidentActivityInformation: (label: string) =>
    `${label} Activity Information`,
  secureCommunication: 'Secure Communication',
  patientResidentVitals: (label: string) => `${label} Vitals`,
  patientResidentManagement: (label: string) => `${label} Management`,
  relatedContacts: 'Related Contacts'
};

export const RtlsFeatures = {
  rtlsStaff: 'Staff Real-Time Location Information',
  rtlsPatient: (label: string) => `${label} Real-Time Location Information`,
  rtlsAsset: 'Asset Real-Time Location Information'
};

export const AlarmsFeatures = {
  anStaff: 'Staff Duress Notifications',
  anPatient: (label: string) => `${label} Duress Notifications`,
  anRounding: 'Rounding Alarms Notifications',
  anRoom: 'Room Alarms Notifications',
  anResident: (label: string) => `${label} Alarms Notifications`,
  anResidentWellBeing: (label: string) =>
    `${label} Well Being Check Alarms Notifications`,
  anMultipleSOSAlarms: 'Multiple SOS Alarms Notifications'
};

export const PatientsActivityFeatures = {
  praiStaffVisits: 'Staff Visits',
  praiPatientLocation: (label: string) => `${label} Location`,
  praiSteps: 'Steps',
  praiSittingHours: 'Sitting Hours',
  praiSleepingHours: 'Sleeping Hours',
  praiBath: 'Bath',
  praiFluidIntake: 'Fluid Intake',
  praiFluidOutput: 'Fluid Output',
  praiFoodIntake: 'Food Intake'
};

export const ResidentManagementFeatures = {
  prmRelatedContact: 'Related Contacts'
};

export const ResidentVitalsFeatures = {
  prvBloodPressure: 'Blood Pressure',
  prvBodyTemperature: 'Body Temperature',
  prvHeartRate: 'Heart Rate',
  prvBodyWeight: 'Body Weight',
  prvBloodSugar: 'Blood Sugar',
  prvOxygenSaturation: 'Oxygen Saturation',
  prvPainLevel: 'Pain Level'
};

export const CommunicationFeatures = {
  scMessaging: 'Secure Messaging',
  scVoice: 'Secure Voice',
  scVideo: 'Secure Video',
  scAdhoc: 'Group Messaging, Voice & Video',
  scCareTeam: (label: string) => `${label}-Centric Group Messaging`,
  scRelatedContact: 'Related Contacts Messaging',
  scSipClient: 'SIP Client Mobile App'
};

export const RelatedContactFeatures = {
  rcSecureMessaging: 'Secure Messaging',
  rcSecureVoice: 'Secure Voice',
  rcSecureVideo: 'Secure Video',
  rcCareTeam: (label: string) => `${label}-Centric Group Messaging`,
  rcStaffAssignments: 'Staff Assignments',
  rcResidentAlarmsNotifications: (label: string) =>
    `${label} Alarms Notifications`,
  rcBloodPressure: 'Blood Pressure',
  rcBodyTemperature: 'Body Temperature',
  rcHeartRate: 'Heart Rate',
  rcBodyWeight: 'Body Weight',
  rcBloodSugar: 'Blood Sugar',
  rcOxygenSaturation: 'Oxygen Saturation',
  rcPainLevel: 'Pain Level',
  rcPatientLocation: (label: string) => `${label} Location`,
  rcSteps: 'Steps',
  rcSittingHours: 'Sitting Hours',
  rcSleepingHours: 'Sleeping Hours',
  rcBath: 'Bath',
  rcStaffVisits: 'Staff Visits',
  rcFluidIntake: 'Fluid Intake',
  rcFluidOutput: 'Fluid Output',
  rcFoodIntake: 'Food Intake'
};

export type FormatLabel = (label: string) => string;

export type FeatureNameMappings =
  | typeof RtlsFeatures
  | typeof AlarmsFeatures
  | typeof CommunicationFeatures
  | typeof RelatedContactFeatures
  | typeof ResidentVitalsFeatures
  | typeof PatientsActivityFeatures;

export const mapWard = (item: Ward) => {
  const {
    facilityId,
    isActive,
    name,
    toggleFeature,
    alarmColorCode,
    id,
    numberOfCaregivers,
    numberOfDevices,
    numberOfResidents,
    numberOfRooms,
    unattendedResidentAlarmMinutes,
    unattendedResidentAlarmTitle
  } = item;
  const services = getMappedServices(toggleFeature);
  return {
    id,
    facilityId,
    isActive,
    name,
    alarmColorCode,
    numberOfCaregivers,
    numberOfDevices,
    numberOfResidents,
    numberOfRooms,
    unattendedResidentAlarmMinutes,
    unattendedResidentAlarmTitle,
    services
  } as MappedWard;
};

export const getMappedServices = (services: IToggleFeature): MappedServices => {
  const serviceKeys = Object.keys(services);

  const serviceList = serviceKeys.reduce((acc, key) => {
    if (typeof services[key] === 'string') {
      return acc;
    }
    let featureList: {
      [feature: string]: {
        id: keyof FeatureNameMappings;
        name: FeatureNameMappings;
        isEnabled: boolean;
      };
    } = {};

    Object.keys(services[key]).forEach(featureKey => {
      featureList[featureKey] = {
        id: featureKey as keyof FeatureNameMappings,
        name:
          RtlsFeatures[featureKey as keyof FeatureNameMappings] ||
          AlarmsFeatures[featureKey as keyof FeatureNameMappings] ||
          ResidentManagementFeatures[featureKey as keyof FeatureNameMappings] ||
          CommunicationFeatures[featureKey as keyof FeatureNameMappings] ||
          ResidentVitalsFeatures[featureKey as keyof FeatureNameMappings] ||
          RelatedContactFeatures[featureKey as keyof FeatureNameMappings] ||
          PatientsActivityFeatures[featureKey as keyof FeatureNameMappings],
        isEnabled: (services[key] as {
          [feature: string]: boolean;
        })[featureKey as string]
      };
    });

    return {
      ...acc,
      [key]: {
        id: key,
        name: ServiceNameMappings[key as keyof typeof ServiceNameMappings],
        features: featureList
      }
    };
  }, {});

  return serviceList as MappedServices;
};

export const unmapServices = (
  mappedServices: MappedServices
): IToggleFeature => {
  const mappedKeys = Object.keys(mappedServices);

  const services: IToggleFeature = mappedKeys.reduce((acc, key) => {
    const { features } = mappedServices[key as keyof MappedServices];
    const featureKeys = Object.keys(features);

    acc[key as keyof IToggleFeature] = {};

    featureKeys.forEach(featureKey => {
      acc[key][featureKey] = (features[
        featureKey as keyof FeatureNameMappings
      ] as {
        id: keyof FeatureNameMappings;
        name: string;
        isEnabled: boolean;
      }).isEnabled;
    });

    return acc;
  }, {} as IToggleFeature);

  return services;
};
