import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react';

import { useAuthState } from 'Auth';
import { StatusTypes, useCaregiversStatus } from 'Caregivers';
import {
  AdHocActionTypes,
  ConversationTypes,
  createChatRoom,
  uploadFile
} from 'Communication';
import { CaregiverProfileStatus } from 'components';
import { useLocationState } from 'Location';
import { fetchCaregivers } from 'Settings/actions';
import { Caregiver } from 'Settings/types';
import { ChatId, LoginId } from 'types';
import { debounce } from 'utils';

import { AdHocCreateDetails } from './AdHocCreateDetails';
import { AdHocList } from './AdHocList';
import { DEAFULT_SEARCH_TERM } from './constants';
import {
  CommunicationActionTypes,
  useAdHocProvider,
  useCommunicationContext
} from './contexts';
import { MessageInputBox } from './MessageInputBox';
import { SearchContactHeader } from './SearchContactHeader';
import { IAdHocCaregiver, IAdHocCreateProps } from './types';
import { isValidSearchTerm } from './utils';

import styles from './AdHocCreate.module.css';

export const AdHocCreate: FunctionComponent<IAdHocCreateProps> = ({
  onClose
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(DEAFULT_SEARCH_TERM);
  const [tempList, setTempList] = useState<Caregiver[]>([]);
  const [tempError, setTempError] = useState<Error | null>(null);
  const [tempLoading, setTempLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [localList, setLocalList] = useState<IAdHocCaregiver[]>([]);
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const [fetchMore, setFetchMore] = useState<boolean>(false);
  const [selectedConversationId, setSelectedConversationId] = useState<
    ChatId | undefined
  >(undefined);

  const [{ conversations }, conversationDispatch] = useCommunicationContext();
  const { adHocContext, adHocDispatch } = useAdHocProvider();
  const { adHocName, selectedList, selectedIds } = adHocContext;

  const {
    facility: { id: facilityId }
  } = useLocationState();
  const { loginId } = useAuthState();

  const {
    data: caregiversStatuses,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const resetLocalState = useCallback(() => {
    setLocalList([]);
    setNextPage('0');
    setFetchMore(true);
  }, []);

  useEffect(() => {
    setNextPage('0');
    setFetchMore(true);
  }, [facilityId]);

  useEffect(() => {
    if (fetchMore && nextPage) {
      setFetchMore(false);
      fetchCaregivers(
        facilityId,
        searchTerm,
        setTempList,
        setNextPage,
        setTempError,
        setTempLoading,
        nextPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, nextPage]);

  useEffect(
    () => {
      if (isValidSearchTerm(searchTerm) && !searching && !tempLoading) {
        setSearching(true);
      }
      if (isValidSearchTerm(searchTerm) && searching && !tempLoading) {
        resetLocalState();
      }
      if (!isValidSearchTerm(searchTerm) && searching && !tempLoading) {
        setSearching(false);
        resetLocalState();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, searching]
  );

  useEffect(() => {
    if (
      tempList.length === 0 ||
      tempLoading ||
      tempError ||
      statusLoading ||
      statusError
    ) {
      return undefined;
    }

    const activeList = tempList.filter(
      item => item.isActive === true && item.loginId !== loginId
    );

    return setLocalList(prevLocal => {
      return [...prevLocal, ...activeList].map(item => {
        const status: CaregiverProfileStatus =
          caregiversStatuses[item.id] === StatusTypes.OFF_DUTY
            ? 'unavailable'
            : 'available';

        return {
          ...item,
          status
        };
      });
    });
  }, [
    caregiversStatuses,
    loginId,
    tempError,
    tempList,
    tempLoading,
    statusError,
    statusLoading
  ]);

  useEffect(() => {
    const active = conversations.find(
      conv => conv.id === selectedConversationId
    );
    if (active) {
      conversationDispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: active
      });
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, selectedConversationId]);

  const handleSelectItem = (selectedLoginId: LoginId) => {
    adHocDispatch({
      type: AdHocActionTypes.SET_SELECTED_IDS,
      payload: [...selectedIds, selectedLoginId]
    });

    const selectedCaregiver = localList.find(
      caregiver => caregiver.loginId === selectedLoginId
    );

    adHocDispatch({
      type: AdHocActionTypes.SET_SELECTED_LIST,
      payload: [...selectedList, selectedCaregiver!]
    });
  };

  const handleDeselectItem = (selectedLoginId: LoginId) => {
    adHocDispatch({
      type: AdHocActionTypes.SET_SELECTED_IDS,
      payload: selectedIds.filter(id => id !== selectedLoginId)
    });

    const filteredSelected = selectedList.filter(
      caregiver => caregiver.loginId !== selectedLoginId
    );

    adHocDispatch({
      type: AdHocActionTypes.SET_SELECTED_LIST,
      payload: filteredSelected
    });
  };

  const handleFetchMore = useCallback(
    value => {
      if (nextPage) {
        setFetchMore(value);
      }
    },
    [nextPage]
  );

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 300);

  const handleCloseCreate = () => {
    adHocDispatch({
      type: AdHocActionTypes.RESET_AD_HOC
    });
    onClose();
  };

  const handleSendText = async (textMessage: string) => {
    const { chatRoomId: newChatId } = await createChatRoom(
      [...selectedIds, loginId],
      ConversationTypes.AD_HOC,
      textMessage,
      adHocName
    );

    setSelectedConversationId(newChatId);
  };

  const handleSendFile = async (file: File) => {
    const { chatRoomId: newChatId } = await createChatRoom(
      [...selectedIds, loginId],
      ConversationTypes.AD_HOC,
      null,
      adHocName
    );

    await uploadFile(file, newChatId, loginId);

    setSelectedConversationId(newChatId);
  };

  return (
    <div className={styles.addHocContainer}>
      <div className={styles.adHocList}>
        <SearchContactHeader
          onCancel={handleCloseCreate}
          onSearch={handleSearch}
          searchValue={searchTerm}
        />
        <div className={styles.searchContainer}>
          <AdHocList
            items={localList}
            selectedItemsIds={selectedIds}
            onSelect={handleSelectItem}
            onDeselect={handleDeselectItem}
            loadingItems={tempLoading}
            itemsError={tempError}
            refetch={handleFetchMore}
          />
        </div>
        <div className={styles.inputContainer}>
          <MessageInputBox
            onFileUpload={handleSendFile}
            onSend={handleSendText}
            clearOnSend={true}
            disabled={selectedList.length === 0}
          />
        </div>
      </div>
      <div className={styles.adHocList}>
        <AdHocCreateDetails onRemove={handleDeselectItem} />
      </div>
    </div>
  );
};
