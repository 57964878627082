import React, { useMemo, useState } from 'react';

import {
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelDetail,
  Snackbar,
  TextStatus
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useModal } from 'hooks';
import {
  Device,
  downloadGatewayCertificate,
  Facility,
  getDeviceDetails
} from 'Settings';
import {
  deviceTypes,
  getHeight,
  isAssetType,
  isCaregiverType,
  isGateway,
  isResidentType,
  isRoomType,
  saveFile
} from 'utils';

import { EditDeviceForm } from './EditDeviceForm';

import stylesModal from 'components/Modal/Modal.module.css';
import groupStyles from 'Settings/Settings.module.css';
import deviceStyles from './Devices.module.css';

export const DeviceDetails: React.FunctionComponent<{
  deviceId: string;
  facility: Facility;
  onModifiedDevice: (device: Device) => void;
  hasDeviceAccess: boolean;
}> = ({ deviceId, facility, onModifiedDevice, hasDeviceAccess = false }) => {
  const [certificateError, setCertificateError] = useState<Error | null>(null);

  const getDeviceDetailsMemo = useMemo(
    () => getDeviceDetails(deviceId, facility.id),
    [deviceId, facility.id]
  );

  const { data, error, loading, setRefetch } = useFetcher<Device>(
    getDeviceDetailsMemo,
    {} as Device
  );

  const { isShowing, toggle } = useModal();

  const modalClosed = () => {
    toggle();
    setRefetch();
  };

  const onEdit = () => {
    dismissCertificateError();
    toggle();
  };

  const downloadCertificate = async () => {
    try {
      const certificateBlob = await downloadGatewayCertificate(
        deviceId,
        facility.id
      )();
      const filename = `${data.mac}.p12`;

      saveFile(filename, certificateBlob);
    } catch (err) {
      setCertificateError(err as Error);
    }
  };

  const dismissCertificateError = () => {
    setCertificateError(null);
  };

  return (
    <>
      <PanelDetail
        onAction={onEdit}
        title={'Device Details'}
        hasError={!!error}
      >
        {deviceId && !error && !loading && (
          <>
            <div className={groupStyles.detailRow}>
              <div className={groupStyles.panelGroupQuarter}>
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>Device ID</span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <div className={groupStyles.panelDetailSpan}>{data.mac}</div>
              </div>
              <div className={groupStyles.panelGroupQuarter}>
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>
                    Device Name
                  </span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <div className={groupStyles.panelDetailSpan}>{data.name}</div>
              </div>
              <div className={groupStyles.panelGroupQuarter}>
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>
                    Device Type
                  </span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <div className={groupStyles.panelDetailSpan}>
                  {deviceTypes(facility.type)[data.type]}
                </div>
              </div>
              <div className={groupStyles.panelGroupStatus}>
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>Status</span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <div className={groupStyles.panelDetailSpan}>
                  <TextStatus status={data.isActive} />
                </div>
              </div>
            </div>
            {hasDeviceAccess && (
              <div className={groupStyles.detailRow}>
                <div className={groupStyles.panelGroupStretch}>
                  <div className={groupStyles.panelGroupTitleWrapper}>
                    <span className={groupStyles.panelGroupTitle}>
                      Unique Identifier
                    </span>
                    <span className={groupStyles.panelDetailFixedSpan}>:</span>
                  </div>
                  <div className={groupStyles.panelDetailSpan}>
                    {data.uniqueIdentifier || 'N/A'}
                  </div>
                </div>
              </div>
            )}
            {isAssetType(data.type) && (
              <div className={groupStyles.detailRow}>
                <div className={groupStyles.panelGroup}>
                  <div className={groupStyles.panelGroupTitleWrapper}>
                    <span className={groupStyles.panelGroupTitle}>
                      Description
                    </span>
                    <span className={groupStyles.panelDetailFixedSpan}>:</span>
                  </div>
                  <div className={groupStyles.panelDetailSpan}>
                    {data.description || 'N/A'}
                  </div>
                </div>
              </div>
            )}
            {isCaregiverType(data.type) && data.caregiver && (
              <div className={groupStyles.detailRow}>
                <div className={groupStyles.panelGroupQuarter}>
                  <div className={groupStyles.panelGroupTitleWrapper}>
                    <span className={groupStyles.panelGroupTitle}>
                      Caregiver
                    </span>
                    <span className={groupStyles.panelDetailFixedSpan}>:</span>
                  </div>
                  <div className={groupStyles.panelDetailSpan}>
                    {data.caregiver.name || 'N/A'}
                  </div>
                </div>
              </div>
            )}
            {isResidentType(data.type, facility.type) && data.resident && (
              <div className={groupStyles.detailRow}>
                <div className={groupStyles.panelGroupQuarter}>
                  <div className={groupStyles.panelGroupTitleWrapper}>
                    <span className={groupStyles.panelGroupTitle}>
                      {residentLabels[facility.type]}
                    </span>
                    <span className={groupStyles.panelDetailFixedSpan}>:</span>
                  </div>
                  <div className={groupStyles.panelDetailSpan}>
                    {data.resident.name || 'N/A'}
                  </div>
                </div>
              </div>
            )}
            {isRoomType(data.type) && (
              <div className={groupStyles.detailRow}>
                {isGateway(data.type) && hasDeviceAccess && (
                  <div className={groupStyles.panelGroupQuarter}>
                    <div className={groupStyles.panelGroupTitleWrapper}>
                      <span className={groupStyles.panelGroupTitle}>
                        RSSI Threshold
                      </span>
                      <span className={groupStyles.panelDetailFixedSpan}>
                        :
                      </span>
                    </div>
                    <div className={groupStyles.panelDetailSpan}>
                      {data.rssiTriggerLevel || 'N/A'}
                    </div>
                  </div>
                )}
                {data.room && (
                  <>
                    {data.room.ward && (
                      <div className={groupStyles.panelGroupQuarter}>
                        <div className={groupStyles.panelGroupTitleWrapper}>
                          <span className={groupStyles.panelGroupTitle}>
                            Ward
                          </span>
                          <span className={groupStyles.panelDetailFixedSpan}>
                            :
                          </span>
                        </div>
                        <div className={groupStyles.panelDetailSpan}>
                          {data.room.ward.name || 'N/A'}
                        </div>
                      </div>
                    )}
                    <div className={groupStyles.panelGroupQuarter}>
                      <div className={groupStyles.panelGroupTitleWrapper}>
                        <span className={groupStyles.panelGroupTitle}>
                          Room
                        </span>
                        <span className={groupStyles.panelDetailFixedSpan}>
                          :
                        </span>
                      </div>
                      <div className={groupStyles.panelDetailSpan}>
                        {data.room.number || 'N/A'}
                      </div>
                    </div>
                  </>
                )}
                {isGateway(data.type) && hasDeviceAccess && (
                  <div className={groupStyles.panelGroupLink}>
                    <button
                      className={deviceStyles.certificateButton}
                      onClick={downloadCertificate}
                    >
                      Download Certificate
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {loading && <LoadingPlaceholder />}
        {error && !loading && <FetchError error={error} />}

        <Modal isShowing={isShowing} toggle={toggle}>
          <div className={stylesModal.modalBackdrop}>
            <div className={stylesModal.modalContentRightAlign}>
              <div
                className={stylesModal.modalRightWithVerticalBar}
                style={{ marginTop: getHeight('Device Details') }}
              >
                <EditDeviceForm
                  toggle={modalClosed}
                  device={data}
                  facility={facility}
                  onModifiedDevice={onModifiedDevice}
                  hasDeviceAccess={hasDeviceAccess}
                />
              </div>
            </div>
          </div>
        </Modal>
      </PanelDetail>
      <Snackbar
        isOpen={!!certificateError}
        onClose={dismissCertificateError}
        message="Could not download certificate. Please try again or make sure the gateway has a valid certificate."
      />
    </>
  );
};
