import React from 'react';

import { ReactComponent as GlucoseMeter } from 'icons/glucose-meter.svg';
import { BloodSugar, convertTimestamp } from 'Residents';

import { VitalCard } from './VitalCard';

import style from './BloodSugarVitalCard.module.css';

export const BloodSugarVitalCard: React.FunctionComponent<{
  onClick: () => void;
  bloodSugar: BloodSugar;
}> = ({ onClick, bloodSugar }) => {
  const { measuredAt, value, code, unit } = bloodSugar || {};

  const hasWarning = !!code && code !== 'GREEN';

  return (
    <div className={style.bloodSugar} onClick={onClick}>
      <VitalCard
        title="Blood Sugar"
        timestamp={measuredAt && convertTimestamp(measuredAt)}
        reading={value && value.bloodSugar}
        hasWarning={hasWarning}
        unit={unit}
      >
        <GlucoseMeter />
      </VitalCard>
    </div>
  );
};
