import { ErrorMessage } from '../Settings/types';

export const errorMessages = (label: string): ErrorMessage[] => [
  {
    statusCode: 400,
    code: 'resetPasswordKey.expired',
    message: 'The reset password link has expired. Please restart the process.'
  },
  {
    statusCode: 400,
    code: 'passwordReset.not.found',
    message: 'Something went wrong. Please restart the process.'
  },
  {
    statusCode: 400,
    code: 'facility.name.empty',
    message: 'Facility name cannot be empty.'
  },
  {
    statusCode: 400,
    code: 'idn.name.empty',
    message: 'Idn name cannot be empty.'
  },
  {
    statusCode: 409,
    code: 'resident.number.unique',
    message: `There already exists a ${label.toLowerCase()} with that number.`
  },
  {
    statusCode: 400,
    code: 'error.password.rejected',
    message:
      'This password is not accepted, please choose something more secure.'
  },
  {
    statusCode: 400,
    code: 'error.password.length',
    message: 'Password must be at least 8 characters long.'
  },
  {
    statusCode: 400,
    code: 'device.mac.format',
    message: 'Please use 6 byte EUI-48 MAC Address.'
  },
  {
    statusCode: 409,
    code: 'device.name.unique',
    message: 'There already exists a device with that name.'
  },
  {
    statusCode: 409,
    code: 'device.mac.unique',
    message: 'There already exists a device with that MAC address.'
  },
  {
    statusCode: 409,
    type: 'room',
    code: 'room.number.unique',
    message: 'There already exists a room with that number.'
  },
  {
    statusCode: 409,
    type: 'ward',
    code: 'ward.name.unique',
    message: 'There already exists a ward with that name.'
  },
  {
    statusCode: 400,
    type: 'caregiver',
    code: 'user.email.empty',
    message: 'Email address cannot be empty.'
  },
  {
    statusCode: 401,
    code: 'invalid_token',
    message: 'Your current session expired. Please refresh to re-authenticate.'
  },
  {
    statusCode: 403,
    code: 'access.denied',
    message: "You don't have the necessary rights to access this resource."
  },
  {
    statusCode: 404,
    code: 'entity.not.found',
    message: 'The resource cannot be found.'
  },
  {
    statusCode: 502,
    message:
      'We encountered a temporary error and could not complete your request.'
  },
  {
    statusCode: 503,
    message: 'Looks like the service is temporarily unavailable.'
  },
  {
    statusCode: 504,
    message: 'Looks like the server is taking too long to respond.'
  },
  {
    statusCode: 400,
    code: 'not.readable.message',
    message: 'The request failed due to unsupported format.'
  },
  {
    statusCode: 400,
    code: 'thUser.login.rights.unauthorized',
    message: 'You are not authorized to modify this role.'
  },
  {
    statusCode: 400,
    code: 'thUser.login.rights.forbidden',
    message: 'You are not authorized to set this role.'
  },
  {
    statusCode: 409,
    code: 'resident.relatedContact.unique',
    message: 'There already exists a Related Contact with this E-mail Address'
  },
  {
    statusCode: 409,
    code: 'user.email.unique',
    message: 'There already exists a Caregiver with this E-Mail Address'
  },
  {
    statusCode: 400,
    code: 'chatRoom.invalid.identifier',
    message: 'Could not save changes to chat. Try again.'
  },
  {
    statusCode: 409,
    code: 'device.mac.uniqueIdentifier.unique',
    message: 'There already exists a device with that unique identifier.'
  },
  {
    statusCode: 400,
    code: 'ward.alarm.settings.time.too_short',
    message: 'Rounding Alarm value must be from 5 to 1440 minutes.'
  },
  {
    statusCode: 400,
    code: 'ward.alarm.settings.time.too_long',
    message: 'Rounding Alarm value must be from 5 to 1440 minutes.'
  },
  {
    statusCode: 400,
    code: 'thUser.name.empty',
    message: 'User name cannot be empty.'
  }
];
