import { CallType } from 'Communication/types';
import { axiosWrapper } from 'utils';

export const initiateCall = (
  chatRoomId: string,
  callType: CallType
) => async () => {
  await axiosWrapper(`/chat-rooms/${chatRoomId}/messages`, 'POST', null, {
    messageType: 'call_initiation_message',
    callType
  });
};
