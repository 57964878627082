import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';

import { useAuthState } from 'Auth/auth.context';
import { getMissedCalls } from 'Communication/actions';
import {
  IMissedCall,
  IMissedCallCount,
  MissedCallActionTypes
} from 'Communication/types';
import { missedCallsReducerFunction } from 'Communication/utils';
import { useFetcher } from 'hooks';
import { ComUpdateTypes, SocketActions, useSocketContext } from 'Socket';
import { useMissedCountState } from './missedCount.context';

interface MissedCallAction {
  type: MissedCallActionTypes.SET_MISSED_CALLS;
  payload: IMissedCallCount[];
}

const MissedCallsContext = createContext<{
  state: IMissedCallCount[];
  // to trigger a manual refecth of missed calls
  refetchMissedCalls: () => void;
}>({
  state: [],
  refetchMissedCalls: () => undefined
});

const missedCallsReducer = (
  state: IMissedCallCount[],
  action: MissedCallAction
) => {
  switch (action.type) {
    case MissedCallActionTypes.SET_MISSED_CALLS: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const MissedCallsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(missedCallsReducer, []);
  const { loggedUser } = useAuthState();
  const [{ comUpdate }, { dispatch: socketDispatch }] = useSocketContext();
  const messageType = Boolean(comUpdate) ? comUpdate?.messageType : null;
  const {
    missedCount: { missedAudioCalls, missedVideoCalls }
  } = useMissedCountState();

  const [fetchMissedCalls, setFetchMissedCalls] = useState<boolean>(false);
  const [count, setCount] = useState<IMissedCall[]>([]);
  const [page, setPage] = useState<number | undefined>(0);

  const resetLocalState = () => {
    setCount([]);
    setPage(0);
    setFetchMissedCalls(true);
  };

  useEffect(() => {
    if (loggedUser && loggedUser.type === 'CAREGIVER') {
      setFetchMissedCalls(true);
    }
  }, [loggedUser]);

  const getMissedCallsAction = useMemo(() => {
    if (page === undefined) {
      return undefined;
    }

    if (fetchMissedCalls) {
      return getMissedCalls(page);
    }
  }, [fetchMissedCalls, page]);

  const {
    data: { items: missedCalls, nextPage },
    loading: missedCallsLoading
  } = useFetcher<{
    items: IMissedCall[];
    nextPage: number;
  }>(getMissedCallsAction, {
    items: [],
    nextPage: 0
  });

  useEffect(() => {
    if (!missedCallsLoading && missedCalls.length > 0) {
      const unseenMissedCalls = missedCalls.filter(call => {
        return call.seen === false;
      });
      setCount(previous => {
        return [...previous, ...unseenMissedCalls];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missedCallsLoading]);

  useEffect(() => {
    if (missedCallsLoading) {
      return;
    }
    if (missedAudioCalls + missedVideoCalls === count.length) {
      return setPage(undefined);
    }

    return setPage(nextPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missedCallsLoading, missedAudioCalls, missedVideoCalls]);

  useEffect(() => {
    if (page === undefined) {
      const missedCallsWithChatId = count.reduce(
        missedCallsReducerFunction,
        []
      );

      dispatch({
        type: MissedCallActionTypes.SET_MISSED_CALLS,
        payload: missedCallsWithChatId
      });

      return setFetchMissedCalls(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (
      messageType === ComUpdateTypes.CALL_DISMISS_MESSAGE &&
      !fetchMissedCalls
    ) {
      socketDispatch({
        type: SocketActions.RESET_SOCKET
      });
      return resetLocalState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType]);

  return (
    <MissedCallsContext.Provider
      value={{
        state,
        refetchMissedCalls: resetLocalState
      }}
    >
      {children}
    </MissedCallsContext.Provider>
  );
};

export const useMissedCallsState = () => {
  const context = useContext(MissedCallsContext);

  if (context === undefined) {
    throw new Error(
      'useMissedCallsState must be used within a MissedCallsProvider'
    );
  }

  return context;
};
