import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  DismissibleError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  Select,
  StatusDropdown
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import {
  AlarmPriorityKey,
  priorityOptions,
  UNATTENDED_ALARM_TITLE
} from 'Settings/constants';
import { getFormValidationErrors, isEmpty, removeExtraSpaces } from 'utils';

import { editWard } from '../actions';
import { WardError } from '../types';

import stylesForm from 'styles/form.module.css';

interface EditWardFormData {
  name: string;
  isActive: boolean;
  unattendedResidentAlarmMinutes: number;
  alarmColorCode: AlarmPriorityKey;
}

export const EditWardForm: React.FunctionComponent<{
  toggle: () => void;
  ward: MappedWard;
  notifyWardChange: (ward: MappedWard) => void;
}> = ({ toggle, ward, notifyWardChange }) => {
  const [modifiedWard, setModifiedWard] = useState<MappedWard>(ward);
  const [localErrors, setLocalErrors] = useState<{
    name: boolean;
    minutes: boolean;
  }>({ name: false, minutes: false });
  const [isAlarmPriorityDisabled, setIsAlarmPriorityDisabled] = useState(
    ward.unattendedResidentAlarmMinutes ? false : true
  );

  const isFieldDisabled = !Boolean(
    modifiedWard.services.alarmsNotifications.features.anRounding.isEnabled &&
      modifiedWard.services.rtls.features.rtlsStaff.isEnabled
  );

  const {
    register,
    errors,
    setValue,
    watch,
    triggerValidation,
    getValues
  } = useForm<EditWardFormData>({
    mode: 'onBlur',
    defaultValues: {
      name: ward.name,
      isActive: ward.isActive,
      unattendedResidentAlarmMinutes:
        isFieldDisabled && ward.unattendedResidentAlarmMinutes
          ? undefined
          : ward.unattendedResidentAlarmMinutes,
      alarmColorCode:
        isFieldDisabled && ward.alarmColorCode ? undefined : ward.alarmColorCode
    },
    submitFocusError: false
  });

  const { setAction, loading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, loading);

  const saveWard = (updatedWard: MappedWard) => {
    setAction(editWard(updatedWard));
    setModifiedWard(updatedWard);
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();

    if (isValid) {
      const {
        name,
        isActive,
        unattendedResidentAlarmMinutes,
        alarmColorCode
      } = getValues();

      saveWard({
        ...ward,
        isActive,
        unattendedResidentAlarmMinutes: isEmpty(unattendedResidentAlarmMinutes)
          ? undefined
          : unattendedResidentAlarmMinutes,
        unattendedResidentAlarmTitle: isEmpty(unattendedResidentAlarmMinutes)
          ? undefined
          : UNATTENDED_ALARM_TITLE,
        alarmColorCode: isEmpty(unattendedResidentAlarmMinutes)
          ? undefined
          : alarmColorCode,
        name: removeExtraSpaces(name)
      });
    }
  };

  useEffect(() => {
    let nameHasError = false;
    let minutesHasError = false;

    if (
      errors.name ||
      (isErrorShowing &&
        error &&
        (error as WardError).code === 'ward.name.unique')
    ) {
      nameHasError = true;
    }

    if (
      errors.unattendedResidentAlarmMinutes ||
      (isErrorShowing &&
        error &&
        ((error as WardError).code === 'ward.alarm.settings.time.too_short' ||
          (error as WardError).code === 'ward.alarm.settings.time.too_long'))
    ) {
      minutesHasError = true;
    }

    setLocalErrors({
      name: nameHasError,
      minutes: minutesHasError
    });
  }, [errors, error, isErrorShowing]);

  useEffect(() => {
    if (!submitted || loading || error) {
      return undefined;
    }
    toggle();
    notifyWardChange(modifiedWard);
  }, [submitted, loading, error, toggle, notifyWardChange, modifiedWard]);

  const validationErrors = getFormValidationErrors(errors);

  const handleInputChange = () => {
    if (error) {
      dismissError();
    }
    const { unattendedResidentAlarmMinutes } = getValues();
    setIsAlarmPriorityDisabled(unattendedResidentAlarmMinutes ? false : true);
  };

  const handleValidation = () => {
    triggerValidation([{ name: 'alarmColorCode' }]);
  };

  return (
    <>
      <FormHeader
        title="Edit Ward Details"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={loading}
      />
      {loading && <LoadingPlaceholder />}
      <form
        onSubmit={handleSubmit}
        className={classnames(stylesForm.visible, {
          [stylesForm.hidden]: loading
        })}
      >
        <div className={stylesForm.formRow}>
          <div className={stylesForm.inputGroupQuarter}>
            <Input
              name="name"
              label="Ward Name"
              register={register}
              validationRules={{ required: true }}
              hasError={Boolean(localErrors.name)}
              onChange={handleInputChange}
            />
          </div>
          <div className={stylesForm.inputGroupQuarter}>
            <Input
              name="unattendedResidentAlarmMinutes"
              label="Rounding Alarm (min)"
              register={register}
              hasError={Boolean(localErrors.minutes)}
              onChange={handleInputChange}
              readonly={isFieldDisabled}
            />
          </div>
          <div className={stylesForm.selectGroupAlarmPriority}>
            <Select
              name="alarmColorCode"
              label="Alarm Priority"
              options={priorityOptions}
              sortField="sortHelper"
              hasError={Boolean(errors.alarmColorCode)}
              register={register}
              onChange={setValue}
              value={watch('alarmColorCode')}
              isDisabled={isAlarmPriorityDisabled || isFieldDisabled}
              required={!isAlarmPriorityDisabled && !isFieldDisabled}
              onBlur={handleValidation}
            />
          </div>
          <div className={stylesForm.selectGroupStatusSmall}>
            <StatusDropdown
              register={register}
              onChange={setValue}
              value={watch('isActive')}
              hasError={!!errors.isActive}
            />
          </div>
        </div>
        <DismissibleError
          name="Ward"
          visible={isErrorShowing}
          error={error}
          dismiss={dismissError}
        />
        {validationErrors && (
          <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
        )}
      </form>
    </>
  );
};
