import React from 'react';

import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { XAxisTick } from './XAxisTick';

import style from './Chart.module.css';

export const Chart: React.FunctionComponent<{
  intervals: ((data: any) => number[]) | number[];
  tooltip: JSX.Element;
  lineOne: JSX.Element;
  lineTwo?: JSX.Element;
  color: string;
  data: any;
  allowHighlight: boolean;
  invertColors?: boolean;
}> = ({
  intervals,
  data,
  tooltip,
  lineOne,
  lineTwo,
  color,
  allowHighlight,
  invertColors = false
}) => {
  const intervalArray = Array.isArray(intervals) ? intervals : intervals(data);
  const strokeColor = invertColors ? color : '#FFF';

  return (
    <ResponsiveContainer width="97%" height={200}>
      <LineChart data={data} className={style.chart}>
        <CartesianGrid
          strokeDasharray="10 10"
          vertical={false}
          strokeWidth={1}
          stroke={invertColors ? '#bdbdbd' : '#FFF'}
        />
        {tooltip}
        {lineOne}
        {lineTwo}
        <YAxis
          stroke={strokeColor}
          ticks={intervalArray}
          axisLine={false}
          tickLine={false}
          domain={[intervalArray[0], intervalArray[intervalArray.length - 1]]}
          interval={0}
          minTickGap={0}
          type="number"
        />
        <XAxis
          dataKey="label"
          stroke={strokeColor}
          axisLine={false}
          tickLine={false}
          padding={{ left: 20, right: 20 }}
          tick={
            <XAxisTick
              color={color}
              allowHighlight={allowHighlight}
              invertColors={invertColors}
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
