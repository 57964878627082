import React, { useCallback, useEffect, useState } from 'react';

import { FetchError, LoadingPlaceholder, PanelDetail } from 'components';
import { residentLabels } from 'consts';
import {
  FeatureNameMappings,
  FormatLabel,
  ServiceNameMappings
} from 'Settings/actions/featureMapping.actions';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';

import { WardFeatureWithProvider } from './WardFeature';
import { WardService } from './WardService';

import styles from './styles/WardFeatures.module.css';

interface IWardFeaturesProps {
  ward: MappedWard;
  facilityType: keyof typeof residentLabels;
  onFeatureChange: (
    feature: IWardFeature,
    serviceId: keyof typeof ServiceNameMappings
  ) => void;
  loading: boolean;
  error: Error | null;
}

export interface IWardService {
  id: keyof typeof ServiceNameMappings;
  name: string;
  noOfFeatures: number;
  noOfEnabledFeatures: number;
}

export interface IWardFeature {
  id: keyof FeatureNameMappings;
  name: string;
  value: boolean;
}

export const WardFeatures: React.FunctionComponent<IWardFeaturesProps> = ({
  ward: { id: wardId, services: servicesList },
  facilityType,
  onFeatureChange,
  loading,
  error
}) => {
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(
    null
  );
  const [features, setFeatures] = useState<IWardFeature[]>([]);
  const [services, setServices] = useState<IWardService[]>([]);

  useEffect(() => {
    const list: IWardService[] = Object.keys(servicesList).map(item => {
      const serviceKey = item as keyof typeof ServiceNameMappings;
      let name: string = '';

      const getServiceName: string | FormatLabel =
        servicesList[serviceKey].name;

      if (typeof getServiceName === 'string') {
        name = getServiceName;
      } else {
        name = getServiceName(`${residentLabels[facilityType]}`);
      }

      return {
        id: servicesList[serviceKey].id,
        name,
        noOfFeatures: Object.keys(servicesList[serviceKey].features).length,
        noOfEnabledFeatures: Object.values(
          servicesList[serviceKey].features
        ).filter(feat => feat.isEnabled === true).length
      } as IWardService;
    });

    setServices(list);
    setSelectedServiceId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardId]);

  useEffect(() => {
    if (!selectedServiceId) {
      return undefined;
    }

    const serviceObj: {
      id: string;
      name: string | FormatLabel;
      features: {
        [key: string]: {
          id: string;
          name: string | FormatLabel;
          isEnabled: boolean;
        };
      };
    } = servicesList[selectedServiceId as keyof typeof ServiceNameMappings];

    const featureList: IWardFeature[] = Object.keys(serviceObj.features).map(
      item => {
        const featureKey = item as keyof FeatureNameMappings;
        let name: string = '';
        const getFeatureName: string | FormatLabel =
          serviceObj.features[featureKey].name;

        if (typeof getFeatureName === 'string') {
          name = getFeatureName;
        } else {
          name = getFeatureName(`${residentLabels[facilityType]}`);
        }

        return {
          id: item as keyof FeatureNameMappings,
          name,
          value: serviceObj.features[featureKey].isEnabled as boolean
        };
      }
    );

    setFeatures(featureList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceId]);

  const handleSelectService = useCallback((id: string) => {
    setSelectedServiceId(id);
  }, []);

  const handleChangeEnabledFeaturesNumber = useCallback(
    (value: boolean) => {
      setServices(oldServices => {
        return oldServices.map(service => {
          if (service.id === selectedServiceId) {
            return {
              ...service,
              noOfEnabledFeatures: value
                ? service.noOfEnabledFeatures + 1
                : service.noOfEnabledFeatures - 1
            } as IWardService;
          }
          return service;
        });
      });
    },
    [selectedServiceId]
  );

  const handleSelectFeature = useCallback(
    (id: string, value: boolean) => {
      const feat = features.find(f => f.id === id);
      if (feat && selectedServiceId) {
        onFeatureChange(
          {
            ...feat,
            value
          },
          selectedServiceId as keyof typeof ServiceNameMappings
        );
        handleChangeEnabledFeaturesNumber(value);
      }
    },
    [
      features,
      handleChangeEnabledFeaturesNumber,
      onFeatureChange,
      selectedServiceId
    ]
  );

  return (
    <PanelDetail
      title="Ward Features"
      customStyles={styles.customPanel}
      childrenStyles={styles.customPanel}
    >
      <div role="grid" className={styles.wardFeaturesContainer}>
        <div className={styles.servicesWrapper}>
          <h4 className={styles.servicesTitle}>Service Type</h4>
          <ul className={styles.servicesList}>
            {services.length &&
              services.map(service => (
                <WardService
                  key={service.name}
                  onClick={handleSelectService}
                  service={service}
                  selectedServiceId={selectedServiceId}
                />
              ))}
          </ul>
        </div>
        <div className={styles.featuresWrapper}>
          <h4 className={styles.featuresTitle}>Feature Name</h4>
          <ul className={styles.featuresList}>
            {selectedServiceId &&
              features.map(feat => (
                <WardFeatureWithProvider
                  key={feat.id}
                  feature={feat}
                  onChange={handleSelectFeature}
                />
              ))}
          </ul>
        </div>
      </div>

      {loading && <LoadingPlaceholder />}
      {error && !loading && <FetchError error={error} />}
    </PanelDetail>
  );
};
