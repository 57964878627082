import classnames from 'classnames';
import React from 'react';

import style from './ActivityInfoCard.module.css';

type Type =
  | 'dailySteps'
  | 'sittingHours'
  | 'sleepingHours'
  | 'lastBath'
  | 'staffVisits'
  | 'residentLocation'
  | 'liquidIntake'
  | 'foodIntake'
  | 'liquidOutput';

export const ActivityInfoCard: React.FunctionComponent<{
  reading?: string | number;
  unit?: string;
  type: Type;
}> = ({ reading = 'No Data', unit = '', type, children }) => {
  const noData = reading === 'No Data';

  return (
    <>
      {children}
      <span
        className={classnames(style.reading, {
          [style.emptyReading]: noData,
          [style.noUnit]: !noData && !unit
        })}
      >
        {reading}
      </span>
      {!noData && (
        <span
          className={classnames(style.measurement, { [style[type]]: true })}
        >
          {unit}
        </span>
      )}
    </>
  );
};
