import React, { useState } from 'react';

import { TwoColumnLayout, WithPermissions } from 'components';
import { useModal } from 'hooks';
import { User } from 'Settings';

import { EditUserDetails } from './EditUserDetails';
import { UserProvider } from './user.context';
import { UserFacilitiesAccess } from './UserFacilitiesAccess';
import { UsersList } from './UsersList';
import { WithUserDetails } from './WithUserDetails';

import styles from './UserSettings.module.css';

export const UserSettings: React.FunctionComponent = () => {
  const [selectedUser, setSelectedUser] = useState({ id: '', title: '' });
  const selectUser = (user: User) => {
    setSelectedUser(user);
  };
  const { isShowing, toggle } = useModal();

  return (
    <>
      <UserProvider>
        <TwoColumnLayout
          left={
            <UsersList
              selectedUserId={selectedUser.id}
              selectUser={selectUser}
            />
          }
          right={
            <>
              <div className={styles.panelHeight}>
                <WithUserDetails userId={selectedUser.id} onEdit={toggle} />
                <WithPermissions
                  action="settings-users:edit"
                  data={{ user: selectedUser }}
                  yes={<EditUserDetails toggle={toggle} isOpen={isShowing} />}
                />
              </div>
              <UserFacilitiesAccess
                user={{ id: selectedUser.id, title: selectedUser.title }}
              />
            </>
          }
        />
      </UserProvider>
    </>
  );
};
