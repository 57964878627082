import React from 'react';
import { Tooltip } from 'recharts';

import { decimalHoursToTime } from 'Residents';

import style from './Tooltip.module.css';

const Content: React.FunctionComponent<{
  payload?: any;
}> = props => {
  if (props.payload[0]) {
    const time = decimalHoursToTime(props.payload[0].payload.hours);

    return (
      <div className={style.sleepingHoursTooltip}>
        <span>{`${props.payload[0].payload.label}`}</span>
        <span>
          Sleeping Hours: <b>{time}</b>
        </span>
      </div>
    );
  }
  return <></>;
};

export const SleepingHoursTooltip = <Tooltip content={Content} />;
