import React, { useRef } from 'react';

import { Column } from 'components';

import { Resident } from '../../types';

import stylesTable from 'components/Table/Table.module.css';
import styles from 'styles/typography.module.css';

export const RoomResidentTableRow: React.FunctionComponent<{
  values: Resident;
  isSelected: boolean;
  label: string;
  setActiveTab: (tab: string) => void;
  onSelect: (key: string, id: string) => void;
  columns: { [key: string]: Column };
}> = ({ values, isSelected, onSelect, setActiveTab, columns, label }) => {
  const roomLinkRef = useRef(null);
  const residentLinkRef = useRef(null);

  const { id, number: residentNumber, room, name } = values;

  const handleClick = (evt: any) => {
    const clickedOnLinks =
      evt.target === roomLinkRef.current ||
      (residentLinkRef && evt.target === residentLinkRef);
    if (clickedOnLinks && isSelected) {
      return;
    }
    if (room) {
      onSelect('room', room.id);
    }
    onSelect('resident', id);
  };

  const handleRoomLink = () => {
    if (room?.id) {
      setActiveTab('Rooms');
    }
  };

  const handleResidentLink = () => {
    setActiveTab(`${label}s`);
  };

  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div onClick={handleClick} className={rowClass} role="row">
      <div
        className={`${stylesTable.tableCell} ${styles.regularLinkFont}`}
        style={{
          flexGrow: columns['residentName'].cols,
          textAlign: columns['residentName'].align
        }}
        role="cell"
        onClick={handleResidentLink}
        ref={residentLinkRef}
      >
        {name}
      </div>
      {room ? (
        <div
          className={`${stylesTable.tableCell} ${styles.regularLinkFont}`}
          style={{
            flexGrow: columns['roomNumber'].cols,
            textAlign: columns['roomNumber'].align
          }}
          role="cell"
          onClick={handleRoomLink}
          ref={roomLinkRef}
        >
          {room.number}
        </div>
      ) : (
        <div
          className={stylesTable.tableCell}
          style={{
            flexGrow: columns['roomNumber'].cols,
            textAlign: columns['roomNumber'].align
          }}
          role="cell"
        >
          {'N/A'}
        </div>
      )}
      <div
        className={stylesTable.tableCell}
        style={{
          flexGrow: columns['residentNumber'].cols,
          textAlign: columns['residentNumber'].align
        }}
        role="cell"
      >
        {residentNumber}
      </div>
    </div>
  );
};
