import { axiosWrapper } from 'utils';

import { Resident, ResidentListResponse } from 'Settings';

export const getPaginatedResidents = (
  facilityId: string,
  page: string
) => async () => {
  const initialUrl = `/facilities/${facilityId}/residents`;
  const url = page ? `${initialUrl}?page=${page}` : initialUrl;
  const response = await axiosWrapper<
    ResidentListResponse | { items: Resident[]; nextPage: string }
  >(url);

  return response.data;
};
