import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { PainLevelLine } from '../Lines';
import { PainLevelToolTip } from '../ToolTips';

export const PainLevelChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    painLevel?: number;
    hasWarning?: boolean | undefined;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={[0, 3, 7, 10]}
      data={data}
      tooltip={PainLevelToolTip}
      lineOne={PainLevelLine('#e73827')}
      color="#e73827"
      allowHighlight={allowHighlight}
    />
  );
};
