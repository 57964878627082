import React from 'react';

import { Switch } from 'components';
import { LocationTrackingFilters, useLabelState } from 'Location';

import styles from './HeaderFilters.module.css';

export const HeaderFilters: React.FunctionComponent<{
  filters: LocationTrackingFilters;
  changeFilters: (updatedFilters: LocationTrackingFilters) => void;
}> = ({ filters, changeFilters }) => {
  const label = useLabelState();

  const filtersLabelMap = {
    caregivers: 'Care Team',
    residents: `${label}s`,
    assets: 'Assets'
  };

  const onFilterChange = (key: string) => (enabled: boolean) => {
    changeFilters({
      ...filters,
      [key]: enabled
    });
  };

  const renderFilters = Object.entries(filters).map(([key, value]) => {
    if (value === undefined) {
      return null;
    }

    return (
      <li key={key}>
        <span className={styles.label}>
          {filtersLabelMap[key as keyof LocationTrackingFilters]}
        </span>
        <Switch id={key} isOn={value} onToggle={onFilterChange(key)} />
      </li>
    );
  });

  return <ul className={styles.filtersList}>{renderFilters}</ul>;
};
