import axios from 'axios';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import { getCaregiverByLoginId, useCaregiversStatus } from 'Caregivers';
import {
  AD_HOC_LEAVE_LABEL,
  AdHocActionTypes,
  ChatRoom,
  IAdHocChatDetailsProps,
  Participant,
  useAdHocDispatch,
  useCommunicationContext
} from 'Communication';
import { FetchError } from 'components';
import { useFetcher } from 'hooks';
import { ReactComponent as Chevron } from 'icons/chevron-left.svg';
import { Caregiver } from 'Settings';
import { LoginId } from 'types';

import {
  addParticipantToChatRoom,
  deleteParticipantFromChatRoom,
  getChatRoomById
} from './actions';
import { changeChatRoomName } from './actions/changeChatRoomName.action';
import { AdHocManageMembers } from './AdHocManageMembers';
import { AdHocMemberList } from './AdHocMemberList';
import { isValidAdHocName } from './utils';

import styles from './AdHocChatDetails.module.css';
import { CommunicationActionTypes } from './contexts';

export const AdHocChatDetails: React.FunctionComponent<IAdHocChatDetailsProps> = ({
  chatId,
  chatName,
  facilityId
}) => {
  const [members, setMembers] = useState<Caregiver[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [showManage, setShowManage] = useState<boolean>(false);

  const { loginId: userLoginId } = useAuthState();
  const adHocDispatch = useAdHocDispatch();
  const [{ activeConversation }, commDispatch] = useCommunicationContext();

  const getChatMemo = useMemo(() => {
    return getChatRoomById(chatId);
  }, [chatId]);

  const { data: chatRoom, error: chatError, loading: chatLoading } = useFetcher<
    ChatRoom | undefined
  >(getChatMemo, undefined);

  const handleEditList = () => {
    setShowManage(true);
  };

  useEffect(() => {
    if (chatLoading || chatError || !chatRoom || !facilityId) {
      setError(chatError);
      setFetching(chatLoading);
      return undefined;
    }
    adHocDispatch({
      type: AdHocActionTypes.SET_AD_HOC_NAME,
      payload: chatRoom.description
    });

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const promises = chatRoom.participants
      .filter(loginId => loginId !== userLoginId)
      .map((loginId: LoginId) => {
        return getCaregiverByLoginId(loginId, facilityId, source.token);
      });

    Promise.all(promises)
      .then(setMembers)
      .catch(e => setError(e.data))
      .finally(() => setFetching(false));

    return () => {
      source.cancel('Request cancelled');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatError, chatLoading, chatRoom, facilityId]);

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    adHocDispatch({
      type: AdHocActionTypes.SET_AD_HOC_NAME,
      payload: value
    });
  };

  const handleModifyName = async () => {
    if (!chatName || !isValidAdHocName(chatName) || !chatId) {
      return undefined;
    }

    setFetching(true);
    try {
      await changeChatRoomName(chatId, chatName);
      commDispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: {
          ...activeConversation,
          description: chatName
        }
      });
    } catch (e) {
      setError(e as Error);
    } finally {
      setFetching(false);
    }
  };

  const handleCloseDetails = () => {
    adHocDispatch({
      type: AdHocActionTypes.SET_SELECTED_AD_HOC,
      payload: null
    });
  };

  const handleDismissError = () => {
    setError(null);
  };

  const handleDeselectMember = async (loginId: LoginId) => {
    setFetching(true);
    try {
      await deleteParticipantFromChatRoom(chatId, loginId);
      commDispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: {
          ...activeConversation,
          participants: activeConversation.participants.filter(
            participant => participant.loginId !== loginId
          )
        }
      });
      setMembers(prevMembers => {
        return prevMembers.filter(member => member.loginId !== loginId);
      });
    } catch (e) {
      setError(e as Error);
    } finally {
      setFetching(false);
    }
  };

  const handleSelectMember = async (loginId: LoginId) => {
    setFetching(true);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    try {
      await addParticipantToChatRoom(chatId, loginId);
      const addedMember = await getCaregiverByLoginId(
        loginId,
        facilityId,
        source.token
      );

      commDispatch({
        type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
        payload: {
          ...activeConversation,
          participants: [
            ...activeConversation.participants,
            Object.assign(
              {
                status: undefined,
                relationship: undefined,
                residentId: undefined
              },
              addedMember
            ) as Participant
          ]
        }
      });

      setMembers(prevMembers => {
        return [...prevMembers, addedMember];
      });
    } catch (e) {
      setError(e as Error);
    } finally {
      source.cancel('Request cancelled');
      setFetching(false);
    }
  };

  const handleLeaveAdHoc = async () => {
    setFetching(true);
    try {
      await deleteParticipantFromChatRoom(chatId, userLoginId);
    } catch (error) {
      setError(error as Error);
    } finally {
      setFetching(false);
      adHocDispatch({
        type: AdHocActionTypes.RESET_AD_HOC
      });
    }
  };

  const {
    data: caregiversStatuses,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  return (
    <aside className={styles.chatDetailsContainer} id="AdHocDetails">
      {chatName !== undefined && (
        <header className={styles.chatDetailsHeader}>
          <Chevron className={styles.backButton} onClick={handleCloseDetails} />
          <input
            className={styles.adHocName}
            type="text"
            value={chatName}
            onChange={handleNameChange}
            onBlur={handleModifyName}
            disabled={fetching || Boolean(error)}
          />
        </header>
      )}
      {error && !fetching && (
        <div className={styles.errorContainer}>
          <FetchError
            error={error}
            closable={true}
            onClose={handleDismissError}
          />
        </div>
      )}
      <AdHocMemberList
        members={members}
        onEditList={handleEditList}
        statuses={caregiversStatuses}
        statusLoading={statusLoading}
        statusError={statusError}
      />
      {showManage && (
        <AdHocManageMembers
          showModal={showManage}
          onClose={setShowManage}
          members={members}
          onDeselect={handleDeselectMember}
          onSelect={handleSelectMember}
          facilityId={facilityId}
          statuses={caregiversStatuses}
          statusLoading={statusLoading}
          statusError={statusError}
          userLoginId={userLoginId}
        />
      )}
      <button className={styles.leaveButton} onClick={handleLeaveAdHoc}>
        {AD_HOC_LEAVE_LABEL}
      </button>
    </aside>
  );
};
