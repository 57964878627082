import React from 'react';

import { TextStatus } from 'components';
import { getFullAddress } from 'utils';
import { Idn } from '../types/idn.type';

import stylesTable from 'components/Table/Table.module.css';

export const IdnTableRow: React.FunctionComponent<{
  values: Idn;
  isSelected: boolean;
  selectIdn: (id: string) => void;
}> = ({ values, isSelected, selectIdn }) => {
  const {
    id,
    name,
    address,
    city,
    state,
    zip,
    numberOfFacilities,
    isActive
  } = values;

  const handleClick = () => {
    selectIdn(id);
  };
  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div
      data-testid="idn-table-row"
      className={rowClass}
      onClick={handleClick}
      role="row"
    >
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        {name}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 4 }}
        role="cell"
      >
        {getFullAddress({ address, city, state, zip })}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        {numberOfFacilities ? numberOfFacilities : '0'}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        <TextStatus status={isActive} />
      </div>
    </div>
  );
};
