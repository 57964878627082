import moment from 'moment';

import { emptyMonth, LiquidOutput, weekOfMonth } from 'Residents';

const convertReadings = (week: string, readings: LiquidOutput[]) => {
  const fluidsReadingsPerWeek = readings.filter(
    entry => 'Week ' + weekOfMonth(moment(entry.timestamp)) === week
  );

  const fluids = fluidsReadingsPerWeek.reduce(
    (acc, current) => acc + Number(current.value.count),
    0
  );

  return {
    fluids,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toMonthData = (data: LiquidOutput[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
