import React from 'react';

import { TwoColumnLayout } from 'components';
import { Facility } from 'Settings/types';

import { FacilityDetails } from '../Facilities/FacilityDetails';
import { CaregiverList } from './Caregivers/CaregiversList';
import { DeviceList } from './Devices/DeviceList';
import { RoomResidentList } from './RoomsResidents/RoomResidentList';
import { WardList } from './Wards/WardList';

import overviewStyles from './OverviewSettings.module.css';

export const OverviewSettings: React.FunctionComponent<{
  facility: Facility;
  error: Error | null;
  loading: boolean;
  setActiveTab: (tab: string) => void;
  refetch: () => void;
  onSelectActiveItem: (key: string, id: string) => void;
  activeItems: { [key: string]: string };
}> = ({ facility, refetch, setActiveTab, onSelectActiveItem, activeItems }) => {
  const onSelectItem = (key: string) => (id: string) => {
    onSelectActiveItem(key, id);
  };

  const { room, resident } = activeItems;
  const selectedRoomResidentsId = { room, resident };

  return (
    <>
      <div className={overviewStyles.overviewContainer}>
        <TwoColumnLayout
          left={
            <div className={overviewStyles.overviewRow}>
              <FacilityDetails
                facilityId={facility.id}
                refetchOnChange={refetch}
                inOverview={true}
              />
            </div>
          }
          right={
            <div className={overviewStyles.overviewRow}>
              <WardList
                facility={facility}
                setActiveTab={setActiveTab}
                onSelect={onSelectItem('ward')}
                selectedWardId={activeItems.ward}
                onlyActive={true}
              />
              <CaregiverList
                facility={facility}
                setActiveTab={setActiveTab}
                onSelect={onSelectItem('caregiver')}
                selectedCaregiverId={activeItems.caregiver}
              />
            </div>
          }
        />
        <TwoColumnLayout
          left={
            <div className={overviewStyles.overviewRow}>
              <RoomResidentList
                facility={facility}
                setActiveTab={setActiveTab}
                onSelect={onSelectActiveItem}
                selectedRoomResidentsId={selectedRoomResidentsId}
              />
              <DeviceList
                facility={facility}
                setActiveTab={setActiveTab}
                onSelect={onSelectItem('device')}
                selectedDeviceId={activeItems.device}
              />
            </div>
          }
          right={null}
        />
      </div>
    </>
  );
};
