import React from 'react';
import { Link } from 'react-router-dom';

import { TextStatus } from 'components';
import { getFullAddress } from 'utils';

import { Facility } from '../types/facility.type';

import stylesTable from 'components/Table/Table.module.css';
import styles from 'styles/typography.module.css';

export const FacilityTableRow: React.FunctionComponent<{
  values: Facility;
  selectFacility: (id: string) => void;
  isSelected: boolean;
}> = ({ values, isSelected, selectFacility }) => {
  const {
    id,
    name,
    address,
    city,
    state,
    zip,
    isActive,
    numberOfWards
  } = values;

  const handleClick = () => {
    selectFacility(id);
  };
  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div onClick={handleClick} className={rowClass} role="row">
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        <Link
          to={`/Settings/Facilities/${id}/Overview`}
          className={styles.regularLinkFont}
        >
          {name}
        </Link>
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 4 }}
        role="cell"
      >
        {getFullAddress({ address, city, state, zip })}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        {numberOfWards ? numberOfWards : 0}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        <TextStatus status={isActive} />
      </div>
    </div>
  );
};
