import React from 'react';

import {
  FoodIntake,
  GraphLoadingPlaceholder,
  HistoryFilterTabs,
  HistoryLabel
} from 'Residents';

import { FOOD_INTAKE_COLOR } from '../chartColors.const';
import { FoodIntakeChart } from './FoodIntakeChart';
import { toMonthData } from './toMonthData';
import { toWeekData } from './toWeekData';

import styles from './../Charts.module.css';

export const FoodIntakeHistoryChart: React.FunctionComponent<{
  changeHistoryFilter: (filter: HistoryLabel) => void;
  activeHistoryTab: HistoryLabel;
  readings: FoodIntake[];
  loading: boolean;
}> = ({ changeHistoryFilter, activeHistoryTab, readings, loading }) => {
  return (
    <div className={styles.chartFoodIntake}>
      <HistoryFilterTabs
        onSelect={changeHistoryFilter}
        activeTab={activeHistoryTab}
        color={FOOD_INTAKE_COLOR}
      />
      {!loading && (
        <FoodIntakeChart
          data={
            activeHistoryTab !== 'This Month'
              ? toWeekData(readings)
              : toMonthData(readings)
          }
          allowHighlight={activeHistoryTab !== 'Last Week'}
        />
      )}
      {loading && <GraphLoadingPlaceholder color={FOOD_INTAKE_COLOR} />}
    </div>
  );
};
