import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  getSettingsTabsForRole,
  SETTINGS_TABS_ACCESS,
  useAuthState
} from 'Auth';
import { Tabs } from 'components';
import { useWithoutSocket } from 'hooks';

interface RouterParams {
  activeTab?: string;
}
export const Settings = ({
  match,
  history
}: RouteComponentProps<RouterParams>) => {
  useWithoutSocket();
  const { role } = useAuthState();
  const availableTabs = getSettingsTabsForRole(role) || [''];

  const onTabSelect = (tab: string) => {
    history.push(`/Settings/${tab}`);
  };
  const activeTab = match.params.activeTab || availableTabs[0];

  const renderTabs = () => {
    const tabsForRole =
      role && SETTINGS_TABS_ACCESS[role as keyof typeof SETTINGS_TABS_ACCESS];
    const tabsContent =
      tabsForRole && tabsForRole ? Object.entries(tabsForRole) : null;

    return (
      tabsContent && (
        <Tabs
          tabNames={availableTabs}
          activeTab={activeTab}
          onTabSelect={onTabSelect}
        >
          {tabsContent.map(([tabKey, TabComponent]) => (
            <TabComponent key={tabKey} />
          ))}
        </Tabs>
      )
    );
  };

  return renderTabs();
};
