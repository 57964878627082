import { axiosWrapper } from 'utils';

import { ResidentContact } from 'Settings';

export const createResidentContact = (
  residentId: string,
  residentContact: ResidentContact
) => async () => {
  const url = `/residents/${residentId}/related-contacts`;
  const response = await axiosWrapper(url, 'POST', null, residentContact);
  return response.data;
};
