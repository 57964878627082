import React, { useCallback, useState } from 'react';

import { DetailsPlaceholder, TwoColumnLayout } from 'components';

import {
  ServiceNameMappings,
  unmapServices
} from 'Settings/actions/featureMapping.actions';
import { Facility } from 'Settings/types';

import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { WardDetails } from './WardDetails';
import { IWardFeature, WardFeatures } from './WardFeatures';
import { WardList } from './WardList';

import { editWardFeature } from 'Settings';
import wardStyles from './styles/WardSettings.module.css';

export const WardSettings: React.FunctionComponent<{
  facility: Facility;
  activeWard: string;
}> = ({ facility, activeWard }) => {
  const [selectedWard, setSelectedWard] = useState<MappedWard | null>(null);
  const [modifiedWard, setModifiedWard] = useState<MappedWard | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const selectWard = useCallback((ward: MappedWard) => {
    setLoading(false);
    setError(null);
    setSelectedWard(ward);
  }, []);

  const handleWardChange = (ward: MappedWard) => {
    setModifiedWard(ward);
  };

  const handleFeatureChange = async (
    feature: IWardFeature,
    serviceId: keyof typeof ServiceNameMappings
  ) => {
    if (!selectedWard) {
      return undefined;
    }

    setLoading(true);

    try {
      const newWard: MappedWard = {
        ...selectedWard!,
        services: {
          ...selectedWard!.services,
          [serviceId]: {
            ...selectedWard!.services[serviceId],
            features: {
              ...selectedWard!.services[serviceId].features,
              [feature.id]: {
                id: feature.id,
                name: feature.name,
                isEnabled: feature.value
              }
            }
          }
        }
      };

      const services = unmapServices(newWard.services);
      await editWardFeature(services, facility.id, newWard.id);
      setModifiedWard(newWard);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TwoColumnLayout
        customStyles={wardStyles.settingsPanel}
        customStylesLeft={wardStyles.leftPanel}
        customStylesRight={wardStyles.rightPanel}
        left={
          <WardList
            facility={facility}
            selectWard={selectWard}
            wardId={selectedWard?.id || activeWard}
            lastModifiedWard={modifiedWard}
          />
        }
        right={
          <>
            <div className={wardStyles.panelHeight}>
              {selectedWard && (
                <WardDetails
                  facility={facility}
                  notifyWardChange={handleWardChange}
                  selectedWard={selectedWard}
                  loading={loading}
                  error={error}
                />
              )}
              {!selectedWard && (
                <DetailsPlaceholder
                  message="Please select Ward to display details"
                  title="Ward Details"
                />
              )}
            </div>
            {selectedWard && (
              <WardFeatures
                ward={selectedWard}
                onFeatureChange={handleFeatureChange}
                facilityType={facility.type}
                loading={loading}
                error={error}
              />
            )}
          </>
        }
      />
    </>
  );
};
