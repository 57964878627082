import { useLayoutEffect, useState } from 'react';

const getMeasurements = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    return {
      top: element.offsetTop - 30,
      height: element.clientHeight,
      left: element.offsetLeft,
      width: element.clientWidth
    };
  }
  return {
    top: 0,
    height: 0,
    left: 0,
    width: 0
  };
};

export function useModalMeasurements(
  elementId: string,
  scrollableAncestorId: string,
  isOpen: boolean
): {
  modalOffsetTop: number;
  modalHeight: number;
  scrollableAncestorHeight: number;
  modalOffsetLeft: number;
  modalWidth: number;
} {
  const [scrollableMeasurements, setScrollableMeasurements] = useState(
    getMeasurements(scrollableAncestorId)
  );
  const [modalMeasurements, setModalMeasurements] = useState(
    getMeasurements(elementId)
  );

  useLayoutEffect(() => {
    if (isOpen) {
      const { top, height, left, width } = getMeasurements(elementId);
      const scrollable = document.getElementById(scrollableAncestorId);
      const scrollableProps = scrollable
        ? {
            height: scrollable.clientHeight,
            top: scrollable.offsetTop,
            left: scrollable.offsetLeft,
            width: scrollable.clientWidth
          }
        : {
            height: 0,
            top: 0,
            left: 0,
            width: 0
          };

      const computedOffsetTop = scrollable ? top - scrollable.scrollTop : top;
      const computedOffsetLeft = scrollable
        ? left - scrollable.scrollLeft
        : left;

      setModalMeasurements({
        top: computedOffsetTop,
        height,
        left: computedOffsetLeft,
        width
      });
      setScrollableMeasurements(scrollableProps);
    }
  }, [isOpen, elementId, scrollableAncestorId]);

  return {
    modalOffsetTop: modalMeasurements.top,
    modalOffsetLeft: modalMeasurements.left,
    modalHeight: modalMeasurements.height,
    scrollableAncestorHeight: scrollableMeasurements.height,
    modalWidth: modalMeasurements.width
  };
}
