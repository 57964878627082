import React, { useRef } from 'react';

import { TextStatus } from 'components';

import { Caregiver } from '../../types/caregiver.type';

import stylesTable from 'components/Table/Table.module.css';
import styles from 'styles/typography.module.css';

export const CaregiverTableRow: React.FunctionComponent<{
  values: Caregiver;
  isSelected: boolean;
  setActiveTab: (tab: string) => void;
  onSelect: (id: string) => void;
}> = ({ values, isSelected, onSelect, setActiveTab }) => {
  const linkRef = useRef(null);

  const handleClick = (evt: any) => {
    if (evt.target === linkRef.current && isSelected) {
      return;
    }
    onSelect(values.id);
  };

  const handleLink = () => {
    setActiveTab('Caregivers');
  };

  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  return (
    <div onClick={handleClick} className={rowClass} role="row">
      <div
        className={`${stylesTable.tableCell} ${styles.regularLinkFont}`}
        style={{ flexGrow: 3 }}
        role="cell"
        onClick={handleLink}
        ref={linkRef}
      >
        {values.name}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {values.role}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        <TextStatus status={values.isActive} />
      </div>
    </div>
  );
};
