import { ACTIVITIES_PAGE_SIZE } from 'consts/activitiesSize.constant';
import { ActivityType, BasicActivity } from 'Residents';
import { axiosWrapper } from 'utils';

export const getActivitiesHistoryPaginated = (
  facilityId: string,
  residentId: string,
  type: ActivityType,
  startDate: string,
  endDate: string,
  page?: number
) => async () => {
  const intervalParams = new URLSearchParams({
    start: startDate,
    end: endDate
  });
  const response = await axiosWrapper<BasicActivity[]>(
    `/facilities/${facilityId}/residents/${residentId}/activities/${type}/paginated?page=${page}&size=${ACTIVITIES_PAGE_SIZE}&${intervalParams}`
  );

  return response.data;
};
