import { axiosWrapper } from 'utils';

import { Resident } from '../types/resident.type';

export const getResidentDetails = (
  facilityId: string,
  id: string
) => async () => {
  const response = await axiosWrapper<Resident>(
    `/facilities/${facilityId}/residents/${id}`
  );
  return response.data;
};
