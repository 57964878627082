import { ICommunicationCounts, IMissedCallsResponse } from 'Communication';
import { axiosWrapper } from 'utils';

export const getMissedCalls = async (page: number) => {
  const { data } = await axiosWrapper<IMissedCallsResponse>(
    `/missed-calls?page=${page}`
  );

  const { items, nextPage } = data;

  return {
    items,
    nextPage: nextPage ? Number(nextPage) : undefined
  };
};

export const getCommunicationCounts = async () => {
  const response = await axiosWrapper<ICommunicationCounts>(
    '/communication-counts'
  );

  return response.data;
};
