import React from 'react';

import {
  RoundButton,
  RoundCheckButton,
  RoundCrossButton,
  RoundEditButton,
  RoundPlusButton,
  RoundSearchButton
} from 'components';

export const UIlib: React.FunctionComponent = () => {
  const onButtonClick = () => null;

  return (
    <>
      <RoundButton onClick={onButtonClick} />
      <RoundPlusButton onClick={onButtonClick} />
      <RoundCheckButton onClick={onButtonClick} />
      <RoundCrossButton onClick={onButtonClick} />
      <RoundSearchButton onClick={onButtonClick} />
      <RoundEditButton onClick={onButtonClick} />
    </>
  );
};
