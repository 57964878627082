import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DetailsPlaceholder,
  FetchError,
  LoadingPlaceholder,
  PanelHeader,
  RoundSearchButton,
  SearchHeader,
  WithPermissions
} from 'components';
import { useFetcher } from 'hooks';
import {
  Facility,
  getFacilitiesByQuery,
  getUserAccessToFacilities,
  UserFacilityAccess
} from 'Settings';
import { getFacilityList } from 'Settings/actions';
import {
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH
} from 'Settings/constants';
import { sortByKey } from 'utils';

import { UserFacilitiesAccessList } from './UserFacilitiesAccessList';

import {
  FACILITY_ACCESS_PLACEHOLDER,
  FACILITY_ACCESS_SEARCH_INPUT_NAME,
  FACILITY_ACCESS_TITLE
} from '../constants/index';

import style from './UserFacilitiesAccess.module.css';

export const UserFacilitiesAccess: React.FunctionComponent<{
  user: { id: string; title: string };
}> = ({ user }) => {
  const [currentFacilities, setCurrentFacilities] = useState<Facility[]>([]);
  const [showHeader, setShowHeader] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);
  const [isSearching, setIsSearching] = useState(false);
  const [currentFacilitiesAccess, setCurrentFacilitiesAccess] = useState<
    UserFacilityAccess[]
  >([]);

  const getUserAccessToFacilitiesMemo = useMemo(
    () => user.id && getUserAccessToFacilities(user.id),
    [user.id]
  );

  const {
    data: facilitiesAccess,
    error: facilitiesAccessError,
    loading: facilitiesAccessLoading
  } = useFetcher<UserFacilityAccess[]>(getUserAccessToFacilitiesMemo, []);

  useEffect(() => {
    setCurrentFacilitiesAccess(facilitiesAccess);
  }, [facilitiesAccess]);

  const facilityAccessToggle = useCallback(
    (value: boolean, facilityIds: string[]) => {
      if (value) {
        setCurrentFacilitiesAccess(prevState => [
          ...prevState,
          ...facilityIds.map(id => ({ facilityId: id }))
        ]);
      } else {
        const filteredFacilitiesAccess = currentFacilitiesAccess.filter(
          facility => !facilityIds.includes(facility.facilityId)
        );
        setCurrentFacilitiesAccess(filteredFacilitiesAccess);
      }
    },
    [currentFacilitiesAccess]
  );

  const {
    data: fetchedFacilities,
    error: fetchedFacilitiesError,
    loading: fetchedFacilitiesLoading,
    setRefetch
  } = useFetcher<Facility[]>(getFacilityList, []);

  useEffect(() => {
    setRefetch();
    setShowHeader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  useEffect(() => {
    if (fetchedFacilities.length !== 0) {
      setCurrentFacilities(fetchedFacilities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedFacilities]);

  const error = fetchedFacilitiesError || facilitiesAccessError;
  const loading = fetchedFacilitiesLoading || facilitiesAccessLoading;

  const facilitiesAccessList = useMemo(() => {
    return sortByKey(currentFacilities, 'name').map(({ name, id }) => {
      const hasAccess = currentFacilitiesAccess.some(
        facilityAccessEntry => facilityAccessEntry.facilityId === id
      );
      return {
        name,
        id,
        hasAccess
      };
    });
  }, [currentFacilities, currentFacilitiesAccess]);

  const openSearchHeaderHandler = () => setShowHeader(true);

  const closeSearchHeaderHandler = () => {
    setSearchTerm(DEFAULT_SEARCH_TERM);
    setIsSearching(false);
    setCurrentFacilities(fetchedFacilities);
    setShowHeader(false);
  };

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getFacilitiesByQuery(searchTerm);
    }
  }, [searchTerm]);

  const {
    data: { items: searchFacilityList },
    error: searchError
  } = useFetcher<{ items: Facility[] }>(memoSearchAction, { items: [] });

  const searchHandler = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      setIsSearching(true);
      setSearchTerm(searchValue);
    } else {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setCurrentFacilities(fetchedFacilities);
    }
  };

  useEffect(() => {
    if (!isSearching) {
      return undefined;
    }
    if (!searchError) {
      setCurrentFacilities(searchFacilityList);
    } else {
      setCurrentFacilities([]);
    }
  }, [searchFacilityList, searchError, isSearching]);

  const hasAnyAccess = facilitiesAccessList.length > 0 || isSearching;
  const isSuperAdmin = user.title === 'Super Admin';

  return (
    <WithPermissions
      action={'settings-users:viewFacilityAccess'}
      data={{ access: hasAnyAccess }}
      yes={
        <>
          {user.id && !isSuperAdmin ? (
            <section
              className={classnames(style.wrapper, { [style.error]: error })}
              id="User Facilities Access"
            >
              {!error && (
                <div className={style.header}>
                  {showHeader ? (
                    <SearchHeader
                      searchInputName={`${FACILITY_ACCESS_SEARCH_INPUT_NAME}`}
                      onClose={closeSearchHeaderHandler}
                      searchValue={''}
                      onSearch={searchHandler}
                    />
                  ) : (
                    <PanelHeader title={FACILITY_ACCESS_TITLE}>
                      <RoundSearchButton onClick={openSearchHeaderHandler} />
                    </PanelHeader>
                  )}
                </div>
              )}
              {!error && !loading && !isSuperAdmin && (
                <UserFacilitiesAccessList
                  placeholder={FACILITY_ACCESS_PLACEHOLDER}
                  facilitiesAccessList={facilitiesAccessList}
                  userId={user.id}
                  onFacilityAccessToggle={facilityAccessToggle}
                />
              )}

              {!error && loading && <LoadingPlaceholder fullHeight={false} />}
              {error && !loading && <FetchError error={error} />}
            </section>
          ) : (
            <DetailsPlaceholder
              message={
                isSuperAdmin
                  ? 'A Super Admin has access to all facilities by default'
                  : 'Please select user to manage their access to facilities'
              }
              title="Facilities Access"
              actionType="search"
            />
          )}
        </>
      }
      no={
        <DetailsPlaceholder
          message={'You do not have access to any facility'}
          title="Facilities Access"
          actionType="search"
        />
      }
    />
  );
};
