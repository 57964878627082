import React from 'react';

import { RoundSearchButton } from 'components';

import style from './StaffListHeader.module.css';

export const StaffListHeader: React.FunctionComponent<{
  toggleSearch: () => void;
}> = ({ toggleSearch }) => {
  return (
    <header className={style.header}>
      <span className={style.title}>Care Team</span>
      <RoundSearchButton onClick={toggleSearch} testId="search-test-button" />
    </header>
  );
};
