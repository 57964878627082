import { axiosWrapper } from 'utils';

export const resendVerificationEmailRelatedContact = (
  relatedContactId: string,
  residentId: string
) => async () => {
  const url = `/residents/${residentId}/related-contacts/${relatedContactId}/email-verification`;
  const response = await axiosWrapper(url, 'POST', null, relatedContactId);

  return response.data;
};
