export const maritalStatuses = [
  'Annulled',
  'Divorced',
  'Interlocutory',
  'Legally Separated',
  'Married',
  'Polygamous',
  'Never Married',
  'Domestic Partner',
  'Unmarried',
  'Widowed',
  'Unknown'
];
