import { useEffect } from 'react';
import { useSocketContext } from 'Socket';

export const useWithoutSocket = () => {
  const [{ isConnectionOpen }, { close: closeSocket }] = useSocketContext();

  useEffect(() => {
    if (isConnectionOpen) {
      closeSocket();
    }
  }, [closeSocket, isConnectionOpen]);
};
