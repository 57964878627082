import { RoomAssignment } from 'Caregivers';
import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

export const getRoomAssignments = ({
  workingDay,
  roomId,
  caregiverId,
  wardId
}: {
  workingDay: string;
  roomId?: string;
  caregiverId?: string;
  wardId?: string;
}) => async () => {
  const queryParams = new URLSearchParams({
    workingDay,
    size: MAX_PAGE_SIZE.toString()
  });
  if (roomId) {
    queryParams.set('roomId', roomId);
  }
  if (caregiverId) {
    queryParams.set('caregiverId', caregiverId);
  }
  if (wardId) {
    queryParams.set('wardId', wardId);
  }

  const response = await axiosWrapper<{ items: RoomAssignment[] }>(
    `/room-assignments?${queryParams}`
  );

  return response.data.items;
};
