import React, { useEffect, useState } from 'react';
import { isIEorEdge } from 'utils';
import styles from './Switch.module.css';

export const Switch: React.FunctionComponent<{
  id: string;
  isOn: boolean; // only used as default state
  onToggle?: (enabled: boolean, id?: string) => void;
  register?: any;
  name?: string;
  label?: string;
}> = ({ id, isOn, onToggle, label, register, ...props }) => {
  const [enabled, setEnabled] = useState(isOn);
  const activeClass = enabled ? styles.active : styles.inactive;

  const handleChange = (evt: any) => {
    if (isIEorEdge()) {
      return;
    }
    const { checked } = evt.target;
    setEnabled(checked);
    if (onToggle) {
      onToggle(checked, id);
    }
  };

  const handleClick = (evt: any) => {
    if (!isIEorEdge()) {
      return;
    }
    const { checked } = evt.target;
    // IE provides current input value on first click
    setEnabled(!checked);
    if (onToggle) {
      onToggle(!checked, id);
    }
  };

  useEffect(() => {
    if (isOn !== enabled) {
      setEnabled(isOn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOn]);

  return (
    <>
      {label && (
        <label className={styles.inputLabel}>
          <span className={styles.spanCollapse}>{label}</span>
          <span className={styles.spanFixed}>:</span>
        </label>
      )}
      <div
        className={label ? styles.switchContainerForm : styles.switchContainer}
        data-testid="switch-container"
      >
        <input
          checked={enabled}
          onChange={handleChange}
          onClick={handleClick}
          className={`${styles.switchCheckbox} ${activeClass}`}
          id={`switch-${id}`}
          type="checkbox"
          data-testid="switch-checkbox"
          ref={register}
          {...props}
        />
        <label className={styles.switchLabel} htmlFor={`switch-${id}`}>
          <span
            className={`${styles.switchButton} ${activeClass}`}
            data-testid="switch-btn"
          />
        </label>
      </div>
    </>
  );
};
