import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react';

import { LoginId } from 'types';
import { concatTitle } from 'utils';

import { AD_HOC_NAME_PLACEHOLDER, LIST_TITLE } from './constants';
import { useAdHocProvider } from './contexts';
import { ParticipantDetails } from './ParticipantDetails';
import { AdHocActionTypes, IAdHocCreateDetails } from './types';
import { isValidAdHocName } from './utils';

import styles from './AdHocCreateDetails.module.css';

export const AdHocCreateDetails: FunctionComponent<IAdHocCreateDetails> = ({
  onRemove
}) => {
  const [localList, setLocalList] = useState<JSX.Element[]>([]);

  const { adHocContext, adHocDispatch } = useAdHocProvider();
  const { adHocName, nameEdited, selectedList } = adHocContext;

  useEffect(() => {
    setLocalList(() => {
      return selectedList.map(item => {
        const { loginId, name, role } = item;
        return (
          <ParticipantDetails
            key={loginId}
            loginId={loginId}
            name={name}
            role={role}
            onRemove={handleRemove}
          />
        );
      });
    });

    if (!nameEdited) {
      adHocDispatch({
        type: AdHocActionTypes.SET_AD_HOC_NAME,
        payload: concatTitle(selectedList, 'name', ', ')
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  const handleRemove = (loginId: LoginId) => {
    onRemove(loginId);
  };

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    adHocDispatch({
      type: AdHocActionTypes.SET_AD_HOC_NAME,
      payload: value
    });
  };

  const handleModifyName = () => {
    if (
      isValidAdHocName(adHocName) &&
      concatTitle(selectedList, 'name', ', ') !== adHocName &&
      !nameEdited
    ) {
      adHocDispatch({
        type: AdHocActionTypes.SET_EDITED_NAME,
        payload: true
      });
    }
  };

  return (
    <div className={styles.adHocDetails}>
      <header className={styles.adHocHeader}>
        <input
          className={styles.adHocName}
          type="text"
          placeholder={AD_HOC_NAME_PLACEHOLDER}
          value={adHocName}
          onChange={handleNameChange}
          onBlur={handleModifyName}
        />
        <h3 className={styles.panelTitle}>{LIST_TITLE}</h3>
      </header>
      <ul className={styles.adHocList}>{localList}</ul>
    </div>
  );
};
