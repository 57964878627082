import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { FieldName } from 'react-hook-form/dist/types';

import { RolesEnum, useAuthState } from 'Auth';
import {
  DismissibleError,
  FetchError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  ResidentFetchDropdown,
  WardFetchDropdown
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import {
  Device,
  Facility,
  Room,
  RoomDevice,
  RoomResident,
  RoomWard
} from 'Settings/types';
import { getFormValidationErrors } from 'utils';

import { editRoom as editRoomAction } from '../actions';
import { createRoomDTO } from './createRoomDTO';
import { RoomDeviceInput } from './RoomDeviceInput';

import stylesForm from 'styles/form.module.css';

interface EditRoomFormData {
  number: string;
  ward: RoomWard;
  residents: RoomResident[];
  devices: RoomDevice[];
}

export const EditRoomForm: React.FunctionComponent<{
  facility: Facility;
  toggle: () => void;
  room: Room;
  notifyRoomChange: (room: Room) => void;
}> = ({ toggle, room, notifyRoomChange, facility }) => {
  const [modifiedRoom, setModifiedRoom] = useState<Room>(room);
  const [validationErrors, setValidationErrors] = useState<any[] | null>();

  const [deviceIndexes, setDeviceIndexes] = useState<number[]>(() =>
    room.devices.length ? room.devices.map((_, index) => index) : [0]
  );

  const [wardStatus, setWardStatus] = useState({
    loading: true,
    error: false
  });

  const [residentStatus, setResidentStatus] = useState({
    loading: true,
    error: false
  });

  const [deviceStatus, setDeviceStatus] = useState({
    loading: true,
    error: false
  });

  const defaultRoomDetails = () => {
    const { number: initialNumber, ward, residents, devices } = room;

    return { ward, number: initialNumber, residents, devices };
  };

  const {
    register,
    unregister,
    errors,
    setValue,
    watch,
    getValues,
    formState,
    triggerValidation
  } = useForm<EditRoomFormData>({
    mode: 'onBlur',
    defaultValues: {
      ...defaultRoomDetails()
    },
    submitFocusError: false
  });

  const { setAction, loading: onSaveLoading, error: onSaveError } = usePoster();

  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(onSaveError, onSaveLoading);

  const editRoom = (updatedRoom: Room) => {
    setAction(editRoomAction(updatedRoom));
    setModifiedRoom(updatedRoom);
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isValid = await triggerValidation();
    if (isValid) {
      const roomData = createRoomDTO(getValues(), room.facilityId);
      const { id } = room;

      editRoom({ id, ...roomData });
    }
  };

  const onAddDeviceInput = (newIndex: number) => {
    setDeviceIndexes(prevIndexes => [...prevIndexes, newIndex]);
  };

  const onRemoveDeviceInput = async (
    deviceIndex: number,
    inputName: FieldName<EditRoomFormData>
  ) => {
    unregister(inputName);
    setDeviceIndexes(prevIndexes => [
      ...prevIndexes.filter(index => index !== deviceIndex)
    ]);
    room.devices.splice(deviceIndex, 1);
  };

  // validate device inputs when their number change
  useEffect(() => {
    async function validateDevices() {
      const keysToValidate: any[] = deviceIndexes.map(index => ({
        name: `devices[${index}]`
      }));
      await triggerValidation(keysToValidate);
    }
    if (formState.dirty) {
      validateDevices();
    }
  }, [triggerValidation, deviceIndexes, formState.dirty]);

  const { role } = useAuthState();

  const filterSelectedDevices = (
    devices: Device[],
    currentSelectedDeviceId: string
  ) => {
    // tslint:disable-next-line variable-name
    const { ward, residents, number, ...addedDevices } = getValues();

    const selectedDeviceIds: string[] = addedDevices.devices
      ? addedDevices.devices.map(device => device?.id)
      : Object.values(addedDevices).map(device => device?.id) ?? [];

    selectedDeviceIds.splice(
      selectedDeviceIds.indexOf(currentSelectedDeviceId),
      1
    );

    return devices.filter(device => !selectedDeviceIds.includes(device.id));
  };

  const renderDeviceInputs = () => {
    const renderDeviceInputGroup = (
      deviceIndexKey: number,
      labelOrderIndex: number,
      sourceArray: number[]
    ) => {
      const inputName = `devices[${deviceIndexKey}]`;
      const hasDeleteButton = sourceArray.length > 1;

      return (
        <RoomDeviceInput
          facility={facility}
          name={inputName}
          deviceIndex={deviceIndexKey}
          label={`Device ${labelOrderIndex + 1}`}
          register={register}
          setValue={setValue}
          watch={watch}
          error={(errors as any)[inputName]}
          isLast={sourceArray.length - 1 === labelOrderIndex}
          onAdd={onAddDeviceInput}
          onDelete={hasDeleteButton ? onRemoveDeviceInput : undefined}
          hasDeviceAccess={role === RolesEnum.ROLE_ADMIN}
          defaultValue={room.devices[deviceIndexKey]}
          statusChange={setDeviceStatus}
          filterSelectedDevices={filterSelectedDevices}
        />
      );
    };

    return deviceIndexes.map((indexKey, orderIndex) => (
      <div className={stylesForm.formRow} key={`deviceRow-${indexKey}`}>
        {renderDeviceInputGroup(indexKey, orderIndex, deviceIndexes)}
      </div>
    ));
  };

  useEffect(() => {
    if (!submitted || onSaveLoading || onSaveError) {
      return undefined;
    }
    toggle();
    notifyRoomChange(modifiedRoom);
  }, [
    submitted,
    onSaveLoading,
    onSaveError,
    toggle,
    notifyRoomChange,
    modifiedRoom
  ]);

  useEffect(() => {
    const formValidationErrors = getFormValidationErrors(errors);
    setValidationErrors(formValidationErrors);
  }, [errors]);

  const fetchError =
    residentStatus.error || wardStatus.error || deviceStatus.error;
  const loading =
    residentStatus.loading ||
    wardStatus.loading ||
    deviceStatus.loading ||
    onSaveLoading;

  const formDisplay = loading ? 'none' : 'block';

  const handleChange = () => {
    if (onSaveError) {
      dismissError();
    }
  };

  return (
    <>
      <FormHeader
        title="Edit Room Details"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={onSaveLoading}
        visible={!fetchError}
      />
      {fetchError && (
        <FetchError error={fetchError} closable={true} onClose={toggle} />
      )}
      {!fetchError && (
        <>
          {loading && <LoadingPlaceholder />}
          <form
            onSubmit={handleSubmit}
            className={stylesForm.rightForm}
            style={{ display: formDisplay }}
          >
            <div className={stylesForm.formRow}>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="number"
                  label="Room Number"
                  register={register}
                  validationRules={{ required: true }}
                  hasError={!!errors.number || isErrorShowing}
                  onChange={handleChange}
                />
              </div>
              <WardFetchDropdown
                name="ward"
                value={watch('ward')}
                onChange={setValue}
                register={register}
                hasError={!!errors.ward}
                facilityId={room.facilityId}
                changeStatus={setWardStatus}
                required={true}
                cssClass={stylesForm.selectGroupQuarter}
                defaultValue={defaultRoomDetails().ward}
              />
              <ResidentFetchDropdown
                name="residents"
                value={watch('residents')}
                onChange={setValue}
                register={register}
                hasError={!!errors.residents}
                facility={facility}
                changeStatus={setResidentStatus}
                allowMultiple={true}
                cssClass={stylesForm.selectGroupHalf}
                activeOnly={true}
              />
            </div>
            {renderDeviceInputs()}

            <DismissibleError
              name="Room"
              visible={isErrorShowing}
              error={onSaveError}
              dismiss={dismissError}
            />
          </form>
          {validationErrors && (
            <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
          )}
        </>
      )}
    </>
  );
};
