import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ProfilePlaceholder } from 'components';
import { Caregiver } from 'Settings';
import { splitName } from 'utils';

import { AlarmTypes, useNotificationState } from 'Notifications';

import style from './CaregiverResultCard.module.css';

export const CaregiverResultCard: React.FunctionComponent<{
  caregiver: Caregiver;
  link: string;
  facility: string;
}> = ({ link, caregiver, facility }) => {
  const { id, name, role, photoUrl } = caregiver;
  const { firstName, lastName, initial } = splitName(name);

  const { notifications } = useNotificationState();

  const isHighlighted = notifications.some(
    ({ type, personOfInterest }) =>
      type === AlarmTypes.CAREGIVER_ALARM && personOfInterest?.id === id
  );

  return (
    <Link
      to={link}
      className={classnames(style.card, {
        [style.highlighted]: isHighlighted
      })}
    >
      <ProfilePlaceholder size={90} name={name} photoUrl={photoUrl} />
      <span className={style.name}>
        <span>{`${firstName} ${initial}`}</span>
        <span>{lastName}</span>
      </span>
      <span className={style.role}>{role}</span>
      <span className={style.facility}>{facility}</span>
    </Link>
  );
};
