import React from 'react';

export const Dot: React.FunctionComponent<{
  cx?: number;
  cy?: number;
  lineColor: string;
  backgroundColor: string;
  payload?: {
    hasWarning: string;
    systolicValue: number;
    diastolicValue: number;
  };
}> = props => {
  const {
    cx = 0,
    cy = 0,
    lineColor,
    backgroundColor,
    payload = {
      hasWarning: false
    }
  } = props;

  const display = cy !== null;

  return display ? (
    <svg
      x={cx - 20}
      y={cy - 37.5}
      width="40"
      height="45"
      viewBox="0 0 1738.9 3212"
    >
      <g>
        <circle cx="869.5" cy="2665.5" r="523" fill={lineColor} />
      </g>
      <g>
        <circle cx="869.5" cy="2665.5" r="315.8" fill={backgroundColor} />
      </g>
      <g display={payload.hasWarning ? 'block' : 'none'}>
        <circle cx="869.5" cy="872.1" r="845" fill={'#FFF'} />
        <path
          d="M785.5,1273.1c0-33.4,8.1-56.9,24.4-70.5c16.2-13.5,36.1-20.3,59.6-20.3c22.6,0,42.2,6.8,58.9,20.3
			c16.7,13.6,25.1,37.1,25.1,70.5c0,32.5-8.4,56-25.1,70.5c-16.7,14.4-36.3,21.7-58.9,21.7c-23.5,0-43.4-7.3-59.6-21.7
			C793.6,1329.1,785.5,1305.6,785.5,1273.1z M829.7,1035.4l-30-613.9c-1.1-23.1,17.3-42.5,40.5-42.5h59.8
			c23.1,0,41.6,19.4,40.5,42.5l-29.9,613.9c-1.1,21.6-18.9,38.6-40.5,38.6l0,0C848.6,1074,830.7,1056.9,829.7,1035.4z"
          fill={backgroundColor}
        />
      </g>
    </svg>
  ) : (
    <></>
  );
};
