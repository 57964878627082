import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import {
  DeviceFetchDropdown,
  DismissibleError,
  FetchError,
  FormHeader,
  ImageUpload,
  Input,
  LoadingPlaceholder,
  Select,
  StatusDropdown,
  Switch
} from 'components';
import { caregiverRoles, states } from 'consts';
import { usePoster, useSubmitError } from 'hooks';
import { Caregiver, Facility } from 'Settings/types';
import { getAvailableCaregiverDevices, getFormValidationErrors } from 'utils';

import { editCaregiver } from '../actions';

import { editUserAvatar } from 'UserProfile';

import { createCaregiverDTO } from './createCaregiverDTO';

import stylesForm from 'styles/form.module.css';
interface EditCaregiverFormData {
  name: string;
  number: string;
  isActive: boolean;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  role: string;
  badge: { id: string; name: string } | null;
  phoneExtension: string;
  password: string;
  isManager: boolean;
}

export const EditCaregiverForm: React.FunctionComponent<{
  toggle: () => void;
  caregiver: Caregiver;
  onCaregiverChange: (caregiver: Caregiver) => void;
  facility: Facility;
}> = ({ toggle, caregiver, onCaregiverChange, facility }) => {
  const [modifiedCaregiver, setModifiedCaregiver] = useState(caregiver);
  const [deviceStatus, setDeviceStatus] = useState({
    loading: true,
    error: false
  });
  const [photoUrl, setPhotoUrl] = useState(caregiver.photoUrl);

  const getDefaultValues = () => {
    const { facilityId, id, badge, ...defaults } = caregiver;
    return {
      ...defaults,
      badge: badge ? { id: badge.id, name: badge.name } : null
    };
  };

  const {
    register,
    getValues,
    errors,
    setValue,
    watch,
    triggerValidation
  } = useForm<EditCaregiverFormData>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
    submitFocusError: false
  });
  const { setAction, loading: onSaveLoading, error: onSaveError } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(onSaveError, onSaveLoading);

  const handleEmailChange = () => {
    if (onSaveError) {
      dismissError();
    }
  };

  const saveCaregiver = (caregiverToSave: any) => {
    setModifiedCaregiver(caregiverToSave);
    setAction(editCaregiver(caregiverToSave));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const validationErrors = getFormValidationErrors(errors);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const caregiverData = createCaregiverDTO({ ...getValues() }, caregiver);
      if (photoUrl && photoUrl !== caregiver.photoUrl) {
        caregiverData.photoUrl = photoUrl;
      }
      saveCaregiver(caregiverData);
    }
  };

  const handleAvatarChange = async (image: File | Blob, filename: string) => {
    const imageURL = await editUserAvatar(image, filename);
    setPhotoUrl(imageURL);
  };

  useEffect(() => {
    if (submitted && !onSaveLoading && !onSaveError) {
      onCaregiverChange(modifiedCaregiver);
      toggle();
    }
  }, [
    modifiedCaregiver,
    onCaregiverChange,
    onSaveError,
    onSaveLoading,
    submitted,
    toggle
  ]);

  const roleOptions = caregiverRoles.map(caregiverRole => ({
    value: caregiverRole,
    label: caregiverRole
  }));

  const loading = onSaveLoading || deviceStatus.loading;
  const error = onSaveError || deviceStatus.error;

  return (
    <>
      <FormHeader
        title="Edit Caregiver Details"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={onSaveLoading}
        visible={!deviceStatus.error}
      />
      {loading && <LoadingPlaceholder />}
      {deviceStatus.error && !loading && (
        <FetchError
          error={deviceStatus.error}
          closable={true}
          onClose={toggle}
        />
      )}
      {!deviceStatus.error && (
        <form
          onSubmit={handleSubmit}
          className={classnames({ [stylesForm.hidden]: loading })}
        >
          <div className={stylesForm.formRow}>
            <div
              className={classnames(stylesForm.inputGroupHalf, {
                [stylesForm.inputGroupWithAvatar]: true
              })}
            >
              <ImageUpload
                name={caregiver.name}
                onImageChange={handleAvatarChange}
                photoUrl={photoUrl}
              />
              <Input
                name="name"
                label="Caregiver Name"
                register={register}
                validationRules={{ required: true }}
                hasError={!!errors.name}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Switch
                id={'cargiver-manager-check-id'}
                isOn={caregiver.isManager}
                name="isManager"
                label="Admin"
                register={register}
              />
            </div>
            <div className={stylesForm.selectGroupStatusSmall}>
              <StatusDropdown
                register={register}
                onChange={setValue}
                value={watch('isActive')}
                hasError={!!errors.isActive}
              />
            </div>
          </div>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="address"
                label="Address"
                register={register}
                hasError={!!errors.address}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="city"
                label="City"
                register={register}
                hasError={!!errors.city}
              />
            </div>
            <div className={stylesForm.selectGroupQuarter}>
              <Select
                name="state"
                label="State"
                options={states}
                hasError={!!errors.state}
                register={register}
                onChange={setValue}
                value={watch('state')}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="zip"
                label="Zip"
                register={register}
                hasError={!!errors.zip}
              />
            </div>
          </div>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="email"
                label="E-mail Address"
                register={register}
                hasError={!!errors.email || !!isErrorShowing}
                validationRules={{ required: true }}
                onChange={handleEmailChange}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="homePhone"
                label="Home Phone"
                register={register}
                hasError={!!errors.homePhone}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="mobilePhone"
                label="Cell Phone"
                register={register}
                hasError={!!errors.mobilePhone}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="number"
                label="Employee Number"
                register={register}
                hasError={!!errors.number}
                type="number"
                readonly={true}
              />
            </div>
          </div>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.selectGroupQuarter}>
              <Select
                name="role"
                label="Caregiver Role"
                options={roleOptions}
                hasError={!!errors.role}
                register={register}
                onChange={setValue}
                value={watch('role')}
                required={true}
              />
            </div>
            <DeviceFetchDropdown
              name="badge"
              value={watch('badge')}
              onChange={setValue}
              register={register}
              hasError={!!errors.badge}
              facility={facility}
              changeStatus={setDeviceStatus}
              label="Location Badge"
              cssClass={stylesForm.selectGroupQuarter}
              filterFunction={getAvailableCaregiverDevices}
              defaultValue={getDefaultValues().badge}
            />
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="phoneExtension"
                label="Phone Extension"
                register={register}
                hasError={!!errors.phoneExtension}
              />
            </div>
            <div className={stylesForm.inputGroupQuarter}>
              <Input
                name="password"
                label="Password"
                register={register}
                hasError={!!errors.password}
                type="password"
              />
            </div>
          </div>

          <DismissibleError
            name="Caregiver"
            visible={isErrorShowing}
            error={error}
            dismiss={dismissError}
          />
          {validationErrors && (
            <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
          )}
        </form>
      )}
    </>
  );
};
