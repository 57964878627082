import { axiosWrapper } from 'utils';

export const unassignCaregiverFromRoom = (body: {
  wardId: string;
  caregiverId: string;
  roomId: string;
  workingDay: string;
}) => async () => {
  const response = await axiosWrapper(
    `/room-assignments`,
    'DELETE',
    null,
    body
  );

  return response.data;
};
