import classnames from 'classnames';
import React, { useEffect } from 'react';

import styles from './Input.module.css';

export const Input: React.FunctionComponent<{
  name: string;
  label: string;
  register: any;
  hasError: boolean;
  type?: string;
  validationRules?: { [key: string]: any };
  placeholder?: string;
  testId?: string;
  isLink?: boolean;
  onBlur?: () => void;
  onChange?: (event?: any) => void;
  value?: any;
  readonly?: boolean;
}> = ({
  name,
  label,
  register,
  hasError,
  validationRules = {},
  type = 'text',
  placeholder = label,
  testId = '',
  isLink = false,
  onBlur,
  readonly = false,
  ...props
}) => {
  const isRequired =
    validationRules && validationRules.required
      ? validationRules.required
      : false;

  useEffect(() => {
    const validation = isRequired
      ? {
          required: `${label} field is required.`
        }
      : { isRequired };

    register({ name, type: 'custom' }, validation);
    // only need initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name, validationRules, label]);

  return (
    <>
      <label className={styles.inputLabel}>
        <span className={styles.spanCollapse}>{label}</span>
        <span className={styles.spanFixed}>:{isRequired && ' *'}</span>
      </label>
      <input
        className={classnames(styles.input, {
          [styles.link]: isLink,
          [styles.inputReadonly]: readonly
        })}
        placeholder={type === 'password' ? '**********' : placeholder}
        type={type}
        name={name}
        ref={register(validationRules)}
        data-haserror={hasError}
        data-testid={testId}
        onBlur={onBlur}
        autoComplete="new-password"
        readOnly={readonly}
        required={validationRules.required}
        {...props}
      />
    </>
  );
};
