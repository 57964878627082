import React from 'react';

import { DismissibleError } from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { addVitalReading } from 'Residents';

import { OxygenSaturationReadingForm } from './OxygenSaturationReadingForm';

export const AddOxygenSaturationReading: React.FunctionComponent<{
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
  facilityId: string;
  residentId: string;
}> = ({ isOpen, onCancel, onSave, facilityId, residentId }) => {
  const {
    setAction,
    loading: newReadingLoading,
    error: newReadingError
  } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(newReadingError, newReadingLoading);

  const saveNewReading = (values: any) => {
    setAction(
      addVitalReading(facilityId, residentId, 'OXYGEN_SATURATION', values)
    );
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const cancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    onCancel();
  };

  if (submitted && !newReadingLoading && !newReadingError) {
    onSave();
    setSubmitted(false);
  }

  return (
    <OxygenSaturationReadingForm
      isOpen={isOpen}
      onSave={saveNewReading}
      onCancel={cancel}
      loading={newReadingLoading}
    >
      {newReadingError && (
        <DismissibleError
          name="Oxygen Saturation reading"
          visible={isErrorShowing}
          error={newReadingError}
          dismiss={dismissError}
        />
      )}
    </OxygenSaturationReadingForm>
  );
};
