import React, { useCallback, useEffect, useState } from 'react';

import styles from './Table.module.css';

import { Column } from 'components';

interface TableProps<T> {
  columns: Column[];
  rows: T[];
  placeholder: string;
  render: (row: T) => React.ReactNode;
  listRef?: any;
}
export function Table<T>(props: TableProps<T>) {
  const rows = props.rows.map(row => props.render(row));

  const columns = props.columns.map(column => (
    <div
      className={styles.tableCell}
      style={{ textAlign: column.align, flexGrow: column.cols }}
      key={column.name}
      data-testid="header-cell"
      role="columnheader"
    >
      {column.name}
    </div>
  ));

  const [node, setNode] = useState<HTMLDivElement | null>(null);

  const checkScrollbar = () => {
    if (node) {
      if (node.scrollHeight > node.clientHeight) {
        node.classList.remove(styles.scrollbarSpace);
      } else {
        node.classList.add(styles.scrollbarSpace);
      }
    }
  };

  const additionalRefLogic = useCallback((tableBody: HTMLDivElement) => {
    if (props.listRef) {
      props.listRef(tableBody);
    }
    setNode(tableBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rows.length !== 0) {
      checkScrollbar();
    }
    window.addEventListener('resize', checkScrollbar);
    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  if (rows.length === 0) {
    return <div className={styles.emptyPlaceholder}>{props.placeholder}</div>;
  }

  return (
    <div className={styles.tableContainer} role="table">
      <div className={styles.tableHeader} role="rowgroup">
        <div className={styles.tableRow} role="row">
          {columns}
        </div>
      </div>
      <div
        className={styles.tableBody}
        ref={additionalRefLogic}
        role="rowgroup"
      >
        {rows}
      </div>
    </div>
  );
}
