import { StatusType } from 'Caregivers';
import { ConversationType } from 'Communication';
import { RelatedContactStatus } from 'Settings/types';
import { ChatId, LoginId, UserId } from 'types';

export enum MessagingActionTypes {
  SET_CHAT_ROOMS = 'SET_CHAT_ROOMS',
  ADD_CHAT_ROOM = 'ADD_CHAT_ROOM',
  REMOVE_CHAT_ROOM = 'REMOVE_CHAT_ROOM',
  UPDATE_LAST_MESSAGE_ON_CHAT_ROOM = 'UPDATE_LAST_MESSAGE_ON_CHAT_ROOM',
  UPDATE_MESSAGE_SEEN_ON_CHAT_ROOM = 'UPDATE_MESSAGE_SEEN_ON_CHAT_ROOM',
  RESET_CHAT_ROOMS = 'RESET_CHAT_ROOMS',
  UPDATE_CHAT_ROOMS = 'UPDATE_CHAT_ROOMS',
  UPDATE_CHAT_NAME = 'UPDATE_CHAT_NAME'
}

export enum CallTypes {
  AUDIO = 'audio',
  VIDEO = 'video'
}

export type CallType = CallTypes.AUDIO | CallTypes.VIDEO;

export enum StartCallTypes {
  START_AUDIO_CALL = 'START_AUDIO_CALL',
  START_VIDEO_CALL = 'START_VIDEO_CALL'
}

export type StartCallType =
  | StartCallTypes.START_AUDIO_CALL
  | StartCallTypes.START_VIDEO_CALL;

export enum ChatMessageTypes {
  TEXT = 'text',
  TEXT_MESSAGE = 'text_message',
  FILE_MESSAGE = 'file_message',
  PARTICIPANT_ADDED = 'participant_added_message',
  PARTICIPANT_REMOVED = 'participant_deleted_message',
  MESSAGE_SEEN_MESSAGE = 'message_seen_message'
}

export type ChatMessageType =
  | ChatMessageTypes.TEXT
  | ChatMessageTypes.TEXT_MESSAGE
  | ChatMessageTypes.FILE_MESSAGE
  | ChatMessageTypes.PARTICIPANT_ADDED
  | ChatMessageTypes.PARTICIPANT_REMOVED
  | ChatMessageTypes.MESSAGE_SEEN_MESSAGE;

export interface Participant {
  loginId: string;
  id: string;
  name: string;
  status: StatusType | RelatedContactStatus | undefined; // if it's caregiver
  photoUrl: string | undefined;
  role: string | undefined;
  relationship: string | undefined;
  residentId: string | undefined;
}

export interface Reader {
  loginId: string;
  name: string;
  status: StatusType | RelatedContactStatus | undefined; // if it's caregiver
  photoUrl: string | undefined;
}

export interface ChatMessage {
  id: string;
  author: Participant;
  readBy: Reader[];
  content: any;
  type: ChatMessageType;
  conversationId: string | undefined;
  sentAt: string;
  errorId?: string;
  photoUrl?: string;
  fileUri?: string;
  fileName?: string;
}

export interface ChatContact {
  chatId?: ChatId;
  userId?: UserId;
  loginId?: LoginId;
  name: string;
  role: string | undefined;
  chatRoomType: ConversationType;
  status?: StatusType | RelatedContactStatus;
  photoUrl?: string;
  residentId?: string;
  relationship: string | undefined;
  resident?: {
    id: string;
    name: string;
    room?: {
      id: string;
      number: string;
      ward: {
        id: string;
        name: string;
      };
    };
  };
}
