import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { CaregiverResultsList } from './CaregiverResultsList';
import { ResidentResultsList } from './ResidentResultsList';
import { useSearchState } from './search.context';

import styles from './GlobalSearch.module.css';

export const GlobalSearch: React.FunctionComponent<RouteComponentProps> = ({
  location
}) => {
  const { searching } = useSearchState();

  const { from } = (location && (location.state as any)) || {
    from: { pathname: '/' }
  };

  return searching ? (
    <div className={styles.searchResultsMainContainer}>
      <ResidentResultsList />
      <CaregiverResultsList />
    </div>
  ) : (
    <Redirect to={from} />
  );
};
