import { RelatedContactStatuses } from 'consts';

export const caregiverStatusMap = {
  ON_DUTY: 'available',
  OFF_DUTY: 'unavailable',
  ON_BREAK: 'break',
  [RelatedContactStatuses.ACTIVE]: 'available',
  [RelatedContactStatuses.INACTIVE]: 'unavailable',
  [RelatedContactStatuses.PENDING]: 'unavailable',
  [RelatedContactStatuses.REVOKED]: 'unavailable'
};
