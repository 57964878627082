import React, { useEffect, useRef } from 'react';

import { Room } from 'Settings/types';

import stylesTable from 'components/Table/Table.module.css';
import { VACANT_ROOM_NAME } from 'consts';

export const RoomTableRow: React.FunctionComponent<{
  values: Room;
  isSelected: boolean;
  selectRoom: (roomId: string) => void;
}> = ({ values, isSelected, selectRoom }) => {
  const { id, number: roomNumber, ward, residents, devices } = values;
  const roomWard = ward && ward.name ? ward.name : 'N/A';

  const selectRoomHandler = () => {
    selectRoom(id || '');
  };
  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  const row = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && row.current) {
      row.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={row} className={rowClass} onClick={selectRoomHandler} role="row">
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {roomNumber}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {roomWard}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        {residents && residents.length && residents.find(({ status }) => status)
          ? residents
              .filter(({ status }) => status)
              .map(({ name }) => name)
              .join(', ')
          : VACANT_ROOM_NAME}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        {devices.length}
      </div>
    </div>
  );
};
