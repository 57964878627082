import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';

import { useAuthState } from 'Auth/auth.context';
import { getMissedMessagesCounts } from 'Communication/actions';
import { IMissedMessage, MissedMessagesActionTypes } from 'Communication/types';
import { useFetcher } from 'hooks';
import { ComUpdateTypes, SocketActions, useSocketContext } from 'Socket';
import { useMissedCountState } from './missedCount.context';

interface IMissedMessagesAction {
  type: MissedMessagesActionTypes.SET_MISSED_MESSAGES;
  payload: IMissedMessage[];
}

const MissedMessagesContext = createContext<{
  state: IMissedMessage[];
  // to trigger a manual refecth of missed messages
  refetchMissedMessages: () => void;
}>({
  state: [],
  refetchMissedMessages: () => undefined
});

const missedMessagesReducer = (
  state: IMissedMessage[] = [],
  action: IMissedMessagesAction
) => {
  switch (action.type) {
    case MissedMessagesActionTypes.SET_MISSED_MESSAGES: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const MissedMessagesProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(missedMessagesReducer, []);
  const { loggedUser, loginId } = useAuthState();
  const [{ comUpdate }, { dispatch: socketDispatch }] = useSocketContext();
  const messageType = Boolean(comUpdate) ? comUpdate?.messageType : null;
  const {
    missedCount: { missedTextMessages }
  } = useMissedCountState();

  const [fetchMissedMessages, setFetchMissedMessages] = useState<boolean>(
    false
  );
  const [missedMessages, setMissedMessages] = useState<IMissedMessage[]>([]);
  const [page, setPage] = useState<number | undefined>(0);

  const resetLocalState = () => {
    setMissedMessages([]);
    setPage(0);
    setFetchMissedMessages(true);
  };

  useEffect(() => {
    if (loggedUser && loggedUser.type === 'CAREGIVER') {
      setFetchMissedMessages(true);
    }
  }, [loggedUser]);

  const getMissedMessagesAction = useMemo(() => {
    if (page === undefined) {
      return undefined;
    }

    if (fetchMissedMessages) {
      return getMissedMessagesCounts(page);
    }
  }, [fetchMissedMessages, page]);

  const {
    data: { items: missedMessagesList, nextPage },
    loading: missedMessagesLoading
  } = useFetcher<{
    items: IMissedMessage[];
    nextPage?: number;
  }>(getMissedMessagesAction, {
    items: [],
    nextPage: 0
  });

  useEffect(() => {
    if (!missedMessagesLoading && missedMessagesList.length > 0) {
      setMissedMessages(previous => {
        return [...previous, ...missedMessagesList];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missedMessagesLoading]);

  useEffect(() => {
    if (missedMessagesLoading) {
      return;
    }

    const missedMessageCount = missedMessages.reduce((acc, count) => {
      return (acc += count.messages);
    }, 0);

    if (missedTextMessages === missedMessageCount) {
      return setPage(undefined);
    }

    return setPage(nextPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missedMessagesLoading, missedTextMessages]);

  useEffect(() => {
    if (page === undefined) {
      setFetchMissedMessages(false);
      dispatch({
        type: MissedMessagesActionTypes.SET_MISSED_MESSAGES,
        payload: missedMessages
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (
      (messageType === ComUpdateTypes.TEXT_MESSAGE ||
        messageType === ComUpdateTypes.FILE_MESSAGE ||
        (messageType === ComUpdateTypes.MESSAGE_SEEN_MESSAGE &&
          comUpdate?.loginSenderId === loginId)) &&
      !fetchMissedMessages
    ) {
      socketDispatch({
        type: SocketActions.RESET_SOCKET
      });
      return resetLocalState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType]);

  return (
    <MissedMessagesContext.Provider
      value={{
        state,
        refetchMissedMessages: resetLocalState
      }}
    >
      {children}
    </MissedMessagesContext.Provider>
  );
};

export const useMissedMessagesState = () => {
  const context = useContext(MissedMessagesContext);

  if (context === undefined) {
    throw new Error(
      'useMissedCallsState must be used within a MissedCallsProvider'
    );
  }

  return context;
};
