import React from 'react';

import { Switch } from 'components';

import { CaregiverWardAccess } from '../types';

import styles from 'components/List/List.module.css';

export const WardAccessListItem: React.FunctionComponent<{
  values: CaregiverWardAccess;
  onToggle: (id: string, enabled: boolean) => void;
}> = ({ values, onToggle }) => {
  const { wardId, wardName, hasAccess } = values;
  const handleToggle = (enabled: boolean) => {
    onToggle(wardId, enabled);
  };

  return (
    <li className={styles.rigidListItem}>
      <span className={styles.listItemSpan}>{wardName}</span>
      <Switch isOn={hasAccess} onToggle={handleToggle} id={wardId} />
    </li>
  );
};
