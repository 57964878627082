import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AuthenticatedApp } from 'AuthenticatedApp';
import { ForgotPassword, Login, PasswordReset } from 'Login';

export const App: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route exact={true} path="/login" component={Login} sensitive={true} />
      <Route
        exact={true}
        path="/forgot-password"
        component={ForgotPassword}
        sensitive={true}
      />
      <Route
        exact={true}
        path="/password-reset/:role(STAFF|FAMILY)"
        component={PasswordReset}
        sensitive={true}
      />
      <Route component={AuthenticatedApp} />
    </Switch>
  </Router>
);
