import { MAX_PAGE_SIZE } from 'consts';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { axiosWrapper } from 'utils';

import { Ward, WardListResponse } from '../types/ward.type';
import { mapWard } from './featureMapping.actions';

export const getWardsByFacility = (
  facilityId: string,
  activeOnly: boolean = false
) => async () => {
  const response = await axiosWrapper<WardListResponse>(
    `/facilities/${facilityId}/wards?size=${MAX_PAGE_SIZE}`
  );

  const mappedWards: MappedWard[] = response.data.items.map(mapWard);

  return activeOnly
    ? mappedWards.filter(({ isActive }) => isActive === true)
    : mappedWards;
};

export const getWardsByQuery = async (
  facilityId: string,
  searchQuery: string,
  page: number
) => {
  const response = await axiosWrapper<{ items: Ward[]; nextPage: string }>(
    `/facilities/${facilityId}/wards?page=${page}&wardSearchQuery=${searchQuery}`
  );
  const mappedWards: MappedWard[] = response.data.items.map(mapWard);

  return {
    items: mappedWards,
    nextPage: response.data.nextPage
  };
};
