import React from 'react';
import { Tooltip } from 'recharts';

import style from './Tooltip.module.css';

const Content: React.FunctionComponent<{
  payload?: any;
}> = props => {
  if (props.payload[0]) {
    return (
      <div className={style.dailyStepsTooltip}>
        <span>{`${props.payload[0].payload.label}`}</span>
        <span>
          Daily Steps: <b>{`${props.payload[0].payload.steps}`}</b>
          {` ${props.payload[0].payload.unit}`}
        </span>
      </div>
    );
  }
  return <></>;
};

export const DailyStepsTooltip = <Tooltip content={Content} />;
