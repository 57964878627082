import { FacilitiesSettings, IdnSettings, UserSettings } from 'Settings';
import { getValues } from './auth.utils';

export enum RolesEnum {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_REGULAR_ADMIN = 'ROLE_REGULAR_ADMIN',
  ROLE_CAREGIVER = 'ROLE_CAREGIVER',
  ROLE_CAREGIVER_MANAGER = 'ROLE_CAREGIVER_MANAGER'
}

export const roles = getValues(RolesEnum);

export type UserRole =
  | RolesEnum.ROLE_ADMIN
  | RolesEnum.ROLE_REGULAR_ADMIN
  | RolesEnum.ROLE_CAREGIVER
  | RolesEnum.ROLE_CAREGIVER_MANAGER;

export const SETTINGS_TABS_ACCESS = {
  [RolesEnum.ROLE_ADMIN]: {
    IDNs: IdnSettings,
    Facilities: FacilitiesSettings,
    Users: UserSettings
  },
  [RolesEnum.ROLE_REGULAR_ADMIN]: {
    Facilities: FacilitiesSettings,
    Users: UserSettings
  }
};

export const COMMUNICATION_ROLES: UserRole[] = [
  RolesEnum.ROLE_CAREGIVER,
  RolesEnum.ROLE_CAREGIVER_MANAGER
];

export const getSettingsTabsForRole = (role: UserRole | null) => {
  const rolesWithAccess = Object.keys(SETTINGS_TABS_ACCESS);
  if (!role || !rolesWithAccess.includes(role)) {
    return null;
  }
  const tabs = Object.keys(
    SETTINGS_TABS_ACCESS[role as keyof typeof SETTINGS_TABS_ACCESS]
  );
  return tabs;
};

export const rules = {
  [RolesEnum.ROLE_ADMIN]: {
    static: [
      'settings:view',
      'schedule:all',
      'caregiver-dashboard:editDetails',
      'caregiver-dashboard:changeStatus',
      'caregiver-dashboard:manageResidents',
      'resident-dashboard:manageAssignments',
      'settings-facilities:add',
      'settings-facilities:edit',
      'settings-users:edit',
      'settings-users:viewFacilityAccess'
    ],
    dynamic: {
      'settings-users:manageFacilityAccess': ({
        loggedUser,
        selectedUser
      }: any) => loggedUser && selectedUser.id !== loggedUser.id
    }
  },
  [RolesEnum.ROLE_REGULAR_ADMIN]: {
    static: [
      'settings:view',
      'schedule:all',
      'caregiver-dashboard:editDetails',
      'caregiver-dashboard:changeStatus',
      'caregiver-dashboard:manageResidents',
      'resident-dashboard:manageAssignments'
    ],
    dynamic: {
      'settings-users:edit': ({ user }: any) => user.title !== 'Super Admin',
      'settings-users:manageFacilityAccess': ({
        loggedUser,
        selectedUser
      }: any) => loggedUser && selectedUser.id !== loggedUser.id,
      'settings-users:viewFacilityAccess': ({ access }: any) => access
    }
  },
  [RolesEnum.ROLE_CAREGIVER]: {
    static: ['communication:all'],
    dynamic: {
      'communication-messages:view': ({ loggedUser, caregiverId }: any) =>
        loggedUser && loggedUser.id !== caregiverId,
      'profile:manageStatus': ({ userData }: any) =>
        Object.keys(userData).length > 0,
      'communication-icons:view': ({ loggedUser, itemId, isAvailable }: any) =>
        loggedUser && itemId !== loggedUser.id && isAvailable,
      'communication-buttons:view': ({ isAvailable }: any) => isAvailable,
      'caregiver-dashboard:changeStatus': ({ loggedUser, caregiverId }: any) =>
        loggedUser && caregiverId === loggedUser.id,
      'caregiver-dashboard:manageResidents': ({
        loggedUser,
        caregiverId
      }: any) => loggedUser && caregiverId === loggedUser.id,
      'resident-dashboard:manageAssignments': ({
        loggedUser,
        caregiverId
      }: any) => loggedUser && caregiverId === loggedUser.id
    }
  },
  [RolesEnum.ROLE_CAREGIVER_MANAGER]: {
    static: [
      'schedule:all',
      'communication:all',
      'caregiver-dashboard:editDetails',
      'caregiver-dashboard:changeStatus',
      'caregiver-dashboard:manageResidents',
      'resident-dashboard:manageAssignments'
    ],
    dynamic: {
      'communication-messages:view': ({ loggedUser, caregiverId }: any) =>
        loggedUser && loggedUser.id !== caregiverId,
      'profile:manageStatus': ({ userData }: any) =>
        Object.keys(userData).length > 0,
      'communication-icons:view': ({ loggedUser, itemId, isAvailable }: any) =>
        loggedUser && itemId !== loggedUser.id && isAvailable,
      'communication-buttons:view': ({ isAvailable }: any) => isAvailable
    }
  }
};

export const SOCKET_SUBSCRIPTION_ACCESS = {
  alarm: {
    key: 'AlarmSubscriptionView',
    roles: [
      RolesEnum.ROLE_CAREGIVER,
      RolesEnum.ROLE_CAREGIVER_MANAGER
    ] as UserRole[]
  },
  rtls: {
    key: 'RtlsSubscriptionView',
    roles: [...roles] as UserRole[]
  },
  com: {
    key: 'MessageSubscriptionView',
    roles: [
      RolesEnum.ROLE_CAREGIVER,
      RolesEnum.ROLE_CAREGIVER_MANAGER
    ] as UserRole[]
  }
};
