import React, { useEffect, useMemo } from 'react';

import { Select } from 'components';
import { residentLabels } from 'consts';
import { useFetcher } from 'hooks';
import { Facility, getResidentsByFacility, Resident } from 'Settings';
import {
  formatSelectedValues,
  sortByKey,
  stripIdForDropdownValue
} from 'utils';

import formStyles from 'styles/form.module.css';

export const ResidentFetchDropdown: React.FunctionComponent<{
  name: string;
  value: any;
  onChange: (name: any, value: any, revalidate?: boolean) => void;
  register: any;
  hasError: boolean;
  facility: Facility;
  changeStatus: (status: any) => void;
  required?: boolean;
  isVisible?: boolean;
  allowMultiple?: boolean;
  cssClass?: string;
  label?: string;
  activeOnly?: boolean;
}> = ({
  name,
  value,
  facility,
  hasError,
  changeStatus,
  onChange,
  register,
  required,
  isVisible = true,
  allowMultiple = false,
  label = residentLabels[facility.type],
  activeOnly = false,
  cssClass = formStyles.selectGroupQuarter
}) => {
  const getResidentsMemo = useMemo(() => getResidentsByFacility(facility.id), [
    facility.id
  ]);
  const {
    data: residents,
    error: residentsError,
    loading: residentsLoading
  } = useFetcher<{
    items: Resident[];
  }>(getResidentsMemo, { items: [] });

  const filteredResidents = activeOnly
    ? residents.items.filter(resident => resident.isActive)
    : residents.items;

  const residentOptions = sortByKey(
    filteredResidents.map(({ id, name: residentName, isActive }) => ({
      value: id,
      label: residentName,
      status: isActive
    })),
    'label'
  );

  useEffect(() => {
    changeStatus({ loading: residentsLoading, error: residentsError });
  }, [residentsLoading, residentsError, changeStatus]);

  const handleChange = (
    fieldName: string,
    selected: any,
    revalidate: boolean
  ) => {
    const formattedValue = formatSelectedValues(selected, residentOptions);
    onChange(fieldName, formattedValue, revalidate);
  };

  const style = isVisible ? { display: 'flex' } : { display: 'none' };

  return (
    <div className={cssClass} style={style}>
      <Select
        name={name}
        label={`${label}${allowMultiple ? '(s)' : ''}`}
        options={residentOptions}
        value={stripIdForDropdownValue(value)}
        register={register}
        hasError={hasError}
        onChange={handleChange}
        required={required}
        isMulti={allowMultiple}
      />
    </div>
  );
};
