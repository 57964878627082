import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { List, PanelDetail, WithSimpleFetch } from 'components';
import { useFetcher } from 'hooks';
import { sortByKey } from 'utils';

import { getCaregiverAccessToFacilities, getFacilityList } from '../actions';
import { CaregiverFacilityAccess, Facility } from '../types';

import { FacilityAccessListItem } from './FacilityAccessListItem';
import { WardsAccessList } from './WardsAccessList';

import caregiverStyles from './Caregivers.module.css';

const ListWithFetch = WithSimpleFetch(List);

export const CaregiverWardsAccess: React.FunctionComponent<{
  parentFacility: Facility;
  caregiver: { id: string };
}> = ({ parentFacility, caregiver }) => {
  const [selectedFacility, setSelectedFacility] = useState<
    CaregiverFacilityAccess
  >({
    facilityId: parentFacility.idn ? '' : parentFacility.id,
    facilityName: '',
    wards: 0
  });
  const [facilitiesAccess, setFacilitiesAccess] = useState<
    CaregiverFacilityAccess[]
  >([]);

  const getFacilityListMemo = useCallback(() => {
    if (parentFacility.idn) {
      return getFacilityList(parentFacility.idn.id);
    }
  }, [parentFacility.idn]);
  const {
    data: facilitiesList,
    error: facilitiesError,
    loading: facilitiesLoading
  } = useFetcher<Facility[]>(getFacilityListMemo, []);
  const getFacilitiesAccessMemo = useMemo(
    () => getCaregiverAccessToFacilities(caregiver.id),
    [caregiver]
  );
  const {
    data: facilitiesAccessResponse,
    error: facilitiesAccessError,
    loading: facilitiesAccessLoading
  } = useFetcher<CaregiverFacilityAccess[]>(getFacilitiesAccessMemo, []);

  useEffect(() => {
    let facilities: CaregiverFacilityAccess[] = [];
    const parentIdn = parentFacility.idn;

    if (parentIdn) {
      facilities = facilitiesList.map(({ id, name }) => {
        const facilityAccess = facilitiesAccessResponse.find(
          ({ facilityId }) => facilityId === id
        );
        return {
          facilityId: id,
          facilityName: name,
          wards: facilityAccess ? facilityAccess.wards : 0
        };
      });
    }

    setFacilitiesAccess(facilities);
  }, [facilitiesList, facilitiesAccessResponse, parentFacility.idn]);

  useEffect(() => {
    if (parentFacility.idn) {
      setSelectedFacility({} as CaregiverFacilityAccess);
    }
  }, [caregiver, parentFacility.idn]);

  const onSelectFacility = (facilityId: string) => {
    const selected = facilitiesAccess.find(
      ({ facilityId: id }) => id === facilityId
    );
    if (selected) {
      setSelectedFacility({ ...selected });
    }
  };

  const onAction = () => null;
  const facilitiesFetchLoading = facilitiesLoading || facilitiesAccessLoading;
  const facilitiesFetchError = !!facilitiesError || !!facilitiesAccessError;
  const wardsListDisplay = !(facilitiesFetchLoading || facilitiesFetchError)
    ? 'flex'
    : 'none';

  const handleWardAccessChange = (facilityId: string, hasAccess: boolean) => {
    setFacilitiesAccess(facilities =>
      facilities.map(facility =>
        facility.facilityId === facilityId
          ? {
              ...facility,
              wards: hasAccess ? facility.wards + 1 : facility.wards - 1
            }
          : facility
      )
    );
  };

  const handleWardAccessError = () => {
    setFacilitiesAccess(prev =>
      prev.map(facility =>
        facility.facilityId === selectedFacility.facilityId
          ? { ...selectedFacility }
          : facility
      )
    );
  };

  const renderFacilitiesAccess = (values: any) => (
    <FacilityAccessListItem
      key={values.facilityId}
      values={values}
      onSelect={onSelectFacility}
      isSelected={selectedFacility.facilityId === values.facilityId}
    />
  );

  return (
    <PanelDetail
      title={`${parentFacility.idn ? 'Facilities & ' : ''}Wards Access`}
      onAction={onAction}
      actionType="search"
      hasError={facilitiesFetchError}
    >
      <div className={caregiverStyles.caregiverAccessContainer}>
        {parentFacility.idn ? (
          <>
            <ListWithFetch
              loading={facilitiesFetchLoading}
              error={facilitiesFetchError}
              title="Facility Name"
              items={sortByKey(facilitiesAccess, 'facilityName')}
              placeholder="No data available"
              render={renderFacilitiesAccess}
            />
            <div
              className={caregiverStyles.caregiverAccessContainer}
              style={{ display: wardsListDisplay }}
            >
              <WardsAccessList
                caregiver={caregiver}
                facilityAccess={selectedFacility}
                hasParentIdn={!!parentFacility.idn}
                onAccessChange={handleWardAccessChange}
                onAccessError={handleWardAccessError}
                listPlaceholder="Please select a facility to display the wards"
              />
            </div>
          </>
        ) : (
          <WardsAccessList
            caregiver={caregiver}
            facilityAccess={selectedFacility}
            hasParentIdn={!!parentFacility.idn}
            onAccessChange={handleWardAccessChange}
            onAccessError={handleWardAccessError}
          />
        )}
      </div>
    </PanelDetail>
  );
};
