import moment from 'moment';

import {
  emptyMonth,
  secondsToDecimalHours,
  SittingHours,
  weekOfMonth
} from 'Residents';

const convertReadings = (week: string, readings: SittingHours[]) => {
  const hoursReadingsPerWeek = readings.filter(
    entry => 'Week ' + weekOfMonth(moment(entry.timestamp)) === week
  );

  const hours = hoursReadingsPerWeek.reduce(
    (acc, current) =>
      acc + secondsToDecimalHours(parseFloat(current.value.count)),
    0
  );

  return {
    hours,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toMonthData = (data: SittingHours[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
