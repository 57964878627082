import { CaregiverProfileDetails, UserProfileDetails } from 'UserProfile';
import { axiosWrapper } from 'utils';

export const getLoggedUserProfile = async () => {
  const response = await axiosWrapper<
    UserProfileDetails | CaregiverProfileDetails
  >('/profile');

  return response.data;
};
