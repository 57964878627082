import React from 'react';

import styles from './List.module.css';

interface ListProps<T> {
  title: string;
  items: T[];
  placeholder: string;
  render: (row: T) => React.ReactNode;
  listRef?: any;
}
export function List<T>(props: ListProps<T>) {
  const items = props.items.map(item => props.render(item));

  const EmptyPlaceholder = (
    <div className={styles.emptyPlaceholder}>{props.placeholder}</div>
  );

  return (
    <div className={styles.listContainer}>
      <h4 className={styles.listTitle} data-testid="list-title">
        {props.title}
      </h4>
      {!items.length ? (
        EmptyPlaceholder
      ) : (
        <ul className={styles.listItems} ref={props.listRef}>
          {items}
        </ul>
      )}
    </div>
  );
}
