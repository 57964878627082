import classnames from 'classnames';
import React from 'react';

import { StatusType } from 'Caregivers';
import { Caregiver } from 'Settings';

import { StaffMemberPlaceholder } from './StaffMemberPlaceholder';

import styles from './AssignmentTableEntry.module.css';

export const AssignmentTableEntry: React.FunctionComponent<{
  staffMember: Caregiver;
  isEven: boolean;
  isDraggingOver: boolean;
  status: StatusType;
  draggableRef?: any;
  style?: any;
}> = ({
  staffMember,
  draggableRef,
  isEven,
  isDraggingOver,
  status,
  ...props
}) => {
  return (
    <div
      className={classnames(styles.entry, {
        [styles.draggedOver]: isDraggingOver && !isEven,
        [styles.even]: isEven
      })}
      ref={draggableRef}
      {...props}
    >
      <StaffMemberPlaceholder staffMember={staffMember} status={status} />
    </div>
  );
};
