import axios, {
  AxiosError,
  AxiosRequestConfig,
  CancelToken,
  Method
} from 'axios';

import { getToken } from 'Auth';

const gatewayUrl = process.env.REACT_APP_GATEWAY_API_BASE || '';
export const SOCKET_UPDATES_URL =
  process.env.REACT_APP_WEB_SOCKET_API_BASE || '';

const AUTH_API_ENDPOINT = '/token';

const isConfigPath = (path: string) => new RegExp('/config').test(path);

const isFormData = (data: any) => data instanceof FormData;

function requestHeaders<T>(extraHeaders: any, url: string, data: any) {
  const accessToken = getToken();
  const headers: any = {
    Accept: 'application/json, application/pkcs12',
    'X-Api-Key': process.env.API_KEY
  };

  if (!isFormData(data)) {
    headers['Content-Type'] = 'application/json';
  }
  if (accessToken && url !== AUTH_API_ENDPOINT) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return { ...headers, ...extraHeaders };
}

export async function axiosWrapper<T>(
  url: string,
  method?: Method,
  extraHeaders?: any,
  body?: any,
  responseType?: any,
  params?: URLSearchParams,
  cancelToken?: CancelToken
) {
  const finalUrl = isConfigPath(url) ? url.replace('/config', '') : url;
  const config: AxiosRequestConfig = {
    url: `${gatewayUrl}${finalUrl}`,
    method,
    headers: requestHeaders(extraHeaders, finalUrl, body),
    params,
    cancelToken
  };
  if (responseType) {
    config.responseType = responseType;
  }

  if (finalUrl === AUTH_API_ENDPOINT) {
    config.auth = {
      // basic auth
      username: body.username,
      password: body.password
    };
  }

  if (method !== 'GET' && finalUrl !== AUTH_API_ENDPOINT) {
    config.data = JSON.stringify(body);
  }

  if (isFormData(body)) {
    config.data = body;
  }

  try {
    const response = await axios.request<T>(config);
    return response;
  } catch (error) {
    if ((error as AxiosError).response) {
      throw (error as AxiosError).response;
    } else {
      throw error;
    }
  }
}
