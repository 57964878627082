import React from 'react';

import { RoomDevice } from 'Settings/types';
import { roomDeviceTypes } from 'utils';

import styles from 'Settings/Settings.module.css';

export const RoomDeviceDetailEntry: React.FunctionComponent<{
  deviceNumber: number;
  device: RoomDevice | undefined;
}> = ({ deviceNumber, device }) => (
  <>
    <div className={styles.panelGroupHalf}>
      <div className={styles.panelGroupTitleWrapper}>
        <h4
          className={styles.panelGroupTitle}
        >{`Device ${deviceNumber} ID`}</h4>
        <span className={styles.panelDetailFixedSpan}>:</span>
      </div>
      <span className={styles.panelDetailSpan}>
        {device && device.mac ? device.mac : 'None'}
      </span>
    </div>
    <div className={styles.panelGroupHalf}>
      <div className={styles.panelGroupTitleWrapper}>
        <h4
          className={styles.panelGroupTitle}
        >{`Device ${deviceNumber} Type`}</h4>
        <span className={styles.panelDetailFixedSpan}>:</span>
      </div>
      <span className={styles.panelDetailSpan}>
        {device && device.type ? roomDeviceTypes[device.type] : 'None'}
      </span>
    </div>
  </>
);
