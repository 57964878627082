import React from 'react';

import { ScheduleProvider } from './schedule.context';
import { ScheduleDashboard } from './ScheduleDashboard';

export const Schedule = () => {
  return (
    <ScheduleProvider>
      <ScheduleDashboard />
    </ScheduleProvider>
  );
};
