import classnames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';

import { caregiverStatusMap } from 'Caregivers';
import { Modal, ProfilePlaceholder } from 'components';
import { useFetcher, useModal } from 'hooks';
import { ReactComponent as CloseIcon } from 'icons/crossmark.svg';
import { formatName } from 'utils';

import { getFile } from './actions';
import { ChatPhoto } from './ChatPhoto';
import { MessageError } from './MessageError';
import { ChatMessage, ChatMessageTypes, Reader } from './types';

import styles from './ChatBubble.module.css';

export const ChatBubble: React.FunctionComponent<{
  onCancel: (message: ChatMessage) => void;
  onRetry: (message: ChatMessage) => void;
  message: ChatMessage;
  isCurrentUser: boolean;
  parentId?: string;
  time: string;
  onPicLoad: Dispatch<SetStateAction<number>>;
}> = ({
  message,
  isCurrentUser,
  onCancel,
  onRetry,
  parentId,
  time,
  onPicLoad
}) => {
  const [isCommunication, setIsCommunication] = useState<boolean>(true);
  const messageRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/Communication') {
      setIsCommunication(true);
    } else {
      setIsCommunication(false);
    }
  }, [pathname]);

  const { isShowing: isPhotoModalOpen, toggle: togglePhotoModal } = useModal();

  const cancelMessage = () => onCancel(message);
  const retrySending = () => onRetry(message);

  const { author, content, readBy, type, fileUri } = message;
  const { name, status, photoUrl, role } = author;
  const formattedName = useMemo(() => formatName(name), [name]);

  const avatarBorder = status ? caregiverStatusMap[status] : undefined;

  const messageFileAction = useMemo(() => {
    if (fileUri) {
      return getFile(fileUri);
    }
  }, [fileUri]);

  const { data: fileData } = useFetcher<string>(messageFileAction, '');

  const renderReadReceipts = (readers: Reader[]) =>
    readers.map(
      ({
        name: readerName,
        loginId,
        status: readerStatus,
        photoUrl: readerPhoto
      }) => {
        const circleBorder = readerStatus
          ? caregiverStatusMap[readerStatus]
          : undefined;
        return (
          <ProfilePlaceholder
            key={loginId}
            name={readerName}
            type={circleBorder}
            photoUrl={readerPhoto}
            size={20}
          />
        );
      }
    );

  const onMessageClick = () => {
    if (
      type.toLowerCase() === ChatMessageTypes.FILE_MESSAGE &&
      fileUri &&
      fileData
    ) {
      togglePhotoModal();
    }
  };

  return (
    <>
      <div
        className={classnames(styles.chatMessageContainer, {
          [styles.isCurrentUser]: isCurrentUser
        })}
        ref={messageRef}
      >
        <div
          className={classnames(styles.chatBubble, {
            [styles.error]: !!message.errorId,
            [styles.fileMessage]:
              type.toLowerCase() === ChatMessageTypes.FILE_MESSAGE
          })}
          onClick={onMessageClick}
        >
          {!isCurrentUser && (
            <div className={styles.avatar}>
              <ProfilePlaceholder
                name={name}
                type={avatarBorder}
                photoUrl={photoUrl}
                size={30}
              />
            </div>
          )}
          <div
            className={classnames(styles.chatBubbleInfo, {
              [styles.marginLeft]: !isCurrentUser
            })}
          >
            {type.toLowerCase() === ChatMessageTypes.FILE_MESSAGE ? (
              <ChatPhoto photoUrl={fileData} onFotoLoad={onPicLoad} />
            ) : (
              <span
                className={classnames(styles.chatText, {
                  [styles.marginLeft]: !isCurrentUser
                })}
              >
                {content}
              </span>
            )}
            <div
              className={classnames(styles.senderInfoContainer, {
                [styles.isCurrentUser]: isCurrentUser,
                [styles.alignEnd]: isCurrentUser || !isCommunication
              })}
            >
              {!isCurrentUser && isCommunication && (
                <span className={styles.senderInfo}>
                  {formattedName} - {role}
                </span>
              )}
              <span>{time}</span>
            </div>
          </div>
        </div>

        {readBy.length > 0 && (
          <div className={styles.readReceipts}>
            {renderReadReceipts(readBy)}
          </div>
        )}
      </div>

      <Modal
        isShowing={isPhotoModalOpen}
        toggle={togglePhotoModal}
        parentId={parentId}
      >
        <div className={styles.modalBackdrop}>
          <div className={styles.modalContent}>
            <button className={styles.cancelButton} onClick={onMessageClick}>
              <CloseIcon />
            </button>
            <div
              style={{ backgroundImage: `url('${fileData}')` }}
              className={styles.fullScreenPhoto}
            >
              <img src={fileData} alt="File" />
            </div>
          </div>
        </div>
      </Modal>
      {message.errorId && (
        <MessageError cancel={cancelMessage} retry={retrySending} />
      )}
    </>
  );
};
