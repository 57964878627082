import moment from 'moment';
import React, { useMemo } from 'react';

import {
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelDetail,
  ProfileComponent,
  TextStatus
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useModal } from 'hooks';
import { Facility, getResidentDetails, Resident } from 'Settings';
import { formatWardRoom, getHeight } from 'utils';

import { EditResidentForm } from './EditResidentForm';

import { ReactComponent as Logo } from 'icons/TH-logo.svg';

import stylesModal from 'components/Modal/Modal.module.css';
import groupStyles from 'Settings/Settings.module.css';
import residentStyles from './ResidentSettings.module.css';

export const ResidentDetails: React.FunctionComponent<{
  residentId: string;
  facility: Facility;
  onResidentChange: (resident: Resident) => void;
}> = ({ residentId, facility, onResidentChange }) => {
  const getResidentDetailsMemo = useMemo(
    () => getResidentDetails(facility.id, residentId),
    [residentId, facility.id]
  );
  const {
    data: residentData,
    error: residentError,
    loading: residentLoading,
    setRefetch
  } = useFetcher<Resident>(getResidentDetailsMemo, {} as Resident);

  const { isShowing, toggle } = useModal();

  const onEdit = () => {
    toggle();
  };

  const modalClosed = () => {
    toggle();
    setRefetch();
  };

  const title = `${residentLabels[facility.type]} Details`;
  const numberLabel = `${residentLabels[facility.type]} Number`;

  return (
    <PanelDetail onAction={onEdit} title={title} hasError={!!residentError}>
      {residentId && !residentError && !residentLoading && (
        <>
          <div className={groupStyles.detailRow}>
            <ProfileComponent
              name={residentData.name}
              label={residentLabels[facility.type]}
              type={residentData.gender && residentData.gender.toLowerCase()}
              style={groupStyles.panelGroupHalfRow}
              photoUrl={residentData.photoUrl}
            />
            <div className={groupStyles.panelGroupHalfRow}>
              <div className={groupStyles.panelGroup}>
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>
                    {numberLabel}
                  </span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={groupStyles.panelDetailSpan}>
                  {residentData.number}
                </span>
              </div>

              <div
                className={`${groupStyles.panelGroupStatus} ${groupStyles.detailsPanelStatus}`}
              >
                <div className={groupStyles.panelGroupTitleWrapper}>
                  <span className={groupStyles.panelGroupTitle}>Status</span>
                  <span className={groupStyles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={groupStyles.panelDetailSpan}>
                  <TextStatus status={residentData.isActive} />
                </span>
              </div>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Address</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {residentData.address || 'N/A'}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>City</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {residentData.city || 'N/A'}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>State</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {residentData.state || 'N/A'}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Zip</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {residentData.zip || 'N/A'}
              </span>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  E-mail Address
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpanBlue}>
                {residentData.email || 'N/A'}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Home Phone</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpanBlue}>
                {residentData.homePhoneNumber || 'N/A'}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Cell Phone</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpanBlue}>
                {residentData.cellPhoneNumber || 'N/A'}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  <Logo className={residentStyles.thLogo} /> device
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpanBlue}>
                {(residentData.badge && residentData.badge.name) || 'N/A'}
              </span>
            </div>
          </div>
          <div className={groupStyles.detailRow}>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Gender</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {residentData.gender}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Date of Birth
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {moment(residentData.birthday).format('MM/DD/YYYY')}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>
                  Marital Status
                </span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {residentData.maritalStatus || 'N/A'}
              </span>
            </div>
            <div className={groupStyles.panelGroupQuarter}>
              <div className={groupStyles.panelGroupTitleWrapper}>
                <span className={groupStyles.panelGroupTitle}>Ward - Room</span>
                <span className={groupStyles.panelDetailFixedSpan}>:</span>
              </div>
              <span className={groupStyles.panelDetailSpan}>
                {formatWardRoom(residentData.room)}
              </span>
            </div>
          </div>
        </>
      )}
      {residentLoading && <LoadingPlaceholder />}
      {residentError && !residentLoading && (
        <FetchError error={residentError} />
      )}

      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentRightAlign}>
            <div
              className={stylesModal.modalRightWithVerticalBar}
              style={{
                marginTop: getHeight(title)
              }}
            >
              <EditResidentForm
                toggle={modalClosed}
                resident={residentData}
                onResidentChange={onResidentChange}
                facility={facility}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PanelDetail>
  );
};
