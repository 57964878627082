import React, { useEffect, useState } from 'react';

import { StatusType, StatusTypes } from 'Caregivers';

import styles from './StatusToggle.module.css';

const statusMap = {
  ON_DUTY: 'on',
  OFF_DUTY: 'off',
  ON_BREAK: 'break'
};

export const StatusToggle: React.FunctionComponent<{
  id: string;
  status: StatusType;
  onStatusChange: (currentStatus: StatusType) => void;
  actionDisabled?: boolean;
}> = ({ id, status, onStatusChange, actionDisabled = false }) => {
  const [toggleStatus, setToggleStatus] = useState(status);
  const active = toggleStatus !== StatusTypes.OFF_DUTY;

  const handleChange = () => {
    if (actionDisabled) {
      return;
    }
    onStatusChange(toggleStatus);
  };

  useEffect(() => {
    setToggleStatus(status);
  }, [status]);

  const statusClass = statusMap[toggleStatus];

  return (
    <div className={styles.switchContainer} data-testid="switch-container">
      <input
        checked={active}
        onChange={handleChange}
        className={`${styles.switchCheckbox} ${styles[statusClass]}`}
        id={`switch-${id}`}
        type="checkbox"
        data-testid="switch-checkbox"
      />
      <label
        className={`${styles[statusClass]} ${styles.switchLabel} `}
        htmlFor={`switch-${id}`}
      >
        <span
          className={`${styles.switchButton} ${styles[statusClass]}`}
          data-testid="switch-btn"
        />
      </label>
    </div>
  );
};
