import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal, ProfilePlaceholder } from 'components';
import { useModal } from 'hooks';
import { ReactComponent as CameraIcon } from 'icons/camera.svg';
import { ReactComponent as CloseIcon } from 'icons/crossmark.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';

import { changeColor } from '../../utils/genderColor';
import { DroppableImageUpload } from './DroppableImageUpload';
import { ImagePreview } from './ImagePreview';

import styles from './ImageUpload.module.css';

const extraProps = (size: number | undefined) => {
  if (!size) {
    return {};
  }
  return {
    style: {
      width: size,
      height: size,
      minWidth: size
    }
  };
};

export const ImageUpload: React.FunctionComponent<{
  photoUrl?: string;
  profileType?: string;
  name?: string;
  size?: number;
  onImageChange: (image: File | Blob, filename: string) => void;
  parentId?: string;
}> = ({ photoUrl, profileType, name, size, onImageChange, parentId }) => {
  const [imagePreview, setImagePreview] = useState<FileReader['result']>(null);
  const [filename, setFilename] = useState<string | undefined>(undefined);
  const offsetTop = useRef(0);

  const {
    isShowing: isUploadModalOpen,
    toggle: toggleImageUploadModal
  } = useModal();

  useEffect(() => {
    if (parentId) {
      const parentElement = document.getElementById(parentId);
      offsetTop.current = parentElement
        ? parentElement.getBoundingClientRect().top
        : 0;
    }
  }, [parentId]);

  useEffect(() => {
    if (!isUploadModalOpen) {
      setImagePreview(null);
    }
  }, [isUploadModalOpen]);

  const onClick = () => {
    toggleImageUploadModal();
  };

  const getCroppedImage = useCallback(
    (image: File | Blob) => {
      toggleImageUploadModal();
      if (filename) {
        onImageChange(image, filename);
      }
    },
    [filename, onImageChange, toggleImageUploadModal]
  );

  const removeImage = () => {
    setImagePreview(null);
  };

  const handleImageUpload = (
    imageUrl: string | ArrayBuffer | null,
    nameWithExtension: string
  ) => {
    setImagePreview(imageUrl);
    setFilename(nameWithExtension);
  };

  return (
    <>
      <button
        className={styles.imageUploadContainer}
        onClick={onClick}
        type="button"
        {...extraProps(size)}
      >
        <ProfilePlaceholder
          name={name}
          type={profileType}
          photoUrl={photoUrl}
          size={size}
        />
        {!photoUrl && !name && (
          <span className={changeColor(profileType)}>
            <PlusIcon />
          </span>
        )}
        <span className={styles.imageOverlay}>
          <CameraIcon />
        </span>
      </button>

      <Modal
        isShowing={isUploadModalOpen}
        toggle={toggleImageUploadModal}
        parentId={parentId}
      >
        <div
          className={styles.modalBackdrop}
          style={{ top: -offsetTop.current }}
        >
          <div className={styles.modalContent}>
            <button className={styles.cancelButton} onClick={onClick}>
              <CloseIcon />
            </button>
            <h2 className={styles.uploadTitle}>
              {imagePreview
                ? 'Adjust photo before uploading'
                : 'Upload a new profile picture'}
            </h2>
            {imagePreview ? (
              <ImagePreview
                imageUrl={imagePreview as string}
                onSaveImage={getCroppedImage}
                onRemoveImage={removeImage}
              />
            ) : (
              <DroppableImageUpload onImageLoaded={handleImageUpload} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
