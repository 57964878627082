import moment from 'moment';

import { emptyWeek, secondsToDecimalHours, SittingHours } from 'Residents';

const convertReadings = (day: string, readings: SittingHours[]) => {
  const hoursReadings = readings.find(
    entry => moment(entry.timestamp).format('ddd') === day
  );

  const hours = hoursReadings
    ? secondsToDecimalHours(parseFloat(hoursReadings.value.count))
    : 0;

  return {
    hours,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toWeekData = (data: SittingHours[]) =>
  emptyWeek.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
