import classnames from 'classnames';
import React from 'react';

import { useAuthState } from 'Auth';
import { Switch, WithPermissions } from 'components';

import style from './UserFacilitiesAccessListHeader.module.css';

export const UserFacilitesAccessListHeader: React.FunctionComponent<{
  onToggle: (value: boolean) => void;
  headerStatus: boolean;
  loading: boolean;
  selectedUser: { id: string };
}> = ({ onToggle, headerStatus, loading, selectedUser }) => {
  const { loggedUser } = useAuthState();

  return (
    <div className={classnames(style.listHeader, { [style.loading]: loading })}>
      <span>Facility Name</span>
      <WithPermissions
        action="settings-users:manageFacilityAccess"
        data={{ loggedUser, selectedUser }}
        yes={<Switch isOn={headerStatus} onToggle={onToggle} id={'header'} />}
      />
    </div>
  );
};
