/**
 * This works with irregular sized children
 * @param {HTMLElement[]} arr
 * @param {number} parentWidth
 * @returns {number}
 */
const horizontalChildrenReducer = (
  arr: HTMLElement[],
  parentWidth: number
): number => {
  let tallestChild = 0;
  let totalHeight = 0;
  let lineWidth = 0;

  arr.forEach(child => {
    const style = getComputedStyle(child);
    const marginLeft = Number(
      style.getPropertyValue('margin-left').replace(/px/, '')
    );
    const marginRight = Number(
      style.getPropertyValue('margin-right').replace(/px/, '')
    );
    const marginTop = Number(
      style.getPropertyValue('margin-top').replace(/px/, '')
    );
    const marginBottom = Number(
      style.getPropertyValue('margin-bottom').replace(/px/, '')
    );
    // increment the line width with the width of the next child in line
    lineWidth += child.offsetWidth + marginRight + marginLeft;

    // check wich child is the tallest and store value
    if (child.offsetHeight + marginTop + marginBottom > tallestChild) {
      tallestChild = child.offsetHeight + marginTop + marginBottom;
    }

    // when we get to the end of the line
    // the children are displayed in the line below
    // we need to reset both the width and the height for the next line
    if (
      lineWidth >
      parentWidth - child.offsetWidth - marginLeft - marginRight
    ) {
      totalHeight += tallestChild;
      tallestChild = 0;
      lineWidth = child.offsetWidth + marginRight + marginLeft;
    }
  });

  return totalHeight;
};

export const generateReverseTriggerFunction = (
  node: HTMLElement,
  setter: (b: boolean) => void
) => () => {
  if (node.scrollTop <= 25) {
    return setter(true);
  } else {
    setter(false);
  }
};

export const generateSetTriggerFunction = (
  node: HTMLElement,
  setter: (b: boolean) => void
) => () => {
  if (node.scrollTop + node.clientHeight === node.scrollHeight) {
    return setter(true);
  } else {
    setter(false);
  }
};

/**
 * For this to work we need a wrapper with a set height and overflow: auto | scroll.
 * Inside the wrapper we have a container for the paginated items.
 * @param {HTMLElement} node
 * @returns {boolean}
 */
export const triggerPagination = (node: HTMLElement | null): boolean => {
  if (node === null) {
    return false;
  }

  const container = node?.children[0] as HTMLElement;
  if (!container) {
    return true;
  }
  const items = Array.from(container.children) as HTMLElement[];
  if (node && items.length === 0) {
    return true;
  }
  let trigger = false;

  if (node && items.length > 0) {
    const childrenHeight = horizontalChildrenReducer(
      items,
      container.clientWidth
    );

    if (childrenHeight > node.clientHeight) {
      trigger = false;
    } else {
      trigger = true;
    }
  }

  return trigger;
};
