import React, { useCallback, useEffect, useRef } from 'react';

import { Switch } from 'components';
import { TOGGLE_BUTTON_ID } from 'Home/constants';
import { useHomeViewContext } from 'Home/contexts';
import { HomeViews, SwitchHomeView } from 'Home/types';
import { useLocationContext } from 'Location';

import styles from './DashboardViewOptions.module.css';

export const DashboardViewOptions: React.FunctionComponent = () => {
  const [{ view, topAlign }, homeViewDispatch] = useHomeViewContext();
  const { label } = useLocationContext();

  const panelRef = useRef<HTMLDivElement | null>(null);
  const { current } = panelRef;

  useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      if (
        (evt.target! as any).id === TOGGLE_BUTTON_ID ||
        (panelRef.current !== null &&
          panelRef.current.contains(evt.target as Node))
      ) {
        return;
      }

      homeViewDispatch({
        type: SwitchHomeView.SET_TOP,
        payload: undefined
      });
    };

    if (topAlign !== undefined) {
      window.addEventListener('click', handleClick, false);
    }

    if (topAlign === undefined) {
      window.removeEventListener('click', handleClick, false);
    }

    return () => {
      window.removeEventListener('click', handleClick, false);
    };
  }, [current, homeViewDispatch, topAlign]);

  const handleToggle = useCallback(() => {
    homeViewDispatch({
      type: SwitchHomeView.SWITCH_VIEW
    });
  }, [homeViewDispatch]);

  if (!topAlign) {
    return null;
  }

  return (
    <div
      className={styles.viewOptionsPanel}
      style={{
        top: topAlign - 60 + 'px'
      }}
      ref={panelRef}
    >
      <div className={styles.viewOption}>
        <span>Notifications</span>
        <Switch
          id={HomeViews.ALARMS_ONLY}
          isOn={view === HomeViews.ALARMS_ONLY}
          onToggle={handleToggle}
        />
      </div>
      <div className={styles.viewOption}>
        <span>Notifications & {label}s</span>
        <Switch
          id={HomeViews.ALARMS_WITH_RESIDENTS}
          isOn={view === HomeViews.ALARMS_WITH_RESIDENTS}
          onToggle={handleToggle}
        />
      </div>
    </div>
  );
};
