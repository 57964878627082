import React from 'react';

import {
  formatLogDate,
  formatSeconds,
  HistoryLogsList,
  StaffVisit
} from 'Residents';
import { formatName } from 'utils';

import { HistoryLogEntry } from './HistoryLogEntry';
import { HistoryValue } from './HistoryValue';

import style from './HistoryLogs.module.css';

export const StaffVisitsHistoryLogs: React.FunctionComponent<{
  historyLogs: StaffVisit[];
  scrollRef?: any;
}> = ({ historyLogs, scrollRef }) => {
  const logEntries = historyLogs.map(log => {
    const { timestamp, value } = log;
    const { timestamp: time, date } = formatLogDate(timestamp);
    const { duration, caregiverName } = value;
    const actualValue = formatSeconds(parseFloat(duration));
    const formattedName = formatName(caregiverName);

    return (
      <HistoryLogEntry key={log.timestamp} timestamp={time} date={date}>
        {value.caregiverName && (
          <div className={style.additionalInfo}>
            <span className={style.topSpan}>{formattedName}</span>
            <span className={style.bottomSpan}>{value.caregiverRole}</span>
          </div>
        )}
        <HistoryValue value={actualValue} />
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList scrollRef={scrollRef}>{logEntries}</HistoryLogsList>
  ) : (
    <p className={style.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
