import { User, UserListResponse } from 'Settings';
import { axiosWrapper } from 'utils';

export const getPaginatedUsers = (page?: number) => async () => {
  const initialUrl = `/users`;
  const url = page ? `${initialUrl}?page=${page}` : initialUrl;
  const response = await axiosWrapper<
    UserListResponse | { items: User[]; nextPage: string }
  >(url);

  return response.data;
};
