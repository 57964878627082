import moment from 'moment';

import { BodyWeight, emptyMonth, weekOfMonth } from 'Residents';

const convertReadings = (week: string, readings: BodyWeight[]) => {
  const bodyWeightReadings = readings
    .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
    .map(entry => parseFloat(entry.value.bodyWeight));

  const bodyWeight =
    bodyWeightReadings.length > 0
      ? bodyWeightReadings.reduce((a, b) => a + b, 0) /
        bodyWeightReadings.length
      : undefined;

  return {
    bodyWeight: bodyWeight?.toFixed(1),
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toMonthData = (data: BodyWeight[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
