import React from 'react';

import { RoundCheckButton, RoundCrossButton } from 'components';

import styles from './FormHeader.module.css';

export const FormHeader: React.FunctionComponent<{
  onCancel: () => void;
  onSubmit: (error: any, type?: string) => any;
  submitDisabled: boolean;
  title: string;
  visible?: boolean;
}> = ({ onCancel, title, onSubmit, submitDisabled, visible = true }) => {
  return (
    <div className={visible ? styles.modalHeader : styles.modalHeaderHidden}>
      <title className={styles.modalTitle}>{title}</title>
      <div className={styles.modalButtons}>
        <RoundCrossButton onClick={onCancel} testId="form-cancel-button" />
        <RoundCheckButton
          onClick={onSubmit}
          disabled={submitDisabled}
          testId="form-submit-button"
        />
      </div>
    </div>
  );
};
