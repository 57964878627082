import { axiosWrapper } from 'utils';

export const assignCaregiverToRoom = (body: {
  wardId: string;
  caregiverId: string;
  roomId: string;
  workingDay: string;
}) => async () => {
  const response = await axiosWrapper(`/room-assignments`, 'POST', null, body);

  return response.data;
};
