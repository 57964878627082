export const facilities = [
  'Assisted Living',
  'Care at Home',
  'Clinic',
  'Critical Access',
  'Hospice',
  'Hospital',
  'Independent Living',
  'Mental Health Care',
  'Rehabilitation Center',
  'Retirement Community',
  'Skilled Nursing',
  'Veteran Administration'
];

export const regionalManagerOptions = [
  { value: 'Regional Manager 1', label: 'Regional Manager 1' },
  { value: 'Regional Manager 2', label: 'Regional Manager 2' },
  { value: 'Regional Manager 3', label: 'Regional Manager 3' }
];

export const accountOwnerOptions = [
  { value: 'Account Owner 1', label: 'Account Owner 1' },
  { value: 'Account Owner 2', label: 'Account Owner 2' },
  { value: 'Account Owner 3', label: 'Account Owner 3' }
];

export const distributorNameOptions = [
  { value: 'Distributor Name 1', label: 'Distributor Name 1' },
  { value: 'Distributor Name 2', label: 'Distributor Name 2' },
  { value: 'Distributor Name 3', label: 'Distributor Name 3' }
];
