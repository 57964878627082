import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { LoadingPlaceholder } from 'components';
import { useAddEventListener } from 'hooks';
import { LoginId } from 'types';
import { debounce, generateSetTriggerFunction, triggerPagination } from 'utils';

import { AdHocListItem } from './AdHocListItem';
import { IAdHocListProps } from './types';

import styles from './AdHocList.module.css';

export const AdHocList: FunctionComponent<IAdHocListProps> = ({
  items = [],
  selectedItemsIds,
  onSelect,
  onDeselect,
  loadingItems,
  itemsError,
  refetch,
  customListStyle,
  customItemStyle,
  customInfoStyle,
  customButtonsStyle
}) => {
  const [localList, setLocalList] = useState<JSX.Element[]>([]);

  const [trigger, setTrigger] = useState<boolean>(false);
  const [initialPagination, setInitialPagination] = useState<boolean>(true);
  const wrapper = useRef(null);
  const { current } = wrapper;

  useAddEventListener(
    current,
    'scroll',
    debounce(generateSetTriggerFunction(current!, setTrigger)),
    !initialPagination
  );

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
      refetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    let flag = false;

    if (localList.length > 0) {
      flag = triggerPagination(current!);

      setTrigger(flag);
    }

    if (flag === false) {
      setInitialPagination(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localList]);

  useEffect(() => {
    const list = items.map(item => {
      const isSelected = selectedItemsIds.includes(item.loginId);
      return (
        <AdHocListItem
          key={item.loginId}
          item={item}
          isSelected={isSelected}
          onDeselect={handleDeselectItem}
          onSelect={handleSelectItem}
          customStyle={customItemStyle}
          customInfoStyle={customInfoStyle}
          customButtonsStyle={customButtonsStyle}
          showButtons={true}
        />
      );
    });

    setLocalList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedItemsIds]);

  const handleSelectItem = (loginId: LoginId) => {
    onSelect(loginId);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeselectItem = (loginId: LoginId) => {
    onDeselect(loginId);
  };

  return (
    <div className={styles.wrapper} ref={wrapper}>
      {localList.length > 0 && (
        <ul
          className={classnames(
            {
              [customListStyle as string]: Boolean(customItemStyle)
            },
            styles.adHocUl
          )}
        >
          {localList}
        </ul>
      )}

      {localList.length === 0 && !loadingItems && !itemsError && (
        <h4 className={styles.emptyPlaceholder}>No results!</h4>
      )}
      {loadingItems && !itemsError && (
        <LoadingPlaceholder
          fullHeight={true}
          withTransparency={true}
          customStyles={styles.fetching}
        />
      )}
    </div>
  );
};
