import { AlarmTypes, NotificationDetail } from 'Notifications/types';
import { ResidentId } from 'types';

export const getMarkAndHighlight = (
  notifications: NotificationDetail[],
  id?: ResidentId
): { isHighlighted: boolean; hasExclamationMark: boolean } => {
  const notificationsForCurrentUser: NotificationDetail[] = notifications.filter(
    (note: NotificationDetail) => {
      return note.personOfInterest?.id === id;
    }
  );

  const isHighlighted = notificationsForCurrentUser.some(
    ({ type: notificationType }) =>
      notificationType === AlarmTypes.RESIDENT_BADGE_SOS_ALARM
  );

  const hasExclamationMark = notificationsForCurrentUser.some(
    ({ type: notificationType }) => {
      return (
        notificationType === AlarmTypes.RESIDENT_ALARM ||
        notificationType === AlarmTypes.RESIDENT_WELL_BEING_ALARM
      );
    }
  );

  return {
    isHighlighted,
    hasExclamationMark
  };
};
