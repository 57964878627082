import { StatusType } from 'Caregivers';
import { CaregiverProfileStatus } from 'components';
import { Dispatch, SetStateAction } from 'react';
import { Caregiver } from 'Settings';
import { ChatId, FacilityId, LoginId } from 'types';

export interface IAdHocCaregiver extends Caregiver {
  status: CaregiverProfileStatus;
}

export interface IAdHocListProps {
  items: IAdHocCaregiver[];
  selectedItemsIds: LoginId[];
  onSelect: (loginId: LoginId) => void;
  onDeselect: (loginId: LoginId) => void;
  loadingItems: boolean;
  itemsError: Error | null;
  refetch: Dispatch<React.SetStateAction<boolean>>;
  customListStyle?: string;
  customItemStyle?: string;
  customInfoStyle?: string;
  customButtonsStyle?: string;
}

export interface IAdHocListItemProps {
  item: {
    [key: string]: any;
    loginId: LoginId;
    name: string;
    role: string;
    photoUrl?: string;
    status: CaregiverProfileStatus;
  };
  isSelected: boolean;
  onSelect?: (loginId: LoginId) => void;
  onDeselect?: (loginId: LoginId) => void;
  customStyle?: string;
  customInfoStyle?: string;
  customButtonsStyle?: string;
  showButtons: boolean;
}

export interface IAdHocCreateDetails {
  onRemove: (loginId: LoginId) => void;
}

export interface IAdHocCreateProps {
  onClose: (state?: boolean | undefined) => void;
}

export enum AdHocActionTypes {
  SET_AD_HOC_NAME = 'SET_AD_HOC_NAME',
  SET_EDITED_NAME = 'SET_EDITED_NAME',
  SET_SELECTED_LIST = 'SET_SELECTED_LIST',
  SET_SELECTED_IDS = 'SET_SELECTED_IDS',
  SET_SELECTED_AD_HOC = 'SET_SELECTED_AD_HOC',
  RESET_AD_HOC = 'RESET_AD_HOC'
}

export interface IAdHocContext {
  adHocName: string;
  nameEdited: boolean;
  selectedIds: LoginId[];
  selectedList: IAdHocCaregiver[];
  adHocChatId: ChatId | null;
}

export type AdHocActionType =
  | {
      type: AdHocActionTypes.SET_AD_HOC_NAME;
      payload: string;
    }
  | {
      type: AdHocActionTypes.SET_EDITED_NAME;
      payload: boolean;
    }
  | {
      type: AdHocActionTypes.SET_SELECTED_IDS;
      payload: string[];
    }
  | {
      type: AdHocActionTypes.SET_SELECTED_AD_HOC;
      payload: ChatId | null;
    }
  | {
      type: AdHocActionTypes.SET_SELECTED_LIST;
      payload: IAdHocCaregiver[];
    }
  | {
      type: AdHocActionTypes.RESET_AD_HOC;
    };

export interface IAdHocChatDetailsProps {
  chatId: ChatId;
  chatName: string;
  facilityId: FacilityId;
}

export interface IAdHocManageMembersProps {
  showModal: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  members: Caregiver[];
  onDeselect: (id: LoginId) => void;
  onSelect: (id: LoginId) => void;
  facilityId: FacilityId;
  statuses: {
    [caregiverId: string]: StatusType;
  };
  statusLoading: boolean;
  statusError: Error | null;
  userLoginId: LoginId;
}

export interface IAdHocMemberListProps {
  members: Caregiver[];
  onEditList: () => void;
  statuses: {
    [caregiverId: string]: StatusType;
  };
  statusLoading: boolean;
  statusError: Error | null;
}
