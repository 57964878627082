import moment from 'moment';

import { BloodPressure, emptyMonth, weekOfMonth } from 'Residents';

import { warningCodes } from '../consts/warningCodes.constant';

const convertReadings = (week: string, readings: BloodPressure[]) => {
  const systolicReadings = readings
    .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
    .map(entry => parseInt(entry.value.systolicPressure, 10));

  const diastolicReadings = readings
    .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
    .map(entry => parseInt(entry.value.diastolicPressure, 10));

  const systolicValue =
    systolicReadings.length > 0
      ? Math.round(
          systolicReadings.reduce((a, b) => a + b) / systolicReadings.length
        )
      : undefined;

  const diastolicValue =
    diastolicReadings.length > 0
      ? Math.round(
          diastolicReadings.reduce((a, b) => a + b) / diastolicReadings.length
        )
      : undefined;

  const hasWarning = warningCodes.some(code =>
    readings
      .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
      .map(entry => entry.code)
      .includes(code)
  );

  return {
    systolicValue,
    diastolicValue,
    hasWarning,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toMonthData = (data: BloodPressure[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
