import { axiosWrapper } from 'utils';
import { MappedWard } from './wardsMapping.utils';

import { Ward } from 'Settings/types';
import { mapWard } from './featureMapping.actions';

export const getWardDetails = (
  facilityId: string,
  wardId: string
) => async () => {
  const response = await axiosWrapper<Ward>(
    `/facilities/${facilityId}/wards/${wardId}`
  );
  const mappedWard: MappedWard = mapWard(response.data);

  return mappedWard;
};
