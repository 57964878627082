import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelList,
  Table
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';
import { Facility, Room } from 'Settings';
import {
  DEFAULT_PAGE,
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH,
  ROOM_LIST_PLACEHOLDER,
  ROOM_SEARCH_INPUT_NAME,
  ROOM_TITLE
} from 'Settings/constants';
import { getHeight } from 'utils';

import { getPaginatedRooms, getRoomsByQuery } from '../actions';
import { AddRoomForm } from './AddRoomForm';
import { RoomTableRow } from './RoomTableRow';

import stylesModal from 'components/Modal/Modal.module.css';

export const RoomList: React.FunctionComponent<{
  facility: Facility;
  selectRoom: (roomId: string) => void;
  selectedRoomId: string;
  lastModifiedRoom: Room;
}> = ({ facility, selectRoom, selectedRoomId, lastModifiedRoom }) => {
  const RoomListTableColumns: Column[] = useMemo(
    () => [
      { name: 'Room Number', align: 'left', cols: 2 },
      { name: 'Ward', align: 'left', cols: 2 },
      { name: `${residentLabels[facility.type]}(s)`, align: 'left', cols: 3 },
      { name: 'Devices', align: 'center', cols: 1 }
    ],
    [facility.type]
  );

  const [page, setPage] = useState<string>(DEFAULT_PAGE);
  const [searchedRoomsPage, setSearchedRoomsPage] = useState(DEFAULT_PAGE);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getRoomsMemo = useMemo(() => getPaginatedRooms(facility.id, page), [
    facility.id,
    page
  ]);

  const {
    data: { items: roomsList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Room[];
    nextPage?: string;
  }>(getRoomsMemo, { items: [] });

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getRoomsByQuery(facility.id, searchTerm, searchedRoomsPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedRoomsPage]);

  const {
    data: { items: searchedRoomsList, nextPage: nextPageForSearchedRooms },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{ items: Room[]; nextPage?: string }>(memoSearchAction, {
    items: []
  });

  const scrollAction = () => {
    if (isFetching && roomsList.length && nextPage && !isSearching) {
      setPage(nextPage);
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedRoomsList.length &&
      nextPageForSearchedRooms &&
      isSearching
    ) {
      setSearchedRoomsPage(nextPageForSearchedRooms);
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const { isShowing, toggle } = useModal();

  const modalClosed = () => {
    setRooms([]);
    if (page === DEFAULT_PAGE) {
      setRefetch();
    } else {
      setPage(DEFAULT_PAGE);
    }
    toggle();
  };

  const onAdd = () => {
    toggle();
  };

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedRoomsPage(DEFAULT_PAGE);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setRooms([]);
      setPage(DEFAULT_PAGE);
      setRefetch();
    }
  };

  useEffect(() => {
    if (roomsList.length !== 0) {
      setRooms(prevState => [...prevState, ...roomsList]);
    }
  }, [roomsList]);

  useEffect(() => {
    // get index of selected room in all data set
    const selectedRoomIndex = rooms.some(room => {
      return room.id === selectedRoomId;
    });
    // check if selected room is on the current page
    if (nextPage && selectedRoomId && !selectedRoomIndex) {
      setPage(nextPage!);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  useEffect(() => {
    setRooms(currentRooms => {
      return currentRooms.map(room => {
        if (room.id === lastModifiedRoom.id) {
          return lastModifiedRoom;
        }
        room.residents = room.residents.filter(
          resident =>
            !lastModifiedRoom.residents.some(
              modifiedResident => modifiedResident.id === resident.id
            ),
          room.number
        );

        return room;
      });
    });
  }, [lastModifiedRoom]);

  useEffect(() => {
    if (searchError) {
      return setRooms([]);
    }
    if (searchedRoomsPage > DEFAULT_PAGE) {
      setRooms(prevState => [...prevState, ...searchedRoomsList]);
    } else {
      setRooms(searchedRoomsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedRoomsList, searchError]);

  const render = (rowValues: any) => (
    <RoomTableRow
      key={rowValues.id}
      values={rowValues}
      isSelected={selectedRoomId === rowValues.id}
      selectRoom={selectRoom}
    />
  );

  const isDataAvailable = rooms.length > 0;

  return (
    <PanelList
      title={ROOM_TITLE}
      onAdd={onAdd}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={ROOM_SEARCH_INPUT_NAME}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<Room>
          columns={RoomListTableColumns}
          rows={rooms}
          placeholder={ROOM_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}

      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentLeftAlign}>
            <div
              className={stylesModal.modalWithVerticalBar}
              style={{ marginTop: getHeight('Rooms') }}
            >
              <AddRoomForm
                toggle={modalClosed}
                selectRoom={selectRoom}
                facility={facility}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PanelList>
  );
};
