import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { TimePicker } from 'components';
import { AddReadingForm, timeToSeconds } from 'Residents';

import styles from './ActivityReadingForm.module.css';

export const SleepingHoursReadingForm: React.FunctionComponent<{
  isOpen: boolean;
  onSave: (formData: any) => void;
  onCancel: () => void;
  loading: boolean;
}> = ({ isOpen, onSave, onCancel, loading, children }) => {
  const [time, setTime] = useState<{
    hours: string;
    minutes: string;
    seconds: string;
  }>({ hours: '', minutes: '', seconds: '' });
  const [validationError, setValidationError] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const { hours, minutes, seconds } = time;
    if (!hours || (hours === '00' && minutes === '00' && seconds === '00')) {
      setValidationError(true);
      return;
    }
    const sleepingDuration = `${hours}:${minutes}:${seconds}`;
    const timestamp = moment().toISOString(true);

    onSave({
      count: timeToSeconds(sleepingDuration),
      timestamp,
      type: 'SLEEPING'
    });
  };
  const changeDuration = (
    key: 'hours' | 'minutes' | 'seconds',
    value: string
  ) => {
    setTime(prevTime => {
      const { hours, minutes, seconds } = prevTime;
      return {
        hours: hours || '00',
        minutes: minutes || '00',
        seconds: seconds || '00',
        [key]: value
      };
    });
    setValidationError(false);
  };

  useEffect(() => {
    setTime({
      hours: '',
      minutes: '',
      seconds: ''
    });
    setValidationError(false);
  }, [isOpen]);

  return (
    <AddReadingForm
      isOpen={isOpen}
      onSave={handleSubmit}
      onCancel={onCancel}
      title="Add Sleeping Hours Readings"
      savingDisabled={loading}
    >
      <div
        className={classnames(styles.inputContainer, {
          [styles.validationError]: validationError
        })}
      >
        <TimePicker
          value={time}
          onChange={changeDuration}
          placeholder="number of hours"
        />
      </div>
      {children}
    </AddReadingForm>
  );
};
