import React, { useEffect, useMemo } from 'react';

import { DetailsPlaceholder, FetchError, LoadingPlaceholder } from 'components';
import { useFetcher } from 'hooks';
import { getUserDetails, User } from 'Settings';

import { useUserContext } from './user.context';
import { UserDetailsPanel } from './UserDetailsPanel';

import style from './WithUserDetails.module.css';

export const WithUserDetails: React.FunctionComponent<{
  userId: string;
  onEdit: () => void;
}> = ({ userId, onEdit }) => {
  const getUserDetailsMemo = useMemo(() => getUserDetails(userId), [userId]);
  const [{ userDetails }, dispatch] = useUserContext();

  const { data, error, loading } = useFetcher<User>(
    getUserDetailsMemo,
    {} as User
  );

  useEffect(() => {
    dispatch({
      type: 'UPDATE_USER',
      payload: data
    });
  }, [data, dispatch]);

  useEffect(() => {
    if (!userId) {
      dispatch({ type: 'RESET_USER' });
    }
  }, [userId, dispatch]);

  return (
    <>
      {userId ? (
        <section className={style.userDetailsWrapper} id="User Details">
          {!loading && !error && (
            <UserDetailsPanel user={userDetails} onEditClick={onEdit} />
          )}
          {loading && !error && <LoadingPlaceholder fullHeight={false} />}
          {error && !loading && <FetchError error={error} />}
        </section>
      ) : (
        <DetailsPlaceholder
          message="Please select User to display details"
          title="User Details"
        />
      )}
    </>
  );
};
