import React from 'react';

import {
  DetailBox,
  PanelHeader,
  ProfileComponent,
  RoundEditButton,
  TextStatus,
  WithPermissions
} from 'components';
import { User } from 'Settings';

import styles from './UserDetailsPanel.module.css';

export const UserDetailsPanel: React.FunctionComponent<{
  user: User;
  onEditClick: () => void;
}> = ({ user, onEditClick }) => {
  return (
    <>
      <PanelHeader title="User Details">
        <WithPermissions
          action="settings-users:edit"
          data={{ user }}
          yes={<RoundEditButton onClick={onEditClick} />}
        />
      </PanelHeader>

      <div className={styles.detailsContent}>
        <div className={styles.detailRow}>
          <ProfileComponent
            name={user.name}
            photoUrl={user.photoUrl}
            label="Full"
            style={styles.half}
          />
          <div className={styles.quarter}>
            <DetailBox label="Title" value={user.title} />
          </div>
          <div className={styles.quarterRight}>
            <DetailBox
              label="Status"
              value={<TextStatus status={user.isActive} />}
            />
          </div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.quarter}>
            <DetailBox label="Address" value={user.address} />
          </div>
          <div className={styles.quarter}>
            <DetailBox label="City" value={user.city} />
          </div>
          <div className={styles.quarter}>
            <DetailBox label="State" value={user.state} />
          </div>
          <div className={styles.quarter}>
            <DetailBox label="Zip" value={user.zip} />
          </div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.quarter}>
            <DetailBox
              label="E-mail Address"
              value={user.email}
              isLink={true}
            />
          </div>
          <div className={styles.quarter}>
            <DetailBox
              label="Home Phone"
              value={user.homePhone}
              isLink={true}
            />
          </div>
          <div className={styles.quarter}>
            <DetailBox
              label="Cell Phone"
              value={user.mobilePhone}
              isLink={true}
            />
          </div>
          <div className={styles.quarter}>
            <DetailBox label="Password" value={'**********'} />
          </div>
        </div>
      </div>
    </>
  );
};
