import React, { useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import {
  CallTypes,
  ChatRoom,
  CommunicationActionTypes,
  ConversationTypes,
  createChatRoomCall,
  initiateCall,
  StartCallType,
  StartCallTypes,
  useCommunicationDispatch
} from 'Communication';
import {
  DetailBox,
  PanelHeader,
  ProfileComponent,
  RoundAudioCallButton,
  RoundEditButton,
  RoundVideoCallButton,
  Snackbar,
  WithPermissions
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { useLocationState } from 'Location';
import { formatName, isIEorEdge } from 'utils';

import { caregiverStatusMap } from './status.constant';
import { StaffMemberDetail } from './types';
import { formatLocation } from './utils';

import styles from './CaregiverDetailsPanel.module.css';

export const CaregiverDetailsPanel: React.FunctionComponent<{
  caregiver: StaffMemberDetail;
  onEditClick: () => void;
  activeChat: ChatRoom;
  callCount: { videoCount?: number; audioCount?: number };
}> = ({ caregiver, onEditClick, activeChat, callCount }) => {
  const [callType, setCallType] = useState<StartCallType>(
    StartCallTypes.START_AUDIO_CALL
  );
  const [activeChatId, setActiveChatId] = useState(activeChat.id);
  const { loggedUser, loginId } = useAuthState();
  const dispatch = useCommunicationDispatch();
  const { selectedWardDetails } = useLocationState();

  const { setAction, error: sendError, loading: onSaveLoading } = usePoster();
  const {
    submitted,
    setSubmitted,
    dismissError,
    isErrorShowing
  } = useSubmitError(sendError, onSaveLoading);

  const { name, role, photoUrl, status } = caregiver;
  const type = caregiverStatusMap[status];

  const formattedName = useMemo(() => formatName(name), [name]);

  const calleeDetails = useMemo(
    () => ({
      name: formattedName,
      title: role as string,
      type,
      photoUrl
    }),
    [formattedName, role, type, photoUrl]
  );

  const isAvailable =
    caregiverStatusMap[caregiver.status] &&
    (caregiverStatusMap[caregiver.status] === 'available' ||
      caregiverStatusMap[caregiver.status] === 'break');

  const startVideoCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (activeChatId) {
      setAction(initiateCall(activeChatId, CallTypes.VIDEO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${caregiver.name} chat room`;

      const id = await createChatRoomCall(
        [loginId, caregiver.loginId],
        ConversationTypes.CAREGIVER,
        CallTypes.VIDEO,
        description
      );

      setActiveChatId(id);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    }
  };

  const startAudioCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (activeChatId) {
      setAction(initiateCall(activeChatId, CallTypes.AUDIO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${caregiver.name} chat room`;

      const id = await createChatRoomCall(
        [loginId, caregiver.loginId],
        ConversationTypes.CAREGIVER,
        CallTypes.AUDIO,
        description
      );

      setActiveChatId(id);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    }
  };

  useEffect(() => {
    if (!onSaveLoading && !sendError && submitted && activeChatId) {
      dispatch({
        type: callType,
        payload: calleeDetails
      });
      setSubmitted(false);
    }
  }, [
    onSaveLoading,
    sendError,
    submitted,
    setSubmitted,
    callType,
    calleeDetails,
    activeChatId,
    dispatch
  ]);

  return (
    <>
      <PanelHeader title="Caregiver Details">
        <WithPermissions
          action="caregiver-dashboard:editDetails"
          yes={<RoundEditButton onClick={onEditClick} />}
        />
        {selectedWardDetails?.services.secureCommunication.features.scMessaging
          .isEnabled && (
          <WithPermissions
            action="communication-icons:view"
            data={{ loggedUser, itemId: caregiver.id, isAvailable }}
            yes={
              <>
                {selectedWardDetails?.services.secureCommunication.features
                  .scVoice.isEnabled && (
                  <RoundAudioCallButton
                    onClick={startAudioCall}
                    missedCalls={callCount.audioCount}
                  />
                )}
                {selectedWardDetails?.services.secureCommunication.features
                  .scVideo.isEnabled && (
                  <RoundVideoCallButton
                    onClick={startVideoCall}
                    missedCalls={callCount.videoCount}
                  />
                )}
              </>
            }
          />
        )}
      </PanelHeader>

      <div className={styles.detailsContent}>
        <div className={styles.detailRow}>
          <ProfileComponent
            name={formattedName}
            label="Caregiver"
            photoUrl={caregiver.photoUrl}
            style={styles.thirdCentered}
            type={caregiverStatusMap[caregiver.status]}
          />
          <div className={styles.thirdCentered}>
            <DetailBox label="Caregiver Role" value={caregiver.role} />
          </div>
          {selectedWardDetails?.services.rtls.features.rtlsStaff.isEnabled && (
            <div className={styles.thirdCentered}>
              <DetailBox
                label="Current Location"
                value={
                  caregiver.location
                    ? formatLocation({
                        room: caregiver.location.room,
                        ward: caregiver.location.ward
                      })
                    : 'Unknown'
                }
              />
            </div>
          )}
        </div>
        <div className={styles.detailRow}>
          <div className={styles.third}>
            <DetailBox
              label="E-mail Address"
              value={caregiver.email}
              isLink={true}
            />
          </div>
          <div className={styles.third}>
            <DetailBox
              label="Home Phone"
              value={caregiver.homePhone}
              isLink={true}
            />
          </div>
          <div className={styles.third}>
            <DetailBox
              label="Cell Phone"
              value={caregiver.mobilePhone}
              isLink={true}
            />
          </div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.third}>
            <DetailBox label="Employee Number" value={caregiver.number} />
          </div>
          {selectedWardDetails?.services.rtls.features.rtlsStaff.isEnabled && (
            <div className={styles.third}>
              <DetailBox
                label="Location Badge"
                value={caregiver.badge && caregiver.badge.name}
                isLink={true}
              />
            </div>
          )}
          <div className={styles.third}>
            <DetailBox
              label="Phone Extension"
              value={caregiver.phoneExtension}
              isLink={true}
            />
          </div>
        </div>
      </div>
      <Snackbar
        message={'Cannot initiate call. Please try again.'}
        isOpen={isErrorShowing}
        onClose={dismissError}
      />
    </>
  );
};
