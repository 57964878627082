import React from 'react';

import style from './PageNotFound.module.css';

export const PageNotFound: React.FunctionComponent<{}> = () => {
  return (
    <div className={style.container}>
      <h1>404</h1>
      <h2>Page not found</h2>
      <h3>The page you’re looking for doesn’t exist or has been moved.</h3>
    </div>
  );
};
