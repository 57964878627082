import { axiosWrapper } from 'utils';

export const addActivityReading = (
  facilityId: string,
  residentId: string,
  body: any
) => async () => {
  const response = await axiosWrapper(
    `/facilities/${facilityId}/residents/${residentId}/activities`,
    'POST',
    null,
    body
  );

  return response.data;
};
