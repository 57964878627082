import Compress from 'compress.js';

const getMimeType = (headerString: string) => {
  let type;
  switch (headerString) {
    case '89504e47':
      type = 'image/png';
      break;
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
      type = 'image/jpeg';
      break;
    default:
      type = 'unknown';
      break;
  }
  return type;
};

// checking if a file has a supported MIME type by  comparing the first 4 bytes against magic numbers that accurately identify a file's type
export const getBLOBFileHeader = (
  file: File,
  callback: (header: string) => void
) => {
  const fileReader = new FileReader();
  fileReader.onloadend = (e: any) => {
    const byteArray = new Uint8Array(e.target.result);
    let header = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < byteArray.length; i++) {
      header += byteArray[i].toString(16);
    }
    return callback(header);
  };

  fileReader.readAsArrayBuffer(file.slice(0, 4)); // only need to read the first 4 bytes
};

export const isAcceptedFileType = (header: string) =>
  getMimeType(header) !== 'unknown';

export const checkFileType = (
  file: File,
  callback: (isValid: boolean) => void
) => {
  getBLOBFileHeader(file, (header: string) => {
    callback(isAcceptedFileType(header));
  });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

export const resizeFile = async (file: File) => {
  const resizedFile = await new Compress().compress([file], {
    size: 2, // max 2 MB
    quality: 1
  });
  const img = resizedFile[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedImg = Compress.convertBase64ToFile(base64str, imgExt) as Blob;

  const newFile = new File([resizedImg], file.name, { type: imgExt });
  return newFile;
};
