import React from 'react';

import styles from './BackButton.module.css';

import { ReactComponent as Chevron } from 'icons/chevron-left.svg';

export const BackToggleButton: React.FunctionComponent<{
  title: string;
  onClick: (e: any) => void;
}> = ({ title, onClick }) => (
  <div className={styles.backButton}>
    <Chevron
      className={styles.chevron}
      data-testid="back-test-button"
      onClick={onClick}
    />
    <span className={styles.title}>{title}</span>
  </div>
);
