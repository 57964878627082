export const PRIMARY_COLOR = '#2a9ad6';
export const ERROR_COLOR = '#ed6a5f';
export const GREY_COLOR = '#E0E0E0';
export const DARK_GREY_COLOR = '#57565a';
export const GREEN_COLOR = '#8ec738';
export const WHITE_COLOR = '#ffffff';

export const headerDropdownStyles: {
  [key: string]: { [key: string]: any };
} = {
  control: {
    borderBottom: 'none',
    fontSize: '15px',
    height: '100%',
    padding: '0',
    alignItems: 'center',
    '&:hover': {
      borderBottom: 'none'
    },
    '& > div:first-of-type': {
      justifyContent: 'flex-end'
    }
  },
  dropdownIndicator: {
    backgroundPosition: 'center bottom 2px',
    height: '25px'
  },
  container: {
    flex: '1',
    height: '33px',
    padding: '3px 0 0 0'
  },
  placeholder: { height: '19px', margin: 0, paddingRight: '5px', right: 0 },
  singleValue: { height: '19px', margin: 0, paddingRight: '5px', right: 0 },
  menu: { marginTop: '8px' }
};
