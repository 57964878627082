export const regions = [
  {
    label: 'International',
    value: 'I'
  },
  {
    label: 'Midwest',
    value: 'MW'
  },
  {
    label: 'Northeast',
    value: 'NE'
  },
  {
    label: 'Southeast',
    value: 'SE'
  },
  {
    label: 'Southwest',
    value: 'SW'
  },
  {
    label: 'West',
    value: 'W'
  }
];
