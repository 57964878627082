import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { useCaregiversStatus } from 'Caregivers';
import {
  AccessMessage,
  FetchError,
  LoadingPlaceholder,
  Snackbar
} from 'components';
import { usePoster } from 'hooks';
import { useLocationState } from 'Location';

import { assignCaregiverToWard, unassignCaregiverFromWard } from './actions';
import { useScheduleContext } from './schedule.context';
import { ScheduleCalendar } from './ScheduleCalendar';
import { StaffAssignments } from './StaffAssignments';
import { StaffList } from './StaffList';

import style from './ScheduleDashboard.module.css';

export const ScheduleDashboard: React.FunctionComponent<{}> = () => {
  const [selectedDay, setSelectedDay] = useState(moment().toDate());
  const [dragDisabled, setDragDisabled] = useState(false);

  const {
    facility,
    ward: wardId,
    filtersLoading,
    filtersError
  } = useLocationState();

  const [{ assignedCaregivers }, dispatch] = useScheduleContext();

  const {
    setAction,
    error: assignmentError,
    resetError: resetAssignmentError
  } = usePoster();

  const {
    data: caregiversStatusMap,
    loading: statusLoading,
    error: statusError,
    changeCaregiverStatus,
    statusChangeError,
    refetchStatus,
    resetChangeError: resetStatusChangeError
  } = useCaregiversStatus();

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId: caregiverString } = result;
    if (!destination || source.droppableId === destination.droppableId) {
      // don't allow dropping in same container
      return;
    }
    const { droppableId: destinationId } = destination;
    const caregiver = JSON.parse(
      caregiverString.replace(`${source.droppableId}-`, '')
    );
    if (destinationId === 'staffList') {
      dispatch({ type: 'REMOVE_CAREGIVER', payload: caregiver.id });
      unassignCaregiver(caregiver.id);
    } else {
      const existingCaregiver = assignedCaregivers.find(
        ({ id }) => id === caregiver.id
      );
      if (existingCaregiver) {
        return;
      }
      dispatch({ type: 'ADD_CAREGIVER', payload: caregiver });
      assignCaregiver(caregiver.id);
    }
  };

  const unassignCaregiver = (caregiverId: string) => {
    const workingDay = moment(selectedDay).format('YYYY-MM-DD');
    setAction(
      unassignCaregiverFromWard({
        wardId,
        facilityId: facility.id,
        caregiverId,
        workingDay
      })
    );
  };

  const assignCaregiver = (caregiverId: string) => {
    const workingDay = moment(selectedDay).format('YYYY-MM-DD');
    setAction(
      assignCaregiverToWard({
        wardId,
        facilityId: facility.id,
        caregiverId,
        workingDay
      })
    );
  };

  const dismissError = () => {
    resetAssignmentError();
    resetStatusChangeError();
    refetchStatus();
  };

  useEffect(() => {
    dispatch({ type: 'SET_CAREGIVERS_STATUS', payload: caregiversStatusMap });
  }, [caregiversStatusMap, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_STATUS_LOADING', payload: statusLoading });
  }, [statusLoading, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_STATUS_FETCH_ERROR', payload: statusError });
  }, [statusError, dispatch]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={style.dashboardContainer}>
        {wardId && !filtersLoading && !filtersError && (
          <>
            <div className={style.leftWrapper}>
              <ScheduleCalendar onSelectDay={setSelectedDay} wardId={wardId} />
              <StaffList
                selectedDay={selectedDay}
                isDragDisabled={dragDisabled}
                onStatusChange={changeCaregiverStatus}
              />
            </div>
            <StaffAssignments
              selectedDay={selectedDay}
              wardId={wardId}
              setDragDisabled={setDragDisabled}
              dragDisabled={dragDisabled}
            >
              <Snackbar
                isOpen={!!statusChangeError || !!assignmentError}
                onClose={dismissError}
                message={`An error occurred while ${
                  statusChangeError ? 'changing status' : 'managing assignments'
                }. Please reload to see latest changes.`}
                reloadAction={dismissError}
              />
            </StaffAssignments>
          </>
        )}
        <AccessMessage />
        {filtersLoading && !filtersError && <LoadingPlaceholder />}
        {!filtersLoading && filtersError && <FetchError />}
      </div>
    </DragDropContext>
  );
};
