import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { AlarmTypes, useNotificationState } from 'Notifications';

import { caregiverStatusMap, StatusType, StatusTypes } from 'Caregivers';
import { ProfilePlaceholder } from 'components';
import { LoginId } from 'types';
import { formatMessageTimestamp, formatName } from 'utils';

import {
  isAdHocConversation,
  isCaregiverConversation,
  isCareTeamConversation,
  isRelatedContactConversation,
  MessagingHistoryConversation
} from './types';
import { getConversationName } from './utils/messaging.utils';

import styles from './MessagingHistoryListEntry.module.css';

const customPlaceholderProperties = {
  backgroundSize: '35px',
  backgroundPosition: 'center'
};

const getAvatarBorder = (
  data: MessagingHistoryConversation
): string | undefined => {
  const { participants } = data;

  if (
    isRelatedContactConversation(data) ||
    isCareTeamConversation(data) ||
    isAdHocConversation(data)
  ) {
    return undefined;
  } else if (isCaregiverConversation(data)) {
    return caregiverStatusMap[participants[0]?.status as StatusType];
  }

  // this is only reachable when conversation type is Unknown
  return participants.some(({ status }) => status === StatusTypes.ON_DUTY)
    ? caregiverStatusMap[StatusTypes.ON_DUTY]
    : caregiverStatusMap[StatusTypes.OFF_DUTY];
};

const getResidentDetails = (conversation: MessagingHistoryConversation) => {
  const { resident } = conversation;
  if (!isRelatedContactConversation(conversation) || !resident) {
    return undefined;
  }
  const { room, name } = resident;
  const formattedName = formatName(name);

  if (!room) {
    return formattedName;
  }
  return `${formattedName} - ${room.ward?.name} - ${room.number}`;
};

export const MessagingHistoryListEntry: React.FunctionComponent<{
  data: MessagingHistoryConversation;
  isSelected: boolean;
  onSelect: (conversation: MessagingHistoryConversation) => void;
  hasUnreadMessages: boolean;
  currentUserLoginId: LoginId;
}> = ({
  data,
  isSelected,
  onSelect,
  hasUnreadMessages,
  currentUserLoginId
}) => {
  const { lastMessage, participants, id: entryId, description } = data;

  const [entryInfo, setEntryInfo] = useState<{
    title: string | undefined;
    name: string | undefined;
    photoUrl: string | undefined;
    residentInfo: string | undefined;
    relationship: string | undefined;
    isHighlighted: boolean;
    customStyle: string | undefined;
  }>({
    title: undefined,
    name: undefined,
    photoUrl: undefined,
    residentInfo: undefined,
    relationship: undefined,
    isHighlighted: false,
    customStyle: undefined
  });

  const { notifications } = useNotificationState();

  useEffect(() => {
    const otherParticipant = participants.find(
      participant => participant.loginId !== currentUserLoginId
    );

    const title = getConversationName(data, currentUserLoginId);

    const name = isCaregiverConversation(data) ? otherParticipant?.name : title;

    const photoUrl =
      isCareTeamConversation(data) || isAdHocConversation(data)
        ? undefined
        : otherParticipant?.photoUrl;

    const residentInfo = getResidentDetails(data);

    const relationship = isRelatedContactConversation(data)
      ? otherParticipant?.relationship
      : undefined;

    const isHighlighted = notifications.some(
      ({ type, personOfInterest }) =>
        type === AlarmTypes.CAREGIVER_ALARM &&
        isCaregiverConversation(data) &&
        personOfInterest?.id === participants[0]?.id
    );

    const customStyle =
      isCareTeamConversation(data) || isAdHocConversation(data)
        ? styles.groupChat
        : undefined;

    setEntryInfo({
      isHighlighted,
      name,
      title,
      photoUrl,
      relationship,
      residentInfo,
      customStyle
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserLoginId, entryId, description, notifications]);

  const onClick = () => {
    onSelect(data);
  };

  return (
    <li
      className={classnames(styles.entry, {
        [styles.selected]: isSelected,
        [styles.highlighted]: entryInfo.isHighlighted
      })}
      onClick={onClick}
    >
      <ProfilePlaceholder
        name={entryInfo.name}
        type={getAvatarBorder(data)}
        photoUrl={entryInfo.photoUrl}
        customClass={entryInfo.customStyle}
        customStyles={customPlaceholderProperties}
      />
      <div
        className={classnames(styles.details, {
          [styles.unread]: hasUnreadMessages
        })}
      >
        <span className={styles.name}>{entryInfo.title}</span>
        <span className={styles.timestamp}>
          {lastMessage && formatMessageTimestamp(lastMessage.timestamp)}
        </span>
        {isRelatedContactConversation(data) && (
          <>
            <div className={styles.additionalInfo}>
              <span className={styles.accent}>For:</span>
              <span className={styles.labelInfo}>
                {entryInfo.residentInfo || 'Multiple Residents'}
              </span>
            </div>
            {entryInfo.residentInfo && (
              <div className={styles.additionalInfo}>
                <span className={styles.accent}>Relationship:</span>
                <span className={styles.labelInfo}>
                  {entryInfo.relationship}
                </span>
              </div>
            )}
          </>
        )}
        <span className={styles.messageText}>
          {lastMessage && lastMessage.textMessage}
        </span>
      </div>
    </li>
  );
};
