import React, { useEffect, useRef } from 'react';

import { TextStatus } from 'components';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { WARD_ALARM_NO, WARD_ALARM_YES } from 'Settings/constants';

import stylesTable from 'components/Table/Table.module.css';

export const WardTableRow: React.FunctionComponent<{
  values: MappedWard;
  isSelected: boolean;
  selectWard: (wardId: string) => void;
}> = ({ values, isSelected, selectWard }) => {
  const {
    id,
    name,
    numberOfRooms,
    numberOfResidents,
    numberOfCaregivers,
    numberOfDevices,
    unattendedResidentAlarmMinutes,
    isActive,
    services
  } = values;

  const selectWardHandler = () => {
    selectWard(id || '');
  };

  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  const row = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && row.current) {
      row.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkForAlarm = Boolean(
    unattendedResidentAlarmMinutes &&
      services.alarmsNotifications.features.anRounding.isEnabled &&
      services.rtls.features.rtlsStaff.isEnabled
  );

  return (
    <div ref={row} className={rowClass} onClick={selectWardHandler} role="row">
      <div className={stylesTable.tableCellThree} role="cell">
        {name}
      </div>
      <div className={stylesTable.tableCellOne} role="cell">
        {numberOfRooms || '0'}
      </div>
      <div className={stylesTable.tableCellOne} role="cell">
        {numberOfResidents || '0'}
      </div>
      <div className={stylesTable.tableCellCaregivers} role="cell">
        {numberOfCaregivers || '0'}
      </div>
      <div className={stylesTable.tableCellOne} role="cell">
        {numberOfDevices || '0'}
      </div>
      <div className={stylesTable.tableCellOne} role="cell">
        {checkForAlarm ? WARD_ALARM_YES : WARD_ALARM_NO}
      </div>
      <div className={stylesTable.tableCellOne} role="cell">
        <TextStatus status={isActive} />
      </div>
    </div>
  );
};
