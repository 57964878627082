import { axiosWrapper } from 'utils';

import { Room, RoomListResponse } from '../types/room.type';

export const getPaginatedRooms = (
  facilityId: string,
  page?: string
) => async () => {
  const initialUrl = `/facilities/${facilityId}/rooms`;
  const url = page ? `${initialUrl}?page=${page}` : initialUrl;
  const response = await axiosWrapper<
    RoomListResponse | { items: Room[]; nextPage: string }
  >(url);
  return response.data;
};
