import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import styles from './SelectDropdown.module.css';

export const SelectDropdown: React.FunctionComponent<{
  onChange: (value: any) => void;
  value: any;
  options: any[];
}> = ({ onChange, value, options }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // on mount and unmount
  useEffect(() => {
    const handleClick = (evt: any) => {
      if (
        dropdownRef.current !== null &&
        dropdownRef.current.contains(evt.target)
      ) {
        return;
      }
      setOptionsVisible(false);
    };

    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  const onSelect = (evt: any) => {
    const { value: newValue } = evt.target.dataset;
    onChange(newValue);
  };
  const onFocus = () => {
    setOptionsVisible(true);
  };

  const renderOptionsContent = () =>
    options.map(option => (
      <li
        key={option}
        onClick={onSelect}
        className={classnames(styles.option, {
          [styles.selected]: option === value
        })}
        data-value={option}
      >
        {option}
      </li>
    ));

  return (
    <div className={styles.wrapper} ref={dropdownRef}>
      <input
        type="text"
        readOnly={true}
        value={value}
        className={styles.selectInput}
        onFocus={onFocus}
      />
      <ul
        className={classnames(styles.options, {
          [styles.optionsVisible]: optionsVisible
        })}
      >
        {renderOptionsContent()}
      </ul>
    </div>
  );
};
