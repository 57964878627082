import React from 'react';

import styles from './MyProfileDetails.module.css';

export const ProfileLoadingPlaceholder: React.FunctionComponent = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.headerPlaceholder} />
      <div className={styles.statusPlaceholder} />
      <div className={styles.buttonPlaceholder} />
    </div>
  );
};
