import moment from 'moment';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { useLocationState } from 'Location/location.context';
import { ChatId } from 'types';

import {
  CallerDetails,
  ChatMessage,
  MessagingHistoryConversation,
  StartCallTypes
} from '../types';
import { byISOdateNewest } from '../utils/messaging.utils';

export enum CommunicationActionTypes {
  SEND_MESSAGE = 'SEND_MESSAGE',
  SET_CONVERSATIONS = 'SET_CONVERSATIONS',
  SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION',
  SET_ACTIVE_CONVERSATION_ID = 'SET_ACTIVE_CONVERSATION_ID',
  STOP_CALL = 'STOP_CALL',
  CLEAR_COMMUNICATION = 'CLEAR_COMMUNICATION',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  CLEAR_NOT_SUPPORTED = 'CLEAR_NOT_SUPPORTED'
}

type Action =
  | { type: CommunicationActionTypes.SEND_MESSAGE; payload: ChatMessage }
  | {
      type: CommunicationActionTypes.SET_CONVERSATIONS;
      payload: MessagingHistoryConversation[];
    }
  | {
      type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION;
      payload: MessagingHistoryConversation;
    }
  | {
      type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION_ID;
      payload: ChatId;
    }
  | {
      type: StartCallTypes.START_VIDEO_CALL;
      payload: CallerDetails;
    }
  | {
      type: StartCallTypes.START_AUDIO_CALL;
      payload: CallerDetails;
    }
  | { type: CommunicationActionTypes.STOP_CALL }
  | { type: CommunicationActionTypes.CLEAR_COMMUNICATION }
  | { type: CommunicationActionTypes.NOT_SUPPORTED }
  | { type: CommunicationActionTypes.CLEAR_NOT_SUPPORTED };

interface State {
  conversations: MessagingHistoryConversation[];
  activeConversation: MessagingHistoryConversation;
  isVideoCallOn: boolean;
  isAudioCallOn: boolean;
  callee: CallerDetails | null;
  showNotSupportedModal?: boolean;
}

const initialCommunicationState = {
  conversations: [],
  activeConversation: {} as MessagingHistoryConversation,
  isAudioCallOn: false,
  isVideoCallOn: false,
  callee: null,
  messages: []
};

type Dispatch = (action: Action) => void;

const CommunicationStateContext = createContext<State>(
  initialCommunicationState
);
const CommunicationDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const communicationReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case CommunicationActionTypes.SET_CONVERSATIONS: {
      return { ...state, conversations: action.payload };
    }
    case CommunicationActionTypes.SET_ACTIVE_CONVERSATION: {
      const { payload: conversation } = action;
      const updatedConversations = state.conversations.reduce((acc, item) => {
        if (conversation.id && item.id === conversation.id) {
          acc.push({ ...conversation });
        } else {
          acc.push({ ...item });
        }
        return acc;
      }, [] as MessagingHistoryConversation[]);

      if (
        conversation.id &&
        !updatedConversations.find(item => item.id === conversation.id)
      ) {
        updatedConversations.unshift(conversation);
      }

      return {
        ...state,
        activeConversation: conversation,
        conversations: updatedConversations
      };
    }
    case CommunicationActionTypes.SET_ACTIVE_CONVERSATION_ID: {
      const active = state.conversations.find(
        conv => conv.id === action.payload
      );
      return {
        ...state,
        activeConversation: active!
      };
    }
    case CommunicationActionTypes.SEND_MESSAGE: {
      const {
        content,
        sentAt,
        conversationId,
        id,
        author,
        type,
        fileName,
        fileUri
      } = action.payload;

      const updatedConversations = state.conversations.map(conversation =>
        conversation.id === conversationId
          ? {
              ...conversation,
              lastMessage: {
                chatRoomId: conversationId!,
                loginSenderId: author.loginId,
                messageType: type,
                fileName,
                fileUri,
                id,
                textMessage: content,
                timestamp: moment(sentAt)
                  .utc()
                  .toISOString()
              },
              hasUnreadMessages: false
            }
          : conversation
      );

      return {
        ...state,
        conversations: updatedConversations.sort(byISOdateNewest)
      };
    }
    case StartCallTypes.START_VIDEO_CALL: {
      return {
        ...state,
        callee: action.payload,
        isVideoCallOn: true,
        isAudioCallOn: false
      };
    }
    case StartCallTypes.START_AUDIO_CALL: {
      return {
        ...state,
        callee: action.payload,
        isVideoCallOn: false,
        isAudioCallOn: true
      };
    }
    case CommunicationActionTypes.STOP_CALL: {
      return {
        ...state,
        callee: null,
        isVideoCallOn: false,
        isAudioCallOn: false
      };
    }
    case CommunicationActionTypes.CLEAR_COMMUNICATION: {
      return initialCommunicationState;
    }
    case CommunicationActionTypes.NOT_SUPPORTED: {
      return {
        ...state,
        callee: null,
        isVideoCallOn: false,
        isAudioCallOn: false,
        showNotSupportedModal: true
      };
    }
    case CommunicationActionTypes.CLEAR_NOT_SUPPORTED: {
      return {
        ...state,
        showNotSupportedModal: false
      };
    }
  }
};

export const CommunicationProvider: (props: {
  children: React.ReactNode;
}) => any = ({ children }) => {
  const [state, dispatch] = useReducer(
    communicationReducer,
    initialCommunicationState
  );

  const {
    facility: { id: facilityId },
    ward: wardId
  } = useLocationState();

  useEffect(() => {
    if (facilityId && wardId) {
      dispatch({
        type: CommunicationActionTypes.CLEAR_COMMUNICATION
      });
    }
  }, [facilityId, wardId]);

  return (
    <CommunicationStateContext.Provider value={state}>
      <CommunicationDispatchContext.Provider value={dispatch}>
        {children}
      </CommunicationDispatchContext.Provider>
    </CommunicationStateContext.Provider>
  );
};

export const useCommunicationState = () => {
  const context = useContext(CommunicationStateContext);
  if (context === undefined) {
    throw new Error(
      'useCommunicationState must be used within a CommunicationProvider'
    );
  }
  return context;
};

export const useCommunicationDispatch = () => {
  const context = useContext(CommunicationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useCommunicationDispatch must be used within a CommunicationProvider'
    );
  }
  return context;
};

export const useCommunicationContext = (): [State, Dispatch] => {
  return [useCommunicationState(), useCommunicationDispatch()];
};
