import classnames from 'classnames';
import React from 'react';

import {
  Modal,
  ProfilePlaceholder,
  RoundAudioCallButton,
  RoundCrossButtonRed
} from 'components';

import styles from './CallInitiationModal.module.css';

export const CallInitiationModal: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  callee: { name?: string; title?: string; type?: string; photoUrl?: string };
  type: 'outgoing' | 'incoming';
  answerCall?: () => void;
}> = ({ isOpen, onClose, type, callee, answerCall }) => {
  const onClick = () => (answerCall && answerCall()) || null;

  return (
    <Modal isShowing={isOpen} toggle={onClose}>
      <div className={styles.callingModal}>
        {type === 'outgoing' && <h1 className={styles.call}>Calling</h1>}
        {type === 'incoming' && <h1 className={styles.call}>Incoming Call</h1>}
        <ProfilePlaceholder
          name={callee.name}
          size={200}
          isPulsing={true}
          type={callee.type}
          photoUrl={callee.photoUrl}
        />
        <h2 className={styles.name}>{callee.name}</h2>
        <h3 className={styles.title}>{callee.title}</h3>
        <div
          className={classnames({
            [styles.buttons]: type === 'incoming',
            [styles.button]: type === 'outgoing'
          })}
        >
          {type === 'incoming' && (
            <RoundAudioCallButton onClick={onClick} size={42} />
          )}
          <RoundCrossButtonRed onClick={onClose} size={42} />
        </div>
      </div>
      <audio autoPlay={true} loop={true}>
        <source src={'/sounds/ringing.mp3'} />
      </audio>
    </Modal>
  );
};
