import React, { useState } from 'react';

import { StatusType, StatusTypes } from 'Caregivers';
import { AutocompleteSelect } from 'components';

import { profileDropdownStyles } from './profileDropdownStyles.constant';

import styles from './StatusChangeModal.module.css';

const customDropdownStyles = {
  ...profileDropdownStyles,
  container: {
    ...profileDropdownStyles.container,
    width: '150px',
    margin: '20px 0'
  },
  singleValue: { ...profileDropdownStyles.singleValue, textAlign: 'right' }
};

const breakOptions = [
  { value: 900, label: '15 minutes' },
  { value: 1800, label: '30 minutes' },
  { value: 2700, label: '45 minutes' },
  { value: 3600, label: '1 hour' }
];

const DEFAULT_BREAK_DURATION = 1800; // 30 mins

export const StatusChangeModal: React.FunctionComponent<{
  wardId: string;
  onStatusChange: (
    status: StatusType,
    wardId: string,
    allWards: boolean,
    breakDuration?: number
  ) => void;
  toggle: () => void;
}> = ({ wardId, onStatusChange, toggle }) => {
  const [onBreak, setOnBreak] = useState(false);
  const [breakForAllWards, setBreakForAllWards] = useState(false);
  const [breakDuration, setBreakDuration] = useState(DEFAULT_BREAK_DURATION);

  const parentElement = document.getElementById('WardStatusWrapper');
  const modalHeight = parentElement ? parentElement.clientHeight - 58 : 200;

  const handleOnBreakClick = () => {
    setOnBreak(true);
  };

  const goOffDuty = () => {
    onStatusChange(StatusTypes.OFF_DUTY, wardId, breakForAllWards);
    toggle();
  };

  const goOnBreak = () => {
    onStatusChange(
      StatusTypes.ON_BREAK,
      wardId,
      breakForAllWards,
      breakDuration
    );
    setOnBreak(false);
    toggle();
  };

  const onAllWardsCheck = (evt: any) => {
    const { checked } = evt.target;
    setBreakForAllWards(checked);
  };

  return (
    <div className={styles.statusModal} style={{ height: modalHeight }}>
      <>
        {onBreak ? (
          <>
            <p className={styles.question}>
              How long are you going to be on break?
            </p>
            <AutocompleteSelect
              value={breakDuration}
              options={breakOptions}
              dropdownStyles={customDropdownStyles}
              onChange={setBreakDuration}
              isClearable={false}
            />
            <button className={styles.breakButton} onClick={goOnBreak}>
              go on break
            </button>
          </>
        ) : (
          <>
            <p className={styles.question}>
              Are you going on break or off duty?
            </p>
            <div className={styles.buttonsWrapper}>
              <button
                className={styles.breakButton}
                onClick={handleOnBreakClick}
              >
                on break
              </button>
              <button className={styles.offButton} onClick={goOffDuty}>
                off duty
              </button>
            </div>
            <div className={styles.checkWrapper}>
              <input
                checked={breakForAllWards}
                onChange={onAllWardsCheck}
                type="checkbox"
                id="break-checkbox"
              />
              <label htmlFor="break-checkbox" className={styles.checkboxLabel}>
                for all wards
              </label>
            </div>
          </>
        )}
      </>
    </div>
  );
};
