import { NotificationDetail } from 'Notifications/types';
import { splitName } from 'utils/stringManipulation';

export const formatNotificationUserData = (
  notification: NotificationDetail
) => {
  const { personOfInterest, wardView, room } = notification;

  if (personOfInterest?.name) {
    const { firstName, initial, lastName } = splitName(personOfInterest.name);
    const subjectName = `${firstName} ${initial} ${lastName}` || 'N/A';
    const locationData =
      room && room.number ? `${wardView.name} - ${room.number}` : 'N/A';

    return `${subjectName} - ${locationData}`;
  } else {
    const locationData =
      room && room.number ? `${wardView.name} - ${room.number}` : 'N/A';

    return locationData;
  }
};
