import React from 'react';

import { formatLogDate, HistoryLogsList, LastBath } from 'Residents';
import { formatName } from 'utils';

import { HistoryLogEntry } from './HistoryLogEntry';
import { HistoryValue } from './HistoryValue';

import style from './HistoryLogs.module.css';

export const LastBathHistoryLogs: React.FunctionComponent<{
  historyLogs: LastBath[];
  scrollRef?: any;
}> = ({ historyLogs, scrollRef }) => {
  const logEntries = historyLogs.map(log => {
    const { timestamp, value } = log;
    const { timestamp: time, date } = formatLogDate(timestamp);
    const { userRole, userName } = value;
    const formattedName = userName && formatName(userName);

    return (
      <HistoryLogEntry key={log.timestamp} timestamp={time} date={date}>
        <HistoryValue value={formattedName || 'N/A'} detail={userRole} />
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList scrollRef={scrollRef}>{logEntries}</HistoryLogsList>
  ) : (
    <p className={style.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
