import React from 'react';

import { DailySteps, formatLogDate, HistoryLogsList } from 'Residents';

import { HistoryLogEntry } from './HistoryLogEntry';
import { HistoryValue } from './HistoryValue';

import style from './HistoryLogs.module.css';

export const DailyStepsHistoryLogs: React.FunctionComponent<{
  historyLogs: DailySteps[];
  scrollRef?: any;
}> = ({ historyLogs, scrollRef }) => {
  const logEntries = historyLogs.map(log => {
    const { timestamp, value } = log;
    const { timestamp: time, date } = formatLogDate(timestamp);

    return (
      <HistoryLogEntry key={log.timestamp} timestamp={time} date={date}>
        <HistoryValue value={value.count} />
      </HistoryLogEntry>
    );
  });

  return historyLogs.length ? (
    <HistoryLogsList scrollRef={scrollRef}>{logEntries}</HistoryLogsList>
  ) : (
    <p className={style.emptyPlaceholder}>
      No information available for the selected period
    </p>
  );
};
