import React from 'react';
import { Line } from 'recharts';

import { Dot } from 'Residents';

export const BodyWeightLine = (color: string) => (
  <Line
    dataKey="bodyWeight"
    stroke="#FFF"
    strokeWidth={2}
    connectNulls={true}
    dot={<Dot lineColor={'#FFF'} backgroundColor={color} />}
    isAnimationActive={true}
  />
);
