import { StaffMember } from './types';

export const createCaregiverDTO = (
  formValues: any,
  additionalData = {} as StaffMember
) => {
  const badge = formValues.badge
    ? { id: formValues.badge.id, name: formValues.badge.name }
    : undefined;
  const caregiverData = {
    ...additionalData,
    role: formValues.role,
    badge,
    phoneExtension: formValues.phoneExtension
  };

  return { ...caregiverData };
};
