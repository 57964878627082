import React from 'react';

import { ReactComponent as LiquidOut } from 'icons/liquid-output.svg';
import { LiquidOutput } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './LiquidOutputCard.module.css';

export const LiquidOutputCard: React.FunctionComponent<{
  liquidOutput: LiquidOutput | undefined;
  onClick: () => void;
}> = ({ liquidOutput, onClick }) => {
  const { value, unit } = liquidOutput || {};
  const reading = value && parseInt(value.count, 10);

  return (
    <div className={style.liquidOutput} onClick={onClick}>
      <ActivityInfoCard reading={reading} unit={unit} type="liquidOutput">
        <LiquidOut />
      </ActivityInfoCard>
    </div>
  );
};
