import moment from 'moment';
import React, { useState } from 'react';
import DayPicker, { Modifiers } from 'react-day-picker';

import generalStyles from './Calendar.module.css';

export const Calendar: React.FunctionComponent<{
  title: string;
  onSelectDay?: (day: Date) => void;
  onMonthChange?: (month: Date) => void;
  styles?: { [key: string]: string };
  dayModifiers?: Partial<Modifiers>;
}> = ({
  title,
  onSelectDay,
  onMonthChange,
  styles = generalStyles,
  dayModifiers = {}
}) => {
  const currentDate = moment().toDate();

  const [selectedDay, setSelectedDay] = useState(currentDate);
  const [selectedMonth, setSelectedMonth] = useState(currentDate);

  const onDayChange = (day: Date) => {
    setSelectedDay(day);
    if (onSelectDay) {
      onSelectDay(day);
    }
  };

  const onMonthNavigation = (month: Date) => {
    setSelectedMonth(month);
    if (onMonthChange) {
      onMonthChange(month);
    }
  };

  const composedStyles: { [key: string]: string } = {
    ...generalStyles,
    ...styles
  };

  const CaptionTitle = () => (
    <div className={composedStyles.caption}>
      <h1 className={composedStyles.captionTitle}>{title}</h1>
      <span className={composedStyles.captionMonth}>
        {moment(selectedMonth).format('MMMM YYYY')}
      </span>
    </div>
  );

  return (
    <DayPicker
      classNames={composedStyles as any}
      showOutsideDays={true}
      enableOutsideDaysClick={false}
      weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
      captionElement={CaptionTitle}
      onDayClick={onDayChange}
      selectedDays={selectedDay}
      month={selectedMonth}
      onMonthChange={onMonthNavigation}
      modifiers={dayModifiers}
    />
  );
};
