import React, { createContext, ReactNode, useContext, useReducer } from 'react';

import {
  HomeViewAction,
  HomeViewDispatch,
  HomeViews,
  HomeViewState,
  SwitchHomeView
} from '../types';

const defaultState: HomeViewState = {
  view: HomeViews.ALARMS_WITH_RESIDENTS,
  topAlign: undefined
};

const HomeViewContext = createContext<HomeViewState>(defaultState);
const HomeViewDispatchContext = createContext<HomeViewDispatch>(
  () => undefined
);

export const homeViewReducer = (
  state: HomeViewState,
  action: HomeViewAction
): HomeViewState => {
  switch (action.type) {
    case SwitchHomeView.SWITCH_VIEW: {
      return state.view === HomeViews.ALARMS_WITH_RESIDENTS
        ? { view: HomeViews.ALARMS_ONLY, topAlign: state.topAlign }
        : { view: HomeViews.ALARMS_WITH_RESIDENTS, topAlign: state.topAlign };
    }
    case SwitchHomeView.SET_TOP: {
      return {
        view: state.view,
        topAlign: action.payload
      };
    }
    default: {
      return defaultState;
    }
  }
};

export const HomeViewProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(homeViewReducer, defaultState);

  return (
    <HomeViewContext.Provider value={state}>
      <HomeViewDispatchContext.Provider value={dispatch}>
        {children}
      </HomeViewDispatchContext.Provider>
    </HomeViewContext.Provider>
  );
};

export const useHomeViewState = () => {
  const context = useContext(HomeViewContext);
  if (context === undefined) {
    throw new Error('useHomeViewState must be used within a HomeViewProvider');
  }
  return context;
};

export const useHomeViewDispatch = () => {
  const context = useContext(HomeViewDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useHomeViewDispatch must be used within a HomeViewProvider'
    );
  }

  return context;
};

export const useHomeViewContext = (): [HomeViewState, HomeViewDispatch] => {
  return [useHomeViewState(), useHomeViewDispatch()];
};
