import React from 'react';

import styles from './ResidentsLoadingPlaceholder.module.css';

export const LoadingPlaceholderCard = (
  <div className={styles.cardPlaceholder}>
    <span className={styles.circle} />
    <span className={styles.textPlaceholderShort} />
    <span className={styles.textPlaceholderLonger} />
    <span className={styles.textPlaceholderLong} />
  </div>
);

export const ResidentsLoadingPlaceholder: React.FunctionComponent = () => (
  <div className={styles.loadingCardsPlaceholder}>
    {LoadingPlaceholderCard}
    {LoadingPlaceholderCard}
    {LoadingPlaceholderCard}
    {LoadingPlaceholderCard}
    {LoadingPlaceholderCard}
  </div>
);
