import { axiosWrapper } from 'utils';

import { Resident } from 'Settings/types';

export const editResidentDetails = (
  facilityId: string,
  resident: Partial<Resident>
) => async () => {
  const url = `/facilities/${facilityId}/residents/${resident.id}`;
  const response = await axiosWrapper(url, 'PATCH', null, resident);
  return response.data;
};
