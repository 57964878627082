import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { heartRateInterval } from '../Intervals';
import { HeartRateLine } from '../Lines';
import { HeartRateToolTip } from '../ToolTips';

export const HeartRateChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    systolicValue?: number;
    diastolicValue?: number;
    hasWarning?: boolean | undefined;
  }>;

  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={heartRateInterval}
      data={data}
      tooltip={HeartRateToolTip}
      lineOne={HeartRateLine('#fb6107')}
      color="#fb6107"
      allowHighlight={allowHighlight}
    />
  );
};
