import classnames from 'classnames';
import React from 'react';

import { RoundButton } from './RoundButton';

import styles from './RoundCrossButton.module.css';

import { ReactComponent as CrossIcon } from 'icons/crossmark.svg';

export const RoundCrossButtonRed: React.FunctionComponent<{
  onClick: () => void;
  testId?: string;
  small?: boolean;
  disabled?: boolean;
  size?: number;
}> = ({ onClick, testId, small = false, disabled = false, size = 24 }) => (
  <RoundButton
    className={classnames(styles.redButton, { [styles.small]: small })}
    onClick={onClick}
    testId={testId}
    size={size}
    disabled={disabled}
  >
    <CrossIcon />
  </RoundButton>
);
