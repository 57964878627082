import React, { FunctionComponent, useCallback } from 'react';

import { ReactComponent as NotificationIcon } from 'icons/notification.svg';
import { useLocationState } from 'Location';

import {
  Dispatch,
  useNotificationDispatch,
  useNotificationState
} from './notification.context';
import { NotificationActionTypes, NotificationSounds } from './types';

import styles from './NotificationsBadge.module.css';

interface INotificationsBadgeProps {
  noOfNotifications: number | false;
  playSound: NotificationSounds | undefined;
  dispatch: Dispatch;
}

export const NotificationsBadge: FunctionComponent<INotificationsBadgeProps> = ({
  dispatch,
  noOfNotifications,
  playSound
}) => {
  const handleEndPlay = useCallback(() => {
    dispatch({
      type: NotificationActionTypes.SOUND,
      payload: undefined
    });
  }, [dispatch]);

  return (
    <>
      <NotificationIcon className={styles.icon} />
      {noOfNotifications && (
        <span className={styles.badge}>{noOfNotifications}</span>
      )}
      {playSound && (
        <audio autoPlay={true} loop={false} onEnded={handleEndPlay}>
          <source src={playSound} />
        </audio>
      )}
    </>
  );
};

export const NotificationBadgeWithProviders: FunctionComponent = () => {
  const { notifications, playSound } = useNotificationState();
  const soundDispatch = useNotificationDispatch();
  const { ward: wardId } = useLocationState();

  const showingBadge =
    Boolean(wardId) && notifications.length > 0 && notifications.length;

  return (
    <NotificationsBadge
      dispatch={soundDispatch}
      noOfNotifications={showingBadge}
      playSound={playSound}
    />
  );
};
