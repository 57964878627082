export enum Activities {
  WALKING = 'WALKING',
  LOUNGING = 'LOUNGING',
  SLEEPING = 'SLEEPING',
  BATHING = 'BATHING',
  ROUNDING = 'ROUNDING',
  LIQUID_INTAKE = 'LIQUID_INTAKE',
  FOOD_INTAKE = 'FOOD_INTAKE',
  LOCATION = 'LOCATION',
  LIQUID_OUTTAKE = 'LIQUID_OUTTAKE'
}
