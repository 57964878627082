import { rules as accessRules, useAuthState, UserRole } from 'Auth';

type ActionRules = {
  [key in UserRole]: {
    static: string[];
    dynamic?: {
      [key: string]: (params?: any) => boolean;
    };
  };
};
const check = (
  rules: ActionRules,
  role: UserRole,
  action: string,
  data: any
) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const WithPermissions: React.FunctionComponent<{
  action: string;
  data?: any;
  yes?: any;
  no?: any;
}> = ({ action, data, yes = null, no = null }) => {
  const { role } = useAuthState();
  return role && check(accessRules, role, action, data) ? yes : no;
};
