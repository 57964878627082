import React from 'react';
import { ChatMessage } from './types';

import styles from './ParticipantBubble.module.css';

interface IParticipantBubbleProps {
  message: ChatMessage;
  time: string;
}

export const ParticipantBubble: React.FunctionComponent<IParticipantBubbleProps> = ({
  message,
  time
}) => {
  return (
    <div key={message.id} className={styles.messageRow}>
      <span className={styles.chatText}>
        {message.content}
        <span className={styles.time}>{time}</span>
      </span>
    </div>
  );
};
