import React, { useRef, useState } from 'react';

import { Modal } from 'components';
import { useModal } from 'hooks';
import {
  getLastWeekHistoryFilters,
  getThisMonthHistoryFilters,
  getThisWeekHistoryFilters,
  HistoryLabel,
  OxygenSaturation
} from 'Residents';

import { OxygenSaturationVitalCard } from './Cards';
import { OxygenSaturationHistory } from './History';
import { AddOxygenSaturationReading } from './History/AddReadings/AddOxygenSaturationReading';

import style from 'Residents/History/History.module.css';

export const ManageOxygenSaturation: React.FunctionComponent<{
  oxygenSaturation: OxygenSaturation;
  facilityId: string;
  residentId: string;
  onReadingAdded: () => void;
}> = ({ oxygenSaturation, facilityId, residentId, onReadingAdded }) => {
  const [historyFilters, setHistoryFilters] = useState(
    getThisWeekHistoryFilters()
  );
  const hasNewReading = useRef(false);
  const { isShowing, toggle: toggleHistoryView } = useModal();
  const { isShowing: isAddFormOpen, toggle: toggleAddReading } = useModal();

  const onAddReading = () => {
    toggleAddReading();
    hasNewReading.current = true;
    // update history filters to This Week
    setHistoryFilters(getThisWeekHistoryFilters());
  };

  const onCloseHistoryView = () => {
    if (hasNewReading.current) {
      // let parent know to refetch on add
      onReadingAdded();
    }
    setHistoryFilters(getThisWeekHistoryFilters());
    toggleHistoryView();
  };

  const changeHistoryFilter = (historyLabel: HistoryLabel) => {
    switch (historyLabel) {
      case 'This Week':
        setHistoryFilters(getThisWeekHistoryFilters());
        break;
      case 'Last Week':
        setHistoryFilters(getLastWeekHistoryFilters());
        break;
      case 'This Month':
        setHistoryFilters(getThisMonthHistoryFilters());
        break;
    }
  };

  const onAdd = () => {
    if (!isAddFormOpen) {
      toggleAddReading();
    }
  };

  return (
    <>
      <OxygenSaturationVitalCard
        onClick={toggleHistoryView}
        oxygenSaturation={oxygenSaturation}
      />
      <Modal isShowing={isShowing} toggle={toggleHistoryView}>
        <div className={style.modalBackdrop} id="HistoryModal">
          <div className={style.modalContent}>
            <OxygenSaturationHistory
              onClose={onCloseHistoryView}
              facilityId={facilityId}
              residentId={residentId}
              onAdd={onAdd}
              historyFilters={historyFilters}
              changeHistoryFilter={changeHistoryFilter}
            />
          </div>
        </div>
      </Modal>
      <AddOxygenSaturationReading
        isOpen={isAddFormOpen}
        onCancel={toggleAddReading}
        onSave={onAddReading}
        facilityId={facilityId}
        residentId={residentId}
      />
    </>
  );
};
