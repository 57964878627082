import { Location } from 'history';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { ReactComponent as Logo } from 'icons/th-logo-full.svg';

import styles from './LoginTemplate.module.css';

const DEFAULT_REDIRECT_FROM = { from: { pathname: '/' } };

export const LoginTemplate: React.FunctionComponent<{
  isAuthenticated: boolean;
  location: Location | undefined;
}> = ({ isAuthenticated, location, children }) => {
  const { from } =
    (location && (location.state as any)) || DEFAULT_REDIRECT_FROM;
  const to =
    from.pathname.toLowerCase() === '/login' ? DEFAULT_REDIRECT_FROM : from;

  return isAuthenticated ? (
    <Redirect to={to} />
  ) : (
    <div className={styles.mainContainer}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        {children}
      </div>
    </div>
  );
};
