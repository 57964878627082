import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  PanelList,
  Table
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useInfiniteScroll } from 'hooks';
import {
  Facility,
  getPaginatedResidents,
  getResidentsByQuery,
  Resident
} from 'Settings';

import {
  DEFAULT_PAGE,
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH,
  ROOM_LIST_PLACEHOLDER
} from '../../constants/index';
import { RoomResidentTableRow } from './RoomResidentTableRow';

export const RoomResidentList: React.FunctionComponent<{
  facility: Facility;
  setActiveTab: (tab: string) => void;
  onSelect: (key: string, id: string) => void;
  selectedRoomResidentsId: { [key: string]: string };
}> = ({ facility, setActiveTab, onSelect, selectedRoomResidentsId }) => {
  const [page, setPage] = useState<string>(DEFAULT_PAGE);
  const [searchedRoomsPage, setSearchedRoomsPage] = useState(DEFAULT_PAGE);
  const [residents, setResidents] = useState<Resident[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const RoomListTableColumns: { [key: string]: Column } = {
    residentName: {
      name: `${residentLabels[facility.type]}`,
      align: 'left',
      cols: 2
    },
    roomNumber: { name: 'Room', align: 'left', cols: 1.3 },
    residentNumber: {
      name: `${residentLabels[facility.type]} Number`,
      align: 'right',
      cols: 2
    }
  };

  const getResidentsMemo = useMemo(
    () => getPaginatedResidents(facility.id, page),
    [facility.id, page]
  );

  const {
    data: { items: residentList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Resident[];
    nextPage?: string;
  }>(getResidentsMemo, { items: [] });

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getResidentsByQuery(facility.id, searchTerm, searchedRoomsPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedRoomsPage]);

  const {
    data: { items: searchResidentList, nextPage: nextPageForSearchedResidents },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{ items: Resident[]; nextPage?: string }>(memoSearchAction, {
    items: []
  });

  const scrollAction = () => {
    if (isFetching && residentList.length && nextPage && !isSearching) {
      setPage(nextPage);
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchResidentList.length &&
      nextPageForSearchedResidents &&
      isSearching
    ) {
      setSearchedRoomsPage(nextPageForSearchedResidents);
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const checkIfSelected = (values: Resident) => {
    return values.id
      ? selectedRoomResidentsId.resident === values.id
      : selectedRoomResidentsId.room === values.room?.id;
  };

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedRoomsPage(DEFAULT_PAGE);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setResidents([]);
      setPage(DEFAULT_PAGE);
      setRefetch();
    }
  };

  useEffect(() => {
    if (residentList.length !== 0) {
      setResidents(currentResidents => [...currentResidents, ...residentList]);
    }
  }, [residentList]);

  useEffect(() => {
    if (searchError) {
      return setResidents([]);
    }
    if (searchedRoomsPage > DEFAULT_PAGE) {
      setResidents(prevState => [...prevState, ...searchResidentList]);
    } else {
      setResidents(searchResidentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResidentList, searchError]);

  const render = (rowValues: Resident) => (
    <RoomResidentTableRow
      key={rowValues.id}
      values={rowValues}
      isSelected={checkIfSelected(rowValues)}
      setActiveTab={setActiveTab}
      onSelect={onSelect}
      label={residentLabels[facility.type]}
      columns={RoomListTableColumns}
    />
  );

  const isDataAvailable = residents.length > 0;
  const title = `Rooms & ${residentLabels[facility.type]}s`;
  const searchInputName = `${title.toLowerCase()}`;

  return (
    <PanelList
      title={title}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={searchInputName}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<Resident>
          columns={Object.values(RoomListTableColumns)}
          rows={residents}
          placeholder={ROOM_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}
    </PanelList>
  );
};
