import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CommunicationIcon } from 'icons/communication.svg';

import { RoundButton } from './RoundButton';

import styles from './RoundMessageButton.module.css';

export const RoundMessageButton: React.FunctionComponent<{
  testId?: string;
  size?: number;
  onClick?: (e: any) => void;
  link?: string;
  missedMessages?: number;
}> = ({ testId, size, onClick, link, missedMessages }) => {
  const handleClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  return link && onClick ? (
    <Link to={link} className={styles.link}>
      <RoundButton
        className={styles.roundMessageButton}
        onClick={onClick}
        testId={testId}
        size={size}
      >
        <CommunicationIcon />
        {missedMessages !== undefined && (
          <span
            className={classNames({
              [styles.missedCount]: missedMessages < 100,
              [styles.missedCountPlus]: missedMessages > 99
            })}
          >
            {missedMessages < 100 ? missedMessages : '99+'}
          </span>
        )}
      </RoundButton>
    </Link>
  ) : (
    <RoundButton
      className={styles.roundMessageButton}
      onClick={handleClick}
      testId={testId}
      size={size}
    >
      <CommunicationIcon />
      {missedMessages !== undefined && (
        <span
          className={classNames({
            [styles.missedCount]: missedMessages < 100,
            [styles.missedCountPlus]: missedMessages > 99
          })}
        >
          {missedMessages < 100 ? missedMessages : '99+'}
        </span>
      )}
    </RoundButton>
  );
};
