import React from 'react';

import { ReactComponent as VideoIcon } from 'icons/video-call.svg';

import { RoundButton } from './RoundButton';

import styles from './RoundVideoCallButton.module.css';

export const RoundVideoCallButton: React.FunctionComponent<{
  onClick: () => void;
  testId?: string;
  size?: number;
  missedCalls?: number;
}> = ({ onClick, testId, size, missedCalls }) => (
  <RoundButton
    className={styles.roundVideoButton}
    onClick={onClick}
    testId={testId}
    size={size}
  >
    <VideoIcon />
    {missedCalls !== undefined && missedCalls !== 0 && (
      <span
        className={
          size && size <= 22 ? styles.missedCount : styles.missedCountExtra
        }
      >
        {missedCalls < 100 ? missedCalls : '99+'}
      </span>
    )}
  </RoundButton>
);
