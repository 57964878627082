import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Snackbar } from 'components';
import { ReactComponent as ImageIcon } from 'icons/image-folder.svg';
import { blobToBase64, checkFileType } from 'utils';

import styles from './DroppableImageUpload.module.css';

const fileErrorMessages = {
  INVALID_TYPE:
    'Please upload an image with the supported types: JPG, JPEG, PNG.',
  MULTIPLE_FILES: 'Please upload only one image at a time.'
};

export const DroppableImageUpload: React.FunctionComponent<{
  onImageLoaded: (
    imageUrl: string | ArrayBuffer | null,
    filename: string
  ) => void;
}> = ({ onImageLoaded }) => {
  const [isOverDroppable, setIsOverDroppable] = useState(false);
  const [fileError, setFileError] = useState<
    'MULTIPLE_FILES' | 'INVALID_TYPE'
  >();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const droppableContainer = useRef<HTMLDivElement | null>(null);

  const processUploadedFile = useCallback(
    (file: File | null) => {
      if (file) {
        checkFileType(file, async (isValid: boolean) => {
          if (!isValid) {
            setFileError('INVALID_TYPE');
            return;
          }

          const imageUrl = await blobToBase64(file);
          onImageLoaded(imageUrl, file.name);
        });
      }
    },
    [onImageLoaded]
  );

  useEffect(() => {
    const droppable = droppableContainer.current;

    const onDragEnter = (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      setIsOverDroppable(true);
    };
    const onDragLeave = (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      setIsOverDroppable(false);
    };
    const onDragOver = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
    };
    const onDrop = (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      setIsOverDroppable(false);

      const dataTransfer = e.dataTransfer;
      if (dataTransfer.files.length > 1) {
        setFileError('MULTIPLE_FILES');
        return;
      }
      const droppedFile = dataTransfer.files[0];
      processUploadedFile(droppedFile);
    };

    const removeEvents = (element: HTMLDivElement) => {
      element.removeEventListener('dragenter', onDragEnter, false);
      element.removeEventListener('dragleave', onDragLeave, false);
      element.removeEventListener('dragover', onDragOver, false);
      element.removeEventListener('drop', onDrop, false);
    };

    if (!droppable) {
      return;
    }

    droppable.addEventListener('dragenter', onDragEnter, false);
    droppable.addEventListener('dragleave', onDragLeave, false);
    droppable.addEventListener('dragover', onDragOver, false);
    droppable.addEventListener('drop', onDrop, false);

    return () => {
      if (droppable) {
        removeEvents(droppable);
      }
    };
  }, [processUploadedFile]);

  const onInputChange = (evt: any) => {
    const files = evt.target.files;
    if (files.length > 1) {
      setFileError('MULTIPLE_FILES');
      return;
    }
    processUploadedFile(files[0]);
  };

  const openFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearError = () => {
    setFileError(undefined);
  };

  return (
    <>
      <div
        className={classnames(styles.dropContainer, {
          [styles.highlighted]: isOverDroppable
        })}
        ref={droppableContainer}
      >
        <ImageIcon />
        <p className={styles.uploadInfo}>
          Drop your image here, or
          <button className={styles.browseButton} onClick={openFileUpload}>
            browse
          </button>
          <input
            ref={fileInputRef}
            className={styles.hidden}
            type="file"
            accept=".jpg, .jpeg, .png"
            multiple={false}
            onChange={onInputChange}
          />
        </p>
        <p className={styles.additionalInfo}>Supported types: JPG, JPEG, PNG</p>
      </div>
      <Snackbar
        message={fileError ? fileErrorMessages[fileError] : ''}
        isOpen={!!fileError}
        onClose={clearError}
      />
    </>
  );
};
