import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';

import { RolesEnum, useAuthState } from 'Auth';
import {
  DismissibleError,
  FetchError,
  FormHeader,
  Input,
  LoadingPlaceholder,
  ResidentFetchDropdown,
  WardFetchDropdown
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { getFormValidationErrors } from 'utils';

import { createRoom as createRoomAction } from '../actions';
import { Facility, Room, RoomResident, RoomWard } from '../types';
import { createRoomDTO } from './createRoomDTO';
import { RoomDeviceInput } from './RoomDeviceInput';

import stylesForm from 'styles/form.module.css';

interface AddRoomFormData {
  number: string;
  ward: RoomWard;
  residents: RoomResident[];
  devices: string[];
}

export const AddRoomForm: React.FunctionComponent<{
  toggle: () => void;
  selectRoom: (roomId: string) => void;
  facility: Facility;
}> = ({ toggle, selectRoom, facility }) => {
  const [deviceIndexes, setDeviceIndexes] = useState<number[]>([0]);
  const [validationErrors, setValidationErrors] = useState<any[] | null>();

  const [wardStatus, setWardStatus] = useState({
    loading: true,
    error: false
  });

  const [residentStatus, setResidentStatus] = useState({
    loading: true,
    error: false
  });

  const [deviceStatus, setDeviceStatus] = useState({
    loading: true,
    error: false
  });

  const {
    register,
    errors,
    setValue,
    watch,
    getValues,
    triggerValidation
  } = useForm<AddRoomFormData>({
    mode: 'onBlur',
    submitFocusError: false
  });
  const { data, setAction, loading: onSaveLoading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, onSaveLoading);

  const createRoom = (room: Room) => {
    setAction(createRoomAction(room));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isValid = await triggerValidation();
    if (isValid) {
      const roomData = createRoomDTO(getValues(), facility.id);
      createRoom(roomData);
    }
  };

  const onAddDeviceInput = (newIndex: number) => {
    setDeviceIndexes(prevIndexes => [...prevIndexes, newIndex]);
  };

  const { role } = useAuthState();

  const renderDeviceInputs = () => {
    const renderDeviceInputGroup = (
      deviceIndexKey: number,
      labelOrderIndex: number,
      sourceArray: number[]
    ) => {
      const inputName = `devices[${deviceIndexKey}]`;

      return (
        <RoomDeviceInput
          facility={facility}
          name={inputName}
          deviceIndex={deviceIndexKey}
          label={`Device ${labelOrderIndex + 1}`}
          register={register}
          setValue={setValue}
          watch={watch}
          error={(errors as any)[inputName]}
          isLast={sourceArray.length - 1 === labelOrderIndex}
          onAdd={onAddDeviceInput}
          hasDeviceAccess={role === RolesEnum.ROLE_ADMIN}
          statusChange={setDeviceStatus}
        />
      );
    };

    return deviceIndexes.map((indexKey, orderIndex) => (
      <div className={stylesForm.formRow} key={`deviceRow-${indexKey}`}>
        {renderDeviceInputGroup(indexKey, orderIndex, deviceIndexes)}
      </div>
    ));
  };

  useEffect(() => {
    if (!submitted || onSaveLoading || error) {
      return undefined;
    }
    toggle();
    selectRoom(data);
  }, [submitted, onSaveLoading, error, toggle, selectRoom, data]);

  useEffect(() => {
    const formValidationErrors = getFormValidationErrors(errors);
    setValidationErrors(formValidationErrors);
  }, [errors]);

  const fetchError =
    residentStatus.error || wardStatus.error || deviceStatus.error;
  const loading =
    residentStatus.loading ||
    wardStatus.loading ||
    deviceStatus.loading ||
    onSaveLoading;

  const formDisplay = loading ? 'none' : 'block';

  const handleChange = () => {
    if (error) {
      dismissError();
    }
  };

  const handleBlur = () => {
    triggerValidation([{ name: 'ward' }]);
  };

  return (
    <>
      <FormHeader
        title="Add New Room"
        onCancel={toggle}
        onSubmit={handleSubmit}
        submitDisabled={onSaveLoading}
        visible={!fetchError}
      />
      {fetchError && (
        <FetchError error={fetchError} closable={true} onClose={toggle} />
      )}
      {!fetchError && (
        <>
          {loading && <LoadingPlaceholder />}
          <form
            onSubmit={handleSubmit}
            className={stylesForm.leftForm}
            style={{ display: formDisplay }}
          >
            <div className={stylesForm.formRow}>
              <div className={stylesForm.inputGroupQuarter}>
                <Input
                  name="number"
                  label="Room Number"
                  register={register}
                  validationRules={{ required: true }}
                  hasError={!!errors.number || isErrorShowing}
                  onChange={handleChange}
                />
              </div>
              <WardFetchDropdown
                name="ward"
                value={watch('ward')}
                onChange={setValue}
                register={register}
                hasError={!!errors.ward}
                facilityId={facility.id}
                changeStatus={setWardStatus}
                required={true}
                cssClass={stylesForm.selectGroupQuarter}
                onBlur={handleBlur}
              />
              <ResidentFetchDropdown
                name="residents"
                value={watch('residents')}
                onChange={setValue}
                register={register}
                hasError={!!errors.residents}
                facility={facility}
                changeStatus={setResidentStatus}
                allowMultiple={true}
                cssClass={stylesForm.selectGroupHalf}
              />
            </div>
            {renderDeviceInputs()}

            <DismissibleError
              name="Room"
              visible={isErrorShowing}
              error={error}
              dismiss={dismissError}
            />
          </form>
          {validationErrors && (
            <div className={stylesForm.errorsWrapper}>{validationErrors}</div>
          )}
        </>
      )}
    </>
  );
};
