import classnames from 'classnames';
import React from 'react';

import { ReactComponent as SendIcon } from 'icons/send.svg';

import styles from './SendButton.module.css';

export const SendButton: React.FunctionComponent<{
  onClick: (e: any) => void;
  disabled?: boolean;
  testId?: string;
}> = ({ onClick, disabled = false, testId }) => (
  <button
    onClick={onClick}
    className={classnames(styles.sendButton, {
      [styles.disabled]: disabled
    })}
    data-testid={testId}
    disabled={disabled}
  >
    <SendIcon />
  </button>
);
