import React, { useEffect, useMemo, useState } from 'react';

import { FetchError, LoadingPlaceholder, Modal } from 'components';
import {
  useFetcher,
  useModalMeasurements,
  usePoster,
  useSubmitError
} from 'hooks';
import {
  editResidentContact,
  getResidentContact,
  ResidentContact
} from 'Settings';

import { EditRelatedContactForm } from './EditRelatedContactForm';

import stylesModal from 'components/Modal/Modal.module.css';

export const EditRelatedContact: React.FunctionComponent<{
  residentId: string;
  contactId: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updates: ResidentContact) => void;
  label: string;
}> = ({ residentId, contactId, isOpen, onClose, onSave, label }) => {
  const { modalOffsetTop } = useModalMeasurements(
    'Related Contacts',
    'MainContent',
    isOpen
  );
  const [updatedContact, setUpdatedContact] = useState({} as ResidentContact);
  const getContactDetailsAction = useMemo(() => {
    if (isOpen) {
      return getResidentContact(residentId, contactId);
    }
  }, [residentId, contactId, isOpen]);

  const { data, error: fetchError, loading: fetchLoading } = useFetcher<
    ResidentContact
  >(getContactDetailsAction, {} as ResidentContact);

  const { setAction, loading: onSaveLoading, error: saveError } = usePoster();

  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(saveError, onSaveLoading);

  const editRelatedContact = (updated: ResidentContact) => {
    setAction(editResidentContact(data.residentId, updated));
    setUpdatedContact(updated);
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const onCancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    onClose();
  };

  useEffect(() => {
    if (submitted && !onSaveLoading && !saveError) {
      onSave(updatedContact);
      setSubmitted(false);
    }
  }, [
    submitted,
    onSaveLoading,
    saveError,
    onSave,
    setSubmitted,
    updatedContact
  ]);

  const errorName = `${label} Related Contact`;

  return (
    <Modal isShowing={isOpen} toggle={onClose}>
      <div className={stylesModal.modalBackdrop}>
        <div className={stylesModal.modalContentRightAlign}>
          <div
            className={stylesModal.modalRightWithVerticalBar}
            style={{
              marginTop: modalOffsetTop
            }}
          >
            {fetchLoading && <LoadingPlaceholder fullHeight={false} />}
            {fetchError && !fetchLoading && <FetchError error={fetchError} />}
            {!fetchLoading && !fetchError && (
              <EditRelatedContactForm
                relatedContact={data}
                onClose={onCancel}
                onSave={editRelatedContact}
                onSaveLoading={onSaveLoading}
                errorName={errorName}
                visible={isErrorShowing}
                error={saveError}
                dismiss={dismissError}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
