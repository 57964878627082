import moment from 'moment';

import { emptyMonth, StaffVisit, weekOfMonth } from 'Residents';

const convertReadings = (week: string, readings: StaffVisit[]) => {
  const visitReadingsPerWeek = readings.filter(
    entry => 'Week ' + weekOfMonth(moment(entry.timestamp)) === week
  );

  const visits = visitReadingsPerWeek.reduce(
    (acc, current) => acc + Number(current.value.numberOfVisits),
    0
  );
  return {
    visits,
    unit: visits === 1 ? 'visit' : 'visits'
  };
};

export const toMonthData = (data: StaffVisit[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
