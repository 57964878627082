import { axiosWrapper } from 'utils';

import { Caregiver, CaregiverListResponse } from '../types/caregiver.type';

export const getPaginatedCaregivers = (
  facilityId: string,
  page: number
) => async () => {
  const initialUrl = `/facilities/${facilityId}/caregivers`;
  const url = page ? `${initialUrl}?page=${page}` : initialUrl;
  const response = await axiosWrapper<
    CaregiverListResponse | { items: Caregiver[]; nextPage: string }
  >(url);

  return response.data;
};
