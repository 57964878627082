import React from 'react';
import { Tooltip } from 'recharts';

import style from './Tooltip.module.css';

const Content: React.FunctionComponent<{
  payload?: any;
}> = props => {
  if (props.payload[0]) {
    return (
      <div className={style.foodIntakeTooltip}>
        <span>{`${props.payload[0].payload.label}`}</span>
        <span>
          Food Intake: <b>{`${props.payload[0].payload.foods}`}</b>
          {` ${props.payload[0].payload.unit}`}
        </span>
      </div>
    );
  }
  return <></>;
};

export const FoodIntakeTooltip = <Tooltip content={Content} />;
