import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { RoomAssignment } from 'Caregivers';
import { useFetcher } from 'hooks';
import { useLocationContext } from 'Location';
import {
  getResidentsByWard,
  getRoomAssignments,
  getRoomsByWard,
  ResidentListItem
} from 'Residents';
import { Resident, Room } from 'Settings';

import { ResidentListEntry } from './ResidentListEntry';
import { ResidentListHeader } from './ResidentListHeader';

import style from './ResidentList.module.css';

export const ResidentList: React.FunctionComponent<{
  currentDragged: string;
  selectedDay: Date;
  setFetchActionStatus: React.Dispatch<
    React.SetStateAction<{
      loading: boolean;
      error: Error | null;
    }>
  >;
  setDragDisabled: (status: boolean) => void;
  styles?: any;
  scrollPosition: number;
  setAssignmentToRoomError: (value: Error | null) => void;
}> = ({
  currentDragged,
  styles,
  selectedDay,
  scrollPosition,
  setDragDisabled,
  setFetchActionStatus,
  setAssignmentToRoomError
}) => {
  const {
    location: { facility, ward: wardId },
    label
  } = useLocationContext();
  const [caregiverClicked, setCaregiverClicked] = useState('');
  const [roomClicked, setRoomClicked] = useState('');
  const [allButtonsDisabled, setAllButtonsDisabled] = useState(false);
  const [content, setContent] = useState<JSX.Element>();

  const residentsByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getResidentsByWard(facility.id, wardId, true);
    }
  }, [facility.id, wardId]);

  const roomsByWardAction = useMemo(() => {
    if (facility.id && wardId) {
      return getRoomsByWard(facility.id, wardId);
    }
  }, [facility.id, wardId]);

  const workingDay = useMemo(() => moment(selectedDay).format('YYYY-MM-DD'), [
    selectedDay
  ]);
  const placeholderMessage = useMemo(() => `No ${label.toLowerCase()}s`, [
    label
  ]);

  const roomAssignmentsByWardAction = useMemo(() => {
    if (wardId) {
      return getRoomAssignments({ workingDay, wardId });
    }
  }, [workingDay, wardId]);

  const {
    data: roomAssignments,
    error: roomAssignmentsError,
    loading: roomAssignmentsLoading,
    setRefetch: refetchRoomAssignments
  } = useFetcher<RoomAssignment[]>(roomAssignmentsByWardAction, []);

  const {
    data: { items: residentsByWard },
    error: residentsError,
    loading: residentsLoading,
    setRefetch: setResidentsRefetch
  } = useFetcher<{
    items: Resident[];
  }>(residentsByWardAction, { items: [] });

  const {
    data: { items: roomsByWard },
    error: roomsError,
    loading: roomsLoading,
    setRefetch: setRoomByWardRefetch
  } = useFetcher<{
    items: Room[];
  }>(roomsByWardAction, { items: [] });

  const refetch = () => {
    setResidentsRefetch();
    setRoomByWardRefetch();
  };

  const loading = residentsLoading || roomsLoading;
  const error = residentsError || roomsError;

  useEffect(() => {
    setFetchActionStatus({ loading, error });
  }, [loading, error, setFetchActionStatus]);

  const residentsByRooms = useMemo(() => {
    if (!roomsByWard.length) {
      return [];
    }
    return roomsByWard.reduce(
      (roomResidents: ResidentListItem[], currentRoom) => {
        const { id: currentRoomId, number: roomNumber, ward } = currentRoom;

        const residentsFromRoom: ResidentListItem[] = residentsByWard.reduce(
          (items: ResidentListItem[], resident) => {
            const { id, room, gender, name, photoUrl } = resident;

            if (room && room.ward && room.id === currentRoomId) {
              return [
                ...items,
                {
                  resident: {
                    id,
                    name,
                    gender,
                    photoUrl
                  },
                  room,
                  hasWarning: false
                } as ResidentListItem
              ];
            }
            return items;
          },
          [] as ResidentListItem[]
        );

        const currentRoomResidents = residentsFromRoom.length
          ? residentsFromRoom
          : [
              {
                room: { id: currentRoomId, number: roomNumber, ward }
              } as ResidentListItem
            ];

        return [...roomResidents, ...currentRoomResidents];
      },
      []
    );
  }, [residentsByWard, roomsByWard]);

  useEffect(() => {
    if (residentsByRooms.length === 0) {
      setDragDisabled(true);
    } else {
      setDragDisabled(false);
    }
  }, [residentsByRooms, setDragDisabled]);

  useEffect(() => {
    const list = residentsByRooms.map((listItem: ResidentListItem) => (
      <ResidentListEntry
        key={(listItem.resident && listItem.resident.id) || listItem.room.id}
        data={listItem}
        currentDragged={currentDragged}
        selectedDay={selectedDay}
        scrollPosition={scrollPosition}
        setCaregiverClicked={setCaregiverClicked}
        caregiverClicked={caregiverClicked}
        setRoomClicked={setRoomClicked}
        roomClicked={roomClicked}
        setAssignmentToRoomError={setAssignmentToRoomError}
        refetch={refetch}
        allButtonsDisabled={allButtonsDisabled}
        setAllButtonsDisabled={setAllButtonsDisabled}
        roomAssignments={roomAssignments}
        roomAssignmentsError={roomAssignmentsError}
        roomAssignmentsLoading={roomAssignmentsLoading}
        refetchRoomAssignments={refetchRoomAssignments}
      />
    ));

    if (list.length === 0) {
      setContent(
        <div className={style.placeholder}>
          <span>{placeholderMessage}</span>
        </div>
      );
    } else {
      setContent(<ul>{list}</ul>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allButtonsDisabled,
    caregiverClicked,
    currentDragged,
    placeholderMessage,
    residentsByRooms,
    roomAssignments,
    roomAssignmentsError,
    roomAssignmentsLoading,
    roomClicked,
    scrollPosition,
    selectedDay
  ]);

  return (
    <div className={style.list} style={styles}>
      <ResidentListHeader scrollPosition={scrollPosition} />
      {content}
    </div>
  );
};
