import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { ResidentContactListResponse } from '../types/resident.type';

export const getResidentContacts = async (
  residentId: string,
  page?: number
) => {
  const additionalParam = page
    ? new URLSearchParams({ page: page.toString() })
    : new URLSearchParams({ size: MAX_PAGE_SIZE.toString() });
  const response = await axiosWrapper<ResidentContactListResponse>(
    `/residents/${residentId}/related-contacts?${additionalParam}`
  );
  return response.data;
};
