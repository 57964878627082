import { User } from 'Settings';
import { axiosWrapper } from 'utils';

export const editUser = (user: User) => async () => {
  const response = await axiosWrapper<User>(
    `/users/${user.id}`,
    'PUT',
    null,
    user
  );

  return response.data;
};
