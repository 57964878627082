import React, {
  createContext,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';

import { useAuthState } from 'Auth';
import {
  CommunicationActionTypes,
  getChatRoomById,
  markMessageSeen,
  MessagingHistoryConversation,
  useCommunicationContext
} from 'Communication';
import {
  ComMessageType,
  ComMessageUpdate,
  ComUpdateTypes,
  SocketActions,
  useSocketContext
} from 'Socket';
import { ChatId, LoginId } from 'types';

import { useLocationState } from 'Location';
import { ChatRoom, MessagingActionTypes } from '../types';

type Action =
  | { type: MessagingActionTypes.SET_CHAT_ROOMS; payload: ChatRoom[] }
  | { type: MessagingActionTypes.ADD_CHAT_ROOM; payload: ChatRoom }
  | { type: MessagingActionTypes.REMOVE_CHAT_ROOM; payload: ChatId }
  | {
      type: MessagingActionTypes.UPDATE_LAST_MESSAGE_ON_CHAT_ROOM;
      payload: any;
    }
  | {
      type: MessagingActionTypes.UPDATE_MESSAGE_SEEN_ON_CHAT_ROOM;
      payload: {
        chatRoomId: string;
        loginId: string;
        messageThatWasSeen: string;
      };
    }
  | {
      type: MessagingActionTypes.RESET_CHAT_ROOMS;
    }
  | {
      type: MessagingActionTypes.UPDATE_CHAT_ROOMS;
      payload: {
        loginId: string;
        update: ComMessageUpdate;
      };
    }
  | {
      type: MessagingActionTypes.UPDATE_CHAT_NAME;
      payload: {
        chatRoomId: ChatId;
        chatName: string;
      };
    };

interface State {
  chatRooms: ChatRoom[];
}

type Dispatch = (action: Action) => void;

const initialChatRooms = {
  chatRooms: [] as ChatRoom[]
} as State;

const MessagingStateContext = createContext<State>(initialChatRooms);
const MessagingDispatchContext = createContext<Dispatch | undefined>(undefined);

export const messagingReducer: Reducer<State, Action> = (
  state: State,
  action: Action
) => {
  const { chatRooms } = state;

  switch (action.type) {
    case MessagingActionTypes.SET_CHAT_ROOMS: {
      const filtered = action.payload.filter(newChat => {
        return !state.chatRooms.some(chat => chat.id === newChat.id);
      });

      return { chatRooms: chatRooms.concat(filtered) };
    }
    case MessagingActionTypes.ADD_CHAT_ROOM: {
      return {
        chatRooms: [action.payload, ...chatRooms]
      };
    }
    case MessagingActionTypes.REMOVE_CHAT_ROOM: {
      const filtered = chatRooms.filter(chat => chat.id !== action.payload);
      return {
        chatRooms: filtered
      };
    }
    case MessagingActionTypes.UPDATE_MESSAGE_SEEN_ON_CHAT_ROOM: {
      const newChatRooms = chatRooms.map(chat => {
        const { loginId, messageThatWasSeen, chatRoomId } = action.payload;

        return chat.id === chatRoomId &&
          chat.lastMessage?.id === messageThatWasSeen &&
          (chat.messageSeenState as { [key: string]: string })[loginId] !==
            messageThatWasSeen
          ? ({
              ...chat,
              messageSeenState: {
                ...chat.messageSeenState,
                [loginId as LoginId]: messageThatWasSeen
              }
            } as ChatRoom)
          : chat;
      });

      return { chatRooms: newChatRooms };
    }
    case MessagingActionTypes.RESET_CHAT_ROOMS: {
      return initialChatRooms;
    }
    case MessagingActionTypes.UPDATE_CHAT_ROOMS: {
      const { loginId, update } = action.payload;

      const updatedChatRooms = chatRooms.map(chat => {
        let participants = [...chat.participants];

        if (
          update.messageType === ComUpdateTypes.PARTICIPANT_ADDED_MESSAGE &&
          update.values?.participantLoginId
        ) {
          participants.push(update.values.participantLoginId);
        }

        if (
          update.messageType === ComUpdateTypes.PARTICIPANT_DELETED_MESSAGE &&
          update.values?.participantLoginId
        ) {
          participants = participants.filter(
            id => id !== update.values.participantLoginId
          );
        }

        return chat.id === update.chatRoomId
          ? ({
              ...chat,
              participants,
              lastMessage: {
                chatRoomId: update.chatRoomId,
                id: update.id,
                loginSenderId: update.loginSenderId,
                participantLoginId: update.values.participantLoginId,
                messageType: update.messageType.toLowerCase(),
                timestamp: update.timestamp,
                textMessage: update.values.textMessage,
                fileName: update.values.fileName,
                fileUri: update.values.fileUri
              },
              messageSeenState:
                /**
                 * @description we make a positive update regarding message seen on current conversation
                 * so we don't get weird UI behaviour
                 * when message is first bolded and
                 * after a sec becomes not bolded
                 *
                 * @condition I send a message
                 * @return mark it as seen
                 */
                loginId === update.loginSenderId
                  ? {
                      ...chat.messageSeenState,
                      [loginId]: update.id
                    }
                  : { ...chat.messageSeenState }
            } as ChatRoom)
          : chat;
      });

      return { chatRooms: updatedChatRooms };
    }
    case MessagingActionTypes.UPDATE_CHAT_NAME: {
      const { chatRoomId, chatName } = action.payload;

      const indexToUpdate = chatRooms.findIndex(chat => chat.id === chatRoomId);

      if (indexToUpdate === -1) {
        return state;
      }

      const updatedChatRooms = [...chatRooms];
      updatedChatRooms[indexToUpdate] = {
        ...chatRooms[indexToUpdate],
        description: chatName
      };

      return { chatRooms: updatedChatRooms };
    }
    default:
      return state;
  }
};

export const MessagingProvider: (props: {
  children: React.ReactNode;
  initialState?: State;
}) => JSX.Element = ({ children, initialState = initialChatRooms }) => {
  const [state, dispatch] = useReducer(messagingReducer, initialState);
  const { chatRooms } = state;
  const [updateType, setUpdateType] = useState<ComMessageType | undefined>(
    undefined
  );
  const [update, setUpdate] = useState<ComMessageUpdate | null>(null);
  const [fetchingChat, setFetchingChat] = useState<boolean>(false);

  const [
    {
      activeConversation: { id: activeConversationId }
    },
    commDispatch
  ] = useCommunicationContext();
  const [{ comUpdate }, { dispatch: socketDispatch }] = useSocketContext();
  const { loginId } = useAuthState();
  const {
    facility: { id: facilityId },
    ward: wardId
  } = useLocationState();

  useEffect(() => {
    dispatch({
      type: MessagingActionTypes.RESET_CHAT_ROOMS
    });
  }, [wardId, facilityId]);

  useEffect(() => {
    if (comUpdate) {
      setUpdate(comUpdate);
    }
  }, [comUpdate]);

  useEffect(() => {
    if (update) {
      setUpdateType(update.messageType);
    }
  }, [update]);

  useEffect(() => {
    if (!updateType) {
      return undefined;
    }

    switch (updateType) {
      case ComUpdateTypes.PARTICIPANT_ADDED_MESSAGE: {
        if (fetchingChat) {
          return setUpdateType(undefined);
        }

        const chatShouldBeUpdated = chatRooms.some(
          chatRoom => chatRoom.id === update!.chatRoomId
        );

        if (
          !chatShouldBeUpdated &&
          update &&
          update.loginSenderId !== loginId
        ) {
          setFetchingChat(true);

          getChatRoomById(update!.chatRoomId)
            .then(chat => {
              dispatch({
                type: MessagingActionTypes.ADD_CHAT_ROOM,
                payload: chat
              });
            })
            .finally(() => setFetchingChat(false));
        } else {
          dispatch({
            type: MessagingActionTypes.UPDATE_CHAT_ROOMS,
            payload: {
              loginId,
              update: update!
            }
          });
        }

        if (
          activeConversationId === update!.chatRoomId &&
          loginId !== update!.loginSenderId
        ) {
          markMessageSeen(update!.chatRoomId, update!.id);
        }

        setUpdateType(undefined);
        break;
      }
      case ComUpdateTypes.FILE_MESSAGE:
      case ComUpdateTypes.TEXT_MESSAGE: {
        if (fetchingChat) {
          return setUpdateType(undefined);
        }

        const chatShouldBeUpdated = chatRooms.some(
          chatRoom => chatRoom.id === update!.chatRoomId
        );

        if (!chatShouldBeUpdated) {
          setFetchingChat(true);

          getChatRoomById(update!.chatRoomId)
            .then(chat => {
              dispatch({
                type: MessagingActionTypes.ADD_CHAT_ROOM,
                payload: chat
              });
            })
            .then(() => setFetchingChat(false));
        } else {
          dispatch({
            type: MessagingActionTypes.UPDATE_CHAT_ROOMS,
            payload: {
              loginId,
              update: update!
            }
          });
        }

        if (
          activeConversationId === update!.chatRoomId &&
          loginId !== update!.loginSenderId
        ) {
          markMessageSeen(update!.chatRoomId, update!.id);
        }

        setUpdateType(undefined);
        break;
      }
      case ComUpdateTypes.MESSAGE_SEEN_MESSAGE: {
        dispatch({
          type: MessagingActionTypes.UPDATE_MESSAGE_SEEN_ON_CHAT_ROOM,
          payload: {
            chatRoomId: update!.chatRoomId,
            loginId: update!.loginSenderId,
            messageThatWasSeen: update!.values.messageThatWasSeen
          }
        });

        setUpdateType(undefined);
        break;
      }
      case ComUpdateTypes.PARTICIPANT_DELETED_MESSAGE: {
        if (update?.values?.participantLoginId === loginId) {
          dispatch({
            type: MessagingActionTypes.REMOVE_CHAT_ROOM,
            payload: update!.chatRoomId
          });
          commDispatch({
            type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
            payload: {} as MessagingHistoryConversation
          });
        } else {
          dispatch({
            type: MessagingActionTypes.UPDATE_CHAT_ROOMS,
            payload: {
              loginId,
              update: update!
            }
          });
        }

        setUpdateType(undefined);
        break;
      }
      case ComUpdateTypes.CHAT_ROOM_RENAMED_MESSAGE: {
        dispatch({
          type: MessagingActionTypes.UPDATE_CHAT_NAME,
          payload: {
            chatName: update!.values.chatRoomName,
            chatRoomId: update!.chatRoomId
          }
        });
        setUpdateType(undefined);
        break;
      }
      default:
        return undefined;
    }

    socketDispatch({
      type: SocketActions.RESET_SOCKET
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateType, chatRooms, fetchingChat]);

  return (
    <MessagingStateContext.Provider value={state}>
      <MessagingDispatchContext.Provider value={dispatch}>
        {children}
      </MessagingDispatchContext.Provider>
    </MessagingStateContext.Provider>
  );
};

export const useMessagingState = () => {
  const context = useContext(MessagingStateContext);
  if (context === undefined) {
    throw new Error(
      'useMessagingState must be used within a MessagingProvider'
    );
  }
  return context;
};

export const useMessagingDispatch = () => {
  const context = useContext(MessagingDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useMessagingDispatch must be used within a MessagingProvider'
    );
  }
  return context;
};

export const useMessagingContext = (): [State, Dispatch] => {
  return [useMessagingState(), useMessagingDispatch()];
};
