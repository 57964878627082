import React, { useCallback, useEffect, useState } from 'react';

import { DismissibleError, Modal } from 'components';
import {
  useModalMeasurements,
  usePoster,
  usePrevious,
  useQueryParams,
  useSubmitError
} from 'hooks';
import { useLocationContext } from 'Location';
import { editResidentDetails } from 'Residents';
import { Resident } from 'Settings';

import {
  CardsActionTypes,
  PartialCard,
  useCardsDispatch
} from './contexts/cards.context';
import { EditResidentForm } from './EditResidentForm';
import { useResidentContext } from './resident.context';

import styles from './EditResidentDetails.module.css';

export const EditResidentDetails: React.FunctionComponent<{
  toggle: () => void;
  isOpen: boolean;
  history: any;
  wardId: string;
}> = ({ toggle, isOpen, history, wardId }) => {
  const query = useQueryParams();
  const [{ residentDetails }, dispatch] = useResidentContext();
  const cardsDispatch = useCardsDispatch();
  const {
    location: { facility },
    dispatch: locationDispatch,
    label
  } = useLocationContext();

  const [updatedResident, setUpdatedResident] = useState<Resident>(
    residentDetails
  );
  const { setAction, loading: onSaveLoading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, onSaveLoading);
  const lastOpenState = usePrevious(isOpen);
  const { modalOffsetLeft, modalWidth } = useModalMeasurements(
    'ResidentDetails',
    'MainContent',
    isOpen
  );
  const computedOffsetLeft = modalOffsetLeft > 0 ? modalOffsetLeft : 15;

  const onUpdate = (updatedValues: Resident) => {
    const { id, cellPhoneNumber, room, badge } = updatedValues;
    setAction(
      editResidentDetails(facility.id, { id, cellPhoneNumber, room, badge })
    );
    setUpdatedResident(updatedValues);
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const onCancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    toggle();
  };

  const handleContextChange = useCallback(() => {
    const updates = updatedResident.room ? updatedResident : ({} as Resident);
    dispatch({ type: 'UPDATE_RESIDENT', payload: updates });

    if (updates.room) {
      cardsDispatch({
        type: CardsActionTypes.EDIT_CARDS,
        payload: {
          wardName: updates.room.ward.name,
          roomNumber: updates.room.number,
          oldRoomNumber: residentDetails.room?.number,
          cardId: updates.id,
          oldRoomId: residentDetails.room?.id,
          newRoomId: updates.room.id,
          wardId,
          facilityId: facility.id
        } as PartialCard
      });
    }

    const { room } = residentDetails;
    const { room: updatedRoom } = updatedResident;
    const previousWard = room && room.ward ? room.ward.id : '';
    const updatedWard =
      updatedRoom && updatedRoom.ward ? updatedRoom.ward.id : '';

    // go to updated ward
    if (updatedWard && updatedWard !== previousWard) {
      query.set('ward', updatedWard);
      history.push({
        search: `?${query}`
      });
      locationDispatch({ type: 'CHANGE_WARD', payload: updatedWard });
    }
  }, [
    locationDispatch,
    history,
    query,
    residentDetails,
    updatedResident,
    dispatch,
    cardsDispatch,
    wardId,
    facility.id
  ]);

  useEffect(() => {
    if (submitted && !onSaveLoading && !error) {
      handleContextChange();
      toggle();
      setSubmitted(false);
    }
  }, [
    submitted,
    onSaveLoading,
    error,
    handleContextChange,
    setSubmitted,
    toggle
  ]);

  // close modal when ward or facility changes
  useEffect(() => {
    if (isOpen && lastOpenState) {
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, facility.id, wardId, toggle]); // ignoring lastOpenState since it's a ref and we get the latest value but don't want to trigger this when its value changes

  return (
    <Modal
      isShowing={Object.keys(residentDetails).length > 0 && isOpen}
      toggle={toggle}
    >
      <div className={styles.modalBackdrop}>
        <div
          className={styles.modalContent}
          style={{ marginLeft: computedOffsetLeft, width: modalWidth }}
        >
          <EditResidentForm
            onSave={onUpdate}
            onCancel={onCancel}
            resident={residentDetails}
            onSaveLoading={onSaveLoading}
          >
            {error && (
              <DismissibleError
                name={label}
                visible={isErrorShowing}
                error={error}
                dismiss={dismissError}
              />
            )}
          </EditResidentForm>
        </div>
      </div>
    </Modal>
  );
};
