import React, { useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelList,
  Table
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';
import { getHeight } from 'utils';

import { Facility } from 'Settings/types/facility.type';

import { getPaginatedResidents, getResidentsByQuery } from '../actions';
import {
  DEFAULT_PAGE,
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH,
  RESIDENT_LIST_PLACEHOLDER
} from '../constants/index';
import { Resident } from '../types/resident.type';
import { AddResidentForm } from './AddResidentForm';
import { ResidentTableRow } from './ResidentTableRow';

import stylesModal from 'components/Modal/Modal.module.css';

export const ResidentList: React.FunctionComponent<{
  facility: Facility;
  selectedResidentId: string;
  selectResident: (id: string) => void;
  modifiedResident: Resident;
  resetActiveResident: () => void;
  residentDetails: (res: Resident) => void;
}> = ({
  facility,
  selectedResidentId,
  selectResident,
  modifiedResident,
  resetActiveResident,
  residentDetails
}) => {
  const nameLabel = `${residentLabels[facility.type]} Name`;
  const numberLabel = `${residentLabels[facility.type]} Number`;

  const ResidentListTableColumns: Column[] = [
    { name: nameLabel, align: 'left', cols: 3 },
    { name: 'Ward - Room', align: 'left', cols: 2 },
    {
      name: numberLabel,
      align: 'left',
      cols: 2
    },
    { name: 'Status', align: 'center', cols: 1 }
  ];

  const [page, setPage] = useState<string>(DEFAULT_PAGE);
  const [searchedResidentsPage, setSearchedResidentsPage] = useState(
    DEFAULT_PAGE
  );
  const [residents, setResidents] = useState<Resident[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);

  const getResidentsMemo = useMemo(
    () => getPaginatedResidents(facility.id, page),
    [facility.id, page]
  );

  const {
    data: { items: residentList, nextPage },
    error,
    loading,
    setRefetch
  } = useFetcher<{
    items: Resident[];
    nextPage?: string;
  }>(getResidentsMemo, { items: [] });

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getResidentsByQuery(
        facility.id,
        searchTerm,
        searchedResidentsPage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedResidentsPage]);

  const {
    data: { items: searchedResidents, nextPage: nextPageForSearchedResidents },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{ items: Resident[]; nextPage?: string }>(memoSearchAction, {
    items: []
  });

  const { isShowing, toggle } = useModal();

  const modalClosed = () => {
    setResidents([]);
    if (page === DEFAULT_PAGE) {
      setRefetch();
    } else {
      setPage(DEFAULT_PAGE);
    }
    toggle();
  };

  const scrollAction = () => {
    if (isFetching && residentList.length && nextPage && !isSearching) {
      setPage(nextPage);
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedResidents.length &&
      nextPageForSearchedResidents &&
      isSearching
    ) {
      setSearchedResidentsPage(nextPageForSearchedResidents);
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const onAdd = () => {
    toggle();
  };

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedResidentsPage(DEFAULT_PAGE);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setResidents([]);
      setPage(DEFAULT_PAGE);
      setRefetch();
    }
  };

  useEffect(() => {
    if (residentList.length !== 0) {
      setResidents(prevState => [...prevState, ...residentList]);
    }
  }, [residentList]);

  useEffect(() => {
    const selectedResidentIndex = residents.some(resident => {
      return resident.id === selectedResidentId;
    });

    if (nextPage && selectedResidentId && !selectedResidentIndex) {
      setPage(nextPage!);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residents]);

  useEffect(() => {
    setResidents(currentResidents => {
      return currentResidents.map(resident =>
        resident.id === modifiedResident.id ? modifiedResident : resident
      );
    });
  }, [modifiedResident]);

  useEffect(() => {
    if (!residents.length) {
      return;
    }
    const selectedResident = residents.find(
      ({ id }) => id === selectedResidentId
    );
    if (!selectedResident && !nextPage) {
      resetActiveResident();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residents, selectedResidentId, resetActiveResident]);

  useEffect(() => {
    if (searchError) {
      return setResidents([]);
    }
    if (searchedResidentsPage > DEFAULT_PAGE) {
      setResidents(prevState => [...prevState, ...searchedResidents]);
    } else {
      setResidents(searchedResidents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedResidents, searchError]);

  useEffect(() => {
    const selectedResident = residents.find(
      ({ id }) => id === selectedResidentId
    );
    residentDetails(selectedResident as Resident);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResidentId, residents]);

  const render = (rowValues: Resident) => (
    <ResidentTableRow
      key={rowValues.id}
      values={rowValues}
      selectResident={selectResident}
      isSelected={selectedResidentId === rowValues.id}
    />
  );

  const isDataAvailable = residents.length > 0;
  const title = `${residentLabels[facility.type]}s`;
  const searchInputName = `${title.toLowerCase()} by name, number, ward and room`;

  return (
    <PanelList
      title={title}
      onAdd={onAdd}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={searchInputName}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<Resident>
          columns={ResidentListTableColumns}
          rows={residents}
          placeholder={RESIDENT_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && <LoadingPlaceholder fullHeight={false} />}
      {error && !loading && <FetchError error={error} />}

      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentLeftAlign}>
            <div
              className={stylesModal.modalWithVerticalBar}
              style={{
                marginTop: getHeight(title)
              }}
            >
              <AddResidentForm
                onResidentCreate={selectResident}
                toggle={modalClosed}
                facility={facility}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PanelList>
  );
};
