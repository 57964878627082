import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { FetcherDropdown } from 'components';
import { headerDropdownStyles } from 'consts';
import { useFetcher, useQueryParams } from 'hooks';
import { useLocationContext } from 'Location';
import { Facility, getFacilityList, getWardsByFacility } from 'Settings';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { sortByKey } from 'utils';

import styles from './Header.module.css';

interface RouterParams {
  activeTab: string;
}

const facilityDropDownStyle = {
  width: '366px',
  height: '25px'
};

const wardDropDownStyle = {
  width: '103px',
  height: '25px'
};

export const LocationFilters = ({
  history
}: RouteComponentProps<RouterParams>) => {
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [wards, setWards] = useState<MappedWard[]>([]);
  const {
    location: { facility: selectedFacility, ward: selectedWard },
    dispatch
  } = useLocationContext();
  const query = useQueryParams();

  const facilityId = query.get('facility') || '';
  const wardId = query.get('ward') || '';

  const facilityHistoryPush = useCallback(
    (option: string) => {
      if (option !== facilityId) {
        query.set('facility', option);
        query.delete('ward');

        history.push({ search: `?${query}` });
        setWards([]);
      }
    },
    [history, query, facilityId]
  );

  const wardHistoryPush = useCallback(
    (option: string) => {
      if (option) {
        query.set('ward', option);
        history.push({ search: `?${query}` });
      }
    },
    [history, query]
  );

  const {
    data: facilitiesData,
    error: facilitiesError,
    loading: facilitiesLoading
  } = useFetcher<Facility[]>(getFacilityList, []);

  const getActiveWardsMemo = useMemo(() => {
    if (facilityId) {
      return getWardsByFacility(facilityId, true);
    }
  }, [facilityId]);

  const {
    data: wardsData,
    error: wardError,
    loading: wardLoading
  } = useFetcher<MappedWard[]>(getActiveWardsMemo, []);

  const hasError = facilitiesError || wardError;
  const isLoading = facilitiesLoading || wardLoading;

  useEffect(() => {
    if (wardsData.length === 0) {
      // remove ward from url if list is empty
      dispatch({ type: 'CHANGE_WARD', payload: '' });
    }
    setWards(sortByKey(wardsData, 'name'));
  }, [wardsData, dispatch]);

  useEffect(() => {
    if (facilitiesData.length === 0) {
      // remove facility and ward from url if list is empty
      dispatch({ type: 'CHANGE_FACILITY', payload: {} as Facility });
    }
    setFacilities(sortByKey(facilitiesData, 'name'));
    dispatch({ type: 'SET_FACILITIES', payload: facilitiesData });
  }, [facilitiesData, dispatch]);

  const matchedFacility = useMemo(
    () =>
      facilityId && facilities
        ? (facilities.find(facility => facilityId === facility.id) as Facility)
        : null,
    [facilityId, facilities]
  );

  // preselect facility on load
  useEffect(() => {
    if (!facilityId && facilities.length) {
      if (selectedFacility.id) {
        facilityHistoryPush(selectedFacility.id);
      } else {
        facilityHistoryPush(facilities[0].id);
        dispatch({ type: 'CHANGE_FACILITY', payload: facilities[0] });
      }
    }
    if (facilityId && !selectedFacility.id && !hasError) {
      if (matchedFacility) {
        dispatch({
          type: 'CHANGE_FACILITY',
          payload: matchedFacility
        });
      }
    }
  }, [
    facilityId,
    facilityHistoryPush,
    selectedFacility,
    dispatch,
    hasError,
    matchedFacility,
    facilities
  ]);

  // preselect ward on load
  useEffect(() => {
    const firstWardId = wards[0] ? wards[0].id : '';
    if (!wards.some(ward => ward.id === wardId) && firstWardId) {
      if (selectedWard) {
        wardHistoryPush(selectedWard);
      } else {
        wardHistoryPush(firstWardId);
        dispatch({ type: 'CHANGE_WARD', payload: firstWardId });
      }
    }
    if (wardId && wards.some(ward => ward.id === wardId) && !selectedWard) {
      dispatch({ type: 'CHANGE_WARD', payload: wardId });
    }
  }, [dispatch, selectedWard, wardHistoryPush, wardId, wards]);

  useEffect(() => {
    dispatch({ type: 'SET_FILTERS_ERROR', payload: hasError });
  }, [hasError, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_FILTERS_LOADING', payload: isLoading });
  }, [isLoading, dispatch]);

  const onFacilitySelect = (option: string) => {
    const facilityOption = facilities.find(facility => facility.id === option);
    if (facilityOption) {
      dispatch({ type: 'CHANGE_FACILITY', payload: facilityOption });
      facilityHistoryPush(option);
    }
  };
  const onWardSelect = (option: string) => {
    dispatch({ type: 'CHANGE_WARD', payload: option });
    wardHistoryPush(option);
  };

  const facilityOptions = facilities.map(facility => ({
    value: facility.id,
    label: facility.name
  }));

  const wardOptions = facilityId
    ? wards.map(ward => ({
        value: ward.id,
        label: ward.name
      }))
    : [];

  const matchedWard = useMemo(
    () =>
      wardId && wards
        ? (wards.find(ward => wardId === ward.id) as MappedWard)
        : null,
    [wardId, wards]
  );

  useEffect(() => {
    if (matchedWard) {
      dispatch({
        type: 'SET_WARDS_DETAILS',
        payload: matchedWard
      });
    }
  }, [dispatch, matchedWard]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!matchedFacility && facilityId) {
      dispatch({ type: 'CHANGE_FACILITY', payload: {} as Facility });
      facilityHistoryPush('');
      return;
    }
    if (!matchedWard && wardId) {
      dispatch({ type: 'CHANGE_WARD', payload: '' });
      wardHistoryPush('');
    }
  }, [
    matchedWard,
    matchedFacility,
    dispatch,
    facilityHistoryPush,
    wardHistoryPush,
    wardId,
    facilityId,
    isLoading
  ]);

  return (
    <>
      <div className={styles.headerFacilityDropdown}>
        <FetcherDropdown
          style={facilityDropDownStyle}
          error={facilitiesError}
          loading={facilitiesLoading}
          options={facilityOptions}
          onChange={onFacilitySelect}
          value={matchedFacility ? matchedFacility.id : null}
          placeholder="Facility"
          dropdownStyles={headerDropdownStyles}
          isClearable={false}
        />
      </div>
      <div className={styles.headerWardDropdown}>
        <FetcherDropdown
          error={wardError || facilitiesError}
          loading={wardLoading}
          style={wardDropDownStyle}
          options={wardOptions}
          onChange={onWardSelect}
          value={matchedWard ? matchedWard.id : null}
          placeholder="Ward"
          dropdownStyles={headerDropdownStyles}
          isClearable={false}
        />
      </div>
    </>
  );
};
