import classnames from 'classnames';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useAuthDispatch } from 'Auth';
import { CaregiverStatus, getCaregiverStatus, StatusType } from 'Caregivers';
import {
  CommunicationActionTypes,
  useCommunicationDispatch
} from 'Communication';
import { FetchError, WithPermissions } from 'components';
import { useFetcher, useModal } from 'hooks';
import { useLocationState } from 'Location';
import {
  NotificationActionTypes,
  useNotificationDispatch
} from 'Notifications';

import { getLoggedUserProfile } from './actions';
import { CaregiverWardStatus } from './CaregiverWardStatus';
import { ManageAccount } from './ManageAccount';
import { ProfileHeader } from './ProfileHeader';
import { ProfileLoadingPlaceholder } from './ProfileLoadingPlaceholder';
import {
  CaregiverProfileDetails,
  isCaregiverProfile,
  UserProfileDetails
} from './types';

import styles from './MyProfileDetails.module.css';

export const MyProfileDetails: React.FunctionComponent<{
  isDropdownVisible?: boolean;
  toggleStyle: Dispatch<SetStateAction<boolean>>;
}> = ({ isDropdownVisible, toggleStyle }) => {
  const [savedChanges, setSavedChanges] = useState(false);
  const [caregiverStatus, setCaregiverStatus] = useState<
    StatusType | undefined
  >();
  const [userDetails, setUserDetails] = useState<
    UserProfileDetails | CaregiverProfileDetails
  >({} as UserProfileDetails | CaregiverProfileDetails);
  const [userAccountChanged, setUserAccountChanged] = useState(false);

  const { dispatch: authDispatch, logout } = useAuthDispatch();
  const {
    isShowing: isManageAccountViewOn,
    toggle: toggleManageAccountView
  } = useModal();
  const {
    facility: { id: facilityId },
    ward: wardId
  } = useLocationState();
  const notificationDispatch = useNotificationDispatch();
  const comDispatch = useCommunicationDispatch();

  const {
    data: userData,
    error: userError,
    loading: userLoading,
    setRefetch: refetchUser
  } = useFetcher<UserProfileDetails | CaregiverProfileDetails>(
    getLoggedUserProfile,
    {} as UserProfileDetails | CaregiverProfileDetails
  );

  const getCaregiverStatusAction = useMemo(() => {
    if (isCaregiverProfile(userData) && facilityId && wardId) {
      return getCaregiverStatus(userData.id, facilityId, wardId);
    }
  }, [facilityId, wardId, userData]);

  const {
    data: statusData,
    error: statusError,
    loading: statusLoading
  } = useFetcher<CaregiverStatus[]>(getCaregiverStatusAction, []);

  const loading = userLoading || statusLoading;
  const fetchError = userError || statusError;

  const onUpdateAccount = (shouldReload: boolean) => {
    setSavedChanges(true);
    refetchUser();
    setUserAccountChanged(shouldReload);
  };

  const onManageAccountClose = () => {
    toggleManageAccountView();

    if (userAccountChanged) {
      setUserAccountChanged(false);
      authDispatch({ type: 'SET_RELOAD', payload: true });
    }
  };

  const logoutUser = () => {
    logout();
    notificationDispatch({ type: NotificationActionTypes.CLEAR });
    comDispatch({ type: CommunicationActionTypes.CLEAR_COMMUNICATION });
  };

  const { id: userId, name, role } = userDetails;

  useEffect(() => {
    setUserDetails(userData);
    authDispatch({ type: 'SET_LOGGED_USER', payload: userData });
  }, [authDispatch, userData]);

  useEffect(() => {
    toggleManageAccountView(false);
    setUserAccountChanged(false);
    if (isDropdownVisible) {
      refetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownVisible]); // trigger only when isDropdownVisible changes; no need to include it as dep since it's called with param and doesn't depend on state

  useEffect(() => {
    if (!loading) {
      setSavedChanges(false);
    }
  }, [loading]);

  useEffect(() => {
    const wardStatus = statusData.find(({ wardId: id }) => wardId === id);
    setCaregiverStatus(wardStatus ? wardStatus.status : wardStatus);
  }, [statusData, wardId]);

  useEffect(() => {
    toggleStyle(!isManageAccountViewOn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManageAccountViewOn]);

  return (
    <>
      {!fetchError && loading && !savedChanges ? (
        <ProfileLoadingPlaceholder />
      ) : (
        <>
          {fetchError && (
            <div className={styles.fetchError}>
              <FetchError error={fetchError} />
            </div>
          )}
          {!fetchError && (
            <>
              {isManageAccountViewOn ? (
                <ManageAccount
                  onClose={onManageAccountClose}
                  onSave={onUpdateAccount}
                  isOpen={isManageAccountViewOn}
                  userDetails={userDetails}
                  status={caregiverStatus}
                />
              ) : (
                <>
                  <ProfileHeader
                    name={name}
                    title={role}
                    toggleEditMode={toggleManageAccountView}
                    status={caregiverStatus}
                    photoUrl={userData.photoUrl}
                  />
                  <WithPermissions
                    action="profile:manageStatus"
                    data={{ userData }}
                    yes={
                      <CaregiverWardStatus
                        isDropdownVisible={!!isDropdownVisible}
                        caregiverId={userId}
                        onCaregiverStatusChange={setCaregiverStatus}
                      />
                    }
                  />
                </>
              )}
            </>
          )}

          <button
            className={classnames(styles.logoutBtn, {
              [styles.hidden]: isManageAccountViewOn
            })}
            onClick={logoutUser}
            data-testid="logout-button"
          >
            sign out
          </button>
        </>
      )}
    </>
  );
};
