import { MAX_PAGE_SIZE } from 'consts';
import { NotificationListResponse } from 'Notifications';
import { axiosWrapper } from 'utils';

export const getWardNotifications = (
  facilityId: string,
  wardId: string
) => async () => {
  const response = await axiosWrapper<NotificationListResponse>(
    `/facilities/${facilityId}/wards/${wardId}/alarms?size=${MAX_PAGE_SIZE}`
  );

  return response.data.items;
};
