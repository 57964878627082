import React from 'react';

import styles from './Measurement.module.css';

export const Measurement: React.FunctionComponent<{
  value?: string;
  unit?: string;
}> = ({ value, unit }) => {
  return (
    <div className={styles.readingWrapper}>
      {value && <h3 className={styles.measurement}>{value}</h3>}
      {!value && <h3 className={styles.measurement}>&#8212;</h3>}
      {value && unit && <span className={styles.unit}>{unit}</span>}
    </div>
  );
};
