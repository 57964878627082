import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { generateTimeInteval } from './time.utils';

import styles from './TimePicker.module.css';

type TimePickerKey = 'hours' | 'minutes' | 'seconds';
const hourOptions = generateTimeInteval(24);
const minuteOptions = generateTimeInteval(60);

export const TimePicker: React.FunctionComponent<{
  onChange: (key: TimePickerKey, value: string) => void;
  value: {
    hours: string;
    minutes: string;
    seconds: string;
  };
  placeholder?: string;
}> = ({ onChange, value, placeholder = '00:00:00' }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  // on mount and unmount
  useEffect(() => {
    const handleClick = (evt: any) => {
      if (menuRef.current !== null && menuRef.current.contains(evt.target)) {
        return;
      }
      setOptionsVisible(false);
    };

    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  const { hours, minutes, seconds } = value;
  const inputValue = !hours ? '' : `${hours}:${minutes}:${seconds}`;

  const onChangeSelection = (evt: any, key: TimePickerKey) => {
    const { value: newValue } = evt.target.dataset;
    onChange(key, newValue);
  };
  const onFocus = () => {
    setOptionsVisible(true);
  };
  const onSelect = (name: TimePickerKey) => (evt: any) => {
    onChangeSelection(evt, name);
  };

  const renderOptionsContent = (options: string[], key: TimePickerKey) =>
    options.map(option => (
      <li
        key={option}
        onClick={onSelect(key)}
        className={classnames(styles.option, {
          [styles.selected]: option === value[key]
        })}
        data-value={option}
      >
        {option}
      </li>
    ));

  return (
    <div className={styles.wrapper} ref={menuRef}>
      <input
        type="text"
        readOnly={true}
        value={inputValue}
        className={styles.timeInput}
        onFocus={onFocus}
        placeholder={placeholder}
      />
      <div
        className={classnames(styles.dropdownsWrapper, {
          [styles.optionsVisible]: optionsVisible
        })}
      >
        <ul id="hours" className={styles.timeSelect}>
          {renderOptionsContent(hourOptions, 'hours')}
        </ul>
        <ul id="minutes" className={styles.timeSelect}>
          {renderOptionsContent(minuteOptions, 'minutes')}
        </ul>
        <ul id="seconds" className={styles.timeSelect}>
          {renderOptionsContent(minuteOptions, 'seconds')}
        </ul>
      </div>
    </div>
  );
};
