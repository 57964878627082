import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { bloodPressureInterval } from '../Intervals';
import {
  BloodPressureDiastolicLine,
  BloodPressureSystolicLine
} from '../Lines';
import { BloodPressureToolTip } from '../ToolTips';

export const BloodPressureChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    systolicValue?: number;
    diastolicValue?: number;
    hasWarning?: boolean | undefined;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={bloodPressureInterval}
      data={data}
      tooltip={BloodPressureToolTip}
      lineOne={BloodPressureSystolicLine('#e73827')}
      lineTwo={BloodPressureDiastolicLine('#e73827')}
      color="#e73827"
      allowHighlight={allowHighlight}
    />
  );
};
