import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Column,
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelList,
  Table
} from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useInfiniteScroll, useModal } from 'hooks';
import { Facility, getPaginatedWards, getWardsByQuery } from 'Settings';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { WardId } from 'types';
import { getHeight } from 'utils';

import {
  DEFAULT_SEARCH_TERM,
  MIN_LENGTH_FOR_TRIGGERING_SEARCH,
  WARD_LIST_PLACEHOLDER,
  WARD_SEARCH_INPUT_NAME,
  WARD_TITLE
} from '../constants/index';
import { AddWardForm } from './AddWardForm';
import { WardTableRow } from './WardTableRow';

import stylesModal from 'components/Modal/Modal.module.css';

export const WardList: React.FunctionComponent<{
  facility: Facility;
  selectWard: (ward: MappedWard) => void;
  wardId: string;
  lastModifiedWard: MappedWard | null;
}> = ({ facility, selectWard, wardId, lastModifiedWard }) => {
  const WardListTableColumns: Column[] = [
    { name: 'Ward Name', align: 'left', cols: 3 },
    { name: 'Rooms', align: 'center', cols: 1 },
    { name: `${residentLabels[facility.type]}s`, align: 'center', cols: 1 },
    { name: 'Caregivers', align: 'center', cols: 1.3 },
    { name: 'Devices', align: 'center', cols: 1 },
    { name: 'Alarm', align: 'center', cols: 1 },
    { name: 'Status', align: 'center', cols: 1 }
  ];

  const [page, setPage] = useState(0);
  const [searchedWardsPage, setSearchedWardsPage] = useState(0);
  const [wards, setWards] = useState<MappedWard[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(DEFAULT_SEARCH_TERM);
  const [wardAdded, setWardAdded] = useState(false);
  const [selectedWardId, setSelectedWardId] = useState<WardId>(wardId);

  const getWardsMemo = useMemo(() => getPaginatedWards(facility.id, page), [
    facility.id,
    page
  ]);

  const memoSearchAction = useMemo(() => {
    if (searchTerm !== DEFAULT_SEARCH_TERM) {
      return getWardsByQuery(facility.id, searchTerm, searchedWardsPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchedWardsPage]);

  const {
    data: { items: wardList, nextPage },
    error,
    loading,
    refetching,
    setRefetch
  } = useFetcher<{
    items: MappedWard[];
    nextPage?: string;
  }>(getWardsMemo, { items: [], nextPage: undefined });

  const {
    data: { items: searchedWards, nextPage: nextPageForSearchedWards },
    error: searchError,
    loading: searchLoading
  } = useFetcher<{
    items: MappedWard[];
    nextPage?: string;
  }>(memoSearchAction, { items: [] });

  useEffect(() => {
    if (wardList.length !== 0) {
      setWards(prevState => [...prevState, ...wardList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardList]);

  useEffect(() => {
    const selectedWardIndex = wards.some(ward => {
      return ward.id === selectedWardId;
    });

    if (nextPage && selectedWardId && !selectedWardIndex && !wardAdded) {
      setPage(parseInt(nextPage!, 10));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wards]);

  useEffect(() => {
    if (searchError) {
      return setWards([]);
    }
    if (searchedWardsPage > 0) {
      setWards(prevState => [...prevState, ...searchedWards]);
    } else {
      setWards(searchedWards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedWards, searchError]);

  const { isShowing, toggle } = useModal();

  const modalClosed = () => {
    setWards([]);
    if (page === 0) {
      setRefetch();
    } else {
      setPage(0);
    }
    toggle();
  };

  const onAdd = () => {
    setWardAdded(true);
    toggle();
  };

  const scrollAction = () => {
    if (isFetching && wardList.length && nextPage && !isSearching) {
      setPage(parseInt(nextPage, 10));
      setIsFetching(false);
    } else if (
      !loading &&
      isFetching &&
      searchedWards.length &&
      nextPageForSearchedWards &&
      isSearching
    ) {
      setSearchedWardsPage(parseInt(nextPageForSearchedWards, 10));
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!lastModifiedWard) {
      return undefined;
    }

    setWards(currentWards => {
      return currentWards.map(ward =>
        ward.id === lastModifiedWard.id ? lastModifiedWard : ward
      );
    });
  }, [lastModifiedWard]);

  const handleSelectWard = useCallback(
    (id: WardId) => {
      setSelectedWardId(id);

      const ward = wards.find(item => item.id === id);
      if (ward) {
        selectWard(ward);
      }
    },
    [wards, selectWard]
  );

  const handleWardAdded = (newWardId: WardId) => {
    setSelectedWardId(newWardId);
  };

  useEffect(() => {
    handleSelectWard(selectedWardId);
  }, [handleSelectWard, selectedWardId]);

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    loading
  );

  const handleSearch = (searchValue: string) => {
    if (searchValue.length >= MIN_LENGTH_FOR_TRIGGERING_SEARCH) {
      if (!isSearching) {
        setIsSearching(true);
      }
      setSearchTerm(searchValue);
      setSearchedWardsPage(0);
    } else if (isSearching) {
      setSearchTerm(DEFAULT_SEARCH_TERM);
      setIsSearching(false);
      setWards([]);
      setPage(0);
      setRefetch();
    }
  };

  const render = (rowValues: any) => (
    <WardTableRow
      key={rowValues.id}
      values={rowValues}
      isSelected={selectedWardId === rowValues.id}
      selectWard={handleSelectWard}
    />
  );

  const isDataAvailable = wards.length > 0;
  return (
    <PanelList
      title={WARD_TITLE}
      onAdd={onAdd}
      hasError={!!error}
      onSearch={handleSearch}
      searchInputName={WARD_SEARCH_INPUT_NAME}
    >
      {!error && (!loading || isDataAvailable) && (
        <Table<MappedWard>
          columns={WardListTableColumns}
          rows={wards}
          placeholder={WARD_LIST_PLACEHOLDER}
          render={render}
          listRef={scrollRef}
        />
      )}
      {(loading || searchLoading) && !refetching && (
        <LoadingPlaceholder fullHeight={false} />
      )}
      {error && !loading && <FetchError error={error} />}

      <Modal isShowing={isShowing} toggle={toggle}>
        <div className={stylesModal.modalBackdrop}>
          <div className={stylesModal.modalContentLeftAlign}>
            <div
              className={stylesModal.modalWithVerticalBar}
              style={{ marginTop: getHeight('Wards') }}
            >
              <AddWardForm
                toggle={modalClosed}
                onWardAdded={handleWardAdded}
                facilityId={facility.id}
              />
            </div>
          </div>
        </div>
      </Modal>
    </PanelList>
  );
};
