import React from 'react';

import { RoundButton } from './RoundButton';

import styles from './RoundTrashButton.module.css';

import { ReactComponent as TrashIcon } from 'icons/trash.svg';

export const RoundTrashButton: React.FunctionComponent<{
  onClick: () => void;
  testId?: string;
  small?: boolean;
}> = ({ onClick, testId, small }) => (
  <RoundButton
    className={small ? styles.smallRoundTrashButton : styles.roundTrashButton}
    onClick={onClick}
    testId={testId}
  >
    <TrashIcon />
  </RoundButton>
);
