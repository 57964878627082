import React, { FunctionComponent, useCallback } from 'react';

import { RolesEnum, useAuthState, UserRole } from 'Auth';
import { Switch, TextStatus } from 'components';
import { FeatureNameMappings } from 'Settings/actions/featureMapping.actions';
import { IWardFeature } from './WardFeatures';

import styles from './styles/WardFeature.module.css';

interface IWardFeatureProps {
  feature: IWardFeature;
  onChange: (feature: keyof FeatureNameMappings, value: boolean) => void;
  role: UserRole | null;
}

export const WardFeature: FunctionComponent<IWardFeatureProps> = ({
  feature,
  onChange,
  role
}) => {
  const handleToggle = useCallback(
    (enabled, id) => {
      onChange(id as keyof FeatureNameMappings, enabled);
    },
    [onChange]
  );

  return (
    <div className={styles.featureItem}>
      <span>{feature.name}</span>
      {role === RolesEnum.ROLE_REGULAR_ADMIN && (
        <div>
          <TextStatus status={feature.value} />
        </div>
      )}
      {role === RolesEnum.ROLE_ADMIN && (
        <Switch
          id={feature.id as string}
          isOn={feature.value} // only used as default state
          onToggle={handleToggle}
        />
      )}
    </div>
  );
};

export const WardFeatureWithProvider: FunctionComponent<Omit<
  IWardFeatureProps,
  'role'
>> = ({ feature, onChange }) => {
  const { role } = useAuthState();

  return <WardFeature feature={feature} onChange={onChange} role={role} />;
};
