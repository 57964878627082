import React from 'react';

import {
  GraphLoadingPlaceholder,
  HistoryFilterTabs,
  HistoryLabel,
  LiquidIntake
} from 'Residents';

import { LIQUID_INTAKE_COLOR } from '../chartColors.const';
import { LiquidIntakeChart } from './LiquidIntakeChart';
import { toMonthData } from './toMonthData';
import { toWeekData } from './toWeekData';

import styles from './../Charts.module.css';

export const LiquidIntakeHistoryChart: React.FunctionComponent<{
  changeHistoryFilter: (filter: HistoryLabel) => void;
  activeHistoryTab: HistoryLabel;
  readings: LiquidIntake[];
  loading: boolean;
}> = ({ changeHistoryFilter, activeHistoryTab, readings, loading }) => {
  return (
    <div className={styles.chartLiquidIntake}>
      <HistoryFilterTabs
        onSelect={changeHistoryFilter}
        activeTab={activeHistoryTab}
        color={LIQUID_INTAKE_COLOR}
      />
      {!loading && (
        <LiquidIntakeChart
          data={
            activeHistoryTab !== 'This Month'
              ? toWeekData(readings)
              : toMonthData(readings)
          }
          allowHighlight={activeHistoryTab !== 'Last Week'}
        />
      )}
      {loading && <GraphLoadingPlaceholder color={LIQUID_INTAKE_COLOR} />}
    </div>
  );
};
