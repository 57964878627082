import React, { useEffect, useMemo, useState } from 'react';

import { Select } from 'components';
import { useFetcher } from 'hooks';
import { getRoomsByFacility } from 'Settings/actions';
import { Room } from 'Settings/types';
import {
  formatSelectedValues,
  sortByKey,
  stripIdForDropdownValue
} from 'utils';

import formStyles from 'styles/form.module.css';
import styles from './Dropdown.module.css';

export const RoomFetchDropdown: React.FunctionComponent<{
  name: string;
  value: any;
  onChange: (name: any, value: any, revalidate?: boolean) => void;
  register: any;
  hasError: boolean;
  facilityId: string;
  changeStatus: (status: any) => void;
  required?: boolean;
  isVisible?: boolean;
  placeholder?: string;
  wardId?: string;
  noOptionsMessage?: string;
  cssClass?: string;
}> = ({
  name,
  value,
  facilityId,
  hasError,
  register,
  onChange,
  changeStatus,
  required,
  wardId,
  isVisible = true,
  noOptionsMessage,
  cssClass = formStyles.selectGroupQuarter
}) => {
  const [roomsOptions, setRoomsOptions] = useState<any[]>([]);
  const getRoomsMemo = useMemo(() => getRoomsByFacility(facilityId), [
    facilityId
  ]);
  const {
    data: allRooms,
    error: roomsError,
    loading: roomsLoading
  } = useFetcher<{
    items: Room[];
  }>(getRoomsMemo, { items: [] });

  useEffect(() => {
    let rooms: any[] = [];
    if (wardId) {
      const availableRooms = allRooms.items.filter(
        room => room.ward && room.ward.id === wardId
      );

      rooms = sortByKey(
        availableRooms.map(({ id, number: roomNumber }) => ({
          value: id,
          label: roomNumber
        })),
        'label'
      );
    }

    setRoomsOptions(rooms);
  }, [wardId, allRooms.items]);

  useEffect(() => {
    changeStatus({ loading: roomsLoading, error: roomsError });
  }, [roomsLoading, roomsError, changeStatus]);

  const noOptionsMessageFunc = () => (
    <span className={styles.noOptionsMessage}>{noOptionsMessage}</span>
  );

  const handleChange = (
    fieldName: string,
    selected: any,
    revalidate: boolean
  ) => {
    const formattedValue = formatSelectedValues(
      selected,
      roomsOptions,
      'number'
    );
    onChange(fieldName, formattedValue, revalidate);
  };

  const style = isVisible ? { display: 'flex' } : { display: 'none' };

  return (
    <div className={cssClass} style={style}>
      <Select
        name={name}
        label="Room"
        options={roomsOptions}
        value={stripIdForDropdownValue(value)}
        register={register}
        hasError={hasError}
        onChange={handleChange}
        required={required}
        noOptionsMessage={noOptionsMessageFunc}
      />
    </div>
  );
};
