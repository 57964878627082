import React from 'react';

import { RoundCrossButton } from 'components';

import { isErrorMessage } from '../../Settings/types';

import styles from './error.module.css';

export const DismissibleError: React.FunctionComponent<{
  name: string;
  error: any;
  dismiss: () => void;
  visible: boolean;
}> = ({ name, error, dismiss, visible }) => {
  const isValidError = error && isErrorMessage(error);
  const defaultMessage = `${name} could not be saved, an error occurred`;

  return visible ? (
    <div className={styles.dismissibleErrorContainer}>
      <RoundCrossButton onClick={dismiss} />
      <div className={styles.errorsWrapper}>
        <h4 className={styles.message}>
          {isValidError ? `${defaultMessage}:` : `${defaultMessage}.`}
        </h4>
        {isValidError && (
          <h4 className={styles.messageDetail}>{error.message}</h4>
        )}
      </div>
    </div>
  ) : null;
};
