import React, { useEffect, useState } from 'react';

import { DismissibleError, Modal } from 'components';
import {
  useModalMeasurements,
  usePoster,
  usePrevious,
  useSubmitError
} from 'hooks';

import { Caregiver, editCaregiverPartially, Facility } from 'Settings';

import { StaffActionTypes, useCaregiverContext } from './caregiver.context';
import { EditCaregiverForm } from './EditCaregiverForm';
import { StaffMemberDetail } from './types';

import styles from './CaregiverDetailsPanel.module.css';

export const EditCaregiverDetails: React.FunctionComponent<{
  facility: Facility;
  toggle: () => void;
  isOpen: boolean;
  wardId: string;
}> = ({ facility, toggle, isOpen, wardId }) => {
  const [cargiverToSave, setCaregiverToSave] = useState(
    {} as StaffMemberDetail
  );

  const [{ caregiverDetails }, dispatch] = useCaregiverContext();

  const { modalWidth, modalOffsetLeft } = useModalMeasurements(
    'Caregiver Details',
    'MainContent',
    isOpen
  );
  const { setAction, loading: onSaveLoading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, onSaveLoading);
  const lastOpenState = usePrevious(isOpen);
  const computedOffsetLeft = modalOffsetLeft > 0 ? modalOffsetLeft : 15;

  const onUpdate = (caregiverData: StaffMemberDetail) => {
    setAction(editCaregiverPartially((caregiverData as unknown) as Caregiver));
    setCaregiverToSave(caregiverData);
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const onCancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    toggle();
  };

  useEffect(() => {
    if (submitted && !onSaveLoading && !error) {
      dispatch({
        type: StaffActionTypes.UPDATE_CAREGIVER,
        payload: ({
          ...cargiverToSave
        } as unknown) as StaffMemberDetail
      });

      toggle();
      setSubmitted(false);
    }
  }, [
    submitted,
    onSaveLoading,
    error,
    setSubmitted,
    toggle,
    dispatch,
    cargiverToSave
  ]);

  // close modal when ward or facility changes
  useEffect(() => {
    if (isOpen && lastOpenState) {
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, facility.id, wardId, toggle]); // ignoring lastOpenState since it's a ref and we get the latest value but don't want to trigger this when its value changes

  return (
    <Modal isShowing={isOpen} toggle={toggle}>
      <div className={styles.modalBackdrop}>
        <div
          className={styles.modalContent}
          style={{ width: modalWidth, marginLeft: computedOffsetLeft }}
        >
          <EditCaregiverForm
            onSave={onUpdate}
            onCancel={onCancel}
            onSaveLoading={onSaveLoading}
            caregiver={caregiverDetails}
            facility={facility}
          />
          <DismissibleError
            name="Caregiver"
            visible={isErrorShowing}
            error={error}
            dismiss={dismissError}
          />
        </div>
      </div>
    </Modal>
  );
};
