import React, { useMemo } from 'react';

import { FetchError, LoadingPlaceholder, PanelHeader } from 'components';
import { useFetcher } from 'hooks';

import { getLatestVitals } from './actions';
import {
  BasicVital,
  BloodPressure,
  BloodSugar,
  BodyTemperature,
  BodyWeight,
  HeartRate,
  OxygenSaturation,
  PainLevel,
  VitalType
} from './types';
import {
  ManageBloodPressure,
  ManageBloodSugar,
  ManageBodyTemperature,
  ManageBodyWeight,
  ManageHeartRate,
  ManageOxygenSaturation,
  ManagePainLevel
} from './Vitals';

import { VitalsFeatures } from './ResidentsDashboard';
import style from './ResidentVitals.module.css';

export const ResidentVitals: React.FunctionComponent<{
  facilityId: string;
  residentId: string | null;
  vitals: VitalsFeatures;
}> = ({ facilityId, residentId, vitals }) => {
  const latestVitalsAction = useMemo(() => {
    if (facilityId && residentId) {
      return getLatestVitals(facilityId, residentId);
    }
  }, [facilityId, residentId]);

  const { data: latestVitals, error, loading, setRefetch } = useFetcher<
    BasicVital[]
  >(latestVitalsAction, []);

  const getVital = (vitalType: VitalType) =>
    latestVitals.find(({ type }) => type === vitalType);

  return (
    <div className={style.residentVitals} id="Vitals">
      {!error && <PanelHeader title="Vitals" />}
      {loading && !error && <LoadingPlaceholder />}
      {error && !loading && <FetchError error={error} />}
      {!loading && !error && (
        <div className={style.cards}>
          {residentId ? (
            <>
              {vitals.prvBloodPressure && (
                <ManageBloodPressure
                  bloodPressure={getVital('BLOOD_PRESSURE') as BloodPressure}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={setRefetch}
                />
              )}
              {vitals.prvBodyTemperature && (
                <ManageBodyTemperature
                  bodyTemperature={
                    getVital('BODY_TEMPERATURE') as BodyTemperature
                  }
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={setRefetch}
                />
              )}
              {vitals.prvHeartRate && (
                <ManageHeartRate
                  heartRate={getVital('HEART_RATE') as HeartRate}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={setRefetch}
                />
              )}
              {vitals.prvBodyWeight && (
                <ManageBodyWeight
                  bodyWeight={getVital('BODY_WEIGHT') as BodyWeight}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={setRefetch}
                />
              )}
              {vitals.prvBloodSugar && (
                <ManageBloodSugar
                  bloodSugar={getVital('BLOOD_SUGAR') as BloodSugar}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={setRefetch}
                />
              )}
              {vitals.prvOxygenSaturation && (
                <ManageOxygenSaturation
                  oxygenSaturation={
                    getVital('OXYGEN_SATURATION') as OxygenSaturation
                  }
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={setRefetch}
                />
              )}
              {vitals.prvPainLevel && (
                <ManagePainLevel
                  painLevel={getVital('PAIN_LEVEL') as PainLevel}
                  facilityId={facilityId}
                  residentId={residentId}
                  onReadingAdded={setRefetch}
                />
              )}
            </>
          ) : (
            <p className={style.vitalsPlaceholder}>
              No clinical information available
            </p>
          )}
        </div>
      )}
    </div>
  );
};
