import classnames from 'classnames';
import React, { useMemo } from 'react';

import { ProfilePlaceholder } from 'components';
import { VACANT_ROOM_NAME } from 'consts';
import { ReactComponent as ExclamationMark } from 'icons/exclamationMark.svg';
import { useNotificationState } from 'Notifications';
import { ResidentListItem } from 'Residents/types';
import { formatName } from 'utils';
import { getMarkAndHighlight } from 'utils/checkAlarms';

import style from 'Residents/ResidentListEntry.module.css';

export const ResidentListEntry: React.FunctionComponent<{
  data: ResidentListItem;
}> = ({ data }) => {
  const { notifications } = useNotificationState();
  const { room, resident } = data;
  const residentData = resident || {
    id: '',
    name: VACANT_ROOM_NAME,
    gender: 'Unknown',
    photoUrl: ''
  };
  const { name, photoUrl } = residentData;
  const type = residentData.gender.toLowerCase();

  const residentNotificationStyle = getMarkAndHighlight(
    notifications,
    residentData.id
  );

  const formattedName = useMemo(() => formatName(name), [name]);

  return (
    <li
      className={classnames(style.entry, {
        [style.highlighted]: residentNotificationStyle.isHighlighted
      })}
    >
      <ProfilePlaceholder name={name} type={type} photoUrl={photoUrl} />
      <div className={style.details}>
        <span className={style[type]}>{formattedName}</span>
        <span className={style.room}>{`Room: ${room.number}`}</span>
      </div>
      {residentNotificationStyle.hasExclamationMark && (
        <div className={style.warning}>
          <ExclamationMark />
        </div>
      )}
    </li>
  );
};
