import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { ITimePassedProps } from 'components/types';
import { getTimeDifference } from 'utils';

export const TimePassed: FunctionComponent<ITimePassedProps> = ({
  timestamp,
  cssClasses = []
}) => {
  const [time, setTime] = useState<string>('');
  useEffect(() => {
    const id = setInterval(() => {
      setTime(getTimeDifference(timestamp));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [timestamp]);

  return <span className={classNames(...cssClasses)}>{time}</span>;
};
