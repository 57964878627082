import { LoginId } from 'types';
import { formatName } from 'utils';

import {
  isAdHocConversation,
  isCaregiverConversation,
  isCareTeamConversation,
  isRelatedContactConversation,
  Message,
  MessagingHistoryConversation
} from '../types';

export const byISOdateNewest = (
  a: MessagingHistoryConversation,
  b: MessagingHistoryConversation
) =>
  a.lastMessage && b.lastMessage
    ? a.lastMessage.timestamp > b.lastMessage.timestamp
      ? -1
      : 1
    : a.lastMessage && !b.lastMessage
    ? -1
    : 1;

export const getConversationName = (
  data: MessagingHistoryConversation,
  currentUserLoginId: LoginId
) => {
  if (isAdHocConversation(data) || isCareTeamConversation(data)) {
    return data.description;
  }

  const { participants } = data;

  const otherParticipant = participants.find(
    participant => participant.loginId !== currentUserLoginId
  );

  if (isCaregiverConversation(data) && otherParticipant) {
    const { name, role } = otherParticipant;
    return `${name} - ${role}`;
  }

  if (isRelatedContactConversation(data) && otherParticipant) {
    const formattedName = formatName(otherParticipant.name);
    return formattedName;
  }

  return 'default chat name';
};

export const sortByTimestamp = (a: Message, b: Message) =>
  a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 1 : 0;
