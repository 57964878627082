const caregiverRolesUnFiltered = [
  'Doctor',
  'Nurse',
  'Pharmacist',
  'Researcher',
  'Teacher/educator',
  'ICT professional',
  'Specialized surgeon',
  'Radiation therapist',
  'Chiropractor',
  'Dental assistant',
  'NA - Nursing auxiliary',
  'Specialized nurse',
  'Hospital administrator',
  'Plastic surgeon',
  'Neuropathologist',
  'Nephrologist',
  'Obstetrician',
  'School dental assistant',
  'Medical microbiologist',
  'Cardiologist',
  'Dermatologist',
  'Laboratory hematologist',
  'Gerodontist',
  'Removable prosthodontist',
  'Specialized dentist',
  'Neuropsychiatrist',
  'Medical assistant',
  'Orthopedic surgeon',
  'Thoracic surgeon',
  'Community health physician',
  'Physical medicine specialist',
  'Urologist',
  'Electroencephalography specialist',
  'Dental hygienist',
  'Public health nurse',
  'Optometrist',
  'Neonatologist',
  'Chemical pathologist',
  'PT - Physiotherapist',
  'Periodontist',
  'Orthodontist',
  'Internal medicine specialist',
  'Dietitian (general)',
  'Hematologist',
  'Interpreter',
  'Respiratory physician',
  'Medical X-ray technician',
  'Occupational health nurse',
  'Pharmaceutical assistant',
  'Masseur',
  'Rheumatologist',
  'Neurosurgeon',
  'Sanitarian',
  'Philologist',
  'Dispensing optometrist',
  'Maxillofacial surgeon',
  'Endodontist',
  'Faith healer',
  'Neurologist',
  'Community physician',
  'Medical record administrator',
  'Cardiovascular surgeon',
  'Fixed prosthodontist',
  'General physician',
  'Orthopedic technician',
  'Psychologist',
  'Community-based dietitian',
  'Medical pathologist',
  'Laboratory medicine specialist',
  'Otorhinolaryngologist',
  'Endocrinologist',
  'Family medicine specialist',
  'Clinical immunologist',
  'Oral pathologist',
  'Radiologist',
  'Public health dentist',
  'Prosthodontist',
  'Specialized physician',
  'Gastroenterologist',
  'Nursing aid',
  'MW - Midwife',
  'Practical aid (pharmacy)',
  'Osteopath',
  'Infectious diseases physician',
  'General surgeon',
  'Diagnostic radiologist',
  'Auxiliary midwife',
  'Translator',
  'OT - Occupational therapist',
  'Psychiatrist',
  'Nuclear medicine physician',
  'Clinical pathologist',
  'Pediatrician',
  'Other professional nurse',
  'Anatomic pathologist',
  'Gynecologist',
  'General pathologist',
  'Anesthesiologist',
  'Other dietitians and public health nutritionists',
  'Pediatric dentist',
  'Care of the elderly physician',
  'Dental surgeon',
  'Dietician AND/OR public health nutritionist',
  'Nursing personnel',
  'Midwifery personnel',
  'Physiotherapist AND/OR occupational therapist',
  'Philologist, translator AND/OR interpreter',
  'Medical doctor',
  'Medical practitioner',
  'Medical administrator - national',
  'Consultant physician',
  'Consultant surgeon',
  'Consultant gynecology and obstetrics',
  'Anesthetist',
  'Hospital registrar',
  'House officer',
  'Occupational physician',
  'Clinical medical officer',
  'Medical practitioner - teaching',
  'Dental administrator',
  'Dental consultant',
  'Dental general practitioner',
  'Dental practitioner - teaching',
  'Nurse administrator - national',
  'Nursing officer - region',
  'Nursing officer - district',
  'Nursing administrator - professional body',
  'Nursing officer - division',
  'Nurse education director',
  'Occupational health nursing officer',
  'Nursing officer',
  'Midwifery sister',
  'Nursing sister (theatre)',
  'Staff nurse',
  'Staff midwife',
  'State enrolled nurse',
  'District nurse',
  'Private nurse',
  'Community midwife',
  'Clinic nurse',
  'Practice nurse',
  'School nurse',
  'Nurse - teaching',
  'Student nurse',
  'Dental nurse',
  'Community pediatric nurse',
  'Hospital pharmacist',
  'Retail pharmacist',
  'Industrial pharmacist',
  'Pharmaceutical officer H.A.',
  'Trainee pharmacist',
  'Medical radiographer',
  'Diagnostic radiographer',
  'Therapeutic radiographer',
  'Trainee radiographer',
  'Ophthalmic optician',
  'Trainee optician',
  'Remedial gymnast',
  'Speech and language therapist',
  'Orthoptist',
  'Trainee remedial therapist',
  'Dietician',
  'Podiatrist',
  'Dental auxiliary',
  'ECG technician',
  'EEG technician',
  'Artificial limb fitter',
  'AT - Audiology technician',
  'Pharmacy technician',
  'Trainee medical technician',
  'Geneticist',
  'Surgical corset fitter',
  'Dental technician',
  'Clinical assistant',
  'Senior registrar',
  'Registrar',
  'Senior house officer',
  'MO - Medical officer',
  'Health visitor, nurse/midwife',
  'Registered nurse',
  'Midwifery tutor',
  'Accident and Emergency nurse',
  'Triage nurse',
  'Community nurse',
  'Nursing continence advisor',
  'Coronary care nurse',
  'Diabetic nurse',
  'Family planning nurse',
  'Care of the elderly nurse',
  'ICN - Infection control nurse',
  'Intensive therapy nurse',
  'Learning disabilities nurse',
  'Neonatal nurse',
  'Neurology nurse',
  'Industrial nurse',
  'Oncology nurse',
  'Macmillan nurse',
  'Marie Curie nurse',
  'Pain control nurse',
  'Palliative care nurse',
  'Chemotherapy nurse',
  'Radiotherapy nurse',
  'PACU nurse',
  'Stomatherapist',
  'Theatre nurse',
  'Pediatric nurse',
  'Psychiatric nurse',
  'Community mental health nurse',
  'Renal nurse',
  'Hemodialysis nurse',
  'Wound care nurse',
  'Nurse grade',
  'Clinical nurse specialist',
  'Nurse practitioner',
  'Nursing sister',
  'CN - Charge nurse',
  'Ward manager',
  'Nursing team leader',
  'Nursing assistant',
  'Healthcare assistant',
  'Nursery nurse',
  'Healthcare service manager',
  'Occupational health service manager',
  'Community nurse manager',
  'Behavior therapist',
  'Behavior therapy assistant',
  'Drama therapist',
  'Domiciliary occupational therapist',
  'Occupational therapy helper',
  'Psychotherapist',
  'Community-based physiotherapist',
  'Play therapist',
  'Play specialist',
  'Play leader',
  'Community-based speech/language therapist',
  'Speech/language assistant',
  'Professional counselor',
  'Marriage guidance counselor',
  'Trained nurse counselor',
  'Trained social worker counselor',
  'Trained personnel counselor',
  'Psychoanalyst',
  'Assistant psychologist',
  'Community-based podiatrist',
  'Foot care worker',
  'Audiometrician',
  'Audiometrist',
  'Technical healthcare occupation',
  'Occupational therapy technical instructor',
  'Administrative healthcare staff',
  'Complementary health worker',
  'Supporting services personnel',
  'Research associate',
  'Research nurse',
  'Human aid to communication',
  'Palantypist',
  'Note taker',
  'Cuer',
  'Lipspeaker',
  'Interpreter for British sign language',
  'Interpreter for Signs supporting English',
  'General practitioner locum',
  'Lactation consultant',
  'Midwife counselor',
  'Nursing occupation',
  'Medical/dental technicians',
  'Parkinson disease nurse',
  'Specialist registrar',
  'Member of mental health review tribunal',
  'Hospital manager',
  'Responsible medical officer',
  'Independent doctor',
  'Bereavement counselor',
  'Surgeon',
  'Medical technician',
  'Remedial therapist',
  'Accident and Emergency doctor',
  'Clinical oncologist',
  'Family planning doctor',
  'Associate general practitioner',
  'Partner of general practitioner',
  'Assistant GP',
  'Deputizing general practitioner',
  'General practitioner registrar',
  'Ambulatory pediatrician',
  'Community pediatrician',
  'Pediatric cardiologist',
  'Pediatric endocrinologist',
  'Pediatric gastroenterologist',
  'Pediatric nephrologist',
  'Pediatric neurologist',
  'Pediatric rheumatologist',
  'Pediatric oncologist',
  'Pain management specialist',
  'Intensive care specialist',
  'Adult intensive care specialist',
  'Pediatric intensive care specialist',
  'Blood transfusion doctor',
  'Histopathologist',
  'Physician',
  'Chest physician',
  'Thoracic physician',
  'Clinical hematologist',
  'Clinical neurophysiologist',
  'Clinical physiologist',
  'Diabetologist',
  'Andrologist',
  'Neuroendocrinologist',
  'Reproductive endocrinologist',
  'Thyroidologist',
  'Clinical geneticist',
  'Clinical cytogeneticist',
  'Clinical molecular geneticist',
  'Genitourinary medicine physician',
  'Palliative care physician',
  'Rehabilitation physician',
  'Child and adolescent psychiatrist',
  'Forensic psychiatrist',
  'Liaison psychiatrist',
  'Psychogeriatrician',
  'Psychiatrist for mental handicap',
  'Rehabilitation psychiatrist',
  'Obstetrician and gynecologist',
  'Breast surgeon',
  'Cardiothoracic surgeon',
  'Cardiac surgeon',
  'Ear, nose and throat surgeon',
  'Endocrine surgeon',
  'Thyroid surgeon',
  'Pituitary surgeon',
  'Gastrointestinal surgeon',
  'General gastrointestinal surgeon',
  'Upper gastrointestinal surgeon',
  'Colorectal surgeon',
  'Hand surgeon',
  'Hepatobiliary surgeon',
  'Ophthalmic surgeon',
  'Pediatric surgeon',
  'Pancreatic surgeon',
  'Transplant surgeon',
  'Trauma surgeon',
  'Vascular surgeon',
  'Medical practitioner grade',
  'Hospital consultant',
  'Visiting specialist registrar',
  'Research registrar',
  'General practitioner grade',
  'General practitioner principal',
  'Hospital specialist',
  'Associate specialist',
  'Research fellow',
  'Allied health professional',
  'Hospital dietitian',
  'Domiciliary physiotherapist',
  'General practitioner-based physiotherapist',
  'Hospital-based physiotherapist',
  'Private physiotherapist',
  'Physiotherapy assistant',
  'Hospital-based speech and language therapist',
  'Arts therapist',
  'Dance therapist',
  'Music therapist',
  'Renal dietitian',
  'Liver dietitian',
  'Oncology dietitian',
  'Pediatric dietitian',
  'Diabetes dietitian',
  'Audiologist',
  'Hearing therapist',
  'Audiological scientist',
  'Hearing aid dispenser',
  'Community-based occupational therapist',
  'Hospital occupational therapist',
  'Social services occupational therapist',
  'Orthotist',
  'Surgical fitter',
  'Hospital-based podiatrist',
  'Podiatry assistant',
  'Lymphedema nurse',
  'Community learning disabilities nurse',
  'Clinical nurse teacher',
  'Community practice nurse teacher',
  'Nurse tutor',
  'Nurse teacher practitioner',
  'Nurse lecturer practitioner',
  'Outreach nurse',
  'Anesthetic nurse',
  'Nurse manager',
  'Nurse administrator',
  'Midwifery grade',
  'Midwife',
  'Student midwife',
  'Parentcraft sister',
  'Healthcare professional grade',
  'Restorative dentist',
  'Pediatric audiologist',
  'Immunopathologist',
  'Audiological physician',
  'Clinical pharmacologist',
  'Private doctor',
  'Agency nurse',
  'Behavioral therapist nurse',
  'Cardiac rehabilitation nurse',
  'Genitourinary nurse',
  'Rheumatology nurse specialist',
  'Continence nurse',
  'Contact tracing nurse',
  'General nurse',
  'Nurse for the mentally handicapped',
  'Liaison nurse',
  'Diabetic liaison nurse',
  'Nurse psychotherapist',
  'Company nurse',
  'Hospital midwife',
  'Genetic counselor',
  'Mental health counselor',
  'Clinical psychologist',
  'Educational psychologist',
  'Coroner',
  'Appliance officer',
  'Medical oncologist',
  'School medical officer',
  'Integrated midwife',
  'RN First Assist',
  'Optician',
  'Medical secretary',
  'Hospital nurse',
  'Consultant anesthetist',
  'Paramedic',
  'Staff grade obstetrician',
  'Staff grade practitioner',
  'Medical student',
  'Acting obstetric registrar',
  'Physiotherapist technical instructor',
  'Resident physician',
  'Certified registered nurse anesthetist',
  'Attending physician',
  'Assigned practitioner',
  'Professional initiating surgical case',
  'Professional providing staff relief during surgical procedure',
  'Consultant pediatrician',
  'Consultant neonatologist',
  'Health educator',
  'Certified health education specialist',
  'Circulating nurse',
  'Perioperative nurse',
  'Scrub nurse',
  'Fellow of American Academy of Osteopathy',
  'Oculoplastic surgeon',
  'Retinal surgeon',
  'Admitting physician',
  'Medical ophthalmologist',
  'Ophthalmologist',
  'Health coach',
  'Respiratory therapist',
  'Podiatric surgeon',
  'Hypnotherapist',
  'Asthma nurse specialist',
  'Nurse case manager',
  'PCP - Primary care physician',
  'Addiction medicine specialist',
  'PA - physician assistant',
  'Government midwife',
  'Nurse complex case manager',
  'Naturopath',
  'Prosthetist',
  'Hip and knee surgeon',
  'Hepatologist',
  'Shoulder surgeon',
  'Interventional radiologist',
  'Pediatric radiologist',
  'Emergency medicine specialist',
  'Family medicine specialist - palliative care',
  'Surgical oncologist',
  'Acupuncturist',
  'Pediatric orthopedic surgeon',
  'Pediatric hematologist',
  'Neuroradiologist',
  'Family medicine specialist - anesthetist',
  'Doula',
  'Traditional herbal medicine specialist',
  'Occupational medicine specialist',
  'Pediatric emergency medicine specialist',
  'Family medicine specialist - care of the elderly',
  'Travel medicine specialist',
  'Spine surgeon',
  'Maternal or fetal medicine specialist',
  'Massage therapist',
  'Hospitalist',
  'Sports medicine specialist',
  'Pediatric respirologist',
  'Homeopath',
  'Family medicine specialist - emergency medicine',
  'Pediatric hematologist or oncologist',
  'Foot and ankle surgeon',
  'Invasive cardiologist',
  'Case manager',
  'Kinesthesiologist'
];

export const caregiverRoles = caregiverRolesUnFiltered.sort();
