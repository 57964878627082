import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthState } from 'Auth';
import {
  CallTypes,
  CommunicationActionTypes,
  ConversationTypes,
  createChatRoomCall,
  createEmptyChatRoom,
  initiateCall,
  MessagingHistoryConversation,
  StartCallType,
  StartCallTypes,
  useCommunicationDispatch
} from 'Communication';
import {
  ProfilePlaceholder,
  RoundAudioCallButton,
  RoundMessageButton,
  RoundVideoCallButton,
  Snackbar,
  WithPermissions
} from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { useLocationState } from 'Location';
import { Resident, ResidentContact } from 'Settings';
import { ChatId } from 'types';
import { formatName, isIEorEdge } from 'utils';

import styles from './RelatedContactsList.module.css';

interface IRelatedContactListEntryProps {
  data: ResidentContact;
  isSelected: boolean;
  activeChatId: ChatId | undefined;
  callCount: { videoCount?: number; audioCount?: number };
  resident: Resident;
  onSelect: (contact: ResidentContact) => void;
  missedMessages?: number;
}

export const RelatedContactListEntry: React.FunctionComponent<IRelatedContactListEntryProps> = ({
  data: relatedContact,
  isSelected,
  onSelect,
  activeChatId,
  callCount,
  missedMessages,
  resident
}) => {
  const [callType, setCallType] = useState<StartCallType | null>(null);
  const [currentChatId, setCurrentChatId] = useState<ChatId | undefined>(
    activeChatId
  );

  const { loggedUser, loginId } = useAuthState();
  const history = useHistory();
  const {
    facility: { id: facilityId },
    ward: wardId,
    selectedWardDetails
  } = useLocationState();
  const dispatch = useCommunicationDispatch();
  const {
    name,
    relationship,
    residentId,
    loginId: contactLoginId,
    photoUrl
  } = relatedContact;

  const formattedName = useMemo(() => formatName(name), [name]);

  const type = relatedContact.status;

  const { setAction, error: sendError, loading: onSaveLoading } = usePoster();

  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(sendError, onSaveLoading);

  const handleClickOnContact = useCallback(() => {
    onSelect(relatedContact);
  }, [onSelect, relatedContact]);

  const calleeDetails = useMemo(
    () => ({ name: formattedName, title: relationship, photoUrl }),
    [formattedName, relationship, photoUrl]
  );

  const startVideoCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (currentChatId) {
      setAction(initiateCall(currentChatId, CallTypes.VIDEO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${formattedName} chat room`;

      const createdChatRoomId = await createChatRoomCall(
        [loginId, contactLoginId],
        ConversationTypes.RELATED,
        CallTypes.VIDEO,
        description,
        residentId
      );

      setCurrentChatId(createdChatRoomId);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_VIDEO_CALL);
    }
  };

  const startAudioCall = async () => {
    if (isIEorEdge()) {
      return dispatch({ type: CommunicationActionTypes.NOT_SUPPORTED });
    }

    if (currentChatId) {
      setAction(initiateCall(currentChatId, CallTypes.AUDIO));
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    } else {
      const description = `${loggedUser!.name} and ${formattedName} chat room`;

      const createdChatRoomId = await createChatRoomCall(
        [loginId, contactLoginId],
        ConversationTypes.RELATED,
        CallTypes.AUDIO,
        description,
        residentId
      );

      setCurrentChatId(createdChatRoomId);
      setTimeout(() => {
        setSubmitted(true);
      });
      setCallType(StartCallTypes.START_AUDIO_CALL);
    }
  };

  useEffect(() => {
    if (
      !onSaveLoading &&
      !sendError &&
      submitted &&
      currentChatId &&
      callType
    ) {
      dispatch({
        type: callType,
        payload: calleeDetails
      });
      setSubmitted(false);
    }
  }, [
    onSaveLoading,
    sendError,
    submitted,
    setSubmitted,
    callType,
    calleeDetails,
    residentId,
    contactLoginId,
    currentChatId,
    dispatch,
    type
  ]);

  const handleClickOnMessageIcon = async (e: MouseEvent) => {
    e.stopPropagation();

    const description = `${loggedUser!.name} and ${formattedName} chat room`;

    let conversation: MessagingHistoryConversation = {
      chatRoomType: ConversationTypes.RELATED,
      participants: [{ ...relatedContact, role: undefined }],
      hasUnreadMessages: false,
      id: '',
      resident: {
        id: resident.id,
        name: resident.name,
        room: {
          id: resident.room ? resident.room.id : '',
          number: resident.room ? resident.room.number : '0',
          ward: {
            id: resident.room ? resident.room.ward.id : '',
            name: resident.room ? resident.room.ward.name : ''
          }
        }
      },
      description,
      loginSenderId: relatedContact.loginId || loginId
    };

    if (!activeChatId) {
      const createdChatRoomId = await createEmptyChatRoom(
        [loginId, relatedContact.loginId],
        ConversationTypes.RELATED,
        description,
        residentId
      );

      conversation = {
        ...conversation,
        id: createdChatRoomId
      };
    } else {
      conversation = {
        ...conversation,
        id: activeChatId
      };
    }

    dispatch({
      type: CommunicationActionTypes.SET_ACTIVE_CONVERSATION,
      payload: conversation
    });

    history.push(`/Communication?facility=${facilityId}&ward=${wardId}`);
  };

  return (
    <>
      <li
        className={classnames(styles.entry, {
          [styles.selected]: isSelected
        })}
        onClick={handleClickOnContact}
      >
        <ProfilePlaceholder name={formattedName} photoUrl={photoUrl} />
        <div className={styles.info}>
          <div className={styles.header}>
            <span className={styles.name}>{formattedName}</span>
            {selectedWardDetails?.services.secureCommunication.features
              .scRelatedContact.isEnabled && (
              <WithPermissions
                action="communication:all"
                yes={
                  <div className={styles.buttons}>
                    {selectedWardDetails?.services.secureCommunication.features
                      .scVoice.isEnabled && (
                      <RoundAudioCallButton
                        onClick={startAudioCall}
                        size={22}
                        missedCalls={callCount.audioCount}
                      />
                    )}
                    {selectedWardDetails?.services.secureCommunication.features
                      .scVideo.isEnabled && (
                      <RoundVideoCallButton
                        onClick={startVideoCall}
                        size={22}
                        missedCalls={callCount.videoCount}
                      />
                    )}
                    <RoundMessageButton
                      size={22}
                      onClick={handleClickOnMessageIcon}
                      missedMessages={missedMessages}
                    />
                  </div>
                }
              />
            )}
          </div>
          <div className={styles.details}>
            <span className={styles.relationship}>
              Relationship: {relationship}
            </span>
          </div>
        </div>
      </li>
      <Snackbar
        message={'Cannot initiate call. Please try again.'}
        isOpen={isErrorShowing}
        onClose={dismissError}
      />
    </>
  );
};
