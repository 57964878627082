import moment from 'moment';

import { emptyMonth, LastBathCount, weekOfMonth } from 'Residents';

const convertReadings = (week: string, readings: LastBathCount[]) => {
  const bathReadingsPerWeek = readings.filter(
    entry => 'Week ' + weekOfMonth(moment(entry.timestamp)) === week
  );

  const nrOfBaths = bathReadingsPerWeek.reduce(
    (acc, current) => acc + Number(current.value.numberOfBathingActivities),
    0
  );

  return {
    baths: nrOfBaths,
    unit: nrOfBaths === 1 ? 'bath' : 'baths'
  };
};

export const toMonthData = (data: LastBathCount[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
