import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AccessMessage,
  Column,
  DropdownMenu,
  FetchError,
  LoadingPlaceholder,
  PanelHeader,
  RoundSearchButton,
  SearchHeader,
  Table
} from 'components';
import { residentLabels } from 'consts';
import { useModal } from 'hooks';
import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import {
  DeviceLocation,
  LocationTrackingFilters,
  useLocationState
} from 'Location';
import { isAssetType, isCaregiverType, isResidentType } from 'utils';

import { formatCurrentLocation } from './formatCurrentLocation';
import { HeaderFilters } from './HeaderFilters';
import { LocationTableRow } from './LocationTableRow';

import styles from './Location.module.css';

const LocationTableColumns: Column[] = [
  { name: 'Device Name', align: 'left', cols: 1 },
  { name: 'Assigned To', align: 'left', cols: 1 },
  { name: 'Current Location', align: 'left', cols: 1 },
  { name: 'Room Occupied By', align: 'left', cols: 1 },
  { name: 'Time at Current Location', align: 'right', cols: 1 }
];

const FilterIconComponent = () => (
  <span className={styles.filterIcon}>
    <FilterIcon />
  </span>
);

export const Location: React.FunctionComponent = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState<LocationTrackingFilters>({
    caregivers: false,
    residents: false,
    assets: false
  });
  const [showFilterBtn, setShowFilterBtn] = useState<boolean>(true);

  const { isShowing: isSearching, toggle: toggleSearch } = useModal();
  const {
    devicesLocation: data,
    rtlsLoading,
    rtlsError,
    facility,
    ward: wardId,
    filtersError,
    filtersLoading,
    selectedWardDetails
  } = useLocationState();

  const isStaffRTLSOn =
    selectedWardDetails?.services.rtls.features.rtlsStaff.isEnabled;
  const isPatientRTLSOn =
    selectedWardDetails?.services.rtls.features.rtlsPatient.isEnabled;
  const isAssetsRTLSOn =
    selectedWardDetails?.services.rtls.features.rtlsAsset.isEnabled;

  useEffect(() => {
    setFilters({
      caregivers: isStaffRTLSOn ? true : undefined,
      residents: isPatientRTLSOn ? (isStaffRTLSOn ? false : true) : undefined,
      assets: isAssetsRTLSOn
        ? isStaffRTLSOn || isPatientRTLSOn
          ? false
          : true
        : undefined
    });
  }, [isStaffRTLSOn, isPatientRTLSOn, isAssetsRTLSOn]);

  const onSearchToggle = useCallback(() => {
    toggleSearch();
    setFilters({
      caregivers: isStaffRTLSOn ? true : undefined,
      residents: isPatientRTLSOn ? true : undefined,
      assets: isAssetsRTLSOn ? true : undefined
    });
  }, [isStaffRTLSOn, isPatientRTLSOn, isAssetsRTLSOn, toggleSearch]);

  const devicesLocation = useMemo(() => {
    if (rtlsLoading) {
      return [];
    }
    const { caregivers, residents, assets } = filters;

    return data.filter(({ type, isActive }) => {
      if (
        ((caregivers && isCaregiverType(type)) ||
          (residents && isResidentType(type, facility.type)) ||
          (assets && isAssetType(type))) &&
        isActive
      ) {
        return true;
      }
      return false;
    });
  }, [data, facility.type, filters, rtlsLoading]);

  const filteredDevicesLocation = useMemo(() => {
    if (searchValue.length < 3 || !isSearching) {
      return devicesLocation;
    }
    const lowercaseValue = searchValue.toLowerCase();

    return devicesLocation.filter(
      ({ name, caregiver, assetTracker, resident, room }) =>
        name.toLowerCase().includes(lowercaseValue) ||
        (caregiver && caregiver.name.toLowerCase().includes(lowercaseValue)) ||
        (resident && resident.name.toLowerCase().includes(lowercaseValue)) ||
        (assetTracker &&
          (assetTracker.name.toLowerCase().includes(lowercaseValue) ||
            assetTracker.description.toLowerCase().includes(lowercaseValue))) ||
        (room &&
          room.residents &&
          room.residents.some(entry =>
            entry.name.toLowerCase().includes(lowercaseValue)
          )) ||
        formatCurrentLocation(room)
          .toLowerCase()
          .includes(lowercaseValue)
    );
  }, [isSearching, searchValue, devicesLocation]);

  const render = (rowValues: any) => (
    <LocationTableRow key={rowValues.id} values={rowValues} />
  );

  const [searchInputName, setSearchInputName] = useState<string>('');

  useEffect(() => {
    if (!facility.type) {
      return undefined;
    }
    const label = `${residentLabels[facility.type].toLowerCase()}s `;
    const text = `devices by ${isAssetsRTLSOn ? 'name, ' : ''}${
      isStaffRTLSOn ? 'caregivers, ' : ''
    }${isPatientRTLSOn ? label : ''}or location`;

    setSearchInputName(text);
  }, [facility.type, isAssetsRTLSOn, isStaffRTLSOn, isPatientRTLSOn]);

  useEffect(() => {
    if (!selectedWardDetails) {
      return undefined;
    }

    const features = Object.values(selectedWardDetails.services.rtls.features);
    const enabledFeatures = features.filter(feat => feat.isEnabled === true);

    if (enabledFeatures.length === 1) {
      setShowFilterBtn(false);
    } else {
      setShowFilterBtn(true);
    }
  }, [selectedWardDetails]);

  return (
    <>
      {!filtersLoading &&
        !filtersError &&
        (wardId ? (
          <div
            className={classnames(styles.locationContainer, {
              [styles.hasError]: !!rtlsError
            })}
          >
            {!rtlsError &&
              (isSearching ? (
                <SearchHeader
                  searchInputName={searchInputName}
                  onClose={onSearchToggle}
                  searchValue={searchValue}
                  onSearch={setSearchValue}
                />
              ) : (
                <PanelHeader title="Location Tracking">
                  {showFilterBtn && (
                    <DropdownMenu
                      Icon={FilterIconComponent}
                      testId="filterBtn"
                      dropdownArrow={true}
                    >
                      <HeaderFilters
                        filters={filters}
                        changeFilters={setFilters}
                      />
                    </DropdownMenu>
                  )}
                  <RoundSearchButton onClick={onSearchToggle} />
                </PanelHeader>
              ))}

            {!rtlsError && !rtlsLoading && (
              <Table<DeviceLocation>
                columns={LocationTableColumns}
                rows={filteredDevicesLocation}
                placeholder="No data available"
                render={render}
              />
            )}
            {!rtlsError && rtlsLoading && (
              <LoadingPlaceholder fullHeight={false} />
            )}
            {rtlsError && !rtlsLoading && <FetchError error={rtlsError} />}
          </div>
        ) : (
          <AccessMessage />
        ))}
      {!filtersError && filtersLoading && <LoadingPlaceholder />}
      {filtersError && !filtersLoading && <FetchError />}
    </>
  );
};
