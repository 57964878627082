import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { bloodSugarInterval } from '../Intervals';
import { BloodSugarLine } from '../Lines';
import { BloodSugarToolTip } from '../ToolTips';

export const BloodSugarChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    bloodSugar?: number;
    hasWarning?: boolean | undefined;
  }>;
  color: string;
  allowHighlight: boolean;
}> = ({ data, color, allowHighlight }) => {
  return (
    <Chart
      intervals={bloodSugarInterval}
      data={data}
      tooltip={BloodSugarToolTip}
      lineOne={BloodSugarLine(color)}
      color={color}
      allowHighlight={allowHighlight}
    />
  );
};
