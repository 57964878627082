import React from 'react';

import styles from './RoundButton.module.css';

export const RoundButton: React.FunctionComponent<{
  onClick: (e: any) => void;
  className?: string;
  disabled?: boolean;
  size?: number;
  testId?: string;
}> = ({
  children,
  onClick,
  className = '',
  disabled = false,
  testId,
  size = 32
}) => {
  return (
    <button
      onClick={onClick}
      style={{ width: size, height: size }}
      className={`${styles.roundButton} ${className}`}
      data-testid={testId}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
