import React from 'react';

import { Chart, ChartLabelType } from 'Residents';

import { ActivityChartLine } from '../ActivityChartLine';
import { FOOD_INTAKE_COLOR } from '../chartColors.const';
import { foodIntakeInterval } from '../Intervals';
import { FoodIntakeTooltip } from '../Tooltips/FoodIntakeTooltip';

export const FoodIntakeChart: React.FunctionComponent<{
  data: Array<{
    label: ChartLabelType;
    foods?: number;
  }>;
  allowHighlight: boolean;
}> = ({ data, allowHighlight }) => {
  return (
    <Chart
      intervals={foodIntakeInterval}
      data={data}
      tooltip={FoodIntakeTooltip}
      lineOne={ActivityChartLine(FOOD_INTAKE_COLOR, 'foods')}
      color={FOOD_INTAKE_COLOR}
      allowHighlight={allowHighlight}
      invertColors={true}
    />
  );
};
