import {
  ChatRoomListResponse,
  ConversationType,
  IChatPage
} from 'Communication';
import { ChatRoom } from 'Communication/types';
import { CHATS_PAGE_SIZE, MAX_PAGE_SIZE } from 'consts';
import { ChatId, LoginId, ResidentId } from 'types';
import { CaregiverProfileDetails, UserProfileDetails } from 'UserProfile';
import { axiosWrapper } from 'utils';

export const generateGetChatsByUser = (
  loggedUser: UserProfileDetails | CaregiverProfileDetails | null
) => {
  if (loggedUser && loggedUser.type === 'CAREGIVER') {
    return getChatRooms;
  }

  return () => [];
};

export const getChatRooms = async () => {
  const response = await axiosWrapper<ChatRoomListResponse>(
    `/chat-rooms?size=${MAX_PAGE_SIZE}`
  );
  return response.data.items;
};

export const getChatRoomByResidentId = async (
  residentId: ResidentId
): Promise<ChatRoom[]> => {
  const response = await axiosWrapper<IChatPage>(
    `/chat-rooms?residentId=${residentId}`
  );

  return response.data.items;
};

export const getChatRoomsByLoginId = async (
  correspondentId: LoginId
): Promise<ChatRoom[]> => {
  const response = await axiosWrapper<IChatPage>(
    `/chat-rooms?correspondentId=${correspondentId}`
  );

  return response.data.items;
};

export const getChatRoomByCorrespondentIdAndChatRoomType = async (
  correspondentId: LoginId | undefined,
  residentId: ResidentId | undefined,
  chatType: ConversationType
): Promise<ChatRoom | undefined> => {
  let chatList = [] as ChatRoom[];

  if (correspondentId) {
    chatList = await getChatRoomsByLoginId(correspondentId);
  } else if (residentId) {
    chatList = await getChatRoomByResidentId(residentId);
  }

  return chatList.find(chat => chat.chatRoomType === chatType);
};

export const getChatRoomById = async (chatId: ChatId): Promise<ChatRoom> => {
  const { data } = await axiosWrapper<ChatRoom>(`/chat-rooms/${chatId}`);

  return data;
};

export const getPaginatedChatRooms = async (page: string) => {
  const res = await axiosWrapper<IChatPage>(
    `/chat-rooms?page=${page}&size=${CHATS_PAGE_SIZE}`
  );
  return {
    items: res.data.items as ChatRoom[],
    nextPage: res.data.nextPage
  };
};
