export interface IMissedMessage {
  chatRoomId: string;
  messages: number;
}

export interface MissedMessagesCountsResponse {
  items: IMissedMessage[];
  nextPage?: number;
}

export enum MissedMessagesActionTypes {
  SET_MISSED_MESSAGES = 'SET_MISSED_MESSAGES'
}
