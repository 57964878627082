import React from 'react';

import {
  BloodPressure,
  GraphLoadingPlaceholder,
  HistoryFilterTabs,
  HistoryLabel
} from 'Residents';

import { BloodPressureChart } from './BloodPressureChart';
import { toMonthData } from './toMonthData';
import { toWeekData } from './toWeekData';

import styles from './../Charts.module.css';

export const BloodPressureHistoryChart: React.FunctionComponent<{
  changeHistoryFilter: (filter: HistoryLabel) => void;
  activeHistoryTab: HistoryLabel;
  readings: BloodPressure[];
  loading: boolean;
}> = ({ changeHistoryFilter, activeHistoryTab, readings, loading }) => {
  return (
    <div className={styles.chartBloodPressure}>
      <HistoryFilterTabs
        onSelect={changeHistoryFilter}
        activeTab={activeHistoryTab}
      />
      {!loading && (
        <BloodPressureChart
          data={
            activeHistoryTab !== 'This Month'
              ? toWeekData(readings)
              : toMonthData(readings)
          }
          allowHighlight={activeHistoryTab !== 'Last Week'}
        />
      )}
      {loading && <GraphLoadingPlaceholder />}
    </div>
  );
};
