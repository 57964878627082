import { ERROR_COLOR, PRIMARY_COLOR } from 'consts';

export const getDefaultStyles = (props: { [key: string]: any }) => ({
  clearIndicator: (provided: any) => ({
    ...provided,
    display: 'none'
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    width: 0
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '25px',
    marginLeft: '3px',
    '& div': {
      padding: '0 5px',
      color: PRIMARY_COLOR
    },
    '&:hover div': {
      color: PRIMARY_COLOR
    },
    '& svg': {
      width: '15px',
      height: '15px'
    }
  }),
  input: (provided: any) => ({
    '& input': {
      fontWeight: 300
    },
    ...provided,
    padding: 0,
    margin: 0
  }),
  control: (provided: any, state: any) => {
    return {
      ...provided,
      border: 'none',
      outline: 'none',
      borderBottom: props.hasError
        ? `1px solid ${ERROR_COLOR}`
        : `1px solid ${PRIMARY_COLOR}`,
      borderRadius: 0,
      fontWeight: 300,
      color: '#57565a',
      minHeight: 0,
      fontSize: '14px',
      padding: '0px 0px 2px 0px',
      boxShadow: 'none',
      alignItems: 'flex-start',
      '&:hover': {
        borderBottom: props.hasError
          ? `1px solid ${ERROR_COLOR}`
          : `1px solid ${PRIMARY_COLOR}`
      },
      '& > div': {
        padding: '0',
        maxHeight: '25px',
        alignItems: 'flex-start'
      },
      '& > div:first-of-type': {
        overflowY: props.isMulti ? 'auto' : 'hidden'
      },
      backgroundColor: 'white',
      opacity: state.isDisabled ? 0.6 : 1
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    fontWeight: 300,
    fontSize: '14px',
    color: state.isSelected ? '#fff' : '#57565a',
    backgroundColor: state.isSelected ? PRIMARY_COLOR : 'transparent',
    '&:hover': {
      backgroundColor: state.isSelected ? PRIMARY_COLOR : '#DEEBFF'
    },
    flex: '1 1 auto',
    alignSelf: 'flex-start',
    minWidth: '100%',
    width: 'auto'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#57565a',
    top: 0,
    transform: 'none',
    opacity: '0.6'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    '& svg': {
      display: 'none'
    },
    backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAkCAYAAADGrhlwAAAAAXNSR0IArs4c6QAAASlJREFUWAnt1kESgiAUBmDwODrdKTd1jOoYuak7NbbuJBmvxKkUAXk/tXgs0gp4fL/MiFLSJAFJQBKQBCSBP0+gPF72ZdN2dM291Kpp1zG1tW+BT4QudkO/7n64bldZYITplDrF1C6Gzq6bdwz1Md9zPKkRpq/tWqb93Q8yT8R2Hq5g1CTGFDfbqR7W4LjxbjkaN9p2djLA9pvDtJvqbEu7rkEgGpwDlYqhdQaD0CgOTDQIheLCLAJxozgxi0FcKG5MEigVhcAkg5aiUBgWUCwKiWEDhaLQGFaQD6V1cfs4aNIA08yLsA45Abx6+z+jXqz+6WZOFBODuTFUgh1EkzqPSfRn3xAYmhoCoonnUCgMFORCITFw0DcKjaF60iQBSUASkAQkgV8m8ADaptHGnvPZYAAAAABJRU5ErkJggg==')`,
    backgroundRepeat: 'no-repeat, repeat',
    backgroundPosition: 'center top -1px',
    backgroundSize: '25px auto, 100%',
    width: '25px',
    height: '20px'
  }),
  multiValue: (provided: any) => ({
    ...provided,
    marginTop: 0
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '3px',
    top: '35px'
  }),
  container: (provided: any) => ({
    ...provided,
    height: '30px',
    flex: '1'
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: (state.data && state.data.color) || '#57565a'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontSize: '14px'
  }),
  menuList: (provided: any) => ({
    ...provided,
    overflow: 'auto'
  })
});
