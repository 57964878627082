import moment from 'moment';

import { emptyMonth, OxygenSaturation, weekOfMonth } from 'Residents';

import { warningCodes } from '../consts/warningCodes.constant';

const convertReadings = (week: string, readings: OxygenSaturation[]) => {
  const oxygenSaturationReadings = readings
    .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
    .map(entry => parseInt(entry.value.oxygenSaturation, 10));

  const oxygenSaturation =
    oxygenSaturationReadings.length > 0
      ? Math.round(
          oxygenSaturationReadings.reduce((a, b) => a + b) /
            oxygenSaturationReadings.length
        )
      : undefined;

  const hasWarning = warningCodes.some(code =>
    readings
      .filter(entry => 'Week ' + weekOfMonth(moment(entry.measuredAt)) === week)
      .map(entry => entry.code)
      .includes(code as 'GREEN' | 'ORANGE' | 'RED')
  );

  return {
    oxygenSaturation,
    hasWarning,
    unit: readings[0] ? readings[0].unit : ''
  };
};

export const toMonthData = (data: OxygenSaturation[]) =>
  emptyMonth.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
