import React, { useMemo } from 'react';

import {
  ProfilePlaceholder,
  RoundCrossButtonRed,
  RoundPlusButton
} from 'components';

import { VACANT_ROOM_NAME } from 'consts';
import { ResidentListItem } from 'Residents/types';
import { formatName } from 'utils';

import style from './AssignableResident.module.css';

export const AssignableResident: React.FunctionComponent<{
  data: ResidentListItem;
  isAssigned: boolean;
  handleAssignment: (id: string) => void;
}> = ({ data, isAssigned, handleAssignment }) => {
  const { room, resident } = data;
  const residentData = resident || {
    id: '',
    name: VACANT_ROOM_NAME,
    gender: 'Unknown',
    photoUrl: ''
  };
  const { name, photoUrl } = residentData;
  const type = residentData.gender.toLowerCase();

  const onClick = () => {
    handleAssignment(room.id);
  };

  const formattedName = useMemo(() => formatName(name), [name]);

  return (
    <li className={style.unselectableEntry}>
      <ProfilePlaceholder name={name} type={type} photoUrl={photoUrl} />
      <div className={style.details}>
        <span className={style[type]}>{formattedName}</span>
        <span className={style.room}>{`Room: ${room.number}`}</span>
      </div>
      <div className={style.action}>
        {isAssigned ? (
          <RoundCrossButtonRed onClick={onClick} size={32} />
        ) : (
          <RoundPlusButton onClick={onClick} />
        )}
      </div>
    </li>
  );
};
