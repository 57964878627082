import React from 'react';

import { RoundButton } from './RoundButton';

import styles from './RoundEditButton.module.css';

import { ReactComponent as EditIcon } from 'icons/edit.svg';

export const RoundEditButton: React.FunctionComponent<{
  onClick: () => void;
  testId?: string;
}> = ({ onClick, testId }) => (
  <RoundButton
    className={styles.roundEditButton}
    onClick={onClick}
    testId={testId}
  >
    <EditIcon />
  </RoundButton>
);
