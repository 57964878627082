import classnames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'Auth';
import {
  getCaregiversByWard,
  RoomAssignment,
  StaffMember,
  StatusTypes,
  useCaregiversStatus
} from 'Caregivers';
import {
  ChatContact,
  ChatRoom,
  CommunicationActionTypes,
  ConversationTypes,
  IMissedCallCount,
  IMissedMessage,
  useCommunicationDispatch
} from 'Communication';
import { FetchError, LoadingPlaceholder, Snackbar } from 'components';
import { residentLabels } from 'consts';
import { useFetcher, useModal } from 'hooks';
import { DeviceLocation, useLocationState } from 'Location';
import { getRoomAssignments, useResidentState } from 'Residents';
import { getCaregiversByStatus } from 'Schedule';
import { Caregiver } from 'Settings';
import { UserId } from 'types';
import { getCallCount, sortByKey } from 'utils';

import { CaregiverListEntry } from './CaregiverListEntry';
import { CaregiverListHeader } from './CaregiverListHeader';
import { ManageCaregiversList } from './ManageCaregiversList';

import style from './CaregiversList.module.css';

interface ICaregiversListProps {
  vacantRoomId: string | null;
  onCaregiverSelect: (caregiver: ChatContact) => void;
  selectedContact: ChatContact | undefined;
  chatRooms: ChatRoom[];
  fetching: boolean;
  externalError: Error | null;
  missedMessages: IMissedMessage[];
  missedCalls: IMissedCallCount[];
}

export const CaregiversList: React.FunctionComponent<ICaregiversListProps> = ({
  vacantRoomId,
  onCaregiverSelect,
  selectedContact,
  missedMessages,
  missedCalls,
  fetching,
  chatRooms,
  externalError
}) => {
  const [selectedCaregiverId, setSelectedCaregiverId] = useState<
    UserId | undefined
  >(undefined);
  const [contentList, setContentList] = useState<JSX.Element[]>([]);
  const { loginId: loggedUserLoginId } = useAuthState();

  const [createChatRoomStatus, setCreateChatRoomStatus] = useState<{
    loading: boolean;
    error: Error | null;
  }>({ loading: false, error: null });

  const {
    facility: { id: facilityId, type: facilityType },
    ward: wardId,
    devicesLocation,
    rtlsLoading,
    rtlsError
  } = useLocationState();

  const { residentDetails } = useResidentState();
  const { room = { id: '' }, id: selectedResidentId } = residentDetails;

  const {
    isShowing: isActionModalOpen,
    toggle: toggleActionModal
  } = useModal();
  const {
    data: caregiversStatusMap,
    loading: statusLoading,
    error: statusError
  } = useCaregiversStatus();

  const dispatch = useCommunicationDispatch();

  const selectedRoomId =
    vacantRoomId && !selectedResidentId ? vacantRoomId : room.id; // vacant room or resident's room

  const caregiversByWardAction = useMemo(() => {
    if (facilityId && wardId) {
      return getCaregiversByWard(facilityId, wardId);
    }
  }, [facilityId, wardId]);

  const roomAssignmentsAction = useMemo(() => {
    const roomId = vacantRoomId || room.id; // vacant room or resident's room
    if (roomId) {
      const workingDay = moment().format('YYYY-MM-DD');
      return getRoomAssignments({ workingDay, roomId });
    }
  }, [room.id, vacantRoomId]);

  const {
    data: caregiversByWard,
    error: caregiversError,
    loading: caregiversLoading
  } = useFetcher<Caregiver[]>(caregiversByWardAction, []);

  const {
    data: roomAssignments,
    error: roomAssignmentsError,
    loading: roomAssignmentsLoading,
    setRefetch: refetchRoomAssignments
  } = useFetcher<RoomAssignment[]>(roomAssignmentsAction, []);

  useEffect(() => {
    setSelectedCaregiverId(undefined);
  }, [facilityId, wardId]);

  const loading =
    caregiversLoading ||
    roomAssignmentsLoading ||
    rtlsLoading ||
    statusLoading ||
    fetching;

  const fetchError =
    caregiversError ||
    roomAssignmentsError ||
    rtlsError ||
    statusError ||
    externalError;

  const getCaregiverLocation = useCallback(
    (location: DeviceLocation | undefined) => {
      if (!location || !location.room) {
        return undefined;
      }
      const {
        room: { number: roomNumber, ward }
      } = location;
      return { room: roomNumber, ward: ward.name };
    },
    []
  );

  const activeCaregivers = useMemo(() => {
    const { available, unavailable } = getCaregiversByStatus(
      caregiversByWard,
      caregiversStatusMap
    );
    return [...available, ...unavailable];
  }, [caregiversByWard, caregiversStatusMap]);

  const residentCaregivers = useMemo(() => {
    const { available, unavailable } = roomAssignments.reduce(
      (caregivers, { caregiverId }) => {
        const location = devicesLocation.find(
          ({ caregiver }) => caregiver && caregiver.id === caregiverId
        );

        const caregiverDetails = activeCaregivers.find(
          ({ id }) => id === caregiverId
        );

        if (caregiverDetails) {
          const caregiverLocation = getCaregiverLocation(location);

          const status = caregiversStatusMap[caregiverId];

          const residentCaregiver = {
            id: caregiverId,
            name: caregiverDetails.name,
            role: caregiverDetails.role,
            status,
            location: caregiverLocation,
            loginId: caregiverDetails.loginId,
            photoUrl: caregiverDetails.photoUrl
          } as StaffMember;

          if (status === StatusTypes.OFF_DUTY) {
            caregivers.unavailable.push(residentCaregiver);
          } else {
            caregivers.available.push(residentCaregiver);
          }
        }
        return caregivers;
      },
      {
        available: [] as StaffMember[],
        unavailable: [] as StaffMember[]
      }
    );

    return [...sortByKey(available, 'name'), ...sortByKey(unavailable, 'name')];
  }, [
    roomAssignments,
    devicesLocation,
    activeCaregivers,
    getCaregiverLocation,
    caregiversStatusMap
  ]);

  useEffect(() => {
    if (selectedRoomId) {
      dispatch({
        type: CommunicationActionTypes.CLEAR_COMMUNICATION
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomId]);

  useEffect(() => {
    if (selectedContact?.residentId === undefined) {
      setSelectedCaregiverId(selectedContact?.userId);
    } else {
      setSelectedCaregiverId(undefined);
    }
  }, [selectedContact]);

  const onSelectCaregiver = useCallback(
    (caregiver: StaffMember) => {
      if (caregiver.id === selectedCaregiverId) {
        return undefined;
      }

      const careChat: ChatContact = {
        chatRoomType: ConversationTypes.CAREGIVER,
        name: caregiver.name,
        relationship: undefined,
        role: caregiver.role,
        userId: caregiver.id,
        loginId: caregiver.loginId,
        photoUrl: caregiver.photoUrl,
        resident: undefined,
        residentId: undefined,
        status: caregiver.status
      };

      onCaregiverSelect(careChat);
    },
    [selectedCaregiverId, onCaregiverSelect]
  );

  useEffect(() => {
    const content = residentCaregivers.map((caregiver: StaffMember) => {
      const caregiverChat = chatRooms.find(entry =>
        entry.participants.includes(caregiver.loginId)
      );

      const callCount = getCallCount(caregiverChat?.id, missedCalls);

      const messagesCount = missedMessages.find(
        counts => counts.chatRoomId === caregiverChat?.id
      )?.messages;

      return (
        <CaregiverListEntry
          activeChat={caregiverChat}
          currentChatId={caregiverChat?.id}
          key={caregiver.id}
          data={caregiver}
          callCount={callCount}
          messagesCount={messagesCount}
          isSelected={selectedCaregiverId === caregiver.id}
          onSelect={onSelectCaregiver}
        />
      );
    });
    setContentList(content);
  }, [
    caregiversByWard,
    chatRooms,
    loggedUserLoginId,
    missedCalls,
    missedMessages,
    onSelectCaregiver,
    residentCaregivers,
    selectedCaregiverId
  ]);

  const noDataMessage = useMemo(() => {
    if (selectedResidentId) {
      return `There are no caregivers assigned to this ${residentLabels[
        facilityType
      ].toLocaleLowerCase()}`;
    } else {
      return 'No caregiver information available';
    }
  }, [facilityType, selectedResidentId]);

  const caregiversByRoom = useMemo(() => {
    if (contentList.length && selectedRoomId) {
      return <ul>{contentList}</ul>;
    } else {
      return <p className={style.listPlaceholder}>{noDataMessage}</p>;
    }
  }, [contentList, noDataMessage, selectedRoomId]);

  const dismissError = () =>
    setCreateChatRoomStatus(prevState => ({
      ...prevState,
      error: null
    }));

  return (
    <>
      <div
        className={classnames(style.list, { [style.hasError]: !!fetchError })}
        id="CaregiversList"
      >
        {!fetchError && <CaregiverListHeader onEdit={toggleActionModal} />}
        {loading && !fetchError && <LoadingPlaceholder />}
        {!loading && !fetchError && caregiversByRoom}
        {fetchError && !loading && <FetchError error={fetchError} />}
      </div>
      <ManageCaregiversList
        availableCaregivers={activeCaregivers}
        assignedCaregivers={residentCaregivers}
        isOpen={isActionModalOpen}
        onClose={toggleActionModal}
        onChange={refetchRoomAssignments}
        wardId={wardId}
        roomId={selectedRoomId}
        caregiversStatus={caregiversStatusMap}
      />
      <Snackbar
        isOpen={!!createChatRoomStatus.error}
        onClose={dismissError}
        message="Cannot initiate a chat room. Please try again or refresh the page."
      />
    </>
  );
};
