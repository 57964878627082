import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { Modal, SearchHeader } from 'components';
import { VACANT_ROOM_NAME } from 'consts';
import { useModalMeasurements, usePoster, useSubmitError } from 'hooks';
import { useLabelState } from 'Location';
import {
  assignCaregiverToRoom,
  ResidentListItem,
  unassignCaregiverFromRoom
} from 'Residents';
import { Resident, Room } from 'Settings';
import { sortByKey } from 'utils';

import { AssignableResident } from './AssignableResident';

import styles from './ManageResidentsList.module.css';

export const ManageResidentsList: React.FunctionComponent<{
  availableResidents: Resident[];
  assignedResidents: ResidentListItem[];
  availableRooms: Room[];
  isOpen: boolean;
  onClose: () => void;
  onChange: () => void;
  wardId: string;
  caregiverId: string;
}> = ({
  assignedResidents,
  availableResidents,
  availableRooms,
  isOpen,
  onClose,
  onChange,
  wardId,
  caregiverId
}) => {
  const label = useLabelState();
  const [searchValue, setSearchValue] = useState('');
  const { modalOffsetTop, modalOffsetLeft } = useModalMeasurements(
    'ResidentsList',
    'MainContent',
    isOpen
  );
  const { setAction, loading: onSaveLoading, error } = usePoster();
  const { submitted, setSubmitted } = useSubmitError(error, onSaveLoading);

  const unassignCaregiver = (roomId: string) => {
    const workingDay = moment().format('YYYY-MM-DD');
    setAction(
      unassignCaregiverFromRoom({ wardId, roomId, caregiverId, workingDay })
    );
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const assignCaregiver = (roomId: string) => {
    const workingDay = moment().format('YYYY-MM-DD');
    setAction(
      assignCaregiverToRoom({ wardId, roomId, caregiverId, workingDay })
    );
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const availableItems = useMemo(
    () =>
      availableRooms.reduce(
        (available, { id: roomId, number: roomNumber, residents, ward }) => {
          const room = { id: roomId, number: roomNumber, ward };
          if (!residents.length || !residents.find(({ status }) => status)) {
            return [
              ...available,
              {
                resident: {
                  id: '',
                  name: VACANT_ROOM_NAME,
                  gender: 'Unknown'
                },
                room
              } as ResidentListItem
            ];
          }

          const residentsFromRoom = sortByKey(residents, 'name').reduce(
            (roomResidents, { id: residentId, name }) => {
              const residentDetails = availableResidents.find(
                ({ id }) => id === residentId
              );
              if (!residentDetails) {
                return roomResidents;
              }

              return [
                ...roomResidents,
                {
                  resident: {
                    id: residentId,
                    name,
                    gender: residentDetails && residentDetails.gender,
                    photoUrl: residentDetails.photoUrl
                  },
                  room,
                  hasWarning: false
                } as ResidentListItem
              ];
            },
            []
          );
          return [...available, ...residentsFromRoom];
        },
        [] as ResidentListItem[]
      ),
    [availableRooms, availableResidents]
  );

  const filteredResidentsAndRooms = useMemo(() => {
    if (searchValue.length < 3) {
      return [];
    }

    return availableItems.filter(({ resident, room }) => {
      const value = searchValue.toLowerCase();
      const residentNameFilter =
        resident && resident.name.toLowerCase().includes(value);
      const roomNumberFilter = room.number.toLowerCase().includes(value);

      return residentNameFilter || roomNumberFilter;
    });
  }, [searchValue, availableItems]);

  useEffect(() => {
    if (submitted && !onSaveLoading && !error) {
      onChange();
      setSubmitted(false);
    }
  }, [submitted, onSaveLoading, error, onChange, setSubmitted]);

  const assignedResidentsContent = assignedResidents.map(item => (
    <AssignableResident
      key={(item.resident && item.resident.id) || item.room.id}
      data={item}
      isAssigned={true}
      handleAssignment={unassignCaregiver}
    />
  ));

  const filteredContent = filteredResidentsAndRooms.map(
    (item: ResidentListItem) => {
      const { resident, room } = item;
      const isAssigned = assignedResidents.find(
        ({ resident: residentToMatch, room: roomToMatch }) => {
          const matchRoom = roomToMatch.id === room.id;
          const matchResident =
            resident && residentToMatch && resident.id === residentToMatch.id;
          return resident && resident.id ? matchResident : matchRoom;
        }
      );
      const action = isAssigned ? unassignCaregiver : assignCaregiver;

      return (
        <AssignableResident
          key={(resident && resident.id) || room.id}
          data={item}
          isAssigned={!!isAssigned}
          handleAssignment={action}
        />
      );
    }
  );

  return (
    <Modal isShowing={isOpen} toggle={onClose}>
      <div className={styles.modalBackdrop}>
        <div
          className={styles.modalContent}
          style={{
            marginTop: modalOffsetTop + 110,
            marginLeft: modalOffsetLeft
          }}
        >
          <div className={styles.listsContainer}>
            <SearchHeader
              customStyle={{
                paddingRight: '5px',
                paddingLeft: 0
              }}
              searchInputName={`${label.toLowerCase()}s and rooms`}
              onClose={onClose}
              onSearch={setSearchValue}
              searchValue={searchValue}
            />
            {!!filteredResidentsAndRooms.length && (
              <ul className={styles.availableResidentsList}>
                {filteredContent}
              </ul>
            )}
            {!!assignedResidents.length && (
              <ul className={styles.assignedResidentsList}>
                {assignedResidentsContent}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
