import moment from 'moment';

import { emptyWeek, StaffVisit } from 'Residents';

const convertReadings = (day: string, readings: StaffVisit[]) => {
  const visitReadings = readings.find(
    entry => moment(entry.timestamp).format('ddd') === day
  );

  const visits = Number(visitReadings?.value.numberOfVisits);

  return {
    visits: isNaN(visits) ? 0 : visits,
    unit: visits === 1 ? 'visit' : 'visits'
  };
};

export const toWeekData = (data: StaffVisit[]) =>
  emptyWeek.map(entry => {
    return {
      label: entry,
      ...convertReadings(entry, data)
    };
  });
