import React from 'react';
import ReactDOM from 'react-dom';

export const Modal: React.FunctionComponent<{
  isShowing: boolean;
  toggle: () => void;
  parentId?: string;
  children: React.ReactNode;
}> = ({ isShowing, toggle, parentId, children }) => {
  const parent = parentId && document.getElementById(parentId);

  return isShowing
    ? ReactDOM.createPortal(<>{children}</>, parent || document.body)
    : null;
};
