import React from 'react';

import { ReactComponent as Bed } from 'icons/bed.svg';
import { secondsToDecimalHours, SleepingHours } from 'Residents';

import { ActivityInfoCard } from './ActivityInfoCard';

import style from './SleepingHoursCard.module.css';

export const SleepingHoursCard: React.FunctionComponent<{
  sleepingHours: SleepingHours | undefined;
  onClick: () => void;
}> = ({ sleepingHours, onClick }) => {
  const { value, unit } = sleepingHours || {};
  const reading =
    value && Math.trunc(secondsToDecimalHours(parseInt(value.count, 10)));

  return (
    <div className={style.sleepingHours} onClick={onClick}>
      <ActivityInfoCard reading={reading} unit={unit} type="sleepingHours">
        <Bed />
      </ActivityInfoCard>
    </div>
  );
};
