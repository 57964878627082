import React, { useEffect, useRef } from 'react';

import { TextStatus } from 'components';
import { Resident } from 'Settings';
import { formatWardRoom } from 'utils';

import stylesTable from 'components/Table/Table.module.css';

export const ResidentTableRow: React.FunctionComponent<{
  values: Resident;
  isSelected: boolean;
  selectResident: (id: string) => void;
}> = ({ values, isSelected, selectResident }) => {
  const handleClick = () => selectResident(values.id);
  const rowClass = isSelected
    ? `${stylesTable.tableRow} ${stylesTable.selected}`
    : stylesTable.tableRow;

  const row = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && row.current) {
      row.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={row} className={rowClass} role="row" onClick={handleClick}>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 3 }}
        role="cell"
      >
        {values.name}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {formatWardRoom(values.room)}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 2 }}
        role="cell"
      >
        {values.number ? values.number : 'N/A'}
      </div>
      <div
        className={stylesTable.tableCell}
        style={{ flexGrow: 1, textAlign: 'center' }}
        role="cell"
      >
        <TextStatus status={values.isActive} />
      </div>
    </div>
  );
};
