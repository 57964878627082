import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

import { useAuthState } from 'Auth';
import { LoadingPlaceholder } from 'components';
import { useAddEventListener } from 'hooks';
import { ChatId } from 'types';
import {
  debounce,
  formatMessageTimestamp,
  generateReverseTriggerFunction,
  isIEorEdge,
  sortByDate,
  triggerPagination
} from 'utils';

import { ChatBubble } from './ChatBubble';
import { ParticipantBubble } from './ParticipantBubble';
import { ChatMessage, ChatMessageTypes } from './types';

import styles from './ChatMessages.module.css';

export const ChatMessages: React.FunctionComponent<{
  onCancel: (message: ChatMessage) => void;
  onRetry: (message: ChatMessage) => void;
  onTrigger: () => void;
  messages: ChatMessage[];
  scrollToNewestMessage: boolean;
  resetScroll: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  error: Error | null;
  chatId: ChatId | undefined;
}> = ({
  messages,
  onCancel,
  onRetry,
  onTrigger,
  scrollToNewestMessage = false,
  resetScroll,
  loading,
  error,
  chatId
}) => {
  const [localList, setLocalList] = useState<JSX.Element[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [initialPagination, setInitialPagination] = useState<boolean>(true);
  const [numberOfLoadedPictures, setNumberOfLoadedPictures] = useState<number>(
    0
  );
  const [numberFileMessages, setNumberFileMessages] = useState<number>(0);
  const timeoutId = useRef<number | undefined>(undefined);
  const wrapper = useRef<HTMLDivElement>(null);
  const { current } = wrapper;

  const { loggedUser } = useAuthState();

  useEffect(() => {
    const fileMessages = messages.filter(
      message => message.type === ChatMessageTypes.FILE_MESSAGE
    );

    setNumberFileMessages(fileMessages.length);

    const list = sortByDate(messages, 'sentAt').map((message, index) => {
      const time = formatMessageTimestamp(message.sentAt);
      if (
        message.type === ChatMessageTypes.PARTICIPANT_ADDED ||
        message.type === ChatMessageTypes.PARTICIPANT_REMOVED
      ) {
        return (
          <ParticipantBubble key={message.id} message={message} time={time} />
        );
      }

      const isCurrentUser =
        loggedUser?.id === message.author.id ||
        loggedUser?.loginId === message.author.loginId;

      return (
        message && (
          <ChatBubble
            onCancel={onCancel}
            onRetry={onRetry}
            key={message.id}
            isCurrentUser={isCurrentUser}
            message={message}
            time={time}
            onPicLoad={setNumberOfLoadedPictures}
          />
        )
      );
    });
    setLocalList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useAddEventListener(
    current,
    'scroll',
    debounce(generateReverseTriggerFunction(current!, setTrigger)),
    !initialPagination
  );

  useEffect(() => {
    if (trigger) {
      setTrigger(false);
      onTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (trigger || !current) {
      return undefined;
    }

    let flag;
    flag = triggerPagination(current);

    setTrigger(flag);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localList, current]);

  // scroll to the newest message when selecting a new chat
  useLayoutEffect(() => {
    if (
      (numberFileMessages === 0 ||
        (numberFileMessages > 0 &&
          numberOfLoadedPictures === numberFileMessages)) &&
      current &&
      initialPagination &&
      localList.length > 0
    ) {
      window.clearTimeout(timeoutId.current);
      const id = window.setTimeout(() => {
        if (isIEorEdge()) {
          current.scrollTop = current.scrollHeight + 1000;
        } else {
          current.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: current.scrollHeight + 1000
          });
        }

        resetScroll(false);
        setInitialPagination(false);
      }, 300);

      timeoutId.current = id;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    numberFileMessages,
    numberOfLoadedPictures,
    current,
    initialPagination,
    localList
  ]);

  // scroll to the newest message when send or receiving a message
  useLayoutEffect(() => {
    if (
      (numberFileMessages === 0 ||
        (numberFileMessages > 0 &&
          numberOfLoadedPictures === numberFileMessages)) &&
      scrollToNewestMessage &&
      current &&
      !initialPagination &&
      !loading
    ) {
      window.clearTimeout(timeoutId.current);
      const id = window.setTimeout(() => {
        if (isIEorEdge()) {
          current.scrollTop = current.scrollHeight + 1000;
        } else {
          current.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: current.scrollHeight + 1000
          });
        }
        resetScroll(false);
      }, 300);
      timeoutId.current = id;
    }

    return () => {
      window.clearTimeout(timeoutId.current);
    };
  }, [
    scrollToNewestMessage,
    current,
    resetScroll,
    initialPagination,
    numberFileMessages,
    numberOfLoadedPictures,
    loading
  ]);

  useEffect(() => {
    setNumberOfLoadedPictures(0);
    setNumberFileMessages(0);
    setInitialPagination(true);
  }, [chatId]);

  return (
    <>
      {messages.length === 0 && !loading && !error && (
        <h4 className={styles.emptyPlaceholder}>
          This conversation's history is empty
        </h4>
      )}
      <div className={styles.messagesContainer} ref={wrapper}>
        {localList.length > 0 && (
          <div className={styles.listContainer}>{localList}</div>
        )}
      </div>
      {(loading ||
        (numberFileMessages > 0 &&
          numberOfLoadedPictures !== numberFileMessages)) &&
        !error && (
          <LoadingPlaceholder fullHeight={true} withTransparency={true} />
        )}
    </>
  );
};
