import React, { useCallback, useEffect, useMemo } from 'react';

import { DetailsPlaceholder, FetchError, LoadingPlaceholder } from 'components';
import { useFetcher } from 'hooks';
import { useLocationContext } from 'Location';
import { getResidentDetails, Resident } from 'Settings';

import { useResidentContext } from './resident.context';
import { ResidentDetailsPanel } from './ResidentDetailsPanel';

import styles from './ResidentDetailsPanel.module.css';

export const WithResidentDetails: React.FunctionComponent<{
  residentId: string | null;
  onEditClick: () => void;
}> = ({ residentId, onEditClick }) => {
  const [{ residentDetails }, dispatch] = useResidentContext();
  const {
    location: { facility },
    label
  } = useLocationContext();
  const residentDetailsAction = useMemo(() => {
    if (residentId) {
      return getResidentDetails(facility.id, residentId);
    }
  }, [residentId, facility.id]);

  const { data, error, loading } = useFetcher<any>(residentDetailsAction, {});

  const onResidentFetch = useCallback(
    (resident: Resident) => {
      dispatch({ type: 'UPDATE_RESIDENT', payload: resident });
    },
    [dispatch]
  );

  useEffect(() => {
    onResidentFetch(data);
  }, [data, onResidentFetch]);

  useEffect(() => {
    if (!residentId) {
      dispatch({ type: 'RESET_RESIDENT' });
    }
  }, [residentId, dispatch]);

  const placeholderMessage = `No ${label.toLowerCase()} assigned to this room`;
  const placeholderTitle = `${label} Details`;
  return (
    <>
      {residentId ? (
        <section className={styles.residentDetailsWrapper} id="ResidentDetails">
          {!loading && !error && (
            <ResidentDetailsPanel
              onEditClick={onEditClick}
              resident={residentDetails}
            />
          )}
          {loading && !error && <LoadingPlaceholder fullHeight={false} />}
          {error && !loading && <FetchError error={error} />}
        </section>
      ) : (
        <div className={styles.detailsPlaceholderWrapper}>
          <DetailsPlaceholder
            message={placeholderMessage}
            title={placeholderTitle}
            showActionButton={false}
          />
        </div>
      )}
    </>
  );
};
