import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { debounce } from 'utils';

import { useSearchContext } from './search.context';

import styles from './SearchBar.module.css';

const pattern = new RegExp("^[A-Za-z0-9 _']*[A-Za-z0-9][A-Za-z0-9 _']*$");

const SearchBarComponent: React.FunctionComponent<RouteComponentProps> = ({
  location
}) => {
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [{ searching }, dispatch] = useSearchContext();

  const onBeginSearch = (evt: any) => {
    if (searching) {
      evt.preventDefault();
      return;
    }
    dispatch({ type: 'INITIATE_SEARCH' });
  };

  const debounceCallback = useCallback(
    debounce((value: string) => {
      if (pattern.test(value.trim())) {
        dispatch({ type: 'SET_SEARCH_TERM', payload: value.trim() });
      }
    }, 500),
    []
  );

  const onInputChange = (evt: any) => {
    const { value } = evt.target;

    setSearchValue(value);
    debounceCallback(value);
  };

  useEffect(() => {
    if (searching && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searching]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'EXIT_SEARCH' });
      setSearchValue('');
    };
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname !== '/Search') {
      dispatch({ type: 'EXIT_SEARCH' });
      setSearchValue('');
    }
  }, [dispatch, location.pathname]);

  return (
    <div
      className={classnames(styles.searchContainer, {
        [styles.searchActive]: searching
      })}
    >
      <input
        className={styles.inputContainer}
        type="search"
        value={searchValue}
        name="search"
        onChange={onInputChange}
        placeholder="Search for caregivers, residents and patients"
        ref={inputRef}
      />
      <NavLink
        exact={true}
        className={styles.searchIcon}
        activeClassName={styles.activeSearchIcon}
        to={'/Search'}
        onClick={onBeginSearch}
      >
        <SearchIcon data-testid="search-icon" />
      </NavLink>
    </div>
  );
};

export const SearchBar = withRouter(SearchBarComponent);
