import React from 'react';

import styles from './NotificationsLoadingPlaceholder.module.css';

export const NotificationsLoadingPlaceholder: React.FunctionComponent = () => {
  return (
    <div className={styles.loadingListPlaceholder}>
      <div className={styles.entryPlaceholder} />
      <div className={styles.entryPlaceholder} />
      <div className={styles.entryPlaceholder} />
    </div>
  );
};
