import { axiosWrapper, blobToBase64 } from 'utils';

export const getUserAvatar = (photoUrl: string) => async () => {
  if (photoUrl === 'string') {
    return 'undefined';
  }

  const response = await axiosWrapper<Blob>(
    photoUrl,
    'GET',
    null,
    null,
    'blob'
  );

  return blobToBase64(response.data);
};
