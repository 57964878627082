import classNames from 'classnames';
import React from 'react';

import styles from './Panel.module.css';

export const PanelHeader: React.FunctionComponent<{
  participants?: boolean;
  title: string;
}> = ({ title, participants, children }) => {
  return (
    <header
      className={classNames(styles.panelHeaderPaddingTop, {
        [styles.panelHeaderWithParticipants]: participants
      })}
    >
      <title
        className={classNames(styles.panelTitle, {
          [styles.panelTitleWithParticipants]: participants
        })}
      >
        {title}
      </title>
      {children && (
        <div className={styles.panelButtonsContainer}>{children}</div>
      )}
    </header>
  );
};
