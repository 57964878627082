export const bodyWeightInterval = (
  data: Array<{
    label: string;
    bodyWeight: number;
  }>
) => {
  let max = 0;
  let min = 2147483647;
  data.forEach(entry => {
    if (entry.bodyWeight < min) {
      min = entry.bodyWeight;
    }
    if (entry.bodyWeight > max) {
      max = entry.bodyWeight;
    }
  });

  if (min === 2147483647) {
    min = 0;
  }
  let interval = 40;

  const intervalMin = Math.trunc(min / 10) * 10;

  let intervalMax = 0;

  // intervalMax must be bigger than the max of the values plus
  //  3 quarters of the interval so that the warning svg is always inside the graph
  while (intervalMax < max + (interval / 4) * 3) {
    intervalMax = intervalMin + 3 * interval;
    interval += 10;
  }
  // we return an arrray representing the ticks of the y axis, the first and last representing
  // the min and max values of the y axis === the domain
  return [
    intervalMin,
    (intervalMax - intervalMin) / 3 + intervalMin,
    (2 * (intervalMax - intervalMin)) / 3 + intervalMin,
    intervalMax
  ];
};
