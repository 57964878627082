import React from 'react';

import {
  FetchError,
  LoadingPlaceholder,
  Modal,
  PanelDetail,
  TextStatus
} from 'components';
import { residentLabels } from 'consts';
import { useModal } from 'hooks';
import { Facility } from 'Settings';
import { MappedWard } from 'Settings/actions/wardsMapping.utils';
import { AlarmPriorityKey, AlarmPriorityTypes } from 'Settings/constants';
import { getHeight } from 'utils';

import { EditWardForm } from './EditWardForm';

import stylesModal from 'components/Modal/Modal.module.css';
import styles from 'Settings/Settings.module.css';

export const WardDetails: React.FunctionComponent<{
  facility: Facility;
  notifyWardChange: (ward: MappedWard) => void;
  selectedWard: MappedWard;
  loading: boolean;
  error: Error | null;
}> = ({ facility, notifyWardChange, selectedWard, loading, error }) => {
  const { isShowing, toggle } = useModal();

  const onEdit = () => {
    toggle();
  };

  const modalClosed = () => {
    toggle();
  };

  const roundingDetails = Boolean(
    selectedWard.services.alarmsNotifications.features.anRounding.isEnabled &&
      selectedWard.services.rtls.features.rtlsStaff.isEnabled
  );

  return (
    <>
      <PanelDetail title="Ward Details" onAction={onEdit} hasError={!!error}>
        {selectedWard && !error && !loading && (
          <>
            <div className={styles.detailRow}>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Ward Name</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {selectedWard.name}
                </span>
              </div>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Rooms</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {selectedWard.numberOfRooms || 0}
                </span>
              </div>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>{`${
                    residentLabels[facility.type]
                  }s`}</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {selectedWard.numberOfResidents || 0}
                </span>
              </div>
              <div className={styles.panelGroupStatus}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Status</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  <TextStatus status={selectedWard.isActive} />
                </span>
              </div>
            </div>
            <div className={styles.detailRow}>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Caregivers</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {selectedWard.numberOfCaregivers || 0}
                </span>
              </div>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Devices</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {selectedWard.numberOfDevices || 0}
                </span>
              </div>
              <div className={styles.panelGroupQuarter}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>
                    Rounding Alarm (min)
                  </h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {roundingDetails
                    ? selectedWard.unattendedResidentAlarmMinutes || 0
                    : 0}
                </span>
              </div>
              <div className={styles.panelGroupAlarmPriority}>
                <div className={styles.panelGroupTitleWrapper}>
                  <h4 className={styles.panelGroupTitle}>Alarm Priority</h4>
                  <span className={styles.panelDetailFixedSpan}>:</span>
                </div>
                <span className={styles.panelDetailSpan}>
                  {roundingDetails
                    ? AlarmPriorityTypes[
                        selectedWard.alarmColorCode as AlarmPriorityKey
                      ] || 'N/A'
                    : 'N/A'}
                </span>
              </div>
            </div>
          </>
        )}
        {loading && <LoadingPlaceholder />}
        {error && !loading && <FetchError error={error} />}

        <Modal isShowing={isShowing} toggle={toggle}>
          <div className={stylesModal.modalBackdrop}>
            <div className={stylesModal.modalContentRightAlign}>
              <div
                className={stylesModal.modalRightWithVerticalBar}
                style={{ marginTop: getHeight('Ward Details') }}
              >
                <EditWardForm
                  toggle={modalClosed}
                  ward={selectedWard}
                  notifyWardChange={notifyWardChange}
                />
              </div>
            </div>
          </div>
        </Modal>
      </PanelDetail>
    </>
  );
};
