import moment, { MomentInput } from 'moment';
import React, { useMemo, useState } from 'react';
import { DateUtils } from 'react-day-picker';

import { CaregiverSchedule, getCaregiverSchedule } from 'Caregivers';
import { Calendar } from 'components';
import { useFetcher } from 'hooks';

import { DailyScheduleLogs } from './DailyScheduleLogs';

import styles from './WorkSchedule.module.css';

export const getMonthInterval = (input: MomentInput) => {
  const firstDayOfMonth = moment(input)
    .startOf('month')
    .day();
  const lastDayOfMonth = moment(input)
    .endOf('month')
    .day();

  const startDate = moment(input)
    .startOf('month')
    .subtract(firstDayOfMonth, 'days');
  const endDate = moment(input)
    .endOf('month')
    .add(6 - lastDayOfMonth, 'days');

  return [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
};

export const WorkSchedule: React.FunctionComponent<{ caregiverId: string }> = ({
  caregiverId
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(new Date());

  const caregiverScheduleAction = useMemo(() => {
    if (caregiverId) {
      const [start, end] = getMonthInterval(currentMonth);
      return getCaregiverSchedule(caregiverId, start, end);
    }
  }, [caregiverId, currentMonth]);

  const { data: caregiverSchedule, error: scheduleError } = useFetcher<
    CaregiverSchedule[]
  >(caregiverScheduleAction, []);

  const workingDays: Date[] = useMemo(() => {
    return caregiverSchedule.reduce((acc, item) => {
      const day = new Date(item.workingDay);
      if (!acc.includes(day)) {
        acc.push(day);
      }

      return acc;
    }, [] as Date[]);
  }, [caregiverSchedule]);

  const scheduledDays = workingDays.filter(day => DateUtils.isFutureDay(day));
  const workedDays = workingDays.filter(day => DateUtils.isPastDay(day));

  return (
    <div className={styles.scheduleContainer}>
      <Calendar
        title="Work Schedule"
        onMonthChange={setCurrentMonth}
        onSelectDay={setSelectedDay}
        styles={styles}
        dayModifiers={
          !scheduleError && caregiverId
            ? {
                [styles.scheduled]: scheduledDays,
                [styles.worked]: workedDays
              }
            : {}
        }
      />
      <DailyScheduleLogs caregiverId={caregiverId} date={selectedDay} />
    </div>
  );
};
