import React, { createContext, useContext, useReducer } from 'react';

import { StaffMemberDetail } from './types';

export enum StaffActionTypes {
  UPDATE_CAREGIVER = 'UPDATE_CAREGIVER',
  RESET_CAREGIVER = 'RESET_CAREGIVER',
  REFETCH_CAREGIVER = 'REFETCH_CAREGIVER'
}

type Action =
  | { type: StaffActionTypes.UPDATE_CAREGIVER; payload: StaffMemberDetail }
  | { type: StaffActionTypes.RESET_CAREGIVER }
  | { type: StaffActionTypes.REFETCH_CAREGIVER };

interface State {
  caregiverDetails: StaffMemberDetail;
  shouldRefetchDetails: boolean;
}

type Dispatch = (action: Action) => void;

const CaregiverStateContext = createContext<State | undefined>(undefined);
const CaregiverDispatchContext = createContext<Dispatch | undefined>(undefined);

export const caregiverReducer = (state: State, action: Action) => {
  switch (action.type) {
    case StaffActionTypes.UPDATE_CAREGIVER: {
      return {
        ...state,
        caregiverDetails: { ...action.payload } as StaffMemberDetail,
        shouldRefetchDetails: false
      };
    }
    case StaffActionTypes.RESET_CAREGIVER: {
      return { ...state, caregiverDetails: {} as StaffMemberDetail };
    }
    case StaffActionTypes.REFETCH_CAREGIVER: {
      return { ...state, shouldRefetchDetails: true };
    }
  }
};

export const CaregiverProvider: (props: {
  children: React.ReactNode;
}) => any = ({ children }) => {
  const [state, dispatch] = useReducer(caregiverReducer, {
    caregiverDetails: {} as StaffMemberDetail,
    shouldRefetchDetails: false
  });
  return (
    <CaregiverStateContext.Provider value={state}>
      <CaregiverDispatchContext.Provider value={dispatch}>
        {children}
      </CaregiverDispatchContext.Provider>
    </CaregiverStateContext.Provider>
  );
};

export const useCaregiverState = () => {
  const context = useContext(CaregiverStateContext);
  if (context === undefined) {
    throw new Error(
      'useCaregiverState must be used within a CaregiverProvider'
    );
  }
  return context;
};

export const useCaregiverDispatch = () => {
  const context = useContext(CaregiverDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useCaregiverDispatch must be used within a CaregiverProvider'
    );
  }
  return context;
};

export const useCaregiverContext = (): [State, Dispatch] => {
  return [useCaregiverState(), useCaregiverDispatch()];
};
