import { axiosWrapper } from 'utils';

export const uploadFile = async (
  file: File,
  chatRoomId: string,
  participantId: string
) => {
  const formData: any = new FormData();
  formData.append('file', file, file.name);

  const url = `/config/chat-rooms/${chatRoomId}/participants/${participantId}/upload`;
  const response = await axiosWrapper(url, 'POST', null, formData);

  // this is undefined
  return response.headers.location;
};
