import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  COMMUNICATION_ROLES,
  getSettingsTabsForRole,
  RolesEnum,
  SETTINGS_TABS_ACCESS,
  useAuthState,
  UserRole
} from 'Auth';
import { CaregiverDashboard, CaregiversOverview } from 'Caregivers';
import { CallModal, CommunicationWithProvider } from 'Communication';
import {
  AdHocProvider,
  MessagingProvider,
  MissedCallsProvider,
  MissedCountProvider,
  MissedMessagesProvider
} from 'Communication/contexts';
import {
  Footer,
  Header,
  PageNotFound,
  PrivateAccessRoute,
  Sidenav
} from 'components';
import { AvatarsProvider } from 'contexts-global/avatar.context';
import { Home } from 'Home';
import { ResidentsDashboard, ResidentsOverview } from 'Residents';
import { Schedule } from 'Schedule';
import { GlobalSearch, SearchProvider } from 'Search';
import { Settings } from 'Settings';
import { APP_VERSION } from 'utils/version';

import { Location } from './Location';
import { FacilitySettings } from './Settings/Facility/FacilitySettings';
import { UIlib } from './UIlib/UIlib';

import { HomeViewProvider } from 'Home/contexts/home-view.context';
import { DashboardViewOptions } from 'Home/DashboardView/DashboardViewOptions';
import { CardsProvider } from 'Residents/contexts';

import styles from 'styles/layout.module.css';

export const AuthenticatedApp: React.FunctionComponent = () => {
  const { isAuthenticated, role } = useAuthState();
  const hasAccessToCommunication =
    isAuthenticated && role && COMMUNICATION_ROLES.includes(role as UserRole);
  const settingsTabs = getSettingsTabsForRole(role)
    ? `:activeTab(${getSettingsTabsForRole(role)!.join('|')})?`
    : '';

  return (
    <>
      <AvatarsProvider>
        <MissedCountProvider>
          <MissedCallsProvider>
            <MissedMessagesProvider>
              <MessagingProvider>
                <SearchProvider>
                  <AdHocProvider>
                    <CardsProvider>
                      <HomeViewProvider>
                        {isAuthenticated && <Header />}
                        <div className={styles.mainLayout}>
                          <Sidenav />
                          <div className={styles.mainContent} id="MainContent">
                            <Switch>
                              <PrivateAccessRoute
                                exact={true}
                                path="/Settings/Facilities/:id/:activeTab"
                                component={FacilitySettings}
                                roles={
                                  Object.keys(
                                    SETTINGS_TABS_ACCESS
                                  ) as UserRole[]
                                }
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path={`/Settings/${settingsTabs}`}
                                component={Settings}
                                roles={
                                  Object.keys(
                                    SETTINGS_TABS_ACCESS
                                  ) as UserRole[]
                                }
                              />
                              <PrivateAccessRoute
                                path="/Location"
                                component={Location}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/Residents"
                                component={ResidentsOverview}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/ResidentDashboard"
                                component={ResidentsDashboard}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/Caregivers"
                                component={CaregiversOverview}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/CaregiverDashboard"
                                component={CaregiverDashboard}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/Schedule"
                                component={Schedule}
                                roles={[
                                  RolesEnum.ROLE_ADMIN,
                                  RolesEnum.ROLE_REGULAR_ADMIN,
                                  RolesEnum.ROLE_CAREGIVER_MANAGER
                                ]}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/Communication"
                                component={CommunicationWithProvider}
                                roles={COMMUNICATION_ROLES}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/UIlib"
                                component={UIlib}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/Home"
                                component={Home}
                              />
                              <PrivateAccessRoute
                                exact={true}
                                path="/Search"
                                component={GlobalSearch}
                              />
                              <Route exact={true} path="/">
                                <Redirect to="/Home" />
                              </Route>
                              <PrivateAccessRoute component={PageNotFound} />
                            </Switch>
                          </div>
                        </div>
                        <DashboardViewOptions />
                      </HomeViewProvider>
                    </CardsProvider>
                  </AdHocProvider>
                </SearchProvider>
              </MessagingProvider>
            </MissedMessagesProvider>
          </MissedCallsProvider>
        </MissedCountProvider>
        <Footer APP_VERSION={APP_VERSION} />
        {hasAccessToCommunication && <CallModal />}
      </AvatarsProvider>
    </>
  );
};
