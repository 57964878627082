import { MAX_PAGE_SIZE } from 'consts';
import { axiosWrapper } from 'utils';

import { RoomListResponse } from '../types/room.type';

export const getRoomsByFacility = (facilityId: string) => async () => {
  const response = await axiosWrapper<RoomListResponse>(
    `/facilities/${facilityId}/rooms?size=${MAX_PAGE_SIZE}`
  );
  return response.data;
};

export const getRoomsByQuery = (
  facilityId: string,
  searchQuery: string,
  page: string
) => async () => {
  const response = await axiosWrapper<RoomListResponse>(
    `/facilities/${facilityId}/rooms?roomSearchQuery=${searchQuery}&page=${page}`
  );
  return response.data;
};
