import { axiosWrapper } from 'utils';

export const createEmptyChatRoom = async (
  participants: string[],
  chatRoomType: string,
  description: string,
  intendedResidentId?: string
) => {
  const url = '/chat-rooms';
  const body = {
    participants,
    chatRoomType,
    description,
    intendedResidentId
  };
  const response = await axiosWrapper(url, 'POST', null, body);

  const chatRoomId = response.headers.location.replace('/chat-rooms/', '');

  return chatRoomId;
};
