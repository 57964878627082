import { axiosWrapper } from 'utils';

import { Caregiver } from 'Settings/types';

export const editCaregiverPartially = (caregiver: Caregiver) => async () => {
  const response = await axiosWrapper(
    `/facilities/${caregiver.facilityId}/caregivers/${caregiver.id}`,
    'PATCH',
    null,
    caregiver
  );

  return response.data;
};
