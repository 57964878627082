import React, { useEffect, useState } from 'react';

import { DismissibleError, Modal } from 'components';
import { usePoster, useSubmitError } from 'hooks';
import { createUser, User } from 'Settings';
import { getHeight } from 'utils';

import { AddUserForm } from './AddUserForm';

import stylesModal from '../../components/Modal/Modal.module.css';
import styles from './AddNewUser.module.css';

export const AddNewUser: React.FunctionComponent<{
  toggle: () => void;
  isOpen: boolean;
  onAdd: (user: User) => void;
}> = ({ toggle, isOpen, onAdd }) => {
  const [savedUser, setSavedUser] = useState<User | undefined>(undefined);
  const { data, setAction, loading: onSaveLoading, error } = usePoster();
  const {
    submitted,
    setSubmitted,
    isErrorShowing,
    dismissError
  } = useSubmitError(error, onSaveLoading);

  const onSave = (newUser: User) => {
    setSavedUser(newUser);
    setAction(createUser(newUser));
    setTimeout(() => {
      setSubmitted(true);
    });
  };

  const onCancel = () => {
    if (isErrorShowing) {
      dismissError();
    }
    toggle();
  };

  useEffect(() => {
    if (submitted && !onSaveLoading && !error) {
      toggle();
      setSubmitted(false);
      if (data && savedUser) {
        onAdd({ ...savedUser, id: data });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, onSaveLoading, error]);

  return (
    <Modal isShowing={isOpen} toggle={toggle}>
      <div className={styles.modalBackdrop}>
        <div className={stylesModal.addFacilityModalContentLeft}>
          <div
            className={stylesModal.facilityModalLeft}
            style={{ marginTop: getHeight('Users') }}
          >
            <AddUserForm
              onSave={onSave}
              onCancel={onCancel}
              onSaveLoading={onSaveLoading}
              error={error}
              isErrorShowing={isErrorShowing}
              dismissError={dismissError}
            >
              {error && (
                <DismissibleError
                  name="User"
                  visible={isErrorShowing}
                  error={error}
                  dismiss={dismissError}
                />
              )}
            </AddUserForm>
          </div>
        </div>
      </div>
    </Modal>
  );
};
