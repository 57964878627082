import React from 'react';

import { ReactComponent as Logo } from 'icons/th-logo-small.svg';

import styles from './AuthUpdatingScreen.module.css';

export const AuthUpdatingScreen: React.FunctionComponent<{}> = () => (
  <div className={styles.authLoadingContainer}>
    <Logo className={styles.logo} />
    <h3 className={styles.authLoadingMessage}>
      Just a moment, we're updating your profile...
    </h3>
  </div>
);
