import React from 'react';

import {
  BloodSugar,
  GraphLoadingPlaceholder,
  HistoryFilterTabs,
  HistoryLabel
} from 'Residents';

import { BloodSugarChart } from './BloodSugarChart';
import { toMonthData } from './toMonthData';
import { toWeekData } from './toWeekData';

import styles from '../Charts.module.css';

export const BloodSugarHistoryChart: React.FunctionComponent<{
  changeHistoryFilter: (filter: HistoryLabel) => void;
  activeHistoryTab: HistoryLabel;
  readings: BloodSugar[];
  loading: boolean;
}> = ({ changeHistoryFilter, activeHistoryTab, loading, readings }) => {
  return (
    <div className={styles.chartBloodSugar}>
      <HistoryFilterTabs
        onSelect={changeHistoryFilter}
        activeTab={activeHistoryTab}
      />
      {!loading && (
        <BloodSugarChart
          data={
            activeHistoryTab !== 'This Month'
              ? toWeekData(readings)
              : toMonthData(readings)
          }
          color="#9a4256"
          allowHighlight={activeHistoryTab !== 'Last Week'}
        />
      )}
      {loading && <GraphLoadingPlaceholder />}
    </div>
  );
};
