import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  ProfilePlaceholder,
  RoundCheckButton,
  RoundPlusButton
} from 'components';

import { IAdHocListItemProps } from './types';

import { formatName } from 'utils';
import styles from './AdHocListItem.module.css';

export const AdHocListItem: FunctionComponent<IAdHocListItemProps> = ({
  isSelected = false,
  onSelect,
  onDeselect,
  item,
  customStyle,
  customInfoStyle,
  customButtonsStyle,
  showButtons
}) => {
  const [formattedName, setFormattedName] = useState<string>();
  const { name, photoUrl, role, loginId, status } = item;

  const handleSelect = () => {
    if (!isSelected && onSelect) {
      onSelect(loginId);
    }
  };

  const handleDeselect = () => {
    if (isSelected && onDeselect) {
      onDeselect(loginId);
    }
  };

  useEffect(() => {
    setFormattedName(formatName(name));
  }, [name]);

  return (
    <li
      className={classnames(styles.listItem, {
        [styles.selected]: isSelected,
        [customStyle as string]: Boolean(customStyle)
      })}
    >
      <div className={styles.avatarContainer}>
        <ProfilePlaceholder
          name={formattedName}
          photoUrl={photoUrl}
          type={status}
        />
      </div>
      <div
        className={classnames(styles.itemInfo, {
          [customInfoStyle as string]: Boolean(customInfoStyle)
        })}
      >
        <span className={styles.name}>{formattedName}</span>
        <span>{role}</span>
      </div>
      {showButtons && (
        <div
          className={classnames(styles.buttons, {
            [customButtonsStyle as string]: Boolean(customButtonsStyle)
          })}
        >
          {isSelected ? (
            <RoundCheckButton onClick={handleDeselect} />
          ) : (
            <RoundPlusButton onClick={handleSelect} />
          )}
        </div>
      )}
    </li>
  );
};
