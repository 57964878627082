import React from 'react';

import styles from './ReadingInput.module.css';

export const ReadingInput: React.FunctionComponent<{
  name: string;
  label: string;
  register: any;
  hasError: boolean;
  unit?: string;
  validationRules?: { [key: string]: any };
  type?: string;
  readonly?: boolean;
}> = ({
  name,
  label,
  register,
  hasError,
  unit,
  validationRules = {},
  readonly = false,
  type = 'number'
}) => {
  const isRequired =
    validationRules && validationRules.required
      ? validationRules.required
      : false;

  const placeholder = isRequired ? `${label} *` : label;
  const extraProps = type === 'number' ? { step: 'any', min: '0' } : {};

  return (
    <fieldset>
      <input
        className={styles.input}
        name={name}
        ref={register(validationRules)}
        placeholder={placeholder}
        type={type}
        data-haserror={hasError}
        readOnly={readonly}
        {...extraProps}
      />
      {unit && <span className={styles.inputUnit} data-input-unit={unit} />}
    </fieldset>
  );
};
