import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';

import { useLocationState } from 'Location';
import { useSocketState } from 'Socket';

import { dismissNotification as dismissNotificationAction } from './actions';
import {
  sortNotifications,
  updateAlarmFromRtlsUpdate
} from './notification.utils';
import {
  ColorCode,
  ColorCodes,
  NotificationActionTypes,
  NotificationDetail,
  NotificationSound,
  NotificationSounds
} from './types';
import { useNotificationsFetch } from './useNotificationsFetch.hook';

export type Action =
  | { type: NotificationActionTypes.DISMISS; payload: string }
  | { type: NotificationActionTypes.REMOTE_DISMISS; payload: string }
  | { type: NotificationActionTypes.FAIL_DISMISS; payload: Error }
  | { type: NotificationActionTypes.FINISH_DISMISS }
  | { type: NotificationActionTypes.FETCH }
  | {
      type: NotificationActionTypes.FINISH_FETCH;
      payload: NotificationDetail[];
    }
  | { type: NotificationActionTypes.FAIL_FETCH; payload: Error }
  | { type: NotificationActionTypes.SET_REFETCH }
  | { type: NotificationActionTypes.ADD; payload: NotificationDetail }
  | { type: NotificationActionTypes.CLEAR }
  | {
      type: NotificationActionTypes.SOUND;
      payload: NotificationSound | undefined;
    }
  | {
      type: NotificationActionTypes.EDIT;
      payload: {
        index: number;
        notification: NotificationDetail;
      };
    };

interface Triggers {
  fetchError: Error | null;
  dismissError: Error | null;
  fetchLoading: boolean;
  dismissLoading: boolean;
  shouldRefetch: boolean;
  playSound: NotificationSound | undefined;
}

export interface State extends Triggers {
  notifications: NotificationDetail[];
}
export type Dispatch = (action: Action) => void;

const NotificationStateContext = createContext<State | undefined>(undefined);
const NotificationDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const initialNotificationTriggers = {
  fetchError: null,
  dismissError: null,
  fetchLoading: false,
  shouldRefetch: false,
  dismissLoading: false,
  playSound: undefined
} as Triggers;

const notificationsReducer = (state: State, action: Action) => {
  const { notifications } = state;

  switch (action.type) {
    case NotificationActionTypes.EDIT: {
      const { index, notification } = action.payload;
      return {
        ...initialNotificationTriggers,
        notifications: [
          ...notifications.slice(0, index),
          { ...notification },
          ...notifications.slice(index + 1)
        ] as NotificationDetail[]
      };
    }
    case NotificationActionTypes.DISMISS: {
      return {
        ...initialNotificationTriggers,
        dismissLoading: true,
        notifications: notifications.filter(({ id }) => id !== action.payload)
      };
    }
    case NotificationActionTypes.REMOTE_DISMISS: {
      return {
        ...initialNotificationTriggers,
        notifications: notifications.filter(({ id }) => id !== action.payload)
      };
    }
    case NotificationActionTypes.FAIL_DISMISS: {
      return {
        ...initialNotificationTriggers,
        dismissError: action.payload,
        notifications
      };
    }
    case NotificationActionTypes.FINISH_DISMISS: {
      return {
        ...initialNotificationTriggers,
        notifications
      };
    }
    case NotificationActionTypes.FETCH: {
      return {
        ...initialNotificationTriggers,
        fetchLoading: true,
        notifications: [] as NotificationDetail[]
      };
    }
    case NotificationActionTypes.FINISH_FETCH: {
      return {
        ...initialNotificationTriggers,
        notifications: [...action.payload]
      };
    }
    case NotificationActionTypes.FAIL_FETCH: {
      return {
        ...initialNotificationTriggers,
        fetchError: action.payload as Error,
        notifications
      };
    }
    case NotificationActionTypes.SET_REFETCH: {
      return {
        ...initialNotificationTriggers,
        shouldRefetch: true,
        notifications
      };
    }
    case NotificationActionTypes.ADD: {
      return {
        ...initialNotificationTriggers,
        notifications: [...notifications, action.payload]
      };
    }
    case NotificationActionTypes.SOUND: {
      return {
        ...state,
        playSound: action.payload
      };
    }
    case NotificationActionTypes.CLEAR: {
      return {
        ...initialNotificationTriggers,
        notifications: [] as NotificationDetail[]
      };
    }
  }
};

export const NotificationProvider: (props: {
  children: React.ReactNode;
  initialState?: State;
}) => any = ({
  children,
  initialState = {
    ...initialNotificationTriggers,
    fetchLoading: true,
    notifications: [] as NotificationDetail[],
    playSound: undefined
  }
}) => {
  const [sortedState, setSortedState] = useState<State>(initialState);
  const [state, dispatch] = useReducer(notificationsReducer, initialState);

  const { ward: wardId, selectedWardDetails } = useLocationState();
  const { alarmUpdate, alarmDismissal, rtlsUpdate } = useSocketState();
  const { refetch, loadDetailedAlarm } = useNotificationsFetch(dispatch);

  useEffect(() => {
    const newState = {
      ...state,
      notifications: selectedWardDetails?.services
        ? sortNotifications(state.notifications, selectedWardDetails?.services)
        : state.notifications
    };
    setSortedState(newState);
  }, [selectedWardDetails, state]);

  useEffect(() => {
    if (
      alarmUpdate &&
      !state.notifications.some(({ id }) => alarmUpdate.id === id)
    ) {
      const { alarmVisibility } = alarmUpdate;
      if (!alarmVisibility || (wardId && alarmVisibility.includes(wardId))) {
        switch (alarmUpdate.colorCode as ColorCode) {
          case ColorCodes.RED: {
            return loadDetailedAlarm(alarmUpdate, NotificationSounds.HIGH);
          }
          case ColorCodes.DARK_ORANGE:
          case ColorCodes.ORANGE: {
            return loadDetailedAlarm(alarmUpdate, NotificationSounds.MEDIUM);
          }
          default:
            return loadDetailedAlarm(alarmUpdate, NotificationSounds.LOW);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmUpdate, loadDetailedAlarm, wardId]);

  useEffect(() => {
    if (
      alarmDismissal &&
      state.notifications.some(({ id }) => alarmDismissal.id === id)
    ) {
      dispatch({
        type: NotificationActionTypes.REMOTE_DISMISS,
        payload: alarmDismissal.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmDismissal]);

  useEffect(() => {
    if (state.shouldRefetch) {
      refetch();
    }
  }, [state.shouldRefetch, refetch]);

  useEffect(() => {
    // if we get an rtlsUpdate with location unknown then fetch notifications again to update location
    if (rtlsUpdate && !rtlsUpdate.room) {
      return refetch();
    }

    // if we get an rtlsUpdate with location then update the notification location from update.room
    let index = -1;

    if (rtlsUpdate && rtlsUpdate.caregiver) {
      index = state.notifications.findIndex(
        note => note.caregiverView?.id === rtlsUpdate.caregiver!.id
      );
    }

    if (rtlsUpdate && rtlsUpdate.resident) {
      index = state.notifications.findIndex(
        note => note.residentView?.id === rtlsUpdate!.resident!.id
      );
    }

    if (index === -1) {
      return;
    }

    const updatedNote = updateAlarmFromRtlsUpdate(
      state.notifications[index],
      rtlsUpdate
    );

    dispatch({
      type: NotificationActionTypes.EDIT,
      payload: {
        index,
        notification: updatedNote
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtlsUpdate]);

  return (
    <NotificationStateContext.Provider value={sortedState}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};

export const useNotificationState = () => {
  const context = useContext(NotificationStateContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationState must be used within a NotificationProvider'
    );
  }

  return context;
};

export const useNotificationDispatch = () => {
  const context = useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationDispatch must be used within a NotificationProvider'
    );
  }
  return context;
};

export const useNotificationContext = (): [State, Dispatch] => {
  return [useNotificationState(), useNotificationDispatch()];
};

export async function dismissNotificationFetch(
  dispatch: Dispatch,
  data: { notificationId: string; facilityId: string; wardId: string }
) {
  const { notificationId, facilityId, wardId } = data;
  dispatch({ type: NotificationActionTypes.DISMISS, payload: notificationId });
  try {
    await dismissNotificationAction(notificationId, facilityId, wardId);
    dispatch({ type: NotificationActionTypes.FINISH_DISMISS });
  } catch (error) {
    dispatch({
      type: NotificationActionTypes.FAIL_DISMISS,
      payload: error as Error
    });
  }
}
