import styles from '../components/ImageUpload/ImageUpload.module.css';

const femaleClasses = `${styles.plusIconFemale} ${styles.plusIcon}`;
const maleClasses = `${styles.plusIconMale} ${styles.plusIcon}`;

export const changeColor = (gender?: string) => {
  if (gender === 'female') {
    return femaleClasses;
  } else if (gender === 'male') {
    return maleClasses;
  }
  return styles.plusIcon;
};
