import React, { useEffect, useMemo, useState } from 'react';

import { FetchError } from 'components';
import { LoadingPlaceholderCard } from 'Home';
import { useFetcher, useInfiniteScroll } from 'hooks';
import { useLocationState } from 'Location';
import { ResidentCard } from 'Residents';
import { Resident } from 'Settings';

import { searchResidentsByName } from './actions';
import { useSearchState } from './search.context';

import styles from './SearchResultsList.module.css';

const NO_RESULTS_MESSAGE = 'We could not find any results for your search';

export const ResidentResultsList: React.FunctionComponent = () => {
  const [page, setPage] = useState(0);
  const [residents, setResidents] = useState<Resident[]>([]);

  const { searchTerm } = useSearchState();
  const { facilities } = useLocationState();

  const getResidentsAction = useMemo(() => {
    if (searchTerm && searchTerm.length >= 3) {
      return searchResidentsByName(searchTerm, page);
    }
    return () => Promise.resolve({ items: [] });
  }, [page, searchTerm]);

  const {
    data: residentData,
    error: residentError,
    loading: residentLoading
  } = useFetcher<{
    items: Resident[];
    nextPage?: string;
  }>(getResidentsAction, { items: [] });

  const scrollAction = () => {
    if (isFetching && residentData.items.length && residentData.nextPage) {
      setPage(parseInt(residentData.nextPage, 10));
      setIsFetching(false);
    }
  };

  const { scrollRef, isFetching, setIsFetching } = useInfiniteScroll(
    scrollAction,
    residentLoading
  );

  useEffect(() => {
    if (residentData.items.length !== 0) {
      setResidents(prevState => [...prevState, ...residentData.items]);
    }
  }, [residentData]);

  useEffect(() => {
    setResidents([]);
    setPage(0);
  }, [searchTerm]);

  const residentCards = useMemo(
    () =>
      residents.map(
        ({ id: residentId, name, gender, room, facilityId, photoUrl }) => {
          const wardId = room && room.ward && room.ward.id;
          const dashboardLink = wardId
            ? `/ResidentDashboard?facility=${facilityId}&ward=${wardId}&resident=${residentId}`
            : '#';
          const facility = facilities.find(({ id }) => id === facilityId);

          const location =
            room && room.number && room.ward && room.ward.name
              ? `${room.ward.name} - ${room.number}`
              : 'Unknown';

          return (
            <ResidentCard
              key={residentId}
              id={residentId}
              name={name}
              type={gender.toLowerCase()}
              link={dashboardLink}
              location={location}
              facility={facility ? facility.name : ''}
              photoUrl={photoUrl}
            />
          );
        }
      ),
    [facilities, residents]
  );

  const placeholderMessage =
    searchTerm && searchTerm.length >= 3 && !residents.length
      ? NO_RESULTS_MESSAGE
      : 'Start searching for residents and patients';

  const isDataAvailable = residents.length > 0;
  const ResidentsLoadingPlaceholder = (
    <>
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
      {LoadingPlaceholderCard}
    </>
  );

  return (
    <div className={styles.resultsContainer}>
      <h1 className={styles.title}>Residents and Patients Search Results</h1>
      {!residentError && (
        <div className={styles.content} ref={scrollRef}>
          {(!residentLoading || isDataAvailable) && residentCards}
          {residentLoading && ResidentsLoadingPlaceholder}
        </div>
      )}
      {!residentError && !residentLoading && !isDataAvailable && (
        <p className={styles.emptyListPlaceholder}>{placeholderMessage}</p>
      )}
      {residentError && !residentLoading && (
        <FetchError error={residentError} />
      )}
    </div>
  );
};
