import { axiosWrapper } from 'utils';

import { Resident } from 'Settings/types/resident.type';

export const createResident = (
  facilityId: string,
  resident: Resident
) => async () => {
  const url = `/facilities/${facilityId}/residents`;
  const response = await axiosWrapper(url, 'POST', null, resident);
  return response.headers.location.replace(`${url}/`, '');
};
