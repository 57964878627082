import { DropdownOption } from 'components/types';

import { Room, SingleRoom } from '../Settings/types';

export const getHeight = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    //  the margin :30px
    return element.offsetTop - 30;
  }
  return 0;
};

export const getWidth = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    //  the margin :30px
    return element.offsetLeft - 30;
  }
  return 0;
};

export const changeToBoolean = (value: any) =>
  value === 'false' ? false : true;

export const groupArray = (array: any[], chunkSize: number) =>
  array.reduce((acc, current, index) => {
    if (index % chunkSize === 0) {
      return [...acc, [current]];
    }
    const lastArrayIndex = index - (index % chunkSize);
    const currentArray = acc[lastArrayIndex / chunkSize];
    currentArray.push(current);
    return acc;
  }, []);

// access an object's prop by key string, supports nested keys
const getNestedProp = (object: any, keyString: string) => {
  if (typeof object !== 'object' || !keyString) {
    return object;
  }
  const nestedKeys = keyString.split('.');

  if (nestedKeys.length === 1) {
    return object[keyString];
  }

  let resultProp = object;

  nestedKeys.some(key => {
    const candidateProp = resultProp[key];
    resultProp = candidateProp;
    if (candidateProp === undefined) {
      return true;
    }
    return false;
  });

  return resultProp;
};

const compareByKey = (key: string, reverse: boolean) => {
  const asc = reverse ? 1 : -1;
  const desc = reverse ? -1 : 1;

  return (first: any, second: any) => {
    const firstToCheck = getNestedProp(first, key);
    const secondToCheck = getNestedProp(second, key);

    if (firstToCheck?.toUpperCase() < secondToCheck?.toUpperCase()) {
      return asc;
    }
    if (firstToCheck?.toUpperCase() > secondToCheck?.toUpperCase()) {
      return desc;
    }
    return 0;
  };
};

export const sortByKey = (
  array: any[],
  key: string,
  reverse: boolean = false
) => array.slice().sort(compareByKey(key, reverse));

export const debounce = (func: any, delay: number = 300) => {
  let inDebounce: any;
  function callback(...args: any[]) {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  }
  return callback;
};

export const getFullAddress = ({ address, city, state, zip }: any) => {
  const partialAddress = [address, city, state]
    .filter(value => value)
    .join(', ');
  const fullAddress =
    !partialAddress && !zip ? 'N/A' : `${partialAddress} ${zip || ''}`;

  return fullAddress;
};

export const setFormAsTouched = (form: any) => {
  const formArray = Object.values(form);
  formArray.map((formField: any) => formField.setTouched());
};

export const splitRoomsByResidents = (rooms: Room[]) => {
  return rooms.reduce((singleRooms, room) => {
    const { id, number: roomNumber, residents } = room;
    let individualRooms: SingleRoom[] = [
      {
        id,
        number: roomNumber
      }
    ];
    if (
      residents &&
      residents.length &&
      residents.find(resident => resident.status)
    ) {
      individualRooms = residents
        .filter(resident => resident.status)
        .map(resident => ({
          id,
          number: roomNumber,
          resident: {
            id: resident.id,
            name: resident.name,
            number: resident.number
          }
        }));
    }
    return [...singleRooms, ...individualRooms];
  }, [] as SingleRoom[]);
};

export const formatSelectedValues = (
  value: any,
  options: DropdownOption[],
  labelKey: string = 'name'
) => {
  if (isEmpty(value)) {
    return value;
  }

  if (Array.isArray(value)) {
    return options.reduce((selectedOptions: any[], current) => {
      if (value.includes(current.value)) {
        const formattedOption = {
          id: current.value,
          [labelKey]: current.label
        };
        const otherKeys = Object.keys(current).filter(
          key => key !== 'value' && key !== 'label'
        );

        otherKeys.forEach((otherKey: string) => {
          formattedOption[otherKey] = current[otherKey];
        });
        return [...selectedOptions, formattedOption];
      }
      return selectedOptions;
    }, []);
  }
  const selected = options.find(({ value: val }) => val === value);
  return selected ? { id: selected.value, [labelKey]: selected.label } : null;
};

export const isEmpty = (value: any) =>
  value === null || value === '' || value === undefined;

export const stripIdForDropdownValue = (value: any) => {
  if (isEmpty(value) || typeof value !== 'object') {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(({ id }) => id);
  }
  return value.id;
};

export const formatWardRoom = (
  room:
    | {
        id: string;
        number: string;
        ward?: { id: string; name: string };
      }
    | undefined
) => {
  if (room && room.number) {
    if (room.ward) {
      return `${room.ward.name} - ${room.number}`;
    }
    return `N/A - ${room.number}`;
  }
  return 'N/A';
};

export const saveFile = (filename: string, contents: Blob) => {
  if (window.navigator.msSaveOrOpenBlob !== undefined) {
    // for IE11
    window.navigator.msSaveBlob(contents, filename);
  } else {
    const downloadLink = window.document.createElement('a');
    const objectUrl = window.URL.createObjectURL(contents);
    downloadLink.href = objectUrl;
    downloadLink.download = filename;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(objectUrl);
  }
};

export const filterHelper = (value: string, ...args: string[]): boolean => {
  return args.some(
    arg => arg && arg.toLowerCase().includes(value.toLowerCase())
  );
};
