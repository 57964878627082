import React, { useState } from 'react';

import { SelectDropdown } from './SelectDropdown';

import styles from './DatePicker.module.css';

export const DatePickerCaption = ({
  date = new Date(),
  localeUtils,
  onSelect
}: {
  date: Date | undefined;
  localeUtils: any;
  onSelect: (date: Date) => void;
}) => {
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());

  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 100, 0);
  const toMonth = new Date();
  const months = localeUtils.getMonths();
  const range = toMonth.getFullYear() + 1 - fromMonth.getFullYear();
  const years = [...Array(range)].map(
    (_: any, i: number) => toMonth.getFullYear() - i
  );

  const handleYearChange = (value: number) => {
    setYear(value);
    onSelect(new Date(value, month));
  };
  const handleMonthChange = (value: string) => {
    const actualValue = months.indexOf(value);
    setMonth(actualValue);
    onSelect(new Date(year, actualValue));
  };

  return (
    <div className={styles.caption}>
      <SelectDropdown
        value={date.getFullYear()}
        onChange={handleYearChange}
        options={years}
      />
      <SelectDropdown
        value={months[date.getMonth()]}
        onChange={handleMonthChange}
        options={months}
      />
    </div>
  );
};
